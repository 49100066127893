import React, {Component} from 'react';
import './MaterialsScreen.scss';
import {Container, Dimmer, Header, Input, Loader, Menu, Table} from "semantic-ui-react";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import ApiService from "../services/ApiService";
import MaterialConfig from "../components/MaterialConfig";
import MaterialConfig2 from "../components/MaterialConfig2";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import FoilConfig from "../components/FoilConfig";
import FontConfig from "../components/FontConfig";

class VatRatesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vatMap: {},
            buttonLabel:"save"
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {

        return (
            <Container>
                <Dimmer active={this.state.loading}>
                    <Loader/>
                </Dimmer>

                <Menu pointing inverted color='blue' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>VAT Rates</Menu.Item>
                </Menu>


                <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                    <Loader active={this.state.loading}/>

                    {this.compileElements()}

                    <Button onClick={this.onSaveClicked} primary>{this.state.buttonLabel}</Button>
                </Container>
            </Container>
        );
    }

    onSaveClicked = async() => {
        let copy = {...this.state.vatMap}
        let keys = Object.keys(copy);

        keys.forEach(key=>{
            copy[key] = parseFloat(copy[key]);
        })


        await ApiService.setVatRates(copy);
        this.setState({buttonLabel:"ok"})
    }

    onRateChanged = (key, value) => {
        let copy = {...this.state.vatMap}
        copy[key] = value;
        this.setState({vatMap: copy})
    }

    compileElements = () => {
        const keys = Object.keys(this.state.vatMap);
        let elements = [];
        if (keys.length) {
            keys.forEach(key => {
                elements.push(
                    <div style={{padding: '10px'}}>
                        <Input label={key} value={this.state.vatMap[key]}
                               onChange={(e, {value}) => this.onRateChanged(key, value)}/>
                    </div>
                )
            })

            return elements
        }

        return null;
    }


    loadData = () => {
        ApiService.getVatRates().then(data => {
            this.setState({vatMap: data.data})
        })
    }


}


export default VatRatesScreen;
