import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Grid,
    Header,
    Icon,
    Image,
    Input,
    Item,
    Label,
    Loader
} from "semantic-ui-react";
import './PopOverIconRenderer.scss'

class PopOverIconRenderer extends Component {
    constructor(props){
        super(props);

        this.state = {
            expanded: false
        }
    }

    render() {
        let color = this.props.color;
        if(!color){
            color = 'red'
        }

        return (
               <div className='PopOverIconRenderer'>
                   <Icon style={{color: color, marginLeft: '40px', fontSize: '20px'}} name={this.props.icon} onMouseEnter={()=>this.setState({expanded: true})} onMouseLeave={()=>this.setState({expanded: false})}/>
                   {this.state.expanded &&
                   <div className='over' style={{width: '100%'}}>
                       {this.props.children}
                   </div>
                   }

               </div>
        );
    }
}


export default PopOverIconRenderer;
