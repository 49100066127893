import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Grid,
    Header,
    Image,
    Input,
    Item,
    Label,
    Loader,
    Select
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PriceConfig from "./PriceConfig";

class TemplateConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lines: this.props.lines || []
        };

        this.emptyLine = {
            fontId: 0,
            fontSize: 0,
            defaultText: 'sample',
            fontColor: '',
            dy: 0
        }
    }

    render() {
        const numLines = this.state.lines.length;
        const linesElement = this.getLinesElement();

        return (
            <Container style={{margin: '1.5rem 0 2.5rem 0'}}>
                <div>Lines: {numLines} &nbsp;&nbsp;
                    <Button compact basic onClick={this.onRemoveLineClicked}>-</Button>
                    <Button compact basic onClick={this.onAddLineClicked}>+</Button>
                </div>
                {linesElement}
                <Button compact onClick={this.onSaveClicked}>Update changes</Button>
            </Container>
        );
    }

    getLinesElement = () => {
        if (this.state.lines.length === 0) {
            return null;
        }

        const fonts = this.props.fonts.map(font => {
            return {
                key: font.id,
                value: font.id,
                text: font.name
            }
        });

        return this.state.lines.map(line => {
            return (
                <Container key={line.id} style={{margin: '2rem 0'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>
                            <h5>Line {line.id} font family</h5>
                            <Select placeholder='Font' label='Font' options={fonts}
                                    value={line.fontId}
                                    onChange={(e, {name, value}) => this.handleChange(line, 'fontId', value)}/>
                        </div>

                        <div style={{margin: '0 10px'}}>
                            <h5>Line {line.id} font size (in PT)</h5>
                            <Input placeholder='13' name='fontSize' value={line.fontSize}
                                   onChange={(e, {name, value}) => this.handleChange(line, name, value)}/>
                        </div>

                        <div style={{margin: '0 10px'}}>
                            <h5>Line {line.id} margin bottom (in CM)</h5>
                            <Input placeholder='13' name='marginBottom' value={line.marginBottom}
                                   onChange={(e, {name, value}) => this.handleChange(line, name, value)}/>
                        </div>
                    </div>

                </Container>
            )
        })
    };

    onAddLineClicked = () => {
        let lines = [...this.state.lines];
        lines.push(JSON.parse(JSON.stringify(this.emptyLine)));

        lines.forEach((line, index) => {
            line.id = index + 1;
        });

        this.setState({lines: lines});
    };

    onRemoveLineClicked = () => {
        let lines = [...this.state.lines];
        lines.pop();

        lines.forEach((line, index) => {
            line.id = index + 1;
        });

        this.setState({lines: lines});
    };

    handleChange = (line, name, value) => {
        let lines = [...this.state.lines];

        let editedLine = lines.filter(l => l.id === line.id)[0];
        editedLine[name] = value;

        this.setState({lines: lines});
    };

    onSaveClicked = () => {
        const lines = [...this.state.lines];

        this.props.onSaveClicked(lines);
    }
}


export default TemplateConfig;
