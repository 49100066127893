class ScreenController {
    constructor() {
        this.history = null;

        this.nextPath = null;
        this.nextPathData = null;

        this.locked = false;
    }

    setHistory = (history) => {
        this.history = history;
    };

    lock = () => {
        this.locked = true;
    };

    unlock = () => {
        this.locked = false;
    };

    execute = () => {
        if (this.nextPath) {
            this.history.push({pathname: this.nextPath, state: this.nextPathData});
            this.nextPath = null;
            this.nextPathData = null;
        }
    };

    goToScreen = (path, data) => {
        console.log("GO TO SCREEN", path)
        if (this.locked) {
            this.nextPath = path;
            this.nextPathData = data;
        } else {
            this.history.push({pathname: path, state: data});
        }
    }
}

export default new ScreenController();
