import React, {Component} from 'react';
import userStatusHandler from './userStatusHandler';
import './App.scss';

import {Redirect, Route, Switch} from "react-router";
import AuthenticateScreen from "./screens/AuthenticateScreen";
import HeaderComponent from "./components/HeaderComponent";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {onStateChanged} from "./services/Store";

import AppHandler from "./controler/AppHandler";
import AuthRequired from "./components/AuthRequired";
import AlbumsScreen from "./screens/AlbumsScreen";
import ApiService from "./services/ApiService";
import {Container} from "semantic-ui-react";
import GuestbooksScreen from "./screens/GuestbooksScreen";
import BudoirScreen from "./screens/BudoirScreen";
import OrdersScreen from "./screens/OrdersScreen";
import ManufactureScreen from "./screens/ManufactureScreen";
import InvoicesScreen from "./screens/InvoicesScreen";
import ExternalScreen from "./screens/ExternalScreen";
import AuthService from "./services/AuthService";
import HomeScreen from "./screens/HomeScreen";
import SalesScreen from "./screens/SalesScreen";
import AlexScreen from "./screens/AlexScreen";
import * as axios from "axios";
import SuppliersScreen from "./screens/SuppliersScreen";
import OrderScreen from "./screens/OrderScreen";
import NewOrderScreen from "./screens/NewOrderScreen";
import BambooBoxScreen from "./screens/BambooBoxScreen";
import CornersScreen from "./screens/CornersScreen";
import ReportsScreen from "./screens/ReportsScreen";
import PouchScreen from "./screens/PouchScreen";
import TimelineScreen from "./screens/TimelineScreen";
import CurrencyScreen from "./screens/CurrencyScreen";
import WebsiteScreen from "./screens/WebsiteScreen";
import EtsyScreen from "./screens/EtsyScreen";
import PendingOrdersScreen from "./screens/PendingOrdersScreen";
import DhlCheckScreen from "./screens/DhlCheckScreen";
import MaterialsScreen from "./screens/MaterialsScreen";
import BudoirEmptyScreen from "./screens/BudoirEmptyScreen";
import EmbossFoilsScreen from "./screens/EmbossFoilsScreen";
import AlbumSleeveScreen from "./screens/AlbumSleeveScreen";
import PremiumBoxesScreen from "./screens/PremiumBoxesScreen";
import Presence from "./services/Presence";
import ShippingScreen from "./screens/ShippingScreen";
import ShippingScreenBatch from "./screens/ShippingScreenBatch";
import ShippingScreenWizard from "./screens/ShippingScreenWizard";
import WebsiteSwatchesScreen from "./screens/WebsiteSwatchesScreen";
import OrdersTabScreen from "./screens/OrdersTabScreen";
import PackingScreen from "./screens/PackingScreen";
import PensScreen from "./screens/PensScreen";
import StocksScreen from "./screens/StocksScreen";
import PacketaScreen from "./screens/PacketaScreen";
import DailyStats from "./screens/DailyStats";
import ClassicAlbums from "./screens/ClassicAlbumsScreen";
import ClassicAlbumsScreen from "./screens/ClassicAlbumsScreen";
import PaperConfigScreen from "./screens/PaperConfigScreen";
import RawItemsConfigScreen from "./screens/RawItemsConfigScreen";
import TaieriScreen from "./screens/TaieriScreen";
import TrustScreen from "./screens/TrustScreen";
import RawMaterialConfigScreen from "./screens/RawMaterialConfigScreen";
import EtsyLedgerScreen from "./screens/EtsyLedgerScreen";
import EtsyAccountingLedgerScreen from "./screens/EtsyAccountingLedgerScreen";
import ShippingBoxesScreen from "./screens/ShippingBoxesScreen";
import QuickFillScreen from "./screens/QuickFillScreen";
import AWBToClientScreen from "./screens/AWBToClientScreen";
import GuestbooksSlotsScreen from "./screens/GuestbooksSlotsScreen";
import BlocStockNeeds from "./screens/BlocStockNeeds";
import ProductsConfigScreen from "./screens/ProductsConfigScreen";
import VowBooksScreen from "./screens/VowBooksScreen";
import MarkersScreen from "./screens/MarkersScreen";
import PersonalisationScreen from "./screens/PersonalisationScreen";
import VatRatesScreen from "./screens/VatRatesScreen";


/**
 * [] Hide edit button on saved configured item
 *
 */


class App extends Component {
    constructor(props) {
        super(props);

        this.APP_VERSION = "3.6";
        this.hidden = null;

        this.state = {
            config: null,
            configLoading: false,
        };

        AppHandler.init();

        onStateChanged((newState) => {
            this.setState({...newState});
        });

        toast.configure()

        const user = AuthService.getUserData();
        const userId = !user ? 'Guest' : user.id;
        Presence.setup(userId, 'Control', "wss://alexvlad.com/presence/");

    }

    componentDidMount() {
        ApiService.loadConfig().then(data => {
            let missmatch = false;

            if (data.data.appVersion && data.data.appVersion !== this.APP_VERSION) {
                missmatch = true;
            }

            this.setState({config: data.data, missmatch: missmatch})

            this.checkVersion();

            if (AuthService.isAuthenticated()) {
                ApiService.getFonts().then(fontsData => {
                    let fonts = fontsData.data.fonts;
                    fonts.forEach(font => {
                        this.addFonts(font.name, `https://data.phototailors.com/products/fonts/${font.filePath}`)

                    })
                })
            }
        })
    }

    render() {
        if (!this.state.config) {
            return <Container>...</Container>;
        }

        let style = {};
        if (axios.defaults.baseURL.indexOf('127.0.0.1') !== -1) {
            style.border = '15px solid #ff0000'
        }
        if (axios.defaults.baseURL.indexOf('control-stage') !== -1) {
            style.border = '15px solid purple'
        }

        if (this.state.missmatch) {
            return (
                <div style={{margin: '80px'}}>
                    <h1>Ai o versiune veche a aplicatiei.</h1>
                    <div>Reincarca pagina sau fa clear la cache</div>
                </div>
            )
        }

        return (
            <div style={style}>
                <HeaderComponent account={this.state.account} location={this.props.history.location}/>
                <Switch>
                    <Route exact path="/login"
                           render={renderProps => (<AuthenticateScreen data={this.state.authentication}/>)}/>

                    <Route
                        exact path="/home"
                        render={() => (
                            <AuthRequired
                                orRender={(<HomeScreen/>)}
                            />)}
                    />

                    <Route
                        exact path="/albums"
                        render={() => (
                            <AuthRequired
                                orRender={(<AlbumsScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/classic-albums"
                        render={() => (
                            <AuthRequired
                                orRender={(<ClassicAlbumsScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/paper-config"
                        render={() => (
                            <AuthRequired
                                orRender={(<PaperConfigScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/raw-materials-config"
                        render={() => (
                            <AuthRequired
                                orRender={(<RawMaterialConfigScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/raw-config"
                        render={() => (
                            <AuthRequired
                                orRender={(<RawItemsConfigScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/guestbooks"
                        render={() => (
                            <AuthRequired
                                orRender={(<GuestbooksScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/vow-books"
                        render={() => (
                            <AuthRequired
                                orRender={(<VowBooksScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/markers"
                        render={() => (
                            <AuthRequired
                                orRender={(<MarkersScreen config={this.state.config}/>)}
                            />)}
                    />


                    <Route
                        exact path="/guestbooks-slots"
                        render={() => (
                            <AuthRequired
                                orRender={(<GuestbooksSlotsScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/boudoir"
                        render={() => (
                            <AuthRequired
                                orRender={(<BudoirScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/boudoir-empty"
                        render={() => (
                            <AuthRequired
                                orRender={(<BudoirEmptyScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/bamboo-box"
                        render={() => (
                            <AuthRequired
                                orRender={(<BambooBoxScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/album-sleeve"
                        render={() => (
                            <AuthRequired
                                orRender={(<AlbumSleeveScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/premium-box"
                        render={() => (
                            <AuthRequired
                                orRender={(<PremiumBoxesScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/shipping-box"
                        render={() => (
                            <AuthRequired
                                orRender={(<ShippingBoxesScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/pc"
                        render={() => (
                            <AuthRequired
                                orRender={(<ProductsConfigScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/emboss"
                        render={() => (
                            <AuthRequired
                                orRender={(<EmbossFoilsScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/vat-rates"
                        render={() => (
                            <AuthRequired
                                orRender={(<VatRatesScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/materials"
                        render={() => (
                            <AuthRequired
                                orRender={(<MaterialsScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/corners"
                        render={() => (
                            <AuthRequired
                                orRender={(<CornersScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/pouch"
                        render={() => (
                            <AuthRequired
                                orRender={(<PouchScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/pen"
                        render={() => (
                            <AuthRequired
                                orRender={(<PensScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/website"
                        render={() => (
                            <AuthRequired
                                orRender={(<WebsiteScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/etsy-config"
                        render={() => (
                            <AuthRequired
                                orRender={(<EtsyScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/etsy-ledger"
                        render={() => (
                            <AuthRequired
                                orRender={(<EtsyLedgerScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/etsy-accounting"
                        render={() => (
                            <AuthRequired
                                orRender={(<EtsyAccountingLedgerScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/currency"
                        render={() => (
                            <AuthRequired
                                orRender={(<CurrencyScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/orders"
                        render={(props) => (
                            <AuthRequired
                                orRender={(<OrdersScreen config={this.state.config} {...props}/>)}
                            />)}
                    />

                    <Route
                        exact path="/manufacture"
                        render={() => (
                            <AuthRequired
                                orRender={(<ManufactureScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/invoices"
                        render={() => (
                            <AuthRequired
                                orRender={(<InvoicesScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/external"
                        render={() => (
                            <AuthRequired
                                orRender={(<ExternalScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/sales"
                        render={() => (
                            <AuthRequired
                                orRender={(<SalesScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/suppliers"
                        render={() => (
                            <AuthRequired
                                orRender={(<SuppliersScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/bloc-stock-needs"
                        render={() => (
                            <AuthRequired
                                orRender={(<BlocStockNeeds config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/new-order"
                        render={() => (
                            <AuthRequired
                                orRender={(<NewOrderScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/reports"
                        render={() => (
                            <AuthRequired
                                orRender={(<ReportsScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/timeline"
                        render={() => (
                            <AuthRequired
                                orRender={(<TimelineScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/website-swatches"
                        render={() => (
                            <AuthRequired
                                orRender={(<WebsiteSwatchesScreen config={this.state.config}/>)}
                            />)}
                    />

                    {/*<Route*/}
                    {/*    exact path="/shipping-dashboard"*/}
                    {/*    render={() => (*/}
                    {/*        <AuthRequired*/}
                    {/*            orRender={(<ShippingDashboardScreen config={this.state.config}/>)}*/}
                    {/*        />)}*/}
                    {/*/>*/}

                    <Route
                        exact path="/pending_orders"
                        render={() => (
                            <AuthRequired
                                orRender={(<PendingOrdersScreen config={this.state.config}/>)}
                            />)}
                    />


                    <Route
                        exact path="/alex"
                        render={() => (
                            <AuthRequired
                                orRender={(<AlexScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/personalisation"
                        render={() => (
                            <AuthRequired
                                orRender={(<PersonalisationScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/awb2client"
                        render={() => (
                            <AuthRequired
                                orRender={(<AWBToClientScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/packing"
                        render={(props) => (
                            <AuthRequired
                                orRender={(<PackingScreen config={this.state.config} {...props}/>)}
                            />)}
                    />

                    <Route
                        exact path="/taieri"
                        render={(props) => (
                            <AuthRequired
                                orRender={(<TaieriScreen config={this.state.config} {...props}/>)}
                            />)}
                    />

                    <Route
                        exact path="/packing/:orderId"
                        render={(props) => (
                            <AuthRequired
                                orRender={(<PackingScreen config={this.state.config} {...props}/>)}
                            />)}
                    />

                    <Route
                        exact path="/orders-tab"
                        render={(props) => (
                            <AuthRequired
                                orRender={(<OrdersTabScreen config={this.state.config} {...props}/>)}
                            />)}
                    />

                    <Route
                        exact path="/dhl_check"
                        render={() => (
                            <AuthRequired
                                orRender={(<DhlCheckScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/packeta"
                        render={() => (
                            <AuthRequired
                                orRender={(<PacketaScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/trust"
                        render={() => (
                            <AuthRequired
                                orRender={(<TrustScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/daily-stats"
                        render={() => (
                            <AuthRequired
                                orRender={(<DailyStats config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/order/:id"
                        render={(props) => (
                            <AuthRequired
                                orRender={(<OrderScreen config={this.state.config} {...props}/>)}
                            />)}
                    />

                    <Route
                        exact path="/inventory"
                        render={() => (
                            <AuthRequired
                                orRender={(<StocksScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/quick-fill-order"
                        render={() => (
                            <AuthRequired
                                orRender={(<QuickFillScreen config={this.state.config}/>)}
                            />)}
                    />

                    <Route
                        exact path="/shipping/:date/wizard"
                        render={() => (
                            <AuthRequired
                                orRender={(<ShippingScreenWizard config={this.state.config}/>)}
                            />)}
                    />

                    {/*<Route render={() => (<div>404</div>)}/>*/}
                    <Redirect exact from="/" to="/home"/>
                </Switch>
            </div>
        );
    }

    checkVersion = () => {
        setTimeout(() => {
            ApiService.loadConfig().then(data => {
                let missmatch = false;

                if (data.data.appVersion && data.data.appVersion !== this.APP_VERSION) {
                    missmatch = true;
                    this.setState({missmatch: missmatch})
                }
            })

            this.checkVersion();
        }, 10000)
    }

    addFonts = (name, path) => {
        const css = `
                @font-face {
                  font-family: '${name}';
                  font-display: swap;
                  src: local('${name}'), local('${name}'), url(${path});
                }`;

        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

    }


}


export default userStatusHandler(App);
