import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Dropdown, Input, Loader, Table} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import './ManufactureScreen.scss';
import WorkItem from "../components/WorkItem";
import moment from "moment-timezone";
import Moment from "react-moment";
import 'moment/locale/ro';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import EmbossWorkItem from "../components/EmbossWorkItem";
import {Extract, Validate} from "../components/utils/checksum";
import DatePicker from "react-datepicker/es";
import workItemSKU from "../components/utils/workItemSKU";
import {Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import BarChart from "recharts/lib/chart/BarChart";
import Utils from "../data/Utils";

class ManufactureScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            view: 'orders',
            filter: '',
            orders: [],
            display: [],
            lastUpdate: new Date().getTime(),
            configs: {},
            filterStart: null,
            filterEnd: null,
            embossTimeFilter: -1,
            embossTemplateFilter: -1,
            syncLock: false,
            stockItems: [],
            manufactureDeadline: -1,
            manufactureProductType: 'Toate',
            manufactureCover: 'Toate',
            fulfiller: 'apertura',
            embossCoverStatusFilter: 'Gata',
            shippings: [],
            allMaterialSizes: [],
            neededBlocks: {filtered:[], items:[]}
        };

        this.stockItems = [];
        this.stockItemsFixed = [];

        this.refreshTimer = null;
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.loadProductConfig().then(data => {
                this.setState({configs: data.data}, () => {
                    this.updateData();
                })
            })

            ApiService.getSizes('raw-material').then(data => {
                this.setState({allMaterialSizes: data.data.sizes})
            })

            ApiService.getBlockStocNeeds().then(data=>{
                this.setState({neededBlocks:data.data})
            })
        });
    }

    render() {

        let orders = [...this.state.orders];
        let allCovers = {};

        orders.forEach(order => {
            order.work_items.forEach(wi => {
                const coverStyle = wi.item.cover_style;
                if (coverStyle) {
                    let coverName = coverStyle.text;
                    allCovers[coverName] = 1;
                }
            })
        })

        orders.forEach(order => {
            order.idSearch = [];
        })

        orders.forEach(order => {
            if (order.suborder_of) {
                let ord = orders.find(o => o.id === order.suborder_of);
                ord.idSearch.push(order.id.toString());
            } else {
                order.idSearch.push(order.id.toString());
            }
        })

        allCovers = ['Toate', ...Object.keys(allCovers)];

        const ordersItems = this.getOrderItemsElement(this.state.display);
        const graphElement = this.getGraphElement();
        const graphElementShipped = this.getGraphElementShipped();

        const latUpdate = moment(this.state.lastUpdate).fromNow();

        const {embossed, notEmbossed, embossElements} = this.getEmbossOrdersElement();

        const blockElement = this.getBlockElement();

        const renderedOrdersNr = ordersItems?.length ?? 0;

        const neededBlocksElement = this.getBlockNeedsElement();


        return (
            <div className="ManufactureScreen" style={{margin: '3rem 1rem'}}>
                {this.state.loading &&
                    <div style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                        background: 'white',
                        position: 'fixed',
                        opacity: '0.4'
                    }}>
                        <Loader active={this.state.loading}/>
                    </div>
                }

                <Moment locale="ro" fromNow interval={5000}>
                    {this.state.lastUpdate}
                </Moment>
                <br/>
                <br/>
                {/*<Button compact primary style={{marginLeft: '20px'}}*/}
                {/*        onClick={() => this.setState({view: 'emboss'})}>Stantat</Button>*/}

                <Button as='a' compact primary style={{marginLeft: '20px'}} href='/personalisation?tab=emboss&deadline=0&hideDone=false&sortByTemplate=true'>Stantat</Button>
                <Button compact primary style={{marginLeft: '20px'}}
                        onClick={() => this.setState({view: 'orders'})}>Comenzi</Button>
                <Button compact primary style={{marginLeft: '20px'}}
                        onClick={() => this.setState({view: 'block'})}>Agregat</Button>

                <Button compact primary style={{marginLeft: '20px'}}
                        onClick={this.downloadCSV}>CSV</Button>

                <Button compact primary style={{marginLeft: '20px'}}
                        onClick={() => this.setState({view: 'graph'})}>Pe zile</Button>

                <Button compact primary style={{marginLeft: '20px'}}
                        onClick={() => this.setState({view: 'blocuri'})}>Blocuri</Button>

                <br/>


                <br/>
                <br/>

                {this.state.view === 'blocuri' &&
                    <div>
                        <h1>Necesar blocuri</h1>

                        <div>{neededBlocksElement}</div>
                    </div>
                }

                {(this.state.view !== 'graph' && this.state.view !== 'blocuri') &&

                    <Input style={{marginBottom: '30px'}} label='search' placeholder='12-34-X'
                           value={this.state.filter} onChange={(e, {value}) => this.setState({filter: value})}/>
                }

                {this.state.view === 'graph' &&
                    <div style={{width: '100%', height: '600px'}}>
                        <h3 style={{marginLeft: '60px'}}>Deadline productie pe zile</h3>
                        {graphElement}
                    </div>
                }

                {this.state.view === 'graph' &&
                    <div style={{width: '100%', height: '600px'}}>
                        <h3 style={{marginLeft: '60px', marginTop: '100px'}}>Productie pe zile</h3>
                        {graphElementShipped}
                    </div>
                }

                {this.state.view === 'orders' &&

                    <div style={{display: 'flex', marginBottom: '20px', flexWrap:'wrap'}}>
                        <Button style={{marginRight: '20px'}} onClick={() => {
                            this.setState({
                                manufactureDeadline: -1,
                                manufactureProductType: 'Toate',
                                manufactureCover: 'Toate'
                            })
                        }}>Reseteaza filtre</Button>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Deadline:</div>
                            <Dropdown
                                options={[-1, 0, 1, 2, 3, 4, 5, 6, 7].map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i === -1 ? 'Toate' : i === 0 ? 'AZI' : i === 1 ? 'Maine' : `peste ${i} zile`,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={this.state.manufactureDeadline}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.setState({manufactureDeadline: value})}
                            />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Produs:</div>
                            <Dropdown
                                options={['Toate', 'Guestbook', 'Boudoir', 'Album', 'Classic Album'].map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={this.state.manufactureProductType}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.setState({manufactureProductType: value})}
                            />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Coperta:</div>
                            <Dropdown
                                options={allCovers.map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={this.state.manufactureCover}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.setState({manufactureCover: value})}
                            />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Producator:</div>
                            <Dropdown
                                options={Object.keys(Utils.getFulfillerConfig()).map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={this.state.fulfiller}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.setState({fulfiller: value})}
                            />
                        </div>
                    </div>
                }

                {
                    this.state.view === 'orders' &&
                    <div className='orders'>
                        <h4>Comenzi: {renderedOrdersNr}</h4>
                        {ordersItems}
                    </div>
                }

                {
                    this.state.view === 'emboss' &&
                    <div className='emboss'>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Filtru deadline:</div>
                            <Dropdown
                                options={[-1, 0, 1, 2, 3, 4, 5, 6, 7].map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i === -1 ? 'Toate' : i === 0 ? 'AZI' : i === 1 ? 'Maine' : `peste ${i} zile`,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={this.state.embossTimeFilter}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.onEmbossTimeFilterChanged(value)}
                            />
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Filtru template:</div>
                            <Dropdown
                                options={[-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i === -1 ? 'Toate' : i === 0 ? 'Fara template' : `#${i}`,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={this.state.embossTemplateFilter}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.onEmbossTemplateFilterChanged(value)}
                            />
                        </div>

                        {/*<div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>*/}
                        {/*    <div style={{fontWeight: 'bold', marginRight: '10px'}}>Status coperta:</div>*/}
                        {/*    <Dropdown*/}
                        {/*        options={['Gata', 'Toate'].map(i => {*/}
                        {/*            return {*/}
                        {/*                key: i,*/}
                        {/*                value: i,*/}
                        {/*                text: i,*/}
                        {/*                id: i*/}
                        {/*            }*/}
                        {/*        })}*/}
                        {/*        selection compact*/}
                        {/*        value={this.state.embossCoverStatusFilter}*/}
                        {/*        style={{minWidth: '200px'}}*/}
                        {/*        onChange={(e, {name, value}) => this.onCoverStatusChanged(value)}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>Total coperti de
                            stantat: {notEmbossed}</div>
                        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>Total coperti stantate: {embossed}</div>
                        {embossElements}
                    </div>
                }

                {
                    this.state.view === 'block' &&
                    <div className='orders'>
                        {blockElement}
                    </div>
                }


            </div>
        )
            ;
    }

    getBlockNeedsElement = () => {
        let dataset = [...this.state.neededBlocks.filtered];
        dataset.forEach(item=>{
            item.percentSales = parseFloat(((item.amt / this.state.neededBlocks.total) * 100).toFixed(2));
        })

        dataset.sort((a,b)=>b.percentSales - a.percentSales);

        return (
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Marime</Table.HeaderCell>

                        <Table.HeaderCell>Spreaduri</Table.HeaderCell>
                        {/*<Table.HeaderCell>Sales%</Table.HeaderCell>*/}

                        <Table.HeaderCell
                        >Necesar</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {dataset.map(item => {
                        let percentSales = ((item.amt / this.state.neededBlocks.total) * 100).toFixed(2);
                        let percentInStock = ((item.inStock / this.state.neededBlocks.totalInStock) * 100).toFixed(2);
                        let needed = (item.coef * (item.amt / 500)).toFixed(3);

                        let statusStyle = {
                            width: '200px',
                            height: '14px',
                            backgroundColor: '#7eb0b4'
                        }

                        let stockStyle = {
                            width: '200px',
                            height: '14px',
                            backgroundColor: 'black'
                        }


                        let perc = (parseFloat(item.inStock) / parseFloat(needed)) * 200;
                        let mainPerc = perc;

                        if (perc > 200) {
                            perc = (parseFloat(needed) / parseFloat(item.inStock)) * 200;
                            stockStyle.width = `${perc}px`
                        } else {
                            statusStyle.width = `${perc}px`
                        }

                        let keyRender = item.key.split(':');

                        let mustBuild = Math.ceil(needed - item.inStock);

                        if(mustBuild <=0){
                            return null;
                        }

                        return (
                            <Table.Row key={item.key}>
                                <Table.Cell>{keyRender[0]}</Table.Cell>
                                <Table.Cell>{keyRender[1]/2}</Table.Cell>
                                {/*<Table.Cell>{item.percentSales}</Table.Cell>*/}
                                <Table.Cell>{mustBuild}</Table.Cell>
                            </Table.Row>
                        )
                    })}

                </Table.Body>

            </Table>
        )
    }

    getBlockElement = () => {
        let orders = [...this.state.orders];
        let guestbooksSpacers = {};
        let guestbooksNoSpacers = {};

        let guestbooksBlackSpacers = {};
        let guestbooksBlackNoSpacers = {};

        let coverSpacers = {};
        let coverNoSpacers = {};

        const shouldFilterEnd = this.state.filterEnd !== null;
        const shouldFilterBoth = this.state.filterEnd !== null && this.state.filterStart !== null

        let formattedStartDate = null;
        let formattedEndDate = null;

        if (shouldFilterEnd) {
            let date = new Date(this.state.filterEnd);
            let userTimezoneOffset = date.getTimezoneOffset() * 60000;
            formattedEndDate = new Date(date.getTime() - userTimezoneOffset);

            formattedEndDate = formattedEndDate.toISOString().split('T')[0].split('-');
            formattedEndDate = `${formattedEndDate[0]}-${formattedEndDate[1]}-${formattedEndDate[2]}`;
        }

        if (shouldFilterBoth) {
            let date = new Date(this.state.filterStart);
            let userTimezoneOffset = date.getTimezoneOffset() * 60000;
            formattedStartDate = new Date(date.getTime() - userTimezoneOffset);

            formattedStartDate = formattedStartDate.toISOString().split('T')[0].split('-');
            formattedStartDate = `${formattedStartDate[0]}-${formattedStartDate[1]}-${formattedStartDate[2]}`;
        }

        if (shouldFilterBoth) {
            orders = orders.filter(o => (o.production_deadline >= formattedStartDate && o.production_deadline <= formattedEndDate) || o.status === 'urgent')
        }

        if (shouldFilterEnd) {
            orders = orders.filter(o => o.production_deadline <= formattedEndDate || o.status === 'urgent')
        }

        orders.forEach(order => {
            order.work_items.sort((a, b) => {
                return a.id - b.id;
            });

            order.work_items.forEach((item, index) => {
                if ((item.product_type === "guestbook" || item.product_type === 'boudoir-empty') && item.progress.bloc === 0 && item.item.page_type.label === 'white') {
                    let size = item.item.size.value;
                    let pages = parseInt(item.item.nr_pages) / 2;
                    let hasSpacer = item.item.page_spacer === "yes";
                    let quantity = item.quantity;

                    let key = `${size}-${pages}`;

                    if (hasSpacer) {
                        if (!guestbooksSpacers[key]) {
                            guestbooksSpacers[key] = [];
                        }
                        for (let i = 0; i < quantity; i++) {
                            guestbooksSpacers[key].push(item);
                        }
                    } else {
                        if (!guestbooksNoSpacers[key]) {
                            guestbooksNoSpacers[key] = [];
                        }
                        for (let i = 0; i < quantity; i++) {
                            guestbooksNoSpacers[key].push(item);
                        }
                    }
                }

                if ((item.product_type === "guestbook" || item.product_type === 'boudoir-empty') && item.progress.bloc === 0 && item.item.page_type.label === 'black') {
                    let size = item.item.size.value;
                    let pages = parseInt(item.item.nr_pages) / 2;
                    let hasSpacer = item.item.page_spacer === "yes";
                    let quantity = item.quantity;

                    let key = `${size}-${pages}`;

                    if (hasSpacer) {
                        if (!guestbooksBlackSpacers[key]) {
                            guestbooksBlackSpacers[key] = [];
                        }
                        for (let i = 0; i < quantity; i++) {
                            guestbooksBlackSpacers[key].push(item);
                        }
                    } else {
                        if (!guestbooksBlackNoSpacers[key]) {
                            guestbooksBlackNoSpacers[key] = [];
                        }
                        for (let i = 0; i < quantity; i++) {
                            guestbooksBlackNoSpacers[key].push(item);
                        }
                    }
                }


                if ((item.product_type === "guestbook" || item.product_type === 'boudoir-empty') && item.progress.coperta === 0) {
                    let size = item.item.size.value;
                    let pages = parseInt(item.item.nr_pages) / 2;
                    let hasSpacer = item.item.page_spacer === "yes";
                    let coverMaterial = item.item.material.value;
                    let quantity = item.quantity;
                    let materialId = item.item.material.id;
                    let materialCode = this.state.configs.materials.find(mat => mat.id === materialId).internal_code;

                    let coverKey = `${coverMaterial}@${size}@${pages}@${materialCode}`;

                    if (hasSpacer) {
                        if (!coverSpacers[coverKey]) {
                            coverSpacers[coverKey] = [];
                        }
                        for (let i = 0; i < quantity; i++) {
                            coverSpacers[coverKey].push(item);
                        }
                    } else {
                        if (!coverNoSpacers[coverKey]) {
                            coverNoSpacers[coverKey] = [];
                        }
                        for (let i = 0; i < quantity; i++) {
                            coverNoSpacers[coverKey].push(item);
                        }
                    }
                }
            })
        });

        const withSpacersRows = this.getGuestbookTableRows(guestbooksSpacers, true);
        const withoutSpacersRows = this.getGuestbookTableRows(guestbooksNoSpacers, false);

        const withSpacersBlackRows = this.getGuestbookTableRows(guestbooksBlackSpacers, true);
        const withoutSpacersBlackRows = this.getGuestbookTableRows(guestbooksBlackNoSpacers, true);

        const coversWithSpacersRows = this.getCoverTableRows(coverSpacers, true);
        const coversWithoutSpacersRows = this.getCoverTableRows(coverNoSpacers, false);

        const guestbookSpacersTotal = this.getCoverTableTotalRow(guestbooksSpacers);
        const withoutSpacersTotal = this.getCoverTableTotalRow(guestbooksNoSpacers);

        const coversWithSpacersTotal = this.getCoverTableTotalRow(coverSpacers, true);
        const coversWithoutSpacersTotal = this.getCoverTableTotalRow(coverNoSpacers, true);


        const withSpacersTable = (
            <div style={{marginRight: '10px'}}>
                <h3>Blocuri cu rezerva</h3>
                <Table collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Bucati</Table.HeaderCell>
                            <Table.HeaderCell>Marime</Table.HeaderCell>
                            <Table.HeaderCell>Spreads</Table.HeaderCell>
                            <Table.HeaderCell>Cod produs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row warning>
                            <Table.Cell><h5>CARTON ALB</h5></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        {withSpacersRows}
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        <Table.Row warning>
                            <Table.Cell><h5>CARTON NEGRU</h5></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        {withSpacersBlackRows}
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        {guestbookSpacersTotal}
                    </Table.Body>
                </Table>
            </div>
        )

        const withoutSpacersTable = (
            <div style={{marginRight: '10px'}}>
                <h3>Blocuri fara rezerva</h3>
                <Table collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Bucati</Table.HeaderCell>
                            <Table.HeaderCell>Marime</Table.HeaderCell>
                            <Table.HeaderCell>Spreads</Table.HeaderCell>
                            <Table.HeaderCell>Cod produs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row warning>
                            <Table.Cell><h5>CARTON ALB</h5></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        {withoutSpacersRows}
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        <Table.Row warning>
                            <Table.Cell><h5>CARTON NEGRU</h5></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        {withoutSpacersBlackRows}
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        {withoutSpacersTotal}
                    </Table.Body>
                </Table>
            </div>
        )

        const coversWithSpacersTable = (
            <div style={{marginRight: '10px'}}>
                <h3>Coperti cu rezerva</h3>
                <Table collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Bucati</Table.HeaderCell>
                            <Table.HeaderCell>Material</Table.HeaderCell>
                            <Table.HeaderCell>Cod material</Table.HeaderCell>
                            <Table.HeaderCell>Marime</Table.HeaderCell>
                            <Table.HeaderCell>Spreads</Table.HeaderCell>
                            <Table.HeaderCell>Cod produs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {coversWithSpacersRows}
                        {coversWithSpacersTotal}
                    </Table.Body>
                </Table>
            </div>
        )

        const coversWithoutSpacersTable = (
            <div style={{marginRight: '10px'}}>
                <h3>Coperti fara rezerva</h3>
                <Table collapsing>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Bucati</Table.HeaderCell>
                            <Table.HeaderCell>Material</Table.HeaderCell>
                            <Table.HeaderCell>Cod material</Table.HeaderCell>
                            <Table.HeaderCell>Marime</Table.HeaderCell>
                            <Table.HeaderCell>Spreads</Table.HeaderCell>
                            <Table.HeaderCell>Cod produs</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {coversWithoutSpacersRows}
                        {coversWithoutSpacersTotal}
                    </Table.Body>
                </Table>
            </div>
        )

        return (
            <div>
                <DatePicker
                    style={{marginLeft: '5px'}}
                    dateFormat="dd-MM-yyy"
                    selected={this.state.filterStart}
                    onChange={date => {
                        this.setState({filterStart: date})
                    }}
                />
                <DatePicker
                    style={{marginLeft: '5px'}}
                    dateFormat="dd-MM-yyy"
                    selected={this.state.filterEnd}
                    onChange={date => {
                        this.setState({filterEnd: date})
                    }}
                />
                <Button compact basic size='small' onClick={() => this.setState({
                    filterStart: null,
                    filterEnd: null
                })}>reset</Button>
                <div style={{display: 'flex', marginTop: '50px'}}>
                    {withSpacersTable}
                    {withoutSpacersTable}
                    {coversWithSpacersTable}
                    {coversWithoutSpacersTable}
                </div>
            </div>
        )
    }

    getCoverTableTotalRow = (data, isCover) => {
        let total = 0;

        for (let key in data) {
            let amount = data[key].length;

            total += parseInt(amount);
        }

        return (
            <Table.Row positive>
                <Table.Cell>{total}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                {isCover &&
                    <Table.Cell></Table.Cell>
                }
                {isCover &&
                    <Table.Cell></Table.Cell>
                }
            </Table.Row>
        )
    }

    getGuestbookTableRows = (data, withSpacers) => {
        let ret = [];

        let ordered = Object.keys(data).sort().reduce(
            (obj, key) => {
                obj[key] = data[key];
                return obj;
            },
            {}
        );

        for (let key in ordered) {
            let size = key.split('-')[0];
            let pages = key.split('-')[1];
            let amount = data[key].length;

            let itemCodes = ordered[key].map(item => {
                return workItemSKU(item) + " ";
            });

            ret.push(
                <Table.Row>
                    <Table.Cell>{amount}</Table.Cell>
                    <Table.Cell>{size}</Table.Cell>
                    <Table.Cell>{pages}</Table.Cell>
                    <Table.Cell style={{whiteSpace: 'pre-wrap'}}>{itemCodes}</Table.Cell>
                </Table.Row>
            )
        }

        return ret;
    }

    getCoverTableRows = (data, withSpacers) => {
        let ret = [];

        let ordered = Object.keys(data).sort().reduce(
            (obj, key) => {
                obj[key] = data[key];
                return obj;
            },
            {}
        );

        for (let key in ordered) {
            let size = key.split('@')[1];
            let pages = key.split('@')[2];
            let material = key.split('@')[0];
            let materialCode = key.split('@')[3];
            let amount = data[key].length;

            let itemCodes = ordered[key].map(item => {
                return workItemSKU(item);
            });

            ret.push(
                <Table.Row>
                    <Table.Cell>{amount}</Table.Cell>
                    <Table.Cell>{material}</Table.Cell>
                    <Table.Cell>{materialCode}</Table.Cell>
                    <Table.Cell>{size}</Table.Cell>
                    <Table.Cell>{pages}</Table.Cell>
                    <Table.Cell style={{whiteSpace: 'pre-wrap'}}>{itemCodes}</Table.Cell>
                </Table.Row>
            )
        }

        return ret;
    }

    getEmbossOrdersElement = () => {
        let ordersEmbossable = [];
        let embossed = 0;
        let notEmbossed = 0;

        let orders = [...this.state.orders];

        orders.forEach(order => {
            order.work_items.sort((a, b) => {
                return a.id - b.id;
            });

            order.work_items.forEach((item, index) => {
                let embossStatus = item.item.emboss;
                item.mainOrderId = order.id;
                if (order.suborder_of) {
                    item.mainOrderId = order.suborder_of;
                }
                if (embossStatus && embossStatus.label && embossStatus.label !== 'no') {
                    item.itemIndex = index + 1;

                    item.deadline = parseInt(order.production_deadline?.split('-').join(''));
                    if (order.status === 'urgent') {
                        item.deadline = 0;
                    }
                    if (isNaN(item.deadline)) {
                        item.deadline = Infinity
                    }

                    ordersEmbossable.push(item);

                    // if(this.state.embossCoverStatusFilter === 'Gata') {
                    //     if (item.progress?.coperta && item.progress?.stantat === 0) {
                    //         ordersEmbossable.push(item);
                    //     }
                    // } else {
                    //     ordersEmbossable.push(item);
                    // }
                }
            })
        });

        let embossable = ordersEmbossable;

        embossable.forEach(item => {
            if (!item.item.embossTemplate) {
                item.item.embossTemplate = {id: 0, name: ''};
            }
        })

        if (this.state.embossTimeFilter > -1) {
            let now = new Date();
            now.setDate(now.getDate() + this.state.embossTimeFilter);
            let deadlineItem = parseInt(now.toISOString().split('T')[0].split('-').join(''));

            embossable = embossable.filter(i => i.deadline <= deadlineItem);
        }

        if (this.state.embossTemplateFilter > -1) {
            embossable = embossable.filter(i => i.item.embossTemplate.id === this.state.embossTemplateFilter);
        }

        embossable = embossable.sort((a, b) => {
            return a.item.embossTemplate.id - b.item.embossTemplate.id;
        })


        if (this.state.filter) {
            let isIdValid = Validate(this.state.filter.toUpperCase());
            if (isIdValid) {
                let extractedId = Extract(this.state.filter.toUpperCase());
                embossable = embossable.filter(o => o.mainOrderId.toString() === extractedId.toString())
            }
        }

        embossable.forEach(item => {
            if (item.progress?.stantat === 0) {
                notEmbossed += 1;
            } else {
                embossed += 1;
            }
        })

        const embossElements = embossable.map((item, index) => {
            return (
                <EmbossWorkItem key={Math.random()} data={item} index={item.itemIndex} configs={this.state.configs}
                                onEmbossStatusToggled={this.onEmbossStatusToggled}/>
            )
        });

        return {embossed, notEmbossed, embossElements};

    };

    onFilterChanged = (e, {value}) => {
        this.setState({filter: value})
    };

    getGraphElement = () => {
        let orders = [...this.state.orders];
        if (!orders.length) {
            return null;
        }

        let data = [];

        let now = new Date();
        for (let i = 0; i < 15; i++) {
            let lab = parseInt(now.toISOString().split('T')[0].split('-').join(''));
            let dayLabel = ['Duminca', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'];
            if (now.getDay() !== 0 && now.getDay() !== 6) {
                data.push({label: lab, dow: dayLabel[now.getDay()], album: 0, guestbook: 0, boudoir: 0});
            }
            now.setDate(now.getDate() + 1);
        }

        orders.forEach(order => {
            order.work_items.forEach(wi => {
                let deadline = order.production_deadline_sort;

                if (wi.product_type === 'guestbook') {
                    if (deadline === 0) {
                        data[0].guestbook += wi.quantity;
                    } else {
                        let item = data.find(i => i.label === deadline);
                        if (item) {
                            item.guestbook += wi.quantity;
                        }
                    }
                }
                if (wi.product_type === 'boudoir' || wi.product_type === 'boudoir-empty') {
                    if (deadline === 0) {
                        data[0].boudoir += wi.quantity;
                    } else {
                        let item = data.find(i => i.label === deadline);
                        if (item) {
                            item.boudoir += wi.quantity;
                        }
                    }
                }
                if (wi.product_type === 'album' || wi.product_type === 'album') {
                    if (deadline === 0) {
                        data[0].album += wi.quantity;
                    } else {
                        let item = data.find(i => i.label === deadline);
                        if (item) {
                            item.album += wi.quantity;
                        }
                    }
                }
            })
        })

        const CustomTooltip = ({active, payload, label}) => {
            const data = payload[0]?.payload;
            if (data && active && payload && payload.length) {
                let total = data.album + data.guestbook + data.boudoir;
                let date = data.label.toString().split('');
                let month = parseInt(`${date[4]}${date[5]}`)
                let day = parseInt(`${date[6]}${date[7]}`);

                const monthNames = ["December", "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November"
                ];

                return (
                    <div className="custom-tooltip">
                        <p className="label">{`${label} ${day} ${monthNames[month]}`}</p>
                        <p className="total">Total: {total}</p>
                        <p className="item">Guestbook: {data.guestbook}</p>
                        <p className="item">Boudoir: {data.boudoir}</p>
                        <p className="item">Album: {data.album}</p>
                    </div>
                );
            }

            return null;
        };

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="dow"/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend/>
                    <Bar dataKey="guestbook" stackId="a" fill="#82ca9d"/>
                    <Bar dataKey="boudoir" stackId="a" fill="#8884d8"/>
                    <Bar dataKey="album" stackId="a" fill="#ffc658"/>
                </BarChart>
            </ResponsiveContainer>
        )
    };

    getGraphElementShipped = () => {
        let items = [...this.state.shippings];
        if (!items.length) {
            return null;
        }

        let data = [];

        let now = new Date();
        for (let i = 0; i < 30; i++) {
            let lab = parseInt(now.toISOString().split('T')[0].split('-').join(''));
            let dayLabel = ['Duminca', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'];
            if (now.getDay() !== 0 && now.getDay() !== 6) {
                data.push({label: lab, dow: dayLabel[now.getDay()], album: 0, guestbook: 0, boudoir: 0});
            }
            now.setDate(now.getDate() - 1);
        }

        data = data.reverse();

        items.forEach(wi => {
            const date = parseInt(wi.created_at.split("T")[0].split("-").join(''));

            if (wi.product_type === 'guestbook') {
                let item = data.find(i => i.label === date);
                if (item) {
                    item.guestbook += wi.quantity;
                }
            }
            if (wi.product_type === 'boudoir' || wi.product_type === 'boudoir-empty') {
                let item = data.find(i => i.label === date);
                if (item) {
                    item.boudoir += wi.quantity;
                }
            }
            if (wi.product_type === 'album' || wi.product_type === 'empty-album') {
                let item = data.find(i => i.label === date);
                if (item) {
                    item.album += wi.quantity;
                }
            }
        })

        const CustomTooltip = ({active, payload, label}) => {
            const data = payload[0]?.payload;
            if (data && active && payload && payload.length) {
                let total = data.album + data.guestbook + data.boudoir;
                let date = data.label.toString().split('');
                let month = parseInt(`${date[4]}${date[5]}`)
                let day = parseInt(`${date[6]}${date[7]}`);

                const monthNames = ["December", "January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November"
                ];

                return (
                    <div className="custom-tooltip">
                        <p className="label">{`${label} ${day} ${monthNames[month]}`}</p>
                        <p className="total">Total: {total}</p>
                        <p className="item">Guestbook: {data.guestbook}</p>
                        <p className="item">Boudoir: {data.boudoir}</p>
                        <p className="item">Album: {data.album}</p>
                    </div>
                );
            }

            return null;
        };


        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="dow"/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend/>
                    <Bar dataKey="guestbook" stackId="a" fill="#82ca9d"/>
                    <Bar dataKey="boudoir" stackId="a" fill="#8884d8"/>
                    <Bar dataKey="album" stackId="a" fill="#ffc658"/>
                </BarChart>
            </ResponsiveContainer>
        )
    };

    getOrderItemsElement = (data) => {
        if (!data || data.length === 0) {
            return null;
        }

        let allWorkItemsOrdered = [];
        let stockItems = [];
        let stockItemsFixed = [];

        const suborderLookup = [...data];

        data.forEach(order => {
            if (!order.production_deadline) {
                order.production_deadline_sort = Infinity;
            } else {
                order.production_deadline_sort = parseInt(order.production_deadline.split('-').join(''));
            }

            if (order.status === 'urgent') {
                order.production_deadline_sort = 0;
            }
        })

        data = data.sort((a, b) => {
            return a.production_deadline_sort - b.production_deadline_sort
        })

        data.forEach(order => {
            order.wi = [];
        })

        data.forEach(order => {
            if (order.suborder_of) {
                let parentOrder = data.find(o => o.id === order.suborder_of);
                parentOrder.wi = [...parentOrder.wi, ...order.work_items]
            } else {
                order.wi = [...order.wi, ...order.work_items]
            }
        })

        data.forEach(item => {
            item.covers = [];
        })

        data.forEach(item => {
            item.work_items.forEach(wi => {
                console.log(wi)
                const coverStyle = wi.item.cover_style;
                if (coverStyle) {
                    let coverName = coverStyle.text;

                    if (item.suborder_of) {
                        let o = data.find(ord => ord.id === item.suborder_of);
                        o.covers.push(coverName);
                    } else {
                        item.covers.push(coverName);
                    }

                }
            })
        })

        const orderRenderer = (order) => {
            let external = null;
            if (!order) {
                return null
            }
            if (this.state.external && this.state.external[order.id]) {
                external = this.state.external[order.id]
            }

            return (
                <WorkItem key={order.id} data={order} external={external}
                          configs={this.state.configs}
                          updateData={this.forceUpdateData} lockSync={this.lockSync} unlockSync={this.unlockSync}/>
            )
        };

        if (this.state.manufactureDeadline !== -1) {
            let now = new Date();
            now.setDate(now.getDate() + this.state.manufactureDeadline);
            now = parseInt(now.toISOString().split('T')[0].split('-').join(''));
            data = data.filter(item => item.production_deadline_sort <= now);
        }

        if (this.state.manufactureProductType !== 'Toate') {
            switch (this.state.manufactureProductType) {
                case 'Guestbook':
                    data = data.filter(o => o.isGuestbook === 1);
                    break;
                case 'Album':
                    data = data.filter(o => o.isAlbum === 1);
                    break;
                case 'Boudoir':
                    data = data.filter(o => o.isBoudoir === 1);
                    break;
                case 'Classic Album':
                    data = data.filter(o => o.isClassicAlbum === 1);
                    break;
                case 'Guestbook Slots':
                    data = data.filter(o => o.isGuestbookSlots === 1);
                    break;
            }
        }

        if (this.state.manufactureCover !== 'Toate') {
            data = data.filter(o => o.covers.indexOf(this.state.manufactureCover) !== -1);
        }

        if(this.state.fulfiller){
            data = data.filter(o => o.fulfiller === this.state.fulfiller);
        }

        if (this.state.filter) {
            let isIdValid = Validate(this.state.filter.toUpperCase());
            if (isIdValid) {
                let extractedId = Extract(this.state.filter.toUpperCase());
                data = data.filter(o =>
                    o.id.toString().search(extractedId) > -1)
            }
        }

        let groups = data.filter(o => o.suborder_of === null);

        return groups.map(order => {
            let linkedOrders = [];

            if (order.linked_orders) {
                linkedOrders = order.linked_orders.map(id => suborderLookup.find(or => or.id === id))
            }

            linkedOrders = linkedOrders.map(item => orderRenderer(item));


            return (
                <div key={order.id} style={{borderLeft: '10px solid black', borderRight: '10px solid black'}}>
                    {orderRenderer(order)}
                    {linkedOrders}
                </div>
            )
        });


        return data.map(order => {
            let external = null;
            if (this.state.external && this.state.external[order.id]) {
                external = this.state.external[order.id]
            }

            return (
                <WorkItem key={order.id} stocks={this.state.stockItems} data={order} external={external}
                          configs={this.state.configs}
                          updateData={this.forceUpdateData} lockSync={this.lockSync} unlockSync={this.unlockSync}/>
            )
        })
    };

    onCheckboxChanged = (e, {name, checked}) => {
        this.setState({showCompleted: checked})
    };

    updateData = async () => {
        if (this.state.syncLock) {

            if (this.refreshTimer) {
                clearTimeout(this.refreshTimer);
                this.refreshTimer = null;
            }
            return;
        }

        this.setState({loading: true}, async () => {
            let stockItems = await ApiService.stocksGetLight('apertura');
            const shippings = await ApiService.getExpeditionsLast15Days();

            stockItems = stockItems.data;

            const stockClone = [...stockItems];
            this.stockItems = [];
            this.stockItemsFixed = [];

            stockClone.forEach(item => {
                this.stockItems.push({...item});
                this.stockItemsFixed.push({...item});
            })

            ApiService.getWorkOrders().then(data => {
                this.setState({
                    orders: [],
                    display: [],
                    external: null,
                    stockItems: [],
                    shippings: []
                }, () => {
                    this.setState({
                        loading: false,
                        lastUpdate: new Date().getTime(),
                        orders: data.data.orders,
                        display: data.data.orders,
                        external: data.data.external,
                        stockItems: stockItems,
                        shippings: shippings.data
                    }, () => {
                        if (this.refreshTimer) {
                            clearTimeout(this.refreshTimer);
                        }
                        this.refreshTimer = setTimeout(() => {
                            this.updateData();
                        }, 1000 * 60 * 1)
                    });
                })
            });
        })
    };

    forceUpdateData = async () => {
        this.setState({loading: true}, async () => {
            let stockItems = await ApiService.stocksGet();
            stockItems = stockItems.data;

            const shippings = await ApiService.getExpeditionsLast15Days();

            ApiService.getWorkOrders().then(data => {
                this.setState({
                    orders: [],
                    display: [],
                    external: null,
                    stockItems: [],
                    shippings: []
                }, () => {
                    this.setState({
                        loading: false,
                        lastUpdate: new Date().getTime(),
                        orders: data.data.orders,
                        display: data.data.orders,
                        external: data.data.external,
                        stockItems: stockItems,
                        shippings: shippings.data
                    });
                })

            });
        })

    };

    downloadCSV = () => {
        let orders = [...this.state.orders];
        let workItems = [];

        let renderableType = ['album', 'guestbook', 'boudoir', 'boudoir-empty']

        orders.forEach(order => {
            order.work_items.forEach(item => {
                if (renderableType.indexOf(item.product_type) > -1) {
                    workItems.push(item);
                }
            })
        })

        let tsv = 'Item ID\tItemType\tSize\tPages\tCover Material\tEmboss\tUV Print\tLine 1\tLine 2\tLine 3\n';

        workItems.forEach(item => {
            let isEmboss = item.item.emboss?.label !== 'no';
            isEmboss = isEmboss ? 'yes' : 'no';

            tsv += `${workItemSKU(item)}\t${item.product_type}\t${item.item.size?.value}\t${item.item.nr_pages}\t${item.item.material?.value}\t${isEmboss}\t${item.item.cover_uv}\t${item.item.emboss_l1_text}\t${item.item.emboss_l2_text}\t${item.item.emboss_l3_text}\n`
        })

        let now = new Date()

        this.download(tsv, `production-${now.toString()}.tsv`, 'text/tab-separated-values')
    }

    download = (data, filename, type) => {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    onEmbossStatusToggled = (newProgress) => {
        ApiService.getWorkItemById(newProgress.id).then(async data => {
            let item = data.data;

            item.progress[newProgress.step] = newProgress.value;
            await ApiService.updateWorkItemStatus(item);
            this.forceUpdateData();
        })
    }

    lockSync = () => {
        this.setState({syncLock: true})
    }

    unlockSync = () => {
        this.setState({syncLock: false}, () => {
            this.updateData()
        })
    }

    onEmbossTimeFilterChanged = (value) => {
        this.setState({embossTimeFilter: value})
    }

    onEmbossTemplateFilterChanged = (value) => {
        this.setState({embossTemplateFilter: value})
    }

    onCoverStatusChanged = (value) => {
        this.setState({embossCoverStatusFilter: value})
    }
}


export default ManufactureScreen;
