import React, {Component} from 'react';
import {Button, Checkbox, Container, Dropdown, Image, Input, Item, Label} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class WebsiteConfigStartupGuestbook extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            expanded: false
        }
    }

    componentDidMount() {
        ApiService.getWebsiteConfig(this.props.configKey).then(data => {
            ApiService.loadProductConfig().then(configs => {
                configs = configs.data;

                let config = {};

                config.sizes = configs.sizes.filter(c => c.type === 'guestbook' && c.available === 1);
                config.materials = configs.materials.filter(c => c.type === 'guestbook' && c.available === 1);
                config.foils = configs.foils.filter(c => c.type === 'guestbook' && c.available === 1);
                config.personalisations = configs.personalisations;
                config.embossTemplates = configs.embossTemplates.filter(c => c.available === 1).map(item => {
                    item.meta = JSON.parse(item.meta);
                    return item;
                })
                config.uvTemplates = configs.uvTemplates.filter(c => c.available === 1).map(item => {
                    item.meta = JSON.parse(item.meta);
                    return item;
                })

                config.embossTextPositions = configs.embossTextPositions;
                config.embossTextAlignments = configs.embossTextAlignments;


                this.setState({data: data.data, config: config})
            })

        })
    }

    render() {
        if (!this.state.config) {
            return null;
        }

        const embossLines = this.getLinesForEmbossTemplate();

        return (
            <div style={{marginTop: '50px'}}>
                <h3>{this.props.name}</h3>

                {this.state.expanded &&
                <div style={{display: 'flex'}}>
                    <div>
                        <div>Size</div>
                        <Dropdown
                            options={this.state.config.sizes.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.cm,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.size}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, size: value}
                                }
                            })}
                        />
                    </div>

                    <div>
                        <div>Material</div>
                        <Dropdown
                            options={this.state.config.materials.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.material}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, material: value}
                                }
                            })}
                        />
                    </div>

                    <div>
                        <div>Personalisation</div>
                        <Dropdown
                            options={this.state.config.personalisations.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.personalisation}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, personalisation: value}
                                }
                            })}
                        />
                    </div>

                    {this.state.data.data.personalisation === 2 &&
                    <div>
                        <div>Emboss Template</div>
                        <Dropdown
                            options={this.state.config.embossTemplates.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.embossTemplate}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, embossTemplate: value}
                                }
                            })}
                        />
                    </div>
                    }
                    {this.state.data.data.personalisation === 2 &&
                    <div>
                        <div>Emboss Foil</div>
                        <Dropdown
                            options={this.state.config.foils.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.embossFoil}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, embossFoil: value}
                                }
                            })}
                        />
                    </div>
                    }
                    {this.state.data.data.personalisation === 2 &&
                    <div>
                        <div>Text position</div>
                        <Dropdown
                            options={this.state.config.embossTextPositions.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.textPosition}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, textPosition: value}
                                }
                            })}
                        />
                    </div>
                    }
                    {this.state.data.data.personalisation === 2 &&
                    <div>
                        <div>Text alignment</div>
                        <Dropdown
                            options={this.state.config.embossTextAlignments.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.data.data.textAlignment}
                            onChange={(e, {name, value}) => this.setState({
                                data: {
                                    ...this.state.data,
                                    data: {...this.state.data.data, textAlignment: value}
                                }
                            })}
                        />
                    </div>
                    }
                </div>
                }

                {embossLines}

                {this.state.expanded &&
                <div>
                    <br/>
                    <br/>
                    <Button primary onClick={this.onSaveClicked}>save</Button>
                </div>
                }

                {this.state.expanded === false &&
                <Button primary onClick={() => this.setState({expanded: true})}>edit</Button>
                }

            </div>
        );
    }

    getLinesForEmbossTemplate = () => {
        if (!this.state.expanded) {
            return null;
        }

        if(this.state.data.data.personalisation !== 2){
            return null;
        }

        let templateId = this.state.data.data.embossTemplate;
        if (!templateId) {
            return null;
        }

        let template = this.state.config.embossTemplates.find(i => i.id === templateId);

        if (!template) {
            return null;
        }

        let numLines = template.meta.lines.length;
        if (!numLines || numLines === 0) {
            return null;
        }

        let returnItems = [];

        for (let i = 0; i < numLines; i++) {
            returnItems.push(
                <div key={`emboss-line-${i}`}>
                    <div>Line {i+1}</div>
                    <Input value={this.state.data.data[`embossL${i+1}`]} onChange={(e)=>this.setState({
                        data: {
                            ...this.state.data,
                            data: {...this.state.data.data, [`embossL${i+1}`]: e.target.value}
                        }
                    })}/>
                </div>

            )
        }

        return returnItems;
    }

    onSaveClicked = () => {
        ApiService.saveWebsiteConfig(this.state.data).then(data => {
            this.setState({expanded: false})
        });
    }

}


export default WebsiteConfigStartupGuestbook;
