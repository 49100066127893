import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container,} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class AlexScreen extends Component {

    render() {

        return (
            <Container style={{margin: '3rem 0'}}>
                <Button primary compact onClick={() => this.test()}>test</Button>
                <Button primary compact onClick={() => this.test2()}>Test2</Button>
                <Button primary compact onClick={() => this.test5()}>Fetch easypost</Button>
                <Button primary compact onClick={() => this.test6()}>Format easypost</Button>
            </Container>
    )
    }

    test = async () => {
        const a = await ApiService.dev_test();
        console.log(a)

    };

    test2 = async () => {
        const a = await ApiService.dev_test2();
        console.log(a)

    };

    test5 = async () => {
        const a = await ApiService.dev_test5();
        console.log(a)

    };
    test6 = async () => {
        const a = await ApiService.dev_test6();
        console.log(a)

    };


}


export default AlexScreen;
