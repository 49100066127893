import React, {Component} from 'react';
import './ConsumptionPanel.scss';

class ConsumptionAvailabilityPanel extends Component {

    render() {
        if (!this.props.data) {
            return null;
        }

        let data = null;
        try {
            data = JSON.parse(this.props.data);
        } catch (e) {
            data = null;
        }

        if(!data.neededSKUs){
            return null;
        }

        data = data.neededSKUs;

        if (!data) {
            return null;
        }

        let itemsToRender = null;
        switch (this.props.type) {
            case 'classic-album':
            case 'boudoir-empty':
            case 'boudoir':
            case 'vow-book':
            case 'album':
            case 'guestbook':
            case 'guestbook-slots':
                itemsToRender = this.getBookConsumption(data);
                break;
            case 'pen-two':
            case 'pen-three':
            case 'pen-three-same':
            case 'corners':
            case 'marker':
            case 'pen':
                itemsToRender = this.getItemConsumption(data);
                break;
        }

        return (
            <div className='ConsumptionPanel'>
                <h4>Available in stoc:</h4>
                {itemsToRender}
            </div>
        );
    }

    getBookConsumption = (data) => {
        return (
            <div>
                <div className="consumptionItem">
                    <>
                        <div className="title">Bloc:</div>
                        <div className="consumed">
                            {data.bloc?.map(c => {
                                const notEnough = c.needed > c.available;
                                const className = notEnough ? 'notInStoc' : 'inStoc';

                                return (
                                    <div className={className}>{c.available} of {c.needed} {c.sku}</div>
                                )
                            })}
                        </div>
                    </>
                </div>

                <div className="consumptionItem">
                    <>
                        <div className="title">Cover:</div>
                        <div className="consumed">
                            {data.cover?.map(c => {
                                const notEnough = c.needed > c.available;
                                const className = notEnough ? 'notInStoc' : 'inStoc';

                                return (
                                    <div className={className}>{c.available} of {c.needed} {c.sku}</div>
                                )
                            })}
                        </div>
                    </>
                </div>

                {data.cover?.fulfilled !== true &&
                <div className="consumptionItem">
                    <>
                        <div className="title">Material:</div>
                        <div className="consumed">
                            {data.material?.map(c => {
                                console.log(data.material)
                                const notEnough = c.needed > c.available;
                                const className = notEnough ? 'notInStoc' : 'inStoc';

                                return (
                                    <div className={className}>{c.available} of {c.needed} {c.sku}</div>
                                )
                            })}
                        </div>
                    </>
                </div>
                }
            </div>
        )
    }

    getItemConsumption = (data) => {
        return (
            <div>
                <div className="consumptionItem">
                    <>
                        <div className="title">Item:</div>
                        <div className="consumed">
                            {data.item?.map(c => {
                                const notEnough = c.needed > c.available;
                                const className = notEnough ? 'notInStoc' : 'inStoc';

                                return (
                                    <div className={className}>{c.available} of {c.needed} {c.sku}</div>
                                )
                            })}
                        </div>
                    </>
                </div>
            </div>
        )
    }

}


export default ConsumptionAvailabilityPanel;
