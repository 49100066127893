import React, {useEffect, useState} from 'react';
import {Checkbox, Dropdown, Input} from "semantic-ui-react";
import './ProductPersonalisation.scss'
import {config} from "../../config/config";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ApiService from "../../services/ApiService";

export default function ProductPersonalisation({
                                                   item,
                                                   configs,
                                                   onItemFieldChanged,
                                                   onEmbossTemplateChanged,
                                                   onImageUploaded
                                               }) {
    const [mock, setMock] = useState(item.cover_uv_image);
    const [mockUploading, setMockUploading] = useState(false);
    const [largeImagePreview, setLargeImagePreview] = useState(false);

    const fileInputRef = React.createRef();

    const onImageSelected = (e) => {
        const data = new FormData();

        setMockUploading(true);

        data.append('file', e.target.files[0]);

        ApiService.uploadImage('uv-packing-check', data).then(r => {
            const image = r.data.image;
            setMock(image);

            onImageUploaded(image);
        }).then(data => {
            setMockUploading(false);
        });
    }

    const getFontSizes = (fontName) => {
        if (!fontName) {
            return [];
        }

        let font = configs.fonts.find(f => f.name === fontName);
        if (!font) {
            return [];
        }

        if (!font.sizes) {
            return []
        }

        return font.sizes.split(' ').map(size => {
            return {
                key: size, text: `${size}pt`, value: size
            }
        })
    }

    const fontSizesL1 = getFontSizes(item.emboss_l1_font?.label);
    const fontSizesL2 = getFontSizes(item.emboss_l2_font?.label);
    const fontSizesL3 = getFontSizes(item.emboss_l3_font?.label);
    const fontSizesL4 = getFontSizes(item.emboss_l4_font?.label);
    const fontSizesL5 = getFontSizes(item.emboss_l5_font?.label);


    const onUvPrintCheckboxToggled = (e, {checked}) => {
        onItemFieldChanged('cover_uv', checked ? 'yes' : 'no')
    }

    const onEmbossCheckboxToggled = (e, {checked}) => {
        onItemFieldChanged('emboss.label', checked ? 'gold' : 'no')
    }

    const onFolioCheckboxToggled = (e, {checked}) => {
        onItemFieldChanged('folio_color', checked ? 'gold' : null)
    }

    const onLaserCheckboxToggled = (e, {checked}) => {
        onItemFieldChanged('laser_engraving', checked ? true : null)
    }

    const uvPrintToggle = item.cover_uv === 'no' ? false : true;
    const embossToggle = item.emboss.label === 'no' ? false : true;
    const folioToggle = item.folio_color  ? true : false;
    const laserToggle = item.laser_engraving  ? true : false;

    return (
        <div className="ProductPersonalisation">
            <h5>Personalisation</h5>
            <div>
                <div className="mockWrapper">
                    <div className="content">

                        {largeImagePreview &&

                        <div className="fullImage" onClick={()=>setLargeImagePreview(false)}>
                            <img src={`${config.ASSETS_PATH}/products/uv-packing-check/${mock}`}/>
                        </div>
                        }

                        {mockUploading &&
                            <div className="loader">
                                <h4>Uploading mock...</h4>
                            </div>
                        }

                        <div className='flex'>

                            {(mock && !mockUploading) &&
                                <div className="image" onClick={() => setLargeImagePreview(true)}>
                                    <img height={"34px"}
                                         src={`${config.ASSETS_PATH}/products/uv-packing-check/${mock}`}/>
                                </div>
                            }

                            {!mockUploading &&
                                <div className="buttons">
                                    {mock
                                        ? <Button basic secondary onClick={() => {
                                            onImageUploaded(null);
                                            setMock('')
                                        }}>remove
                                            mock</Button>
                                        : <Button
                                            basic
                                            content="Mock image"
                                            labelPosition="left"
                                            icon="image"
                                            onClick={() => fileInputRef.current.click()}
                                        />}

                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        hidden
                                        onChange={onImageSelected}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className="toggle">
                    <Checkbox toggle checked={uvPrintToggle} onChange={onUvPrintCheckboxToggled} label='UV Print'/>
                    <div className="toggleContent">
                    </div>
                </div>
                <div className="toggle">
                    <Checkbox toggle checked={embossToggle} onChange={onEmbossCheckboxToggled} label='Emboss'/>

                    {embossToggle &&
                    <div className="toggleContent">
                        <div className="flex">
                            <div className='item'>
                                <div className='label'>Emboss foil*</div>
                                <Dropdown
                                    options={configs.foils?.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    selection compact
                                    value={item.emboss.label}
                                    style={{minWidth: '200px'}}
                                    onChange={(e, {name, value}) => onItemFieldChanged('emboss.label', value)}
                                />
                            </div>

                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Emboss template*</div>

                                <Dropdown
                                    options={configs.embossTemplatesConfig.map(i => {
                                        return {
                                            key: i.name,
                                            value: i.name,
                                            text: i.name,
                                            id: i.name
                                        }
                                    })}
                                    selection compact search
                                    value={item.embossTemplate?.id.toString().indexOf('#') > -1 ? item.embossTemplate?.id : `#${item.embossTemplate?.id}`}
                                    style={{minWidth: '200px'}}
                                    onChange={(e, {name, value}) => onEmbossTemplateChanged(value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='item'>
                                <div className='label'>Text position*</div>

                                <Dropdown
                                    options={configs.embossTextPositions.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.id,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    selection compact
                                    value={item.textPosition}
                                    style={{minWidth: '200px'}}
                                    onChange={(e, {name, value}) => onItemFieldChanged('textPosition', value)}
                                />
                            </div>

                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Text alignment*</div>

                                <Dropdown
                                    options={configs.embossTextAlignments.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.id,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    selection compact
                                    value={item.textAlignment}
                                    style={{minWidth: '200px'}}
                                    onChange={(e, {name, value}) => onItemFieldChanged('textAlignment', value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='item' style={{marginRight: '20px'}}>
                                <div className='label'>Emboss Line 1</div>

                                <Input style={{width: '300px'}} value={item.emboss_l1_text}
                                       onChange={(e) => onItemFieldChanged('emboss_l1_text', e.target.value)}/>
                            </div>
                            <div className='item'>
                                <div className='label'>Font</div>

                                <Dropdown
                                    options={configs.fonts.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    style={{width: '150px'}}
                                    selection compact
                                    value={item.emboss_l1_font?.label}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l1_font.label', value)}
                                />
                            </div>
                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Font size</div>

                                <Dropdown
                                    options={fontSizesL1}
                                    selection compact
                                    value={item.emboss_l1_size.label}
                                    style={{width: '80px'}}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l1_size.label', value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='item' style={{marginRight: '20px'}}>
                                <div className='label'>Emboss Line 2</div>

                                <Input style={{width: '300px'}} value={item.emboss_l2_text}
                                       onChange={(e) => onItemFieldChanged('emboss_l2_text', e.target.value)}/>
                            </div>
                            <div className='item'>
                                <div className='label'>Font</div>

                                <Dropdown
                                    options={configs.fonts.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    style={{width: '150px'}}
                                    selection compact
                                    value={item.emboss_l2_font?.label}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l2_font.label', value)}
                                />
                            </div>
                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Font size</div>

                                <Dropdown
                                    options={fontSizesL2}
                                    selection compact
                                    value={item.emboss_l2_size.label}
                                    style={{width: '80px'}}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l2_size.label', value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='item' style={{marginRight: '20px'}}>
                                <div className='label'>Emboss Line 3</div>

                                <Input style={{width: '300px'}} value={item.emboss_l3_text}
                                       onChange={(e) => onItemFieldChanged('emboss_l3_text', e.target.value)}/>
                            </div>
                            <div className='item'>
                                <div className='label'>Font</div>

                                <Dropdown
                                    options={configs.fonts.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    style={{width: '150px'}}
                                    selection compact
                                    value={item.emboss_l3_font?.label}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l3_font.label', value)}
                                />
                            </div>
                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Font size</div>

                                <Dropdown
                                    options={fontSizesL3}
                                    selection compact
                                    value={item.emboss_l3_size.label}
                                    style={{width: '80px'}}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l3_size.label', value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='item' style={{marginRight: '20px'}}>
                                <div className='label'>Emboss Line 4</div>

                                <Input style={{width: '300px'}} value={item.emboss_l4_text}
                                       onChange={(e) => onItemFieldChanged('emboss_l4_text', e.target.value)}/>
                            </div>
                            <div className='item'>
                                <div className='label'>Font</div>

                                <Dropdown
                                    options={configs.fonts.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    style={{width: '150px'}}
                                    selection compact
                                    value={item.emboss_l4_font?.label}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l4_font.label', value)}
                                />
                            </div>
                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Font size</div>

                                <Dropdown
                                    options={fontSizesL4}
                                    selection compact
                                    value={item.emboss_l4_size.label}
                                    style={{width: '80px'}}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l4_size.label', value)}
                                />
                            </div>
                        </div>

                        <div className="flex">
                            <div className='item' style={{marginRight: '20px'}}>
                                <div className='label'>Emboss Line 5</div>

                                <Input style={{width: '300px'}} value={item.emboss_l5_text}
                                       onChange={(e) => onItemFieldChanged('emboss_l5_text', e.target.value)}/>
                            </div>
                            <div className='item'>
                                <div className='label'>Font</div>

                                <Dropdown
                                    options={configs.fonts.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    style={{width: '150px'}}
                                    selection compact
                                    value={item.emboss_l5_font?.label}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l5_font.label', value)}
                                />
                            </div>
                            <div className='item' style={{marginLeft: '20px'}}>
                                <div className='label'>Font size</div>

                                <Dropdown
                                    options={fontSizesL5}
                                    selection compact
                                    value={item.emboss_l5_size.label}
                                    style={{width: '80px'}}
                                    onChange={(e, {
                                        name,
                                        value
                                    }) => onItemFieldChanged('emboss_l5_size.label', value)}
                                />
                            </div>
                        </div>

                    </div>
                    }
                </div>
                <div className="toggle">
                    <Checkbox checked={folioToggle} onChange={onFolioCheckboxToggled} toggle label='Folie termotransfer'/>
                    {folioToggle &&
                    <div className="toggleContent">
                        <div className="flex">
                            <div className='item'>
                                <div className='label'>Foil color*</div>
                                <Dropdown
                                    options={["gold", "bronze", "silver"].map(i => {
                                        return {
                                            key: i,
                                            value: i,
                                            text: i,
                                            id: i
                                        }
                                    })}
                                    selection compact
                                    value={item.folio_color}
                                    style={{minWidth: '200px'}}
                                    onChange={(e, {name, value}) => onItemFieldChanged('folio_color', value)}
                                />
                            </div>
                        </div>


                    </div>
                    }
                </div>
                <div className="toggle">
                    <Checkbox checked={laserToggle} onChange={onLaserCheckboxToggled} toggle label='Laser engraving'/>

                </div>

            </div>
        </div>
    )
}
