import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {dispatch} from "../services/Store";
import {SCREEN_PASSWORD_RECOVERY, SCREEN_REGISTRATION} from "./index";
import Utils from "../data/Utils";
import ScreenController from "../controler/ScreenController";
import ActionsApp from "../actions/ActionsApp";
import {Button, Form, Grid, Header, Image, Message, Segment} from "semantic-ui-react";

class AuthenticateScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            emailError: "",

            password: "",
            passwordError: "",

            code: "",
            codeError:"",

            formError: ""
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data){
            if(nextProps.data.error){
                let state = {...this.state};
                state[nextProps.data.field] = nextProps.data.message;

                this.setState(state);
            }
        }
    }

    render() {
        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' color='black' textAlign='center'>
                        Log-in to your account
                    </Header>
                    {this.state.formError && (
                        <Header as='h4' style={{color:'#f44336'}}>{this.state.formError}</Header>
                    )}
                    <Form size='large' onSubmit={this.onFormSubmit}>
                        <Segment stacked>
                            <Form.Input
                                fluid icon='user'
                                iconPosition='left'
                                name='email'
                                placeholder='E-mail address'
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                fluid
                                icon='lock'
                                name='password'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                fluid
                                icon='shield alternate'
                                name='auth-token'
                                iconPosition='left'
                                placeholder='Code'
                                autoComplete='one-time-code'
                                inputMode="numeric"
                                pattern="[0-9]*"
                                type='text'
                                value={this.state.code}
                                onChange={this.handle2faChange}
                            />

                            <Button color='black' fluid size='large'>
                                Login
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    handle2faChange = (e, { name, value }) => this.setState({ code: value })

    onFormSubmit = () => {
        const emailValid = true;

        if(!emailValid){
            this.setState({
                emailError: "Please use a valid email address"
            });

            return;
        }

        if(!this.state.code.length){
            this.setState({
                formError: "Please use your code"
            });

            return;
        }

        if(this.state.password.length < 8){
            this.setState({
                emailError: "",
                passwordError: "Please use a password that has at least 8 characters"
            });

            return;
        }

        this.setState({
            emailError: "",
            passwordError: "",
            formError: "",
            codeError:""
        }, ()=>{
            dispatch(ActionsApp.AUTHENTICATE_REQ, {email: this.state.email, password: this.state.password, code: this.state.code, redirect: this.props.redirect});
        });
    };

    onRegisterAccountClicked = () => {
        ScreenController.goToScreen(SCREEN_REGISTRATION);
    };

    onForgotPasswordClicked = () => {
        ScreenController.goToScreen(SCREEN_PASSWORD_RECOVERY);
    };
}


export default AuthenticateScreen;
