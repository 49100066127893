import React, {Component} from 'react';
import './OrderPacker.scss';
import {Button, Dropdown, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";

class OrderPacker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            boxes: [],
            packing: [],
            providers: [],
            itemsInOrder: [],
            loading: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let itemsNotPacked = [];
        let allItemsPacked = [];

        this.state.packing.forEach(box=>{
            box.items.forEach(item=>{
                allItemsPacked.push(item);
            })
        })

        this.state.itemsInOrder.forEach(item=>{
            let search = allItemsPacked.find(it=>it.partId === item.id);
            if(!search){
                itemsNotPacked.push(item);
            }
        })

        const boxElements = this.getBoxElements(itemsNotPacked);

        return (
            <div className='OrderPacker'>
                {this.state.loading &&
                <div className="loader"></div>
                }
                <div style={{
                    marginBottom: '10px',
                    paddingBottom: '4px',
                    borderBottom: '2px solid #dededf',
                    fontSize: '15px'
                }}>Packing
                </div>

                <div>
                    {itemsNotPacked.length > 0 &&
                    <h4 className='error'>{itemsNotPacked.length} items not packed</h4>
                    }
                    <div className="header">
                        <a className="link" onClick={this.getData}>refresh</a>
                        <a className="link" onClick={this.onAddBoxClicked}>add box</a>
                        <a className="link" onClick={this.onAddEverythingToABoxClicked}>add remaining in a new box</a>
                        <a className="link" onClick={this.onAddEverythingToSameClicked}>add remaining in same box</a>
                    </div>

                    {boxElements}
                </div>
            </div>
        )
    }

    getBoxElements = (itemsNotPacked) => {
        return this.state.packing.map(box => {
            const itemsInBox = this.getItemsElements(box.id, itemsNotPacked);

            return (
                <div className="box">
                    <div className="title">
                        <div className="title-label">Box {box.id}</div>
                        <div className="remove" onClick={() => this.onBoxRemoveClicked(box.id)}>x</div>
                    </div>
                    <div className="box-body">
                        <div className="grouper"></div>
                        <div className="content">
                            <div>
                                <Dropdown closeOnBlur
                                          search selection name='orderDeadline' options={this.state.providers}
                                          value={box.sizeId} onChange={(e, {value})=>this.onShipperSelected(box.id, value)}/>
                            </div>
                            <div className="add-item">
                                <a className="link" onClick={()=>this.onAddItemInBoxClicked(box.id)}>add item in box</a>
                            </div>

                            <div className="items">
                                {itemsInBox}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    getItemsElements = (id, itemsNotPacked) => {
        let packing = [...this.state.packing];
        let item = packing.find(b=>b.id === id);

        let itemsToRender = this.state.itemsInOrder.map(it=>{
            let notPacked = itemsNotPacked.find(i=>i.id === it.id);

            notPacked = notPacked !== undefined;

            return {
                key: it.id,
                value: it.id,
                text: `${it.product_type} ${it.id}`,
                disabled: !notPacked
            }
        })

        if(item && item.items?.length){
            return item.items.map(item=>{
                return (
                    <div className="item-in-box">
                        <div>
                            <Dropdown closeOnBlur
                                      search selection name='orderDeadline'
                                      options={itemsToRender}
                                      value={item.partId} onChange={(e,{value})=>this.onItemFromBoxSelected(value, item.id, id)}/>
                        </div>
                        <div className="remove" onClick={()=>this.removeItemFromBox(item.id, id)}>x</div>
                    </div>
                )
            })
        }

        return null;
    }

    onAddEverythingToABoxClicked = () => {
        let box = {
            id: this.state.packing.length + 1,
            type: 'Custom',
            sizeId: 0,
            items: []
        }

        let itemsNotPacked = [];
        let allItemsPacked = [];

        this.state.packing.forEach(box=>{
            box.items.forEach(item=>{
                allItemsPacked.push(item);
            })
        })

        this.state.itemsInOrder.forEach(item=>{
            let search = allItemsPacked.find(it=>it.partId === item.id);
            if(!search){
                itemsNotPacked.push(item);
            }
        })

        itemsNotPacked.forEach(item=>{
            box.items.push({
                partId: item.id
            })
        })

        let packing = [...this.state.packing];
        packing.push(box);

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking();
        })
    }

    onAddEverythingToSameClicked = async () => {
        await this.getData();

        let packing = [...this.state.packing];
        let box = packing[0];

        if(!box){
            return;
        }

        let itemsNotPacked = [];
        let allItemsPacked = [];

        this.state.packing.forEach(box=>{
            box.items.forEach(item=>{
                allItemsPacked.push(item);
            })
        })

        this.state.itemsInOrder.forEach(item=>{
            let search = allItemsPacked.find(it=>it.partId === item.id);
            if(!search){
                itemsNotPacked.push(item);
            }
        })

        itemsNotPacked.forEach(item=>{
            box.items.push({
                partId: item.id
            })
        })


        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking();
        })

        console.log(this.state)
    }

    onItemFromBoxSelected = (selectedId, itemId, boxId) =>{
        let packing = [...this.state.packing];
        let box = packing.find(b => b.id === boxId);
        let item = box.items.find(i=>i.id === itemId);

        item.partId = selectedId;

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking();
        })
    }

    removeItemFromBox = (itemId, boxId)=>{
        let packing = [...this.state.packing];
        let box = packing.find(b => b.id === boxId);
        let item = box.items.find(i=>i.id === itemId);

        if(item){
            let index = box.items.indexOf(item);
            box.items.splice(index, 1);
        }

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking();
        })
    }

    onAddItemInBoxClicked = (id) => {
        let packing = [...this.state.packing];
        let item = packing.find(b => b.id === id);
        let itemInBox = {partId: 0, id: item.items.length + 1};
        item.items.push(itemInBox);

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking();
        })
    }

    onAddBoxClicked = () => {
        let box = {
            id: this.state.packing.length + 1,
            sizeId: 0,
            type: 'Custom',
            items: []
        }

        let packing = [...this.state.packing];
        packing.push(box);

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking();
        })
    }


    onShipperSelected = async (id, sizeId) => {
        let packing = [...this.state.packing];
        let item = packing.find(b => b.id === id);
        item.sizeId = sizeId;

        let box = this.state.boxes.find(b=>b.id === sizeId);
        item.type= `${box.code} (${box.width}x${box.height}x${box.depth}cm)`

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking()
        })
    }

    onBoxRemoveClicked = (id) => {
        let packing = [...this.state.packing];
        let item = packing.find(b => b.id === id);
        let index = packing.indexOf(item);

        packing.splice(index, 1);

        packing.forEach((item, index) => {
            item.id = index + 1;
        })

        this.setState({packing: packing, loading: true}, ()=>{
            this.updatePacking()
        })
    }

    updatePacking = async () => {
        await ApiService.updateOrderPacking(this.props.orderId, this.state.packing);
        const packing = await ApiService.getOrderPacking(this.props.orderId);

        this.setState({
            packing: packing.data,
            loading: false
        })
    }

    getData = async () => {
        let boxes = await ApiService.getProductShippingBox();
        boxes = boxes.data;
        boxes = boxes.filter(b => b.available === 1);

        boxes.forEach(box => {
            let value = `${box.code}.${box.width}x${box.height}x${box.depth}cm`
            box.sku = `SHIPPINGBOX-ID.${box.id}-TYPE.${value}`;
        })

        for (let i = 0; i < boxes.length; i++) {
            let box = boxes[i];
            let stock = await ApiService.stockGetItemBySKU(box.sku);
            if(stock.data.length) {
                box.stock = stock.data[0];
                box.name = `${box.code} (${box.width}x${box.height}x${box.depth}cm) ${box.stock.quantity} in stock`
            }
        }

        let boxesDisplay = boxes.map(b => {
            return {
                key: b.id,
                value: b.id,
                text: b.name
            }
        })

        const itemsInOrder = await ApiService.fetchShippmentItemsForOrder(this.props.orderId);

        const packing = await ApiService.getOrderPacking(this.props.orderId);

        this.setState({
            providers: [{key: 'Custom', text: 'Custom', value: 0}, ...boxesDisplay],
            packing: packing.data,
            itemsInOrder: itemsInOrder.data.items,
            boxes: boxes
        })
    }


}


export default OrderPacker;
