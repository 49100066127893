import React, {Component} from 'react';
import './ShippingScreen.scss';
import {
    Container, Menu, Table,
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import moment from "moment-timezone";
import AuthService from "../services/AuthService";

class ShippingScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            batches: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const elements = this.getBatchesElements();

        return (
            <Container style={{margin: '3rem 0'}}>
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                            <Table.HeaderCell>Nr Comenzi</Table.HeaderCell>
                            <Table.HeaderCell>Nr AWB</Table.HeaderCell>
                            <Table.HeaderCell>Trimis</Table.HeaderCell>
                            <Table.HeaderCell>Actiuni</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {elements}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                AWB se face din comanda
                                {/*<Button basic color='blue' onClick={this.onNewShippingBatchClicked}>Expediere noua</Button>*/}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Container>
        );
    }

    getBatchesElements = () => {
        if (this.state.batches.length === 0) {
            return null;
        }

        return this.state.batches.map(batch => {
            const shipped = batch.shipped === 1;
            const complete = batch.complete === 1;
            const errored = batch.errored;

            const shippedLabel = shipped ? 'da' : 'nu';

            return (
                <Table.Row key={batch.id} onClick={()=>this.onShippingBatchClicked(batch.id)}>
                    <Table.Cell>{batch.name}</Table.Cell>
                    <Table.Cell>{batch.nr_orders}</Table.Cell>
                    <Table.Cell>{batch.nr_awb}</Table.Cell>
                    <Table.Cell negative={!shipped} positive={shipped}>
                        {shippedLabel}
                    </Table.Cell>
                    {!errored &&
                    <Table.Cell>
                        {(!shipped && complete) &&
                        <Button basic size='small' color='purple' onClick={(e) => this.onShippedClicked(batch.id, e)}>Au
                            plecat</Button>
                        }

                    </Table.Cell>
                    }
                    {errored &&
                    <Table.Cell><h4>Corecteaza erorile pentru a continua</h4></Table.Cell>
                    }
                </Table.Row>
            )
        })
    }

    onShippingBatchClicked = (id) => {
        window.location.href = `/shipping/${id}`;
    }

    onNewShippingBatchClicked = () => {
        const time = moment().format('DD.MM.YY-HH.mm');
        const user = AuthService.getUserData();

        const batch = {
            name: time,
            created_by: user?.email
        }

        ApiService.shippingBatchNew(batch).then(data => {
            if (data.data.id) {
                window.location.href = `/shipping/${data.data.id}`;
            }
        })
    }

    onShippedClicked = (batchId, e) => {
        e.preventDefault();
        e.stopPropagation();

        ApiService.shippingBatchMarkAsShipped(batchId).then(data => {
            this.getData();
        })
    }

    onDownloadFileClicked = (batchId, e) => {
        e.preventDefault();
        e.stopPropagation();

        ApiService.downloadAwbBatch(batchId);
    }

    getData = () => {
        ApiService.shippingBatchesGet().then(data => {
            this.setState({batches: data.data})
        })
    }

}


export default ShippingScreen;
