import React, {Component} from 'react';
import './VerificationComponent.scss';
import ApiService from "../../services/ApiService";
import {Container} from "semantic-ui-react";
import {Generate} from "../utils/checksum";

class VerificationComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentDidMount() {
        ApiService.getReportsVerification(this.props.from, this.props.to)
            .then(data => {
                this.setState( {data: data.data});
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.from === prevProps.from){
            return;
        }
        ApiService.getReportsVerification(this.props.from, this.props.to)
            .then(data => {
                this.setState( {data: data.data});
            })
    }

    render() {
        const items = this.getItems();

        return (
            <div className='VerificationComponent'>
                <Container className='main'>
                    <h3>Orders verification</h3>
                    <div className='header'>
                        <div style={{width:'50px'}}>ID</div>
                        <div style={{width: '120px'}}>Data</div>
                        <div style={{width: '80px'}}>Pret items</div>
                        <div style={{width: '80px'}}>Incasat</div>
                        <div style={{width: '80px'}}>Facturat</div>
                        <div>Client</div>
                    </div>
                    <div className='items'>
                        {items}
                    </div>
                </Container>
            </div>
        );
    }

    getItems = () => {
        if (this.state.data.length === 0) {
            return <div className='ok'>All orders in the selected time period are ok</div>;
        }

        return this.state.data.map(item => {
            let date = new Date(item.date);
            let userTimezoneOffset = date.getTimezoneOffset() * 60000;
            let selectedDate = new Date(date.getTime() - userTimezoneOffset);
            selectedDate = selectedDate.toISOString().split('T')[0];

            let itemsPrice = parseFloat(item.itemsPrice.toFixed(2));
            let collected = parseFloat(item.collected.toFixed(2));
            let invoiceTotal = parseFloat(item.invoiceTotal.toFixed(2));

            let idStyle = {
                width: '50px'
            };

            if (itemsPrice !== collected || itemsPrice !== invoiceTotal || collected !== invoiceTotal) {
                idStyle.background = 'red';
                idStyle.color = 'white';
            } else {
                return null;
            }

            return (
                <div style={{display: 'flex', padding: '5px'}}>
                    <div style={idStyle}><a style={idStyle} href={`/order/${Generate(item.id)}`} target='_blank'>{`${Generate(item.id)}`}</a></div>
                    <div style={{width: '120px'}}>{selectedDate}</div>
                    <div style={{width: '80px'}}>{itemsPrice}</div>
                    <div style={{width: '80px'}}>{collected}</div>
                    <div style={{width: '80px'}}>{invoiceTotal}</div>
                    <div>{item.name}</div>
                </div>
            )
        })
    }

}


export default VerificationComponent;
