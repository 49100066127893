import React, {Component} from 'react';
import './HeaderComponent.scss';
import {dispatch} from "../services/Store";
import AuthService from "../services/AuthService";
import ActionsApp from "../actions/ActionsApp";
import {Container, Divider, Dropdown, Image, Menu} from "semantic-ui-react";
import ScreenController from "../controler/ScreenController";

class HeaderComponent extends Component {

    render() {
        let accountData = null;

        if (AuthService.isAuthenticated()) {
            accountData = AuthService.getUserData();
        }

        if (!accountData) {
            return null;
        }

        const activeItem = this.props.location.pathname.split('/')[1];

        return (
            <div>
                <Menu pointing secondary stackable>
                    <Container>
                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Dropdown item text='Configuration'>
                                <Dropdown.Menu>
                                    <Menu.Item
                                        name='products config'
                                        active={activeItem === 'pc'}
                                        onClick={() => this.handleItemClick('/pc')}
                                    />

                                    {/*<Menu.Item*/}
                                    {/*    name='albums'*/}
                                    {/*    active={activeItem === 'albums'}*/}
                                    {/*    onClick={() => this.handleItemClick('/albums')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='classic albums'*/}
                                    {/*    active={activeItem === 'classic-albums'}*/}
                                    {/*    onClick={() => this.handleItemClick('/classic-albums')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Guestbooks'*/}
                                    {/*    active={activeItem === 'guestbooks'}*/}
                                    {/*    onClick={() => this.handleItemClick('/guestbooks')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Guestbooks Slots'*/}
                                    {/*    active={activeItem === 'guestbooks-slots'}*/}
                                    {/*    onClick={() => this.handleItemClick('/guestbooks-slots')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Boudoir'*/}
                                    {/*    active={activeItem === 'boudoir'}*/}
                                    {/*    onClick={() => this.handleItemClick('/boudoir')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Boudoir Empty'*/}
                                    {/*    active={activeItem === 'boudoir-empty'}*/}
                                    {/*    onClick={() => this.handleItemClick('/boudoir-empty')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Bamboo Box'*/}
                                    {/*    active={activeItem === 'bamboo-box'}*/}
                                    {/*    onClick={() => this.handleItemClick('/bamboo-box')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Corners'*/}
                                    {/*    active={activeItem === 'corners'}*/}
                                    {/*    onClick={() => this.handleItemClick('/corners')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='Pouch'*/}
                                    {/*    active={activeItem === 'pouch'}*/}
                                    {/*    onClick={() => this.handleItemClick('/pouch')}*/}
                                    {/*/>*/}

                                    {/*<Menu.Item*/}
                                    {/*    name='Pens'*/}
                                    {/*    active={activeItem === 'pen'}*/}
                                    {/*    onClick={() => this.handleItemClick('/pen')}*/}
                                    {/*/>*/}

                                    {/*<Menu.Item*/}
                                    {/*    name='Album Sleeve'*/}
                                    {/*    active={activeItem === 'album-sleeve'}*/}
                                    {/*    onClick={() => this.handleItemClick('/album-sleeve')}*/}
                                    {/*/>*/}

                                    {/*<Menu.Item*/}
                                    {/*    name='Premium Box'*/}
                                    {/*    active={activeItem === 'premium-box'}*/}
                                    {/*    onClick={() => this.handleItemClick('/premium-box')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='paper config'*/}
                                    {/*    active={activeItem === 'paper-config'}*/}
                                    {/*    onClick={() => this.handleItemClick('/paper-config')}*/}
                                    {/*/>*/}

                                    {/*<Menu.Item*/}
                                    {/*    name='Shipping Box'*/}
                                    {/*    active={activeItem === 'shipping-box'}*/}
                                    {/*    onClick={() => this.handleItemClick('/shipping-box')}*/}
                                    {/*/>*/}

                                    {/*<Menu.Item*/}
                                    {/*    name='raw paper config'*/}
                                    {/*    active={activeItem === 'raw-config'}*/}
                                    {/*    onClick={() => this.handleItemClick('/raw-config')}*/}
                                    {/*/>*/}
                                    {/*<Menu.Item*/}
                                    {/*    name='raw materials config'*/}
                                    {/*    active={activeItem === 'raw-materials-config'}*/}
                                    {/*    onClick={() => this.handleItemClick('/raw-materials-config')}*/}
                                    {/*/>*/}


                                    <Menu.Item
                                        name='Materials'
                                        active={activeItem === 'materials'}
                                        onClick={() => this.handleItemClick('/materials')}
                                    />

                                    <Menu.Item
                                        name='Emboss'
                                        active={activeItem === 'emboss'}
                                        onClick={() => this.handleItemClick('/emboss')}
                                    />

                                    <Divider/>

                                    <Menu.Item
                                        name='Currency'
                                        active={activeItem === 'currency'}
                                        onClick={() => this.handleItemClick('/currency')}
                                    />
                                    <Menu.Item
                                        name='Website'
                                        active={activeItem === 'website'}
                                        onClick={() => this.handleItemClick('/website')}
                                    />
                                    <Menu.Item
                                        name='Phototailors swatches'
                                        active={activeItem === 'website'}
                                        onClick={() => this.handleItemClick('/website-swatches')}
                                    />
                                    <Menu.Item
                                        name='Suppliers'
                                        active={activeItem === 'suppliers'}
                                        onClick={() => this.handleItemClick('/suppliers')}
                                    />
                                    <Menu.Item
                                        name='Packeta'
                                        active={activeItem === 'packeta'}
                                        onClick={() => this.handleItemClick('/packeta')}
                                    />
                                    <Menu.Item
                                        name='VAT Rates'
                                        active={activeItem === 'vat-rates'}
                                        onClick={() => this.handleItemClick('/vat-rates')}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        }

                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Dropdown item text='Data'>
                                <Dropdown.Menu>
                                    <Menu.Item
                                        name='Mugur'
                                        active={activeItem === 'sales'}
                                        onClick={() => this.handleItemClick('/sales')}
                                    />
                                    <Menu.Item
                                        name='Reports'
                                        active={activeItem === 'reports'}
                                        onClick={() => this.handleItemClick('/reports')}
                                    />
                                    <Menu.Item
                                        name='Dhl check'
                                        active={activeItem === 'dhl_check'}
                                        onClick={() => this.handleItemClick('/dhl_check')}
                                    />

                                    <Menu.Item
                                        name='ETSY Ledger'
                                        active={activeItem === 'etsy-ledger'}
                                        onClick={() => this.handleItemClick('/etsy-ledger')}
                                    />

                                    <Menu.Item
                                        name='ETSY - Mugur'
                                        active={activeItem === 'etsy-accounting'}
                                        onClick={() => this.handleItemClick('/etsy-accounting')}
                                    />

                                    <Menu.Item
                                        name='Bloc Stock Needs'
                                        active={activeItem === 'bloc-stock-needs'}
                                        onClick={() => this.handleItemClick('/bloc-stock-needs')}
                                    />
                                    {/*<Menu.Item*/}
                                    {/*    name='Dhl transit'*/}
                                    {/*    active={activeItem === 'shipping-dashboard'}*/}
                                    {/*    onClick={() => this.handleItemClick('/shipping-dashboard')}*/}
                                    {/*/>*/}
                                </Dropdown.Menu>
                            </Dropdown>
                        }

                        {/*{['admin'].indexOf(accountData.role) !== -1 &&*/}
                        {/*<Menu.Item*/}
                        {/*    name='Clients'*/}
                        {/*    active={activeItem === 'clients'}*/}
                        {/*    onClick={() => this.handleItemClick('/clients')}*/}
                        {/*/>*/}
                        {/*}*/}

                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Orders'
                                active={activeItem === 'orders'}
                                onClick={() => this.handleItemClick('/orders')}
                            />
                        }

                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Etsy Awb'
                                active={activeItem === 'awb2client'}
                                onClick={() => this.handleItemClick('/awb2client')}
                            />
                        }

                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Packing'
                                active={activeItem === 'packing'}
                                onClick={() => this.handleItemClick('/packing')}
                            />
                        }

                        {['admin', 'manufacture'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Personalisation'
                                active={activeItem === 'personalisation'}
                                onClick={() => this.handleItemClick('/personalisation')}
                            />
                        }

                        {['admin', 'manufacture'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Manufacture'
                                active={activeItem === 'manufacture'}
                                onClick={() => this.handleItemClick('/manufacture')}
                            />
                        }

                        {['admin', 'manufacture'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Inventory'
                                active={activeItem === 'inventory'}
                                onClick={() => this.handleItemClick('/inventory')}
                            />
                        }

                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Taieri'
                                active={activeItem === 'taieri'}
                                onClick={() => this.handleItemClick('/taieri')}
                            />
                        }

                        {['admin'].indexOf(accountData.role) !== -1 &&
                            <Menu.Item
                                name='Invoices'
                                active={activeItem === 'invoices'}
                                onClick={() => this.handleItemClick('/invoices')}
                            />
                        }

                        {/*{['admin', 'manufacture', 'external'].indexOf(accountData.role) !== -1 &&*/}
                        {/*    <Menu.Item*/}
                        {/*        name='External'*/}
                        {/*        active={activeItem === 'external'}*/}
                        {/*        onClick={() => this.handleItemClick('/external')}*/}
                        {/*    />*/}
                        {/*}*/}

                        {/*{['admin'].indexOf(accountData.role) !== -1 &&*/}
                        {/*<Menu.Item*/}
                        {/*    name='Timeline'*/}
                        {/*    active={activeItem === 'timeline'}*/}
                        {/*    onClick={() => this.handleItemClick('/timeline')}*/}
                        {/*/>*/}
                        {/*}*/}


                        <Menu.Menu position='right'>
                            <Menu.Item
                                name='logout'
                                active={activeItem === 'logout'}
                                onClick={this.onLogoutClicked}
                            ><p>{accountData.email}</p></Menu.Item>
                        </Menu.Menu>

                    </Container>
                </Menu>
            </div>
        );
    }

    onLogoutClicked = () => {
        dispatch(ActionsApp.LOGOUT);
    };

    handleItemClick = (item) => {
        ScreenController.goToScreen(item);
    }
}


export default HeaderComponent;
