import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import SupplierPricesBookItem from "./SupplierPricesBookItem";

class SupplierPricesBook extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sizes: []
        };
    }

    componentDidMount() {
        ApiService.getSizes(this.props.bookType).then(data => {
            this.setState({sizes: data.data.sizes})
        })
    }

    render() {
        const elements = this.getElements();

        return (
            <div>
                <h2 style={{marginTop: '100px'}}>{this.props.bookType}</h2>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>

                            <Table.HeaderCell/>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {elements}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    getElements = () => {
        if (this.state.sizes.length === 0) {
            return null;
        }


        let prices = this.props.prices.prices;


        let supplierPrices = prices[this.props.supplierId];
        if (!supplierPrices) {
            supplierPrices = {};
        }

        let productPrices = supplierPrices[this.props.bookType];
        if (!productPrices) {
            productPrices = {};
        }

        return this.state.sizes.map(size => {
            let price = {...productPrices[size.cm]};

            if(!price){
                price = {};
            }

            return (
                <SupplierPricesBookItem key={`guestbooks.${size.id}`} size={size} pricesId={this.props.prices.id}
                                        price={price} type={this.props.bookType} supplierId={this.props.supplierId}
                                        onUpdatePrice={this.props.onUpdatePrice}/>
            )
        })
    }

}


export default SupplierPricesBook;
