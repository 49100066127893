import React, {Component} from 'react';

import './CutCard.scss'
import {
    Button,
    Dropdown,
    Input,
} from "semantic-ui-react";

class CutCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isSource = this.props.source === true;

        let materials = [];
        let sizes = [];

        if(this.props.materials){
            materials = this.props.materials.map(item=>{
                return {
                    key: item,
                    text: item,
                    value: item
                }
            })
        }

        if(this.props.sizes){
            sizes = this.props.sizes.map(item=>{
                return {
                    key: item,
                    text: item,
                    value: item
                }
            })
        }

        return (
            <div className="CutCard">
                <div className="header">
                    {isSource ? <h3>Coala</h3> : <h3>Taiat</h3>}
                    {!isSource &&
                        <Button onClick={this.props.onRemoveClicked} basic size={'tiny'} color={"white"}>X</Button>
                    }
                </div>
                {isSource &&
                <div>
                    <div className="CutCard_inputHead">Operator</div>
                    <Input value={this.props.operator} onChange={this.onOperatorChanged} placeholder="Mihai"/>
                </div>
                }
                <div>
                    <div className="CutCard_inputHead">Material</div>
                    {isSource ?  <Dropdown closeOnBlur
                                           selection name='pricelistDate'
                                           options={materials}
                                           value={this.props.selectedMaterial} onChange={this.onMaterialSelected}/> : <div>{this.props.selectedMaterial}</div>}

                </div>
                <div>
                    <div className="CutCard_inputHead">Marime</div>
                    <Dropdown closeOnBlur
                              selection name='pricelistDate'
                              options={sizes}
                              value={this.props.selectedSize} onChange={this.onSizeSelected}/>
                </div>

                <div>
                    <div className="CutCard_inputHead">{isSource ? 'Coli taiate' : 'Bucati'}</div>
                    <Input value={this.props.amount} onChange={this.onAmountChanged}/>
                </div>
            </div>
        );
    }

    onMaterialSelected = (e, {name, value}) => {
        this.props.onMaterialSelected(value);
    }

    onSizeSelected = (e, {name, value}) => {
        this.props.onSizeSelected(value);
    }

    onAmountChanged = (e, {name, value}) => {
        this.props.onAmountChanged(value);
    }

    onOperatorChanged = (e, {name, value}) => {
        this.props.onOperatorChanged(value);
    }



}


export default CutCard;
