import React from 'react';
import './Fulfiller.scss';
import Utils from "../../data/Utils";

export default function Fulfiller({fulfiller}){
    const config = Utils.getFulfillerConfig();

    return (
        <div className="Fulfiller" style={{background: config[fulfiller].bg}}>
            {config[fulfiller].label}
        </div>
    )
}
