class Presence {
    constructor() {
        this.serverPath = null;
        this.connected = false;
        this.ws = null;

        this.userId = 1;
        this.property = "LB";
        this.path = window.location.href;

        this.watchForPathChange();
        this.ping();
    }

    setup(userId, property, serverPath){
        this.serverPath = serverPath;
        this.userId = userId;
        this.property = property;

        this.connect();
    }

    updateUserId(userId){
        this.userId = userId;
        this.onPathChanged();
    }

    test(){
        window.history.pushState('page2', 'Title', Math.random());
        setTimeout(()=>{
            this.test()
        }, 10000)
    }

    connect(){
        const webSocket = new WebSocket(this.serverPath);

        webSocket.onopen =  () =>  {
            this.ws = webSocket;
            this.connected = true;

            this.onPathChanged();
        }
        webSocket.onerror =  (error) => {
            console.log(error)
        }

        webSocket.onclose =  () => {
            this.ws = null;
            this.connected = false;

            setTimeout(()=>{
                this.connect();
            }, 5000);
        }
    }

    watchForPathChange() {
        if (this.path !== window.location.href) {
            this.path = window.location.href;
            this.onPathChanged();
        }

        setTimeout(() => {
            this.watchForPathChange()
        }, 1000);
    }

    sendPresence() {
        this.ws.send(JSON.stringify({id: this.userId, path: this.path, property: this.property}))
    }

    onPathChanged() {
        if(this.connected){
            this.sendPresence();
        }
    }

    ping(){
        if(this.connected){
            this.ws.send(JSON.stringify({id: "PING"}))
        }
        setTimeout(()=>{
            this.ping();
        }, 5000)
    }
}

export default new Presence();

// let presence = new Presence();
// presence.setup(12, 'LB', "wss://alexvlad.com/presence/");
// presence.updateUserId(33);
