import React, {Component} from 'react';
import {Button, Checkbox, Container, Header, Icon, Image, Input, Item, Label, Modal} from "semantic-ui-react";
import {ChromePicker} from 'react-color'
import MaterialFoilMapping from "./MaterialFoilMapping";

class MaterialFoilMappings extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const foils = this.getFoilsElement();

        return (
            <Container style={{paddingLeft: '2rem', marginTop: '1rem'}}>
                <Header style={{marginTop: '2rem'}} as='h4'>Incompatible foils</Header>
                {foils}
            </Container>
        );
    }

    getFoilsElement = () => {
        if (this.props.foils.length === 0) {
            return null;
        }

        return this.props.foils.map(foil => {
            let checked = false;

            if (this.props.meta.incompatibleFoils && this.props.meta.incompatibleFoils[foil.id] === true) {
                checked = true;
            }

            return (
                <div style={{margin: '3px 0'}}>
                    <Checkbox label={foil.name} name='canEmboss' toggle checked={checked}
                              onChange={(e, {checked}) => this.props.onIncompatibeFoilChanged(foil.id, checked)}/>
                </div>
            )
        })
    };

}


export default MaterialFoilMappings;
