import React, {Component} from 'react';
import './MonthlyProfitComponent.scss';
import ApiService from "../../services/ApiService";
import {Cell, Pie, PieChart, Tooltip} from "recharts";

class MonthlyEstimatedProfitComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        ApiService.getEstimatedReportsIncome(this.props.from, this.props.to)
            .then(data => {
                this.setState(data.data);
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.from === prevProps.from) {
            return;
        }
        ApiService.getEstimatedReportsIncome(this.props.from, this.props.to)
            .then(data => {
                this.setState(data.data);
            })
    }

    render() {
        let incasat = parseFloat(Math.abs(this.state.totalIncasat).toFixed(1));
        let shiping = parseFloat(Math.abs(this.state.totalShipping).toFixed(1));
        let productie = parseFloat(Math.abs(this.state.totalProduction).toFixed(1));
        let taxes = parseFloat(Math.abs(this.state.platform_fee + this.state.payment_fee + this.state.income_fee + this.state.vat_fee).toFixed(1));
        let profit = parseFloat(Math.abs(this.state.profit).toFixed(1));

        const data1 = [
            {name: 'productie', value: productie},
            {name: 'profit', value: profit},
            {name: 'transport', value: shiping},
            {name: 'taxe', value: taxes},

        ]

        const data2 = [
            {name: 'platform', value: parseFloat(Math.abs(this.state.platform_fee).toFixed(1))},
            {name: 'payment', value: parseFloat(Math.abs(this.state.payment_fee).toFixed(1))},
            {name: 'impozit', value: parseFloat(Math.abs(this.state.income_fee).toFixed(1))},
            {name: 'tva', value: parseFloat(Math.abs(this.state.vat_fee).toFixed(1))},
        ]

        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        const RADIAN = Math.PI / 180;

        return (
            <div className='MonthlyProfitComponent'>
                <h3 className='download' style={{margin: '0'}}>Estimari comenzi {this.props.from}</h3>
                <div style={{marginBottom: '30px'}}>(incasate luna curenta; pentru comenzile in lucru, transportul este estimat la RON 100 / comanda)</div>
                <div className='content'>
                    <div>
                        <h3 style={{textAlign: 'center'}}>Incasat: {incasat}</h3>
                        <PieChart width={500} height={500}>
                            <Pie isAnimationActive={false} data={data1} cx={250} cy={200} outerRadius={160}
                                 fill="#8884d8"
                                 label={entry => entry.name}>
                                {
                                    data1.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                    </div>

                    <div>
                        <h3 style={{textAlign: 'center'}}>Taxe: {taxes}</h3>
                        <PieChart width={500} height={500}>
                            <Pie isAnimationActive={false} data={data2} cx={250} cy={200} outerRadius={160}
                                 fill="#8884d8"
                                 label={entry => entry.name}>
                                {
                                    data2.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                    </div>
                </div>
            </div>
        );
    }

    download = (data, filename, type) => {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

}


export default MonthlyEstimatedProfitComponent;
