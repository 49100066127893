import React, {Component} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ApiService from "../services/ApiService";
import {config} from "../config/config";
import {Image} from "semantic-ui-react";
import './UvPackingPrintCheck.scss';

class UvPackingPrintCheck extends Component {
    constructor(props){
        super(props);
        this.state = {
            fullscreenImage: false,
            loaderVisible: false
        }

        this.fileInputRef = React.createRef();
    }

    render(){
        const style = this.state.fullscreenImage ? {display:'flex'} : {display:'none'}

        return (
            <div  className="UvPackingPrintCheck">
                {this.state.loaderVisible &&
                <div className="loader">
                    <h2>Uploading image...</h2>
                </div>
                }

                <div className="fullImage" style={style} onClick={()=>this.setState({fullscreenImage: false})}>
                    <img src={`${config.ASSETS_PATH}/products/uv-packing-check/${this.props.image}`}/>
                </div>
                <div className="header">UV Template image</div>
                <div className="content">
                    {this.props.image &&
                    <div className="image"  onClick={()=>this.setState({fullscreenImage: true})}>
                        <img height={"33px"} src={`${config.ASSETS_PATH}/products/uv-packing-check/${this.props.image}`}/>
                    </div>
                    }
                    <div className="buttons">
                        {this.props.image
                            ? <Button basic secondary onClick={()=>this.props.onImageUploaded(null)}>remove</Button>
                            : <Button
                                basic
                                content="select image"
                                labelPosition="left"
                                icon="image"
                                onClick={() => this.fileInputRef.current.click()}
                            />}

                        <input
                            ref={this.fileInputRef}
                            type="file"
                            hidden
                            onChange={this.onImageSelected}
                        />
                    </div>
                </div>
            </div>
        )
    }

    onImageSelected = (e) => {
        const data = new FormData();

        this.setState({loaderVisible: true})

        data.append('file', e.target.files[0]);

        ApiService.uploadImage('uv-packing-check', data).then(r => {
            const image = r.data.image;

            this.props.onImageUploaded(image);
        }).then(data=>{
            this.setState({loaderVisible: false})
        });
    }
}

export default UvPackingPrintCheck
