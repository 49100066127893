import React, {Component} from 'react';
import './AwbShipment.scss';
import {Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class AwbShipment extends Component {
    constructor(props) {
        super(props);


        this.state = {
            boxes: {
                1: {
                    w: '',
                    h: '',
                    d: '',
                    kg: '',
                    set: false,
                    showSave: false
                }
            }
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({boxes: nextProps.boxes})
    }

    render() {
        const renderBoxElement = this.getRenderBoxElement();

        return (
            <div className='AwbShipment'>
                <div className='boxes'>
                {renderBoxElement}
                </div>

                {this.props.onlyOneBox === false &&
                <div className='add-box' onClick={this.onNewBoxClicked}>+</div>
                }
            </div>
        );
    }

    getRenderBoxElement = () => {
        let boxes = [];
        let stateBoxes = {...this.state.boxes};

        for (let key in stateBoxes){
            stateBoxes[key].package = key;
            boxes.push(stateBoxes[key]);
        }

        return boxes.map(box=>{
            const className = box.set ? 'input-list set' : 'input-list';

            return (
                <div key={`box-${box.package}`} className={className}>
                    <div>BOX {box.package}</div>
                    <Input className='item' label='w' size='mini' value={box.w}
                           onChange={(e) => this.onFieldChanged('w', box.package, e.target.value)}/>
                    <Input className='item' label='h' size='mini' value={box.h}
                           onChange={(e) => this.onFieldChanged('h', box.package, e.target.value)}/>
                    <Input className='item' label='d' size='mini' value={box.d}
                           onChange={(e) => this.onFieldChanged('d', box.package, e.target.value)}/>
                    <Input className='item' label='kg' size='mini' value={box.kg}
                           onChange={(e) => this.onFieldChanged('kg', box.package, e.target.value)}/>

                    {box.showSave &&
                    <Button compact color='red' style={{marginTop: '10px'}} onClick={()=>this.onSaveClicked(box.package)}>save</Button>
                    }
                </div>
            )
        })
    };

    onFieldChanged = (field, boxId, value) => {
        let stateBoxes = {...this.state.boxes};
        stateBoxes[boxId][field] = value;
        stateBoxes[boxId].showSave = true;
        this.setState({boxes: stateBoxes})
    };

    onSaveClicked = (boxId) => {
        let stateBoxes = {...this.state.boxes};
        stateBoxes[boxId].showSave = false;
        stateBoxes[boxId].set = true;


        this.setState({boxes: stateBoxes}, () => {
            this.props.onBoxSaved({...this.state.boxes[boxId]})
        })
    };

    onNewBoxClicked = () => {
        let keys = Object.keys(this.state.boxes).map(i=>parseInt(i));
        let lastKey = keys[keys.length -1];
        let nextKey = lastKey+1;

        let stateBoxes = {...this.state.boxes};
        stateBoxes[nextKey.toString()] = {
            w: '',
            h: '',
            d: '',
            kg: '',
            set: false,
            showSave: false
        };

        this.setState({boxes: stateBoxes})
    }
}

export default AwbShipment;
