import React, {Component} from 'react';
import './OrderTimeline.scss';
import ApiService from "../../services/ApiService";
import Utils from "../../data/Utils";
import {Icon} from "semantic-ui-react";

class OrderTimeline extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            expandedMetaForId: 0
        }
    }

    componentDidMount() {
        ApiService.orderTimelineLoad(this.props.id).then(data => {
            this.setState({
                data: data.data
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.id !== this.props.id){
            ApiService.orderTimelineLoad(this.props.id).then(data => {
                this.setState({
                    data: data.data
                })
            })
        }

    }

    render() {
        const timelineElements = this.getTimelineElements();

        return (
            <div className='OrderTimeline'>{timelineElements}</div>
        )
    }

    getTimelineElements = () => {
        if (!this.state.data || this.state.data.length === 0) {
            return null;
        }

        return this.state.data.map(item => {
            return (
                <div className='Item'>
                    <div className='head'>
                        <div className='date'>{Utils.dateToStringDisplay(new Date(item.date), '.')}</div>
                        <div className='who'>{item.who}</div>

                        {item.meta &&
                            <Icon className='meta' name='question circle' onClick={()=>this.setState({expandedMetaForId:item.id})}/>
                        }
                    </div>
                    <div className='what' dangerouslySetInnerHTML={{__html: item.what}}></div>



                    {(item.meta && this.state.expandedMetaForId===item.id) &&
                        <pre className='meta'>{JSON.stringify(JSON.parse(item.meta), null, 4)}</pre>
                    }
                </div>
            )
        })
    }

}


export default OrderTimeline;
