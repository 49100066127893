import React, {Component} from 'react';
import './InvoicesScreen.scss';
import ApiService from "../services/ApiService";
import InvoiceElement from "../components/InvoiceElement";
import InvoiceEditor from "../components/InvoiceEditor";
import {Button, Icon, Input, Pagination, Table} from "semantic-ui-react";
import moment from "moment-timezone";
import Utils from "../data/Utils";

class InvoicesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            filter: '',
            downloadAllLabel: '',
            editingInvoiceId: 0,
            totalPages: 10,
            currentPage: 1,

            searchTerm: '',
            listIsSearchResult: false,

            loadInProgress: false
        }
    }

    componentDidMount() {
        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search);
            const page = searchParams.get("page");
            const searchTerm = searchParams.get("searchTerm");

            if(!page){
                searchParams.set("page", "1");
                let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                window.history.pushState(null, '', newRelativePathQuery);
            }

            this.setState({
                currentPage: page || 1,
                searchTerm: searchTerm || '',
                listIsSearchResult: searchTerm ? searchTerm.length > 1 : false
            })
        }

        this.loadData();
    }

    render() {
        const invoicesElement = this.getInvoicesElement();
        const invoicesTableElement = this.getInvoicesTableElement();

        const {column, data, direction} = this.state;

        return (
            <div className='InvoicesScreen'>
                <div className="header">
                    <Button primary onClick={this.onCreateNewInvoiceClicked}>New Invoice</Button>

                    <div className="searchField">
                        <Input placeholder='John Doe or EXT-123'
                               value={this.state.searchTerm} onChange={this.onSearchTermChanged}/>
                        <Button onClick={this.searchByTerm}>Search</Button>
                    </div>
                </div>


                {(this.state.searchTerm && this.state.listIsSearchResult) &&
                    <div className="search-prompt">
                        <div className="label">
                            Search results for {this.state.searchTerm}:
                        </div>
                        <Button icon color="red" size="tiny" onClick={this.resetSearch}><Icon name='cancel'/></Button>
                    </div>
                }

                <div className='list'>
                    {/*{invoicesElement}*/}

                    <Table celled fixed selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    width={2}
                                >
                                    Nr.
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                >
                                    Client
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={3}
                                >
                                    Date
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                >
                                    Amount
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={3}
                                >
                                    Actions
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.loadInProgress &&
                            <h3 style={{margin:'30px'}}>Loading...</h3>
                            }
                            {invoicesTableElement}
                        </Table.Body>
                    </Table>


                </div>

                {this.state.loadInProgress === false &&
                <div className="pagination">
                    <Pagination
                        activePage={this.state.currentPage}
                        onPageChange={this.handlePaginationChange}
                        totalPages={this.state.totalPages}
                        siblingRange={3}
                    />
                </div>
                }

                {this.state.editorVisible &&

                    <InvoiceEditor
                        invoiceId={this.state.editingInvoiceId}
                        visible={this.state.editorVisible}
                        onClose={() => this.setState({editorVisible: false, editingInvoiceData: null})}
                        onSaved={this.onInvoiceSaved}
                    />

                }
            </div>
        );
    }

    resetSearch = () => {
        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search);

            searchParams.delete("searchTerm", "1");
            searchParams.set("page", "1");
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({listIsSearchResult: false, searchTerm: '', currentPage: 1}, () => {
            this.loadData()
        })
    }

    searchByTerm = () => {
        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search);

            searchParams.set("page", "1");
            searchParams.set("searchTerm", this.state.searchTerm);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({listIsSearchResult: true, currentPage: 1}, () => {
            this.loadData();
        })
    }


    handlePaginationChange = (e, {activePage}) => {
        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search);

            searchParams.set("page", activePage);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({currentPage: activePage}, () => {
            this.setState({invoices: []}, () => {
                this.loadData()
            })
        })
    }

    getInvoicesTableElement = () => {
        if (this.state.invoices.length === 0) {
            return null;
        }

        return this.state.invoices.map(invoice => {
            let style = {};
            if (invoice.autogenerated) {
                style = {
                    background: '#ff9090',
                    padding: '10px'
                }
            }

            return (
                <Table.Row key={invoice.id} style={style}>
                    <Table.Cell onClick={() => console.log("c")}>{invoice.series}-{invoice.series_count}</Table.Cell>
                    <Table.Cell>{invoice.data.clientName}</Table.Cell>
                    <Table.Cell>{Utils.stringToDateDisplay(invoice.date)}</Table.Cell>
                    <Table.Cell>{invoice.data.currency} {invoice.data.total}</Table.Cell>
                    <Table.Cell selectable={false}>
                        <div style={{display: 'flex'}}>
                            <div className='table-actions'
                                 onClick={() => this.downloadInvoiceById(invoice.id)}>download
                            </div>
                            <div className='table-actions'
                                 onClick={() => this.onInvoiceEditClicked(invoice)}>edit {invoice.changes > 0 &&
                                <span style={{marginLeft: '15px'}}><Icon style={{color: 'red'}}
                                                                         name='warning sign'/>{invoice.changes} changes</span>}</div>
                        </div>
                    </Table.Cell>
                </Table.Row>
            )
        })

    };

    downloadInvoiceById = (id) => {
        return new Promise(resolve => {
            ApiService.downloadInvoiceById(id).then(data => {
                return resolve();
            })
        })
    };

    getInvoicesElement = () => {
        if (this.state.invoices.length === 0) {
            return null;
        }

        return this.state.invoices.map(invoice => {
            return (
                <InvoiceElement key={invoice.id} data={invoice} onInvoiceEditClicked={this.onInvoiceEditClicked}/>
            )
        })
    };

    onInvoiceEditClicked = (data) => {
        this.setState({editorVisible: true, editingInvoiceId: data.id});
    };

    onInvoiceSaved = (invoiceData) => {
        this.setState({editorVisible: false, editingInvoiceData: null}, () => {
            this.loadData();
        });
    };

    onSearchTermChanged = (e, {value}) => {
        this.setState({searchTerm: value})
    };

    loadData = async ()=>{
        this.setState({loadInProgress: true, invoices:[]},async()=>{
            await this.loadDataAction();
        })
    }

    loadDataAction = async () => {
        let data;

        if (this.state.searchTerm.length) {
            data = await ApiService.getInvoicesSearchByTerm(this.state.searchTerm, this.state.currentPage);
        } else {
            data = await ApiService.getInvoices(this.state.currentPage);
        }

        this.setState({
            invoices: data.data.invoices,
            totalPages: Math.ceil(data.data.total / 100),
            loadInProgress: false
        })
    };

    onCreateNewInvoiceClicked = () => {
        this.setState({
            editorVisible: true,
            editingInvoiceId: null
        })
    }

    handleSort = (clickedColumn) => () => {
        const {column, data, direction} = this.state

        // if (column !== clickedColumn) {
        //     this.setState({
        //         column: clickedColumn,
        //         data: _.sortBy(data, [clickedColumn]),
        //         direction: 'ascending',
        //     })
        //
        //     return
        // }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

}


export default InvoicesScreen;
