import React, {Component} from 'react';

import './OrderPiece.scss';
import {Button, Dropdown, Input} from "semantic-ui-react";
import OrderPieceAlbum from "./orderPieces/OrderPieceAlbum";

class OrderPiece extends Component {
    constructor(props) {
        super(props);

        this.state = {
            translatedOrder: this.props.transformedData || {},
            dirty: false
        }
    }

    render() {
        const configElement = this.getConfigElement();
        return (
            <div className='OrderPiece'>

                <div className='raw'>
                    <div className='line1'>
                        <div className='title'>{this.props.data.sku} x {this.props.data.quantity}</div>
                        <div className='price'>{this.props.data.currency} {this.props.data.price}</div>
                    </div>
                    {configElement}

                </div>
            </div>
        );
    }


    getConfigElement = () => {
        if (!this.props.data.config || this.props.data.config.length === 0) {
            return null;
        }

        return this.props.data.config.map(item => {
            return (
                <div key={Math.random()} className='instructions'>
                    <div className='key'>{item.name}</div>
                    <div className='value'>{item.value.replace('&quot;', `"`)}</div>
                </div>
            )
        })
    };


    getProductTypeDetails = () => {
        switch (this.state.translatedOrder.productType) {
            case 'album':
                return this.getAlbumDetails();
            case 'guestbook':
                return this.getAlbumDetails();
            case 'corners':
                return this.getCornerDetails();
                default:
                return this.detDefaultDetails();
        }
    };

    getAlbumDetails = () => {
        const o = this.state.translatedOrder;
        let cornersRandId = Math.random().toString().split('.')[1];
        let pageTypeRandId = Math.random().toString().split('.')[1];
        let pageSpacersRandId = Math.random().toString().split('.')[1];
        let coverUvRandId = Math.random().toString().split('.')[1];
        let embossRandId = Math.random().toString().split('.')[1];

        return (
            <div>
                <Input size='mini' style={{margin: '5px 0'}} label='Quantity' name='quantity' placeholder='1,2,3'
                       value={o.quantity} onChange={this.onTranslatedOrderChanged}/><br/>
                <Input size='mini' style={{margin: '5px 0'}} label='Size' name='album_size' placeholder='20x50'
                       value={o.album_size} onChange={this.onTranslatedOrderChanged}/><br/>
                <Input size='mini' style={{margin: '5px 0'}} label='Nr. Pages' name='nr_pages' placeholder='60'
                       value={o.nr_pages} onChange={this.onTranslatedOrderChanged}/><br/>

                <Input size='mini' style={{margin: '5px 0'}} label='Material' name='material'
                       placeholder='Brown leather'
                       value={o.material} onChange={this.onTranslatedOrderChanged}/><br/>

                <div>(old: {this.state.translatedOrder.corner_amount} {this.state.translatedOrder.corner_color})</div>


                <div style={{display: 'flex', alignItems: 'center', margin: '5px 0'}}>
                    <div className="ui label label" style={{marginRight: '5px'}}>Corners</div>


                    <div style={{marginRight: '10px'}}>
                        <label>
                            white
                            <input
                                style={{marginRight: '3px'}}
                                type="input"
                                name={`${cornersRandId}corners_white`}
                                value={this.state.translatedOrder.corners_white}
                                onChange={(e)=>this.onCornerColorChanged("corners_white", e.target.value)}
                            />

                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            black
                            <input
                                style={{marginRight: '3px'}}
                                type="input"
                                name={`${cornersRandId}corners_black`}
                                value={this.state.translatedOrder.corners_black}
                                onChange={(e)=>this.onCornerColorChanged("corners_black", e.target.value)}
                            />

                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            kraft
                            <input
                                style={{marginRight: '3px'}}
                                type="input"
                                name={`${cornersRandId}corners_kreaft`}
                                value={this.state.translatedOrder.corners_kraft}
                                onChange={(e)=>this.onCornerColorChanged("corners_kraft", e.target.value)}
                            />

                        </label>
                    </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', margin: '5px 0'}}>
                    <div className="ui label label" style={{marginRight: '5px'}}>Pages</div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${pageTypeRandId}page-type`}
                                value="white"
                                checked={this.state.translatedOrder.page_type === "white"}
                                onChange={this.onPageTypeChanged}
                            />
                            white
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${pageTypeRandId}page-type`}
                                value="black"
                                checked={this.state.translatedOrder.page_type === "black"}
                                onChange={this.onPageTypeChanged}
                            />
                            black
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${pageTypeRandId}page-type`}
                                value="photo"
                                checked={this.state.translatedOrder.page_type === "photo"}
                                onChange={this.onPageTypeChanged}
                            />
                            photo
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${pageTypeRandId}page-type`}
                                value="text_print"
                                checked={this.state.translatedOrder.page_type === "text_print"}
                                onChange={this.onPageTypeChanged}
                            />
                            printed text
                        </label>
                    </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', margin: '5px 0'}}>
                    <div className="ui label label" style={{marginRight: '5px'}}>Spacers</div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${pageSpacersRandId}page-spacers`}
                                value="yes"
                                checked={this.state.translatedOrder.page_spacer === "yes"}
                                onChange={this.onPageSpacersChanged}
                            />
                            yes
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${pageSpacersRandId}page-spacers`}
                                value="no"
                                checked={this.state.translatedOrder.page_spacer === "no"}
                                onChange={this.onPageSpacersChanged}
                            />
                            no
                        </label>
                    </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', margin: '5px 0'}}>
                    <div className="ui label label" style={{marginRight: '5px'}}>UV Print</div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${coverUvRandId}cover_uv`}
                                value="yes"
                                checked={this.state.translatedOrder.cover_uv === "yes"}
                                onChange={this.onCoverUvChanged}
                            />
                            yes
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${coverUvRandId}cover_uv`}
                                value="no"
                                checked={this.state.translatedOrder.cover_uv === "no"}
                                onChange={this.onCoverUvChanged}
                            />
                            no
                        </label>
                    </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', margin: '5px 0'}}>
                    <div className="ui label label" style={{marginRight: '5px'}}>Emboss</div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="no"
                                checked={this.state.translatedOrder.emboss === "no"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            no
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="blind"
                                checked={this.state.translatedOrder.emboss === "blind"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            blind
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="gold"
                                checked={this.state.translatedOrder.emboss === "gold"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            gold
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="transparent"
                                checked={this.state.translatedOrder.emboss === "transparent"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            transparent
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="silver"
                                checked={this.state.translatedOrder.emboss === "silver"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            silver
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="rose gold"
                                checked={this.state.translatedOrder.emboss === "rose gold"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            rose gold
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="cream"
                                checked={this.state.translatedOrder.emboss === "cream"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            cream
                        </label>
                    </div>
                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="navy"
                                checked={this.state.translatedOrder.emboss === "navy"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            navy
                        </label>
                    </div>
                    <div style={{marginRight: '10px'}}>
                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="radio"
                                name={`${embossRandId}emboss`}
                                value="burgundy"
                                checked={this.state.translatedOrder.emboss === "burgundy"}
                                onChange={this.onCoverEmbossChanged}
                            />
                            burgundy
                        </label>
                    </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Input style={{width: '350px', margin: '5px 0'}} size='mini' label='Emboss L1' name='emboss_l1_text'
                           placeholder='Laura & Adrian' value={o.emboss_l1_text}
                           onChange={this.onTranslatedOrderChanged}/>
                    <br/>

                    <Dropdown style={{marginLeft: '20px'}} name='emboss_l1_size' inline options={[
                        {key: 1, text: '24pt', value: 24},
                        {key: 2, text: '32pt', value: 32}
                    ]} placeholder='Font Size' value={o.emboss_l1_size} onChange={this.onTranslatedOrderChanged}/>

                    <Dropdown style={{marginLeft: '20px'}} name='emboss_l1_font' inline options={[
                        {key: 1, text: 'Goudy', value: 'goudy.ttf'},
                        {key: 2, text: 'Goudy Coursive', value: 'goudycoursive.otf'}
                    ]} placeholder='Font' value={o.emboss_l1_font} onChange={this.onTranslatedOrderChanged}/>
                </div>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Input style={{width: '350px', margin: '5px 0'}} size='mini' label='Emboss L2' name='emboss_l2_text'
                           placeholder='January 18 2025' value={o.emboss_l2_text}
                           onChange={this.onTranslatedOrderChanged}/>
                    <br/>

                    <Dropdown style={{marginLeft: '20px'}} name='emboss_l2_size' inline options={[
                        {key: 1, text: '24pt', value: 24},
                        {key: 2, text: '32pt', value: 32}
                    ]} placeholder='Font Size' value={o.emboss_l2_size} onChange={this.onTranslatedOrderChanged}/>

                    <Dropdown style={{marginLeft: '20px'}} name='emboss_l2_font' inline options={[
                        {key: 1, text: 'Goudy', value: 'goudy.ttf'},
                        {key: 2, text: 'Goudy Coursive', value: 'goudycoursive.otf'}
                    ]} placeholder='Font' value={o.emboss_l2_font} onChange={this.onTranslatedOrderChanged}/>
                </div>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Input style={{width: '350px', margin: '5px 0'}} size='mini' label='Emboss L3' name='emboss_l3_text'
                           placeholder='....' value={o.emboss_l3_text} onChange={this.onTranslatedOrderChanged}/>
                    <br/>

                    <Dropdown style={{marginLeft: '20px'}} name='emboss_l3_size' inline options={[
                        {key: 1, text: '24pt', value: 24},
                        {key: 2, text: '32pt', value: 32}
                    ]} placeholder='Font Size' value={o.emboss_l3_size} onChange={this.onTranslatedOrderChanged}/>

                    <Dropdown style={{marginLeft: '20px'}} name='emboss_l3_font' inline options={[
                        {key: 1, text: 'Goudy', value: 'goudy.ttf'},
                        {key: 2, text: 'Goudy Coursive', value: 'goudycoursive.otf'}
                    ]} placeholder='Font' value={o.emboss_l3_font} onChange={this.onTranslatedOrderChanged}/>
                </div>

                {this.state.dirty &&
                    <Button color='red' onClick={this.applyItemDetails}>ok</Button>
                }
            </div>
        )
    };

    getCornerDetails = () => {
        const o = this.state.translatedOrder;
        let cornersRandId = Math.random().toString().split('.')[1];

        return (
            <div>
                <div>(old: {this.state.translatedOrder.corner_amount} {this.state.translatedOrder.corner_color})</div>
                <div style={{display: 'flex', alignItems: 'center', margin: '5px 0'}}>
                    <div className="ui label label" style={{marginRight: '5px'}}>Color</div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            white
                            <input
                                style={{marginRight: '3px'}}
                                type="input"
                                name={`${cornersRandId}corners_white`}
                                value={this.state.translatedOrder.corners_white}
                                onChange={(e)=>this.onCornerColorChanged("corners_white", e.target.value)}
                            />
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            black
                            <input
                                style={{marginRight: '3px'}}
                                type="input"
                                name={`${cornersRandId}corners_black`}
                                value={this.state.translatedOrder.corners_black}
                                onChange={(e)=>this.onCornerColorChanged("corners_black", e.target.value)}
                            />
                        </label>
                    </div>

                    <div style={{marginRight: '10px'}}>
                        <label>
                            kraft
                            <input
                                style={{marginRight: '3px'}}
                                type="input"
                                name={`${cornersRandId}corners_kraft`}
                                value={this.state.translatedOrder.corners_kraft}
                                onChange={(e)=>this.onCornerColorChanged("corners_kraft", e.target.value)}
                            />
                        </label>
                    </div>


                </div>

                {this.state.dirty &&
                <Button color='red' onClick={this.applyItemDetails}>ok</Button>
                }
            </div>
        )
    };

    detDefaultDetails = () => {
        const o = this.state.translatedOrder;

        return (
            <div>
                {this.state.dirty &&
                <Button color='red' onClick={this.applyItemDetails}>ok</Button>
                }
            </div>
        )
    };

    applyItemDetails = () => {
        let item = {...this.state.translatedOrder};
        if(item.productType === 'album' || item.productType === 'guestbook'){
            if(!item.progress){
                item.progress =  {
                    "bloc": 0,
                    "coperta": 0,
                    "stantat": 0,
                    "album": 0,
                    "impachetat": 0,
                };
            }

            if(!item.cover_uv || item.cover_uv === 'no'){
                delete item.progress.uv;
            } else {
                if(!item.progress.uv){
                    item.progress.uv = 0;
                }
            }

            if(!item.emboss || item.emboss === 'no'){
                delete item.progress.stantat;
            } else {
                if(!item.progress.stantat){
                    item.progress.stantat = 0;
                }
            }
        }
        this.setState({dirty: false}, ()=>{
            this.props.onOrderPieceDetailsChanged(this.props.data.item_id, {...item})
        });
    }

    onPageTypeChanged = (event) => {
        let translatedOrder = {...this.state.translatedOrder};
        translatedOrder['page_type'] = event.target.value;

        this.setState({
            translatedOrder: translatedOrder,
            dirty: true
        });
    };

    onCoverEmbossChanged = (event) => {
        let translatedOrder = {...this.state.translatedOrder};
        translatedOrder['emboss'] = event.target.value;

        this.setState({
            translatedOrder: translatedOrder,
            dirty: true
        });
    };

    onCornerColorChanged = (name, value) => {
        let translatedOrder = {...this.state.translatedOrder};
        translatedOrder[name] =value;

        this.setState({
            translatedOrder: translatedOrder,
            dirty: true
        });
    };

    onCoverUvChanged = (event) => {
        let translatedOrder = {...this.state.translatedOrder};
        translatedOrder['cover_uv'] = event.target.value;

        this.setState({
            translatedOrder: translatedOrder,
            dirty: true
        });
    };

    onPageSpacersChanged = (event) => {
        let translatedOrder = {...this.state.translatedOrder};
        translatedOrder['page_spacer'] = event.target.value;

        this.setState({
            translatedOrder: translatedOrder,
            dirty: true
        });
    };

    onTranslatedOrderChanged = (e, {name, value}) => {
        let translatedOrder = {...this.state.translatedOrder};

        translatedOrder[name] = value;

        this.setState({translatedOrder: translatedOrder, dirty: true})
    };

}


export default OrderPiece;
