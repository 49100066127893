import React, {Component} from 'react';
import {formatDistanceToNow} from "date-fns";

import './EtsySyncStats.scss';
import ApiService from "../services/ApiService";

export class ExchangeSyncStats extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.data) {
            return null;
        }

        const lastEtsySync = formatDistanceToNow(this.props.data.last, {addSuffix: true});

        let autoClassName = this.props.data.enabled ? 'value green button' : 'value red button';

        let now = new Date().getTime();
        let syncDiff = (now - this.props.data.last) / 1000;


        let lastClassName = syncDiff > 1900 ? 'value red' : 'value green';

        return (
            <div className='EtsySyncStats'>
                <div className='title'>Exchange sync</div>
                <div className='content'>
                    <div className='item'>
                        <div className='name'>last</div>
                        <div className={lastClassName}>{lastEtsySync}</div>
                    </div>
                    <div className='item'>
                        <div className='name'>last day</div>
                        <div className='value green'>{this.props.data.lastSyncedDay}</div>
                    </div>
                    <div className='item'>
                        <div className='name'>force sync</div>
                        <div className='value blue button' onClick={this.props.onExchangeSyncClicked}>start</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExchangeSyncStats;