import React, {Component} from 'react';
import './OrderPieceElement.scss';
import {Dropdown, Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ApiService from "../../services/ApiService";
import {Generate} from "../utils/checksum";
import UvPackingPrintCheck from "../UvPackingPrintCheck";
import ConsumptionPanel from "../ConsumptionPanel";
import ConsumptionAvailabilityPanel from "../ConsumptionAvailabilityPanel";
import ProductPersonalisation from "../personalisation/ProductPersonalisation";

class OrderPieceBoudoirEmpty extends Component {
    constructor(props) {
        super(props);

        let hasProgress = false;
        if(this.props.data.progress && Object.keys(this.props.data.progress).length){
            hasProgress = true;
        }


        this.state = {
            productType: this.props.data.product_type || 'boudoir-empty',
            invoiceName: this.props.data.invoiceName || 'Photo album',
            orderId: this.props.data.orderId || 0,
            item: {
                size: this.props.data.item.size || {id: 0, label: ''},
                nr_pages: this.props.data.item.nr_pages || '',
                material: this.props.data.item.material || {id: 0, label: ''},
                cover_style: this.props.data.item.cover_style || {id: 0, label: ''},
                corners_white: this.props.data.item.corners_white || 0,
                corners_black: this.props.data.item.corners_black || 0,
                corners_kraft: this.props.data.item.corners_kraft || 0,
                page_type: this.props.data.item.page_type || {id: 0, label: ''},
                cover_uv: this.props.data.item.cover_uv || '',
                cover_uv_image: this.props.data.item.cover_uv_image || '',
                page_spacer: this.props.data.item.page_spacer || '',

                folio_color: this.props.data.item.folio_color,
                laser_engraving: this.props.data.item.laser_engraving,
                emboss: this.props.data.item.emboss || {id: 0, label: ''},
                emboss_l1_text: this.props.data.item.emboss_l1_text || '',
                emboss_l1_size: this.getFontSizeHack(this.props.data.item.emboss_l1_size),
                emboss_l1_font: this.getFontByNameHack(this.props.data.item.emboss_l1_font),

                emboss_l2_text: this.props.data.item.emboss_l2_text || '',
                emboss_l2_size: this.getFontSizeHack(this.props.data.item.emboss_l2_size),
                emboss_l2_font: this.getFontByNameHack(this.props.data.item.emboss_l2_font),

                emboss_l3_text: this.props.data.item.emboss_l3_text || '',
                emboss_l3_size: this.getFontSizeHack(this.props.data.item.emboss_l3_size),
                emboss_l3_font: this.getFontByNameHack(this.props.data.item.emboss_l3_font),

                emboss_l4_text: this.props.data.item.emboss_l4_text || '',
                emboss_l4_size: this.getFontSizeHack(this.props.data.item.emboss_l4_size),
                emboss_l4_font: this.getFontByNameHack(this.props.data.item.emboss_l4_font),

                emboss_l5_text: this.props.data.item.emboss_l5_text || '',
                emboss_l5_size: this.getFontSizeHack(this.props.data.item.emboss_l5_size),
                emboss_l5_font: this.getFontByNameHack(this.props.data.item.emboss_l5_font),

                embossTemplate: this.props.data.item.embossTemplate || {id: 0, name: ''},

                textPosition: this.props.data.item.textPosition || 1,
                textAlignment: this.props.data.item.textAlignment || 1,

                faceoff: this.props.data.item.faceoff || 'nu'
            },
            progress: hasProgress ? this.props.data.progress :  {
                bloc: 0,
                coperta: 0,
                stantat: 0,
                uv: 0,
                folie_termo: 0,
                laser_engraving: 0,
                impachetat: 0,
                vellum: 0,
            },
            details: this.props.data.details || "",
            price: this.props.data.price || '',
            currency: this.props.data.currency || "USD",
            quantity: this.props.data.quantity || 0,
            id: this.props.data.id || -1
        }
    }

    render() {
        let formInvalid = false;

        if (!this.state.invoiceName || !this.state.invoiceName.length) {
            console.log("FAILED invoiceName")
            formInvalid = true;
        }

        if (!this.state.quantity) {
            console.log("FAILED quantity")
            formInvalid = true;
        }

        if (!this.state.price || !this.state.price.length) {
            console.log("FAILED price")
            formInvalid = true;
        }

        if (!this.state.currency || !this.state.currency.length) {
            console.log("FAILED currency")
            formInvalid = true;
        }

        if (!this.state.item.size || !this.state.item.size.id) {
            console.log("FAILED size")
            formInvalid = true;
        }

        if (!this.state.item.cover_style || !this.state.item.cover_style.id) {
            console.log("FAILED cover_style")
            formInvalid = true;
        }

        if (!this.state.item.nr_pages || !this.state.item.nr_pages.length) {
            console.log("FAILED nr_pages")
            formInvalid = true;
        }

        if (!this.state.item.material || !this.state.item.material.id) {
            console.log("FAILED material")
            formInvalid = true;
        }

        if (this.state.item.corners_white === null || this.state.item.corners_white === undefined) {
            console.log("FAILED corners_white")
            formInvalid = true;
        }
        if (this.state.item.corners_black === null || this.state.item.corners_black === undefined) {
            console.log("FAILED corners_black")
            formInvalid = true;
        }
        if (this.state.item.corners_kraft === null || this.state.item.corners_kraft === undefined) {
            console.log("FAILED corners_kraft")
            formInvalid = true;
        }

        if (!this.state.item.page_type.label || !this.state.item.page_type.label.length) {
            console.log("FAILED page_type")
            formInvalid = true;
        }

        if (!this.state.item.cover_uv || !this.state.item.cover_uv.length) {
            console.log("FAILED cover_uv")
            formInvalid = true;
        }

        if (!this.state.item.page_spacer || !this.state.item.page_spacer.length) {
            console.log("FAILED page_spacer")
            formInvalid = true;
        }

        if (!this.state.item.emboss.label || !this.state.item.emboss.label.length) {
            console.log("FAILED emboss")
            formInvalid = true;
        }

        const fontSizesL1 = this.getFontSizes(this.state.item.emboss_l1_font.label);
        const fontSizesL2 = this.getFontSizes(this.state.item.emboss_l2_font.label);
        const fontSizesL3 = this.getFontSizes(this.state.item.emboss_l3_font.label);

        const mainOrderId = this.props.mainOrderId ? this.props.mainOrderId : this.props.data.orders_id;
        const codeSuffix = this.props.data.product_type.substring(0, 3).toUpperCase() + "-" + this.props.data.id;
        const photosName = `${Generate(mainOrderId)}-${codeSuffix}`

        const disableEdit = (this.props.orderStatus === 'in production' || this.props.orderStatus === 'urgent');
        const mainClassName = disableEdit ? 'OrderPieceElement disableAll' : 'OrderPieceElement';

        return (
            <div className={mainClassName}>
                <div className='header'>
                    <h3 className='name'>Boudoir Empty {photosName}</h3>
                    {this.state.id === -1 &&
                    <div className='ct' onClick={this.props.onChangeTypeClicked}>change type</div>
                    }
                    {this.state.id !== -1 &&
                    <div className='delete' onClick={() => this.props.onDeleteClicked(this.state.id)}>delete</div>
                    }
                </div>

                {(disableEdit === true) &&
                    <div style={{color:'orange', marginTop:'0'}}>
                        Editing disabled while the order is in production.
                    </div>
                }

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Invoice name*</div>
                        <Input style={{width: '200px'}} value={this.state.invoiceName}
                               onChange={(e) => this.onMainFieldChanged('invoiceName', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Price*</div>
                        <Input style={{width: '80px'}} value={this.state.price}
                               onChange={(e) => this.onMainFieldChanged('price', e.target.value)}/>
                        <Input style={{width: '65px'}} value={this.state.currency}
                               onChange={(e) => this.onMainFieldChanged('currency', e.target.value)}/>

                    </div>

                    <div className='item'>
                        <div className='label'>Quantity*</div>
                        <Input style={{width: '60px'}} value={this.state.quantity}
                               onChange={(e) => this.onMainFieldChanged('quantity', e.target.value)}/>
                    </div>
                </div>

                <div style={{width: '100%', height: '1px', background: '#f3f3f3', marginTop: '20px'}}></div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Size*</div>

                        <Dropdown
                            options={this.props.configs.sizes.filter(i => i.type === 'boudoir-empty').map(i => {
                                return {
                                    key: i.id,
                                    value: i.cm,
                                    text: i.cm,
                                    id: i.id
                                }
                            })}
                            selection compact
                            value={this.state.item.size.value}
                            search
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onItemFieldChanged('size', item.options.find(o => o.value === item.value))}
                        />

                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Nr.Pages*</div>
                        <Input style={{width: '150px'}} value={this.state.item.nr_pages}
                               onChange={(e) => this.onItemFieldChanged('nr_pages', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Material*</div>
                        <Dropdown
                            options={this.props.configs.materials.filter(i => i['for_boudoir-empty'] === 1).map(i => {
                                return {
                                    key: i.id,
                                    value: i.name,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            search
                            value={this.state.item.material.value}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onItemFieldChanged('material', item.options.find(o => o.value === item.value))}
                        />
                    </div>

                    <div className='item'>
                        <div className='label'>Cover style*</div>

                        <Dropdown
                            options={this.props.configs.styles.filter(i => i.type === 'boudoir-empty').map(i => {
                                return {
                                    key: i.id,
                                    value: i.name,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            search
                            value={this.state.item.cover_style.value}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onItemFieldChanged('cover_style', item.options.find(o => o.value === item.value))}
                        />
                    </div>

                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Pages*</div>

                        <Dropdown
                            options={[
                                {
                                    id: 1,
                                    text: 'white',
                                    value: 'white',
                                },
                                {
                                    id: 2,
                                    text: 'black',
                                    value: 'black',
                                },
                                {
                                    id: 3,
                                    text: 'photo',
                                    value: 'photo',
                                },
                                {
                                    id: 4,
                                    text: 'printed text',
                                    value: 'printed text',
                                },

                            ]}
                            selection compact
                            value={this.state.item.page_type.label}
                            style={{minWidth: '100px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('page_type.label', value)}
                        />
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Spacers*</div>

                        <Dropdown
                            options={[
                                {
                                    id: 1,
                                    text: 'yes',
                                    value: 'yes',
                                },
                                {
                                    id: 2,
                                    text: 'no',
                                    value: 'no',
                                }

                            ]}
                            selection compact
                            value={this.state.item.page_spacer}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('page_spacer', value)}
                        />
                    </div>

                    <div className='item'>
                        <div className='label'>Faceoff*</div>

                        <Dropdown
                            options={['nu', 'alb', 'crem', 'negru'].map(i => {
                                return {
                                    key: i,
                                    value: i,
                                    text: i,
                                    id: i
                                }
                            })}
                            selection compact
                            value={this.state.item.faceoff}
                            style={{minWidth: '200px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('faceoff', value)}
                        />
                    </div>

                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item'>
                        <div className='label'>Vellum text</div>

                        <pre>{this.props.data.item.vellumText}</pre>
                    </div>

                    <div className='item' style={{marginLeft: '40px'}}>
                        <div className='label'>Vellum text alignment</div>

                        <pre>{this.props.data.item.vellumAlignment}</pre>
                    </div>
                </div>

                <div style={{width: '100%', height: '1px', background: '#f3f3f3', marginTop: '20px'}}></div>

                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '20px'}}>
                    <div className='item'>
                        <ProductPersonalisation
                            item={this.state.item}
                            configs={this.props.configs}
                            onItemFieldChanged={this.onItemFieldChanged}
                            onEmbossTemplateChanged={this.onEmbossTemplateChanged}
                            onImageUploaded={(image) => this.onItemFieldChanged('cover_uv_image', image)}/>
                    </div>
                </div>

                <div style={{width: '100%', height: '1px', background: '#f3f3f3', marginTop: '20px'}}></div>

                {this.props.data.item.clientRequest &&

                <div className='item'>
                    <div className='label'>Client notes</div>
                    <div className='client-request'>{this.props.data.item.clientRequest}</div>
                </div>

                }

                <div className='item'>
                    <div className='label'>Production notes</div>
                    <textarea id="story"
                              name="story"
                              rows="3" cols="33"
                              value={this.state.details}
                              onChange={(e) => this.onMainFieldChanged('details', e.target.value)}
                              style={{width: '100%'}}>

                        </textarea>
                </div>

                {!this.props.data.consumption_meta &&
                    <div className='item'>
                        <ConsumptionAvailabilityPanel data={this.props.data.needed_skus_meta} type={this.props.data.product_type}/>
                    </div>
                }

                <div className='item'>
                    <ConsumptionPanel data={this.props.data.consumption_meta} type={this.props.data.product_type}/>
                </div>

                {this.state.dirty &&
                <div>
                    <Button color='red' disabled={formInvalid} compact
                            onClick={this.onOrderPieceSaveClicked}>Save</Button>
                    <div>Warning! This action will remove the item from production. You will have to manually add it back to production.</div>
                </div>
                }

            </div>
        );
    }

    getFontSizes = (fontName) => {
        if(!fontName){
            return [];
        }

        let font = this.props.configs.fonts.find(f=>f.name === fontName);
        if(!font){
            return [];
        }

        if(!font.sizes){
            return []
        }

        return font.sizes.split(' ').map(size=>{
            return {
                key: size, text: `${size}pt`, value: size
            }
        })
    }

    getFontByNameHack = (font) => {
        if(!font){
            return {id:0, label: ''}
        }

        let found = null;

        switch(font.label) {
            case 'goudy.ttf':
                found = this.props.configs.fonts.find(f=>f.name === 'Goudy Old Style');
                break;
            case 'Goudy':
                found = this.props.configs.fonts.find(f=>f.name === 'Goudy Old Style');
                break;
            case 'goudycoursive.otf':
                found =  this.props.configs.fonts.find(f=>f.name === 'Goudy Cursive');
                break;
            case 'traftonscript.ttf':
                found = this.props.configs.fonts.find(f=>f.name === 'Trafton Script');
                break;
            default:
                found = this.props.configs.fonts.find(f=>f.name === font.label);
        }

        if(!found){
            return {id:0, label: ''};
        }

        return {id:found.id, label: found.name}

    }

    getFontSizeHack = (font) => {
        if(!font){
            return {id:0, label: ''}
        }

        if(font.label){
            if(font.label.toString() === '32'){
                font.label = 36;
            }
            font.label = font.label.toString();
        }

        return font;
    }

    onMainFieldChanged = (field, value) => {
        this.setState({[field]: value, dirty: true})
    };

    onItemFieldChanged = (field, value) => {
        let item = {...this.state.item};
        if (field.indexOf('.') > 0) {
            let parts = field.split('.');
            item[parts[0]][parts[1]] = value;
        } else {
            item[field] = value;
        }

        this.setState({item: item, dirty: true});
    };

    onOrderPieceSaveClicked = () => {
        this.setState({
            dirty: false
        }, () => {
            this.props.onOrderItemSaveClicked({...this.state});
        })
    };

    onEmbossTemplateChanged = (embossTemplate) => {
        let item = {...this.state.item};
        item.embossTemplate = {id: embossTemplate, name: `${embossTemplate}`};

        const chosenTemplate = this.props.configs.embossTemplatesConfig.find(i => i.name === embossTemplate);

        for (let i = 1; i <= 5; i++) {
            item[`emboss_l${i}_font`] = {id: 0, label: ""}
            item[`emboss_l${i}_size`] = {id: 0, label: ""}
        }

        for (const lineNr in chosenTemplate.lines) {
            const lineConfig = chosenTemplate.lines[lineNr];
            const font = this.props.configs.fonts.find(f => f.name === lineConfig.font);

            item[`emboss_l${lineNr}_font`] = {id: font.id, label: lineConfig.font}
            item[`emboss_l${lineNr}_size`] = {id: 0, label: lineConfig.size}
        }

        console.log(item)

        this.setState({item: item, dirty: true});
    }
}


export default OrderPieceBoudoirEmpty;
