import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class SizesConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, sizes: []};
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            loading: true
        }, () => {
            ApiService.getSizes(this.props.type).then(data => {
                this.setState({loading: false, sizes: data.data.sizes});
            });
        });
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getSizes(this.props.type).then(data => {
                this.setState({loading: false, sizes: data.data.sizes});
            });
        });

    }

    render() {
        const sizes = this.getSizes();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Table  fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                width={2}>
                                CM
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={2}
                            >
                                CM User
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={2}
                            >
                                INCH
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={2}
                            >
                                RATIO
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={3}
                            >
                                ASPECT
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={1}
                                textAlign='center'
                            >
                                AVAILABLE
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={1}
                                textAlign='center'
                            >
                                ACTION
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sizes}
                    </Table.Body>
                </Table>

                <Button style={{marginTop: '3rem'}} content='add new size'  basic onClick={this.onAddNewSizeClicked}/>
            </Container>
        );
    }

    getSizes = () => {
        if (this.state.sizes.length === 0) {
            return null;
        }

        return this.state.sizes.map(size => {
            return (
                <SizeConfig key={size.id} data={size} onSavedClicked={this.onSizeChanged}/>
            )
        })
    };

    onSizeChanged = (size) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editSize(size).then(data => {
                this.setState({loading: false, sizes: data.data.sizes});
            });
        });
    };

    onAddNewSizeClicked = () => {
        let sizes = [...this.state.sizes];
        sizes.push({
            editing: true,
            id: -1,
            cm: '',
            inch: '',
            available: false,
            ratio: '',
            type: this.props.type
        });

        this.setState({
            sizes: sizes
        })
    };
}


export default SizesConfig;
