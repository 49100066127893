import React, {Component} from 'react';
import ApiService from "../../services/ApiService";
import './OrderUser.scss';
import moment from "moment-timezone";
import OrderStatusToggler from "./OrderStatusToggler";
import {Generate} from "../utils/checksum";

class OrderUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            ordersExpanded: false
        };

        this.userStatus = {
            0: 'Email not verified',
            1: 'Email verified, user needs to fill the name',
            2: 'Account active',
            3: 'Account inactive',
            4: 'Account suspended'
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        let orders = this.getUserOrders();
        let ordersExpanded = this.getUserOrdersExpanded();

        return (
            <div className='OrderUser'>
                <div className='head'>
                    <div className='text'>Ordered by</div>
                    <div className='fill'></div>
                    <div className='change'>change</div>
                </div>

                <div className='body'>
                    <div className='header'>{this.state.user.salutation} {this.state.user.firstname} {this.state.user.middlename} {this.state.user.lastname}</div>
                    <div className='underheader'>{this.state.user.external_source} user</div>
                </div>

                <div className='extra'>
                    <div>{this.state.user.email}</div>
                    <div>{this.state.user.phone}</div>
                    <div>{orders}</div>
                    <div>{ordersExpanded}</div>

                </div>


            </div>
        );
    }

    getUserOrders = () => {
        if (!this.state.user.orders) {
            return null;
        }

        return (
            <a className='link' onClick={() => this.setState({ordersExpanded: !this.state.ordersExpanded})}>
                {this.state.user.orders.length} orders
            </a>
        )
    };

    getUserOrdersExpanded = () => {
        if (!this.state.user.orders) {
            return null;
        }

        if (!this.state.ordersExpanded) {
            return null;
        }

        return this.state.user.orders.map(item => {
            const createdTimeRaw = moment(item.created);
            let createdTime = createdTimeRaw.tz('Europe/Bucharest').format('DD.MM.YYYY');
            if(this.props.from){
                if(this.props.from === item.id){
                    return (
                        <div className='expanded-order'>
                            <div className='id' style={{fontWeight: 'bold'}}>{Generate(item.id)}</div>
                            <div className='date'>{createdTime}</div>
                            <div><OrderStatusToggler displayOnly status={item.status}/></div>
                        </div>
                    )
                } else return (
                    <div className='expanded-order'>
                        <a className='id' href={`/order/${Generate(item.id)}`} target='_blank'>{Generate(item.id)}</a>
                        <div className='date'>{createdTime}</div>
                        <div><OrderStatusToggler displayOnly status={item.status}/></div>
                    </div>
                )
            }
            return (
                <div className='expanded-order'>
                    <a className='id' href={`/order/${Generate(item.id)}`} target='_blank'>{Generate(item.id)}</a>
                    <div className='date'>{createdTime}</div>
                    <div><OrderStatusToggler displayOnly status={item.status}/></div>
                </div>
            )
        })

    };

    loadData = () => {
        ApiService.getUserById(this.props.id).then(data => {
            this.setState({user: data.data});
        })
    }

}


export default OrderUser;
