import React, {Component} from 'react';
import './OrderItemStatus.scss';
import OrderItemStatusModal from "./OrderItemStatusModal";
import OrderItemStatusButton from "./OrderItemStatusButton";
import ApiService from "../services/ApiService";
import {Generate} from "./utils/checksum";

class OrderItemStatus extends Component {
    constructor(props) {
        super(props);

        let steps = this.props.data.progress;
        delete steps['impachetat'];

        let item = this.props.data.item;

        if (!item.emboss || !item.emboss.label || item.emboss.label === 'no') {
            delete steps['stantat'];
        }

        if (!item.cover_uv || item.cover_uv === 'no') {
            delete steps['uv'];
        }

        if (!item.folio_color) {
            delete steps['folie_termo'];
        }

        if (!item.laser_engraving) {
            delete steps['laser_engraving'];
        }

        this.state = {
            steps: steps,
            itemForModal: null,
            modeForModal: null,
            payloadForModalCallback: null,

            isModalVisible: false,
            modalParams: {}

        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.data.progress) {
            this.setState({
                steps: nextProps.data.progress
            })
        }
    }

    render() {
        const statusElements = this.getStatusElements();

        return (
            <div className='OrderItemStatus'>
                {statusElements}
                <OrderItemStatusModal
                    isVisible={this.state.isModalVisible}
                    modalParams={this.state.modalParams}
                    onRebutClicked={this.onRebutClicked}
                    onCloseClicked={this.onModalCloseClicked}
                />
            </div>
        );
    }

    getStatusElements = () => {
        let elements = [];

        let data = null;
        let consumption;
        let needs;

        try {
            data = JSON.parse(this.props.data.consumption_meta);
            consumption = data.consumption;
            needs = data.needs.neededSKUs;
        } catch (e) {
            consumption = null;
            needs = null;
        }

        let coverInStoc = false;
        let coverInStocSKU = '';

        let materialInStoc = false;
        let materialInStocSKU = '';

        let blocInStoc = false;
        let blocInStocSKU = '';

        if (consumption) {
            coverInStoc = consumption.cover?.fulfilled;
            if (coverInStoc) {
                coverInStocSKU = consumption.cover?.consumed[0].sku;
            }

            materialInStoc = consumption.material?.fulfilled;
            if (materialInStoc) {
                materialInStocSKU = consumption.material?.consumed[0].sku;
            }

            blocInStoc = consumption.bloc?.fulfilled;
            if (blocInStoc) {
                blocInStocSKU = consumption.bloc?.consumed[0].sku;
            }

        }

        let coverNeedsToBeInStock = false;
        let coverNeedsToBeInStockSKU = "";

        let blocNeedsToBeInStock = false;
        let blocNeedsToBeInStockSKU = "";

        if (needs?.cover?.length) {
            coverNeedsToBeInStock = true;
            coverNeedsToBeInStockSKU = needs.cover[0].sku;
        }

        if (needs?.bloc?.length) {
            blocNeedsToBeInStock = true;
            blocNeedsToBeInStockSKU = needs.bloc[0].sku;
        }

        for (let key in this.state.steps) {
            const stepValue = this.state.steps[key];

            const inStock = key === 'coperta' && coverInStoc || key === 'coperta' && materialInStoc || key === 'bloc' && blocInStoc;
            const inStockSKU = key === 'coperta' && coverInStoc ? coverInStocSKU : key === 'coperta' && materialInStoc ? materialInStocSKU : key === 'bloc' && blocInStoc ? blocInStocSKU : ''
            const needsToBeInStock = key === 'coperta' && coverNeedsToBeInStock || key === 'bloc' && blocNeedsToBeInStock;
            const needsToBeInStockSKU = key === 'coperta' ? coverNeedsToBeInStockSKU : key === 'bloc' ? blocNeedsToBeInStockSKU : '';
            let span = (key === 'coperta' && coverInStoc) ? 'C' : (key === 'coperta' && materialInStoc) ? "M" : '';


            elements.push(
                <OrderItemStatusButton
                    type={key}
                    inStock={inStock}
                    inStockSKU={inStockSKU}
                    value={stepValue}
                    span={span}
                    needsToBeInStock={needsToBeInStock}
                    needsToBeInStockSKU={needsToBeInStockSKU}
                    onToggle={this.onItemStatusButtonToggled}
                    onShowModal={this.onItemStatusButtonShowModal}
                />
            )
        }

        return elements;
    };

    onItemStatusButtonShowModal = async ({action, step, sku, buildSKU}) => {
        let modalParams = {
            action: '',
        }

        switch (action) {
            case 'must_build':
                if (step === 'bloc') {
                    modalParams.action = 'Se face bloc nou:'
                }

                if (step === 'coperta') {
                    modalParams.action = 'Se face coperta noua:'
                }

                const newItemParams = await this.getNewItemParams(buildSKU);
                modalParams.newItem  = newItemParams;
                break;
            case 'in_stock':
                if (step === 'bloc') {
                    const fromStocParams = await this.getItemFromStocParams(sku);
                    modalParams.action = 'Se foloseste bloc din stoc: '
                    modalParams.fromStocParams = fromStocParams;
                }

                if (step === 'coperta') {
                    if (sku.indexOf('MATERIAL') > -1) {
                        const fromStocParams = await this.getItemFromStocParams(sku);
                        modalParams.fromStocParams = fromStocParams;
                        modalParams.action = 'Se face coperta noua:'

                        const newItemParams = await this.getNewItemParams(buildSKU);
                        modalParams.newItem  = newItemParams;
                    } else {
                        const fromStocParams = await this.getItemFromStocParams(sku);
                        modalParams.fromStocParams = fromStocParams;
                        modalParams.action = 'Se foloseste coperta din stoc:'
                    }
                }
                break;
        }

        this.props.lockSync();
        this.setState({modalParams: modalParams, isModalVisible: true})
    }

    onRebutClicked = async (consumeAgain) => {
        const payload = {
            sku: consumeAgain.sku,
            pieceId: this.props.data.id,
            pieceSKU: `${Generate(this.props.data.orders_id)}-${this.props.data.id}`,
            orderId: this.props.data.orders_id,
            quantity: 1,
        }

        await ApiService.stockConsumeItem(payload);
    }

    onModalCloseClicked = () => {
        this.props.unlockSync();
        this.setState({isModalVisible: false})
    }

    onItemStatusButtonToggled = ({value, step}) => {
        this.props.onUpdateStatus({id: this.props.data.id, value: value, step: step});
    }

    getItemFromStocParams = async (sku)=>{
        let item = await ApiService.stockGetItemBySKU(sku);

        item = item.data[0];

        let itemMeta = JSON.parse(item.meta);

        let payload = {
            type : this.getItemTypeTextBySKU(sku),
            size: itemMeta.sizeName || null,
            pages: itemMeta.pages || null,
            materialName: itemMeta.materialName || null,
            sku: sku,
            pickPlace: item.pick_place,
            inInventory: item.quantity,
            required: this.props.data.quantity
        }

        return payload;
    }

    getNewItemParams = async (sku)=>{
        const itemType = this.getItemTypeBySKU(sku);

        let payload = {
            type : this.getItemTypeTextBySKU(sku),
            size: this.props.data.item.size.value,
            pages: parseInt(this.props.data.item.nr_pages) / 2,
            materialName: itemType === 'cover' ? this.props.data.item.material.value : null,
            pageType: itemType === 'bloc' ? this.props.data.item.page_type.label : null,
            coverType: itemType === 'cover' ? this.props.data.item.cover_style.text : null,
            sku: sku,
            required: this.props.data.quantity
        }

        return payload;
    }

    getItemTypeTextBySKU = (sku) => {
        let typeText = '';
        if(sku.indexOf('BLOC-R-') > -1){
            typeText = 'bloc cu rezerva';
        } else if(sku.indexOf('BLOC-CLA') > -1 ){
            typeText = 'bloc album clasic';
        } else if(sku.indexOf('BLOC-') > -1 ){
            typeText = 'bloc fara rezerva';
        } else if(sku.indexOf('COVER-R-') > -1 ){
            typeText = 'coperta cu rezerva';
        } else if(sku.indexOf('COVER-M') > -1 ){
            typeText = 'coperta fara rezerva';
        } else if(sku.indexOf('COVER-CLA') > -1 ){
            typeText = 'coperta album clasic';
        } else if(sku.indexOf('MATERIAL-') > -1 ){
            typeText = 'material';
        }

        return typeText;
    }

    getItemTypeBySKU = (sku) => {
        let type = '';
        if(sku.indexOf('BLOC-R-') > -1){
            type = 'bloc';
        } else if(sku.indexOf('BLOC-CLA') > -1 ){
            type = 'bloc';
        } else if(sku.indexOf('BLOC-') > -1 ){
            type = 'bloc';
        } else if(sku.indexOf('COVER-R-') > -1 ){
            type = 'cover';
        } else if(sku.indexOf('COVER-M') > -1 ){
            type = 'cover';
        } else if(sku.indexOf('COVER-CLA') > -1 ){
            type = 'cover';
        } else if(sku.indexOf('MATERIAL-') > -1 ){
            type = 'material';
        }

        return type;
    }
}

export default OrderItemStatus;
