import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import StyleConfig from "./StyleConfig";
import GalleryItem from "./GalleryItem";

class GalleryConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, items: []};
    }

    componentDidMount() {
      this.loadData();
    }

    render() {
        const items = this.getItems();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>
                <Table  fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>IMAGES</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>NAME</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>SIZE</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>STYLE</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>MATERIAL</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>PERSONALISATION</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>TEMPLATE</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>EMBOSS FOIL</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign='center'>ACTION</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items}
                    </Table.Body>
                </Table>

                <Button style={{marginTop: '3rem'}} content='add item' basic
                        onClick={this.onAddNewStyleClicked}/>
            </Container>
        );
    }

    getItems = () => {
        return this.state.items.map(item=>{
            return (
                <GalleryItem key={item.id} type={this.props.type} data={item} configs={this.state.config}
                            onItemSaved={this.onItemSaved}
                             onItemDeleted={this.onItemDeleted}/>
            )
        })
    }

    onAddNewStyleClicked = () => {
        let items = [...this.state.items];
        items.push({
            editing: true,
            id: -1,
            meta: {
                name:"",
                size:"",
                style:"",
                material:"",
                personalisation:"",
                embossTemplate:"",
                uvTemplate:"",
                embossFoil:"",
                images:[],
            },


            type: this.props.type
        });

        this.setState({
            items: items
        })
    };

    loadData = () => {
        this.setState({
            loading: true
        }, () => {
            ApiService.loadProductConfig().then(config => {
                ApiService.getWebsiteGallery(this.props.type).then(items => {
                    this.setState({loading: false, config: config.data, items: items.data});
                });
            });
        });
    }

    onItemSaved = (item) => {
        if (item.id === -1) {
            delete item.id;
            ApiService.createWebsiteGallery(item).then(data => {
                this.loadData();
            })
        } else {
            ApiService.editWebsiteGallery(item).then(data => {
                this.loadData();
            })
        }
    }

    onItemDeleted = (id) => {
        ApiService.deleteWebsiteGallery(id).then(data=>{
            this.loadData();
        })
    }


}


export default GalleryConfig;
