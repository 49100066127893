import React, {Component} from 'react';
import './DhlCheckScreen.scss';
import {Statistic} from "semantic-ui-react";

class DailyStats extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        return (
            <div >
                <div style={{display:"flex", alignItems:"center", padding:'40px', background:'aliceblue'}}>
                    <h2>Orders</h2>
                    <div style={{display: "flex", flex:1, alignItems: "center", justifyContent: "space-evenly"}}>
                        <div>
                            <h3>New</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>102</Statistic.Value>
                                <Statistic.Label>Orders</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Guestbooks</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Boudoir</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Album</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Fulfillment</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Orders</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Guestbooks</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Boudoir</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Album</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Backlog</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Orders</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Guestbooks</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Boudoir</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Album</Statistic.Label>
                            </Statistic>
                        </div>
                    </div>
                </div>

                <div style={{display:"flex", alignItems:"center",padding:'40px', background:'#fff0f0'}}>
                    <h2 style={{}}>Shippments</h2>
                    <div style={{display: "flex", flex:1, alignItems: "center", justifyContent: "space-evenly"}}>
                        <div>
                            <h3>Shipped</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>DHL</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Packeta</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Arrived at customer</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>DHL</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Packeta</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Late</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>DHL</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Packeta</Statistic.Label>
                            </Statistic>
                        </div>
                    </div>
                </div>

                <div style={{display:"flex", alignItems:"center",padding:'40px', background:'aliceblue'}}>
                    <h2 style={{}}>Manufacture</h2>
                    <div style={{display: "flex", flex:1, alignItems: "center", justifyContent: "space-evenly"}}>
                        <div>
                            <h3>Blocs</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>From stoc</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Made manually</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Covers</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>From stoc</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>Made manually</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Corners used</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>WHITE</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>BLACK</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>KRAFT</Statistic.Label>
                            </Statistic>
                        </div>

                        <div>
                            <h3>Pens used</h3>
                            <Statistic style={{marginRight: '20px'}}>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>WHITE</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>BLACK</Statistic.Label>
                            </Statistic>

                            <Statistic>
                                <Statistic.Value>120</Statistic.Value>
                                <Statistic.Label>KRAFT</Statistic.Label>
                            </Statistic>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}


export default DailyStats;
