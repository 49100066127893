import React, {Component} from 'react';
import './OrderPieceElement.scss';
import {Dropdown, Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ConsumptionPanel from "../ConsumptionPanel";
import ConsumptionAvailabilityPanel from "../ConsumptionAvailabilityPanel";

class OrderPieceCorners extends Component {
    constructor(props) {
        super(props);

        let hasProgress = false;
        if (this.props.data.progress && Object.keys(this.props.data.progress).length) {
            hasProgress = true;
        }

        this.state = {
            productType: this.props.data.product_type || 'corners',
            invoiceName: this.props.data.invoiceName || 'Extra corners',
            orderId: this.props.data.orderId || 0,
            item: {
                corners_white: this.props.data.item.corners_white || 0,
                corners_black: this.props.data.item.corners_black || 0,
                corners_kraft: this.props.data.item.corners_kraft || 0,
            },
            progress: hasProgress ? this.props.data.progress : {
                impachetat: 0,
            },
            details: this.props.data.details || "",
            price: this.props.data.price || '',
            currency: this.props.data.currency || "USD",
            quantity: this.props.data.quantity || 0,
            totalPrice: (parseFloat(this.props.data.quantity) && parseFloat(this.props.data.price)) ? parseFloat(this.props.data.quantity) * parseFloat(this.props.data.price) :0,
            id: this.props.data.id || -1
        }
    }

    render() {
        let formInvalid = false;

        if (!this.state.invoiceName || !this.state.invoiceName.length) {
            console.log("FAILED invoiceName")
            formInvalid = true;
        }

        if (!this.state.quantity) {
            console.log("FAILED quantity")
            formInvalid = true;
        }

        if (!this.state.totalPrice) {
            console.log("FAILED quantity")
            formInvalid = true;
        }

        // if (!this.state.price || !this.state.price.length) {
        //     console.log("FAILED price")
        //     formInvalid = true;
        // }

        if (!this.state.currency || !this.state.currency.length) {
            console.log("FAILED currency")
            formInvalid = true;
        }

        if (this.state.item.corners_white === null || this.state.item.corners_white === undefined) {
            console.log("FAILED corners_white")
            formInvalid = true;
        }
        if (this.state.item.corners_black === null || this.state.item.corners_black === undefined) {
            console.log("FAILED corners_black")
            formInvalid = true;
        }
        if (this.state.item.corners_kraft === null || this.state.item.corners_kraft === undefined) {
            console.log("FAILED corners_kraft")
            formInvalid = true;
        }

        const disableEdit = (this.props.orderStatus === 'in production' || this.props.orderStatus === 'urgent');
        const mainClassName = disableEdit ? 'OrderPieceElement disableAll' : 'OrderPieceElement';

        return (
            <div className={mainClassName}>
                <div className='header'>
                    <h3 className='name'>Corners</h3>
                    {this.state.id === -1 &&
                    <div className='ct' onClick={this.props.onChangeTypeClicked}>change type</div>
                    }
                    {this.state.id !== -1 &&
                    <div className='discount' onClick={() => this.props.onDiscountClicked(this.state)}>apply 100% discount</div>
                    }
                    {this.state.id !== -1 &&
                    <div className='delete' onClick={() => this.props.onDeleteClicked(this.state.id)}>delete</div>
                    }
                </div>

                {(disableEdit === true) &&
                    <div style={{color:'orange', marginTop:'0'}}>
                        Editing disabled while the order is in production.
                    </div>
                }

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Invoice name*</div>
                        <Input style={{width: '200px'}} value={this.state.invoiceName}
                               onChange={(e) => this.onMainFieldChanged('invoiceName', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Price per sheet*</div>
                        <Input disabled style={{width: '80px'}} value={this.state.price}
                               onChange={(e) => this.onMainFieldChanged('price', e.target.value)}/>
                        <Input style={{width: '65px'}} value={this.state.currency}
                               onChange={(e) => this.onMainFieldChanged('currency', e.target.value)}/>

                    </div>

                    <div className='item'>
                        <div className='label'>Sheets*</div>
                        <Input style={{width: '60px'}} value={this.state.quantity}
                               onChange={(e) => this.onMainFieldChanged('quantity', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginLeft: '20px'}}>
                        <div className='label'>Total Price*</div>
                        <Input style={{width: '80px'}} value={this.state.totalPrice}
                               onChange={(e) => this.onMainFieldChanged('totalPrice', e.target.value)}/>
                        <Input style={{width: '65px'}} value={this.state.currency}
                               onChange={(e) => this.onMainFieldChanged('currency', e.target.value)}/>

                    </div>
                </div>



                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Color*</div>
                        <Dropdown
                            options={['white', 'black', 'kraft'].map(i => {
                                return {
                                    key: i,
                                    value: i,
                                    text: i,
                                    id: i
                                }
                            })}
                            selection compact
                            search
                            value={this.determineColor()}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onCornersColorChanged(item)}
                        />
                    </div>
                </div>

                <div className='item'>
                    <div className='label'>Production notes</div>
                    <textarea id="story"
                              name="story"
                              rows="3" cols="33"
                              value={this.state.details}
                              onChange={(e) => this.onMainFieldChanged('details', e.target.value)}
                              style={{width: '100%'}}
                    >

                        </textarea>
                </div>

                {!this.props.data.consumption_meta &&
                    <div className='item'>
                        <ConsumptionAvailabilityPanel data={this.props.data.needed_skus_meta} type={this.props.data.product_type}/>
                    </div>
                }

                <div className='item'>
                    <ConsumptionPanel data={this.props.data.consumption_meta} type={this.props.data.product_type}/>
                </div>

                {this.state.dirty &&
                <Button color='red' disabled={formInvalid} compact onClick={this.onOrderPieceSaveClicked}>Save</Button>
                }

            </div>
        );
    }

    onCornersColorChanged = (color) => {
        let newColor = {
            corners_white: 0,
            corners_black: 0,
            corners_kraft: 0
        }

        switch (color.value) {
            case 'white':
                newColor.corners_white = 1;
                break;
            case 'black':
                newColor.corners_black = 1;
                break;
            case 'kraft':
                newColor.corners_kraft = 1;
                break;
        }

        this.setState({
            item: newColor, dirty: true
        })
    }

    determineColor = () => {
        if (this.state.item.corners_white) {
            return 'white';
        }
        if (this.state.item.corners_black) {
            return 'black';
        }
        if (this.state.item.corners_kraft) {
            return 'kraft';
        }

        return '';
    }

    onMainFieldChanged = (field, value) => {
        this.setState({[field]: value, dirty: true})
    };

    onItemFieldChanged = (field, value) => {
        let item = {...this.state.item};
        if (field.indexOf('.') > 0) {
            let parts = field.split('.');
            item[parts[0]][parts[1]] = value;
        } else {
            item[field] = value;
        }

        this.setState({item: item, dirty: true});
    };

    onOrderPieceSaveClicked = () => {
        this.setState({
            dirty: false,
            price: parseFloat(this.state.totalPrice) / parseFloat(this.state.quantity)
        }, () => {
            let data = {...this.state};

            delete data.totalPrice;
            this.props.onOrderItemSaveClicked(data);
        })
    };
}


export default OrderPieceCorners;
