import React, {Component} from 'react';
import {
    Button,
    Checkbox, Dimmer, Dropdown,
    Grid,
    Header,
    Image,
    Input, Loader,
    Table
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PriceConfig from "./PriceConfig";
import MaterialFoilMappings from "./MaterialFoilMappings";
import {config} from '../config/config'
import EmbossConfigurator from "./EmbossConfigurator";

class MaterialConfig extends Component {
    constructor(props) {
        super(props);

        this.materialThumbRef = React.createRef();
        this.ratio1Ref = React.createRef();
        this.ratio2Ref = React.createRef();
        this.ratio3Ref = React.createRef();
        this.ratio4Ref = React.createRef();

        this.state = {
            editing: this.props.data.editing,
            loading: false,

            name: this.props.data.name,
            blindEmbossColor: this.props.data.blindEmbossColor,
            highlightEmbossColor: this.props.data.highlightEmbossColor,
            lowlightEmbossColor: this.props.data.lowlightEmbossColor,
            imagePath: this.props.data.imagePath,
            thumbPath: this.props.data.thumbPath,
            internal_code: this.props.data.internal_code,
            canEmboss: this.props.data.canEmboss === 1,
            canUvPrint: this.props.data.canUvPrint === 1,
            available: this.props.data.available === 1,
            category: this.props.data.category,
            meta: this.props.data.meta,
            type: this.props.data.type,
            prices: this.props.data.prices,
            foils: [],
            preview: `${config.ASSETS_PATH}/products/material/${this.props.data.thumbPath}`
        }
    }

    componentDidMount() {
        ApiService.getFoils(this.props.data.type).then(data=>{
            this.setState({foils: data.data.foils})
        })
    }

    render() {
        const item = this.props.data;

        if (this.state.editing) {
            return this.getEditingElement();
        }

        let embossStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.canEmboss) {
            embossStyle.background = '#4caf50';
        } else {
            embossStyle.background = '#f44336';
        }

        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }

        let uvStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.canUvPrint) {
            uvStyle.background = '#4caf50';
        } else {
            uvStyle.background = '#f44336';
        }

        return (
            <Table.Row key={item.id}>
                <Table.Cell><Image src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageThumb}`}
                                   size='small'/></Table.Cell>
                <Table.Cell><h5>{item.name}</h5></Table.Cell>
                <Table.Cell>{item.category}</Table.Cell>
                <Table.Cell>{item.blindEmbossColor}</Table.Cell>
                <Table.Cell>{item.highlightEmbossColor}</Table.Cell>
                <Table.Cell>{item.lowlightEmbossColor}</Table.Cell>
                <Table.Cell>
                    <div style={embossStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={uvStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={availableStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return null;
        }

        return (
            <Button basic icon='pencil alternate' compact onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        if (!this.state.editing) {
            return false;
        }

        const item = this.props.data;

        return (
            <div style={{padding: '30px'}}>
                <h2>{item.name}</h2>

                <br/>
                <br/>

                <div style={{display: 'flex'}}>
                    <div style={{margin: '0 10px'}}>
                        <h5>Material name</h5>
                        <Input placeholder='name' name='name' value={this.state.name}
                               onChange={this.handleChange}/>
                    </div>


                    {/*internal_code*/}
                    {/*"textil 14"*/}
                    {/*manufacturerCode*/}
                    {/*""*/}
                    {/*manufacturerName*/}

                    <div style={{margin: '0 10px'}}>
                        <h5>Internal code</h5>
                        <Input placeholder='internal_code' name='internal_code' value={this.state.internal_code}
                               onChange={this.handleChange}/>
                    </div>
                    <div style={{margin: '0 10px'}}>
                        <h5>Material category</h5>
                        <Dropdown name='category' selection options={[
                            {key: 1, text: 'eco', value: 'eco'},
                            {key: 2, text: 'leather', value: 'leather'},
                            {key: 3, text: 'textil', value: 'textil'},
                            {key: 4, text: 'premium leather', value: 'premium leather'},
                            {key: 5, text: 'velvet', value: 'velvet'},
                        ]} placeholder='Category' value={this.state.category} onChange={this.handleChange}/>
                    </div>
                    <div style={{margin: '0 10px'}}>
                        <h5 style={{width: '150px'}}>Material availability</h5>
                        <Checkbox label='available' name='available' style={{marginTop: '15px'}} toggle
                                  checked={this.state.available}
                                  onChange={this.onCheckboxChanged}/>
                    </div>

                </div>

                <br/>
                <br/>

                {this.state.loading === false &&
                <div style={{display: 'flex', width: '900px'}}>

                    <div style={{margin: '0 10px'}}>
                        <h5>Material image <br/>600x600px</h5>
                        <Image src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageThumb}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.materialThumbRef.current.click()}
                        />
                        <input
                            ref={this.materialThumbRef}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Thumb')}
                        />
                    </div>

                    <div style={{margin: '0 10px'}}>
                        <h5>Wide image <br/> 1280x[...]px </h5>
                        <Image src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio1}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio1Ref.current.click()}
                        />
                        <input
                            ref={this.ratio1Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio1')}
                        />
                    </div>

                    <div style={{margin: '0 10px'}}>
                        <h5>Square image <br/> 1280x[...]px </h5>
                        <Image src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio2}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio2Ref.current.click()}
                        />
                        <input
                            ref={this.ratio2Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio2')}
                        />
                    </div>

                    <div style={{margin: '0 10px'}}>
                        <h5>Landscape image <br/> 1280x[...]px </h5>
                        <Image src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio3}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio3Ref.current.click()}
                        />
                        <input
                            ref={this.ratio3Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio3')}
                        />
                    </div>

                    <div style={{margin: '0 10px'}}>
                        <h5>Portrait image <br/> 1280x[...]px </h5>
                        <Image src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio4}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio4Ref.current.click()}
                        />
                        <input
                            ref={this.ratio4Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio4')}
                        />
                    </div>
                </div>
                }

                {this.state.loading === true &&
                <h3>Uploading...</h3>
                }


                <br/>
                <br/>
                <Checkbox label='embossable' name='canEmboss' toggle checked={this.state.canEmboss}
                          onChange={this.onCheckboxChanged}/>
                {this.state.canEmboss && (
                    <MaterialFoilMappings
                        foils={this.props.foils}
                        meta={this.state.meta}
                        onIncompatibeFoilChanged={this.onFoilChecked}

                    />
                )}

                <br/>
                <br/>
                <Checkbox label='uv printable' name='canUvPrint' toggle checked={this.state.canUvPrint}
                          onChange={this.onCheckboxChanged}/>

                <br/>
                <br/>


                {this.state.foils.length !== 0 &&
                <EmbossConfigurator
                    material={this.state.meta.imageRatio3 ? this.state.meta.imageRatio3 : this.state.meta.imageRatio5}
                    foils={this.state.foils}
                    blind={this.props.data.blindEmbossColor}
                    highlight={this.props.data.highlightEmbossColor}
                    lowlight={this.props.data.lowlightEmbossColor}
                    onSaveClicked={this.onEmbossColorsSavedClicked}
                />
                }

                <Button basic onClick={this.onSaveClicked}>Save</Button>
            </div>
        )
    };

    getPricesElement = () => {
        if (!this.state.showPrices) {
            return null;
        }

        const priceElements = this.getPriceElements();

        return (
            <div style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Header as='h4'>Prices </Header>
                <Button style={{marginBottom: '1.5rem'}} compact basic color='green' icon='check'
                        onClick={this.onSaveClicked}/>
                <Grid container>
                    {priceElements}
                </Grid>
            </div>
        )
    };

    getPriceElements = () => {
        if (!this.props.sizes || this.props.sizes.length === 0) {
            return null;
        }

        return this.props.sizes.map(size => {
            const prices = this.state.prices[`${size.id}`];

            return (
                <PriceConfig
                    key={size.id}
                    label={size.cm}
                    prices={prices}
                    currencies={this.props.config.currencies}
                    onChanged={(value, currencyId) => this.onPriceChanged(value, currencyId, size.id)}
                />
            )
        })
    };

    onPriceChanged = (value, currencyId, sizeId) => {
        let prices = {...this.state.prices};

        if (!prices[sizeId]) {
            prices[sizeId] = {};
        }

        prices[sizeId][currencyId] = value;

        this.setState({prices: prices});
    };

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onSavedClicked({
                id: this.props.data.id,
                name: this.state.name,
                imagePath: this.state.imagePath,
                thumbPath: this.state.thumbPath,
                canEmboss: this.state.canEmboss,
                canUvPrint: this.state.canUvPrint,
                available: this.state.available,
                category: this.state.category,
                internal_code: this.state.internal_code,
                meta: this.state.meta,
                blindEmbossColor: this.state.blindEmbossColor,
                highlightEmbossColor: this.state.highlightEmbossColor,
                lowlightEmbossColor: this.state.lowlightEmbossColor,
                prices: this.state.prices,
                type: this.state.type,
            })
        });

    };

    onEmbossColorsSavedClicked = (data) => {
        this.setState({editing: false}, () => {
            this.props.onSavedClicked({
                id: this.props.data.id,
                blindEmbossColor: data.blindEmbossColor,
                highlightEmbossColor: data.highlightEmbossColor,
                lowlightEmbossColor: data.lowlightEmbossColor
            })
        });

    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

    onImageSelected = (e, type) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        this.setState({loading: true}, ()=>{
            ApiService.uploadImage('material', data).then(r => {
                let meta = {...this.state.meta};
                meta[`image${type}`] = r.data.image;

                this.setState({
                    loading: false,
                    meta: meta
                })
            });
        })
    };

    onFoilChecked = (id, checked) => {
        let meta = {...this.state.meta};
        if (!meta.incompatibleFoils) {
            meta.incompatibleFoils = {};
        }

        if (checked) {
            meta.incompatibleFoils[id] = true;
        } else {
            delete meta.incompatibleFoils[id];
        }

        this.setState({
            meta: meta
        })

    };

    onColorChanged = (foilId, color) => {
        const materialMeta = {...this.state.meta};

        if (!materialMeta.foils) {
            return;
        }

        materialMeta.foils[foilId] = color;
        this.setState({meta: materialMeta});
    }

}


export default MaterialConfig;
