import React, {Component} from 'react';
import './EtsyScreen.scss';
import {Button, Container} from "semantic-ui-react";
import InputWithLabel from "../components/InputWithLabel";
import ApiService from "../services/ApiService";

class EtsyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status: "",
            authUrl: "",
            api_key: "",
            shop_name: "",
            shop_id: "",
            callback_url: "",
            callback_path: "",
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <Container className='EtsyScreen'>
                <h2>Etsy API Status</h2>
                <div><b>{this.state.status}</b></div>

                <h2>Etsy API configuration</h2>

                <div style={{margin: '10px 0'}}>
                    <InputWithLabel label="API key" value={this.state.api_key}
                                    onChange={value => this.setState({api_key: value})}/>
                </div>

                <div style={{margin: '10px 0'}}>
                    <InputWithLabel label="Shop name" value={this.state.shop_name}
                                    onChange={value => this.setState({shop_name: value})}/>
                </div>

                <div style={{margin: '10px 0'}}>
                    <InputWithLabel disabled label="Shop ID" value={this.state.shop_id}/>
                </div>

                <div style={{margin: '10px 0'}}>
                    <InputWithLabel label="Callback URL" value={this.state.callback_url}
                                    onChange={value => this.setState({callback_url: value})}/>
                </div>

                <div style={{margin: '10px 0'}}>
                    <InputWithLabel label="Callback path" value={this.state.callback_path}
                                    onChange={value => this.setState({callback_path: value})}/>
                </div>

                <Button primary onClick={this.onUpdateConfigClicked}>Save</Button>

                <h2>Authorize ETSY</h2>
                <a href={this.state.authUrl}>Click here to authorize</a>

            </Container>
        )
    }

    onUpdateConfigClicked = async () => {
        const payload = {
            api_key: this.state.api_key,
            shop_name: this.state.shop_name,
            callback_url: this.state.callback_url,
            callback_path: this.state.callback_path,
        }
        const data = await ApiService.editEtsyConfig(payload);
        window.location.reload();
    }

    loadData = async () => {
        const data = await ApiService.getEtsyConfig();

        this.setState({
            status: data.data.status,
            authUrl: data.data.authUrl,
            api_key: data.data.meta.api_key,
            shop_name: data.data.meta.shop_name,
            shop_id: data.data.meta.shop_id,
            callback_url: data.data.meta.callback_url,
            callback_path: data.data.meta.callback_path,
        })
        console.log(data.data)
    }
}


export default EtsyScreen
