import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Button, Container, Grid, Header, Menu, Segment} from "semantic-ui-react";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import AlbumSleevesConfig from "../components/AlbumSleevesConfig";
import PremiumBoxesConfig from "../components/PremiumBoxesConfig";
import ShippingBoxesConfig from "../components/ShippingBoxesConfig";

class ProductsConfigScreen extends Component {
    render() {

        const menuItems = [
            {name:'Albums', path:'/albums'},
            {name:'Classic albums', path:'/classic-albums'},
            {name:'Guestbooks', path:'/guestbooks'},
            {name:'Guestbooks Slots', path:'/guestbooks-slots'},
            {name:'Boudoir', path:'/boudoir'},
            {name:'Boudoir Empty', path:'/boudoir-empty'},
            {name:'Bamboo Box', path:'/bamboo-box'},
            {name:'Corners', path:'/corners'},
            {name:'Pouch', path:'/pouch'},
            {name:'Pens', path:'/pen'},
            {name:'Album Sleeve', path:'/album-sleeve'},
            {name:'Premium Box', path:'/premium-box'},
            {name:'Paper Config', path:'/paper-config'},
            {name:'Shipping Box', path:'/shipping-box'},
            {name:'Raw Paper Config', path:'/raw-config'},
            {name:'Raw Materials Config', path:'/raw-materials-config'},
            {name:'Vow books', path:'/vow-books'},
            {name:'Markers', path:'/markers'},
        ]

        return (
            <Container style={{padding:'3rem'}}>
                <Grid columns='equal'>

                    {menuItems.map(item=>{
                        return (
                            <Grid.Column width={4}>
                                <Button as="a" href={item.path} style={{cursor:'pointer'}} >{item.name}</Button>
                            </Grid.Column>
                        )
                    })}


                </Grid>
            </Container>
        );
    }



}


export default ProductsConfigScreen;
