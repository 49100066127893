import React, {Component} from 'react';
import './ShippingScreen.scss';
import {
    Container, Menu, Table,
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Label} from "recharts";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

class ShippingScreenWizard extends Component {
    componentDidMount() {

    }

    render() {

        return (
            <Container style={{margin: '3rem 0'}}>
                <h3>Expeditie 25-04-2021</h3>
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Comanda</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Detalii</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>19-23-K</Table.Cell>
                            <Table.Cell>John Doe</Table.Cell>
                            <Table.Cell>
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>AWB</Table.HeaderCell>
                                            <Table.HeaderCell>BOX</Table.HeaderCell>
                                            <Table.HeaderCell>Dimensiuni</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>123456789</Table.Cell>
                                            <Table.Cell>BOX1</Table.Cell>
                                            <Table.Cell>1.3 kg - 20/20/10</Table.Cell>
                                        </Table.Row>
                                        <Table.Row positive>
                                            <Table.Cell>Germany</Table.Cell>
                                            <Table.Cell>Remote: NO</Table.Cell>
                                            <Table.Cell>160 RON</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>

                                </Table>
                                <Button color='blue' size='small'>Modifica</Button>
                                <Button color='red' size='small'>Sterge</Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>19-23-K</Table.Cell>
                            <Table.Cell>John Doe</Table.Cell>
                            <Table.Cell>
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>AWB</Table.HeaderCell>
                                            <Table.HeaderCell>BOX</Table.HeaderCell>
                                            <Table.HeaderCell>Dimensiuni</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>123456789</Table.Cell>
                                            <Table.Cell>BOX1</Table.Cell>
                                            <Table.Cell>1.3 kg - 20/20/10</Table.Cell>
                                        </Table.Row>
                                        <Table.Row positive>
                                            <Table.Cell>Germany</Table.Cell>
                                            <Table.Cell>Remote: NO</Table.Cell>
                                            <Table.Cell>160 RON</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>

                                </Table>
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>AWB</Table.HeaderCell>
                                            <Table.HeaderCell>BOX</Table.HeaderCell>
                                            <Table.HeaderCell>Dimensiuni</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>123456789</Table.Cell>
                                            <Table.Cell>BOX1</Table.Cell>
                                            <Table.Cell>1.3 kg - 20/20/10</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>123456789</Table.Cell>
                                            <Table.Cell>BOX2</Table.Cell>
                                            <Table.Cell>1.3 kg - 20/20/10</Table.Cell>
                                        </Table.Row>
                                        <Table.Row positive>
                                            <Table.Cell>Germany</Table.Cell>
                                            <Table.Cell>Remote: NO</Table.Cell>
                                            <Table.Cell>160 RON</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>

                                </Table>
                                <Button color='blue' size='small'>Modifica</Button>
                                <Button color='red' size='small'>Sterge</Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row negative>
                            <Table.Cell>19-23-K</Table.Cell>
                            <Table.Cell>John Doe</Table.Cell>
                            <Table.Cell >
                                NU PLEACA AZI
                            </Table.Cell>
                        </Table.Row>

                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                        <Button color='blue'>Adauga colete</Button>
                                    </div>
                                    <Button color='purple'>Finalizeaza expediere</Button>
                                </div>


                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Container>
        );
    }

    test = () => {
        ApiService.dev_test();
    };


}


export default ShippingScreenWizard;
