import { Redirect } from 'react-router-dom';
import React, {Component} from 'react';
import AuthService from "../services/AuthService";

export class AuthRequired extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(!AuthService.isAuthenticated()) {
            return(<Redirect to='/login' /> );

        } else {
            return (this.props.orRender);
        }
    }
}
export default AuthRequired;