import React, {Component} from 'react';
import './OrderPieceElement.scss';
import {Dropdown, Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class OrderPieceShipping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productType: this.props.data.product_type || 'shipping' ,
            invoiceName: this.props.data.invoiceName || 'Shipping',
            orderId: this.props.data.orderId || 0,
            item: {
            },
            progress: this.props.data.progress || {
                bloc: 0,
                coperta: 0,
                stantat: 0,
                album: 0,
                impachetat: 0,
                uv: 0
            },
            details: this.props.data.details || "",
            price: this.props.data.price || '',
            currency: this.props.data.currency || "USD",
            quantity: this.props.data.quantity,
            id:this.props.data.id || -1,
            dirty: !this.props.data.id|| this.props.data.placeholder===1
        }
    }

    render() {
        let formInvalid = false;

        if(!this.state.invoiceName || !this.state.invoiceName.length){
            console.log("FAILED invoiceName")
            formInvalid = true;
        }


        if(!this.state.currency || !this.state.currency.length){
            console.log("FAILED currency")
            formInvalid = true;
        }

        const disableEdit = (this.props.orderStatus === 'in production' || this.props.orderStatus === 'urgent');
        const mainClassName = disableEdit ? 'OrderPieceElement disableAll' : 'OrderPieceElement';

        return (
            <div className={mainClassName}>
                <div className='header'>
                    <h3 className='name'>Shipping item</h3>
                    {this.state.id === -1 &&
                    <div className='ct' onClick={this.props.onChangeTypeClicked}>change type</div>
                    }
                    {this.state.id !== -1 &&
                    <div className='delete' onClick={()=>this.props.onDeleteClicked(this.state.id)}>delete</div>
                    }
                </div>

                {(disableEdit === true) &&
                    <div style={{color:'orange', marginTop:'0'}}>
                        Editing disabled while the order is in production.
                    </div>
                }

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Invoice name*</div>
                        <Input style={{width: '200px'}} value={this.state.invoiceName}
                               onChange={(e) => this.onMainFieldChanged('invoiceName', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Price*</div>
                        <Input style={{width: '80px'}} value={this.state.price}
                               onChange={(e) => this.onMainFieldChanged('price', e.target.value)}/>
                        <Input style={{width: '65px'}} value={this.state.currency}
                               onChange={(e) => this.onMainFieldChanged('currency', e.target.value)}/>

                    </div>

                    <div className='item'>
                        <div className='label'>Quantity*</div>
                        <Input style={{width: '60px'}} value={this.state.quantity}
                               onChange={(e) => this.onMainFieldChanged('quantity', e.target.value)}/>
                    </div>
                </div>

                <div className='item'>
                    <div className='label'>Production notes</div>
                    <textarea id="story"
                              name="story"
                              rows="3" cols="33"
                              value={this.state.details}
                              onChange={(e) => this.onMainFieldChanged('details', e.target.value)}
                              style={{width: '100%'}}
                    >

                        </textarea>
                </div>

                {this.state.dirty &&
                <Button color='red' disabled={formInvalid} compact onClick={this.onOrderPieceSaveClicked}>Save</Button>
                }

            </div>
        );
    }

    onMainFieldChanged = (field, value) => {
        this.setState({[field]: value, dirty: true})
    };

    onItemFieldChanged = (field, value) => {
        let item = {...this.state.item};
        if(field.indexOf('.') > 0) {
            let parts = field.split('.');
            item[parts[0]][parts[1]] = value;
        } else {
            item[field] = value;
        }

        this.setState({item: item, dirty: true});
    };

    onOrderPieceSaveClicked = ()=>{
        this.setState({
            dirty: false
        }, ()=>{
            this.props.onOrderItemSaveClicked({...this.state});
        })
    };
}


export default OrderPieceShipping;
