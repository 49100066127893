import React from "react";
import {FixedSizeList as List} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {format, fromUnixTime} from "date-fns";
import {Generate} from "./utils/checksum";

export default function EtsyLedger({
                                       // Are there more items to load?
                                       // (This information comes from the most recent API request.)
                                       hasNextPage,

                                       // Are we currently loading a page of items?
                                       // (This may be an in-flight flag in your Redux store for example.)
                                       isNextPageLoading,

                                       // Array of items loaded so far.
                                       items,

                                       // Callback function responsible for loading the next page of items.
                                       loadNextPage
                                   }) {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading ? () => {
    } : loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index) => !hasNextPage || index < items.length;

    // Render an item or a loading indicator.
    const Item = ({index, style}) => {
        let content;
        if (!isItemLoaded(index)) {
            return <div style={style}>Loading</div>
        }

        const item = items[index];
        let faulty = false;

        try {
            let prevItem = items[index - 1];
            if(item.sequence_number !== prevItem.sequence_number - 1){
                faulty = true;
            }
        } catch(e){
            console.log("No prev item")
        }

        let itemStyle = {...style, display: 'flex', paddingTop: '5px'}
        let colItemStyle = {margin: '0 10px', textAlign: 'left'};
        const date = format(fromUnixTime(parseInt(item.create_date)), 'dd-MM-yyyy HH:mm');

        const even = item.sequence_number % 2 === 0;

        itemStyle.background = even ? '#f8f8f8' : '#fff'

        if (item.ledger_type === 'DISBURSE2') {
            itemStyle.background = '#c7e1c4'
        }

        if (item.parsed === 0) {
            itemStyle.background = '#e5b9b9'
        }

        if (item.receipt_id === 0) {
            // itemStyle.background = '#b9cee5'
            itemStyle.opacity = 0.5
        }

        if(faulty){
            itemStyle.background = '#7635e3'
        }


        let orderId = '-';

        if (item.order_id) {
            orderId = (<a href={`/order/${Generate(item.order_id)}/`}>{Generate(item.order_id)}</a>)
        }

        return (<div style={itemStyle}>
            <div style={{...colItemStyle, width: '140px'}}>{date} </div>
            <div style={{...colItemStyle, width: '50px'}}>{item.sequence_number}</div>
            <div style={{...colItemStyle, width: '100px'}}>{item.amount / 100}</div>
            <div style={{...colItemStyle, width: '80px'}}>{item.currency}</div>
            <div style={{...colItemStyle, width: '220px'}}>{item.description}</div>
            <div style={{...colItemStyle, width: '90px'}}>{item.balance / 100}</div>
            <div style={{...colItemStyle, width: '210px'}}>{item.ledger_type}</div>
            <div style={{...colItemStyle, width: '150px'}}>{item.reference_type}</div>
            <div style={{...colItemStyle, width: '120px'}}>{item.reference_id}</div>
            <div style={{...colItemStyle, width: '120px', overflow: 'hidden'}}>{item.payment_adjustments}</div>
            <div style={{...colItemStyle, width: '120px', overflow: 'hidden'}}>{orderId}</div>
        </div>);
    };

    return (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
        >
            {({onItemsRendered, ref}) => (
                <List
                    className="List"
                    height={800}
                    itemCount={itemCount}
                    itemSize={30}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    width={1800}
                >
                    {Item}
                </List>
            )}
        </InfiniteLoader>
    );
}
