import React, {Component} from 'react';
import {Button, Dropdown, Radio, Table} from "semantic-ui-react";
import {Generate} from "../../../utils/checksum";

class StocVsMissingBlocSpacer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: "normal",
            timeFilter: -1
        }
    }

    render() {
        let renderData = [];

        this.props.items.forEach(item => {
            if(item.deadline) {
                item.deadlineFilter = parseInt(item.deadline?.split('-').join(''));
            } else {
                item.deadlineFilter = Infinity;
            }
        })

        let items = [...this.props.items];

        if (this.state.timeFilter > -1) {
            let now = new Date();
            now.setDate(now.getDate() + this.state.timeFilter);
            let deadlineItem = parseInt(now.toISOString().split('T')[0].split('-').join(''));

            items = items.filter(i => i.deadlineFilter <= deadlineItem);
        }


        items.forEach(item => {
            let data = {};
            data.orderId = item.orderId;
            data.deadline = item.deadline;
            data.deadlineFilter = item.deadlineFilter;

            let skuParts = item.sku.split('-');
            data.size = skuParts[2];
            data.pages = skuParts[3];
            data.spacer = "yes";
            data.quantity = item.quantity;

            renderData.push(data);
        })

        renderData = renderData.sort((a, b) => {
            return a.deadlineFilter - b.deadlineFilter;
        })

        let mergedData = [];
        let mergedKeys = {}

        items = [...this.props.items];

        if (this.state.timeFilter > -1) {
            let now = new Date();
            now.setDate(now.getDate() + this.state.timeFilter);
            let deadlineItem = parseInt(now.toISOString().split('T')[0].split('-').join(''));

            items = items.filter(i => i.deadlineFilter <= deadlineItem);
        }

        items.forEach(item => {
            let data = {};
            if (!mergedKeys[item.sku]) {
                mergedKeys[item.sku] = 0;
            }
            mergedKeys[item.sku] += item.quantity;
        })

        for (let sku in mergedKeys) {
            let data = {};
            let quantity = mergedKeys[sku];

            let skuParts = sku.split('-');
            data.size = skuParts[2];
            data.pages = skuParts[3];
            data.spacer = "yes";
            data.quantity = quantity;

            mergedData.push(data);
        }

        return (
            <div>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                    <Radio style={{marginRight: '20px'}}
                           onChange={() => this.setState({mode: this.state.mode === 'normal' ? "merge" : "normal"})}
                           checked={this.state.mode === 'merge'} toggle label={'merge items'}/>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{fontWeight: 'bold', marginRight: '10px'}}>Filtru deadline:</div>
                        <Dropdown
                            options={[-1, 0, 1, 2, 3, 4, 5, 6, 7].map(i => {
                                return {
                                    key: i,
                                    value: i,
                                    text: i === -1 ? 'Toate' : i === 0 ? 'AZI' : i === 1 ? 'Maine' : `peste ${i} zile`,
                                    id: i
                                }
                            })}
                            selection compact search
                            value={this.state.timeFilter}
                            style={{minWidth: '200px'}}
                            onChange={(e, {name, value}) => this.onTimeFilterChanged(value)}
                        />
                    </div>

                    <Button style={{marginLeft: '20px'}} onClick={() => this.downloadClicked(renderData, mergedData)}
                            primary
                    >CSV</Button>
                </div>

                <div>
                    {this.state.mode === 'normal' ? <div><h4>Total: {renderData?.length}</h4></div> : <div><h4>Total: {mergedData?.length} </h4></div>}
                    {this.state.mode === 'normal' ? this.getNormal(renderData) : this.getMerged(mergedData)}
                </div>

            </div>
        )
    }

    downloadClicked = (normalData, mergeData) => {
        if (this.state.mode === 'normal') {
            let tsv = `Comanda\tDeadline\tMarime\tSpreads\tRezerva\tBucati\n`;
            normalData.forEach(item => {
                tsv += `${Generate(item.orderId)}\t${item.deadline}\t${item.size}\t${item.pages}\t${item.spacer}\t${item.quantity}\n`
            })

            const filename = this.getDownloadFileName('bloc-spacer-orders');
            this.download(tsv, filename, 'text/tab-separated-values')
        }
        if (this.state.mode === 'merge') {
            let tsv = `Marime\tSpreads\tRezerva\tBucati\n`;
            mergeData.forEach(item => {
                tsv += `${item.size}\t${item.pages}\t${item.spacer}\t${item.quantity}\n`
            })

            const filename = this.getDownloadFileName('bloc-spacer-merged');
            this.download(tsv, filename, 'text/tab-separated-values')
        }
    }

    getDownloadFileName = (prefix) => {
        let name = prefix;
        switch (this.state.timeFilter) {
            case -1:
                name += '-TOATE'
                break;
            case 0:
                name += '-AZI'
                break;
            case 1:
                name += '-MAINE'
                break;
            default:
                name += `-${this.state.timeFilter} zile`
                break;
        }

        let now = new Date();
        name += `-${now.toLocaleString()}.tsv`

        return name;
    }

    download = (data, filename, type) => {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    getNormal = (renderData) => {
        const getItems = () => {

            return renderData.map(item => {
                return (
                    <Table.Row>
                        <Table.Cell><a href={`/order/${Generate(item.orderId)}`}
                                       target="_blank">{Generate(item.orderId)}</a></Table.Cell>
                        <Table.Cell>{item.deadline}</Table.Cell>
                        <Table.Cell>{item.size}</Table.Cell>
                        <Table.Cell>{item.pages}</Table.Cell>
                        <Table.Cell>{item.spacer}</Table.Cell>
                        <Table.Cell>{item.quantity}</Table.Cell>
                    </Table.Row>
                )
            })
        }
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Order id</Table.HeaderCell>
                        <Table.HeaderCell>Deadline</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Pages</Table.HeaderCell>
                        <Table.HeaderCell>Spacer</Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {getItems()}
                </Table.Body>
            </Table>
        )
    }

    onTimeFilterChanged = (value) => {
        this.setState({timeFilter: value})
    }

    getMerged = (renderData) => {
        const getItems = () => {
            return renderData.map(item => {
                return (
                    <Table.Row>
                        <Table.Cell>{item.size}</Table.Cell>
                        <Table.Cell>{item.pages}</Table.Cell>
                        <Table.Cell>{item.spacer}</Table.Cell>
                        <Table.Cell>{item.quantity}</Table.Cell>
                    </Table.Row>
                )
            })
        }
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Pages</Table.HeaderCell>
                        <Table.HeaderCell>Spacer</Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {getItems()}
                </Table.Body>
            </Table>
        )
    }
}

export default StocVsMissingBlocSpacer;
