import React, {Component} from 'react';
import './TaieriScreen.scss';
import {Button, Dimmer, Dropdown, Icon, Input, Loader, Modal, Table} from "semantic-ui-react";
import CutCard from "../components/CutCard";
import ApiService from "../services/ApiService";
import {format, fromUnixTime} from "date-fns";

class TaieriScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: 'add',
            historyItems: [],
            loading: true,
            selectedMaterial: '',
            selectedSize: '',
            operator: '',
            sourceAmount: 0,
            availableMaterials: ['Mucava', 'Hartie Alba', 'DA140', 'DA280'],
            raw_mucavaSizes: [],
            raw_whitePaperSizes: [],
            raw_da140Sizes: [],
            raw_da280Sizes: [],

            mucavaSizes: [],
            whitePaperSizes: [],
            da140Sizes: [],
            da280Sizes: [],

            results: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let raw_availableSizes = [];

        switch (this.state.selectedMaterial) {
            case 'Mucava':
                raw_availableSizes = [...this.state.raw_mucavaSizes].map(i => i.cm)
                break;
            case 'Hartie Alba':
                raw_availableSizes = [...this.state.raw_whitePaperSizes].map(i => i.cm)
                break;
            case 'DA140':
                raw_availableSizes = [...this.state.raw_da140Sizes].map(i => i.cm)
                break;
            case 'DA280':
                raw_availableSizes = [...this.state.raw_da280Sizes].map(i => i.cm)
                break;
            default:
                raw_availableSizes = [];
        }

        let availableSizes = [];

        switch (this.state.selectedMaterial) {
            case 'Mucava':
                availableSizes = [...this.state.mucavaSizes].map(i => i.cm)
                break;
            case 'Hartie Alba':
                availableSizes = [...this.state.whitePaperSizes].map(i => i.cm)
                break;
            case 'DA140':
                availableSizes = [...this.state.da140Sizes].map(i => i.cm)
                break;
            case 'DA280':
                availableSizes = [...this.state.da280Sizes].map(i => i.cm)
                break;
            default:
                availableSizes = [];
        }

        const resultsElements = this.getResultsElements(availableSizes);

        let canSubmit = true;
        if (!this.state.operator || this.state.operator.length < 2) {
            canSubmit = false;
        }
        if (!this.state.selectedMaterial || this.state.selectedMaterial.length < 2) {
            canSubmit = false;
        }
        if (!this.state.selectedSize || this.state.selectedSize.length < 2) {
            canSubmit = false;
        }
        if (!this.state.sourceAmount || parseInt(this.state.sourceAmount) === 0) {
            canSubmit = false;
        }
        if (this.state.results.length === 0) {
            canSubmit = false;
        }

        this.state.results.forEach(item => {
            if (!item.size || item.size.length < 2) {
                canSubmit = false;
            }
            if (!item.amount || parseInt(item.amount) === 0) {
                canSubmit = false;
            }
        })

        const historyElement = this.getHistoryElements();
        const loadingElement = this.getLoaderElement();

        if(this.state.loading){
            return loadingElement;
        }


        return (
            <div className="TaieriScreen">
                <div style={{marginLeft: '10px'}}>
                    <Button onClick={()=>this.setState({mode: 'history'})}>Istoric taieri</Button>
                </div>
                <div className="menuHead">
                    <h2>Taiere noua</h2>
                    <Button onClick={this.onSaveClicked} disabled={canSubmit !== true} primary>Salveaza</Button>
                </div>
                <div className="newCut">
                    <CutCard source
                             selectedOperator={this.state.operator}
                             selectedMaterial={this.state.selectedMaterial}
                             materials={this.state.availableMaterials}
                             selectedSize={this.state.selectedSize}
                             sizes={raw_availableSizes}
                             amount={this.state.sourceAmount}
                             onMaterialSelected={this.onMaterialSelected}
                             onOperatorChanged={this.onOperatorChanged}
                             onSizeSelected={this.onSizeSelected}
                             onAmountChanged={this.onAmountChanged}
                    />

                    <div className="arrow">&#8594;</div>
                    <div className="results">
                        {resultsElements}
                        <Button primary basic onClick={this.onAddNewResultClicked}>+</Button>
                    </div>
                </div>



                <Modal
                    basic
                    onClose={()=>this.setState({mode: 'add'})}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    open={this.state.mode === 'history'}
                    size='fullscreen'
                >
                    <Modal.Content>
                        {historyElement}
                    </Modal.Content>

                </Modal>
            </div>
        )
    }

    onMaterialSelected = (material) => {
        this.setState({selectedMaterial: material});
    }

    onSizeSelected = (size) => {
        this.setState({selectedSize: size});
    }

    onOperatorChanged = (operator) => {
        this.setState({operator: operator});
    }

    onAmountChanged = (amount) => {
        this.setState({sourceAmount: amount});
    }

    getData = async () => {
        const raw_mucavaSizes = await ApiService.getSizes('raw_mucava');
        const raw_whitePaperSizes = await ApiService.getSizes('raw_white-paper');
        const raw_da140Sizes = await ApiService.getSizes('raw_DA140');
        const raw_da280Sizes = await ApiService.getSizes('raw_DA280');

        const mucavaSizes = await ApiService.getSizes('mucava');
        const whitePaperSizes = await ApiService.getSizes('white-paper');
        const da140Sizes = await ApiService.getSizes('DA140');
        const da280Sizes = await ApiService.getSizes('DA280');

        const history = await ApiService.getAllCuts();

        this.setState({
            selectedMaterial: '',
            selectedSize: '',
            operator: '',
            sourceAmount: 0,
            results: [],

            raw_mucavaSizes: raw_mucavaSizes.data.sizes,
            raw_whitePaperSizes: raw_whitePaperSizes.data.sizes,
            raw_da140Sizes: raw_da140Sizes.data.sizes,
            raw_da280Sizes: raw_da280Sizes.data.sizes,

            mucavaSizes: mucavaSizes.data.sizes,
            whitePaperSizes: whitePaperSizes.data.sizes,
            da140Sizes: da140Sizes.data.sizes,
            da280Sizes: da280Sizes.data.sizes,
            historyItems: history.data,
            loading: false
        })
    }

    onAddNewResultClicked = () => {
        const newResult = {id: this.state.results.length, amount: 0};

        let results = [...this.state.results];
        results.push(newResult);

        this.setState({results})
    }

    getResultsElements = (availableSizes) => {
        return this.state.results.map((item, index) => {
            return (
                <CutCard key="index"
                         sizes={availableSizes}
                         amount={item.amount}
                         selectedSize={item.size}
                         onSizeSelected={(size) => this.updateResultItemSize(item.id, size)}
                         onAmountChanged={(amount) => this.updateResultItemAmount(item.id, amount)}
                         onRemoveClicked={() => this.onResultRemoveClicked(item.id)}
                         selectedMaterial={this.state.selectedMaterial}

                />
            )
        })
    }

    updateResultItemSize = (itemId, size) => {
        let items = [...this.state.results];

        let item = items.find(i => i.id === itemId);
        item.size = size;
        this.setState({results: items})
    }

    updateResultItemAmount = (itemId, amount) => {
        let items = [...this.state.results];

        let item = items.find(i => i.id === itemId);
        item.amount = amount;
        this.setState({results: items})
    }

    onResultRemoveClicked = (itemId) => {
        let items = [...this.state.results];
        items = items.filter(i => i.id !== itemId);
        this.setState({results: items})
    }

    onSaveClicked = async () => {
        this.setState({loading: true}, async ()=>{
            let payload = {
                operator: this.state.operator,
                from: {
                    material: this.state.selectedMaterial,
                    size: this.state.selectedSize,
                    amount: this.state.sourceAmount
                },
                to: this.state.results.map(item => {
                    return {
                        material: this.state.selectedMaterial,
                        size: item.size,
                        amount: item.amount
                    }
                })
            }

            await ApiService.addNewCut(payload);
            this.getData();
        })
    }

    getHistoryElements = () => {
        const rows = this.getHistoryElementsRows();

        return (
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Operator</Table.HeaderCell>
                        <Table.HeaderCell>Tip coala</Table.HeaderCell>
                        <Table.HeaderCell>Marime Coala</Table.HeaderCell>
                        <Table.HeaderCell>Coli taiate</Table.HeaderCell>
                        <Table.HeaderCell>Rezultat</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rows}
                </Table.Body>

            </Table>
        )
    }

    getHistoryElementsRows = () => {
        return this.state.historyItems.map(row => {
            const date = format(fromUnixTime(parseInt(row.date) / 1000), 'dd-MM-yyyy H:mm');
            const rowResults = this.getRowResults(row.results)

            return (
                <Table.Row>
                    <Table.Cell>{date}</Table.Cell>
                    <Table.Cell>{row.operator}</Table.Cell>
                    <Table.Cell>{row.from.material}</Table.Cell>
                    <Table.Cell>{row.from.size}</Table.Cell>
                    <Table.Cell>{row.from.amount}</Table.Cell>
                    <Table.Cell>
                        {rowResults}
                    </Table.Cell>
                </Table.Row>
            )
        })
    }

    getRowResults = (results) => {
        return results.map(result => {
            return (
                <div>{result.size} - {result.amount} bucati</div>
            )
        })
    }

    getLoaderElement = () => {
        return (
            <Dimmer active>
                <Loader>Loading</Loader>
            </Dimmer>

        )
    }

}


export default TaieriScreen;
