import React, {Component} from 'react';
import {Container, Dimmer, Dropdown, Header, Icon, Image, Input, Item, Loader, Table} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PricingConfigGuestbookSize from "./PricingConfigGuestbookSize";
import './PricingConfig.scss';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import PricingConfigMaterial from "./PricingConfigMaterial";
import PricingConfigEmboss from "./PricingConfigEmboss";
import PricingConfigPouch from "./PricingConfigPouch";
import PricingConfigBambooBox from "./PricingConfigBambooBox";
import PricingConfigCorners from "./PricingConfigCorners";
import PricingConfigAlbumSize from "./PricingConfigAlbumSize";
import PricingConfigSleeve from "./PricingConfigSleeve";
import PricingPremiumBox from "./PricingPremiumBox";
import PricingConfigRush from "./PricingConfigRush";

class PricingConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sizes: [],
            materials: [],
            prices: [],
            rates: [],
            selectedDate: null,
            createExpanded: false,
            selectedCurrency: 'USD',
            selectedCurrencyRate: 1,
            newDate: 0
        };
    }

    componentDidMount() {
        this.loadData();
    }


    render() {
        const sizesElement = this.getSizesElement();
        const materialsElement = this.getMaterialsElement();
        const customEmbossElement = this.getCustomEmbossElement();
        const pouchElement = this.getPouchElement();
        const cornersPricing = this.getCornersPricingElement();
        const bambooElement = this.getBambooElement();
        const sleeveElement = this.getSleeveElement();
        const rushElement = this.getRushElement();
        const premiumBoxElement = this.getPremiumBoxElement();
        const createDateElement = this.getCreateDateElement();
        const dateSelectElement = this.getDateSelectElement();

        return (
            <Container className='PricingConfig'>
                <Icon className='create-icon' name='plus circle' onClick={this.onCreateNewListClicked}/>
                {dateSelectElement}
                {createDateElement}

                <Button.Group style={{marginLeft: '20px'}} basic>
                    <Button toggle active={this.state.selectedCurrency === 'USD'} onClick={()=>this.setState({selectedCurrency: 'USD', selectedCurrencyRate: 1})}>USD</Button>
                    <Button toggle active={this.state.selectedCurrency === 'EUR'} onClick={()=>this.setState({selectedCurrency: 'EUR', selectedCurrencyRate: this.state.rates['EUR']})}>EUR</Button>
                    <Button toggle active={this.state.selectedCurrency === 'GBP'} onClick={()=>this.setState({selectedCurrency: 'GBP', selectedCurrencyRate: this.state.rates['GBP']})}>GBP</Button>
                    <Button toggle active={this.state.selectedCurrency === 'AUD'} onClick={()=>this.setState({selectedCurrency: 'AUD', selectedCurrencyRate: this.state.rates['AUD']})}>AUD</Button>
                </Button.Group>

                <h3 style={{marginTop: '80px'}}>Size pricing - {this.state.selectedCurrency}</h3>

                {(this.props.type === 'guestbook' ||  this.props.type === 'boudoir-empty') &&
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>CM</Table.HeaderCell>
                            <Table.HeaderCell width={1}>20</Table.HeaderCell>
                            <Table.HeaderCell width={1}>30</Table.HeaderCell>
                            <Table.HeaderCell width={1}>40</Table.HeaderCell>
                            <Table.HeaderCell width={1}>50</Table.HeaderCell>
                            <Table.HeaderCell width={1}>60</Table.HeaderCell>
                            <Table.HeaderCell width={1}>70</Table.HeaderCell>
                            <Table.HeaderCell width={1}>80</Table.HeaderCell>
                            <Table.HeaderCell width={1}>90</Table.HeaderCell>
                            <Table.HeaderCell width={1}>100</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sizesElement}
                    </Table.Body>
                </Table>
                }

                {(this.props.type !== 'guestbook' && this.props.type !== 'boudoir-empty') &&
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>CM</Table.HeaderCell>
                            <Table.HeaderCell width={1}>base</Table.HeaderCell>
                            <Table.HeaderCell width={8}>extra spread</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sizesElement}
                    </Table.Body>
                </Table>
                }

                <h3 style={{marginTop: '80px'}}>Material pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>SIZE</Table.HeaderCell>
                            <Table.HeaderCell width={2}>TEXTIL</Table.HeaderCell>
                            <Table.HeaderCell width={2}>ECO</Table.HeaderCell>
                            <Table.HeaderCell width={2}>LEATHER</Table.HeaderCell>
                            <Table.HeaderCell width={4}>PREMIUM LEATHER</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {materialsElement}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Custom emboss pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>SQ CM</Table.HeaderCell>
                            <Table.HeaderCell width={5}>SQ INCH</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {customEmbossElement}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Pouch pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>Price</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {pouchElement}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Bamboo pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>Price</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {bambooElement}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Corners pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>white</Table.HeaderCell>
                            <Table.HeaderCell width={2}>black</Table.HeaderCell>
                            <Table.HeaderCell width={6}>kraft</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {cornersPricing}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Sleeve pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>Price</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sleeveElement}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Premium box pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>Price</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {premiumBoxElement}
                    </Table.Body>
                </Table>

                <h3 style={{marginTop: '80px'}}>Rush pricing - {this.state.selectedCurrency}</h3>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={10}>Price</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rushElement}
                    </Table.Body>
                </Table>


                <div style={{marginBottom: '100px'}}/>
            </Container>
        );
    }

    getSizesElement = () => {
        if (this.state.sizes.length === 0) {
            return null;
        }

        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let sizesPricing = productTypePrices['size'];
        if (!sizesPricing) {
            sizesPricing = {};
        }


        return this.state.sizes.map(size => {
            let price = {...sizesPricing[size.cm]}
            if (!price) {
                price = {};
            }

            if(this.state.selectedCurrency !== 'USD'){
                for(let key in price){
                    price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
                }
            }

            if (this.props.type === 'guestbook' || this.props.type === 'boudoir-empty') {
                return (
                    <PricingConfigGuestbookSize key={`size.${pricing.id}.${size.id}`} priceId={pricing.id}
                                                selectedCurrency={this.state.selectedCurrency}
                                                selectedCurrencyRate={this.state.selectedCurrencyRate}
                                                type={this.props.type} size={size} price={price}
                                                onUpdateClientPrice={this.onUpdateClientPrice}/>
                )
            } else {
                return (
                    <PricingConfigAlbumSize key={`size.${pricing.id}.${size.id}`} priceId={pricing.id}
                                            selectedCurrency={this.state.selectedCurrency}
                                            selectedCurrencyRate={this.state.selectedCurrencyRate}
                                            type={this.props.type} size={size} price={price}
                                            onUpdateClientPrice={this.onUpdateClientPrice}/>
                )
            }
        })
    }

    getMaterialsElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let materialPricing = productTypePrices['material'];
        if (!materialPricing) {
            materialPricing = {};
        }

        return this.state.sizes.map(size => {
            let price = {...materialPricing[size.cm]};
            if (!price) {
                price = {};
            }

            if(this.state.selectedCurrency !== 'USD'){
                for(let key in price){
                    price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
                }
            }

            return (
                <PricingConfigMaterial key={`material.${pricing.id}.${size.id}`} priceId={pricing.id}
                                       selectedCurrency={this.state.selectedCurrency}
                                       type={this.props.type} size={size} materialPricing={price}
                                       onUpdateClientPrice={this.onUpdateClientPrice}/>
            )
        })
    }

    getCustomEmbossElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['customEmboss']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingConfigEmboss priceId={pricing.id} type={this.props.type} embossPricing={price}
                                 selectedCurrency={this.state.selectedCurrency}
                                 onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }

    getPouchElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['pouch']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingConfigPouch priceId={pricing.id} type={this.props.type} pouchPricing={price}
                                selectedCurrency={this.state.selectedCurrency}
                                onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }

    getCornersPricingElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['corners']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingConfigCorners priceId={pricing.id} type={this.props.type} cornersPricing={price}
                                  selectedCurrency={this.state.selectedCurrency}
                                  onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }

    getBambooElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['bambooBox']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingConfigBambooBox priceId={pricing.id} type={this.props.type} bambooBoxPricing={price}
                                    selectedCurrency={this.state.selectedCurrency}
                                    onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }

    getSleeveElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['album-sleeve']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingConfigSleeve priceId={pricing.id} type={this.props.type} bambooBoxPricing={price}
                                    selectedCurrency={this.state.selectedCurrency}
                                    onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }


    getRushElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['rush']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingConfigRush priceId={pricing.id} type={this.props.type} bambooBoxPricing={price}
                                    selectedCurrency={this.state.selectedCurrency}
                                    onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }

    getPremiumBoxElement = () => {
        if (!this.state.selectedDate) {
            return null;
        }

        let pricing = this.state.prices.find(p => p.date === this.state.selectedDate);
        let prices = pricing.prices;
        let productTypePrices = prices[this.props.type];

        if (!productTypePrices) {
            productTypePrices = {};
        }

        let price = {...productTypePrices['premium-box']};
        if (!price) {
            price = {};
        }

        if(this.state.selectedCurrency !== 'USD'){
            for(let key in price){
                price[key] = Math.ceil(parseFloat(price[key]) * this.state.selectedCurrencyRate) - 0.01;
            }
        }

        return (
            <PricingPremiumBox priceId={pricing.id} type={this.props.type} bambooBoxPricing={price}
                                    selectedCurrency={this.state.selectedCurrency}
                                    onUpdateClientPrice={this.onUpdateClientPrice}/>
        )
    }

    getCreateDateElement = () => {
        if (!this.state.createExpanded) {
            return null;
        }

        return (
            <div className='create-pricing-list'>
                <Input label='New pricing list date' name='newDate' value={this.state.newDate}
                       onChange={this.handleChange}/>
                <Button basic onClick={this.onCreateNewPriceListSaveClicked}>save</Button>
            </div>
        )
    }

    getDateSelectElement = () => {
        let dates = this.state.prices.map(pr => {
            return {
                key: pr.date,
                text: pr.date,
                value: pr.date,
            }
        }).reverse();

        return (
            <Dropdown closeOnBlur
                      selection name='pricelistDate'
                      options={dates}
                      placeholder='Price list date'
                      value={this.state.selectedDate} onChange={this.handleDateSelected}/>
        )
    }

    onCreateNewListClicked = () => {
        let date = new Date();
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        let selectedDate = new Date(date.getTime() - userTimezoneOffset).toISOString().split('.')[0].replace('T', ' ');
        this.setState({
            createExpanded: true,
            newDate: selectedDate
        })
    }

    onCreateNewPriceListSaveClicked = () => {
        ApiService.createClientPrices(this.state.newDate).then(data => {
            this.setState({selectedDate: null}, () => {
                this.loadData();
            })
        })
    }

    loadData = () => {
        ApiService.getSizes(this.props.type).then(sizes => {
            ApiService.getMaterials(this.props.type).then(materials => {
                ApiService.getCurrencyRatios().then(currencyRates => {
                    ApiService.getClientPrices().then(prices => {
                        let selectedDate = null;
                        if (prices.data.length) {
                            selectedDate = prices.data[prices.data.length - 1].date;
                        }
                        this.setState({
                            sizes: sizes.data.sizes,
                            materials: materials.data.materials,
                            prices: prices.data,
                            rates: currencyRates.data[currencyRates.data.length -1],
                            selectedDate: selectedDate,
                            createExpanded: false
                        })
                    })
                })
            })
        })
    }

    onUpdateClientPrice = (data) => {
        ApiService.updateClientPrice(data).then(data => {
            this.loadData();
        })
    }

    handleDateSelected = (e, {value}) => {
        this.setState({
            selectedDate: value,
        })
    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
}


export default PricingConfig;
