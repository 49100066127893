import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FontConfig from "./FontConfig";

class FontsConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, fonts: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getFonts(this.props.type).then(data => {
                this.setState({loading: false, fonts: data.data.fonts});
            });
        });

    }

    render() {
        const fonts = this.getFonts();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Item.Group divided relaxed>
                    {fonts}
                </Item.Group>
                <Button style={{marginTop: '3rem'}} content='add new font' basic
                        onClick={this.onAddNewFontClicked}/>
            </Container>
        );
    }

    getFonts = () => {
        if (this.state.fonts.length === 0) {
            return null;
        }

        return this.state.fonts.map(font => {
            return (
                <FontConfig key={font.id} config={this.props.config} data={font}
                                onSavedClicked={this.onFontChanged}/>
            )
        })
    };

    onFontChanged = (font) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editFont(font).then(data => {
                this.setState({loading: false, fonts: data.data.fonts});
            });
        });
    };

    onAddNewFontClicked = () => {
        let fonts = [...this.state.fonts];
        fonts.push({
            editing: true,
            id: -1,
            name: '',
            available: false
        });

        this.setState({
            fonts: fonts
        })
    };
}


export default FontsConfig;
