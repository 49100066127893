import React, {Component} from 'react';
import {Button, Checkbox, Container, Grid, Image, Input, Item, Label} from "semantic-ui-react";

class StyleUVPrintConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const sizesElements = this.getSizesElements();

        return (
            <Container>
                <Grid container style={{margin: '1rem'}}>
                    {sizesElements}
                </Grid>
            </Container>
        );
    }

    getSizesElements = () => {
        if(!this.props.sizes.length){
            return null;
        }

        return this.props.sizes.map(size=>{
            return (
                <Item key={size.id} style={{margin: '10px'}}>
                    <Item.Content>
                        <Item.Header as='a'>
                            {size.cm}
                        </Item.Header>
                        <Item.Extra>
                            <Input label='Top (mm)' placeholder='1' name='top' value={this.getValue(size.id, 'top')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>
                        </Item.Extra>


                    </Item.Content>
                </Item>
            )
        })
    };

    handleChange = (sizeId, name, value)=>{
        this.props.onValueChanged(sizeId, name, value)
    };

    getValue = (sizeId, name) => {
        if(!this.props.data){
            return '';
        }
        if(!this.props.data[sizeId]){
            return '';
        }
        return this.props.data[sizeId][name] || '';
    }

}


export default StyleUVPrintConfig;
