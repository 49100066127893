import React, {Component} from 'react';
import './OrderStatusToggler.scss';

class OrderStatusToggler extends Component {
    constructor(props) {
        super(props);

        this.statuses = [
            {name: 'new', color: 'red'},
            {name: 'hold', color: 'gray'},
            {name: 'mock-up', color: 'purple'},
            {name: 'proofing', color: 'orange'},
            {name: 'in production', color: 'blue'},
            {name: 'urgent', color: 'blue'},
            {name: 'issue', color: 'red'},
            {name: 'to pack', color: 'turqoise'},
            {name: 'packing', color: 'red'},
            {name: 'ready to ship', color: 'turqoise'},
            {name: 'shipped', color: 'turqoise'},
            {name: 'complete', color: 'green'},
            {name: 'canceled', color: 'black'},
        ];

        this.state = {
            selectedStatus: this.statuses.find(s => s.name === this.props.status),
            isExpanded: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            selectedStatus: this.statuses.find(s => s.name === nextProps.status)
        })
    }

    componentDidMount() {
        if (!this.props.displayOnly) {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (!this.props.displayOnly) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({isExpanded: false});
        }
    };

    render() {
        if (this.props.displayOnly) {
            return (
                <div className='OrderStatusToggler'>
                    <div className={`status small ${this.statuses.find(s => s.name === this.props.status)?.color}`}>{this.props.status}</div>
                </div>)
        }


        const options = this.getOptionsElement();

        return (
            <div className='OrderStatusToggler' ref={this.setWrapperRef}>
                <div className={`status ${this.state.selectedStatus.color}`}
                     onClick={this.onHeadClicked}>{this.state.selectedStatus.name}</div>

                {options}
            </div>
        );
    }

    getOptionsElement = () => {
        if (!this.state.isExpanded) {
            return null;
        }

        let statuses = [...this.statuses].filter(s => s.name !== this.state.selectedStatus.name);

        if (!this.props.enabled) {
            statuses = statuses.filter(s=>s.name === 'new' || s.name === 'canceled' || s.name ==='complete')
        }

        if(this.props.errors?.length){
            statuses = statuses.filter(s=>s.name !== 'in production')
            statuses = statuses.filter(s=>s.name !== 'urgent')
            statuses = statuses.filter(s=>s.name !== 'ready to ship')
            statuses = statuses.filter(s=>s.name !== 'shipped')
        }

        let items = [];

        items = statuses.map(status => {
            let className = this.state.mouseOver === status.name ? 'status hover' : 'status';

            return (
                <div className={className} onClick={() => this.onStatusClicked(status)}
                     onMouseEnter={() => this.setState({mouseOver: status.name})}
                     onMouseLeave={() => this.setState({mouseOver: false})}>{status.name}</div>
            )
        });

        return (
            <div className='options'>{items}</div>
        )
    };

    onStatusClicked = (status) => {
        if (this.props.onStatusChanged) {
            this.setState({isExpanded: false}, () => {
                if(status.name !== 'packing') {
                    this.props.onStatusChanged(status.name);
                }
            })
        }
    };

    onHeadClicked = () => {
        this.setState({isExpanded: true});
    }

}


export default OrderStatusToggler;
