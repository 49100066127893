import * as axios from 'axios';

import AuthService from "./AuthService";
import {dispatch} from "./Store";
import {saveAs} from 'file-saver';

import ScreenController from "../controler/ScreenController";
import ActionsApp from "../actions/ActionsApp";
import FileDownload from "js-file-download";
import {format, fromUnixTime} from "date-fns";

// axios.defaults.baseURL = "http://127.0.0.1:3010";
// axios.defaults.baseURL = "http://192.168.1.15:3010";
//
//
// axios.defaults.baseURL = "https://d8fc-2a02-2f08-ec11-3000-89c6-bfdb-de27-e3a.ngrok.io";
axios.defaults.baseURL = "https://control3.phototailors.com/api";
// axios.defaults.baseURL = "http://localhost:3010/api";
// axios.defaults.baseURL = "https://control-stage.phototailors.com/api";

axios.interceptors.request.use(
    config => {
        config.headers.Authorization = AuthService.getAuthToken();

        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(function (response) {
    if (response.data.redirect) {
        ScreenController.goToScreen(response.data.redirect);
        dispatch(ActionsApp.HIDE_LOADER);
        if (response.data.logout) {
            dispatch(ActionsApp.LOGOUT);
        }
        return Promise.reject();

    }
    return response;
}, function (error) {
    switch (error.response.status) {
        case 401:
            AuthService.clearAuthToken();
            window.location = '/login';
            break;
        case 403:
            window.location = '/validate';
            break;
        default:
            return Promise.reject(error);
    }
});

export default {
    rawGet: (url) => {
        return axios.get(url);
    },

    authenticateUser: (data) => {
        return axios.post(`/authenticate`, {data: data});
    },

    loadAccountData: () => {
        return axios.get(`/account`);
    },

    getBlockStocNeeds: () => {
        return axios.get(`/stoc/bloc/needs`);
    },

    updateBlockStocNeedsSpreadsMerge: (data) => {
        return axios.post(`/stoc/bloc/needs/update/spreads/merge`, {data});
    },

    transferStock: (data) => {
        return axios.post(`/stoc/transfer`, {data});
    },

    updateBlockStocNeedsSizesMerge: (data) => {
        return axios.post(`/stoc/bloc/needs/update/sizes/merge`, {data});
    },

    updateBlockStocNeedsCoef: (data) => {
        return axios.post(`/stoc/bloc/needs/update/coef`, {data});
    },

    updateBlockStocNeedsFilter: (data) => {
        return axios.post(`/stoc/bloc/needs/update/filter`, {data});
    },

    loadConfig: () => {
        return axios.get(`/config`);
    },

    getCurrencyRatios: () => {
        return axios.get(`/config/get/currencyRatios`);
    },

    setCurrencyRatios: (data) => {
        return axios.post(`/config/set/currencyRatios`, {data: data});
    },

    getSizes: (type) => {
        return axios.get(`/get/sizes/${type}`);
    },

    getStyles: (type) => {
        return axios.get(`/get/styles/${type}`);
    },

    getProductBamboo: () => {
        return axios.get(`/get/product/bamboo-box`);
    },

    getProductPouch: () => {
        return axios.get(`/get/product/pouch`);
    },

    editProductPouch: (data) => {
        return axios.post(`/edit/product/pouch`, {data: data});
    },

    getStockNeedsForItem: (item) => {
        return axios.post(`/orders/get/stock/for/item`, {data: item});
    },

    getProductPen: () => {
        return axios.get(`/get/product/pen`);
    },

    editProductPen: (data) => {
        return axios.post(`/edit/product/pen`, {data: data});
    },

    getProductMarker: () => {
        return axios.get(`/get/product/marker`);
    },

    editProductMarker: (data) => {
        return axios.post(`/edit/product/marker`, {data: data});
    },

    getProductPremiumBox: () => {
        return axios.get(`/get/product/premium-box`);
    },

    getProductShippingBox: () => {
        return axios.get(`/get/product/shipping-box`);
    },

    editProductShippingBox: (data) => {
        return axios.post(`/edit/product/shipping-box`, {data: data});
    },

    moveShippedOrdersToShipped: (daa) => {
        return axios.get(`/orders/moveToShipped`);
    },


    getOrderPacking: (orderId) => {
        return axios.get(`/get/order/packing/${orderId}`);
    },


    updateOrderPacking: (orderId, data) => {
        return axios.post(`/update/order/packing/${orderId}`, {data: data});
    },


    editProductPremiumBox: (data) => {
        return axios.post(`/edit/product/premium-box`, {data: data});
    },

    getProductAlbumSleeve: () => {
        return axios.get(`/get/product/album-sleeve`);
    },

    editProductAlbumSleeve: (data) => {
        return axios.post(`/edit/product/album-sleeve`, {data: data});
    },

    editProductBambooBox: (data) => {
        return axios.post(`/edit/product/bamboo-box`, {data: data});
    },

    getProductCorners: () => {
        return axios.get(`/get/product/corners`);
    },

    getProductClassicAlbumBlocs: () => {
        return axios.get(`/get/product/classic-albums-bloc`);
    },

    editClassicAlbumBloc: (data) => {
        return axios.post(`/edit/product/classic-albums-bloc`, {data: data});
    },

    getProductClassicAlbumCoverSize: () => {
        return axios.get(`/get/product/classic-albums-cover-size`);
    },

    editClassicAlbumCoverSize: (data) => {
        return axios.post(`/edit/product/classic-albums-cover-size`, {data: data});
    },

    editProductCorners: (data) => {
        return axios.post(`/edit/product/corners`, {data: data});
    },

    editSize: (size) => {
        return axios.post(`/edit/size`, {data: size});
    },

    editStyle: (style) => {
        return axios.post(`/edit/style`, {data: style});
    },

    editFoil: (foil) => {
        return axios.post(`/edit/emboss/foil`, {data: foil});
    },

    editEmbossTemplate: (template) => {
        return axios.post(`/edit/emboss/template`, {data: template});
    },

    editUVPrintTemplate: (template) => {
        return axios.post(`/edit/uvprint/template`, {data: template});
    },

    getUVTemplates: (type) => {
        return axios.get(`/get/uvprint/templates/${type}`);
    },

    getMaterials: (type) => {
        return axios.get(`/get/materials/${type}`);
    },
    deleteMaterial: (id) => {
        return axios.post(`/materials/delete`, {data: {materialId: id}});
    },
    toggleMaterialValability: (id) => {
        return axios.post(`/materials/toggle/validity`, {data: {materialId: id}});
    },
    getAllMaterials: () => {
        return axios.get(`/get/all/materials`);
    },
    getAllSizes: () => {
        return axios.get(`/get/all/sizes`);
    },

    getFoils: (type) => {
        return axios.get(`/get/emboss/foils/${type}`);
    },

    getAllFoils: (type) => {
        return axios.get(`/get/all/emboss/foils`);
    },

    getImages: (type) => {
        return axios.get(`/get/images/${type}`);
    },

    getEmbossTemplates: (type) => {
        return axios.get(`/get/emboss/templates/${type}`);
    },

    getFonts: () => {
        return axios.get(`/get/fonts`);
    },

    getWebsiteConfig: (key) => {
        return axios.post(`/website/get/config`, {data: key});
    },

    saveWebsiteConfig: (data) => {
        return axios.post(`/website/save/config`, {data: data});
    },

    editMaterial: (material) => {
        return axios.post(`/edit/material`, {data: material});
    },
    updateMaterialsDisplayOrder: (type, data) => {
        return axios.post(`/materials/display/order/${type}`, {data: data})
    },

    editImage: (image) => {
        return axios.post(`/edit/image`, {data: image});
    },

    editFont: (font) => {
        return axios.post(`/edit/font`, {data: font});
    },

    uploadImage: (type, data) => {
        return axios.post(`/upload/image/${type}`, data);
    },

    uploadSAGAInvoices: (data) => {
        return axios.post(`/upload/saga/invoices`, data);
    },

    uploadSAGAInvoicesStatus: () => {
        return axios.get(`/upload/saga/invoices`);
    },

    getOrders: () => {
        return axios.get(`/get/orders`);
    },

    getOrdersPaged: ({page, status, productFilter, searchTerm, fulfillerFilter}) => {
        return axios.post(`/orders/paged`, {data: {page, status, productFilter, searchTerm, fulfillerFilter}});
    },

    getCompletedGuestbookOrdersInLastYear: () => {
        return axios.get(`/orders/getCompletedGuestbookOrdersInLastYear`);
    },

    getSubordersForOrder: (orderId) => {
        return axios.get(`/get/suborders/of/${orderId}`);
    },

    downloadInvoice: (id) => {
        return axios.get(`/get/order/invoice/${id}`);
    },

    getAwbForOrder: (id) => {
        return axios.get(`/awb/order/${id}`);
    },

    getAllInvoicesForOrder: (id) => {
        return axios.get(`/order/invoices/get/all/${id}`);
    },

    deleteAwb: (id) => {
        return axios.get(`/awb/delete/${id}`);
    },

    createNewAwb: (id) => {
        return axios.get(`/awb/new/${id}`);
    },

    getAwbPrices: (data) => {
        return axios.post(`/awb/get/prices`, {data: data});
    },

    createAwb: (data) => {
        return axios.post(`/awb/create`, {data: data});
    },

    quickFillOrder: (data) => {
        return axios.post(`/order/quickFill`, {data: data});
    },

    createPacketaAwb: (data) => {
        return axios.post(`/awb/create/packeta`, {data: data});
    },

    downloadAwb: (id) => {
        return axios.get(`/download/awb/${id}`, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `${id}.pdf`);
        })
    },

    downloadAwbForPrint: (id) => {
        return axios.get(`/download/awb/${id}`, {responseType: 'blob'})
    },

    downloadAwbBatch: (id) => {
        return axios.get(`/shipping/batch/download/${id}`, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `print_${id}.pdf`);
        })
    },

    getWorkOrderForId: (id) => {
        return axios.get(`/get/order/work/${id}`, {responseType: 'blob'}).then((response) => {
            FileDownload(response.data, `${id}.pdf`);
        })
    },

    updateOrder: (order) => {
        return axios.post(`/update/order`, {data: order});
    },

    loadConfiguredProject: (projectId, userId) => {
        return axios.get(`/configured-project/${projectId}/${userId}`);
    },

    downloadImageBatch: (batchId, userId, name) => {
        return axios.get(`/configured-project-get-images/${batchId}/${userId}/${name}`, {responseType: 'blob'}).then((response) => {
            let name = response.headers['content-disposition'].split('"');
            saveAs(response.data, name[1]);
        })

    },

    orderRemake: (order) => {
        return axios.post(`/order/remake`, {data: order});
    },

    newUserForNewOrder: (data) => {
        return axios.post(`/users/newUserForNewOrder`, {data: data});
    },

    orderRemakeCreate: (order) => {
        return axios.post(`/order/remake/create`, {data: order});
    },

    updateShippingAddressForOrder: (order) => {
        return axios.post(`/update/order/shipping/address`, {data: order});
    },

    getOrderById: (id) => {
        return axios.get(`/get/order/${id}`);
    },

    getOrderAssistant: (id) => {
        return axios.get(`/get/order/assistant/${id}`);
    },

    loadTimeline: () => {
        return axios.get(`/timeline/get`);
    },

    loadProductConfig: () => {
        return axios.get(`/product/configs`);
    },

    getWorkOrders: () => {
        return axios.get(`/get/orders/work`);
    },

    getWorkOrdersUnfinished: () => {
        return axios.get(`/get/orders/work/unfinished`);
    },

    getProductionDeadlineOfOrder: (orderId) => {
        return axios.get(`/get/production/deadline/for/order/${orderId}`);
    },

    pushInvoice: (data) => {
        return axios.post(`/invoice/push`, {data: data});
    },

    getInvoiceById: (id) => {
        return axios.get(`/invoice/get/${id}`);
    },

    getInvoices: (page = 1) => {
        return axios.get(`/invoices/get/paginated/${page}`);
    },

    getInvoicesSearchByTerm: (searchTerm, page) => {
        return axios.get(`/invoices/search/term/${searchTerm}/${page}`);
    },

    getInvoicesSearchSeries: (series) => {
        return axios.get(`/invoices/search/series/${series}`);
    },

    getAllInvoices: () => {
        return axios.get(`/invoices/get`);
    },

    getVatRates: () => {
        return axios.get(`/invoice/vatMap`);
    },

    setVatRates: (data) => {
        return axios.post(`/invoice/vatMap`, {data: data});
    },

    getNewInvoiceDataForFianceId: (financeId) => {
        return axios.get(`/invoices/new/finance/${financeId}`);
    },

    downloadInvoiceById: (id) => {

        return axios.get(`/invoice/download/${id}`, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, response.headers.filename);
        })
    },

    syncEtsyOrders: () => {
        return axios.get(`/orders/sync/etsy`);
    },

    syncExchangeRates: () => {
        return axios.get(`/system/sync/exchange`);
    },

    placeExternalOrder: (data) => {
        return axios.post(`/external/place/order`, {data: data});
    },

    deleteExternalOrder: (id) => {
        return axios.get(`/external/delete/order/${id}`);
    },

    getExternalWork: () => {
        return axios.get('/external/orders');
    },

    toggleExternalWorkStatus: (data) => {
        return axios.post(`/external/status/toggle`, {data: data});
    },

    saveWorkItem: (data) => {
        return axios.post(`/order/work/item/save`, {data: data});
    },
    updateWorkItemStatus: (data) => {
        return axios.post(`/order/work/item/status/save`, {data: data});
    },
    markOrdersAsReadyToPack: (data) => {
        return axios.post(`/order/mark/as/ready/to/pack`, {data: data});
    },
    loadOrderItems: (id) => {
        return axios.get(`/order/work/item/get/${id}`);
    },
    deleteWorkItem: (id) => {
        return axios.get(`/order/work/item/delete/${id}`);
    },
    getWorkItemById: (id) => {
        return axios.get(`/order/work/item/get/id/${id}`);
    },
    getDataForInvoice: (orderId) => {
        return axios.get(`/get/data/for/invoice/${orderId}`);
    },
    getShipingAddressForOrder: (orderId) => {
        return axios.get(`/get/shipping/for/order/${orderId}`);
    },
    mergeOrders: (data) => {
        return axios.post(`/orders/merge`, {data: data});
    },
    uploadEtsyStatement: (data) => {
        return axios.post(`/sales/import/etsy`, {data: data});
    },
    getSalesData: () => {
        return axios.get(`/sales/get`);
    },
    loadHistoryForInvoice: (id) => {
        return axios.get(`/invoices/history/get/${id}`);
    },
    salesSetItemOrder: (data) => {
        return axios.post(`/sales/item/reorder`, {data: data});
    },
    downloadAccountantStatement: (id) => {
        return axios.get(`/sales/get/accountant/statement`, {responseType: 'blob'}).then((response) => {
            FileDownload(response.data, `statement.tsv`);
        })
    },
    downloadSagaXML: (date) => {
        return axios.post(`/sales/get/saga/invoices`, {data: date}, {responseType: 'blob'}).then(response => {
            FileDownload(response.data, response.headers.filename);
        })
    },
    downloadVATInvoiceButtonClicked: (date) => {
        return axios.post(`/sales/get/VAT/invoices`, {data: date}, {responseType: 'blob'}).then(response => {
            FileDownload(response.data, response.headers.filename);
        })
    },
    downloadSagaIncasariXML: (date) => {
        return axios.post(`/sales/get/saga/incasari`, {data: date}, {responseType: 'blob'}).then(response => {
            FileDownload(response.data, response.headers.filename);
        })
    },
    downloadSagaIncasariWithoutITXML: (date) => {
        return axios.post(`/sales/get/saga/incasari/without/it`, {data: date}, {responseType: 'blob'}).then(response => {
            FileDownload(response.data, response.headers.filename);
        })
    },
    getOrdersWithStatuses: (statuses) => {
        return axios.post(`/get/orders/with/statuses`, {data: statuses});
    },
    getAwb2ClientData: (statuses) => {
        return axios.get(`/get/orders/awb2client`);
    },
    getSuppliers: () => {
        return axios.get(`/suppliers/get`);
    },
    getSuppliersFull: (data) => {
        return axios.post(`/suppliers/get/full`, {data: data});
    },
    getSupplierProducts: (data) => {
        return axios.post(`/suppliers/products/get`, {data: data});
    },
    getSuppliersPricing: () => {
        return axios.get('/suppliers/pricing/get');
    },
    supplierPushProduct: (data) => {
        return axios.post(`/suppliers/product/push`, {data: data});
    },
    supplierPushProducts: (data) => {
        return axios.post(`/suppliers/products/push`, {data: data});
    },
    getUserById: (id) => {
        return axios.get(`/users/get/${id}`)
    },
    getAdminUsers: () => {
        return axios.get(`/users/admins`)
    },
    userToggleStatus: (userId) => {
        return axios.get(`/users/toggleStatus/${userId}`)
    },
    userToggleRemoteLogin: (userId) => {
        return axios.get(`/users/toggleRemoteLogin/${userId}`)
    },
    newOrder: (data) => {
        return axios.post(`/order/new`, {data: data})
    },
    getUsers: () => {
        return axios.get(`/users/all`)
    },
    loadUserAddresses: (user_id) => {
        return axios.get(`/user/addresses/${user_id}`)
    },
    orderFinanceAdd: (data) => {
        return axios.post(`/orderfinance/add`, {data: data})
    },

    orderFinanceGet: (id) => {
        return axios.get(`/orderfinance/get/${id}`)
    },

    financeRemoveInvoiceFromOrder: (data) => {
        return axios.post(`/orderfinance/remove/invoice`, {data: data})
    },

    financeDeleteItem: (id) => {
        return axios.get(`/orderfinance/delete/${id}`)
    },

    orderTimelineLoad: (id) => {
        return axios.get(`/timeline/get/order/${id}`)
    },

    orderFinanceAttachInvoice: (data) => {
        return axios.post(`/orderfinance/attach/invoice`, {data: data})
    },

    setSupplierForOrderItem: (data) => {
        return axios.post(`/order/work/item/set/supplier`, {data: data})
    },

    updateOrderStatus: (data) => {
        return axios.post(`/order/update/status`, {data: data})
    },

    updateOrderDeadline: (data) => {
        return axios.post(`/order/update/deadline`, {data: data})
    },

    updateOrderFulfiller: (data) => {
        return axios.post(`/order/update/fulfiller`, {data: data})
    },

    linkOrders: (data) => {
        return axios.post(`/order/link`, {data: data})
    },

    getPricingOptionsFor: (id) => {
        return axios.get(`/product/pricing/for/item/${id}`)
    },

    createFullInvoiceForFinance: (data) => {
        return axios.post(`/invoice/create/full/for/finance`, {data: data})
    },

    createNewEmptyInvoice: () => {
        return axios.get(`/invoice/create/new/empty`)
    },

    getItemsForInvoice: (data) => {
        return axios.post(`/invoice/get/items/for/order`, {data: data})
    },

    recalculateInvoiceTotals: (data) => {
        return axios.post(`/invoices/recalculate`, {data: data})
    },

    changeInvoicePaymentProvider: (data) => {
        return axios.post(`/invoices/change/payment/provider`, {data: data})
    },

    getReportsVerification: (from, to) => {
        return axios.get(`/reports/verification/${from}/${to}`)
    },

    getReportsIncome: (from, to) => {
        return axios.get(`/reports/income/${from}/${to}`)
    },

    getReportsIncomeReal: (from, to) => {
        return axios.get(`/reports/real/income/${from}/${to}`)
    },

    getReportsDividedReal: (from, to) => {
        return axios.get(`/reports/real/divided/${from}/${to}`)
    },

    getReportsDividedRealAllTime: (from, to) => {
        return axios.get(`/reports/real/divided/alltime/${from}/${to}`)
    },

    getEstimatedReportsIncome: (from, to) => {
        return axios.get(`/reports/estimated/income/${from}/${to}`)
    },

    getReportsProductionTSV: (from, to) => {
        return axios.get(`/reports/production/tsv/${from}/${to}`)
    },

    getReportsProductionTSVRepairs: (from, to) => {
        return axios.get(`/reports/production/tsv/repairs/${from}/${to}`)
    },

    getReportsProductionTSVRepairs2: (from, to) => {
        return axios.get(`/reports/production/tsv/repairs2/${from}/${to}`)
    },

    getReportMaterialUsage: () => {
        return axios.get(`/reports/material-usage`)
    },

    getReportBonuriConsum: () => {
        return axios.get(`/reports/bonuri-consum`)
    },

    getClientPrices: () => {
        return axios.get(`/client/prices/get/all`)
    },

    createClientPrices: (data) => {
        return axios.post(`/client/prices/create`, {data: data})
    },

    createSupplierPrices: (data) => {
        return axios.post(`/suppliers/prices/create`, {data: data})
    },

    updateClientPrice: (data) => {
        return axios.post(`/client/prices/update`, {data: data})
    },

    updateSupplierPrice: (data) => {
        return axios.post(`/suppliers/update/price`, {data: data})
    },

    getReportsPieces: () => {
        return axios.get(`/reports/pieces`)
    },

    getWebsiteGallery: (type) => {
        return axios.get(`/website/gallery/${type}`)
    },

    editWebsiteGallery: (data) => {
        return axios.post(`/website/gallery/edit`, {data: data})
    },

    deleteWebsiteGallery: (id) => {
        return axios.post(`/website/gallery/delete`, {data: {id: id}})
    },

    createWebsiteGallery: (data) => {
        return axios.post(`/website/gallery/create`, {data: data})
    },

    getPendingOrders: () => {
        return axios.get(`/orders/pending`)
    },

    getAddons: (type) => {
        return axios.get(`/addons/get/${type}`)
    },

    getEtsySyncStats: () => {
        return axios.get(`/system/values/etsy`)
    },

    getExchangeSyncStats: () => {
        return axios.get(`/system/values/exchange`)
    },

    toggleEtsyAutosync: () => {
        return axios.get(`/orders/toggle/etsy/autosync`)
    },

    uploadDhlValidationData: (data) => {
        return axios.post(`/dhlvalidation/upload`, {data: data})
    },

    getDhlValidationData: (selector) => {
        return axios.get(`/dhlvalidation/get/${selector}`)
    },

    getDHLItemsInTransit: () => {
        return axios.get(`/dhlvalidation/in/transit`)
    },

    updateDhlValidationItem: (data) => {
        return axios.post(`/dhlvalidation/update`, {data: data})
    },

    manualAppropveOrder: (orderId) => {
        return axios.post(`/orders/platform/approve`, {data: orderId})
    },

    notifyUserOrderShipped: (data) => {
        return axios.post(`/orders/platform/notify/shipped`, {data: data})
    },

    shippingBatchNew: (date) => {
        return axios.post(`/shipping/batch/create`, {data: date});
    },

    shippingBatchesGet: () => {
        return axios.get(`/shipping/batch/get`);
    },

    shippingBatchMarkAsShipped: (batchId) => {
        return axios.post(`/shipping/batch/mark/shipped`, {data: batchId});
    },

    shippingBatchGetItems: (batchId) => {
        return axios.post(`/shipping/batch/get/items`, {data: batchId});
    },

    shippingBatchesGenerate: (batchId) => {
        return axios.post(`/shipping/batch/generate`, {data: batchId});
    },

    shippingBatchItemCreateAwb: (data) => {
        return axios.post(`/shipping/batch/item/new/awb`, {data: data});
    },

    shippingBatchEditBoxDetails: (data) => {
        return axios.post(`/shipping/batch/box/edit`, {data: data});
    },

    shippingBatchDeleteAwb: (awbId) => {
        return axios.post(`/shipping/batch/awb/delete`, {data: awbId});
    },

    shippingBatchCreateBox: (data) => {
        return axios.post(`/shipping/batch/box/create`, {data: data});
    },

    shippingBatchDeleteBox: (data) => {
        return axios.post(`/shipping/batch/box/delete`, {data: data});
    },

    migrateMaterialsToLive: (type) => {
        return axios.get(`/materials/migrate/${type}`)
    },

    mergeInvoicesForShipping: (orderId) => {
        return axios.get(`/invoices/merge/for/shipping/${orderId}`)
    },

    finishOrderPacking: (orderId, data) => {
        return axios.post(`/orders/finish/packing/${orderId}`, {data: data})
    },

    fetchShippmentItemsForOrder: (orderId) => {
        return axios.get(`/orders/get/shipping/items/${orderId}`)
    },

    getOrderPieceWeights: () => {
        return axios.get(`/orders/get/piece/weights`);
    },

    addOrderPieceWeight: (data) => {
        return axios.post(`/orders/get/piece/weights/add`, {data: data});
    },

    stockItemCreate: (data) => {
        return axios.post(`/stocks/create`, {data: data});
    },

    stocksUpdatePickPlaceBulk: (data) => {
        return axios.post(`/stocks/pickPlace/update`, {data: data});
    },

    stockImportJSON: (data) => {
        return axios.get(`/stocks/json`);
    },

    getAllCuts: (data) => {
        return axios.get(`/stocks/cuts`);
    },

    addNewCut: (data) => {
        return axios.post(`/stocks/cuts`, {data: data});
    },

    stocksToTSV: (data) => {
        return axios.post(`/stocks/totsv`, {data: data}, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `inventory-export-${data.filename}.tsv`);
        });
    },

    dataToTSV: (data) => {
        return axios.post(`/data/totsv`, {data: data}, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `export-${data.filename}.tsv`);
        });
    },

    stockConsumeItem: (data) => {
        return axios.post(`/stocks/consumeItem`, {data: data});
    },

    stockCreateAndConsume: (data) => {
        return axios.post(`/stocks/createAndConsume`, {data: data});
    },

    stockUpdateCount: (data) => {
        return axios.post(`/stocks/updateCount`, {data: data});
    },

    stockAddMore: (data) => {
        return axios.post(`/stocks/addMore`, {data: data});
    },

    stockLoadTimelineForItem: (itemId) => {
        return axios.get(`/stocks/get/timeline/${itemId}`);
    },

    stockGetItemBySKU: (sku) => {
        return axios.get(`/stocks/get/item/${sku}`);
    },

    stocksGet: (stocName) => {
        return axios.get(`/stocks/get/${stocName}`);
    },

    stocksGetLight: (stocName) => {
        return axios.get(`/stocks/getLight/${stocName}`);
    },

    packetaGetConfigData: () => {
        return axios.get(`/packeta/config`);
    },

    getExpeditionsLast15Days: () => {
        return axios.get(`/awb/get/last15Days`);
    },

    packetaUpdateAssignment: (data) => {
        return axios.post(`/packeta/update/config`, {data: data});
    },

    getShipingSyncStatus: () => {
        return axios.get('/shiping/getSyncProgress');
    },

    forceShipingSync: () => {
        return axios.get('/shiping/forceSyncInProgress');
    },

    getEtsyConfig: () => {
        return axios.get(`/etsy/get/config`);
    },

    getEtsyLastForceSync: () => {
        return axios.get(`/etsy/get/lastForceSync`);
    },

    etsyPerformForcedSync: () => {
        return axios.get(`/etsy/perform/forceSync`);
    },

    etsyParseLedgerFromSequence: (seq) => {
        return axios.get(`/etsy/ledger/fromSequence/${seq}`);
    },

    etsyFetchPaymentById: (id) => {
        return axios.get(`/etsy/ledger/getPayment/${id}`);
    },

    etsyFetchReceiptById: (id) => {
        return axios.get(`/etsy/ledger/getReceipt/${id}`);
    },

    fetchLedgerToTSV: (fromSeq, toSeq) => {
        return axios.get(`/etsy/ledger/export/from/${fromSeq}/to/${toSeq}`, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `ledger-export-${fromSeq}-${toSeq}.tsv`);
        });

        // return axios.get(`/etsy/ledger/export/from/${fromSeq}/to/${toSeq}`);
    },

    etsyGetDisburseItems: () => {
        return axios.get(`/etsy/ledger/disburse/items`);
    },

    etsyAccountantGetParsedSequences: (seq) => {
        const date = new Date();
        const formatted = format(date, 'dd-MM-yyyy HH:mm');

        return axios.post(`/etsy/ledger/accountant/get/parsed/seq`, {data: seq}, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `Apertura ETSY ${formatted}.zip`);
        });
    },

    etsyAccountantParseFromSequence: (seq) => {
        return axios.get(`/etsy/ledger/accountant/parse/from/${seq}`, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `a.tsv`);
        });
    },

    editEtsyConfig: (data) => {
        return axios.post(`/etsy/edit/config`, {data: data});
    },

    etsyLedgerGetItems: (lastId) => {
        if (!lastId) {
            return axios.get(`/etsy/getItems/beginning`);
        } else {
            return axios.get(`/etsy/getItems/${lastId}`);
        }

    },

    etsyLedgerForceSync: () => {
        return axios.get(`/etsy/ledger/force/sync`);
    },

    etsyLedgerGetItemsNotParsed: (lastId) => {
        if (!lastId) {
            return axios.get(`/etsy/getItemsNotParsed/beginning`);
        } else {
            return axios.get(`/etsy/getItemsNotParsed/${lastId}`);
        }

    },

    getPersonalisationScreenItems: (data) => {
        return axios.post(`/orders/getPersonalisationScreenItems`, {data: data});
    },

    dev_test: () => {
        return axios.get(`/dev/test7`, {responseType: 'blob'}).then((response) => {
            saveAs(response.data, `stats.tsv`);
        });
    },

    dev_test2: () => {
        return axios.get(`/dev/test2`, {responseType: 'blob'}).then(response => {
            saveAs(response.data, `emboss items.tsv`);
        });
    },

    dev_test5: () => {
        return axios.get(`/dev/test5`, {responseType: 'blob'}).then(response => {
            saveAs(response.data, `emboss items.tsv`);
        });
    },

    dev_test6: () => {
        return axios.get(`/dev/test6`, {responseType: 'blob'}).then(response => {
            saveAs(response.data, `emboss items.tsv`);
        });
    },

};

