import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Input, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FoilConfig from "./FoilConfig";
import EmbossTemplateConfig from "./EmbossTemplateConfig";

class PricingConfigGuestbookSize extends Component {
    constructor(props) {
        super(props);
        this.state = {
            '20': this.props.price['20'] || 0,
            '30': this.props.price['30'] || 0,
            '40': this.props.price['40'] || 0,
            '50': this.props.price['50'] || 0,
            '60': this.props.price['60'] || 0,
            '70': this.props.price['70'] || 0,
            '80': this.props.price['80'] || 0,
            '90': this.props.price['90'] || 0,
            '100': this.props.price['100'] || 0,
            editing: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            '20': nextProps.price['20'] || 0,
            '30': nextProps.price['30'] || 0,
            '40': nextProps.price['40'] || 0,
            '50': nextProps.price['50'] || 0,
            '60': nextProps.price['60'] || 0,
            '70': nextProps.price['70'] || 0,
            '80': nextProps.price['80'] || 0,
            '90': nextProps.price['90'] || 0,
            '100': nextProps.price['100'] || 0,
        })
    }

    render() {
        const viewElement = this.getViewElement();
        const editElement = this.getEditElement();

        if (!this.state.editing) {
            return viewElement;
        }
        if (this.state.editing) {
            return editElement;
        }
    }

    getViewElement = () => {
        const size = this.props.size;

        return (
            <Table.Row key={size.id}>
                <Table.Cell>{size.cm}</Table.Cell>
                <Table.Cell>{this.state['20']}</Table.Cell>
                <Table.Cell>{this.state['30']}</Table.Cell>
                <Table.Cell>{this.state['40']}</Table.Cell>
                <Table.Cell>{this.state['50']}</Table.Cell>
                <Table.Cell>{this.state['60']}</Table.Cell>
                <Table.Cell>{this.state['70']}</Table.Cell>
                <Table.Cell>{this.state['80']}</Table.Cell>
                <Table.Cell>{this.state['90']}</Table.Cell>
                <Table.Cell>{this.state['100']}</Table.Cell>

                <Table.Cell>
                    <Button disabled={this.props.selectedCurrency !== 'USD'} basic onClick={() => this.setState({editing: true})}>edit</Button>
                </Table.Cell>
            </Table.Row>
        )
    }

    getEditElement = () => {
        if (this.props.selectedCurrency !== 'USD') {
            return null;
        }

        const size = this.props.size;
        return (
            <Table.Row key={size.id}>
                <Table.Cell>{size.cm}</Table.Cell>
                <Table.Cell><Input name='20' value={this.state['20']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='30' value={this.state['30']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='40' value={this.state['40']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='50' value={this.state['50']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='60' value={this.state['60']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='70' value={this.state['70']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='80' value={this.state['80']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='90' value={this.state['90']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='100' value={this.state['100']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Button basic onClick={this.onSaveClicked}>save</Button></Table.Cell>
            </Table.Row>
        )
    }

    onSaveClicked = () => {
        let payload = {
            values: {...this.state},
            path: `${this.props.type}.size.${this.props.size.cm}`,
            priceId: this.props.priceId,
        }

        delete payload.values.editing;

        this.setState({editing: false}, () => {
            this.props.onUpdateClientPrice(payload);
        })

    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});

}


export default PricingConfigGuestbookSize;
