import React from 'react';

import './OrderBadgeFilters.scss';

export class OrderBadgeDisplay extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className='OrderBadgeDisplay'>
                {this.props.isGuestbook === 1 &&
                <div className="item">G</div>
                }

                {this.props.isAlbum === 1 &&
                <div className="item">A</div>
                }

                {this.props.isBoudoir === 1 &&
                <div className="item">B</div>
                }

                {this.props.isClassicAlbum === 1 &&
                    <div className="item">CA</div>
                }

                {this.props.isGuestbookSlots === 1 &&
                    <div className="item">GS</div>
                }




            </div>
        )
    }


}

export default OrderBadgeDisplay;
