import React, {Component} from 'react';
import {Button, Checkbox, Container, Dimmer, Grid, Header, Image, Input, Item, Label, Loader} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PriceConfig from "./PriceConfig";

class ImagePassepartoutConfig extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();

        this.state = {
            editing: this.props.data.editing,

            name: this.props.data.name,
            meta: this.props.data.meta,
            imagePath: this.props.data.imagePath,
            type: this.props.data.type
        }

    }

    render() {
        const editButton = this.getEditButton();
        const editingElement = this.getEditingElement();

        const availableStyle = this.props.data.available ? {color: 'green'} : {color: 'red'};

        return (
            <Item>
                <Item.Image size='tiny'
                            src={`${this.props.data.imagePathUrl || 'https://via.placeholder.com/150'}`}/>

                <Item.Content>
                    <Item.Header as='a'>
                        {this.props.data.name} {editButton}
                    </Item.Header>
                    <Item.Extra>
                    </Item.Extra>

                    {editingElement}
                </Item.Content>
            </Item>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return (
                <Button compact basic color='green' icon='check' onClick={this.onSaveClicked}/>
            )
        }

        return (
            <Button basic icon='pencil alternate' compact  onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        if (!this.state.editing) {
            return false;
        }

        return (
            <Container>
                <Loader active={this.state.loading}/>

                <br/>
                <br/>
                <Button
                    basic
                    content="select image"
                    labelPosition="left"
                    icon="image"
                    onClick={() => this.fileInputRef.current.click()}
                />
                <input
                    ref={this.fileInputRef}
                    type="file"
                    hidden
                    onChange={this.onImageSelected}
                />

                {this.state.fontUploaded && (<h5>Image uploaded</h5>)}

                <br/>
                <br/>
                <Input label='name' placeholder='name' name='name' value={this.state.name}
                       onChange={this.handleChange}/>
                <br/>
                <br/>

                <Input label='scale 9' placeholder='10,20,30,40' name='scale9' value={this.state.meta.scale9}
                       onChange={this.handleMetaChanged}/>
                <br/>
                <br/>

                <Input label='dx (mm)' placeholder='1' name='dx' value={this.state.meta.dx}
                       onChange={this.handleMetaChanged}/>
                <br/>
                <br/>

                <Input label='dy (mm)' placeholder='1' name='dy' value={this.state.meta.dy}
                       onChange={this.handleMetaChanged}/>
                <br/>
                <br/>
            </Container>
        )
    };

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onSavedClicked({
                id: this.props.data.id,
                name: this.state.name,
                imagePath: this.state.imagePath,
                available: this.state.available,
                type: this.state.type,
                meta: this.state.meta,
                category: 'passepartout'
            })
        });

    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    handleMetaChanged = (e, {name, value}) => {
        let meta = {...this.state.meta}
        meta[name]=value;

        this.setState({meta: meta});
    };
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

    onImageSelected = (e) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        ApiService.uploadImage('images', data).then(r => {
            this.setState({
                loading: false,
                imagePath: r.data.image,
                fontUploaded: true
            })
        });
    }

}


export default ImagePassepartoutConfig;
