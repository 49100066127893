import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import './OrdersScreen.scss';
import {Container, Dimmer, Input, Loader, Menu, Pagination, Tab, Table} from "semantic-ui-react";
import OrderItem from "../components/OrderItem";
import ApiService from "../services/ApiService";
import {throttle} from "throttle-debounce";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {toast} from "react-toastify";

import * as queryString from 'query-string';
import EtsySyncStats from "../components/EtsySyncStats";
import ExchangeSyncStats from "../components/ExchangeSyncStats";
import {Extract, Validate} from "../components/utils/checksum";
import OrderBadgeFilters from "../components/OrderBadgeFilters";
import {Link} from "react-router-dom";
import OrderItemForTable from "../components/OrderItemForTable";
import ShipmentRefreshAndStatus from "../components/ShipmentRefreshAndStatus";
import Fulfiller from "../components/order/Fulfiller";
import FulfillerFilter from "../components/order/FulfillerFilter";

class OrdersScreen extends Component {
    constructor(props) {
        super(props);

        this.tabs = ['all', 'new', 'hold', 'mock-up', 'proofing', 'in production', 'urgent', 'issue', 'to pack', 'packing', 'ready to ship', 'shipped', 'complete', 'canceled', 'search']

        this.state = {
            loading: false,
            currentTabName: 'new',
            expandedId: 0,
            expandedTab: '',
            currentPage: 1,
            productFilter: "",
            fulfillerFilter: "",
            searchTerm: "",
            counts: {},
            orders: [],
            transit: []

        };

    }

    componentDidMount() {
        let searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get("page");
        let searchTerm = searchParams.get("searchTerm");
        let tab = searchParams.get("tab");
        let productFilter = searchParams.get("productFilter");
        let fulfillerFilter = searchParams.get("fulfillerFilter");

        if (!page) {
            page = 1;
            searchParams.set("page", "1");
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (!tab) {
            tab = "new";
            searchParams.set("tab", "new");
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({
            loading: true,
            currentTabName: tab,
            currentPage: page,
            productFilter: productFilter,
            fulfillerFilter: fulfillerFilter ?? '',
            searchTerm: searchTerm || '',
        }, async () => {

            const syncStats = await ApiService.getEtsySyncStats();
            const exchangeStats = await ApiService.getExchangeSyncStats();

            this.setState({etsySyncStats: syncStats.data, exchangeSync: exchangeStats.data}, () => {
                this.loadData();
            })
        });
    }


    render() {
        const orderItemsForTable = this.getOrderItemsElementForTable();

        let counters = this.state.counts;
        let currentTabTotal = counters[this.state.currentTabName] || 0;
        console.log({currentTabTotal})
        const tabIndex = this.tabs.indexOf(this.state.currentTabName);

        return (
            <Container style={{margin: '3rem 0', width: '90%'}}>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <Button style={{height: '38px'}} icon='plus square'
                            onClick={() => window.location = '/new-order'}/>

                    <div className="searchField">
                        <Input placeholder='John Doe or EXT-123'
                               value={this.state.searchTerm} onChange={this.onSearchTermChanged}/>
                        <Button onClick={this.searchByTerm}>Search</Button>
                    </div>

                    <OrderBadgeFilters value={this.state.productFilter} onFiltersChange={(data) => {
                        if (this.state.productFilter === data) {
                            this.setState({productFilter: ''}, () => {
                                this.computeFilteredItems();
                            })
                        } else {
                            this.setState({productFilter: data}, () => {
                                this.computeFilteredItems();
                            })
                        }
                    }}
                    />

                    <FulfillerFilter selected={this.state.fulfillerFilter} onFulfilledFilterChange={(filter => {
                        this.setState({fulfillerFilter: filter}, () => {
                            this.computeFulfillerFilter();
                        })
                    })}/>

                    <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: "column"}}>
                        <div style={{margin: '0 30px 20px 0'}}>
                            <EtsySyncStats data={this.state.etsySyncStats}
                                           onEtsySyncClicked={this.onSyncEtsyOrdersClicked}/>
                        </div>
                        <div style={{margin: '0 30px 20px 0'}}>
                            <ExchangeSyncStats data={this.state.exchangeSync}
                                               onExchangeSyncClicked={this.onExchangeSyncClicked}/>
                        </div>
                    </div>

                </div>

                <Tab style={{marginTop: '30px'}} onTabChange={this.handleTabChange} activeIndex={tabIndex}
                     panes={[
                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>All</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['all'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>New</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['new'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Hold</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['hold'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Mock-up</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['mock-up'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Proofing</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['proofing'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Production</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['in production'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Urgent</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['urgent'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Issue</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['issue'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>To pack</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['to pack'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Packing</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['packing'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Ready to ship</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['ready to ship'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Shipped</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['shipped'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Complete</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['complete'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Canceled</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>
                                             {counters['canceled'] || 0}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Search</div>
                                         <div style={{fontSize: '12px', marginLeft: '5px', color: "gray"}}>

                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <Link to={`/orders-tab/?tab=${tabIndex}`}>Open this tab</Link>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },
                     ]}>
                </Tab>

                {this.state.currentTabName === 'shipped' &&
                    <div style={{marginTop: '10px'}}><ShipmentRefreshAndStatus/></div>
                }

                {(this.state.currentTabName === 'search' && this.state.searchTerm.length) &&
                    <h2>Search results for: {this.state.searchTerm}</h2>
                }

                <Table basic selectable>
                    <Table.Header style={{position: "sticky", top: "0", background: "white"}}>
                        <Table.Row>
                            <Table.HeaderCell style={{width: "100px"}}>ID</Table.HeaderCell>
                            <Table.HeaderCell style={{width: "200px"}}>Customer</Table.HeaderCell>
                            <Table.HeaderCell style={{width: "170px"}}>Date</Table.HeaderCell>
                            <Table.HeaderCell style={{width: "200px"}}>Price</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'>Status</Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'>Errors {this.state.expandedTab}</Table.HeaderCell>
                            {this.state.currentTabName === 'shipped' &&
                                <Table.HeaderCell>Shippment status</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.loading &&
                            <h3 style={{margin: '20px'}}>Loading...</h3>
                        }
                        {orderItemsForTable}
                    </Table.Body>

                </Table>

                {/*<div style={{width: "90%"}}>*/}
                {/*    {orderItems}*/}
                {/*</div>*/}

                <div style={{display: 'flex'}}>
                    <Pagination
                        style={{margin: 'auto'}}
                        onPageChange={this.handlePaginationChange}
                        siblingRange={3}
                        activePage={this.state.currentPage}
                        totalPages={Math.ceil(currentTabTotal / 50)}
                    />
                </div>
            </Container>
        );
    }

    loadData = () => {
        this.setState({orders: [], loading: true}, () => {
            this.loadDataAction();
        })
    }

    loadDataAction = async () => {
        let data = await ApiService.getOrdersPaged({
            status: this.state.currentTabName,
            page: this.state.currentPage,
            productFilter: this.state.productFilter,
            fulfillerFilter: this.state.fulfillerFilter,
            searchTerm: this.state.searchTerm
        });

        let orders = data.data.orders;

        orders.forEach(order => {
            try {
                order.cost = JSON.parse(order.cost);
            } catch (e) {
                order.cost = {total_price: 0, currency: "USD"}
            }
        })
        this.setState({loading: false, orders: orders, counts: data.data.count, transit: data.data.transit});
    }

    onSearchTermChanged = (e, {value}) => {
        this.setState({searchTerm: value})
    };

    searchByTerm = () => {
        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search);

            searchParams.set("page", "1");
            searchParams.set("tab", "search");
            searchParams.set("searchTerm", this.state.searchTerm);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({currentTabName: 'search', currentPage: 1}, () => {
            this.loadData();
        })
    }

    computeFilteredItems = () => {
        let searchParams = new URLSearchParams(window.location.search);
        if (this.state.productFilter.length < 2) {
            searchParams.delete("productFilter");
        } else {
            searchParams.set("productFilter", this.state.productFilter);
        }
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.loadData();
    }

    computeFulfillerFilter = () => {
        let searchParams = new URLSearchParams(window.location.search);
        if (this.state.fulfillerFilter.length < 2) {
            searchParams.delete("fulfillerFilter");
        } else {
            searchParams.set("fulfillerFilter", this.state.fulfillerFilter);
        }
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.loadData();
    }

    handlePaginationChange = (e, {activePage}) => {
        this.setState({currentPage: activePage}, () => {
            let searchParams = new URLSearchParams(window.location.search);

            searchParams.set("page", activePage);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);

            this.loadData();
        })
    }


    handleTabChange = (e, data) => {
        let tabName = this.tabs[data.activeIndex];

        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("tab", tabName);
        searchParams.set("page", "1");
        if (tabName !== 'search') {
            searchParams.delete("searchTerm");
        }

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        if (tabName !== 'search') {
            this.setState({currentTabName: tabName, currentPage: 1, searchTerm: ''}, () => {
                this.loadData();
            })
        } else {
            this.setState({currentTabName: tabName, currentPage: 1}, () => {
                this.loadData();
            })
        }
    };

    getOrderItemsElementForTable = () => {
        if (!this.state.orders || this.state.orders.length === 0) {
            return null;
        }

        let transitItems = [];
        this.state.transit.forEach(item => {
            let dup = {...item};
            dup.statusMeta = JSON.parse(dup.statusMeta);
            dup.requestMeta = JSON.parse(dup.requestMeta);
            dup.awbMeta = JSON.parse(dup.awbMeta);
            transitItems.push(dup);
        })

        let itemsToRender = [...this.state.orders];
        itemsToRender.forEach(item => {
            const awbForOrder = transitItems.filter(i => i.orders_id === item.id);

            const lastAwb = awbForOrder[awbForOrder.length - 1];

            if (lastAwb) {
                item.lastAwbSentAt = lastAwb.created_at.split('T')[0].split('-').join('');
            } else {
                item.lastAwbSentAt = Infinity;
            }
        })

        itemsToRender.sort((a, b) => b.lastAwbSentAt - a.lastAwbSentAt);
        itemsToRender.forEach(item => {
            delete item.lastAwbSentAt;
        })


        return itemsToRender.map((order, index) => {
            const awbsForOrder = transitItems.filter(item => item.orders_id === order.id)

            return (
                <OrderItemForTable
                    key={order.id}
                    data={order}
                    expandedId={this.state.expandedId}
                    expandedTab={this.state.expandedTab}
                    awbsForOrder={awbsForOrder}
                    isShippedTab={this.state.currentTabName === 'shipped'}
                    onExpanded={(id, tab) => this.setState({expandedId: id, expandedTab: tab})}
                    history={this.props.history}
                />
            )
        })
    };

    onSyncEtsyOrdersClicked = () => {
        ApiService.syncEtsyOrders()
            .then(data => {
                if (data.data.error) {
                    toast.error(data.data.error, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                } else {
                    toast.success('Sync... This will take 2 minutes', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            })
    }

    onExchangeSyncClicked = () => {
        ApiService.syncExchangeRates().then(data => {
            toast.success('Sync...', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    }
}


export default OrdersScreen;
