import React, {Component} from 'react';
import './WebsiteSwatchComponent.scss';
import * as Reorder from 'react-reorder';
import {reorder} from 'react-reorder';
import {Button, ButtonGroup, Checkbox} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import {config} from "../config/config";

class WebsiteSwatchComponent extends Component {
    constructor(props) {
        super(props);


        const tabs = this.props.data[this.props.productType];

        this.state = {
            materials: [],
            editTabMode: true,
            selectedTabId: tabs[0]?.id || -1,
            tabs: tabs,
            selectorMode: 'reorder',
        };
    }

    componentDidMount() {
        this.loadMaterials();
    }

    render() {
        const tabsData = this.state.tabs.sort((a, b) => a.order - b.order)

        const tabStyle = {
            float: 'left',
            width: '90px',
            margin: '5px',
            border: '1px solid black',
            textAlign: 'center',
            padding: '5px',
            borderRadius: '9px',
            backgroundColor: 'white',
            cursor: 'pointer'
        }

        const materialReorderTabStyle = {
            float: 'left',
            width: '150px',
            margin: '5px',
            textAlign: 'center',
            padding: '5px',
            borderRadius: '9px',
            cursor: 'pointer'
        }

        const tabs = [...this.state.tabs];
        const currentTab = tabs.find(tab => tab.id === this.state.selectedTabId);
        if (currentTab) {
            currentTab.items.sort((a, b) => a.order - b.order)
        }

        return (
            <div className="WebsiteSwatchComponent" style={{display: "flex", flexDirection: 'column'}}>
                <h3>{this.props.productType} tabs and tab names</h3>
                <div className="tabNames">
                    <div style={{margin: '5px'}}>
                        <Button basic primary size={'tiny'} onClick={this.addNewTab}>add tab</Button>
                    </div>

                    {
                        this.state.tabs.map(tab => {
                            return (
                                <div className="editTabItem">
                                    <input value={tab.name} onChange={(e) => this.changeTabName(tab, e.target.value)}/>
                                    <Button size={'tiny'} color={'red'}
                                            onClick={() => this.removeTab(tab)}>Remove</Button>
                                </div>
                            )
                        })
                    }


                </div>

                {this.state.tabs.length > 0 &&
                <>
                    <h3>set the order of the tabs (drag to reorder)</h3>
                    <Reorder reorderId='id' onReorder={this.onReorder} placeholderClassName='placeholder'
                             draggedClassName='dragged'
                             style={{
                                 float: 'left',
                                 width: '100%',
                                 height: 'auto',
                                 margin: '0 0 15px 0',
                                 fontSize: '12px'
                             }}>
                        {tabsData.map(tab => {
                            return (
                                <div key={tab.id} style={tabStyle}>
                                    <div style={{border: 'none', textAlign: 'center'}}>{tab.name}</div>
                                </div>
                            )
                        })}
                    </Reorder>

                    <h3>assign or order swatches in each tab</h3>
                    <ButtonGroup>
                        {this.state.tabs.map(tab => {
                            return (
                                <Button basic active={tab.id === this.state.selectedTabId}
                                        onClick={() => this.setState({selectedTabId: tab.id})}>{tab.name}</Button>
                            )
                        })}
                    </ButtonGroup>

                    <div className="modeSelector">
                        <Checkbox
                            onChange={(e, {checked}) => {
                                this.setState({selectorMode: checked ? 'select' : 'reorder'})
                            }}
                            checked={this.state.selectorMode !== 'reorder'} label='Select materials'/>
                        <Checkbox
                            onChange={(e, {checked}) => {
                                this.setState({selectorMode: !checked ? 'select' : 'reorder'})
                            }}
                            checked={this.state.selectorMode === 'reorder'} label='Reorder materials'/>
                    </div>

                    {this.state.selectorMode === 'select' &&
                    <div className="materialSelector">
                        {this.state.materials.map(material => {
                            let thumb = this.getThumbByMaterial(material);

                            const index = currentTab.items.findIndex(item => item.id === material.id);

                            const selected = index > -1;

                            const className = selected ? 'materialThumb selected' : 'materialThumb';

                            return (
                                <div className="materialItemSelectMode"
                                     onClick={() => this.toggleMaterialToTab(material)}>
                                    <div>{material.name}</div>
                                    <img className={className} src={thumb}/>
                                </div>
                            )
                        })}
                    </div>
                    }

                    {this.state.selectorMode === 'reorder' &&

                    <Reorder reorderId='idmat' onReorder={this.onReorderMaterial} placeholderClassName='placeholder'
                             draggedClassName='dragged'
                             style={{
                                 float: 'left',
                                 width: '100%',
                                 height: 'auto',
                                 margin: '0 0 15px 0',
                                 fontSize: '12px'
                             }}>
                        {currentTab.items.map(item => {
                            const thumb = this.getThumbByMaterialId(item.id);

                            return (
                                <div key={item.id} style={materialReorderTabStyle}>
                                    <div className="materialItemReorderMode">
                                        <div>{item.name}</div>
                                        <img className="materialItemThumbReorderMode" src={thumb}/>
                                    </div>
                                </div>
                            )
                        })}
                    </Reorder>
                    }
                </>
                }

                <div style={{marginTop: '20px'}}>
                    <Button primary onClick={this.onSaveClicked}>SAVE</Button>
                </div>
            </div>
        );
    }

    getThumbByMaterial = (material) => {
        let thumb = 'https://via.placeholder.com/400?text=no%20image';
        if (material.meta && material.meta.imageThumb) {
            thumb = `${config.ASSETS_PATH}/products/material/${material.meta.imageThumb}`
        }

        return thumb;
    }

    getThumbByMaterialId = (materialId) => {
        const material = [...this.state.materials].find(m=>m.id === materialId);
        if(!material){
            return 'https://via.placeholder.com/400?text=no%20image'
        }

        return this.getThumbByMaterial(material);
    }

    addNewTab = () => {
        let tabs = [...this.state.tabs];
        let largestOrder = 0;
        let largestId = 0;

        tabs.forEach(tab => {
            if (tab.id > largestId) {
                largestId = tab.id;
            }

            if (tab.order > largestOrder) {
                largestOrder = tab.order;
            }
        })


        tabs.push({
                order: largestOrder + 1,
                id: largestId + 1,
                name: "New Tab",
                items: []
            }
        )

        this.setState({tabs: tabs, selectedTabId: tabs[0].id})
    }

    toggleMaterialToTab = (material) => {
        const tabs = [...this.state.tabs];
        const currentTab = tabs.find(tab => tab.id === this.state.selectedTabId);
        let items = currentTab.items;

        const index = items.findIndex(item => item.id === material.id);

        let lastOrder = 0;
        items.forEach(item => {
            if (item.order > lastOrder) {
                lastOrder = item.order;
            }
        })

        if (index === -1) {
            items.push({id: material.id, name: material.name, order: lastOrder + 1});
        } else {
            items.splice(index, 1);
        }

        this.setState({tabs: tabs})
    }

    removeTab = (tab) => {
        let tabs = [...this.state.tabs];
        let tabIndexToRemove = tabs.findIndex(t => t.id === tab.id);

        tabs.splice(tabIndexToRemove, 1);


        this.setState({selectorMode: 'select', tabs: tabs, selectedTabId: tabs[0]?.id || -1})
    }

    changeTabName = (tab, newName) => {
        let tabs = [...this.state.tabs];
        let tabToChange = tabs.find(t => t.id === tab.id);
        tabToChange.name = newName;

        this.setState({tabs: tabs})
    }

    onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        let newOrder = reorder(this.state.tabs, previousIndex, nextIndex);
        newOrder.forEach((item, index) => {
            item.order = index;
        })
        this.setState({tabs: newOrder});
    }

    onReorderMaterial = (event, previousIndex, nextIndex, fromId, toId) => {
        let tabs = [...this.state.tabs];
        const currentTab = tabs.find(tab => tab.id === this.state.selectedTabId);
        let items = currentTab.items;

        let newOrder = reorder(items, previousIndex, nextIndex);
        newOrder.forEach((item, index) => {
            item.order = index;
        })
        this.setState({tabs: tabs});
    }

    loadMaterials = async () => {
        const materials = await ApiService.getAllMaterials();
        const filteredMaterials = materials.data.filter(m => m[`for_${this.props.productType}`] === 1);
        this.setState({materials: filteredMaterials})
    }

    onSaveClicked = () => {
        this.props.onSaveClicked({tabs: this.state.tabs, productType: this.props.productType});
    }

}


export default WebsiteSwatchComponent;
