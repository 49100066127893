import React, {Component} from 'react';
import './SuppliersScreen.scss';
import ApiService from "../services/ApiService";
import {Button, Container, Dimmer, Dropdown, Icon, Input, Loader, Tab, Table} from "semantic-ui-react";
import SupplierProductConfig from "../components/SupplierProductConfig";
import SupplierPricesBook from "../components/productionPrices/SupplierPricesBook";
import SupplierPricesCorners from "../components/productionPrices/SupplierPricesCorners";
import SupplierPricesBambooBoxes from "../components/productionPrices/SupplierPricesBambooBoxes";
import SupplierPricesPouches from "../components/productionPrices/SupplierPricesPouches";
import SupplierPricesAlbumSleeves from "../components/productionPrices/SupplierPricesAlbumSleeves";
import SupplierPricesPremiumBox from "../components/productionPrices/SupplierPricesPremiumBox";
import SupplierPricesPen from "../components/productionPrices/SupplierPricesPen";
import SupplierPricesClassicAlbums from "../components/productionPrices/SupplierPricesClassicAlbums";
import SupplierPricesMarker from "../components/productionPrices/SupplierPricesMarker";

class SuppliersScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            dates: [],
            selectedDate: '',
            createProduct: false,
            selectedPrices: null,
            selectedSupplierId: 0,
            newDate: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const suppliersElement = this.getSuppliersElement();
        const createElement = this.getCreateDateElement();

        return (
            <div className='SuppliersScreen'>
                <Dimmer active={this.state.dataLoading}>
                    <Loader/>
                </Dimmer>

                <div className='container'>
                    <Tab style={{marginTop: '30px'}} onTabChange={this.handleTabChange} defaultActiveIndex={-1}
                         panes={suppliersElement}></Tab>
                    {this.state.selectedSupplierId > 0 &&
                    <div>
                        {this.state.createProduct === false &&
                        <Icon className='create-icon' name='plus circle' onClick={this.onCreateNewListClicked}/>
                        }

                        {this.state.createProduct === true &&
                        <Icon className='cancel-icon' name='times circle' onClick={()=>this.setState({createProduct: false})}/>
                        }

                        {this.state.createProduct === true &&
                        <div>   {createElement}</div>

                        }


                        <Dropdown closeOnBlur
                                  selection name='pricelistDate'
                                  options={this.state.dates}
                                  placeholder='Price list date'
                                  value={this.state.selectedDate} onChange={this.handleDateSelected}/>

                    </div>
                    }
                    {this.state.selectedPrices !== null &&
                    <div>
                        <SupplierPricesBook supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices} bookType='guestbook'
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesBook supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices} bookType='guestbook-slots'
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesBook supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices} bookType='album'
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesBook supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices} bookType='boudoir'
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesBook supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices} bookType='boudoir-empty'
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesBook supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices} bookType='vow-book'
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesClassicAlbums supplierId={this.state.selectedSupplierId}
                                                     prices={this.state.selectedPrices}
                                                     onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesCorners supplierId={this.state.selectedSupplierId}
                                            prices={this.state.selectedPrices}
                                            onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesBambooBoxes supplierId={this.state.selectedSupplierId}
                                               prices={this.state.selectedPrices}
                                               onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesPouches supplierId={this.state.selectedSupplierId}
                                                   prices={this.state.selectedPrices}
                                                   onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesPen supplierId={this.state.selectedSupplierId}
                                               prices={this.state.selectedPrices}
                                               onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesMarker supplierId={this.state.selectedSupplierId}
                                           prices={this.state.selectedPrices}
                                           onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesAlbumSleeves supplierId={this.state.selectedSupplierId}
                                               prices={this.state.selectedPrices}
                                               onUpdatePrice={this.onUpdatePrice}/>
                        <SupplierPricesPremiumBox supplierId={this.state.selectedSupplierId}
                                                    prices={this.state.selectedPrices}
                                                    onUpdatePrice={this.onUpdatePrice}/>
                    </div>
                    }

                </div>
            </div>
        )
    }

    getSuppliersElement = () => {
        if (!this.state.suppliers || this.state.suppliers.length === 0) {
            return null;
        }

        return this.state.suppliers.map(item => {
            return {menuItem: item.name, render: () => null}
        })
    };

    getCreateDateElement = () => {
        if (!this.state.createProduct) {
            return null;
        }

        return (
            <div style={{margin: '40px'}}>
                <Input label='New pricing list date' name='newDate' value={this.state.newDate}
                       onChange={this.handleChange}/>
                <Button basic onClick={this.onCreateNewPriceListSaveClicked}>save</Button>
            </div>
        )
    }

    onCreateNewListClicked = () => {
        let date = new Date();
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        let selectedDate = new Date(date.getTime() - userTimezoneOffset).toISOString().split('.')[0].replace('T', ' ');
        this.setState({
            createProduct: true,
            newDate: selectedDate
        })
    }

    handleTabChange = (e, data) => {
        let supplier = this.state.suppliers[data.activeIndex];
        this.setState({selectedSupplierId: supplier.id, selectedDate: null, selectedPrices: null});
    };

    handleDateSelected = (e, {value}) => {
        let selectedPrices = this.state.prices.find(p => p.date === value);
        this.setState({
            selectedDate: value,
            selectedPrices: selectedPrices
        })
    };

    loadData = () => {
        ApiService.getSuppliersPricing().then(data => {
            const suppliers = data.data.suppliers;
            const prices = data.data.prices;
            let dates = data.data.prices.map(i => i.date);
            dates = dates.map(item => {
                return {
                    key: item,
                    text: item,
                    value: item
                }
            })

            let selectedPrices = this.state.selectedPrices;
            if (selectedPrices !== null) {
                selectedPrices = prices.find(p => p.date === this.state.selectedDate);
            }

            this.setState({
                suppliers: suppliers,
                prices: prices,
                dates: dates,
                dataLoading: false,
                selectedPrices: selectedPrices
            })
        })
    }

    onUpdatePrice = (data) => {
        ApiService.updateSupplierPrice(data).then(data => {
            this.loadData();
        })
    }

    onCreateNewPriceListSaveClicked = () => {
        ApiService.createSupplierPrices(this.state.newDate).then(data => {
            this.setState({selectedDate: null, selectedPrices: null, createProduct: false}, () => {
                this.loadData();
            })
        })
    }


    onReloadData = () => {

    }


}


export default SuppliersScreen;
