import React, {Component} from 'react';
import './MaterialsScreen.scss';
import {Container, Dimmer, Header, Loader, Menu, Table} from "semantic-ui-react";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import ApiService from "../services/ApiService";
import MaterialConfig from "../components/MaterialConfig";
import MaterialConfig2 from "../components/MaterialConfig2";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import FoilConfig from "../components/FoilConfig";
import FontConfig from "../components/FontConfig";

class EmbossFoilsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            foils: [],
            fonts: [],
            editingId: -1
        }
    }

    componentDidMount() {
        this.loadEmbossFoils();
    }

    render() {
        const foils = this.getFoils();
        const fonts = this.getFonts();

        return (
            <Container>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>

                <Menu pointing inverted color='blue' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Emboss configuration</Menu.Item>
                </Menu>


                <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                    <Loader active={this.state.loading}/>

                    <Header as='h3'>Foils</Header>
                    <Table fixed selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    width={2}
                                >
                                    NAME
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                >
                                    COLOR
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    textAlign='center'
                                >
                                    AVAILABLE
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    textAlign='center'
                                >
                                    ACTION
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {foils}
                        </Table.Body>
                    </Table>
                    <Button content='add new foil' basic
                            onClick={this.onAddNewFoilClicked}/>

                    <Header as='h3' style={{marginTop: '80px'}}>Emboss fonts</Header>
                    <Table fixed selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    width={2}
                                >
                                    NAME
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={4}
                                >
                                    SAMPLE
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                >
                                    FILE
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={3}
                                    textAlign='center'
                                >
                                    SIZES
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    textAlign='center'
                                >
                                    AVAILABLE
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    textAlign='center'
                                >
                                    ACTION
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {fonts}
                        </Table.Body>
                    </Table>
                    <Button content='add new font' basic
                            onClick={this.onAddNewFontClicked}/>
                </Container>
            </Container>
        );
    }

    onDataChanged = (material) => {
        this.setState({loading: false}, ()=>{
            ApiService.editMaterial(material).then(data=>{
                this.setState({materials: data.data.materials, loading: false, editingId: -1})
            })
        })
    }

    getFoils = () => {
        if (this.state.foils.length === 0) {
            return null;
        }

        return this.state.foils.map(foil => {
            return (
                <FoilConfig key={foil.id} config={this.props.config} data={foil} sizes={this.state.sizes}
                            onFoilSavedClicked={this.onFoilChanged}/>
            )
        })
    }

    getFonts = () => {
        if (this.state.fonts.length === 0) {
            return null;
        }

        return this.state.fonts.map(font => {
            return (
                <FontConfig key={font.id} config={this.props.config} data={font}
                            onFontSaveClicked={this.onFontChanged}/>
            )
        })
    };

    onFoilChanged = (foil) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editFoil(foil).then(data => {
                this.setState({loading: false, foils: data.data.foils});
            });
        });
    };

    onFontChanged = (font) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editFont(font).then(data => {
                this.setState({loading: false, fonts: data.data.fonts});
            });
        });
    };


    onAddNewFoilClicked = () => {
        let foils = [...this.state.foils];
        foils.push({
            editing: true,
            id: -1,

            name: '',
            imagePath: '',
            available: false,
            type: this.props.type
        });

        this.setState({
            foils: foils
        })
    };

    onAddNewFontClicked = () => {
        let fonts = [...this.state.fonts];
        fonts.push({
            editing: true,
            id: -1,
            name: 'New Font Name',
            available: false
        });

        this.setState({
            fonts: fonts
        })
    };



    loadEmbossFoils = () => {
        ApiService.getFoils('bla').then(foilsData=>{
            ApiService.getFonts(this.props.type).then(fontsData => {
                this.setState({
                    loading: false,
                    foils: foilsData.data.foils,
                    fonts: fontsData.data.fonts
                });
            });
        })
    }


}


export default EmbossFoilsScreen;
