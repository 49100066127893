import React, {Component} from 'react';
import {Button, Dropdown, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Utils from "../../data/Utils";

class StockItemCreateMaterial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            materialName: null,
            sizeName: null,
            materialCategory: "",
            pickPlace: '',
            quantity: 0,
            saving: false
        }
    }

    render() {
        const SKU = this.getItemSKU();

        return (
            <div>
                <div style={{margin: '0px 10px', marginBottom: '20px'}}>
                    <div>SKU</div>
                    <Input disabled>{SKU}</Input>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{margin: '0px 10px'}}>
                        <div>Material</div>
                        <Dropdown
                            options={this.props.materials.map(i => {
                                return {key: i.id, value: i.name, text: i.name, id: i.id, category: i.category}
                            })}
                            selection compact
                            search
                            value={this.state.materialName}
                            style={{minWidth: '150px'}}
                            onChange={(e, item) => {
                                const mat = item.options.find(i=>i.value === item.value);
                                this.setState({materialName: item.value, materialCategory: mat.category})
                            }}
                        />
                    </div>

                    <div style={{margin: '0px 10px'}}>
                        <div>Size</div>
                        <Dropdown
                            options={this.props.sizes.map(i => {
                                return {key: i.id, value: i.cm, text: i.cm, id: i.id}
                            })}
                            selection compact
                            search
                            value={this.state.sizeName}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => {
                                this.setState({sizeName: item.value})
                            }}
                        />
                    </div>



                    <div style={{margin: '0px 10px'}}>
                        <div>Pick place</div>
                        <Input style={{width: '80px'}} value={this.state.pickPlace} onChange={(e, { name, value }) => this.setState({ pickPlace: value })}/>
                    </div>

                    <div style={{margin: '0px 10px'}}>
                        <div>Quantity</div>
                        <Input style={{width: '80px'}} value={this.state.quantity} onChange={(e, { name, value }) => this.setState({ quantity: value })}/>
                    </div>

                    <Button disabled={this.state.saving} style={{marginLeft: '10px', marginTop:'20px'}} primary onClick={this.onSaveClicked}>{this.state.saving ? 'Saving...' : 'Save'}</Button>
                </div>

            </div>
        )
    }

    onSaveClicked = () => {
        const sku = this.getItemSKU();

        let materialId = 0;
        let sizeId = 0;

        if(this.state.materialName){
            let material = this.props.materials.find(item=>item.name === this.state.materialName);
            materialId = material.id;
        }

        if(this.state.sizeName){
            let size = this.props.sizes.find(item=>item.cm === this.state.sizeName);
            sizeId = size.id;
        }

        let payload = {
            sku: sku,
            name: Utils.getCurrentStocName(),
            quantity: parseInt(this.state.quantity),
            pick_place: this.state.pickPlace,
            type: 'material',
            meta: {
                materialId: materialId,
                sizeId: sizeId,
                materialName: this.state.materialName,
                sizeName: this.state.sizeName,
                materialCategory: this.state.materialCategory
            }
        }


       this.setState({saving: true}, async()=>{
            await ApiService.stockItemCreate(payload);

            this.setState({saving: false}, ()=>{
                this.props.hide();
            })
       });
    }

    delay = () => {
       return new Promise(resolve=>{
           setTimeout(()=>{
               return resolve();
           }, 2000)
       })
    }

    getItemSKU = () => {
        let materialId = 0;
        let sizeId = 0;

        if(this.state.materialName){
            let material = this.props.materials.find(item=>item.name === this.state.materialName);
            materialId = material.id;
        }

        if(this.state.sizeName){
            let size = this.props.sizes.find(item=>item.cm === this.state.sizeName);
            sizeId = size.id;
        }

        return `MATERIAL-M${materialId}-${this.state.sizeName}`
    }
}

export default StockItemCreateMaterial;
