import React, {Component} from 'react';
import './TrustScreen.scss';
import {Container, Label} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class TrustScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            online: {}
        }
    }

    componentDidMount() {
        this.getData();
        this.fetchOnlineStatus()
    }

    render() {
        const usersElements = this.getUsersElements();

        return (
            <div className="TrustScreen">
                <div className="users">
                    {usersElements}
                </div>
            </div>
        )
    }

    getUsersElements = () => {
        return this.state.users.map(user => {
            let userOnline = this.state.online[user.id];

            return (
                <div className="user">
                    <h1>{user.email}</h1>
                    <div className="labels">
                        <div className="label">
                            <Label color={"gray"}>
                                Role: {user.role}
                            </Label>
                        </div>
                        <div className="label">
                            <Label color={userOnline ? 'green' : 'red'}>
                                Online: {userOnline ? 'Yes' : 'No'}
                            </Label>
                        </div>
                        <div className="label" onClick={() => this.onStatusToggled(user.id)}>
                            <Label as='a' color={user.status === 2 ? 'green' : 'red'}>
                                Status: {user.status === 2 ? 'Active' : 'Disabled'}
                            </Label>
                        </div>
                        <div className="label" onClick={() => this.onRemoteLoginToggled(user.id)}>
                            <Label as='a' color={user.remote_login === 1 ? 'green' : 'red'}>
                                Remote Login: {user.remote_login === 1 ? 'Enabled' : 'Disabled'}
                            </Label>
                        </div>
                    </div>

                    {userOnline &&
                        <div className="online">
                            <h4>Pages open:</h4>
                            {userOnline.map(item => <div><a href={item}>{item}</a></div>)}
                        </div>
                    }
                </div>
            )
        })
    }

    onStatusToggled = async (userId) => {
       await ApiService.userToggleStatus(userId);
       this.getData();
    }

    onRemoteLoginToggled = async (userId) => {
        await ApiService.userToggleRemoteLogin(userId);
        this.getData();
    }

    getData = async () => {
        const users = await ApiService.getAdminUsers();
        this.setState({users: users.data});
    }

    fetchOnlineStatus = () => {
        let usersOnline = {};

        fetch('https://alexvlad.com/presence/live?k=2102594').then((response) => response.json())
            .then((data) => {
                data.forEach(item => {
                    item = item.data;
                    if (item && item.id) {
                        if (!usersOnline[item.id]) {
                            usersOnline[item.id] = [];
                        }

                        usersOnline[item.id].push(item.path)
                    }
                })
                this.setState({online: usersOnline}, () => {
                    setTimeout(() => {
                        this.fetchOnlineStatus();
                    }, 1000)
                })
            });
    }
}


export default TrustScreen;
