import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import StyleConfig from "./StyleConfig";
import GalleryItem from "./GalleryItem";

class AddonsConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, items: []};
    }

    componentDidMount() {
      this.loadData();
    }

    render() {
        const addonsElement = this.getAddonsElements();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>
                {addonsElement}
            </Container>
        );
    }

    getAddonsElements = () => {
        if(this.state.items.length === 0) {
            return null;
        }

        return this.state.items.map(item=>{
            return(
                <div className='addon'>
                    <div className='title'>{item.name}</div>
                </div>
            )
        })
    }

    loadData = () => {
        ApiService.getAddons(this.props.type).then(data=>{
            this.setState({items: data.data})
        })
    }

}


export default AddonsConfig;
