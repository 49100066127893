import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Grid,
    Header,
    Image,
    Input,
    Item,
    Label,
    Loader,
    Table
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PriceConfig from "./PriceConfig";
import {config} from "../config/config";

class FoilConfig extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();

        this.state = {
            editing: this.props.data.editing,

            name: this.props.data.name,
            imagePath: this.props.data.imagePath,
            color: this.props.data.color,
            available: this.props.data.available === 1,
            type: this.props.data.type,
        }

    }

    render() {
        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }

        const item = this.props.data;

        if (this.state.editing) {
            return this.getEditingElement();
        }

        return (
            <Table.Row key={item.id}>
                <Table.Cell><h5>{item.name}</h5></Table.Cell>
                <Table.Cell><h5>{item.color}</h5></Table.Cell>

                <Table.Cell>
                    <div style={availableStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return (
                <Button compact basic color='green' icon='check' onClick={this.onSaveClicked}/>
            )
        }

        return (
            <Button basic icon='pencil alternate' compact onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        const item = this.props.data;

        return (
            <Table.Row key={item.id}>
                <Table.Cell> <Input placeholder='name' name='name' value={this.state.name}
                                    onChange={this.handleChange}/></Table.Cell>
                <Table.Cell> <Input placeholder='color' name='color' value={this.state.color}
                                    onChange={this.handleChange}/></Table.Cell>
                <Table.Cell textAlign='center'>
                         <Checkbox label='available' name='available' toggle checked={this.state.available}
                                   onChange={this.onCheckboxChanged}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={this.onSaveClicked}>save</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    };

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onFoilSavedClicked({
                id: this.props.data.id,
                name: this.state.name,
                color: this.state.color,
                imagePath: this.state.imagePath,
                available: this.state.available,
                type: this.state.type
            })
        });

    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

    onImageSelected = (e) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        ApiService.uploadImage('emboss-foils', data).then(r => {
            this.setState({
                loading: false,
                imagePath: r.data.image
            })
        });
    }

}


export default FoilConfig;
