import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Button, Container, Header, Input, Menu} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class CurrencyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rates: {
                EUR: '...',
                GBP: '...',
                AUD: '...',
            },
            EUR: 0,
            GBP: 0,
            AUD: 0,
            editing: false
        }
    }

    componentDidMount() {
        ApiService.getCurrencyRatios().then(data=>{
            this.setState({rates: data.data[0]})
        })
    }

    render() {
        const editingElement = this.getEditingElement();
        const viewElement = this.getViewElement();

        return (
            <Container>
                <Menu pointing inverted color='blue' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Currencies rates configuration</Menu.Item>
                </Menu>

                {viewElement}
                {editingElement}
            </Container>
        );
    }

    getEditingElement = () =>{
        if(!this.state.editing){
            return null;
        }

        return (
            <div>
                <div>USD: 1</div>
                <Input label='EUR' placeholder='' name='EUR' value={this.state.EUR} onChange={this.handleChange}/>
                <Input label='GBP' placeholder='' name='GBP' value={this.state.GBP} onChange={this.handleChange}/>
                <Input label='AUD' placeholder='' name='AUD' value={this.state.AUD} onChange={this.handleChange}/>

                <Button style={{marginTop: '3rem'}} content='save' color={'green'}  onClick={this.onSaveClicked}/>
            </div>
        )
    }

    getViewElement = () =>{
        if(this.state.editing){
            return null;
        }

        return (
            <div>
                <div>USD: 1</div>
                <div>EUR: {this.state.rates.EUR}</div>
                <div>GBP: {this.state.rates.GBP}</div>
                <div>AUD: {this.state.rates.AUD}</div>

                <Button style={{marginTop: '3rem'}} content='edit' basic onClick={()=>this.setState({editing: true})}/>
            </div>
        )
    }

    onSaveClicked = () => {
        let payload = {
            EUR: this.state.EUR,
            GBP: this.state.GBP,
            AUD: this.state.AUD,
        }

        ApiService.setCurrencyRatios(payload).then(data=>{
            this.setState({rates: data.data[0], editing: false});
        })
    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});


}


export default CurrencyScreen;
