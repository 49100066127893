import React, {Component} from 'react';
import './PersonalisationScreen.scss';
import {Checkbox, Container, Dropdown, Input, Menu, Tab,} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ProductPersonalisationEmboss from "../components/personalisation/types/ProductPersonalisationEmboss";
import ProductPersonalisationUVPrint from "../components/personalisation/types/ProductPersonalisationUVPrint";
import ProductPersonalisationFolieTermo from "../components/personalisation/types/ProductPersonalisationFolieTermo";
import ProductPersonalisationLaser from "../components/personalisation/types/ProductPersonalisationLaser";

class PersonalisationScreen extends Component {

    constructor(props) {
        super(props);

        this.tabs = ['emboss', 'uv print', 'folie termotransfer', 'laser engraving']


        this.state = {
            loading: false,
            currentTabName: '',
            configs:{},
            currentDeadline: -1,
            searchTerm: "",
            hideDone:false,
            sortByTemplate:false,
            counters: {emboss: {}, laserengraving: {}, termofoil: {}, uv: {}},
            orders: [],
            isSearchResult: false
        }
    }

    componentDidMount() {
        let searchParams = new URLSearchParams(window.location.search);
        let tab = searchParams.get("tab");
        let deadline = parseInt(searchParams.get("deadline"));
        let searchTerm = searchParams.get("searchTerm");
        let hideDone = searchParams.get("hideDone") === "true";
        let sortByTemplate = searchParams.get("sortByTemplate") === "true";

        if (!tab) {
            tab = "emboss";
            searchParams.set("tab", "emboss");
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (isNaN(deadline)) {
            deadline = -1;
            searchParams.set("deadline", -1);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }
        if (!hideDone) {
            hideDone = false;
            searchParams.set("hideDone", false);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }
        if (!sortByTemplate) {
            sortByTemplate = false;
            searchParams.set("sortByTemplate", false);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }


        ApiService.loadProductConfig().then(loadedConfigs => {
            this.setState({
                loading: true,
                configs: loadedConfigs.data,
                currentTabName: tab,
                currentDeadline: deadline,
                hideDone: hideDone,
                sortByTemplate: sortByTemplate,
                searchTerm: searchTerm || '',
            }, async () => {
                this.loadData();
            });
        })


    }

    render() {
        const tabIndex = this.tabs.indexOf(this.state.currentTabName);
        const workItemsElements = this.getWorkItemsElements(this.state.orders);

        return (
            <Container className="PersonalisationScreen" style={{margin: '3rem 0'}}>
                <div className="headbar">
                    <div className="searchField">
                        <Input placeholder='12-34-Y'
                               value={this.state.searchTerm} onChange={this.onSearchTermChanged}/>
                        <Button onClick={this.searchByTerm}>Search</Button>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
                            <div style={{fontWeight: 'bold', marginRight: '10px'}}>Deadline:</div>
                            <Dropdown
                                options={[-1, 0, 1, 2, 3, 4, 5, 6, 7].map(i => {
                                    return {
                                        key: i,
                                        value: i,
                                        text: i === -1 ? 'Toate' : i === 0 ? 'AZI' : i === 1 ? 'Maine' : `peste ${i} zile`,
                                        id: i
                                    }
                                })}
                                selection compact
                                value={parseInt(this.state.currentDeadline)}
                                style={{minWidth: '200px'}}
                                onChange={(e, {name, value}) => this.onEmbossTimeFilterChanged(value)}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <Checkbox
                        label='ascunde terminate'
                        onChange={this.onHideDoneToggled}
                        checked={this.state.hideDone}
                    />
                </div>

                {this.state.currentTabName === 'emboss' &&
                <div style={{marginTop:'10px'}}>
                    <Checkbox
                        label='grupeaza dupa template'
                        onChange={this.onSortByTemplateToggled}
                        checked={this.state.sortByTemplate}
                    />
                </div>
                }

                <Tab style={{marginTop: '30px'}} onTabChange={this.handleTabChange} activeIndex={tabIndex}
                     panes={[
                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Emboss</div>
                                         <div className={this.state.counters.emboss.done===this.state.counters.emboss.total ? 'tabStatus done' : 'tabStatus'}>
                                             { !this.state.isSearchResult ? `${this.state.counters.emboss.done} / ${this.state.counters.emboss.total}` : 'search result'}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>UV Print</div>
                                         <div className={this.state.counters.uv.done===this.state.counters.uv.total ? 'tabStatus done' : 'tabStatus'}>
                                             {!this.state.isSearchResult ?  `${this.state.counters.uv.done} / ${this.state.counters.uv.total}` : 'search result'}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Folie termotransfer</div>
                                         <div className={this.state.counters.termofoil.done===this.state.counters.termofoil.total ? 'tabStatus done' : 'tabStatus'}>
                                             {!this.state.isSearchResult ? `${this.state.counters.termofoil.done} / ${this.state.counters.termofoil.total}` : 'search result'}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },

                         {
                             menuItem: (
                                 <Menu.Item>
                                     <div style={{display: 'flex', alignItems: 'baseline'}}>
                                         <div>Laser engraving</div>
                                         <div className={this.state.counters.laserengraving.done===this.state.counters.laserengraving.total ? 'tabStatus done' : 'tabStatus'}>
                                             {!this.state.isSearchResult ?  `${this.state.counters.laserengraving.done} / ${this.state.counters.laserengraving.total}`  : 'search result'}
                                         </div>
                                     </div>
                                 </Menu.Item>
                             ),
                             render: () => null
                         },
                     ]}>
                </Tab>

                <div className="list">
                    {workItemsElements}
                    {/*<ProductPersonalisationEmboss/>*/}
                </div>
            </Container>
        )
    }

    getWorkItemsElements = (orders) => {
        let renderEmbossStack = [];
        let renderUVStack = [];
        let renderFolioStack = [];
        let renderLaserStack = [];

        orders.forEach(order => {
            let mainOrderId = order.id;
            if (order.suborder_of) {
                mainOrderId = order.suborder_of;
            }

            order.work_items.forEach(item => {
                switch (item.product_type) {
                    case 'guestbook':
                    case 'album':
                    case 'guestbook-slots':
                    case 'boudoir':
                    case 'boudoir-empty':
                    case 'classic-album':
                        let data = {...item};
                        data.mainOrderId = mainOrderId;

                        if (!data.item.embossTemplate) {
                            data.item.embossTemplate = {id: 0, name: ''};
                        }

                        if ( isNaN(data.deadline) || data.deadline === null) {
                            data.deadline = Infinity
                        }

                        if (data.item.emboss?.label !== 'no') {
                            const isDone = data.progress.stantat === 1;

                            if(!this.state.hideDone || (this.state.hideDone && !isDone)) {
                                renderEmbossStack.push(data);

                            }
                        }
                        if (data.item.cover_uv !== 'no') {
                            const isDone = data.progress.uv === 1;
                            if(!this.state.hideDone || (this.state.hideDone && !isDone)) {
                                renderUVStack.push(data)

                            }
                        }

                        if (data.item.folio_color) {
                            const isDone = data.progress.folie_termo === 1;

                            if(!this.state.hideDone || (this.state.hideDone && !isDone)) {
                                renderFolioStack.push(data)

                            }
                        }

                        if (data.item.laser_engraving) {
                            const isDone = data.progress.laser_engraving === 1;

                            if(!this.state.hideDone || (this.state.hideDone && !isDone)) {
                                renderLaserStack.push(data)

                            }
                        }

                        break;
                }
            })
        })

        if(this.state.sortByTemplate){
            renderEmbossStack = renderEmbossStack.sort((a, b) => {
                return a.item.embossTemplate.id.localeCompare(b.item.embossTemplate.id)
            })
        }

        switch(this.state.currentTabName){
            case 'emboss':
                return renderEmbossStack.map(data=><ProductPersonalisationEmboss key={data.id} configs={this.state.configs}
                                                                                 data={data}
                                                                                 onEmbossStatusToggled={this.onEmbossStatusToggled}/>);
            case 'uv print':
                return renderUVStack.map(data=><ProductPersonalisationUVPrint  key={data.id} configs={this.state.configs}
                                                                              data={data}
                                                                              onEmbossStatusToggled={this.onEmbossStatusToggled}/>);
            case 'folie termotransfer':
                return renderFolioStack.map(data=><ProductPersonalisationFolieTermo  key={data.id} configs={this.state.configs}
                                                                                    data={data}
                                                                                    onEmbossStatusToggled={this.onEmbossStatusToggled}/>);
            case 'laser engraving':
                return renderLaserStack.map(data=><ProductPersonalisationLaser  key={data.id} configs={this.state.configs}
                                                                               data={data}
                                                                               onEmbossStatusToggled={this.onEmbossStatusToggled}/>);
            default:
                return [];
        }
    }

    handleTabChange = (e, data) => {
        let tabName = this.tabs[data.activeIndex];

        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("tab", tabName);

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({currentTabName: tabName}, () => {
            this.loadData();
        })

    };

    loadData = () => {
        ApiService.getPersonalisationScreenItems({
            deadline: this.state.currentDeadline,
            searchTerm: this.state.searchTerm
        }).then(data => {
            this.setState({loading: false, counters: data.data.counters, orders: data.data.orders, isSearchResult: data.data.isSearchResult})
        });
    }

    onEmbossTimeFilterChanged = (data) => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("deadline", data);

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({currentDeadline: data}, () => {
            this.loadData();
        })
    }

    onSearchTermChanged = (e, {value}) => {
        this.setState({searchTerm: value})
    };

    searchByTerm = () => {
        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search);

            searchParams.set("searchTerm", this.state.searchTerm);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.loadData();

    }

    onEmbossStatusToggled = (newProgress) => {
        ApiService.getWorkItemById(newProgress.id).then(async data => {
            let item = data.data;

            item.progress[newProgress.step] = newProgress.value;
            await ApiService.updateWorkItemStatus(item);
            this.loadData();
        })
    }

    onHideDoneToggled = () =>{
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("hideDone", !this.state.hideDone);

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({hideDone: !this.state.hideDone}, () => {
            // this.loadData();
        })
    }

    onSortByTemplateToggled = () =>{
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("sortByTemplate", !this.state.sortByTemplate);

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({sortByTemplate: !this.state.sortByTemplate}, () => {
            // this.loadData();
        })
    }
}


export default PersonalisationScreen;
