import React, {Component} from 'react';
import {Button, Checkbox, Container, Image, Input, Item, Label} from "semantic-ui-react";

class PouchConfig extends Component {
    constructor(props) {
        super(props);

            this.state = {
                editing: this.props.data.editing,

                cm: this.props.data.cm,
                available: this.props.data.available === 1,
                type: this.props.data.type
            }

    }

    render() {
        const editButton = this.getEditButton();
        const editingElement = this.getEditingElement();

        const availableStyle = this.props.data.available ? {color: 'green'} : {color: 'red'};

        return (
            <Item>
                <Item.Image size='tiny' src='https://via.placeholder.com/150'/>

                <Item.Content>
                    <Item.Header as='a'>
                        {this.props.data.cm} cm {editButton}
                    </Item.Header>
                    <Item.Description>
                        <p>{this.props.data.inch} inch</p>
                    </Item.Description>

                    {editingElement}

                    {this.state.editing &&
                    <Button style={{marginTop: '15px'}} compact primary onClick={this.onSaveClicked}>Save</Button>
                    }
                </Item.Content>
            </Item>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return null;
        }

        return (
            <Button compact basic icon='pencil alternate' onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        if(!this.state.editing){
            return false;
        }

        return (
            <Container>
                <br/>
                <br/>
                <Input label='cm' placeholder='10x10' name='cm' value={this.state.cm} onChange={this.handleChange}/>
                <br/>
                <br/>

                <Checkbox label='available' name='available' toggle checked={this.state.available}  onChange={this.onCheckboxChanged}/>
            </Container>
        )
    };

    onSaveClicked =() => {
        this.setState({editing: false}, ()=>{
            this.props.onSavedClicked({
                id: this.props.data.id,
                cm: this.state.cm,
                available: this.state.available,
                type: this.state.type
            })
        });

    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });
    onCheckboxChanged = (e, { name, checked }) => this.setState({ [name]: checked });

}


export default PouchConfig;
