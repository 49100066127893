import React, {Component} from 'react';
import './OrderProduction.scss';
import ApiService from "../../services/ApiService";
import {Button, Dropdown} from "semantic-ui-react";
import {toast} from "react-toastify";
import OrderItemStatus from "../OrderItemStatus";

class OrderProduction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            suppliers: [],
            showMore: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.id !== this.props.id){
            this.loadData();
        }
    }

    render() {
        if (!this.state.items || this.state.items.length === 0) {
            return null;
        }

        const itemsElements = this.getItemsElements();

        return (
            <div className='OrderProduction'>
                <Button toggle active={this.state.showMore}
                        onClick={() => this.setState({showMore: !this.state.showMore})}>
                    Pricing details
                </Button>
                {itemsElements}
            </div>
        );
    };

    getItemsElements = () => {
        return this.state.items.map(item => {
            switch (item.product_type) {
                case 'guestbook':
                    return this.formatGuestbook(item);
                case 'guestbook-slots':
                    return this.formatGuestbookSlots(item);
                case 'vow-book':
                    return this.formatVowBook(item);
                case 'classic-album':
                    return this.formatClassicAlbum(item);
                case 'boudoir-empty':
                    return this.formatGuestbook(item);
                case 'album':
                    return this.formatAlbum(item);
                case 'boudoir':
                    return this.formatBudoir(item);
                case 'corners':
                    return this.formatCorners(item);
                case 'bamboo-box':
                    return this.formatBambooBox(item);
                case 'pouch':
                    return this.formatPouch(item);
                case 'pen':
                    return this.formatPen(item);
                case 'marker':
                    return this.formatMarker(item);
                case 'pen-two':
                case 'pen-three-same':
                    return this.formatPen(item);
                case 'pen-three':
                    return this.formatPenThree(item);
                case 'album-sleeve':
                    return this.formatAlbumSleeve(item);
                case 'premium-box':
                    return this.formatPremiumBox(item);
                case 'addon':
                    return this.formatAddon(item);
            }
        })
    };

    formatCorners = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'corners':
                    if(!productPrices.corners){
                        return `No price set for corners`
                    }
                    if(productPrices.corners.length === 0){
                        return 'No corners selected';
                    }

                    let total = 0;
                    productPrices.corners.forEach(c=>{
                        total += c.total;
                    });

                    total = parseFloat(total.toFixed(2));
                    return `${productPrices.supplierName} ${productPrices.corners[0].currency} ${total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>

                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>corners</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>white</div>
                                <div className='value'>{data.item.corners_white}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>black</div>
                                <div className='value'>{data.item.corners_black}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>kraft</div>
                                <div className='value'>{data.item.corners_kraft}</div>
                            </div>
                        </div>
                        <div className='match'>
                            <div>{getPriceFor('corners')}</div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        )
    };

    formatGuestbook = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bloc':
                    if(!productPrices.bloc){
                        return `No price set for bloc`
                    }
                    return `${productPrices.supplierName} ${productPrices.bloc.currency} ${productPrices.bloc.total}`
                case 'cover':
                    if(!productPrices.cover){
                        return `No price set for cover`
                    }
                    return `${productPrices.supplierName} ${productPrices.cover.currency} ${productPrices.cover.total}`
                case 'corners':
                    if(!productPrices.corners){
                        return `No price set for corners`
                    }
                    if(productPrices.corners.length === 0){
                        return 'No corners selected';
                    }

                    let total = 0;
                    productPrices.corners.forEach(c=>{
                        total += c.total;
                    });
                    total = parseFloat(total.toFixed(2));
                    return `${productPrices.supplierName} ${productPrices.corners[0].currency} ${total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                    size: {data.item.size.value} | pages: {data.item.nr_pages} | spacers: {data.item.page_spacer} |
                    material: {data.item.material.value} | style: {data.item.cover_style.value} |
                    uv: {data.item.cover_uv} |
                    emboss: {data.item.emboss.label}</div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }
                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>bloc</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>nr_pages</div>
                                <div className='value'>{data.item.nr_pages}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>page_type</div>
                                <div className='value'>{data.item.page_type.label}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>page_spacer</div>
                                <div className='value'>{data.item.page_spacer}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bloc')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>cover</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>material</div>
                                <div className='value'>{data.item.material.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_uv</div>
                                <div className='value'>{data.item.cover_uv}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_emboss</div>
                                <div className='value'>{data.item.emboss.label}</div>
                            </div>

                        </div>
                        <div className='match'>
                            <div>{getPriceFor('cover')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>corners</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>white</div>
                                <div className='value'>{data.item.corners_white}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>black</div>
                                <div className='value'>{data.item.corners_black}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>kraft</div>
                                <div className='value'>{data.item.corners_kraft}</div>
                            </div>
                        </div>
                        <div className='match'>
                            <div>{getPriceFor('corners')}</div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        )
    };

    formatGuestbookSlots = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';
        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);

            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bloc':
                    if(!productPrices.bloc){
                        return `No price set for bloc`
                    }
                    return `${productPrices.supplierName} ${productPrices.bloc.currency} ${productPrices.bloc.total}`
                case 'cover':
                    if(!productPrices.cover){
                        return `No price set for cover`
                    }
                    return `${productPrices.supplierName} ${productPrices.cover.currency} ${productPrices.cover.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                    size: {data.item.size.value} | pages: {data.item.nr_pages} |
                    material: {data.item.material.value} | style: {data.item.cover_style.value} |
                    uv: {data.item.cover_uv} |
                    emboss: {data.item.emboss.label}</div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }
                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>bloc</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>nr_pages</div>
                                <div className='value'>{data.item.nr_pages}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>page_type</div>
                                <div className='value'>{data.item.page_type.label}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bloc')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>cover</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>material</div>
                                <div className='value'>{data.item.material.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_uv</div>
                                <div className='value'>{data.item.cover_uv}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_emboss</div>
                                <div className='value'>{data.item.emboss.label}</div>
                            </div>

                        </div>
                        <div className='match'>
                            <div>{getPriceFor('cover')}</div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        )
    };

    formatVowBook = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';
        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);

            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bloc':
                    if(!productPrices.bloc){
                        return `No price set for bloc`
                    }
                    return `${productPrices.supplierName} ${productPrices.bloc.currency} ${productPrices.bloc.total}`
                case 'cover':
                    if(!productPrices.cover){
                        return `No price set for cover`
                    }
                    return `${productPrices.supplierName} ${productPrices.cover.currency} ${productPrices.cover.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                    size: {data.item.size.value} | pages: {data.item.nr_pages} |
                    material: {data.item.material.value} | style: {data.item.cover_style.value} |
                    uv: {data.item.cover_uv} |
                    emboss: {data.item.emboss.label}</div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }
                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>bloc</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>nr_pages</div>
                                <div className='value'>{data.item.nr_pages}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>page_type</div>
                                <div className='value'>{data.item.page_type.label}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bloc')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>cover</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>material</div>
                                <div className='value'>{data.item.material.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_uv</div>
                                <div className='value'>{data.item.cover_uv}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_emboss</div>
                                <div className='value'>{data.item.emboss.label}</div>
                            </div>

                        </div>
                        <div className='match'>
                            <div>{getPriceFor('cover')}</div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        )
    };

    formatClassicAlbum = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bloc':
                    if(!productPrices.bloc){
                        return `No price set for bloc`
                    }
                    return `${productPrices.supplierName} ${productPrices.bloc.currency} ${productPrices.bloc.total}`
                case 'cover':
                    if(!productPrices.cover){
                        return `No price set for cover`
                    }
                    return `${productPrices.supplierName} ${productPrices.cover.currency} ${productPrices.cover.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                    size: {data.item.size.value} | pages: {data.item.nr_pages} |
                    material: {data.item.material.value} | style: {data.item.cover_style.value} |
                    uv: {data.item.cover_uv} |
                    emboss: {data.item.emboss.label}</div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }
                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>bloc</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>nr_pages</div>
                                <div className='value'>{data.item.nr_pages}</div>
                            </div>

                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bloc')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>cover</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>material</div>
                                <div className='value'>{data.item.material.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_uv</div>
                                <div className='value'>{data.item.cover_uv}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_emboss</div>
                                <div className='value'>{data.item.emboss.label}</div>
                            </div>

                        </div>
                        <div className='match'>
                            <div>{getPriceFor('cover')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatBudoir = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bloc':
                    if(!productPrices.bloc){
                        return `No price set for bloc`
                    }
                    return `${productPrices.supplierName} ${productPrices.bloc.currency} ${productPrices.bloc.total}`
                case 'cover':
                    if(!productPrices.cover){
                        return `No price set for cover`
                    }
                    return `${productPrices.supplierName} ${productPrices.cover.currency} ${productPrices.cover.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                    size: {data.item.size.value} | pages: {data.item.nr_pages} |
                    material: {data.item.material.value} | style: {data.item.cover_style.value} |
                    uv: {data.item.cover_uv} |
                    emboss: {data.item.emboss.label}</div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }
                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>bloc</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>nr_pages</div>
                                <div className='value'>{data.item.nr_pages}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bloc')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>cover</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>material</div>
                                <div className='value'>{data.item.material.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_uv</div>
                                <div className='value'>{data.item.cover_uv}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_emboss</div>
                                <div className='value'>{data.item.emboss.label}</div>
                            </div>

                        </div>
                        <div className='match'>
                            <div>{getPriceFor('cover')}</div>
                        </div>
                    </div>
                    }


                </div>

            </div>
        )
    };

    formatAlbum = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bloc':
                    if(!productPrices.bloc){
                        return `No price set for bloc`
                    }
                    return `${productPrices.supplierName} ${productPrices.bloc.currency} ${productPrices.bloc.total}`
                case 'cover':
                    if(!productPrices.cover){
                        return `No price set for cover`
                    }
                    return `${productPrices.supplierName} ${productPrices.cover.currency} ${productPrices.cover.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                    size: {data.item.size.value} | pages: {data.item.nr_pages} |
                    material: {data.item.material.value} | style: {data.item.cover_style.value} |
                    uv: {data.item.cover_uv} |
                    emboss: {data.item.emboss.label}</div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }
                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>bloc</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>nr_pages</div>
                                <div className='value'>{data.item.nr_pages}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bloc')}</div>
                        </div>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>cover</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>material</div>
                                <div className='value'>{data.item.material.value}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_uv</div>
                                <div className='value'>{data.item.cover_uv}</div>
                            </div>
                            <div className='value'>
                                <div className='label'>cover_emboss</div>
                                <div className='value'>{data.item.emboss.label}</div>
                            </div>

                        </div>
                        <div className='match'>
                            <div>{getPriceFor('cover')}</div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        )
    };

    formatBambooBox = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'bamboo-box':
                    if(!productPrices.bambooBox){
                        return `No price set for bambooBox`
                    }
                    return `${productPrices.supplierName} ${productPrices.bambooBox.currency} ${productPrices.bambooBox.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | size: {data.item.size.value} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>box</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('bamboo-box')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatPouch = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'pouch':
                    if(!productPrices.pouch){
                        return `No price set for pouch`
                    }
                    return `${productPrices.supplierName} ${productPrices.pouch.currency} ${productPrices.pouch.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | size: {data.item.size.value} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>box</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('pouch')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatPen = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'pen':
                    if(!productPrices.pen){
                        return `No price set for pouch`
                    }
                    return `${productPrices.supplierName} ${productPrices.pen.currency} ${productPrices.pen.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | color: {data.item.color} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>box</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('pouch')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatMarker = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'marker':
                    if(!productPrices.pen){
                        return `No price set for pouch`
                    }
                    return `${productPrices.supplierName} ${productPrices.pen.currency} ${productPrices.pen.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | color: {data.item.code} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>marker</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>code</div>
                                <div className='value'>{data.item.code}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('marker')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatPenThree = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'pen':
                    if(!productPrices.pen){
                        return `No price set for pouch`
                    }
                    return `${productPrices.supplierName} ${productPrices.pen.currency} ${productPrices.pen.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | color: once of each </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>box</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('pouch')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatAlbumSleeve = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'album-sleeve':
                    if(!productPrices.albumSleeve){
                        return `No price set for Album SLeeve`
                    }
                    return `${productPrices.supplierName} ${productPrices.albumSleeve.currency} ${productPrices.albumSleeve.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | size: {data.item.size.value} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>box</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('pouch')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatPremiumBox = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'premium-box':
                    if(!productPrices.premiumBox){
                        return `No price set for Premium Box`
                    }
                    return `${productPrices.supplierName} ${productPrices.premiumBox.currency} ${productPrices.premiumBox.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | size: {data.item.size.value} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>box</div>
                        <div className='values'>
                            <div className='value'>
                                <div className='label'>size</div>
                                <div className='value'>{data.item.size.value}</div>
                            </div>
                        </div>

                        <div className='match'>
                            <div>{getPriceFor('pouch')}</div>
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    formatAddon = (data) => {
        let productionColor = data.suppliers_id ? '#40ba2142' : '#ba212142';

        let suppliersDropdownOptions = data.pricingOptions.map(item=>{
            return {
                id: item.supplierId,
                value: item.supplierId,
                text: `${item.supplierName} ${item.currency} ${item.total}`
            }
        });

        let selectedSupplier = null;
        if(data.suppliers_id){
            selectedSupplier = suppliersDropdownOptions.find(item=>item.id === data.suppliers_id);
            if(selectedSupplier){
                selectedSupplier = selectedSupplier.id
            }
        }

        let supplierDate = this.state.suppliers.find(item=>item.id === data.suppliers_id);
        if(supplierDate){
            supplierDate = supplierDate.priceList;
        } else {
            supplierDate = ''
        }

        const getPriceFor = (type) => {
            let productPrices = data.pricingOptions.find(item=>item.supplierId === data.suppliers_id);
            if(!productPrices){
                return 'No supplier set';
            }

            switch(type){
                case 'pouch':
                    if(!productPrices.pouch){
                        return `No price set for pouch`
                    }
                    return `${productPrices.supplierName} ${productPrices.pouch.currency} ${productPrices.pouch.total}`
            }
        };

        return (
            <div className='item' key={data.id}>
                <div className='title'>[{data.id}] {data.quantity} x {data.product_type} | {data.invoiceName} </div>
                <div className='content'>
                    <div className='part' style={{background: productionColor}}>
                        <div className='title'>production</div>
                        <div style={{margin:'5px 0', color:'#a2a2a2'}}>PL {supplierDate}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Dropdown
                                options={suppliersDropdownOptions}
                                selection compact
                                value={selectedSupplier}
                                style={{minWidth: '100px'}}
                                onChange={(e, item) => this.onItemSupplierChanged(data.id, item.value, item)}
                            />
                            <div className='delete-button' style={{marginLeft: '15px'}}
                                 onClick={() => this.onSupplierRemoveClicked(data.id)}>x
                            </div>
                        </div>
                    </div>
                    {this.state.showMore === false &&
                    <div className='part' style={{display: 'flex', alignItems: 'center'}}>
                        <OrderItemStatus displayOnly data={{progress: data.progress, item: data.item}}/>
                    </div>
                    }

                    {this.state.showMore &&
                    <div className='part'>
                        <div className='title'>addon</div>
                        <div className='values'>
                            <div className='value'>
                                {/*<div className='label'>size</div>*/}
                                {/*<div className='value'>{data.item.size.value}</div>*/}
                            </div>
                        </div>

                        <div className='match'>
                            {/*<div>{getPriceFor('addon')}</div>*/}
                        </div>
                    </div>
                    }

                </div>

            </div>
        )
    };

    getSuppliersData = (data) => {
        if (data.length === 0) {
            return [];
        }

        return data.map(supplier => {
            let totalCost = 0;

            totalCost += supplier.cover.total;
            totalCost += supplier.bloc.total;

            for (let cornerKey in supplier.corners) {
                totalCost += supplier.corners[cornerKey].total;
            }

            return {
                id: supplier.supplier.id,
                value: supplier.supplier.id,
                text: `${supplier.supplier.name} ${supplier.cover.currency} ${totalCost}`,
                meta: supplier
            }
        })
    };

    getSuppliersDataBambooBox = (data) => {
        if (data.length === 0) {
            return [];
        }

        return data.map(supplier => {
            let totalCost = 0;

            totalCost += supplier.bambooBox.total;


            return {
                id: supplier.supplier.id,
                value: supplier.supplier.id,
                text: `${supplier.supplier.name} ${supplier.bambooBox.currency} ${totalCost}`,
                meta: supplier
            }
        })
    };

    getSuppliersDataCorners = (data) => {
        if (data.length === 0) {
            return [];
        }

        return data.map(supplier => {
            let totalCost = 0;
            let currency = '';

            for (let cornerKey in supplier.corners) {
                currency = supplier.corners[cornerKey].currency;
                totalCost += supplier.corners[cornerKey].total;
            }

            return {
                id: supplier.supplier.id,
                value: supplier.supplier.id,
                text: `${supplier.supplier.name} ${currency} ${totalCost}`,
                meta: supplier
            }
        })
    };

    loadData = () => {
        ApiService.loadOrderItems(this.props.id).then(async data => {
            let items = data.data;

            if (items.length) {
                let order_date = items[0].order_date;

                for(let i=0; i<items.length; i++){
                    let pricing = await ApiService.getPricingOptionsFor(items[i].id);
                    items[i].pricingOptions = pricing.data;
                }

                ApiService.getSuppliersFull({order_date: order_date}).then(suppliers => {
                    this.setState({items: items, suppliers: suppliers.data});
                })
            }

        })
    };

    getMaterialById = (id) => {
        if (!this.props.configs || !this.props.configs.materials || this.props.configs.materials.length === 0) {
            return null;
        }

        for (let i = 0; i < this.props.configs.materials.length; i++) {
            if (this.props.configs.materials[i].id === id) {
                return this.props.configs.materials[i];
            }
        }
    };

    getStyleById = (id) => {
        if (!this.props.configs || !this.props.configs.styles || this.props.configs.styles.length === 0) {
            return null;
        }

        for (let i = 0; i < this.props.configs.styles.length; i++) {
            if (this.props.configs.styles[i].id === id) {
                return this.props.configs.styles[i];
            }
        }
    };

    onSupplierRemoveClicked = (id, supplierId, item) => {
        ApiService.setSupplierForOrderItem({
            orderItemId: id,
            supplierId: null,
            orderId: this.props.id
        }).then(data => {
            toast.success('Supplier reset', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.loadData();
            this.props.onRefresh();
        })
    };

    onItemSupplierChanged = (id, supplierId, item) => {
        let prices = item.options.find(i => i.id === item.value).meta;

        ApiService.setSupplierForOrderItem({
            orderItemId: id,
            supplierId: supplierId,
            orderId: this.props.id,
            prices: prices
        }).then(data => {
            toast.success('Supplier set', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.loadData();
            this.props.onRefresh();
        })

    }

}


export default OrderProduction;
