import React, {Component} from 'react';

import styles from './OrderItemPhotos.module.css';
import ApiService from "../../services/ApiService";
import {Button} from "semantic-ui-react";

class OrderItemPhotos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            configuredProject: null,
            downloading: false
        }
    }

    componentDidMount() {
        this.loadProject();
    }

    render() {
        return (
            <div className={styles.main}>
                <h4>Album photos</h4>
                {!this.state.configuredProject &&
                <div>loading data...</div>
                }
                {(this.state.configuredProject && this.state.configuredProject.err) &&
                <div className={styles.error}>Error: {this.state.configuredProject.err}</div>
                }
                {(this.state.configuredProject && !this.state.configuredProject.err) &&
                <div className={styles.container}>
                    <div>Background: <b>{this.state.configuredProject.photoBackground}</b></div>
                    {this.getDownloadPhotosElement()}
                </div>
                }
            </div>
        );
    }

    getDownloadPhotosElement = () => {
        if(this.state.configuredProject.uploadBatch && this.state.configuredProject.uploadBatchComplete === true){
            return (
                <div>
                    <div>User uploaded images</div>
                    <div>
                        {this.state.downloading === true &&
                        <Button primary disabled>Downloading...</Button>
                        }
                        {this.state.downloading === false &&
                        <Button primary onClick={this.onDownloadUserImagesClicked}>Download</Button>
                        }
                    </div>
                </div>
            )
        }
        return (
            <div>User has not yet uploaded the photos</div>
        )
    }

    onDownloadUserImagesClicked = () => {
        if(this.state.downloading){
            return null;
        }
        this.setState({downloading: true}, ()=>{
            ApiService.downloadImageBatch(this.state.configuredProject.uploadBatch, this.props.userId,this.props.name).then(data=>{
                this.setState({downloading: false})
            })
        })
    }

    loadProject = () => {
        ApiService.loadConfiguredProject(this.props.projectId, this.props.userId).then(data => {
            this.setState({
                configuredProject: data.data
            })
        })
    }
}


export default OrderItemPhotos;
