import React, {Component} from 'react';
import {Table} from "semantic-ui-react";

class CoverStockSmallItem extends Component {
    render() {
        const items = this.getItems();

        return items;
    }

    getItems = () => {
        return this.props.items.map(item=>{
            return (
                <Table.Row id={item.id}  onClick={()=>this.props.onItemClicked(item)}>
                    <Table.Cell>{item.sku}</Table.Cell>
                    <Table.Cell>{item.quantity}</Table.Cell>
                    <Table.Cell>{item.pick_place}</Table.Cell>
                    <Table.Cell>{item.meta.sizeName}</Table.Cell>
                </Table.Row>
            )
        })
    }
}

export default CoverStockSmallItem;
