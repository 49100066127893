import React, {Component} from 'react';

import './GalleryItem.scss';
import * as Reorder from 'react-reorder';
import {reorder} from 'react-reorder';
import {config} from "../config/config";
import {Button, Dimmer, Image, Loader} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class MigrateMaterialsToLive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isConfirmVisible: false,
            loading: false,
            label:''
        };
    }

    render() {
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <div className='toggle' style={{display:'flex', alignItems:'center', margin: '0 0 20px 0'}}>
                    {/*<Button onClick={() => this.setState({isConfirmVisible: true})}> Migrate to live</Button>*/}


                    {this.state.isConfirmVisible &&
                    <Button color='red' onClick={this.onMigrateClicked}> start migration</Button>
                    }

                    {this.state.label.length > 0 &&
                    <div style={{
                        background: '#8BC34A',
                        color: 'white',
                        padding: '5px',
                        fontWeight: 'bold'
                    }}>{this.state.label}</div>
                    }
                </div>
            </div>
        );
    }

    onMigrateClicked = () => {
        this.setState({loading: true, isConfirmVisible:false}, ()=>{
            ApiService.migrateMaterialsToLive(this.props.type).then(data=>{
                this.setState({loading: false, label: 'Migration complete'})
            })
        })
    }

}


export default MigrateMaterialsToLive;
