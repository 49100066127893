import React, {Component} from 'react';
import ApiService from "../../services/ApiService";
import './OrderFinance.scss';
import moment from "moment-timezone";
import {Button, Dimmer, Dropdown, Icon, Input, Loader, TextArea} from "semantic-ui-react";
import Utils from "../../data/Utils";
import InvoiceEditor from "../InvoiceEditor";

class OrderFinance extends Component {
    constructor(props) {
        super(props);

        this.financeTypes = [
            {
                id: 1,
                text: 'Collection',
                value: 'Collection',
                convertToBaseCurrency: false
            },
            {
                id: 2,
                text: 'Refund',
                value: 'Refund',
                convertToBaseCurrency: false
            },
            {
                id: 3,
                text: 'Production',
                value: 'Production',
                convertToBaseCurrency: true
            },
            {
                id: 4,
                text: 'Shipping',
                value: 'Shipping',
                convertToBaseCurrency: true
            }
        ];

        this.financeOrder = ['Collection', 'Refund', 'Production', 'Shipping'];

        this.state = {
            isAddMode: false,
            data: [],
            newFinance: {
                type: '',
                amount: '',
                currency: '',
                description: ''
            },
            attachInvoiceForId: 0,
            attachInvoiceSeries: '',

            editorVisible: false,
            fromFinanceItemId: null,
            fromInvoiceId: null,
            mouseOver: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const addElement = this.getAddElement();
        const financeElements = this.getFinanceElements();
        const totals = this.getTotalsElement();

        return (
            <div className='OrderFinance' onMouseEnter={() => this.setState({mouseOver: true})}
                 onMouseLeave={() => this.setState({mouseOver: false})}>
                {this.state.editorVisible &&

                <InvoiceEditor
                    invoiceId={this.state.fromInvoiceId}
                    financeId={this.state.fromFinanceItemId}
                    onSaved={this.onInvoiceSaved}
                    onClose={() => this.setState({editorVisible: false, editingInvoiceData: null})}
                />

                }

                <div className='head'>
                    <div className='text'>Finance</div>
                    {this.props.isSuborder === false &&
                    <div className='download' onClick={this.downloadAllInvoices}>Download invoices</div>
                    }
                    <div className='fill'></div>
                    {this.state.isAddMode === false &&
                    <div className='change' onClick={() => this.setState({isAddMode: true})}>add</div>
                    }
                </div>

                <div className='extra'>
                    <Dimmer active={this.state.loading}>
                        <Loader/>
                    </Dimmer>
                    {addElement}
                    {financeElements}
                    {totals}
                </div>
            </div>
        );
    }

    getFinanceElements = () => {
        if (!this.state.data || this.state.data.length === 0) {
            return null;
        }

        return this.state.data.map(item => {
            let signClassName = 'amount green';
            if (item.converted_amount < 0) {
                signClassName = 'amount red';
            }

            const dateRaw = moment(item.date);
            let date = dateRaw.tz('Europe/Bucharest').format('DD.MM.YYYY');

            let getInvoicesElement = () => {

                if (item.invoices.length === 0) {
                    return null;
                }

                return item.invoices.map(invoice => {
                    const isStornated = invoice.type === 'stornated';
                    const isStorno = invoice.type === 'storno';
                    const invoiceClassName = isStornated ? 'invoice stornated' : 'invoice';


                    let invoiceDate = Utils.stringToDateDisplay(invoice.date, '.');
                    let invoiceStyle = {};
                    if (invoice.autogenerated) {
                        invoiceStyle = {
                            background: '#ff9090',
                            padding: '10px'
                        }
                    }

                    return (
                        <div className={invoiceClassName} key={invoice.id} style={invoiceStyle}>
                            <div className='head'>
                                <div className="name">{isStorno?"STORNO ":""}{invoice.series}-{invoice.series_count}</div>
                                <Icon name='edit' style={{cursor: 'pointer'}} color='black'
                                      onClick={() => this.onInvoiceEditClicked(invoice, item.id)}/>
                                <Icon style={{cursor: 'pointer'}} name='download' color='green'
                                      onClick={() => this.onInvoiceDownloadClicked(invoice.id)}/>
                                <Icon style={{cursor: 'pointer'}} name='remove' color='red'
                                      onClick={() => this.onInvoiceRemoveFromOrder(invoice)}/>
                            </div>
                            <div className='details'>
                                <div className='info'>{invoice.data.clientName}</div>
                                <div className='info'>{invoiceDate}</div>
                                <div className='info'>{invoice.data.currency} {invoice.data.total}</div>
                                <div className='info'>payment: {invoice.payment_provider}</div>
                            </div>
                        </div>
                    )
                })
            };

            let getTaxElement = () => {
                if (item.type === 'Collection' || item.type === 'Refund') {
                    return (
                        <div className='taxes'>
                            <div className='tax-item gray'>
                                <div className='name'>Value</div>
                                <div className='value'>RON {parseFloat(item.converted_amount).toFixed(2)}</div>
                            </div>

                            <div className='tax-item'>
                                <div className='name'>VAT</div>
                                <div className='value'>RON {parseFloat(item.vat_fee).toFixed(2)}</div>
                            </div>

                            <div className='tax-item'>
                                <div className='name'>Payment fee</div>
                                <div className='value'>RON {parseFloat(item.payment_fee).toFixed(2)}</div>
                            </div>

                            <div className='tax-item'>
                                <div className='name'>Platform fee</div>
                                <div className='value'>RON {parseFloat(item.platform_fee).toFixed(2)}</div>
                            </div>

                            <div className='tax-item'>
                                <div className='name'>Income tax</div>
                                <div className='value'>RON {parseFloat(item.income_fee).toFixed(2)}</div>
                            </div>
                        </div>
                    )
                }

                if (item.type === 'Shipping') {
                    return (
                        <div className='taxes'>
                            <div className='tax-item'>
                                <div className='name'>VAT</div>
                                <div className='value'>RON {parseFloat(item.vat_fee).toFixed(2)}</div>
                            </div>

                        </div>
                    )
                }

                return null
            };

            let getAddInvoiceElement = () => {
                if (item.type !== 'Collection' && item.type !== 'Refund') {
                    return null;
                }

                return (<div className='invoice'>
                    {this.state.mouseOver &&
                    <div className='head'>
                        <div className='create' onClick={() => this.onCreateInvoiceClicked(item)}>create
                            invoice
                        </div>
                        <div className='attach'
                             onClick={() => this.setState({attachInvoiceForId: item.id})}>attach invoice
                        </div>
                    </div>
                    }
                    {this.state.mouseOver === false &&
                    <div className='head'>
                        <div className='create'>&nbsp;</div>
                        <div className='attach'>&nbsp;</div>
                    </div>
                    }
                    {this.state.attachInvoiceForId === item.id &&
                    <div style={{marginTop: '10px'}}>
                        <Input size='mini' label='Invoice' placeholder='EXT-XXX'
                               value={this.state.attachInvoiceSeries}
                               onChange={(e, {value}) => this.setState({attachInvoiceSeries: value})}/>
                        <div style={{marginTop: '10px'}}>
                            <Button primary compact
                                    onClick={() => this.onAttachInvoiceClicked(item)}>Save</Button>
                            <Button secondary compact
                                    onClick={() => this.setState({attachInvoiceForId: 0})}>Cancel</Button>
                        </div>
                    </div>
                    }
                </div>)
            };

            let invoiceElements = getInvoicesElement();
            let addInvoiceElement = getAddInvoiceElement();
            let taxElement = getTaxElement();

            return (
                <div className='finance-item' key={item.id}>
                    <div className='head'>
                        <div
                            className={signClassName}>{item.currency} {item.amount}</div>
                        <div>{date}</div>
                        <div className='type'>{item.type}</div>
                        <div className='fill'></div>
                        <div className='delete' onClick={() => this.onDeleteFinanceItemClicked(item.id)}>x</div>
                    </div>
                    <div className='info'>{item.description}</div>
                    {taxElement}
                    <div className='invoices'>
                        {invoiceElements}
                        {addInvoiceElement}
                    </div>
                </div>
            )
        })
    };

    getTotalsElement = () => {
        let profit = 0;
        let expense = 0;
        let income = 0;
        let margin = 0;

        for (let i = 0; i < this.props.orders.length; i++) {
            let stats = this.props.orders[i].stats;
            if(!stats){
                stats = {expense: 0, income:0}
            }
            expense += parseFloat(stats.expense);
            income += parseFloat(stats.income);
        }

        profit = (income + expense).toFixed(2);
        margin = ((profit / income) * 100).toFixed(2);

        profit = parseFloat(profit).toFixed(2);
        expense = parseFloat(expense).toFixed(2);
        income = parseFloat(income).toFixed(2);
        margin = parseFloat(margin).toFixed(2);

        let profitClassName = 'amount green';
        if (profit < 0) {
            profitClassName = 'amount red';
        }

        return (
            <div className='finance-item' style={{paddingBottom: '14px'}}>
                <div className='head'>
                    <div className='amount green' style={{flex: 1}}>RON {income}</div>
                    <div className={profitClassName} style={{flex: 1}}>RON {profit}</div>
                </div>
                <div className='head'>
                    <div className='amount red' style={{flex: 1}}>RON {expense}</div>
                    <div className='amount blue' style={{flex: 1}}>{margin}%</div>
                </div>
            </div>
        )
    };

    getAddElement = () => {
        if (!this.state.isAddMode) {
            return null;
        }

        let typeValue = this.state.newFinance.type.length > 0 ? this.state.newFinance.type : '-Select type-';
        let canSave = true;

        if (this.state.newFinance.type.length === 0) {
            canSave = false;
        }

        if (this.state.newFinance.currency.length !== 3) {
            canSave = false;
        }

        if (this.state.newFinance.amount.length === 0) {
            canSave = false;
        }

        return (
            <div className='add-finance'>
                <div>
                    <Dropdown
                        options={[
                            {
                                id: 0,
                                text: '-Select type-',
                                value: '-Select type-',
                                disabled: true
                            }, ...this.financeTypes
                        ]}
                        selection compact
                        value={typeValue}
                        style={{minWidth: '180px'}}
                        onChange={(e, {name, value}) => this.onItemFieldChanged('type', value)}
                    />
                </div>

                {this.getAddFinanceItemElement()}

                <div className='buttons'>
                    <Button disabled={!canSave} primary compact onClick={this.onSaveClicked}>Save</Button>
                    <Button secondary compact onClick={this.onCancelClicked}>Cancel</Button>
                </div>
            </div>
        )
    };

    getAddFinanceItemElement = () => {
        if (!this.state.newFinance.type) {
            return null;
        }

        switch (this.state.newFinance.type) {
            case 'Collection':
            case 'Refund':
                return this.getAddCollectionElement();
            default:
                return this.getAddGenericElement();
        }
    };

    getAddCollectionElement = () => {
        return (
            <div className='content'>
                <div className='amount'>
                    <Input style={{minWidth: '100px'}} fluid placeholder='USD/RON'
                           value={this.state.newFinance.currency}
                           onChange={(e, {value}) => this.onItemFieldChanged('currency', value)}/>
                    <Input style={{minWidth: '130px'}} fluid placeholder='123/33.12'
                           value={this.state.newFinance.amount}
                           onChange={(e, {value}) => this.onItemFieldChanged('amount', value)}/>
                </div>
                {/*<div className='checkbox-option'>*/}
                {/*    <Checkbox label='Generate invoice'*/}
                {/*              onChange={(e, {checked})=>this.onItemFieldChanged('generateInvoice', checked)}*/}
                {/*              checked={this.state.newFinance.generateInvoice}/>*/}
                {/*</div>*/}
                <div>
                    <TextArea style={{width: '100%'}} placeholder='description' rows={3}
                              value={this.state.newFinance.description}
                              onChange={(e, {value}) => this.onItemFieldChanged('description', value)}/>
                </div>

            </div>
        )
    };

    getAddGenericElement = () => {
        return (
            <div className='content'>
                <div className='amount'>
                    <Input style={{minWidth: '100px'}} fluid placeholder='USD/RON'
                           value={this.state.newFinance.currency}
                           onChange={(e, {value}) => this.onItemFieldChanged('currency', value)}/>
                    <Input style={{minWidth: '130px'}} fluid placeholder='123/33.12'
                           value={this.state.newFinance.amount}
                           onChange={(e, {value}) => this.onItemFieldChanged('amount', value)}/>
                </div>
                <div>
                    <TextArea style={{width: '100%'}} placeholder='description' rows={3}
                              value={this.state.newFinance.description}
                              onChange={(e, {value}) => this.onItemFieldChanged('description', value)}/>
                </div>

            </div>
        )
    };

    onItemFieldChanged = (field, value) => {
        let item = {...this.state.newFinance};
        if (field.indexOf('.') > 0) {
            let parts = field.split('.');
            item[parts[0]][parts[1]] = value;
        } else {
            item[field] = value;
        }

        if (value === 'Collection' || value === 'Refund') {
            item.generateInvoice = true;
        }


        this.setState({newFinance: item});
    };

    onSaveClicked = () => {
        let item = {...this.state.newFinance};
        item.orders_id = this.props.mainOrder.id;

        this.setState({
            loading: true,
            newFinance: {
                type: '',
                amount: '',
                currency: '',
                description: ''
            },
            isAddMode: false
        }, () => {
            ApiService.orderFinanceAdd(item).then(data => {
                this.loadData();
            });
        })
    };

    onCancelClicked = () => {
        this.setState({
            newFinance: {
                type: '',
                amount: '',
                currency: '',
                description: ''
            },
            isAddMode: false
        })
    };

    onInvoiceDownloadClicked = (id) => {
        ApiService.downloadInvoiceById(id);
    };

    onAttachInvoiceClicked = (finance) => {
        let data = {
            finances_id: finance.id,
            invoice: this.state.attachInvoiceSeries,
            orders_id: finance.orders_id
        };

        this.setState({
            loading: true,
            attachInvoiceSeries: '',
            attachInvoiceForId: 0

        }, () => {
            ApiService.orderFinanceAttachInvoice(data).then(data => {
                this.props.onRefresh();
                this.loadData();
            });
        });
    };

    onInvoiceRemoveFromOrder = (invoice) => {
        this.setState({loading: true}, () => {
            ApiService.financeRemoveInvoiceFromOrder({
                orders_id: invoice.orders_id,
                invoice_id: invoice.id,
            }).then(data => {
                this.props.onRefresh();
                this.loadData();
            })
        })

    };

    onDeleteFinanceItemClicked = (id) => {
        this.setState({loading: true}, () => {
            ApiService.financeDeleteItem(id).then(data => {
                this.loadData();
                this.props.onRefresh();
            })
        })
    };

    onInvoiceSaved = () => {
        this.setState({
            loading: true,
            editorVisible: false,
            editingInvoiceData: null,
        }, () => {
            this.props.onRefresh();
            this.loadData();
        });
    };

    onInvoiceEditClicked = (data, financeId) => {
        this.setState({
            editorVisible: true,
            fromInvoiceId: data.id
        });
    };

    onCreateInvoiceClicked = (financeItem) => {
        this.setState({
            editorVisible: true,
            fromFinanceItemId: financeItem.id,
            fromInvoiceId: null
        })
    };

    downloadAllInvoices = () => {
        ApiService.getAllInvoicesForOrder(this.props.mainOrder.id).then(async data => {
            let invoiceIDS = data.data;
            for (let i = 0; i < invoiceIDS.length; i++) {
                await ApiService.downloadInvoiceById(invoiceIDS[i]);
            }
        });
    };

    loadData = async () => {
        let orderFinance = [];

        if (this.props.orders?.length) {
            for (let i = 0; i < this.props.orders.length; i++) {
                let suborderId = this.props.orders[i].id;

                let subOrderFinance = await ApiService.orderFinanceGet(suborderId);
                subOrderFinance = subOrderFinance.data;
                orderFinance = [...orderFinance, ...subOrderFinance];
            }
        }

        orderFinance.sort((a, b) => {
            return this.financeOrder.indexOf(a.type) - this.financeOrder.indexOf(b.type)
        });

        this.setState({loading: false, data: orderFinance});
    }

}


export default OrderFinance;
