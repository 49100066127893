import React, {Component} from 'react';
import {Button, Container, Dimmer, Dropdown, Image, Input, Item, Loader, Table} from "semantic-ui-react";

import './GalleryItem.scss';
import {config} from "../config/config";
import ApiService from "../services/ApiService";

class ImageUpload extends Component {
    constructor(props) {
        super(props);

        this.imageRef = React.createRef();

        this.state = {
            loading: false,
            imagePath: ''
        };
    }


    render() {

        return (
            <div style={{margin: '0 10px'}}>

                {this.state.loading === true &&
                    <div>Uploading image...</div>
                }

                {this.state.loading === false &&
                <h5>{this.props.label}<br/> {this.props.sizeLabel} </h5>
                }

                {this.state.imagePath &&
                <Image src={`${config.ASSETS_PATH}/products/${this.props.path}/${this.state.imagePath}`} size='small'/>
                }

                {this.state.loading === false &&
                <div>
                    <Button
                        basic
                        content="select image"
                        labelPosition="left"
                        icon="image"
                        onClick={() => this.imageRef.current.click()}
                    />
                    <input
                        ref={this.imageRef}
                        type="file"
                        hidden
                        onChange={(e) => this.onImageSelected(e)}
                    />
                </div>
                }
            </div>
        );
    }

    onImageSelected = (e) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        this.setState({loading: true}, () => {
            ApiService.uploadImage(this.props.path, data).then(r => {
                this.setState({
                    loading: false,
                    imagePath: r.data.image
                }, () => {
                    this.props.onImageUploaded(r.data.image);
                })
            });
        })
    };


}


export default ImageUpload;
