import React, {Component} from 'react';
import './ItemWeights.scss';
import ItemWeight from "./ItemWeight";

class ItemWeights extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        if (!this.props.items || !this.props.weights) {
            return null;
        }

        let items = this.getItems();

        if(items.length === 0){
            return null;
        }

        return (
            <div className='ItemWeights'>
                <h3>Ce greutate are produsul? Se cantareste o bucata - 1 guestbook, 1 sheet colturi, 1 pix, etc</h3>
                <div className="items">
                    {items}
                </div>
            </div>
        );
    }

    getItems = () => {
        let data = [];
        this.props.items.forEach(item=>{
            let lookup = this.props.weights.find(w=>w.key === item.sizeKey);
            if(!lookup){
                data.push(item);
            }
        })

        return data.map(item =>{
            return (
                <ItemWeight key={item.sizeKey} name={item.name} itemKey={item.sizeKey}/>
            )
        })
    }

}


export default ItemWeights;
