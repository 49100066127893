import React, {Component} from 'react';
import '../../screens/QuickFillScreen.scss';
import {Dropdown, Input} from "semantic-ui-react";


class FilledItemCorners extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="FilledItem">
                <h2 className="FilledItemTitle">Corners x {this.props.data.quantity}</h2>
                <div className="FilledItemBody">
                    <div className="row">
                    <div className="selectionItem">
                        <div className="title">Amount</div>
                        <div className="items">
                            <div className="item">{this.props.data.autofill.option_sheets} sheets</div>
                        </div>
                    </div>

                    <div className="selectionItem">
                        <div className="title">Color</div>
                        <div className="items">
                            <div className="item">{this.props.data.autofill.option_color}</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }

}


export default FilledItemCorners;
