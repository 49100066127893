import React, {Component} from 'react';
import ApiService from "../../services/ApiService";
import {Button, Icon, Table} from "semantic-ui-react";
import StocStatisticsRequiredVsAvailable from "./statistics/StocStatisticsRequiredVsAvailable";
import {Label} from "recharts";
import {format} from "date-fns";

class MaterialsAvailabilityStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            render: [],
            sizes: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.getData();
    }

    render() {
        return (
            <div style={{position: 'absolute', left: 20, right: 20}}>
                <Button basic icon labelPosition='left' style={{marginLeft: '5px', height: '42px'}}
                        onClick={this.toTSVClicked}>
                    <Icon name='download' />
                    tsv</Button>
                <Table celled>
                    <Table.Header style={{position: 'sticky', top: '0px'}}>
                        <Table.Row>
                            <Table.HeaderCell>Material</Table.HeaderCell>
                            <Table.HeaderCell>Ordered last 12 mo</Table.HeaderCell>
                            <Table.HeaderCell>Must manufacture</Table.HeaderCell>
                            <Table.HeaderCell>Can manufacture</Table.HeaderCell>
                            <Table.HeaderCell>Covers:</Table.HeaderCell>
                            {this.state.sizes.map(size => {
                                return (
                                    <Table.HeaderCell>{size.cm}</Table.HeaderCell>
                                )
                            })}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.state.render.map(item => {
                            return (
                                <Table.Row>
                                    <Table.Cell negative={item.manufactureNeeds > item.currentCanManufacture}>
                                        {item.name}</Table.Cell>
                                    <Table.Cell>{item.ordered}</Table.Cell>
                                    <Table.Cell>{item.manufactureNeeds}</Table.Cell>
                                    <Table.Cell>{item.currentCanManufacture}</Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    {this.state.sizes.map(size => {
                                        return (
                                            <Table.Cell>
                                                {item.sizes[size.cm].coversInInventory}
                                            </Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            )
                        })}

                    </Table.Body>
                </Table>
            </div>
        )
    }

    toTSVClicked = () => {
        const now = format(new Date(), 'dd-MM-YYY');
        const filename = `materials-status-${now}.tsv`;

        let columns = [
            {
                source: 'name',
                name: 'Material'
            },
            {
                source: 'ordered',
                name: 'Ordered last 12 months',
            },
            {
                source: 'manufactureNeeds',
                name: 'Must manufacture'
            },
            {
                source: 'currentCanManufacture',
                name: 'Can manufacture'
            },
            {
                source: 'none',
                name: 'Covers:'
            },
        ]

        this.state.sizes.forEach(size => {
            columns.push({
                source: `sizes.${size.cm}.coversInInventory`,
                name: size.cm
            })
        })

        ApiService.dataToTSV({columns: columns, filename:filename, data:this.state.render})
    }

    getData = async () => {
        let materials = await ApiService.getMaterials('guestbook');
        let sizes = await ApiService.getSizes('guestbook');
        let materialSizes = await ApiService.getSizes('raw-material');
        let orders = await ApiService.getCompletedGuestbookOrdersInLastYear()

        orders = orders.data.map(i=>{
            return {
                quantity: i.quantity,
                meta: JSON.parse(i.item_meta)
            }
        });

        sizes = sizes.data.sizes.filter(i => i.available === 1);
        materials = materials.data.materials;
        materialSizes = materialSizes.data.sizes;

        materialSizes.sort((a, b) => {
            if (a.cm < b.cm) {
                return 1
            }
            if (a.cm > b.cm) {
                return -1
            }
            return 0;
        })

        const largestMaterialSize = materialSizes[0];

        const inventoryCovers = this.props.items.filter(i => i.type === 'cover-r');
        const inventoryMaterials = this.props.items.filter(i => i.type === 'material');

        let render = [];

        materials.forEach(material => {
            let currentCanManufacture = inventoryMaterials.find(c => c.meta.materialId === material.id && c.meta.sizeId === largestMaterialSize.id);
            currentCanManufacture = currentCanManufacture?.quantity || 0;
            let ordered = orders.filter(o=>o.meta.material.id === material.id);
            let orderedPieces =  ordered.reduce((accumulator, object) => {
                return accumulator + object.quantity;
            }, 0);

            let mat = {
                id: material.id,
                name: material.name,
                manufactureNeeds: 0,
                currentCanManufacture: currentCanManufacture,
                sizes: {},
                ordered:orderedPieces
            }


            sizes.forEach(size => {
                let coversInInventory = inventoryCovers.filter(c => c.meta.materialId === material.id && c.meta.sizeId === size.id);

                if (coversInInventory) {
                    coversInInventory = coversInInventory.reduce((accumulator, object) => {
                        return accumulator + object.quantity;
                    }, 0);
                }

                if (!coversInInventory) {
                    coversInInventory = 0
                }

                if (coversInInventory < 5) {
                    mat.manufactureNeeds += (5 - coversInInventory);
                }

                mat.sizes[size.cm] = {
                    coversInInventory: coversInInventory || 0
                }
            })

            render.push(mat)

        })

        this.setState({render, sizes});
    }

}

export default MaterialsAvailabilityStatistics;
