import React, {Component} from 'react';
import ApiService from "../../services/ApiService";
import './OrderLink.scss';
import moment from "moment-timezone";
import OrderStatusToggler from "./OrderStatusToggler";
import {Button, Checkbox, Input} from "semantic-ui-react";
import {toast} from "react-toastify";
import {Extract, Generate} from "../utils/checksum";

class OrderLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            showExtraParent: false,
            parentExtraValue: '',
            suborders: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        let ordersExpanded = this.getUserOrdersExpanded();

        return (
            <div className='OrderLink'>
                <div className='head' style={{alignItems: 'baseline'}}>
                    <div className='text'>Suborders</div>
                    <div className='fill'></div>
                    <div className='text' onClick={() => this.setState({showExtraParent: true})}
                         style={{padding: '0 5px', border: "1px solid black", cursor: 'pointer'}}>+
                    </div>
                </div>

                <div className='extra'>
                    {this.state.showExtraParent &&
                    <div style={{display: "flex", marginBottom: "15px"}}>
                        <Input value={this.state.parentExtraValue} onChange={this.onParentExtraChange}
                               placeholder={"order id"}/>
                        <Button onClick={this.parentExtra} size={"small"} primary>Add</Button>
                    </div>
                    }
                    <div>{ordersExpanded}</div>
                </div>
            </div>
        );
    }


    getUserOrdersExpanded = () => {
        if (!this.state.user.orders) {
            return null;
        }

        let itemsToRender = this.state.user.orders;

        const existsInRenderArray = (orderId)=>{
            let result = false;
            itemsToRender.forEach(item=>{
                if(item.id === orderId){
                    result = true;
                }
            })

            return result;
        }

        this.state.suborders.forEach(order=>{
            if(!existsInRenderArray(order.id)){
                itemsToRender.push(order);
            }
        })


        return this.state.user.orders.map(item => {
            const createdTimeRaw = moment(item.created);
            let createdTime = createdTimeRaw.tz('Europe/Bucharest').format('DD.MM.YYYY');

            let belongsToAnother = (item.suborder_of && item.suborder_of !== this.props.from);

            if (this.props.from) {
                if (this.props.from === item.id || item.remake_of) {
                    return null;
                } else return (
                    <div className='expanded-order'>
                        {!belongsToAnother &&
                        <Checkbox
                            className='checkbox-suborder'
                            name='checkboxRadioGroup'
                            value='this'
                            checked={item.suborder_of === this.props.from}
                            onChange={(e, {checked}) => this.handleSuborderChange(item.id, checked)}
                        />
                        }
                        {belongsToAnother &&
                        <a className='' href={`/order/${Generate(item.suborder_of)}`}
                           target='_blank'>{`${Generate(item.suborder_of)} <`}</a>
                        }
                        <a className='id' href={`/order/${Generate(item.id)}`} target='_blank'>{Generate(item.id)}</a>
                        <div className='date'>{createdTime}</div>
                        <div><OrderStatusToggler displayOnly status={item.status}/></div>
                    </div>
                )
            }
            return (
                <div className='expanded-order'>
                    <a className='id' href={`/order/${Generate(item.id)}`} target='_blank'>{Generate(item.id)}</a>
                    <div className='date'>{createdTime}</div>
                    <div><OrderStatusToggler displayOnly status={item.status}/></div>
                </div>
            )
        })

    };

    loadData = async () => {
        const user = await ApiService.getUserById(this.props.id);
        const suborders = await ApiService.getSubordersForOrder(this.props.from);

        this.setState({user: user.data, suborders: suborders.data});
    };

    onParentExtraChange = (e, {name, value}) => this.setState({parentExtraValue: value});

    parentExtra = () => {
        let orderToParent = null;

        if (this.state.parentExtraValue.length) {
            try {
                orderToParent = Extract(this.state.parentExtraValue)
            } catch (e) {

            }
        }

        if(!isNaN(orderToParent)){
           this.handleSuborderChange(orderToParent, true);
        }
    }

    handleSuborderChange = (id, linked) => {
        let orderId = this.props.from;
        let linkedOrderId = id;

        ApiService.linkOrders({orderId, linkedOrderId, linked}).then(data => {
            toast.success('Order linking updated', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            window.location.reload();
        })
    }

}


export default OrderLink;
