import React, {Component} from 'react';
import './PendingOrder.scss';
import {Button} from "semantic-ui-react";

class PendingOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isManualApproveVisible: false
        }
    }

    render() {
        const order = this.props.order;

        let total = 0;

        order.info_meta.forEach(item => {
            total += parseFloat(item.total);
        })

        let className = 'status';

        const orderLines = this.getOrderLinesElements(order);

        if (order.status === 'migrated') {
            className += ' green';
        }

        return (
            <div className='PendingOrder'>
                <div className='header'>
                    <div className='name'>
                        <div className='date'>{order.date.split('T')[0]}</div>
                        <div
                            className='client'>{`${order.shipping_meta.firstName} ${order.shipping_meta.lastName}`}</div>
                        <div className='cost'>
                            <div className='currency'>{order.currency}</div>
                            <div className='amount'>{total.toFixed(2)}</div>
                        </div>
                        <div className={className}>{order.status}</div>
                        <br/>
                        {order.status !== 'migrated' &&
                        <Button size={'tiny'} primary onClick={()=>this.setState({isManualApproveVisible: true})}>Manual approve</Button>
                        }

                        {this.state.isManualApproveVisible === true &&
                            <Button size={'tiny'} color='red' onClick={this.onManualApproveClicked}>Are you sure?</Button>
                        }
                    </div>
                </div>
                <div className='details'>
                    {orderLines}
                </div>
            </div>
        );
    }

    onManualApproveClicked = () => {
        this.props.onManualApproveClicked(this.props.order.id);
    }

    getOrderLinesElements = (order) => {
        return order.info_meta.map(item => {
            return (
                <div className='line'>{`${item.amount}x ${item.name}`}</div>
            )
        })
    }

}


export default PendingOrder;
