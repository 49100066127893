export const SCREEN_ACCOUNT_INFORMATION_MAIN = '/account/information/you';
export const SCREEN_ACCOUNT_INFORMATION_CHANGE_PASSWORD = '/account/information/change-password';
export const SCREEN_ACCOUNT_INFORMATION_CHANGE_EMAIL = '/account/information/change-email';
export const SCREEN_ACCOUNT_INFORMATION_PREFERENCES = '/account/information/preferences';

export const SCREEN_ACCOUNT_SHIPPING_MAIN = '/account/shipping/main';
export const SCREEN_ACCOUNT_SHIPPING_ADD = '/account/shipping/add';
export const SCREEN_ACCOUNT_SHIPPING_EDIT = '/account/shipping/edit';

export const SCREEN_ACCOUNT_BILLING_MAIN = '/account/billing/main';
export const SCREEN_ACCOUNT_BILLING_ADD = '/account/billing/add';
export const SCREEN_ACCOUNT_BILLING_EDIT = '/account/billing/edit';

export const SCREEN_ACCOUNT_ORDERS_MAIN = '/account/orders/all';

export const SCREEN_REGISTRATION = '/registration';
export const SCREEN_LOGIN = '/login';
export const SCREEN_PASSWORD_RECOVERY = '/password-recovery';
export const SCREEN_REGISTRATION_COMPLETE = '/registration-complete';

export const SCREEN_CONFIGURED_ITEMS = '/configured-items';
export const SCREEN_CHECKOUT = '/checkout';
export const SCREEN_CART = '/cart';


export const SCREEN_WIZARD_USB_START = '/configure/usb';
export const SCREEN_WIZARD_ALBUM_LANDING = '/configure/album';
export const SCREEN_WIZARD_ALBUM_START = '/configure/album/new';
export const SCREEN_WIZARD_USB_RESUME = '/configure/usb/resume';

