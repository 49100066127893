import React, {Component} from 'react';
import './ShippingScreenBatch.scss';
import {
    Container, Header, Menu, Modal, Table,
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ShippingScreenBatchAwb from "../components/shippingScreen/ShippingScreenBatchAwb";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

class ShippingScreenBatch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            batch: {},
            generating: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const orderElements = this.getOrderElements();
        let hasErrors = false;

        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i];

            if (item.empty) {
                continue;
            }

            item.data.forEach(awb => {
                if (awb.err_estimate === 1 || awb.err_generate === 1) {
                    hasErrors = true;
                }
            })
        }


        return (
            <Container style={{margin: '3rem 0'}} className='ShippingScreenBatch'>

                <Modal
                    basic
                    onClose={() => {
                    }}
                    onOpen={() => {
                    }}
                    open={this.state.generating}
                    size='small'
                >
                    <Header icon>
                        <Icon name='sync'/>

                    </Header>
                    <Modal.Content>
                        <h3 style={{textAlign: 'center'}}>
                            Se genereaza AWB. Nu inchide pagina. Poate dura cateva minute...
                        </h3>
                    </Modal.Content>
                    <Modal.Actions>

                    </Modal.Actions>
                </Modal>

                <h3 className="title">Expeditie {this.state.batch.name}</h3>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Comanda</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Detalii</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {orderElements}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                <div style={{display: 'flex'}}>
                                    <div style={{flex: 1}}>
                                    </div>
                                    {this.state.generating === false && this.state.batch?.complete === 0 &&
                                    <div>
                                        {!hasErrors &&
                                        <Button color='purple' onClick={this.onGenerateShippingClicked}>Finalizeaza
                                            expediere</Button>
                                        }

                                    </div>
                                    }

                                    {this.state.batch?.complete === 1 && hasErrors === false &&
                                    <Button basic color='blue' onClick={this.onDownloadClicked}>Descarca fisierul</Button>
                                    }

                                    {hasErrors &&
                                    <h3>Rezolva erorile!</h3>
                                    }
                                </div>


                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Container>
        );
    }

    getOrderElements = () => {
        if (this.state.items.length === 0) {
            return null;
        }

        return this.state.items.map(item => {
            if (item.empty) {
                return this.getEmptyLine(item);
            } else {
                return this.getNonEmptyLine(item);
            }
        })
    }

    getEmptyLine = (order) => {
        return (
            <Table.Row negative key={order.id}>
                <Table.Cell className="orderLabel red"><h2>{order.label}</h2></Table.Cell>
                <Table.Cell>{order.client}</Table.Cell>
                <Table.Cell>

                    {this.state.batch?.complete === 0 &&
                    <Button size='small'
                            onClick={() => this.onCreateNewAwbClicked(order.id, parseInt(this.props.match.params.id))}>Adauga
                        AWB</Button>
                    }
                </Table.Cell>
            </Table.Row>
        )
    }

    getNonEmptyLine = (order) => {
        const awbItems = order.data.map(item => <ShippingScreenBatchAwb key={item.id} refreshData={this.getData}
                                                                        order={order} awb={item}
                                                                        complete={this.state.batch.complete}/>)

        return (
            <Table.Row key={order.id}>
                <Table.Cell className="orderLabel"><h2>{order.label}</h2></Table.Cell>
                <Table.Cell>{order.client}</Table.Cell>
                <Table.Cell>
                    {this.state.batch?.complete === 0 &&
                    <Button basic size='small'
                            onClick={() => this.onCreateNewAwbClicked(order.id, parseInt(this.props.match.params.id))}>Adauga
                        AWB</Button>
                    }
                    {awbItems}
                </Table.Cell>

            </Table.Row>
        )
    }

    onCreateNewAwbClicked = (orderId, batchId) => {
        ApiService.shippingBatchItemCreateAwb({orderId: orderId, batchId: batchId}).then(data => {
            this.getData();
        })
    }

    getData = () => {
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            ApiService.shippingBatchGetItems(parseInt(this.props.match.params.id)).then(data => {
                this.setState({items: data.data.orders, batch: data.data.batch, generating: false});
            })
        }
    }

    onGenerateShippingClicked = () => {
        const batchId = parseInt(this.props.match.params.id);

        this.setState({generating: true}, () => {
            ApiService.shippingBatchesGenerate(batchId).then(data => {
                this.getData();
            })
        })
    }

    onDownloadClicked = () => {
        const batchId = parseInt(this.props.match.params.id);
        ApiService.downloadAwbBatch(batchId);
    }
}


export default ShippingScreenBatch;
