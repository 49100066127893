import React, {Component} from 'react';
import '../../screens/QuickFillScreen.scss';
import {Dropdown, Input} from "semantic-ui-react";


class FilledItemShipping extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="FilledItem">
                <h2 className="FilledItemTitle">Shipping x {this.props.data.quantity}</h2>
                <div className="FilledItemBody">
                    <div className="row">
                        <div className="selectionItem">
                            <div className="title">Shipping</div>
                            <div className="items">
                                <div className="item">Will add a shipping cost for the order</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default FilledItemShipping;
