import React, {Component} from 'react';
import {Button, Dropdown, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Utils from "../../data/Utils";

class StockItemCreateCorners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sizeName: null,
            color: '',
            pickPlace: '',
            quantity: 0,
            saving: false
        }
    }

    render() {
        const SKU = this.getItemSKU();

        return (
            <div>
                <div style={{margin: '0px 10px', marginBottom: '20px'}}>
                    <div>SKU</div>
                    <Input disabled>{SKU}</Input>
                </div>

                <div style={{display: 'flex'}}>

                    <div style={{margin: '0px 10px'}}>
                        <div>Color</div>
                        <Dropdown
                            options={this.props.corners.map(i => {
                                return {key: i.id, value: i.color, text: i.color, id: i.id}
                            })}
                            selection compact
                            search
                            value={this.state.color}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => {
                                this.setState({color: item.value})
                            }}
                        />
                    </div>

                    <div style={{margin: '0px 10px'}}>
                        <div>Pick place</div>
                        <Input style={{width: '80px'}} value={this.state.pickPlace} onChange={(e, { name, value }) => this.setState({ pickPlace: value })}/>
                    </div>

                    <div style={{margin: '0px 10px'}}>
                        <div>Quantity</div>
                        <Input style={{width: '80px'}} value={this.state.quantity} onChange={(e, { name, value }) => this.setState({ quantity: value })}/>
                    </div>

                    <Button disabled={this.state.saving} style={{marginLeft: '10px', marginTop:'20px'}} primary onClick={this.onSaveClicked}>{this.state.saving ? 'Saving...' : 'Save'}</Button>
                </div>

            </div>
        )
    }

    onSaveClicked = () => {
        const sku = this.getItemSKU();

        let payload = {
            sku: sku,
            name: Utils.getCurrentStocName(),
            quantity: parseInt(this.state.quantity),
            pick_place: this.state.pickPlace,
            type: 'corner',
            meta: {
                color: this.state.color,
            }
        }


       this.setState({saving: true}, async()=>{
            await ApiService.stockItemCreate(payload);

            this.setState({saving: false}, ()=>{
                this.props.hide();
            })
       });
    }

    delay = () => {
       return new Promise(resolve=>{
           setTimeout(()=>{
               return resolve();
           }, 2000)
       })
    }

    getItemSKU = () => {
        return `CORNER-${this.state.color}`
    }
}

export default StockItemCreateCorners;
