import React, {Component} from 'react';
import {Button, Container, Dimmer, Dropdown, Header, Image, Input, Item, Loader} from "semantic-ui-react";
import './ExternalConfigurator.scss';
import moment from "moment-timezone";
import DatePicker from "react-datepicker/es";

class ExternalConfigurator extends Component {
    constructor(props) {
        super(props);

        this.configs = {
            "Print colaje": {
                label: "Print colaje",
                orderId: 0,
                source: 'Florin',
                fields: {
                    "0": {
                        type: "date",
                        label: "Termen",
                        required: true,
                        value: null
                    },
                    "3": {
                        type: "text",
                        label: "Marime",
                        required: true,
                        value: ""
                    },
                    "4": {
                        type: "dropdown",
                        label: "Hartie",
                        options: ["Lustra", "Lucios", "Silk"],
                        required: true,
                        value: ""
                    },
                    "5": {
                        type: "text",
                        label: "Bucati",
                        required: true,
                        value: ""
                    },
                    "6": {
                        type: "url",
                        label: "Fisiere",
                        required: true,
                        value: ""
                    },
                    "m": {
                        type: "textbox",
                        label: "Mentiuni",
                        required: false,
                        value: ""
                    },
                }
            },
            "Print UV Coperta": {
                label: "Print UV Coperta",
                orderId: 0,
                source: 'Florin',
                fields: {
                    "0": {
                        type: "date",
                        label: "Termen",
                        value: null,
                        required: true,
                    },
                    "m": {
                        type: "textbox",
                        label: "Mentiuni",
                        required: false,
                        value: ""
                    },
                    "2": {
                        type: "url",
                        label: "Fisiere",
                        value: "",
                        required: true,
                    }
                }
            },
            "Print passepartout": {
                label: "Print passepartout",
                orderId: 0,
                source: 'Florin',
                fields: {
                    "0": {
                        type: "date",
                        label: "Termen",
                        value: null,
                        required: true,
                    },
                    "1": {
                        type: "text",
                        label: "Marime",
                        value: null,
                        required: true,
                    },
                    "m": {
                        type: "textbox",
                        label: "Mentiuni",
                        required: false,
                        value: ""
                    },
                    "2": {
                        type: "url",
                        label: "Fisiere",
                        value: "",
                        required: true,
                    }
                }
            },
            "Taiere MDF": {
                label: "Taiere MDF",
                orderId: 0,
                source: 'Florin',
                fields: {
                    "0": {
                        type: "date",
                        label: "Termen",
                        value: null,
                        required: true,
                    },
                    "1": {
                        type: "text",
                        label: "Marime",
                        value: null,
                        required: true,
                    },
                    "m": {
                        type: "textbox",
                        label: "Mentiuni",
                        required: false,
                        value: ""
                    },
                    "2": {
                        type: "url",
                        label: "Fisiere",
                        value: "",
                        required: true,
                    }
                }
            }
        };

        let conf = JSON.parse(JSON.stringify(this.configs));

        let ec = this.props.type ? conf[this.props.type] : null;
        this.state = {
            editingConfiguration: ec
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let conf = JSON.parse(JSON.stringify(this.configs));
        let ec = nextProps.type ? conf[nextProps.type] : null;

        this.setState({
            editingConfiguration: null
        }, ()=>{
           this.setState({
               editingConfiguration: ec
           })
        })
    }

    render() {
        const addConfigurationElement = this.getAddConfigurationElement();

        return (
            <Container className='ExternalConfigurator'>
                {addConfigurationElement}
            </Container>
        );
    }

    getAddConfigurationElement = () => {
        let elements = [];

        if (!this.state.editingConfiguration) {
            return null;
        }

        let config = this.state.editingConfiguration;

        elements.push(
            <div className='pre-title'>comanda noua</div>
        );
        elements.push(
            <div className='title'>{config.label}</div>
        );

        elements.push(
            <div className='item'>
                <div className='label'>Nr. comanda*</div>
                <Input style={{width: '100%'}} value={this.state.editingConfiguration.orderId}
                       onChange={this.onOrderIdChanged}/>
            </div>
        );

        elements.push(
            <div className='item'>
                <div className='label'>Sursa*</div>
                <Dropdown
                    name='emboss_l1_size'
                    options={[
                        {
                            id: 1,
                            text: 'Florin',
                            value: 'Florin',
                        },
                        {
                            id: 2,
                            text: 'Indigo',
                            value: 'Indigo',
                        },
                        {
                            id: 3,
                            text: 'Octaser',
                            value: 'Octaser',
                        },

                    ]}
                    selection
                    value={config.source}
                    onChange={(e, {name, value}) => this.onOrderSourceChanged(value)}
                />

            </div>
        )

        for (let key in config.fields) {
            let field = config.fields[key];

            if (field.type === 'text') {
                elements.push(
                    <div className='item'>
                        <div className='label'>{field.label}{field.required && '*'}</div>
                        <Input value={field.value} onChange={(e) => this.onFieldChanged(field, e.target.value)}
                               style={{width: '100%'}}/>
                    </div>
                )
            }

            if (field.type === 'textbox') {
                elements.push(
                    <div className='item'>
                        <div className='label'>{field.label}{field.required && '*'}</div>
                        <textarea id="story"
                                  name="story"
                                  rows="3" cols="33"
                                  value={field.value}
                                  onChange={(e) => this.onFieldChanged(field, e.target.value)}
                                  style={{width: '100%'}}
                        >

                        </textarea>
                    </div>
                )
            }

            if (field.type === 'url') {
                elements.push(
                    <div className='item'>
                        <div className='label'>{`${field.label} (url)`}{field.required && '*'}</div>
                        <Input value={field.value} onChange={(e) => this.onFieldChanged(field, e.target.value)}
                               style={{width: '100%'}}/>
                    </div>
                )
            }

            if (field.type === 'dropdown') {
                let options = [];

                field.options.forEach((option, index) => {
                    options.push({
                        id: index,
                        text: option,
                        value: option,
                    })
                });

                elements.push(
                    <div className='item'>
                        <div className='label'>{field.label}{field.required && '*'}</div>
                        <Dropdown
                            name='emboss_l1_size'
                            options={options}
                            selection
                            value={field.value}
                            onChange={(e, {name, value}) => this.onFieldChanged(field, value)}
                        />

                    </div>
                )
            }

            if (field.type === 'date') {
                elements.push(
                    <div className='item'>
                        <div className='label'>{field.label}{field.required && '*'}</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                format={"dd-MM-yyyy"}
                                onChange={(date) => {
                                    date.setHours(13);
                                    date.setMinutes(0);
                                    this.onFieldChanged(field, date);
                                }
                                }
                                value={field.value}
                                style={{border: 'none'}}
                            />

                            <div style={{marginLeft: '5px'}}>({moment(field.value).fromNow()})</div>
                        </div>
                    </div>
                )
            }
        }

        let formValid = true;
        for (let key in config.fields) {
            let field = config.fields[key];
            if(field.required){
                if(!field.value || field.value.length === 0){
                    formValid = false;
                }
            }
        }

        if(!this.state.editingConfiguration.source || this.state.editingConfiguration.source.length === 0){
            formValid = false;
        }


        elements.push(
            <div className='buttons'>
                <Button disabled={!formValid} primary compact onClick={()=>this.props.onSaveClicked({...this.state.editingConfiguration})}>Plaseaza</Button>
                <Button color={'red'} compact onClick={this.props.onCancelClicked}>Anuleaza</Button>
            </div>
        );

        return (<div className='order'>{elements}</div>);

    };

    onOrderIdChanged = (e) => {
        let item = {...this.state.editingConfiguration};
        item.orderId = e.target.value;

        this.setState({editingConfiguration: item})
    };

    onOrderSourceChanged = (value) => {
        let item = {...this.state.editingConfiguration};
        item.source = value;

        this.setState({editingConfiguration: item})
    };

    onFieldChanged = (field, value) => {
        let item = {...this.state.editingConfiguration};

        for (let key in item.fields) {
            let f = item.fields[key];
            if (f.label === field.label) {
                f.value = value;
                break;
            }
        }

        this.setState({editingConfiguration: item})
    };

}


export default ExternalConfigurator;
