const env = "dev";
const product = "phototailors_c";

const config = {
    phototailors_c: {
        dev: {
            API_URL: "http://localhost:3001",
            LS_USER_KEY: "phototailors_client-dev",
            ASSETS_PATH: "https://d1496qv8pnac5u.cloudfront.net"
        },
        prod: {
            API_URL: "http://localhost:3001",
            LS_USER_KEY: "phototailors_client",
            ASSETS_PATH: "https://d1496qv8pnac5u.cloudfront.net"
        }
    }
};

const getConfig = () => {
    return config[product][env];
};

module.exports = {
    config: getConfig()
};