import React, {Component} from 'react';
import './OrderTimeline.scss';
import {Button, Dropdown, Icon, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";

class ShippedNotifyUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            providers: [
                {key: 'DHL', text: 'DHL', value: 'DHL'},
                {key: 'GLS', text: 'GLS', value: 'GLS'}
            ],
            selected: -1,
            tracking: '',
            sending: false
        }
    }

    render() {
        let isButtonActive = this.state.selected !== -1 && this.state.tracking.length !== 0;

        return (
            <div className='OrderDeadline'>
                <div style={{
                    marginBottom: '10px',
                    paddingBottom: '4px',
                    borderBottom: '2px solid #dededf',
                    fontSize: '15px'
                }}>Notify user
                </div>

                {this.state.sending === false &&
                <div>
                    <Dropdown closeOnBlur
                              search selection name='orderDeadline' options={this.state.providers}
                              placeholder='-select-'
                              value={this.state.selected} onChange={this.onShipperSelected}/>
                    <br/>
                    <br/>
                    <Input label='tracking' value={this.state.tracking}
                           onChange={(e, {value}) => this.setState({tracking: value})}></Input>
                    <br/>
                    <br/>
                    <Button disabled={!isButtonActive} primary size='tiny' onClick={this.onSendEmailClicked}>send
                        email</Button>
                </div>
                }

                {this.state.sending === true &&
                <Button disabled={true} primary size='tiny'>sending email...</Button>
                }
            </div>
        )
    }

    onShipperSelected = (e, {value}) => {
        this.setState({selected: value})
    }

    onSendEmailClicked = () => {
        this.setState({sending: true}, ()=>{
            ApiService.notifyUserOrderShipped({orderId:this.props.orderId, shipper: this.state.selected, tracking: this.state.tracking}).then(data=>{
                this.setState({
                    selected: -1,
                    tracking: '',
                    sending: false
                })
            })
        })
    }

}


export default ShippedNotifyUser;
