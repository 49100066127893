import React, {Component} from 'react';
import {Button, Dropdown, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Utils from "../../data/Utils";

class StockItemCreateShippingBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            sizeName: '',
            sizeId: '',
            pickPlace: '',
            quantity: 0,
            saving: false
        }
    }

    render() {
        const SKU = this.getItemSKU();

        return (
            <div>
                <div style={{margin: '0px 10px', marginBottom: '20px'}}>
                    <div>SKU</div>
                    <Input disabled>{SKU}</Input>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{margin: '0px 10px'}}>
                        <div>Size</div>
                        <Dropdown
                            options={this.props.sizes.map(i => {
                                let value = `${i.code} (${i.width}x${i.height}x${i.depth}cm)`
                                return {key: i.id, value: i.code, text: value, id: i.id}
                            })}
                            selection compact
                            search
                            value={this.state.sizeName.split('.')[0]}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => {
                                let id = this.props.sizes.find(s=>s.code === item.value);
                                let value = `${item.value}.${id.width}x${id.height}x${id.depth}cm`
                                this.setState({sizeName: value, sizeId: id.id})
                            }}
                        />
                    </div>



                    <div style={{margin: '0px 10px'}}>
                        <div>Pick place</div>
                        <Input style={{width: '80px'}} value={this.state.pickPlace} onChange={(e, { name, value }) => this.setState({ pickPlace: value })}/>
                    </div>

                    <div style={{margin: '0px 10px'}}>
                        <div>Quantity</div>
                        <Input style={{width: '80px'}} value={this.state.quantity} onChange={(e, { name, value }) => this.setState({ quantity: value })}/>
                    </div>

                    <Button disabled={this.state.saving} style={{marginLeft: '10px', marginTop:'20px'}} primary onClick={this.onSaveClicked}>{this.state.saving ? 'Saving...' : 'Save'}</Button>
                </div>

            </div>
        )
    }

    onSaveClicked = () => {
        const sku = this.getItemSKU();

        let payload = {
            sku: sku,
            name: Utils.getCurrentStocName(),
            quantity: parseInt(this.state.quantity),
            pick_place: this.state.pickPlace,
            type: 'shipping_box',
            meta: {
                sizeId: this.state.sizeId,
                sizeName: this.state.sizeName,
            }
        }


       this.setState({saving: true}, async()=>{
            await ApiService.stockItemCreate(payload);

            this.setState({saving: false}, ()=>{
                this.props.hide();
            })
       });
    }

    delay = () => {
       return new Promise(resolve=>{
           setTimeout(()=>{
               return resolve();
           }, 2000)
       })
    }

    getItemSKU = () => {
        // if(this.state.code){
        //     let material = this.props.materials.find(item=>item.name === this.state.materialName);
        //     materialId = material.id;
        // }
        //
        // if(this.state.sizeName){
        //     let size = this.props.sizes.find(item=>item.cm === this.state.sizeName);
        //     sizeId = size.id;
        // }

        return `SHIPPINGBOX-ID.${this.state.sizeId}-TYPE.${this.state.sizeName}`
    }
}

export default StockItemCreateShippingBox;
