import React, {Component} from 'react';
import {Container, Dimmer, Form, Header, Image, Input, Item, Loader} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import moment from "moment-timezone";
import './InputWithLabel.scss';

class InputWithLabel extends Component {

    render() {
        return (
            <div className='InputWithLabel'>
                <div className='label'>{this.props.label}</div>
                <Input disabled={this.props.disabled} className='input' placeholder={this.props.label} value={this.props.value} onChange={(e)=>this.props.onChange(e.target.value)}/>
            </div>
        );
    }

}


export default InputWithLabel;
