import React from 'react';
import './SalesItem.scss';

class SalesItem extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            sector: this.props.data.sector,
            isSelectedInCalculator: false
        }
    }

    render(){
        const elementToRender = this.getElementToRender();

        return (
            <div className='SalesItem' onClick={this.onClicked}>
               {elementToRender}
            </div>
        )
    }

    onClicked = ()=>{
        if(this.props.onClick){
            this.props.onClick(this.props.diff, this.props.agg, this.props.aggItems);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.data.item_order !== this.props.data.item_order) || (nextState.isSelectedInCalculator !== this.state.isSelectedInCalculator);
    }

    getElementToRender = () => {
        if(this.props.data.type === 'Deposit'){
            return (
                <div className='Item' style={{background: '#8cce8c'}}>
                    <div className='col'>{this.props.data.type}</div>
                    <div className='col'>{this.props.data.amount}</div>
                </div>
            )
        }
        if(this.props.data.type === 'ST'){
            return (
                <div className='Item' style={{background: '#8bb2ce'}}>
                    <div className='col'>{this.props.data.type}</div>
                    <div className='col'>{this.props.data.amount}</div>
                </div>
            )
        }
        else {
            let invoiceNumbers = ``;
            let invoiceAmounts = ``;
            let invoiceDate = '';
            let total = 0;

            let etsyOrder = '';
            let etsyOrderUrl = '';
            let price = (this.props.data.net + Math.abs(this.props.data.taxes)).toFixed(2);

            if(this.props.data.title.indexOf('Payment for Order ') > -1){
                etsyOrder = this.props.data.title.split('Payment for Order ')[1];
                etsyOrderUrl=`https://www.etsy.com/your/orders/sold/${etsyOrder}?search_query=${etsyOrder}&page=1`
            }

            if(this.props.data.invoices){

                this.props.data.invoices.forEach(invoice=>{
                    let data = JSON.parse(invoice.data);
                    invoiceNumbers+= invoice.series_count + " ";
                    invoiceAmounts+= data.total + " ";
                    total += data.total;
                    invoiceDate = new Date(invoice.date).toString().split(' ')
                    invoiceDate = invoiceDate[2] + " " + invoiceDate[1] + " " + invoiceDate[3]
                })
            }
            if(this.props.renderLight) {
                let itemStyle = {

                }

                if(this.state.isSelectedInCalculator){
                    itemStyle = {border: '1px solid blue'};
                }

                return (
                    <div className='Item'>
                        {this.props.editMode &&
                        <button style={{marginRight: '5px'}} onClick={this.moveItem}>v</button>
                        }
                        <div className='col'>{this.props.data.type}</div>
                        <div className='bla'>{this.props.data.date}</div>
                        <div className='col' style={itemStyle} onClick={this.onNetSelected}><span style={{fontSize: '8px'}}>incasat: </span>{this.props.data.net}</div>
                        <div className='bla'><span style={{fontSize: '8px'}}>facturat: </span>{total}</div>
                        <div className='bla'><span style={{fontSize: '8px'}}>facturat-incasat: </span>{(total-price).toFixed(2)}</div>
                        <a className='bla' href={etsyOrderUrl} target='_blank'>{etsyOrder}</a>
                        <div className='bla'><span style={{fontSize: '8px'}}>comanda: </span>{this.props.data.orders_id}</div>
                        <div className='bla'><span style={{fontSize: '8px'}}>id facturi: </span>{invoiceNumbers}</div>
                        <div className='bla'><span style={{fontSize: '8px'}}>data facturi: </span>{invoiceDate}</div>
                        <div className='bla'><span style={{fontSize: '8px'}}>sume facturi: </span>{invoiceAmounts}</div>
                    </div>
                )
            } else {
                return (
                    <div className='Item'>
                        {this.props.editMode &&
                        <button style={{marginRight: '5px'}} onClick={this.moveItem}>v</button>
                        }
                        <div className='col'>{this.props.data.type}</div>
                        <div className='bla'>{this.props.data.date}</div>
                        <div className='col'>{price}</div>
                        <div className='bla'>{total}</div>
                        <div className='bla'>{(total-price).toFixed(2)}</div>
                        <a className='bla' href={etsyOrderUrl} target='_blank'>{etsyOrder}</a>
                        <div className='bla'>Order: {this.props.data.orders_id}</div>
                        <div className='bla'>{invoiceNumbers}</div>
                        <div className='bla'>{invoiceAmounts}</div>
                    </div>
                )
            }
        }
    }

    onNetSelected = () => {
        let add = this.state.isSelectedInCalculator === false;

        this.setState({isSelectedInCalculator: !this.state.isSelectedInCalculator}, ()=>{
            if(add && this.props.calcAdd){
                this.props.calcAdd(this.props.data)
            }
            if(!add && this.props.calcSub){
                this.props.calcSub(this.props.data)
            }

        })
    }

    onSectorChanged = (sector) => {
        this.setState({
            sector: sector
        })
    };

    moveItem = () => {
        this.props.moveItem(this.props.data.item_order, this.props.data.id)
    }


}

export default SalesItem;
