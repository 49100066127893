import React, {Component} from 'react';
import './DhlCheckScreen.scss';
import {Container, Dropdown} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class PacketaScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shippers: null,
            countries: [],
            assignments: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const carriersElements = this.getCarriersElements();

        return (
            <Container style={{margin: '3rem 0'}}>
                {carriersElements}
            </Container>
        );
    }

    getCarriersElements = () => {
        if (!this.state.shippers) {
            return null;
        }

        let ret = [];
        for (let countryCode in this.state.shippers) {
            let country = this.state.countries.find(c => c.code.toLowerCase() === countryCode);
            let availableShippersForCountry = this.state.shippers[countryCode];
            let assignedItem = this.state.assignments.find(i => i.country_code === countryCode);


            ret.push(
                <div key={countryCode} style={{marginBottom:'30px'}}>
                    <div style={{fontWeight:'bold'}}>({countryCode}) {country.name}</div>

                    <Dropdown
                        error={!assignedItem}
                        options={availableShippersForCountry.map(i => {
                            return {
                                key: i.id,
                                value: i.id,
                                text: i.name,
                                id: i.id
                            }
                        })}
                        selection compact
                        value={assignedItem ? assignedItem.carrier_id : null}
                        style={{minWidth: '400px'}}
                        onChange={(e, {name, value}) => this.onShipperChanged(countryCode, value)}
                    />
                </div>
            )
        }

        return ret;

        // return this.state.shi
    }

    onShipperChanged = async(countryCode, shipperId) => {
        await ApiService.packetaUpdateAssignment({countryCode, shipperId});
        this.getData();
    }

    getData = async () => {
        const data = await ApiService.packetaGetConfigData();
        this.setState({
            shippers: data.data.shippers,
            countries: data.data.countries,
            assignments: data.data.assignments
        })
        // console.log(data.data)
    }

}


export default PacketaScreen;
