import React, {Component} from 'react';
import './OrderPieceElement.scss';
import {Dropdown, Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import OrderPieceAlbum from "./OrderPieceAlbum";
import OrderPieceGuestbook from "./OrderPieceGuestbook";
import OrderPieceRush from "./OrderPieceRush";
import OrderPieceBambooBox from "./OrderPieceBambooBox";
import OrderPieceCorners from "./OrderPieceCorners";
import OrderPieceBudoir from "./OrderPieceBudoir";
import OrderPiecePouch from "./OrderPiecePouch";
import OrderPieceDiscount from "./OrderPieceDiscount";
import OrderPieceAddon from "./OrderPieceAddon";
import OrderPieceShipping from "./OrderPieceShipping";
import OrderPieceBoudoirEmpty from "./OrderPieceBoudoirEmpty";
import OrderPiecePremiumBox from "./OrderPiecePremiumBox";
import OrderPieceAlbumSleeve from "./OrderPieceAlbumSleeve";
import OrderPiecePen from "./OrderPiecePen";
import OrderPieceClassicAlbum from "./OrderPieceClassicAlbum";
import OrderPiecePenTwo from "./OrderPiecePenTwo";
import OrderPiecePenThree from "./OrderPiecePenThree";
import OrderPiecePenThreeSame from "./OrderPiecePenThreeSame";
import OrderPieceGuestbookSlots from "./OrderPieceGuestbookSlots";
import OrderPieceMarker from "./OrderPieceMarker";
import OrderPieceVow from "./OrderPieceVow";

class OrderPieceGeneric extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product_type: this.props.data.product_type
        }
    }

    render() {
        if (this.state.product_type) {
            let data = this.props.data;
            data.product_type = this.state.product_type;

            switch (this.state.product_type) {
                case 'album':
                    return (
                        <OrderPieceAlbum configs={this.props.configs} data={data}
                                         orderStatus={this.props.orderStatus}
                                         mainOrderId={this.props.mainOrderId}
                                         onDeleteClicked={this.props.onDeleteClicked}
                                         onChangeTypeClicked={() => this.setState({product_type: ''})}
                                         onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'guestbook':
                    return (
                        <OrderPieceGuestbook configs={this.props.configs} data={data}
                                             orderStatus={this.props.orderStatus}
                                             mainOrderId={this.props.mainOrderId}
                                             onDeleteClicked={this.props.onDeleteClicked}
                                             onChangeTypeClicked={() => this.setState({product_type: ''})}
                                             onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'guestbook-slots':
                    return (
                        <OrderPieceGuestbookSlots configs={this.props.configs} data={data}
                                             orderStatus={this.props.orderStatus}
                                             mainOrderId={this.props.mainOrderId}
                                             onDeleteClicked={this.props.onDeleteClicked}
                                             onChangeTypeClicked={() => this.setState({product_type: ''})}
                                             onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'boudoir':
                    return (
                        <OrderPieceBudoir configs={this.props.configs} data={data} userId={this.props.userId}
                                          orderStatus={this.props.orderStatus}
                                          mainOrderId={this.props.mainOrderId}
                                          onDeleteClicked={this.props.onDeleteClicked}
                                          onChangeTypeClicked={() => this.setState({product_type: ''})}
                                          onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'boudoir-empty':
                    return (
                        <OrderPieceBoudoirEmpty configs={this.props.configs} data={data}
                                                orderStatus={this.props.orderStatus}
                                                mainOrderId={this.props.mainOrderId}
                                          onDeleteClicked={this.props.onDeleteClicked}
                                          onChangeTypeClicked={() => this.setState({product_type: ''})}
                                          onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'rush':
                    return (
                        <OrderPieceRush data={data} onDeleteClicked={this.props.onDeleteClicked}
                                        onChangeTypeClicked={() => this.setState({product_type: ''})}
                                        onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'bamboo-box':
                    return (
                        <OrderPieceBambooBox data={data} configs={this.props.configs}
                                             orderStatus={this.props.orderStatus}
                                             onDeleteClicked={this.props.onDeleteClicked}
                                             onChangeTypeClicked={() => this.setState({product_type: ''})}
                                             onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'corners':
                    return (
                        <OrderPieceCorners data={data} onDeleteClicked={this.props.onDeleteClicked}
                                           orderStatus={this.props.orderStatus}
                                           onChangeTypeClicked={() => this.setState({product_type: ''})}
                                           onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                           onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'pen':
                    return (
                        <OrderPiecePen data={data} onDeleteClicked={this.props.onDeleteClicked}
                                       orderStatus={this.props.orderStatus}
                                           onChangeTypeClicked={() => this.setState({product_type: ''})}
                                           onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                           onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'pen-two':
                    return (
                        <OrderPiecePenTwo data={data} onDeleteClicked={this.props.onDeleteClicked}
                                       orderStatus={this.props.orderStatus}
                                       onChangeTypeClicked={() => this.setState({product_type: ''})}
                                       onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                       onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'pen-three':
                    return (
                        <OrderPiecePenThree data={data} onDeleteClicked={this.props.onDeleteClicked}
                                          orderStatus={this.props.orderStatus}
                                          onChangeTypeClicked={() => this.setState({product_type: ''})}
                                          onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                          onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'pen-three-same':
                    return (
                        <OrderPiecePenThreeSame data={data} onDeleteClicked={this.props.onDeleteClicked}
                                            orderStatus={this.props.orderStatus}
                                            onChangeTypeClicked={() => this.setState({product_type: ''})}
                                            onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                            onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'marker':
                    return (
                        <OrderPieceMarker data={data} configs={this.props.configs} onDeleteClicked={this.props.onDeleteClicked}
                                       orderStatus={this.props.orderStatus}
                                       onChangeTypeClicked={() => this.setState({product_type: ''})}
                                       onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                       onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'vow-book':
                    return (
                        <OrderPieceVow data={data} configs={this.props.configs} onDeleteClicked={this.props.onDeleteClicked}
                                          orderStatus={this.props.orderStatus}
                                          onChangeTypeClicked={() => this.setState({product_type: ''})}
                                          onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}
                                          onDiscountClicked={this.props.onOrderDiscountClicked}
                        />
                    );
                case 'pouch':
                    return (
                        <OrderPiecePouch data={data} configs={this.props.configs}
                                         orderStatus={this.props.orderStatus}
                                         onDeleteClicked={this.props.onDeleteClicked}
                                           onChangeTypeClicked={() => this.setState({product_type: ''})}
                                           onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'premium-box':
                    return (
                        <OrderPiecePremiumBox data={data} configs={this.props.configs}
                                              orderStatus={this.props.orderStatus}
                                         onDeleteClicked={this.props.onDeleteClicked}
                                         onChangeTypeClicked={() => this.setState({product_type: ''})}
                                         onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'album-sleeve':
                    return (
                        <OrderPieceAlbumSleeve data={data} configs={this.props.configs}
                                               orderStatus={this.props.orderStatus}
                                              onDeleteClicked={this.props.onDeleteClicked}
                                              onChangeTypeClicked={() => this.setState({product_type: ''})}
                                              onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'discount':
                    return (
                        <OrderPieceDiscount data={data} configs={this.props.configs}
                                            orderStatus={this.props.orderStatus}
                                         onDeleteClicked={this.props.onDeleteClicked}
                                         onChangeTypeClicked={() => this.setState({product_type: ''})}
                                         onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'addon':
                    return (
                        <OrderPieceAddon data={data} configs={this.props.configs}
                                         orderStatus={this.props.orderStatus}
                                            onDeleteClicked={this.props.onDeleteClicked}
                                            onChangeTypeClicked={() => this.setState({product_type: ''})}
                                            onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'shipping':
                    return (
                        <OrderPieceShipping data={data} configs={this.props.configs}
                                            orderStatus={this.props.orderStatus}
                                         onDeleteClicked={this.props.onDeleteClicked}
                                         onChangeTypeClicked={() => this.setState({product_type: ''})}
                                         onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
                case 'classic-album':
                    return (
                        <OrderPieceClassicAlbum configs={this.props.configs} data={data}
                                                orderStatus={this.props.orderStatus}
                                                mainOrderId={this.props.mainOrderId}
                                             onDeleteClicked={this.props.onDeleteClicked}
                                             onChangeTypeClicked={() => this.setState({product_type: ''})}
                                             onOrderItemSaveClicked={this.props.onOrderItemSaveClicked}/>
                    );
            }
        }
        return (
            <div className='OrderPieceElement'>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label' style={{background:'red', color:'white', fontWeight:'bold', padding:'5px 3px'}}>Product type*</div>
                        <Dropdown
                            options={[
                                {
                                    id: 1,
                                    text: 'Album foto',
                                    value: 'album',
                                },
                                {
                                    id: 2,
                                    text: 'Guestbook',
                                    value: 'guestbook',
                                },
                                {
                                    id: 19,
                                    text: 'Guestbook Slots',
                                    value: 'guestbook-slots',
                                },
                                {
                                    id: 3,
                                    text: 'Boudoir',
                                    value: 'boudoir',
                                },
                                {
                                    id: 4,
                                    text: 'Boudoir Empty',
                                    value: 'boudoir-empty',
                                },
                                {
                                    id: 5,
                                    text: 'Corners',
                                    value: 'corners',
                                },
                                {
                                    id: 6,
                                    text: 'Bamboo box',
                                    value: 'bamboo-box',
                                },
                                {
                                    id: 7,
                                    text: 'Rush/Upgrade',
                                    value: 'rush',
                                },
                                {
                                    id: 8,
                                    text: 'Pouch',
                                    value: 'pouch',
                                },
                                {
                                    id: 9,
                                    text: 'Discount',
                                    value: 'discount',
                                },
                                {
                                    id: 10,
                                    text: 'Add-on',
                                    value: 'addon',
                                },
                                {
                                    id: 11,
                                    text: 'Shipping',
                                    value: 'shipping',
                                },
                                {
                                    id: 12,
                                    text: 'Premium Box',
                                    value: 'premium-box',
                                },
                                {
                                    id: 13,
                                    text: 'Album Sleeve',
                                    value: 'album-sleeve',
                                },
                                {
                                    id: 14,
                                    text: 'Classic Album',
                                    value: 'classic-album',
                                },
                                {
                                    id: 15,
                                    text: '1 Pen',
                                    value: 'pen',
                                },
                                {
                                    id: 16,
                                    text: 'Pack of 2 Pens',
                                    value: 'pen-two',
                                },
                                {
                                    id: 17,
                                    text: 'Pack of 3 Multicolor Pens',
                                    value: 'pen-three',
                                },
                                {
                                    id: 18,
                                    text: 'Pack of 3 Pens',
                                    value: 'pen-three-same',
                                },
                                {
                                    id: 19,
                                    text: 'Marker',
                                    value: 'marker',
                                },
                                {
                                    id: 20,
                                    text: 'Vow Book',
                                    value: 'vow-book',
                                },

                            ]}
                            selection compact search
                            value={this.state.product_type}
                            style={{minWidth: '180px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('product_type', value)}
                        />
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Price</div>
                        <div>{this.props.data.price} {this.props.data.currency}</div>
                    </div>

                    <div className='item'>
                        <div className='label'>Quantity</div>
                        <div>{this.props.data.quantity}</div>
                    </div>
                </div>
            </div>)
    }

    onItemFieldChanged = (field, value) => {
        this.setState({product_type: value})
    }

}


export default OrderPieceGeneric;
