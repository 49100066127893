import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import AlbumSleevesConfig from "../components/AlbumSleevesConfig";
import PremiumBoxesConfig from "../components/PremiumBoxesConfig";

class PremiumBoxesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'empty'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='grey' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Premium Box configuration</Menu.Item>
                    <Menu.Item
                        name='sizes'
                        active={activeItem === 'sizes'}
                        onClick={() => this.setState({activeItem: 'sizes'})}
                    />
                </Menu>

                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'sizes':
                return (<PremiumBoxesConfig type='premium-box'/>);
            default :
                return null;
        }
    }

}


export default PremiumBoxesScreen;
