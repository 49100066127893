import React from 'react';
import './ProductPersonalisationEmboss.scss'
import workItemSKU from "../../utils/workItemSKU";

export default function ProductPersonalisationEmboss({data, configs, onEmbossStatusToggled}) {
    const item = data.item;

    let textAlignment = '';
    let textPosition = '';

    if(data.item.textAlignment){
        textAlignment = configs.embossTextAlignments.find(i=>i.id === data.item.textAlignment).name;
    }

    if(data.item.textPosition){
        textPosition = configs.embossTextPositions.find(i=>i.id === data.item.textPosition).name;
    }

    let font1 = data.item.emboss_l1_font.label;
    if (font1) {
        font1 = font1.replace('.', '');
    }

    let font2 = data.item.emboss_l2_font.label;
    if (font2) {
        font2 = font2.replace('.', '');
    }

    let font3 = data.item.emboss_l3_font.label;
    if (font3) {
        font3 = font3.replace('.', '');
    }

    let font4 = data.item.emboss_l4_font?.label;
    if (font4) {
        font4 = font4.replace('.', '');
    }

    let font5 = data.item.emboss_l5_font?.label;
    if (font5) {
        font5 = font5.replace('.', '');
    }

    let statusClassName = data.progress.stantat === 0 ? 'status' : 'status done';

    if(data.progress.stantat === undefined){
        statusClassName = 'status';
    }


    const toggleStatus = () => {
        let newValue = parseInt(data.progress.stantat) === 0? 1:0;

        let up = {
            id: data.id,
            step: 'stantat',
            orders_id: data.orders_id,
            value: newValue
        }

        onEmbossStatusToggled(up);
    }

    return (
        <div className="ProductPersonalisationEmboss">
            <div className="header">
                <div className="hlistItem">{workItemSKU(data)}</div>
                <div className="hlistItem">Folie: {item.emboss.label.toUpperCase()}</div>
                <div className="hlistItem">Template: {item.embossTemplate?.name}</div>
                <div className="fill"/>
                <div className={statusClassName} onClick={toggleStatus}>stantat</div>
            </div>

            {data.details &&
                <div className="productionNotes">⚠ {data.details}</div>
            }

            <div className="textPos">
                <div className="hlistItem">Pozitie text: {textPosition}</div>
                <div>Aliniere text: {textAlignment}</div>
            </div>

            <div className="lines">
`                <div className="line">
                    <div className="lineInfo">Linia 1: {data.item.emboss_l1_font.label.split('.')[0]} {data.item.emboss_l1_size.label}pt</div>
                    <div className="preview"  style={{
                        lineHeight: `${data.item.emboss_l1_size.label}px`,
                        fontSize: `${data.item.emboss_l1_size.label}px`,
                        fontFamily: font1,
                        background: "#efefef",
                        padding: '10px 8px'
                    }}>{data.item.emboss_l1_text?.trim().replace(/\s+/g, '⎵')}</div>
                </div>

                {data.item.emboss_l2_size.label &&
                <div className="line">
                    <div className="lineInfo">Linia 2: {data.item.emboss_l2_font.label.split('.')[0]} {data.item.emboss_l2_size.label}pt</div>
                    <div className="preview"  style={{
                        lineHeight: `${data.item.emboss_l2_size.label}px`,
                        fontSize: `${data.item.emboss_l2_size.label}px`,
                        fontFamily: font2,
                        background: "#efefef",
                        padding: '10px 8px'
                    }}>{data.item.emboss_l2_text?.trim().replace(/\s+/g, '⎵')}</div>

                </div>
                }

                {data.item.emboss_l3_size.label &&
                <div className="line">
                    <div className="lineInfo">Linia 3: {data.item.emboss_l3_font.label.split('.')[0]} {data.item.emboss_l3_size.label}pt</div>
                    <div className="preview"  style={{
                        lineHeight: `${data.item.emboss_l3_size.label}px`,
                        fontSize: `${data.item.emboss_l3_size.label}px`,
                        fontFamily: font3,
                        background: "#efefef",
                        padding: '10px 8px'
                    }}>{data.item.emboss_l3_text?.trim().replace(/\s+/g, '⎵')}</div>

                </div>
                }

                {data.item.emboss_l4_size?.label &&
                <div className="line">
                    <div className="lineInfo">Linia 4: {data.item.emboss_l4_font.label.split('.')[0]} {data.item.emboss_l4_size.label}pt</div>
                    <div className="preview"  style={{
                        lineHeight: `${data.item.emboss_l4_size.label}px`,
                        fontSize: `${data.item.emboss_l4_size.label}px`,
                        fontFamily: font4,
                        background: "#efefef",
                        padding: '10px 8px'
                    }}>{data.item.emboss_l4_text?.trim().replace(/\s+/g, '⎵')}</div>

                </div>
                }

                {data.item.emboss_l5_size?.label &&
                <div className="line">
                    <div className="lineInfo">Linia 5: {data.item.emboss_l5_font.label.split('.')[0]} {data.item.emboss_l5_size.label}pt</div>
                    <div className="preview"  style={{
                        lineHeight: `${data.item.emboss_l5_size.label}px`,
                        fontSize: `${data.item.emboss_l5_size.label}px`,
                        fontFamily: font5,
                        background: "#efefef",
                        padding: '10px 8px'
                    }}>{data.item.emboss_l5_text?.trim().replace(/\s+/g, '⎵')}</div>

                </div>
                }
            </div>

            {data.item.cover_uv_image &&
            <div className="preview">
                <img width="100%" height="auto" src={`https://d1496qv8pnac5u.cloudfront.net/products/uv-packing-check/${data.item.cover_uv_image}`}/>
            </div>
            }
        </div>
    )
}
