import React, {Component} from 'react';
import ApiService from "../services/ApiService";
import moment from 'moment-timezone';
import './WorkItem.scss';
import OrderItemStatus from "./OrderItemStatus";
import PopOverIcon from "./PopOverIcon";
import PopOverIconRenderer from "./PopOverIconRenderer";
import ExternalOrder from "./ExternalOrder";
import {Button} from "semantic-ui-react";
import {Generate} from "./utils/checksum";
import workItemSKU from "./utils/workItemSKU";

class WorkItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            data: this.props.data
        }
    }

    render() {
        if (this.state.data.deleted === 1) {
            return null;
        }

        const items = this.getItemsElements();
        const header = this.getOrderHeader();

        const className = this.props.data.linked_orders ? 'WorkItem linked' : 'WorkItem';

        return (
            <div className={className}>
                {header}
                {items}
            </div>
        );
    }

    getOrderHeader = () => {
        if (this.props.data.suborder_of) {
            return null;
        }
        let productionDeadline = this.props.data.production_deadline || null;

        let productionDeadlineDisplay = "Timp necunoscut";
        if (productionDeadline) {
            productionDeadlineDisplay = moment(productionDeadline + " 12:00:00").fromNow();
        }

        if (this.state.data.status === 'urgent') {
            productionDeadlineDisplay = (<div style={{color: 'red', fontWeight: 'bold'}}>URGENT</div>);
        }

        const externalElements = this.getExternalElements();

        const isSuborder = this.state.data.suborder_of !== null;

        return (
            <div className='header'>
                <a href={`/order/${Generate(this.props.data.id)}`} target='_blank'
                   className='orderid'>{Generate(this.props.data.id)}</a>

                <div className='deadline'>{productionDeadlineDisplay}</div>

                {(this.props.external && this.props.external.length) &&

                <PopOverIconRenderer icon='suitcase' color='blue'>
                    {externalElements}
                </PopOverIconRenderer>
                }

                {!isSuborder &&
                <div className='ready'>
                    <Button onClick={this.onReadyToPackClicked}>Ready to Pack</Button>
                </div>
                }
            </div>
        )
    };

    getExternalElements = () => {
        if (!this.props.external || this.props.external.length === 0) {
            return null;
        }

        return this.props.external.map(item => {
            return (
                <ExternalOrder key={Math.random()} data={item}/>
            )
        })
    }

    getItemsElements = () => {
        const items = this.state.data.work_items;
        const order = this.state.data;

        items.sort((a, b) => {
            return a.id - b.id;
        });

        return items.map((item, index) => {
            switch (item.product_type) {
                case 'album':
                    return this.getAlbumEntry(order, item, index);
                case 'guestbook':
                    return this.getGuestbookEntry(order, item, index);
                case 'guestbook-slots':
                    return this.getGuestbookSlotsEntry(order, item, index);
                case 'vow-book':
                    return this.getVowBookEntry(order, item, index);
                case 'classic-album':
                    return this.getClassicAlbumEntry(order, item, index);
                case 'boudoir':
                    return this.getBoudoirEntry(order, item, index);
                case 'boudoir-empty':
                    return this.getBoudoirEmptyEntry(order, item, index);
                case 'corners':
                    return this.getCornersEntry(order, item, index);
                case 'bamboo-box':
                    return this.getBoxEntry(order, item, index);
                case 'pouch':
                    return this.getPouchEntry(order, item, index);
                case 'album-sleeve':
                    return this.getAlbumSleeveEntry(order, item, index);
                case 'premium-box':
                    return this.getPremiumBoxEntry(order, item, index);
                case 'addon':
                    return this.getAddonEntry(order, item, index);
                default:
                    return null;
            }
        })
    };

    getGuestbookEntry = (order, _item, index, stocCover, stocBloc) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let spacers = item.page_spacer === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let corners_white = item.corners_white || 0;
        let corners_black = item.corners_black || 0;
        let corners_kraft = item.corners_kraft || 0;

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0,3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'><u>Rezerva:</u> {spacers}</div>
                <div className='pages'><u>Pagini:</u> {item.page_type.label}</div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>

                {/*<div className='colturi'>*/}
                {/*    <u>Colturi</u>*/}
                {/*    <div>{corners_white} albe</div>*/}
                {/*    <div>{corners_black} negre</div>*/}
                {/*    <div>{corners_kraft} kraft</div>*/}
                {/*</div>*/}
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>

                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus
                        key={this.props.data.id}
                        large
                        data={_item}
                        onUpdateStatus={this.onUpdateStatus}
                        stocCover={_item.stoc_cover}
                        stocMaterial={_item.stoc_material}
                        stocMaterialPotential={_item.stoc_material_potential}
                        stocCoverPotential={_item.stoc_cover_potential}
                        stocBlocPotential={_item.stoc_bloc_potential}
                        stocBloc={_item.stoc_bloc}
                        lockSync={() => this.props.lockSync && this.props.lockSync()}
                        unlockSync={() => this.props.unlockSync && this.props.unlockSync()}
                    />
                </div>
            </div>
        );
    };

    getGuestbookSlotsEntry = (order, _item, index, stocCover, stocBloc) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0,3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'></div>
                <div className='pages'><u>Pagini:</u> {item.page_type.label}</div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>


                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>

                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus
                        key={this.props.data.id}
                        large
                        data={_item}
                        onUpdateStatus={this.onUpdateStatus}
                        stocCover={_item.stoc_cover}
                        stocMaterial={_item.stoc_material}
                        stocMaterialPotential={_item.stoc_material_potential}
                        stocCoverPotential={_item.stoc_cover_potential}
                        stocBlocPotential={_item.stoc_bloc_potential}
                        stocBloc={_item.stoc_bloc}
                        lockSync={() => this.props.lockSync && this.props.lockSync()}
                        unlockSync={() => this.props.unlockSync && this.props.unlockSync()}
                    />
                </div>
            </div>
        );
    };

    getVowBookEntry = (order, _item, index, stocCover, stocBloc) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0,3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'></div>
                <div className='pages'><u>Pagini:</u> {item.page_type.label}</div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>


                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>

                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus
                        key={this.props.data.id}
                        large
                        data={_item}
                        onUpdateStatus={this.onUpdateStatus}
                        stocCover={_item.stoc_cover}
                        stocMaterial={_item.stoc_material}
                        stocMaterialPotential={_item.stoc_material_potential}
                        stocCoverPotential={_item.stoc_cover_potential}
                        stocBlocPotential={_item.stoc_bloc_potential}
                        stocBloc={_item.stoc_bloc}
                        lockSync={() => this.props.lockSync && this.props.lockSync()}
                        unlockSync={() => this.props.unlockSync && this.props.unlockSync()}
                    />
                </div>
            </div>
        );
    };

    getClassicAlbumEntry = (order, _item, index, stocCover, stocBloc) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0,3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>

                {/*<div className='colturi'>*/}
                {/*    <u>Colturi</u>*/}
                {/*    <div>{corners_white} albe</div>*/}
                {/*    <div>{corners_black} negre</div>*/}
                {/*    <div>{corners_kraft} kraft</div>*/}
                {/*</div>*/}
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>

                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus
                        key={this.props.data.id}
                        large
                        data={_item}
                        onUpdateStatus={this.onUpdateStatus}
                        stocCover={_item.stoc_cover}
                        stocMaterial={_item.stoc_material}
                        stocMaterialPotential={_item.stoc_material_potential}
                        stocCoverPotential={_item.stoc_cover_potential}
                        stocBlocPotential={_item.stoc_bloc_potential}
                        stocBloc={_item.stoc_bloc}
                        lockSync={() => this.props.lockSync && this.props.lockSync()}
                        unlockSync={() => this.props.unlockSync && this.props.unlockSync()}
                    />
                </div>
            </div>
        );
    };

    getBoudoirEmptyEntry = (order, _item, index) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let spacers = item.page_spacer === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let corners_white = item.corners_white || 0;
        let corners_black = item.corners_black || 0;
        let corners_kraft = item.corners_kraft || 0;

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'><u>Rezerva:</u> Da</div>
                <div className='pages'></div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>
                <div className='faceoff'><u>Faceoff:</u> {item.faceoff || "nu"}</div>
                {/*<div className='colturi'>*/}
                {/*    <u>Colturi</u>*/}
                {/*    <div>{corners_white} albe</div>*/}
                {/*    <div>{corners_black} negre</div>*/}
                {/*    <div>{corners_kraft} kraft</div>*/}
                {/*</div>*/}
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>

                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        );
    };

    getBoudoirEntry = (order, _item, index) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>
                <div className='faceoff'><u>Faceoff:</u> {item.faceoff || "nu"}</div>
                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };

    getAlbumEntry = (order, _item, index) => {
        let item = _item.item;
        let uv = item.cover_uv === 'yes' ? 'Da' : 'Nu';
        let emboss = (!item.emboss || item.emboss.label === 'no') ? 'Nu' : 'Da';

        let materialCode = this.props.configs.materials.find(m => m.id === item.material.id).internal_code;
        let materialCategory = this.props.configs.materials.find(m => m.id === item.material.id).category;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'>{parseInt(item.nr_pages) / 2} colaje</div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'><u>Coperta:</u> {item.cover_style.value}</div>
                <div className='material' title={`[${materialCategory}] ${item.material.value}`}>
                    <u>Material:</u> {materialCode}</div>
                <div className='uv'><u>Uv:</u> {uv}</div>
                <div className='emboss'><u>Emboss:</u> {emboss}</div>

                <div className='faceoff'><u>Faceoff:</u> {item.faceoff || "nu"}</div>
                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };

    getCornersEntry = (order, _item, index) => {
        return null;
        let item = _item.item;
        let corners_white = item.corners_white || 0;
        let corners_black = item.corners_black || 0;
        let corners_kraft = item.corners_kraft || 0;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'></div>
                <div className='colaje'></div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'></div>
                <div className='material'></div>
                <div className='uv'></div>
                <div className='emboss'></div>

                <div className='colturi'>
                    <u>Colturi</u>
                    <div>{corners_white} albe</div>
                    <div>{corners_black} negre</div>
                    <div>{corners_kraft} kraft</div>
                </div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };

    getBoxEntry = (order, _item, index) => {
        let item = _item.item;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'></div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'></div>
                <div className='material'></div>
                <div className='uv'></div>
                <div className='emboss'></div>

                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };

    getPouchEntry = (order, _item, index) => {
        let item = _item.item;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'></div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'></div>
                <div className='material'></div>
                <div className='uv'></div>
                <div className='emboss'></div>

                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };

    getAlbumSleeveEntry = (order, _item, index) => {
        let item = _item.item;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'></div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'></div>
                <div className='material'><u>Material:</u> {item.material.value}</div>
                <div className='uv'></div>
                <div className='emboss'></div>

                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };

    getPremiumBoxEntry = (order, _item, index) => {
        let item = _item.item;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime'>{item.size.value}</div>
                <div className='colaje'></div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'></div>
                <div className='material'><u>Material:</u> {item.material.value}</div>
                <div className='uv'></div>
                <div className='emboss'></div>

                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };


    getAddonEntry = (order, _item, index) => {
        let item = _item.item;

        const codeSuffix = _item.product_type.substring(0, 3).toUpperCase() + "-" + _item.id;

        return (
            <div key={_item.id} className='row'>
                <div className='id'>{workItemSKU(_item)}</div>
                <div className='type'>{_item.product_type}</div>
                <div className='qty'>{_item.quantity}x</div>
                <div className='marime' style={{width: '250px', fontWeight: 'bold'}}>{_item.invoiceName}</div>
                <div className='colaje'></div>
                <div className='rezerva'></div>
                <div className='pages'></div>
                <div className='coperta'></div>
                <div className='material'></div>
                <div className='uv'></div>
                <div className='emboss'></div>

                <div className='colturi'></div>
                <div className='warning' style={{width: '50px', fontSize: '20px'}}>
                    {_item.details && <PopOverIcon icon='warning sign' text={_item.details}/>}
                </div>
                <div className='fill'/>
                <div className='production-status'>
                    <OrderItemStatus key={this.props.data.id} large data={_item} onUpdateStatus={this.onUpdateStatus}
                                     lockSync={() => this.props.lockSync && this.props.lockSync()}
                                     unlockSync={() => this.props.unlockSync && this.props.unlockSync()}/>
                </div>
            </div>
        )
    };


    onUpdateStatus = (newProgress) => {
        ApiService.getWorkItemById(newProgress.id).then(data => {
            const stateData = {...this.state.data};
            let items = [...stateData.work_items];
            let item = items.filter(i => i.id === newProgress.id)[0];

            item.progress[newProgress.step] = newProgress.value;
            this.setState({data: stateData})

            let up = data.data;
            up.progress[newProgress.step] = newProgress.value;

            ApiService.updateWorkItemStatus(up).then(data => {

            });
        })
    };

    onReadyToPackClicked = () => {
        let readyToPackOrders = [];

        if (this.state.data.linked_orders) {
            readyToPackOrders = [...this.state.data.linked_orders, this.state.data.id]
        } else {
            readyToPackOrders = [this.state.data.id]
        }

        ApiService.markOrdersAsReadyToPack(readyToPackOrders).then(data => {
            this.props.updateData();
        })
    }

    getStocSKUFromItem = (item) => {
        if(item.product_type !== 'guestbook'){
            return {blocSKU: '', coverSKU: ''};
        }

        const hasSpacer = item.item.page_spacer === 'yes';
        let pages = parseInt(item.item.nr_pages) / 2;

        let coverSKU = '';
        if(item.item.cover_style.text !== 'Foto'){
            coverSKU = 'COVER-';
            if(hasSpacer){
                coverSKU += 'R-';
            }

            coverSKU += `M${item.item.material.id}-`;
            coverSKU += `${item.item.size.value}-`;
            coverSKU += `${pages}`;
        }


        let blocSKU = '';
        if(item.item.page_type.label === 'white') {
            blocSKU = 'BLOC-';
            if (hasSpacer) {
                blocSKU += 'R-';
            }

            blocSKU += `${item.item.size.value}-`;
            blocSKU += `${pages}`
        }

        return {blocSKU, coverSKU, item}
    }

}


export default WorkItem;
