import React, {Component} from 'react';
import './OrderAssistant.scss';

class OrderAssistant extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(this.props.errors?.length === 0){
            return null;
        }

        return (
            <div className='OrderAssistant'>
               {this.props.errors ? this.props.errors.join(' / ') : ''}
            </div>
        )
    }

}


export default OrderAssistant;
