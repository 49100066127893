import React, {Component} from 'react';
import {Button, Container, Icon, Input, Modal} from "semantic-ui-react";
import './SalesScreen.scss';
import AddEtsyStatement from "../components/AddEtsyStatement";
import ApiService from "../services/ApiService";
import SalesItem from "../components/SalesItem";

class SalesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            renderLight: true,
            editMode: true,
            invoicesMonth: '',
            incasariMonth: '',
            pdfInvoicesMonth: '',
            invoicesMonthValid: false,
            pdfInvoicesMonthValid: false,
            incasariMonthValid: false,
            calculatorValueItems: {},
            isBlockModalVisible:false,

            selectedSum: 0,
            selectedPossibilities: [],
            selectedItemsForPossibilities: [],
            balancingModalVisible: false,
            sagaXLSXUploading: false,
            uploadedSAGAXLSXFilename: ''
        }

        this.sagaXLSXRef = React.createRef();
        this.changes = {}; // id=>newOrder
    }

    componentDidMount() {
        this.loadInvoiceStatus();
        // this.loadSales();
    }

    render() {
        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <div className='SalesScreen'>

                    {this.state.isBlockModalVisible &&
                    <div className="modal">
                        <div>Se pregatesc facturile...</div>
                    </div>
                    }

                    <div className="item">
                        <div className='title'>Facturi PDF</div>
                        <div className='description'>Descarca toate facturile din luna specificata in format PDF.</div>
                        <div className='actions'>
                            <Button primary icon compact labelPosition='left' disabled={!this.state.pdfInvoicesMonthValid}
                                    onClick={this.onDownloadAllInvoicesClicked}>
                                <Icon name='download'/>
                                Facturi PDF
                            </Button>
                            <Input placeholder='mm.yyyy' onChange={this.onInvMonthPDFChanged}/>
                        </div>
                    </div>

                    <div className="item">
                        <div className='title'>Saga Facturi</div>
                        <div className='description'>Descarca fisier XML cu toate facturile din perioada specificata, pentru importul in SAGA</div>
                        <div className='actions'>
                            <Button primary icon compact labelPosition='left' disabled={!this.state.invoicesMonthValid}
                                    onClick={this.onSagaXmlButtonClicked}>
                                <Icon name='download'/>
                                SAGA Facturi
                            </Button>
                            <Input placeholder='mm.yyyy' onChange={this.onInvMonthChanged}/>
                        </div>
                    </div>

                    <div className="item">
                        <div className='title'>Verificare TVA Facturi</div>
                        <div className='description'>Descarca fisier cu toate facturile din perioada specificata, pentru verificare TVA</div>
                        <div className='actions'>
                            <Button primary icon compact labelPosition='left' disabled={!this.state.invoicesMonthValid}
                                    onClick={this.onVATInvoiceButtonClicked}>
                                <Icon name='download'/>
                                SAGA Facturi
                            </Button>
                            <Input placeholder='mm.yyyy' onChange={this.onInvMonthChanged}/>
                        </div>
                    </div>


                    {/*<div className="item">*/}
                    {/*    <div className='title'>Saga Incasari</div>*/}
                    {/*    <div className='description'>*/}
                    {/*        <div>*/}
                    {/*            Descarca fisier XML cu toate Incasarile din perioada specificata, pentru importul in SAGA*/}
                    {/*        </div>*/}
                    {/*        <br />*/}
                    {/*        <div>1. Incarca fisierul XLS cu toate facturile importate de SAGA, sau asigura-te ca acest fisier este incarcat anterior</div>*/}
                    {/*        <div>2. Descarca fisierul XML cu incasarile din perioada specificata</div>*/}
                    {/*    </div>*/}
                    {/*    <div className='actions'>*/}
                    {/*        <Button primary icon compact labelPosition='left'*/}
                    {/*                disabled={this.state.sagaXLSXUploading}*/}
                    {/*                onClick={() => this.sagaXLSXRef.current.click()}>*/}
                    {/*            <Icon name='upload'/>*/}

                    {/*            {this.state.sagaXLSXUploading ? `Fisier Facturi SAGA: Se incarca...` : `Fisier Facturi SAGA: ${this.state.uploadedSAGAXLSXFilename || 'Nu exista'}`}*/}
                    {/*        </Button>*/}
                    {/*        <input*/}
                    {/*            ref={this.sagaXLSXRef}*/}
                    {/*            type="file"*/}
                    {/*            hidden*/}
                    {/*            onChange={this.onSagaFileSelected}*/}
                    {/*        />*/}

                    {/*        <span style={{marginLeft: '0px'}}/>*/}
                    {/*        <Button primary icon compact labelPosition='left' disabled={!this.state.incasariMonthValid}*/}
                    {/*                onClick={this.onSagaIncasariXmlButtonClicked}>*/}
                    {/*            <Icon name='download'/>*/}
                    {/*            SAGA Incasari*/}
                    {/*        </Button>*/}
                    {/*        <Input size='small' placeholder='mm.yyyy.v' onChange={this.onIncasariMonthChanged}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="item">
                        <div className='title'>Saga Incasari</div>
                        <div className='description'>
                            <div>
                                Descarca fisier XML cu toate Incasarile din perioada specificata, pentru importul in SAGA
                            </div>
                            <br />
                            <div>1. Descarca fisierul XML cu incasarile din perioada specificata</div>
                        </div>
                        <div className='actions'>
                            <Button primary icon compact labelPosition='left'
                                    onClick={this.onSagaIncasariXmlWithoutITButtonClicked}>
                                <Icon name='download'/>
                                SAGA Incasari
                            </Button>
                            <Input size='small' placeholder='mm.yyyy' onChange={this.onIncasariMonthChanged}/>
                        </div>
                    </div>

                    <div style={{display: 'flex', marginBottom: '50px'}}>
                        <div>
                            {/*<Button primary icon compact labelPosition='left'*/}
                            {/*        onClick={this.onAccountantStatementDownloadClicked}>*/}
                            {/*    <Icon name='download'/>*/}
                            {/*    Statement*/}
                            {/*    contabil*/}
                            {/*</Button>*/}

                            {/*<span style={{marginLeft: '15px'}}/>*/}


                            {/*<span style={{marginLeft: '45px'}}/>*/}
                            {/*<Button primary icon compact labelPosition='left'*/}
                            {/*        disabled={this.state.sagaXLSXUploading}*/}
                            {/*        onClick={() => this.sagaXLSXRef.current.click()}>*/}
                            {/*    <Icon name='upload'/>*/}

                            {/*    {this.state.sagaXLSXUploading ? `Fisier Facturi SAGA: Uploading...` : `Fisier Facturi SAGA: ${this.state.uploadedSAGAXLSXFilename || 'Nu exista'}`}*/}
                            {/*</Button>*/}
                            {/*<input*/}
                            {/*    ref={this.sagaXLSXRef}*/}
                            {/*    type="file"*/}
                            {/*    hidden*/}
                            {/*    onChange={this.onSagaFileSelected}*/}
                            {/*/>*/}

                            {/*<span style={{marginLeft: '0px'}}/>*/}
                            {/*<Button primary icon compact labelPosition='left' disabled={!this.state.incasariMonthValid}*/}
                            {/*        onClick={this.onSagaIncasariXmlButtonClicked}>*/}
                            {/*    <Icon name='download'/>*/}
                            {/*    SAGA Incasari*/}
                            {/*</Button>*/}
                            {/*<Input size='small' placeholder='mm.yyyy' onChange={this.onIncasariMonthChanged}/>*/}
                        </div>
                    </div>

                </div>
            </Container>
        );
    }

    onDownloadAllInvoicesClicked = async () => {
        this.setState({isBlockModalVisible: true}, async ()=>{
            await this.onDownloadAllInvoicesClickedAction();
            this.setState({isBlockModalVisible: false})
        })
    }

    onDownloadAllInvoicesClickedAction = async () => {
        return new Promise(async resolve=>{
            let invoiceToDownload = [];

            let invoices = await ApiService.getAllInvoices();
            invoices = invoices.data;

            for (let i = 0; i < invoices.length; i++) {
                let inv = invoices[i];

                let invD = inv.date.split(' ')[0];
                invD = invD.split('-');
                let invM = invD[1];
                let invY = invD[0];

                let reqD = this.state.pdfInvoicesMonth.split('.');
                let reqM = reqD[0];
                let reqY = reqD[1];

                if (invM.toString() === reqM.toString() && invY.toString() === reqY.toString()) {
                    invoiceToDownload.push(inv.id);
                }
            }

            for (let i = 0; i < invoiceToDownload.length; i++) {
                await this.downloadInvoiceById(invoiceToDownload[i]);
            }

            return resolve();
        })
    };

    downloadInvoiceById = (id) => {
        return new Promise(resolve => {
            ApiService.downloadInvoiceById(id).then(data => {
                return resolve();
            })
        })
    };

    loadInvoiceStatus = async () => {
        const data = await ApiService.uploadSAGAInvoicesStatus();

        this.setState({
            uploadedSAGAXLSXFilename: data.data.filename,
            sagaXLSXUploading: false
        })
    }

    onAccountantStatementDownloadClicked = () => {
        ApiService.downloadAccountantStatement();
    };

    onSagaXmlButtonClicked = () => {
        ApiService.downloadSagaXML(this.state.invoicesMonth);
    };

    onVATInvoiceButtonClicked = () => {
        ApiService.downloadVATInvoiceButtonClicked(this.state.invoicesMonth);
    };



    onSagaIncasariXmlButtonClicked = () => {
        ApiService.downloadSagaIncasariXML(this.state.incasariMonth);
    };

    onSagaIncasariXmlWithoutITButtonClicked = () => {
        ApiService.downloadSagaIncasariWithoutITXML(this.state.incasariMonth);
    };

    onSagaFileSelected = (e) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        this.setState({sagaXLSXUploading: true}, async () => {
            await ApiService.uploadSAGAInvoices(data);

            this.loadInvoiceStatus();
        })

        // this.setState({loadingImage: true}, () => {
        //     ApiService.uploadImage('material', data).then(r => {
        //         let meta = {...this.state.meta};
        //         meta[`image${type}`] = r.data.image;
        //
        //         this.setState({
        //             loading: false,
        //             meta: meta
        //         })
        //     });
        // })
        // console.log(e)
    }

    getItems = () => {
        if (this.state.statementMode) {
            return null;
        }

        if (this.state.data.length === 0) {
            return null;
        }

        let elements = [];
        let soFarTotal = 0;
        let sector = 1;

        let items = this.state.data;

        if (!this.firstRun) {
            items = this.state.data.sort((a, b) => {
                return a.sector - b.sector;
            })
        }

        let agg = [];
        let aggItems = [];

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            const shouldRender = (items.length - i) < 5000;

            if (this.firstRun) {
                item.sector = sector;
                item.id = i;
            }
            if (item.type === 'Deposit') {

                soFarTotal = Math.round(soFarTotal * 100) / 100;
                let diff = soFarTotal - item.amount;
                diff = Math.round(diff * 100) / 100;


                if (shouldRender) {
                    elements.push(<SalesItem key={Math.random()} data={{type: `ST`, amount: soFarTotal}}
                                             moveItem={this.moveItem} editMode={this.state.editMode} diff={diff}
                                             agg={[...agg].reverse()} aggItems={[...aggItems].reverse()}
                                             renderLight={this.state.renderLight} onClick={this.brute}/>)
                    elements.push(<SalesItem key={item.id} data={item} moveItem={this.moveItem}
                                             renderLight={this.state.renderLight} editMode={this.state.editMode}/>)
                }
                agg = [];
                aggItems = [];

                let color = 'red';
                if (diff === 0) {
                    color = 'white'
                }
                if (shouldRender) {
                    elements.push(<div key={Math.random()}
                                       style={{marginBottom: '10px', padding: '5px', color: color}}>Diff: {diff}</div>)
                }

                soFarTotal = 0;
                sector += 1;
            } else {
                if (item.subtype !== 'paid-by-buyer' && item.subtype !== 'processing') {
                    soFarTotal += item.net;
                }

                if (this.state.renderLight) {
                    if (item.type === 'Sale') {
                        if (shouldRender) {
                            agg.push(item.net);
                            aggItems.push(item);
                            elements.push(<SalesItem key={item.id} data={item} moveItem={this.moveItem}
                                                     moveItemUp={this.moveItemUp}
                                                     calcAdd={(item) => this.calcAdd(item)}
                                                     calcSub={(item) => this.calcSub(item)}
                                                     renderLight={this.state.renderLight}
                                                     editMode={this.state.editMode}/>)
                        }
                    }
                } else {
                    if (shouldRender) {
                        elements.push(<SalesItem onClick={() => console.log(item)} key={item.id} data={item}
                                                 moveItem={this.moveItem}
                                                 renderLight={this.state.renderLight} editMode={this.state.editMode}/>)
                    }
                }

            }
        }

        this.firstRun = false;

        return elements;
    };

    calcAdd = (item) => {
        let items = {...this.state.calculatorValueItems}
        items[item.id] = item;
        this.doCalc(items);
        this.setState({calculatorValueItems: items})
    }


    brute = (diff, agg, items) => {
        let testArray = [];
        let subset = [];

        console.log(diff, agg)

        for (let i = 0; i < agg.length; i++) {
            if (subset.length) {
                continue;
            }


            testArray.push(agg[i]);
            let result = this.subsetSum(testArray, diff);
            if (result.length) {
                console.log(result)
                subset = result;
            }

            // if (result.length) {
            //     // console.log(result)
            //     // subset = result;
            //
            //
            //
            //     testArray.push(agg[i + 1]);
            //     testArray.push(agg[i + 2]);
            //     testArray.push(agg[i + 3]);
            //     testArray.push(agg[i + 4]);
            //     result = this.subsetSum(testArray, diff);
            //
            //     if (result.length) {
            //
            //         subset = [...subset, ...result];
            //     }
            // }
        }

        let itemsToMove = [];
        subset.forEach(value => {
            let itemToMove = items.find(it => it.net === value);
            itemsToMove.push(itemToMove)
        })

        this.setState({
            selectedSum: diff,
            selectedPossibilities: subset,
            selectedItemsForPossibilities: items,
            balancingModalVisible: true
        })

        // this.moveItemsBulkAuto(itemsToMove);
    }

    moveItemsBulkAuto = async (items) => {
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            await this.moveItem(item.item_order, item.id);
        }

        this.setState({
            selectedSum: 0,
            selectedPossibilities: [],
            selectedItemsForPossibilities: [],
            balancingModalVisible: false
        })
    }

    subsetSum = (numbers, target, partial, results = []) => {
        let s, n, remaining;


        partial = partial || [];

        // sum partial
        s = partial.reduce((a, b) => {
            return a + b;
        }, 0);

        // check if the partial sum is equals to target
        if (s === target) {
            results.push(partial);
        }

        if (s >= target) {

            return; // if we reach the number why bother to continue
        }

        for (let i = 0; i < numbers.length; i++) {
            n = numbers[i];
            remaining = numbers.slice(i + 1);
            this.subsetSum(remaining, target, partial.concat([n]), results);
        }


        return results;

    }

    calcReset = () => {
        let items = {}
        this.doCalc(items);
        this.setState({calculatorValueItems: items})
    }

    moveBulk = async () => {
        let items = {...this.state.calculatorValueItems}
        items = Object.values(items);

        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            await this.moveItem(item.item_order, item.id);
        }

        this.calcReset();
    }

    calcSub = (item) => {
        let items = {...this.state.calculatorValueItems}
        delete items[item.id];
        this.doCalc(items);
        this.setState({calculatorValueItems: items})
    }

    doCalc = (items) => {
        let sum = 0;
        for (let key in items) {
            sum += items[key].net
        }

        console.log(sum)
    }

    moveItem = (item_order, id) => {
        return new Promise(resolve => {


            let newOrder = 0;
            let depositId = 0;

            for (let i = 0; i < this.state.data.length; i++) {
                let a = this.state.data[i];
                if (a.item_order > item_order && a.type === 'Deposit') {
                    newOrder = a.item_order + 1;
                    depositId = a.id;
                    break;
                }
            }

            let dataSet = [...this.state.data];

            dataSet.sort((a, b) => {
                return a.item_order - b.item_order;
            })

            let itemsToChange = dataSet.filter(item => item.item_order > item_order && item.item_order <= newOrder);

            for (let i = 0; i < itemsToChange.length; i++) {
                let item = itemsToChange[i];

                this.changes[item.id] = item.item_order - 1;

                item.item_order -= 1;
            }

            let item = dataSet.find(it => it.id === id);
            item.item_order = newOrder;

            this.changes[id] = newOrder;

            dataSet.sort((a, b) => {
                return a.item_order - b.item_order;
            })

            this.setState({data: dataSet}, () => {
                return resolve();
            })
        })
    }

    onInvMonthChanged = (e, {value}) => {
        let valid = false;
        if (value && value.length) {
            let a = value.split('.');
            if (a.length === 2 && a[1].length === 4) {
                valid = true;
            }
        }

        this.setState({
            invoicesMonth: value,
            invoicesMonthValid: valid
        })
    }

    onInvMonthPDFChanged = (e, {value}) => {
        let valid = false;
        if (value && value.length) {
            let a = value.split('.');
            if (a.length === 2 && a[1].length === 4) {
                valid = true;
            }
        }

        this.setState({
            pdfInvoicesMonth: value,
            pdfInvoicesMonthValid: valid
        })
    }


    onIncasariMonthChanged = (e, {value}) => {
        let valid = false;
        if (value && value.length) {
            let a = value.split('.');
            if (a.length === 2 && a[1].length === 4) {
                valid = true;
            }
        }

        if (this.state.uploadedSAGAXLSXFilename.length < 2) {
            valid = false
        }

        this.setState({
            incasariMonth: value,
            incasariMonthValid: valid
        })
    }

    onSaveClicked = () => {
        let updateArray = [];
        for (let id in this.changes) {
            updateArray.push({id: id, item_order: this.changes[id]})
        }

        this.changes = {};
        ApiService.salesSetItemOrder(updateArray).then(() => {
            ApiService.getSalesData().then(data => {
                this.setState({data: data.data})
            })
        })
    }

    loadSales = () => {
        ApiService.getSalesData().then(data => {
            this.setState({data: data.data})
        })
    }

}


export default SalesScreen;
