import React, {Component} from 'react';
import {Checkbox, Container, Dimmer, Image, Input, Item, Loader, Modal, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import BambooBoxConfig from "./BambooBoxConfig";
import CornerConfig from "./CornerConfig";
import SalesItem from "./SalesItem";

class ClassicAlbumCoverSizeConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: false,
            sortDirection: '',
            editingItem: null,
            sortingColumn: 'size',
            data: [
                {
                    id: 1,
                    size: '20x30',
                    spreads: 30,
                    provider: 'China'
                },
                {
                    id: 2,
                    size: '30x30',
                    spreads: 35,
                    provider: 'Europe',
                    available: true
                }
            ]
        };
    }

    componentDidMount() {
      this.loadData();
    }

    render() {
        const {sortDirection, sortingColumn} = this.state;

        const sortedData = this.sortByParams();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                {this.state.loading &&
                <div style={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    background: 'white',
                    position: 'fixed',
                    opacity: '0.4'
                }}>
                <Loader active={this.state.loading}/>
                </div>
                }

                <Modal open={this.state.editingItem !== null} onClose={()=>this.setState({editingItem: null})}>
                    <Modal.Header>Edit {this.state.editingItem?.size} cm | {this.state.editingItem?.spreads} spreads | from {this.state.editingItem?.provider}</Modal.Header>
                    <Modal.Content>
                        <Input style={{padding: '1rem'}} size='mini' name='size' label='size' placeholder='20x30'
                        value={this.state.editingItem?.size}
                               onChange={(e, {value})=>this.setState({editingItem: {...this.state.editingItem, size: value}})}
                        />
                        <Input style={{padding: '1rem'}} size='mini' name='spreads' label='spreads' placeholder='25'
                               value={this.state.editingItem?.spreads}
                               onChange={(e, {value})=>this.setState({editingItem: {...this.state.editingItem, spreads: value}})}
                        />
                        <Input style={{padding: '1rem'}} size='mini' name='provider' label='provider' placeholder='China'
                               value={this.state.editingItem?.provider}
                               onChange={(e, {value})=>this.setState({editingItem: {...this.state.editingItem, provider: value}})}
                        />
                        <Checkbox label='available' name='available' toggle checked={this.state.editingItem?.available}
                                  onChange={(e, {name, checked})=> this.setState({editingItem: {...this.state.editingItem, available: checked}})}
                        />


                    </Modal.Content>
                    <Modal.Actions><Button type="primary" onClick={this.onItemEditSaveClicked}>Save</Button></Modal.Actions>
                </Modal>

                <Table sortable celled fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={sortingColumn === 'size' ? sortDirection : null}
                                onClick={() => this.setState({sortingColumn: 'size', sortDirection: this.state.sortDirection  === 'ascending' ? 'descending' : 'ascending'})}
                            >
                                Size
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortingColumn === 'spreads' ? sortDirection : null}
                                onClick={() => this.setState({sortingColumn: 'spreads', sortDirection: this.state.sortDirection  === 'ascending' ? 'descending' : 'ascending'})}
                            >
                                Spreads
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortingColumn === 'provider' ? sortDirection : null}
                                onClick={() => this.setState({sortingColumn: 'provider', sortDirection: this.state.sortDirection  === 'ascending' ? 'descending' : 'ascending'})}
                            >
                                Provider
                            </Table.HeaderCell>
                            <Table.HeaderCell>Available</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sortedData.map((item) => {
                            const availableStyle = {
                                width: '20px',
                                height: '20px',
                                margin: '0 auto'
                            }

                            if (item.available) {
                                availableStyle.background = '#4caf50';
                            } else {
                                availableStyle.background = '#f44336';
                            }

                            return (
                            <Table.Row key={item.id} onClick={()=>this.setState({editingItem: item})}>
                                <Table.Cell>{item.size}</Table.Cell>
                                <Table.Cell>{item.spreads}</Table.Cell>
                                <Table.Cell>{item.provider}</Table.Cell>
                                <Table.Cell>
                                    <div style={availableStyle}/>
                                </Table.Cell>
                            </Table.Row>
                        )})}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell colSpan='3'>
                                <Button
                                    onClick={()=>this.setState({editingItem: {}})}
                                    primary
                                    size='small'>
                                   Add bloc
                                </Button>

                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Container>
        );
    }

    loadData = () => {
        this.setState({
            loading: true
        }, () => {
            ApiService.getProductClassicAlbumBlocs().then(data => {
                this.setState({loading: false, data: data.data});
            });
        });
    }

    onItemEditSaveClicked = async () => {
        const newItem = {...this.state.editingItem};

        this.setState({loading: true, editingItem: null});

        ApiService.editClassicAlbumBloc(newItem).then(data=>{
            this.loadData();
        })
    }

    wait = () => {
        return new Promise(resolve => {
            setTimeout(()=>{
                resolve();
            }, 1000)
        })
    }

    sortByParams = () => {
        const {sortingColumn, sortDirection} = this.state;

        let data = [...this.state.data];
        data = data.sort((a, b) => {
            if (sortDirection === 'ascending') {
                return a[sortingColumn].toString().localeCompare(b[sortingColumn].toString());
            } else {
                return b[sortingColumn].toString().localeCompare(a[sortingColumn].toString());
            }
        })

        return data;
    }

}


export default ClassicAlbumCoverSizeConfig;
