import React, {Component} from 'react';
import {Button, Modal, Table} from "semantic-ui-react";
import StocVsMissingCoverSpacer from "./missing/StocVsMissingCoverSpacer";
import StocVsMissingBlocSpacer from "./missing/StocVsMissingBlocSpacer";
import StocVsMissingBloc from "./missing/StocVsMissingBloc";
import StocVsMissingCover from "./missing/StocVsMissingCover";
import StocVsMissingCorners from "./missing/StocVsMissingCorners";
import StocVsMissingPen from "./missing/StocVsMissingPen";
import StocVsMissingClassicCover from "./missing/StocVsMissingClassicCover";
import StocVsMissingClassicBloc from "./missing/StocVsMissingClassicBloc";

class StocStatisticsRequiredVsAvailable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalMode: ''
        }
    }

    render() {
        let collapseBySKU = {};

        let blocSpacer = {r: 0, a: 0, m: 0, i:0};
        let blocClassic = {r: 0, a: 0, m: 0, i:0};
        let bloc = {r: 0, a: 0, m: 0, i:0};
        let coverSpacer = {r: 0, a: 0, m: 0, i:0};
        let coverClassic = {r: 0, a: 0, m: 0, i:0};
        let cover = {r: 0, a: 0, m: 0, i:0};
        let corners = {r: 0, a: 0, m: 0, i:0};
        let pen = {r: 0, a: 0, m: 0, i:0};

        let items = [...this.props.items];

        items.forEach(item => {
            if (!collapseBySKU[item.sku]) {
                collapseBySKU[item.sku] = {q:0, type: item.type};
            }
            collapseBySKU[item.sku].q += item.quantity
        })

        for (let sku in collapseBySKU) {
            let amount = collapseBySKU[sku].q;
            let type = collapseBySKU[sku].type;
            let inStoc = this.getAmountBySKU(sku);

            switch(type){
                case 'bloc-r':
                    blocSpacer.r += amount;
                    blocSpacer.a += inStoc >= amount ? amount : inStoc;
                    blocSpacer.i += inStoc;
                    break;
                case 'bloc-c':
                    blocClassic.r += amount;
                    blocClassic.a += inStoc >= amount ? amount : inStoc;
                    blocClassic.i += inStoc;
                    break;
                case 'bloc':
                    bloc.r += amount;
                    bloc.a += inStoc >= amount ? amount : inStoc;
                    bloc.i += inStoc;
                    break;
                case 'cover-r':
                    coverSpacer.r += amount;
                    coverSpacer.a += inStoc >= amount ? amount : inStoc;
                    coverSpacer.i += inStoc;
                    break;
                case 'cover-c':
                    coverClassic.r += amount;
                    coverClassic.a += inStoc >= amount ? amount : inStoc;
                    coverClassic.i += inStoc;
                    break;
                case 'cover':
                    cover.r += amount;
                    cover.a += inStoc >= amount ? amount : inStoc;
                    cover.i += inStoc;
                    break;
                case 'corner':
                    corners.r += amount;
                    corners.a += inStoc >= amount ? amount : inStoc;
                    corners.i += inStoc;
                    break;
                case 'pen':
                    pen.r += amount;
                    pen.a += inStoc >= amount ? amount : inStoc;
                    pen.i += inStoc;
                    break;
            }

        }

        if (blocSpacer.r > blocSpacer.a) {
            blocSpacer.m = blocSpacer.r - blocSpacer.a;
        }

        if (blocClassic.r > blocClassic.a) {
            blocClassic.m = blocClassic.r - blocClassic.a;
        }

        if (bloc.r > bloc.a) {
            bloc.m = bloc.r - bloc.a;
        }

        if (coverSpacer.r > coverSpacer.a) {
            coverSpacer.m = coverSpacer.r - coverSpacer.a;
        }

        if (coverClassic.r > coverClassic.a) {
            coverClassic.m = coverClassic.r - coverClassic.a;
        }

        if (cover.r > cover.a) {
            cover.m = cover.r - cover.a;
        }

        if (corners.r > corners.a) {
            corners.m = corners.r - corners.a;
        }

        if (pen.r > pen.a) {
            pen.m = pen.r - pen.a;
        }

        let missingItems = this.getMissingItems();


        let totals = {}

        this.props.stoc.forEach(item=>{
            if(!totals[item.type]){
                totals[item.type] = 0
            }

            totals[item.type] += item.quantity;
        });

        return (
            <div>
                <Table celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Required/Available</Table.HeaderCell>
                            <Table.HeaderCell>Bloc Spacer</Table.HeaderCell>
                            <Table.HeaderCell>Cover Spacer</Table.HeaderCell>
                            <Table.HeaderCell>Bloc</Table.HeaderCell>
                            <Table.HeaderCell>Cover</Table.HeaderCell>
                            <Table.HeaderCell>Corners</Table.HeaderCell>
                            <Table.HeaderCell>Pens</Table.HeaderCell>
                            <Table.HeaderCell>Bloc album classic</Table.HeaderCell>
                            <Table.HeaderCell>Cover album classic</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Required</Table.Cell>
                            <Table.Cell>{blocSpacer.r}</Table.Cell>
                            <Table.Cell>{coverSpacer.r}</Table.Cell>
                            <Table.Cell>{bloc.r}</Table.Cell>
                            <Table.Cell>{cover.r}</Table.Cell>
                            <Table.Cell>{corners.r}</Table.Cell>
                            <Table.Cell>{pen.r}</Table.Cell>
                            <Table.Cell>{blocClassic.r}</Table.Cell>
                            <Table.Cell>{coverClassic.r}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Available</Table.Cell>
                            <Table.Cell>{blocSpacer.a}</Table.Cell>
                            <Table.Cell>{coverSpacer.a}</Table.Cell>
                            <Table.Cell>{bloc.a}</Table.Cell>
                            <Table.Cell>{cover.a}</Table.Cell>
                            <Table.Cell>{corners.a}</Table.Cell>
                            <Table.Cell>{pen.a}</Table.Cell>
                            <Table.Cell>{blocClassic.a}</Table.Cell>
                            <Table.Cell>{coverClassic.a}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Missing</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('blocSpacer')} selectable
                                        style={{paddingLeft: '10px'}}
                                        error={blocSpacer.m > 0}>{blocSpacer.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('coverSpacer')} selectable
                                        style={{paddingLeft: '10px'}}
                                        error={coverSpacer.m > 0}>{coverSpacer.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('bloc')} selectable
                                        style={{paddingLeft: '10px'}} error={bloc.m > 0}>{bloc.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('cover')} selectable
                                        style={{paddingLeft: '10px'}} error={cover.m > 0}>{cover.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('corners')} selectable
                                        style={{paddingLeft: '10px'}} error={corners.m > 0}>{corners.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('pen')} selectable
                                        style={{paddingLeft: '10px'}} error={pen.m > 0}>{pen.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('blocClassic')} selectable
                                        style={{paddingLeft: '10px'}}
                                        error={blocClassic.m > 0}>{blocClassic.m}</Table.Cell>
                            <Table.Cell onClick={() => this.onCellClicked('coverClassic')} selectable
                                        style={{paddingLeft: '10px'}}
                                        error={coverClassic.m > 0}>{coverClassic.m}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Totals</Table.Cell>
                            <Table.Cell>{totals['bloc-r']}</Table.Cell>
                            <Table.Cell>{totals['cover-r']}</Table.Cell>
                            <Table.Cell>{totals['bloc']}</Table.Cell>
                            <Table.Cell>{totals['cover']}</Table.Cell>
                            <Table.Cell>{totals['corner']}</Table.Cell>
                            <Table.Cell>{totals['pen']}</Table.Cell>
                            <Table.Cell>{totals['bloc-c']}</Table.Cell>
                            <Table.Cell>{totals['cover-c']}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Modal
                    open={this.state.modalMode.length > 0}>
                    <Modal.Header>Missing {this.state.modalMode}</Modal.Header>
                    <Modal.Content image>
                        <Modal.Description>
                            {this.renderMissing(missingItems)}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => this.setState({modalMode: ''})}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }

    onCellClicked = (type) => {
        this.setState({modalMode: type})
    }

    renderMissing = (items) => {
        switch(this.state.modalMode){
            case 'coverSpacer':
                return <StocVsMissingCoverSpacer items={items} materials={this.props.materials}/>
            case 'blocClassic':
                return <StocVsMissingClassicBloc items={items}/>
            case 'coverClassic':
                return <StocVsMissingClassicCover items={items} materials={this.props.materials}/>
            case 'blocSpacer':
                return <StocVsMissingBlocSpacer items={items}/>
            case 'bloc':
                return <StocVsMissingBloc items={items}/>
            case 'cover':
                return <StocVsMissingCover items={items} materials={this.props.materials}/>
            case 'corners':
                return <StocVsMissingCorners items={items}/>
            case 'pen':
                return <StocVsMissingPen items={items}/>
        }

    }

    getMissingItems = () => {
        let missing = [];
        let currentStoc = [...this.props.stoc];
        let stoc = [];

        if(this.props.items.length === 0){
            return [];
        }

        currentStoc.forEach(item=>{
            stoc.push({...item})
        })

        let items = [...this.props.items];

        if(items.length === 0){
            return [];
        }

        if(this.state.modalMode === 'blocSpacer'){
            items = items.filter(item => {
                return item.type === 'bloc-r'
            })
        }

        if(this.state.modalMode === 'blocClassic'){
            items = items.filter(item => {
                return item.type === 'bloc-c'
            })
        }

        if(this.state.modalMode === 'bloc'){
            items = items.filter(item => {
                return item.type === 'bloc'
            })
        }

        if(this.state.modalMode === 'coverSpacer'){
            items = items.filter(item => {
                return item.type === 'cover-r'
            })
        }

        if(this.state.modalMode === 'coverClassic'){
            items = items.filter(item => {
                return item.type === 'cover-c'
            })
        }

        if(this.state.modalMode === 'cover'){
            items = items.filter(item => {
                return item.type === 'cover'
            })
        }

        if(this.state.modalMode === 'corners'){
            items = items.filter(item => {
                return item.type === 'corner'
            })
        }

        if(this.state.modalMode === 'pen'){
            items = items.filter(item => {
                return item.type === 'pen'
            })
        }

        items.forEach(item=>{
            if(item.deadline){
                let parts = item.deadline.split('-');
                item.sortDeadline = `${parts[2]}-${parts[1]}-${parts[0]}`
            } else {
                item.sortDeadline = ''
            }
        })

        items = items.sort((a,b)=>a.sortDeadline.localeCompare(b.sortDeadline));


        for(let i=0; i<items.length; i++){
            let item = {...items[i]};

            let inStoc = this.getAmountBySKUFromShadowStoc(item.sku, stoc);
            if(inStoc >= item.quantity){
                this.useAmountBySKUFromShadowStoc(item.sku, stoc, item.quantity);
            } else {
                missing.push(item)
            }
        }
        return missing;
    }

    getAmountBySKUFromShadowStoc = (sku, stoc) => {
        let item = stoc.find(i => i.sku === sku);
        if (item) {
            return item.quantity;
        } else {
            return 0;
        }
    }

    useAmountBySKUFromShadowStoc = (sku, stoc, amount) => {
        let item = stoc.find(i => i.sku === sku);
        if (item) {
            item.quantity -= amount
        }
    }

    getAmountBySKU = (sku) => {
        let item = this.props.stoc.find(i => i.sku === sku);
        if (item) {
            return item.quantity;
        } else {
            return 0;
        }
    }

}

export default StocStatisticsRequiredVsAvailable;
