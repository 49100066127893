import React, {Component} from 'react';
import './OrderItemStatusButton.scss';

class OrderItemStatusButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {type, inStock, inStockSKU, value, needsToBeInStock, needsToBeInStockSKU, span} = this.props;

        let className = "";

        if (value === 0) {
            className = "OrderItemStatusButton red";
        } else {
            className = "OrderItemStatusButton green";
        }

        if (inStock && value === 0) {
            className = "OrderItemStatusButton blue";
        }

        return (
            <div className={className} onClick={this.onButtonClicked}>
                {type}
                {span &&
                    <span className={'span'}>{span}</span>
                }
            </div>
        );
    }

    onButtonClicked = () => {
        const {type, inStock, inStockSKU, value, needsToBeInStock, needsToBeInStockSKU, span, onToggle, onShowModal} = this.props;
        onToggle({action: 'toggle', value: value === 1 ? 0 : 1, step: type});

        if (value === 0) {
            if (!needsToBeInStock) {
            } else {
                if (inStock) {
                    onShowModal({step: type, action: "in_stock", sku: inStockSKU, buildSKU: needsToBeInStockSKU})
                } else {
                    onShowModal({step: type, action: "must_build", buildSKU:needsToBeInStockSKU})
                }
            }
        }

        console.log("Clicked", {type}, {value}, {inStock}, {inStockSKU}, {needsToBeInStock}, {needsToBeInStockSKU})
    }
}

export default OrderItemStatusButton;
