import React, {Component} from 'react';
import './ItemWeight.scss';
import {Button, Input} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class ItemWeight extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weight: ''
        }
    }

    render() {

        return (
            <div className='ItemWeight'>
                <div className="name">{this.props.name}</div>
                <Input placeholder='0.100' name='name' value={this.state.weight}
                       onChange={this.handleChange}/>
                <div style={{marginLeft: '3px', fontWeight:'bold', marginRight:'20px'}}>Kg</div>
                <Button primary onClick={this.onSaveClicked}>save</Button>
            </div>
        );
    }

    onSaveClicked = async () => {
        await ApiService.addOrderPieceWeight({
            key: this.props.itemKey,
            weight: this.state.weight
        })
        window.location.reload();
    }

    handleChange = e => {
        this.setState({weight: e.target.value})
    }

}


export default ItemWeight;
