import React, {Component} from 'react';
import {Button, Checkbox, Container, Icon, Image, Input, Item, Label, Table} from "semantic-ui-react";
import Utils from "../data/Utils";

class SizeConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: this.props.data.editing,

            cm: this.props.data.cm,
            cm_n: this.props.data.cm_n,
            inch: this.props.data.inch,
            available: this.props.data.available === 1,
            ratio: this.props.data.ratio,
            aspect: this.props.data.aspect,
            meta: this.props.data.meta || {},
            type: this.props.data.type
        }

    }

    render() {
        const item = this.props.data;

        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }

        if (this.state.editing) {
            return (
                <Table.Row key={item.id}>
                    <Table.Cell>
                        {this.props.data.id === -1 &&
                        <Input label='cm' placeholder='10x10' name='cm' value={this.state.cm}
                               onChange={this.handleChange}/>
                        }
                        {this.props.data.id !== -1 &&
                        <Table.Cell>
                            {item.cm_n}
                            <br/>
                            <br/>
                            <div style={{fontSize: '12px', textAlign: 'end', color: 'gray', margin: '16px 0 0 0'}}>Emboss margins in mm:</div>
                        </Table.Cell>
                        }
                    </Table.Cell>

                    <Table.Cell>
                        <Input style={{width:'100px'}} label='cm' placeholder='10x10' name='cm_n' value={this.state.cm_n} onChange={this.handleChange}/>
                        <br/>
                        <br/>
                        <Input style={{width:'70px'}} label='emb top' placeholder='1' name='embossMarginTop' value={this.state.meta.embossMarginTop} onChange={this.handleChange}/>
                    </Table.Cell>

                    <Table.Cell>
                        <Input style={{width:'100px'}} label='inch' placeholder='10x10' name='inch' value={this.state.inch} onChange={this.handleChange}/>
                        <br/>
                        <br/>
                        <Input style={{width:'70px'}} label='emb right' placeholder='1' name='embossMarginRight' value={this.state.meta.embossMarginRight} onChange={this.handleChange}/>
                    </Table.Cell>

                    <Table.Cell>
                        <Input style={{width:'100px'}} label='ratio' placeholder='1:1' name='ratio' value={this.state.ratio} onChange={this.handleChange}/>
                        <br/>
                        <br/>
                        <Input style={{width:'70px'}} label='emb bottom' placeholder='1' name='embossMarginBottom' value={this.state.meta.embossMarginBottom} onChange={this.handleChange}/>
                    </Table.Cell>

                    <Table.Cell>
                        <Input style={{width:'100px'}} label='aspect' placeholder='vertical' name='aspect' value={this.state.aspect} onChange={this.handleChange}/>
                        <br/>
                        <br/>
                        <Input style={{width:'70px'}} label='emb left' placeholder='1' name='embossMarginLeft' value={this.state.meta.embossMarginLeft} onChange={this.handleChange}/>
                    </Table.Cell>

                    <Table.Cell><Checkbox label='available' name='available' toggle checked={this.state.available}
                                          onChange={this.onCheckboxChanged}/></Table.Cell>
                    <Table.Cell>
                        <div style={{margin: '0 auto', width: 'fit-content'}}>
                            <Button basic onClick={this.onSaveClicked}>save</Button>
                        </div>
                    </Table.Cell>
                </Table.Row>
            )
        }

        return (
            <Table.Row key={item.id}>
                <Table.Cell>{item.cm}</Table.Cell>
                <Table.Cell>{item.cm_n}</Table.Cell>
                <Table.Cell>{item.inch}</Table.Cell>
                <Table.Cell>{item.ratio}</Table.Cell>
                <Table.Cell>{item.aspect}</Table.Cell>
                <Table.Cell>
                    <div style={availableStyle}></div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onSavedClicked({
                id: this.props.data.id,
                cm: this.state.cm,
                cm_n: this.state.cm_n,
                inch: this.state.inch,
                available: this.state.available,
                ratio: this.state.ratio,
                aspect: this.state.aspect,
                meta: this.state.meta,
                type: this.state.type
            })
        });

    };

    handleChange = (e, {name, value}) => {
        switch(name){
            case 'embossMarginTop':
            case 'embossMarginRight':
            case 'embossMarginBottom':
            case 'embossMarginLeft':
                let meta = {...this.state.meta};
                meta[name] = value;
                this.setState({meta: meta});
                break;
            default:
                this.setState({[name]: value})
                break;
        }

    };
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

}


export default SizeConfig;
