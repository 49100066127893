import React, {Component} from 'react';
import './PendingOrdersScreen.scss';
import {
    Button,
    Container, Dimmer, Loader,
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PendingOrder from "../components/PendingOrder";

class PendingOrdersScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            dataLoading: false
        }
    }

    componentDidMount() {
        ApiService.getPendingOrders().then(data => {
            this.setState({orders: data.data.reverse()})
        })
    }

    render() {
        const ordersElements = this.getPendingOrdersElement();

        return (
            <Container style={{margin: '3rem 0'}} className='PendingOrdersScreen'>
                <Dimmer active={this.state.dataLoading} page>
                    <Loader/>
                </Dimmer>
                {ordersElements}
            </Container>
        );
    }

    getPendingOrdersElement = () => {
        if(this.state.orders.length === 0){
            return null;
        }

        return this.state.orders.map(order=>{
            return (
                <PendingOrder key={order.id} order={order} onManualApproveClicked={this.onManualApproveClicked}/>
            )
        })
    }

    onManualApproveClicked = (orderId) => {
        this.setState({
            dataLoading: true
        }, ()=>{
            ApiService.manualAppropveOrder(orderId).then(data=>{
                window.location.reload();
            })
        })
    }
}


export default PendingOrdersScreen;
