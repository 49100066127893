import React, {Component} from 'react';
import './OrderScreen.scss';

import ApiService from "../services/ApiService";
import OrderUser from "../components/order/OrderUser";
import OrderShipping from "../components/order/OrderShipping";
import {toast} from "react-toastify";
import moment from "moment-timezone";
import OrderFinance from "../components/order/OrderFinance";
import {Button, Menu, Tab} from "semantic-ui-react";
import OrderItem_Items from "../components/OrderItem_Items";
import OrderItem_Awb from "../components/OrderItem_Awb";
import OrderTimeline from "../components/order/OrderTimeline";
import OrderProduction from "../components/order/OrderProduction";
import * as queryString from 'query-string';
import OrderStatusToggler from "../components/order/OrderStatusToggler";
import getIcon from "../components/utils/iconHelper";
import OrderLink from "../components/order/OrderLink";
import OrderDeadline from "../components/order/OrderDeadline";
import OrderAssistant from "../components/order/OrderAssistant";
import OrderRemake from "../components/order/OrderRemake";
import ShippedNotifyUser from "../components/order/ShippedNotifyUser";
import {Extract, Generate, Validate} from "../components/utils/checksum";
import OrderShipper from "../components/order/OrderShipper";
import OrderPacker from "../components/order/OrderPacker";
import OrderShippingPacketa from "../components/order/OrderShippingPacketa";
import OrderFulfiller from "../components/order/OrderFulfiller";

class OrderScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTabIndex: 0,
            mainOrder: {},
            order: {},
            orders: [],
            addressEditable: false,
            selectedMenu: 'items',
            refresh: false,
            config: {},
            configs: {}
        }
    }

    componentDidMount() {
        let qs = queryString.parse(this.props.location.search);

        if (qs && qs.page) {
            this.setState({selectedMenu: qs.page})
        }

        if (this.props.match && this.props.match.params && (this.props.match.params.id || this.props.orderId)) {
            this.loadData();
        } else {
            if (this.props.orderId) {
                this.loadData();
            }
        }
    }

    render() {
        if (!this.state.mainOrder.id) {
            return null;
        }

        const createdRT = moment(this.state.mainOrder.created);
        let created = createdRT.tz('Europe/Bucharest').format('DD MMMM YYYY');

        const completedRT = moment(this.state.mainOrder.completed);
        let completed = completedRT.tz('Europe/Bucharest').format('DD MMMM YYYY');


        const orderInfoClassName = this.state.mainOrder.suborder_of ? 'order-info suborder' : 'order-info';
        const isSuborder = (this.state.mainOrder.suborder_of === null) ? false : true;
        const isRemake = (this.state.mainOrder.remake_of === null) ? false : true;

        const orderType = isSuborder ? 'Sub' : 'Order';

        let awbWarning = null;

        const renderElement = this.getRenderElement(awbWarning);

        const multiOrderTab = this.getMultiOrderTab();

        return (
            <div className='OrderScreen'>
                <div className='content'>
                    <div className='left'>
                        <div className={orderInfoClassName}>
                            <div className='info'>
                                <div className='order-nr'>{orderType} {Generate(this.state.mainOrder.id)}</div>
                                {isSuborder &&
                                <a className='id' href={`/order/${Generate(this.state.mainOrder.suborder_of)}`}
                                   target='_blank'>Parent
                                    order: {Generate(this.state.mainOrder.suborder_of)}</a>
                                }
                                {isRemake &&
                                <a className='remake' href={`/order/${this.state.mainOrder.remake_of}`}
                                   target='_blank'><br/>Remake of: {this.state.mainOrder.remake_of}
                                    <br/>{this.state.mainOrder.remake_reason}<br/></a>
                                }
                                <div className='date'>{created}</div>
                                {this.state.mainOrder.completed &&
                                <div className='date' style={{color: 'green'}}>{completed}</div>
                                }
                                {this.state.mainOrder.external_source === 'etsy' &&
                                <div>{this.state.mainOrder.external_meta.cost.currency} {(parseFloat(this.state.mainOrder.external_meta.cost.subtotal) + parseFloat(this.state.order.external_meta.cost.total_shipping_cost)).toFixed(2)}</div>
                                }
                                {this.state.mainOrder.external_source === 'platform' &&
                                <div>{this.state.mainOrder.external_meta.cost.currency} {this.state.mainOrder.external_meta.cost.total_price}</div>
                                }
                                {this.state.mainOrder.external_source === 'etsy' &&
                                    <div style={{margin:'20px 0'}}>
                                        <div>Etsy ref:</div>
                                        <b>{this.state.mainOrder.external_id}</b>
                                    </div>
                                }
                                <div>{getIcon(this.state.mainOrder.external_source, 30)}</div>
                            </div>
                            <div className='fill'/>
                            <OrderStatusToggler status={this.state.order.status}
                                                errors={this.state.errors}
                                                enabled={true}
                                                onStatusChanged={this.onStatusChanged}/>
                        </div>
                        <div className='panel'>
                            <div style={{height: '100px', fontWeight: 'bold'}}>
                                <OrderFulfiller label="Assigned to"
                                               fulfiller={this.state.mainOrder.fulfiller}
                                                onFulfillerSelected={this.onFulfillerSelected}/>
                            </div>
                        </div>
                        <div className='panel'>
                            <div style={{height: '100px', fontWeight: 'bold'}}>
                                <OrderDeadline label="Production deadline"
                                               deadline={this.state.mainOrder.production_deadline}
                                               onDeadlineSelected={this.onDeadlineSelected}/>
                            </div>
                        </div>
                        <div className='panel'>
                            <div style={{height: '100px', fontWeight: 'bold'}}>

                                <OrderDeadline label="Delivery deadline"
                                               deadline={this.state.mainOrder.delivery_deadline}
                                               onDeadlineSelected={this.onDeliveryDeadlineSelected}/>
                            </div>
                        </div>
                        <div className='panel'>
                            <OrderUser id={this.state.mainOrder.users_id} from={this.state.mainOrder.id}/>
                        </div>
                        <div className='panel'>
                            <div style={{fontWeight: 'bold'}}>
                                <OrderPacker reloadPage={this.reloadPage} orderId={this.state.mainOrder.id} packing={this.state.mainOrder.packing}/>
                            </div>
                        </div>
                        <div className='panel'>
                            <div style={{fontWeight: 'bold'}}>
                                <OrderShipper reloadPage={this.reloadPage} order={this.state.mainOrder} orderId={this.state.mainOrder.id} shipper={this.state.mainOrder.shipper}/>
                            </div>
                        </div>

                        <div className='panel'>
                            {this.state.mainOrder.shipper === 'DHL' &&
                            <OrderShipping data={this.state.mainOrder.shipping_meta}
                                           initialAddress={this.state.mainOrder.external_meta.shiping}
                                           validation={this.state.mainOrder.address_invalid}
                                           isRemoteDelivery={this.state.mainOrder.is_remote_delivery}
                                           onEditAddressClicked={() => this.setState({addressEditable: true})}
                                           addressEditable={this.state.addressEditable}
                                           onSaveClicked={this.onUpdateShippingAddress}/>
                            }

                            {this.state.mainOrder.shipper === 'Packeta' &&
                                <OrderShippingPacketa data={this.state.mainOrder.shipping_meta}
                                               initialAddress={this.state.mainOrder.external_meta.shiping}
                                               validation={this.state.mainOrder.address_invalid}
                                               isRemoteDelivery={this.state.mainOrder.is_remote_delivery}
                                               onEditAddressClicked={() => this.setState({addressEditable: true})}
                                               addressEditable={this.state.addressEditable}
                                               onSaveClicked={this.onUpdateShippingAddress}/>
                            }
                        </div>
                        <div className='panel'>
                            {!isSuborder &&
                            <OrderLink id={this.state.mainOrder.users_id} from={this.state.mainOrder.id}/>
                            }
                        </div>
                        <div className='panel'>
                            <OrderRemake id={this.state.mainOrder.id}/>
                        </div>
                    </div>
                    <div className='middle'>
                        <div className="multiOrder">
                            {multiOrderTab}
                        </div>

                        <div className='assistant'>
                            {this.state.refresh === false &&
                            <OrderAssistant errors={this.state.order.errors}/>
                            }
                        </div>

                        <div className='menu'>
                            <Menu pointing secondary>
                                <Menu.Item
                                    name='items'
                                    active={this.state.selectedMenu === 'items'}
                                    onClick={this.handleItemClick}
                                >
                                    Items
                                </Menu.Item>

                                <Menu.Item
                                    name='production'
                                    active={this.state.selectedMenu === 'production'}
                                    onClick={this.handleItemClick}
                                >
                                    Production
                                </Menu.Item>

                                {!isSuborder &&

                                <Menu.Item
                                    name='shipping'
                                    disabled={this.ordersHaveAnyErrors() || this.state.order.suborder_of }
                                    active={this.state.selectedMenu === 'shipping'}
                                    onClick={this.handleItemClick}
                                >
                                    Shipping
                                </Menu.Item>
                                }
                                <Menu.Item
                                    name='timeline'
                                    active={this.state.selectedMenu === 'timeline'}
                                    onClick={this.handleItemClick}
                                >
                                    Timeline
                                </Menu.Item>
                            </Menu>
                        </div>
                        <div className='rendered-element'>
                            {renderElement}
                        </div>
                    </div>

                    <div className='right'>
                        <div className='panel'>
                            {this.state.refresh === false &&
                            <OrderFinance orders={this.state.orders}
                                          mainOrder={this.state.mainOrder}
                                          isSuborder={isSuborder}
                                          onRefresh={this.refreshData}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getMultiOrderTab = () => {
        if (this.state.orders.length < 2) {
            return null;
        }

        let panes = []

        for (let i = 0; i < this.state.orders.length; i++) {
            panes.push({menuItem: `Order ${i + 1}`, render: () => null})
        }

        return (
            <Tab activeTab={this.state.currentTabIndex} onTabChange={this.onMultiOrderTabChange}
                 menu={{color: "blue", inverted: true,}} panes={panes}/>
        )
    }

    onMultiOrderTabChange = (e, {activeIndex}) => {
        this.setState({
            currentTabIndex: activeIndex,
            order: this.state.orders[activeIndex]
        })
    }

    ordersHaveAnyErrors = () => {
        let haveErrors = false;
        this.state.orders.forEach(order => {
            if (order.errors?.length) {
                haveErrors = true;
            }
        })
        return haveErrors;
    }

    getRenderElement = (awbWarning) => {

        switch (this.state.selectedMenu) {
            case 'items':
                return (<OrderItem_Items data={this.state.order} mainOrderId={this.state.order?.suborder_of} onOrderItemTranslated={() => {
                }}
                                         configs={this.state.configs}
                                         onRefresh={this.onWorkItemSaved} onMergeClicked={this.onMergeClicked}
                                         onCancelOrderClicked={this.onCancelOrderClicked}/>);
            case 'shipping':
                return (
                    <OrderItem_Awb data={this.state.order} mainOrderId={this.state.mainOrder.id} onRefresh={this.refreshData} warning={awbWarning}/>
                )
            case 'timeline':
                return (<OrderTimeline id={this.state.order.id}/>)
            case 'production':
                return (<OrderProduction id={this.state.order.id} configs={this.state.configs}
                                         onRefresh={this.refreshData}/>)
        }
    };

    onUpdateShippingAddress = (data) => {
        ApiService.updateShippingAddressForOrder({
            id: this.state.order.id,
            shipping_meta: JSON.stringify(data)
        }).then(data => {
            toast.success('Shipping address saved', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.setState({addressEditable: false}, () => {
                this.loadData();
            });
        });
    };

    onWorkItemSaved = () => {
        toast.success('Work item modified successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
        this.refreshData();
    };

    loadData = async () => {
        let orderId = this.props.match.params.id || this.props.orderId;

        if (Validate(orderId) === false) {
            return;
        }

        orderId = Extract(orderId);

        let order = await ApiService.getOrderById(orderId);
        const configs = await ApiService.loadProductConfig();
        const orderAssistant = await ApiService.getOrderAssistant(orderId);

        order.data.errors = orderAssistant.data;

        let orders = [];

        orders.push(order.data);

        if (order.data.linked_orders?.length) {
            for (let i = 0; i < order.data.linked_orders[i]; i++) {
                let subOrderId = order.data.linked_orders[i];
                let suborder = await ApiService.getOrderById(subOrderId);
                let subOrderAssistant = await ApiService.getOrderAssistant(subOrderId);

                suborder.data.errors = subOrderAssistant.data;

                orders.push(suborder.data);
            }
        }

        this.setState({
            configs: configs.data,
            currentTabIndex: 0,
            order: order.data,
            orders: orders,
            mainOrder: order.data
        })

    };

    handleItemClick = (e, {name}) => {
        this.setState({selectedMenu: name})
    };

    refreshData = async() => {
        let orders = [...this.state.orders];
        for(let i=0; i<orders.length; i++){
            let order = orders[i];

            let validate = await ApiService.getOrderAssistant(order.id);
            order.errors = validate.data;
        }

        this.setState({refresh: true, orders: orders}, () => {
            setTimeout(() => {
                this.setState({refresh: false})
            }, 50)
        })

    };

    onStatusChanged = (status) => {
        let orderId = this.props.match.params.id || this.props.orderId;
        if (Validate(orderId) === false) {
            return;
        }

        orderId = Extract(orderId)

        ApiService.updateOrderStatus({orderId: this.state.order.id, status: status, linked_orders: this.state.mainOrder.linked_orders}).then(data => {
            ApiService.getOrderById(orderId).then(data => {
                this.setState({order: data.data}, () => {
                    this.reloadPage();
                });
            })
        })
    };

    onDeadlineSelected = (deadline) => {
        let orderId = this.props.match.params.id || this.props.orderId;
        if (Validate(orderId) === false) {
            return;
        }

        orderId = Extract(this.props.match.params.id || this.props.orderId)

        ApiService.updateOrderDeadline({id: this.state.order.id, production_deadline: deadline}).then(data => {
            ApiService.getOrderById(orderId).then(data => {
                this.setState({order: data.data}, () => {
                    toast.success('Production deadline updated', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.refreshData();
                });
            })
        })
    }

    onFulfillerSelected = (fulfiller) => {
        let orderId = this.props.match.params.id || this.props.orderId;
        if (Validate(orderId) === false) {
            return;
        }

        orderId = Extract(this.props.match.params.id || this.props.orderId)

        ApiService.updateOrderFulfiller({id: this.state.order.id, fulfiller: fulfiller}).then(data => {
            window.location.reload();
            ApiService.getOrderById(orderId).then(data => {
                this.setState({order: data.data}, () => {
                    toast.success('Fulfiller updated', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.refreshData();
                });
            })
        })
    }

    onDeliveryDeadlineSelected = (deadline) => {
        let orderId = this.props.match.params.id || this.props.orderId;
        if (Validate(orderId) === false) {
            return;
        }

        orderId = Extract(this.props.match.params.id || this.props.orderId)

        ApiService.updateOrderDeadline({id: this.state.order.id, delivery_deadline: deadline}).then(data => {
            ApiService.getOrderById(orderId).then(data => {
                this.setState({order: data.data}, () => {
                    toast.success('Delivery deadline updated', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.refreshData();
                });
            })
        })
    }

    reloadPage = () => {
        window.location.reload();
    }


}


export default OrderScreen;
