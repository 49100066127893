import React, {Component} from 'react';
import {Container} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import OrderPiece from "./OrderPiece";
import "./OrderItem_Items.scss";

import moment from "moment-timezone";
import OrderPieceGeneric from "./orderPieces/OrderPieceGeneric";
import {toast} from "react-toastify";
import ShippmentItems from "./ShippmentItems";
import {Generate} from "./utils/checksum";

class OrderItem_Items extends Component {
    constructor(props) {
        super(props);

        let translatedOrder = this.props.data.work_meta || {};
        let productionDeadline = this.props.data.production_deadline;
        if (productionDeadline) {
            productionDeadline = new Date(productionDeadline);
        } else {
            productionDeadline = new Date();
            productionDeadline.setHours(13)
            productionDeadline.setMinutes(0)
        }

        this.state = {
            translatedOrder: translatedOrder,
            workOrderSaved: false,
            productionNotes: translatedOrder.productionNotes || '',
            productionDeadline: productionDeadline,
            orderItems: []
        };

        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.loadOrderItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data.id !== this.props.data.id){
            this.loadOrderItems();
        }

    }

    render() {
        const items = this.getItems();
        const externalItems = this.getExternalItems();

        return (
            <div className='OrderItem_Items'>
                <div className='items'>
                    {this.props.data.external_source === 'etsy' &&
                    <div className='left'>
                        <div className='external-items'>
                            <div className='title'>Ordered items</div>
                            <div className='item'>{externalItems}</div>

                        </div>

                        <div className='message-buyer'>
                            <div className='title'>Message from buyer</div>
                            <div className='message'> {this.props.data.external_meta.message_from_buyer}</div>
                        </div>

                    </div>
                    }
                    <div className='right'>
                        {this.props.data.autofill_disabled !== 1 &&
                        <div className="quickFillButton" onClick={()=>{
                            window.location = `/quick-fill-order/?id=${Generate(this.props.data.id)}`
                        }
                        }>Quick Fill</div>
                        }

                        {items}

                        <div style={{textAlign: 'center'}} onClick={this.onAddWorkItemClicked}><Button primary compact>add
                            item</Button></div>
                    </div>
                </div>

            </div>
        );
    }

    getExternalItems = () => {
        switch (this.props.data.external_source) {
            case 'etsy':
                return this.getEtsyExternalItems();

            case 'platform':
                return this.getPlatformExternalItems();
        }
    };

    getPlatformExternalItems = () => {

    }

    getEtsyExternalItems = () => {
        return this.props.data.external_meta.items.map(order => {
            return (
                <OrderPiece
                    key={order.item_id}
                    data={order}
                />
            )
        });
    }

    getItems = () => {
        return this.state.orderItems.map(order => {
            return (
                <OrderPieceGeneric
                    key={order.id || Math.random()}
                    configs={this.props.configs}
                    mainOrderId={this.props.mainOrderId}
                    data={order} userId={this.props.data.users_id}
                    orderStatus={this.props.data.status}
                                   onOrderItemSaveClicked={this.onOrderItemSaveClicked}
                                   onOrderDiscountClicked={this.onOrderDiscountClicked}
                                   onDeleteClicked={this.onOrderItemDeleteClicked}/>
            )
        });
    };

    onWorkMetaApply = (id, data) => {
        let workMeta = {...this.state.translatedOrder};
        workMeta[id] = data;

        this.setState({translatedOrder: workMeta})
    };

    onWorkMetaSaveClicked = () => {
        let work = {
            id: this.props.data.id,
            name: this.props.data.external_meta.shiping.name,
            ...this.state.translatedOrder
        };

        this.props.onOrderItemTranslated(work);
    };

    onDownloadWorkOrderClicked = () => {
        ApiService.getWorkOrderForId(this.props.data.id);
    };

    applyProductionNotes = () => {
        let workMeta = {...this.state.translatedOrder};
        workMeta.productionNotes = this.state.productionNotes;
        this.setState({translatedOrder: workMeta})
    };

    applyProductionDeadline = () => {
        let workMeta = {...this.state.translatedOrder};
        workMeta.productionDeadline = this.state.productionDeadline.getTime();
        this.setState({translatedOrder: workMeta})

        let update = {
            id: this.props.data.id,
            production_deadline: new Date(this.state.productionDeadline).toJSON().slice(0, 19).replace('T', ' ')
        }

        ApiService.updateOrder(update).then(data => {
            toast.success('Data saved', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    };

    onOrderItemSaveClicked = (data) => {
        let orderDate = this.props.data.created;
        orderDate = orderDate.substring(0, 10) + " " + orderDate.substring(11, 19);

        data.orders_id = this.props.data.id;
        data.order_date = orderDate;


        ApiService.saveWorkItem(data).then(data => {
            this.loadOrderItems();
            this.props.onRefresh()
        });
    };

    onOrderDiscountClicked = (data) => {
        let discount = {
            productType: 'discount' ,
            invoiceName:  'Discount corners',
            orderId: this.props.data.id,
            item: {},
            progress: {},
            details: "",
            price:  data.price,
            currency: data.currency,
            quantity: -data.quantity,
            id:  -1,
        }

        let orderDate = this.props.data.created;
        orderDate = orderDate.substring(0, 10) + " " + orderDate.substring(11, 19);

        discount.orders_id = this.props.data.id;
        discount.order_date = orderDate;

        ApiService.saveWorkItem(discount).then(data => {
            this.loadOrderItems();
            this.props.onRefresh()
        });
    };

    loadOrderItems = () => {
        this.setState({
            orderItems: []
        }, () => {
            ApiService.loadOrderItems(this.props.data.id).then(data => {
                let items = data.data;
                if (items.length < this.props.data.external_meta.items.length) {
                    for (let i = items.length; i < this.props.data.external_meta.items.length; i++) {
                        let order = this.props.data.external_meta.items[i];
                        items.push({
                            price: order.price,
                            quantity: order.quantity,
                            currency: order.currency,
                            item: {},
                            product_type: ''
                        })
                    }
                }

                this.setState({orderItems: items})
            })
        })
    };

    onAddWorkItemClicked = () => {
        let items = [...this.state.orderItems];
        items.push({
            price: '',
            quantity: '',
            currency: '',
            item: {},
            product_type: ''
        });

        this.setState({
            orderItems: items
        })
    };

    onOrderItemDeleteClicked = (id) => {
        this.setState({orderItems: []}, () => {
            ApiService.deleteWorkItem(id).then(data => {
                this.loadOrderItems();
            })
        })
    };

    onMergeToClicked = () => {
        this.props.onMergeClicked()
    }

}


export default OrderItem_Items;
