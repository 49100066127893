import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import SizesConfig from "../components/SizesConfig";
import MaterialsConfig from "../components/MaterialsConfig";
import StylesConfig from "../components/StylesConfig";
import FontsConfig from "../components/FontsConfig";
import EmbossConfig from "../components/EmbossConfig";
import UVPrintConfig from "../components/UVPrintConfig";
import PricingConfig from "../components/PricingConfig";
import GalleryConfig from "../components/GalleryConfig";
import AddonsConfig from "../components/AddonsConfig";
import MarkersConfig from "../components/MarkersConfig";

class MarkersScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'empty'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='blue' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Markers configuration</Menu.Item>
                    <Menu.Item
                        name='color'
                        active={activeItem === 'color'}
                        onClick={() => this.setState({activeItem: 'color'})}
                    />

                </Menu>

                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'color':
                return (<MarkersConfig config={this.props.config}/>);
            case 'empty':
                return (<Header style={{marginTop: '4rem', textAlign: 'center'}} as='h2'>Markers</Header>);
            default :
                return null;
        }
    }

}


export default MarkersScreen;
