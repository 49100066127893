import React, {PureComponent} from 'react';
import {Label, Table} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import moment from 'moment-timezone';
import './OrderItem.scss';
import AwbComponent from "./AwbComponent";
import OrderStatusToggler from "./order/OrderStatusToggler";
import {Generate} from "./utils/checksum";
import OrderBadgeDisplay from "./OrderBadgeDisplay";
import Fulfiller from "./order/Fulfiller";

class OrderItemForTable extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            awbDetails: false
        };
    }

    render() {
        const order = this.props.data;
        if(order.suborder_of){
            return null;
        }

        const created = moment(order.created).format('DD MMMM YYYY');

        let hasError = false;
        if (this.props.data.address_invalid?.length > 0) {
            hasError = true;
        }

        const isShippingTab = this.props.isShippedTab;
        const isAWBLate = isShippingTab ? this.isAWBLate() : false;
        const isAWBDelayed = isShippingTab ? this.isAWBDelayed() : false;

        return (
            <Table.Row className="OrderItem" error={hasError}
                       onClick={() => this.props.history.push(`/order/${Generate(this.props.data.id)}/?page=items`)}>
                <Table.Cell>
                    {isAWBLate &&
                    <Label ribbon color={"red"}>AWB LATE</Label>
                    }
                    {isAWBDelayed &&
                    <Label ribbon color={"orange"}>AWB DELAY</Label>
                    }
                    {this.props.data.remake_of &&
                    <Label ribbon color={"purple"}>Remake</Label>
                    }
                    {this.props.data.suborder_of !== null &&
                    <Label ribbon color={"blue"}>Suborder</Label>
                    }
                    <Fulfiller fulfiller={this.props.data.fulfiller}/>
                    <div><b>{Generate(order.id)}</b></div>
                </Table.Cell>
                <Table.Cell>{order.client}</Table.Cell>
                <Table.Cell>{created}</Table.Cell>
                <Table.Cell>
                    {this.props.data.external_source === 'etsy' &&
                    <div>{order.cost.currency} {order.cost.subtotal}</div>
                    }

                    {this.props.data.external_source !== 'etsy' &&
                    <div>{order.cost.currency} {order.cost.total_price}</div>
                    }
                </Table.Cell>
                <Table.Cell> <OrderBadgeDisplay
                    isAlbum={order.isAlbum}
                    isGuestbook={order.isGuestbook}
                    isBoudoir={order.isBoudoir}
                    isClassicAlbum={order.isClassicAlbum}
                    isGuestbookSlots={order.isGuestbookSlots}
                /></Table.Cell>

                <Table.Cell> <OrderStatusToggler displayOnly status={order.status}/></Table.Cell>

                <Table.Cell>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: 'center'
                    }}>
                        {this.props.data.is_remote_delivery === 1 &&
                        <Label color={"red"} style={{margin: "3px 0"}}>REMOTE AREA</Label>
                        }
                        {this.props.data.address_invalid?.length > 0 &&
                        <Label color={"red"} style={{margin: "3px 0"}}>ADDRESS INVALID</Label>
                        }
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {this.props.data.status === 'shipped' &&
                    <div className='awb' onClick={(e) => e.stopPropagation()}>
                        {this.getAwbItems()}
                    </div>
                    }
                </Table.Cell>
            </Table.Row>
        );
    }

    getAwbElement = () => {
        if (this.props.expandedTab !== 'Awb') {
            return null;
        }
        return (
            <AwbComponent data={this.props.data}/>
        )
    };

    isAWBLate = () => {
        if (this.props.awbsForOrder.length === 0) {
            return false;
        }

        let isLate = false;

        this.props.awbsForOrder.map(awb => {
            if (awb.statusMeta.isLate) {
                isLate = true;
            }
        })

        return isLate;
    }

    isAWBDelayed = () => {
        if (this.props.awbsForOrder.length === 0) {
            return false;
        }

        let isDelayed = false;

        this.props.awbsForOrder.map(awb => {
            if (awb.statusMeta.isDelayed) {
                isDelayed = true;
            }
        })

        return isDelayed;

    }

    getAwbItems = () => {
        if (this.props.awbsForOrder.length === 0) {
            return null;
        }

        return this.props.awbsForOrder.map(awb => {
            const isLate = awb.statusMeta.isLate;

            let itemClassName = isLate ? 'item late' : 'item';

            const allEvents = awb.statusMeta.events.map(event => {
                return (
                    <div className='lastEvent'>
                        <b>{event.date}</b> {event.statusDescription}, {event.location}</div>
                )
            })

            let lastEvent = awb.statusMeta.events[awb.statusMeta.events.length - 1];
            if (!lastEvent) {
                lastEvent = {}
            }

            return (
                <div className={itemClassName}>
                    <div className="row">
                        <div className="nr">
                            <b>{awb.awbMeta.tracking}
                                {awb.statusMeta.packetaExternalTrackingCode &&
                                <div>
                                    <div className="nr"><b>{awb.statusMeta.packetaExternalTrackingCode}</b></div>
                                    <div className="nr"><b>{awb.statusMeta.packetaExternalCarrierName}</b></div>
                                </div>
                                }
                            </b></div>

                        <div className="status"><h4>{awb.provider}</h4></div>
                        <div className="status">ETA: {awb.statusMeta.estimatedArrival}</div>
                        <div className="more" onClick={(e) => {
                            e.stopPropagation();
                            this.setState({awbDetails: !this.state.awbDetails})
                        }}>more
                        </div>
                    </div>
                    {this.state.awbDetails === false &&
                    <div className='lastEvent'>
                        <b>{lastEvent.date}</b> {lastEvent.statusDescription}, {lastEvent.location}
                    </div>
                    }
                    {this.state.awbDetails === true &&
                    <div className="allEvents">
                        <div className='goingTo'>Initial delivery estimate: {awb.statusMeta.estimatedArrival}</div>
                        {allEvents}
                    </div>
                    }
                </div>
            )
        })
        // return this.state.awbs.map(item=>{
        //     const deliveryTimeRaw = moment(item.created_at);
        //     let deliveryTime = deliveryTimeRaw.tz('Europe/Bucharest').format('DD MMMM YYYY');
        //
        //     return (
        //         <div>{deliveryTime} <span style={{fontWeight: 'bold'}}>{item.awbMeta.tracking}</span></div>
        //     )
        // })
    }

    onInvoiceDowloadClicked = () => {
        ApiService.downloadInvoice(this.props.orderId);
    };

    onItemsClicked = () => {
        this.props.history.push(`/order/${this.props.data.id}/?page=items`);
    };

    getProfitElement = () => {
        if (this.props.data.status !== 'complete' && this.props.data.status !== 'canceled') {
            return null;
        }

        let stats = this.props.data.stats;
        if (stats.err) {
            return (
                <div className='profit-stats'>
                    <div className="item red"> not enough data</div>
                </div>
            )
        }

        const profitClassName = stats.profit > 0 ? 'item green' : 'item red'
        const marginClassName = stats.margin > 0 ? 'item green' : 'item red'

        return (
            <div className='profit-stats'>
                <div className={profitClassName}>RON {stats.profit}</div>
                <div className={marginClassName}>{stats.margin}%</div>
            </div>
        )
    }

}


export default OrderItemForTable;
