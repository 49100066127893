import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import SizesConfig from "../components/SizesConfig";
import MaterialsConfig from "../components/MaterialsConfig";
import StylesConfig from "../components/StylesConfig";
import FontsConfig from "../components/FontsConfig";
import EmbossConfig from "../components/EmbossConfig";
import UVPrintConfig from "../components/UVPrintConfig";
import PricingConfig from "../components/PricingConfig";
import GalleryConfig from "../components/GalleryConfig";

class BudoirScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'empty'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='purple' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Boudoir configuration</Menu.Item>
                    <Menu.Item
                        name='sizes'
                        active={activeItem === 'sizes'}
                        onClick={() => this.setState({activeItem: 'sizes'})}
                    />
                    <Menu.Item
                        name='styles'
                        active={activeItem === 'styles'}
                        onClick={() => this.setState({activeItem: 'styles'})}
                    />
                    <Menu.Item
                        name='materials'
                        active={activeItem === 'materials'}
                        onClick={() => this.setState({activeItem: 'materials'})}
                    />
                    <Menu.Item
                        name='pricing'
                        active={activeItem === 'pricing'}
                        onClick={() => this.setState({activeItem: 'pricing'})}
                    />
                    <Menu.Item
                        name='emboss'
                        active={activeItem === 'emboss'}
                        onClick={() => this.setState({activeItem: 'emboss'})}
                    />
                    <Menu.Item
                        name='gallery'
                        active={activeItem === 'gallery'}
                        onClick={() => this.setState({activeItem: 'gallery'})}
                    />
                    {/*<Menu.Item*/}
                    {/*    name='uv print'*/}
                    {/*    active={activeItem === 'uv print'}*/}
                    {/*    onClick={() => this.setState({activeItem: 'uv print'})}*/}
                    {/*/>*/}
                    {/*<Menu.Item*/}
                    {/*    name='fonts'*/}
                    {/*    active={activeItem === 'fonts'}*/}
                    {/*    onClick={() => this.setState({activeItem: 'fonts'})}*/}
                    {/*/>*/}
                </Menu>

                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'sizes':
                return (<SizesConfig type='boudoir'/>);
            case 'materials':
                return (<MaterialsConfig type='boudoir' config={this.props.config}/>);
            case 'styles':
                return (<StylesConfig type='boudoir' config={this.props.config}/>);
            case 'emboss':
                return (<EmbossConfig type='boudoir' config={this.props.config}/>);
            case 'uv print':
                return (<UVPrintConfig type='boudoir' config={this.props.config}/>);
            case 'fonts':
                return (<FontsConfig type='boudoir' config={this.props.config}/>);
            case 'pricing':
                return (<PricingConfig type='boudoir' config={this.props.config}/>);
            case 'gallery':
                return (<GalleryConfig type='boudoir' config={this.props.config}/>);
            case 'empty':
                return (<Header style={{marginTop: '4rem', textAlign: 'center'}} as='h2'>boudoir</Header>);
            default :
                return null;
        }
    }

}


export default BudoirScreen;
