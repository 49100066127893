import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Button, ButtonGroup, Container} from "semantic-ui-react";
import WebsiteSwatchComponent from "../components/WebsiteSwatchComponent";
import ApiService from "../services/ApiService";

class WebsiteSwatchesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'empty',
            materialOrders: {
                'album': [],
                'guestbook': []
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const getRenderItem = () => {
            switch (this.state.activeItem) {
                case 'albums':
                    return (<WebsiteSwatchComponent data={this.state.materialOrders} productType={'album'}
                                                    onSaveClicked={this.onSaveClicked}/>)
                case 'guestbooks':
                    return (<WebsiteSwatchComponent data={this.state.materialOrders} productType={'guestbook'}
                                                    onSaveClicked={this.onSaveClicked}/>)
                default:
                    return null;
            }
        }

        const renderItem = getRenderItem();

        return (
            <Container>
                {this.state.activeItem === 'empty' &&
                <ButtonGroup style={{marginTop: '50px'}}>
                    <Button basic active={this.state.activeItem === 'albums'}
                            onClick={() => this.setState({activeItem: 'albums'})}>{'albums'}</Button>
                    <Button basic active={this.state.activeItem === 'guestbooks'}
                            onClick={() => this.setState({activeItem: 'guestbooks'})}>{'guestbooks'}</Button>
                </ButtonGroup>
                }

                <div style={{marginTop: '50px'}}>
                    {renderItem}
                </div>
            </Container>
        );
    }

    onSaveClicked = (data) => {
        const mo = {...this.state.materialOrders};

        mo[data.productType] = data.tabs;

        this.setState({activeItem: 'empty', materialOrders: mo}, ()=>{
            ApiService.saveWebsiteConfig({id:5, data: JSON.stringify(this.state.materialOrders)}).then(data=>{
                window.location.reload();
            })
        })
    }

    loadData = () => {
        ApiService.getWebsiteConfig('swatches.order').then(data=>{
            const set = JSON.parse(data.data.data);
            if(set) {
                this.setState({
                    materialOrders: set
                })
            }
        })
    }

}


export default WebsiteSwatchesScreen;
