import React, {Component} from 'react';
import '../../screens/QuickFillScreen.scss';
import {Dropdown, Input} from "semantic-ui-react";


class FilledItemPenTwo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="FilledItem">
                <h2 className="FilledItemTitle">Set of 2 Pens x {this.props.data.quantity}</h2>
                <div className="FilledItemBody">
                    <div className="row">

                        <div className="selectionItem">
                            <div className="title">Pens</div>
                            <div className="items">
                                <div
                                    className='item '
                                >I will add set of 2 pens
                                </div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">Color</div>
                            <div className="items">
                                <div
                                    className='item '
                                >{this.props.data.autofill.option_color}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default FilledItemPenTwo;
