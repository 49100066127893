import React, {Component} from 'react';
import './PackingScreen.scss';
import ApiService from "../services/ApiService";
import {Generate} from "../components/utils/checksum";
import * as queryString from "query-string";
import OrderItem_Awb from "../components/OrderItem_Awb";

class PackingScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            address: null,
            currentOrderId: null,
            weights: [],
            orders: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let mainOrderId = this.state.currentOrderId;
        let currentIndex = this.state.orders.findIndex(o => o.id === this.state.currentOrderId);

        let backClassName = 'button disabled';
        if (currentIndex > 0) {
            backClassName = 'button';
        }

        let nextClassname = 'button disabled';
        if (currentIndex < this.state.orders.length - 1) {
            nextClassname = 'button';
        }

        let currentOrder = null;
        currentOrder = this.state.orders.find(o => o.id === this.state.currentOrderId);

        let noOrders = this.state.orders.length === 0 && this.state.loading === false;

        return (
            <div className="PackingScreen">
                <div className="middle">
                    <div className="currentOrder">
                        <div className="orderNameAndAddress">
                            {Generate(mainOrderId)}
                            {this.state.address &&
                                <div className="address">
                                    <div><b>{this.state.address.name}</b></div>
                                    <div>{this.state.address.zip}, {this.state.address.first_line} {this.state.address.second_line}</div>
                                    <div>{this.state.address.city}, {this.state.address.county}, {this.state.address.country}</div>
                                </div>
                            }

                        </div>
                        {this.state.loading === false &&
                            <div className="counter">{this.state.orders.length} orders left</div>
                        }
                    </div>

                    <div className="itemsToPack">
                        {noOrders &&
                            <div className="empty">No orders to pack</div>
                        }

                        {(mainOrderId !== null && currentOrder) &&
                            <OrderItem_Awb data={currentOrder} mainOrderId={this.state.currentOrderId}/>
                        }

                    </div>
                </div>
            </div>
        )
    }

    onPrevClicked = () => {
        let currentIndex = this.state.orders.findIndex(o => o.id === this.state.currentOrderId);
        try {
            let newId = this.state.orders[currentIndex - 1].id;

            this.setNewPath(newId);
            this.setState({currentOrderId: newId});
        } catch (e) {
        }
    }

    reloadPage = () => {
        window.location.reload();
    }

    onNextClicked = () => {
        let currentIndex = this.state.orders.findIndex(o => o.id === this.state.currentOrderId);
        try {
            let newId = this.state.orders[currentIndex + 1].id;

            this.setNewPath(newId);
            this.setState({currentOrderId: newId});
        } catch (e) {
        }
    }

    getData = async () => {
        this.setState({loading: true});
        let qs = queryString.parse(this.props.location.search);
        let requesterOrderId = qs.orderId || null;

        let data = await ApiService.getOrders();
        let weights = await ApiService.getOrderPieceWeights();

        let currentOrderId = this.state.currentOrderId;

        let orders = data.data?.orders?.filter(o => (o.status === 'to pack' || o.status === 'packing') && o.suborder_of === null) ?? [];

        orders.forEach(order => {
            if (order.status_dates) {
                let statusDates = JSON.parse(order.status_dates);
                if (statusDates['to pack']) {
                    order.status_date_to_pack = statusDates['to pack'];
                } else {
                    order.status_date_to_pack = 0;
                }
            } else {
                order.status_date_to_pack = 0;
            }
        })

        orders.sort((a, b) => a.status_date_to_pack - b.status_date_to_pack);

        if (currentOrderId) {
            let currentSelectedOrderLookup = orders.find(o => Generate(o.id) === currentOrderId);
            if (!currentSelectedOrderLookup) {
                currentOrderId = null;
            }
        }

        if (requesterOrderId) {
            const orderLookup = orders.find(o => Generate(o.id) === requesterOrderId);
            if (orderLookup) {
                currentOrderId = orderLookup.id;
            }
        }

        if (!currentOrderId && orders.length) {
            currentOrderId = orders[0].id;
            this.setNewPath(currentOrderId);
        }

        let address = null
        if (currentOrderId) {
            address = await ApiService.getShipingAddressForOrder(currentOrderId);
            address = address.data;
        }

        this.setState({
            loading: false,
            currentOrderId: currentOrderId,
            orders: orders,
            weights: weights,
            address: address
        })
    }

    setNewPath = (orderId) => {
        let searchParams = new URLSearchParams(window.location.search)
        searchParams.set("orderId", Generate(orderId));
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);
    }

}


export default PackingScreen;
