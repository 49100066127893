import React, {Component} from 'react';
import './DhlCheckScreen.scss';
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";
import ApiService from "../services/ApiService";


class DhlCheckScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddVisible: false,
            isDataVisible: true,
            isModalVisible: false,
            processing: false,
            dataLoading: true,
            expandedId: -1,
            totalDelta:0,
            data: '',
            items: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const items = this.getItemsElements();

        return (
            <Dimmer.Dimmable as={'div'} className='DhlCheckScreen' dimmed={this.state.data}>
                {/*<div className='DhlCheckScreen'>*/}
                <Dimmer active={this.state.dataLoading}>
                    <Loader/>
                </Dimmer>

                {this.state.isModalVisible &&
                <Modal
                    size={'small'}
                    open={this.state.isModalVisible}
                    onClose={() => this.setState({isModalVisible: false, modalItem: null})}>
                    <Modal.Header>Add finance</Modal.Header>
                    <Modal.Content>
                        <div>I will add finance item SHIPPING</div>
                        <div><b>RON {-this.state.modalItem.delta || -this.state.modalItem.total}</b></div>
                        <div>to order id</div>
                        <input onChange={e => this.setState({
                            modalItem: {
                                ...this.state.modalItem,
                                orders_id: e.target.value
                            }
                        })} value={this.state.modalItem.orders_id || ''}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => this.setState({isModalVisible: false, modalItem: null})}>
                            Cancel
                        </Button>
                        <Button positive
                                onClick={() => {
                                    let amountToAdd = 0;
                                    let meta = JSON.parse(this.state.modalItem.meta);
                                    let hasVAT = meta['Total amount (excl. VAT)'] !== meta['Total amount (incl. VAT)'];
                                    let vat = 0;

                                    if(this.state.modalItem.delta){
                                        amountToAdd = this.state.modalItem.delta;
                                    } else {
                                        amountToAdd = this.state.modalItem.total;
                                    }

                                    if(hasVAT) {
                                        vat = parseFloat((amountToAdd - parseFloat((amountToAdd/1.19).toFixed(2))).toFixed(2))
                                    }

                                    amountToAdd = -amountToAdd;
                                    this.addFinanceItemToOrder(this.state.modalItem.id, this.state.modalItem.orders_id, amountToAdd, vat)
                                }}>
                            Add Finance
                        </Button>
                    </Modal.Actions>
                </Modal>
                }

                {this.state.processing &&
                <h3>Processing data... please wait</h3>
                }
                <div className='add'>
                    <Button primary={this.state.isAddVisible}
                            onClick={() => this.setState({isAddVisible: !this.state.isAddVisible})}>add</Button>

                    <Button
                            onClick={this.loadAll}>show all</Button>

                    <h4>Total delta: RON {this.state.totalDelta.toFixed(2)}</h4>

                    {this.state.isAddVisible &&
                    <div className='container'>
                        <div>paste here dhl json (csvToJson it first)</div>
                        <textarea value={this.state.data} onChange={e => this.setState({data: e.target.value})}
                                  cols={150} rows={25}/>

                        <div className='save'>
                            <Button onClick={this.saveClicked} primary>save</Button>
                        </div>
                    </div>
                    }
                </div>

                {this.state.isDataVisible &&
                <div className='data'>
                    {items}
                </div>
                }

            </Dimmer.Dimmable>
        );
    }

    getItemsElements = () => {
        if (this.state.items.length === 0) {
            return null;
        }

        return this.state.items.map(item => {
            let className = 'dot';
            if (item.valid === 0 && item.error === 'No shippment found') {
                className += ' notFound';
            } else {
                className += ' delta';
            }

            if(item.valid === 1){
                className = 'dot valid'
            }

            let date = item.date.split('');
            date = `${date[6]}${date[7]}-${date[4]}${date[5]}-${date[0]}${date[1]}${date[2]}${date[3]}`;

            let meta = JSON.parse(item.meta)
            let client = meta['Receivers Name'];

            return (
                <div className='item' key={item.id}>
                    <div className='content'>
                        <div className={className} onClick={()=>{
                            if(this.state.expandedId !== item.id){
                                this.setState({expandedId: item.id})
                            } else {
                                this.setState({expandedId: -1})
                            }
                        }}></div>

                        <div className='data'>
                            <div className='label'>invoice</div>
                            <div className='value'>{item.invoice}</div>
                        </div>

                        <div className='data'>
                            <div className='label'>date</div>
                            <div className='value'>{date}</div>
                        </div>

                        <div className='data'>
                            <div className='label'>paid</div>
                            <div className='value'>{item.total}</div>
                        </div>

                        <div className='data'>
                            <div className='label'>client</div>
                            <div className='value'>{client}</div>
                        </div>

                        {item.error !== 'No shippment found' &&
                        <div className='data'>
                            <div className='label'>delta</div>
                            <div className='value bold'>{item.delta}</div>
                        </div>
                        }

                        {item.error === 'No shippment found' &&
                        <div className='data'></div>
                        }

                        {item.error !== 'No shippment found' &&
                        <div className='data'>
                            <div className='label'>order</div>
                            <div className='value'><a target='_blank'
                                                      href={`https://control3.phototailors.com/order/${item.orders_id}`}>{item.orders_id}</a>
                            </div>
                        </div>
                        }

                        {item.error === 'No shippment found' &&
                        <div className='data'></div>
                        }

                        <div className='data'>
                            <div className='label'>error</div>
                            <div className='value'>{item.error}</div>
                        </div>

                        <div className='data'>
                            <div className='label'>action</div>
                            {item.valid !== 1 &&
                            <div className='value button' onClick={() => this.markAsOk(item)}>mark as ok</div>
                            }
                        </div>

                        <div className='data'>
                            <div className='label'>action</div>
                            {item.valid !== 1 &&
                            <div className='value button' onClick={() => this.onAddFinanceClicked(item)}>add
                                finance</div>
                            }
                        </div>
                        <div className='data'>
                            <div className='label'>resolve</div>
                            <div className='value'>{item.resolve}</div>
                        </div>
                    </div>
                    {this.state.expandedId === item.id &&
                    <pre>
                        {JSON.stringify(JSON.parse(item.meta), null, 4)}
                    </pre>
                    }
                </div>
            )
        })
    }

    loadData = () => {
        ApiService.getDhlValidationData('invalid').then(data => {
            data.data.sort((a,b)=>a.date-b.date)

            let totalDelta = 0;
            data.data.forEach(item=>{
                if(item.delta && item.delta !== 0){
                    totalDelta += item.delta
                }
            })

            this.setState({
                processing: false,
                isAddVisible: false,
                isDataVisible: true,
                dataLoading: false,
                data: '',
                totalDelta: totalDelta,
                items: data.data
            })
        })

    }

    addFinanceItemToOrder = (id, orderId, amount, vat) => {
        orderId = parseInt(orderId);

        let payload = {
            id: id,
            valid: 1,
            orders_id: orderId,
            vat: vat,
            addFinance: amount,
            resolve: `added SHIPPING finance item ${amount} to order id ${orderId}`
        }

        this.setState({isModalVisible: false, modalItem: null, dataLoading: true}, () => {
            ApiService.updateDhlValidationItem(payload).then(data => {
                let items = [...this.state.items];

                if (data.data.ok) {
                    items = items.filter(i => i.id !== id);
                }
                this.setState({dataLoading: false, items: items})
            })
        })
    }

    onAddFinanceClicked = (item) => {
        this.setState({isModalVisible: true, modalItem: item})
    }

    markAsOk = (item) => {
        let payload = {
            id: item.id,
            valid: 1,
            resolve: `clicked mark as ok`
        }

        this.setState({isModalVisible: false, modalItem: null, dataLoading: true}, () => {
            ApiService.updateDhlValidationItem(payload).then(data => {

                let items = [...this.state.items];

                if (data.data.ok) {
                    items = items.filter(i => i.id !== item.id);
                }
                this.setState({dataLoading: false, items: items})
            })
        })

    }

    saveClicked = () => {
        let jsonData = null;

        try {
            jsonData = JSON.parse(this.state.data);
        } catch (e) {
        }


        if (jsonData) {
            this.setState({
                processing: true,
                isAddVisible: false,
                isDataVisible: false
            }, () => {
                ApiService.uploadDhlValidationData(jsonData).then(data => {
                    this.loadData();
                })
            })

        } else {
            this.setState({data: 'invalid json'})
        }
    }

    loadAll = () => {
        ApiService.getDhlValidationData('all').then(data => {
            data.data.sort((a,b)=>a.date-b.date);

            data.data.reverse();

            this.setState({
                processing: false,
                isAddVisible: false,
                isDataVisible: true,
                dataLoading: false,
                data: '',
                items: data.data
            })
        })
    }


}


export default DhlCheckScreen;
