import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import StyleConfig from "./StyleConfig";

class StylesConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, styles: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getStyles(this.props.type).then(styles => {
                ApiService.getSizes(this.props.type).then(sizes => {
                    this.setState({loading: false, styles: styles.data.styles, sizes: sizes.data.sizes});
                });
            });
        });

    }

    render() {
        const styles = this.getStyles();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>
                <Table  fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                width={2}
                            >
                                PREVIEW
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={2}
                            >
                                NAME
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                width={1}
                                textAlign='center'
                            >
                                AVAILABLE
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={1}
                                textAlign='center'
                            >
                                ACTION
                            </Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {styles}
                    </Table.Body>
                </Table>

                <Button style={{marginTop: '3rem'}} content='add new style' basic
                        onClick={this.onAddNewStyleClicked}/>
            </Container>
        );
    }

    getStyles = () => {
        if (this.state.styles.length === 0) {
            return null;
        }

        return this.state.styles.map(style => {
            return (
                <StyleConfig key={style.id} config={this.props.config} data={style} sizes={this.state.sizes}
                             onSavedClicked={this.onStyleChanged}/>
            )
        })
    };

    onStyleChanged = (style) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editStyle(style).then(data => {
                this.setState({loading: false, styles: data.data.styles});
            });
        });
    };

    onAddNewStyleClicked = () => {
        let styles = [...this.state.styles];
        styles.push({
            editing: true,
            id: -1,

            name: '',
            thumbPath: '',
            pricesBase: {},
            pricesExtraSpread: {},
            available: false,

            defaultSpreads: '',
            minSpreads: '',
            maxSpreads: '',
            meta: {
                passepartout: {},
                emboss: {},
                uvPrint: {}
            },

            hasPassepartout: false,
            hasOwnImages: false,
            hasOwnSpreads: false,
            hasWhitePage: false,
            hasWhitePageForPhoto: false,
            hasEmboss: false,
            hasUvPrint: false,


            type: this.props.type
        });

        this.setState({
            styles: styles
        })
    };
}


export default StylesConfig;
