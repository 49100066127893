import React, {Component} from 'react';
import './EtsyAccountingLedgerScreen.scss';
import {Button, Checkbox, Container, Input} from "semantic-ui-react";
import EtsyLedger from "../components/EtsyLedger";
import ApiService from "../services/ApiService";

import {format, fromUnixTime} from "date-fns";

class EtsyAccountingLedgerScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            lastItemClicked:null,
            items: []
        }
    }

    componentDidMount() {
        ApiService.etsyGetDisburseItems().then(data => {
            let items = data.data;
            items.forEach(item => {
                item.selected = false;
            })

            this.setState({items: data.data, loading: false})
        })
    }

    render() {
        const elements = this.getItems();

        let totalChecked = 0;
        this.state.items.forEach(it => {
            if (it.checked) {
                totalChecked += 1;
            }
        })

        return (
            <Container className="EtsyAccountingLedgerScreen">
                <h3>Etsy disbursments</h3>
                <div style={{marginBottom: '20px'}}>Select the items you want to download</div>

                {elements}

                <div className="Download">
                    <Button disabled={this.state.loading || totalChecked === 0} primary
                            onClick={this.onDownloadClicked}>Download {totalChecked} statements</Button>
                </div>
            </Container>
        )
    }

    getItems = () => {
        if (!this.state.items?.length) {
            return null;
        }

        return this.state.items.map((item, index) => {
            const date = format(fromUnixTime(parseInt(item.create_date)), 'dd-MM-yyyy');

            return (
                <div key={item.id} className="Item">
                    <Checkbox  checked={item.checked} onChange={(e, data) => this.setChecked(item.id, data.checked, e)}
                              label={`${item.id} - ${date} - ${-item.amount / 100}`}/>
                </div>
            )
        })
    }

    setChecked = (itemId, checked, e) => {
        let items = [...this.state.items];

        if(e.shiftKey && this.state.lastItemClicked){
            let from = this.state.lastItemClicked < itemId ? this.state.lastItemClicked : itemId;
            let to = this.state.lastItemClicked > itemId ? this.state.lastItemClicked : itemId;

            for(let i=from; i<=to; i++){
                let item = items.find(it => it.id === i);
                if(item) {
                    item.checked = checked;
                }
            }
        } else {
            let item = items.find(it => it.id === itemId);
            item.checked = checked;
        }

        this.setState({items: items, lastItemClicked: itemId});
    }

    onDownloadClicked = () => {
        let seq = [];

        this.setState({
            loading: true
        }, () => {
            this.state.items.forEach(it => {
                if (it.checked) {
                    const date = format(fromUnixTime(parseInt(it.create_date)), 'dd-MM-yyyy');
                    seq.push({nr: it.sequence_number, date: date})
                }
            })

            ApiService.etsyAccountantGetParsedSequences(seq).then(data => {
                this.setState({loading: false})
            });
        })

    }

}


export default EtsyAccountingLedgerScreen
