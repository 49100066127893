import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import PouchConfig from "../components/PouchConfig";
import PouchesConfig from "../components/PouchesConfig";
import WebsiteConfigEditor from "../components/WebsiteConfigEditor";
import WebsiteConfigStartupGuestbook from "../components/WebsiteConfigStartupGuestbook";

class WebsiteScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Container style={{margin: '3rem 0'}}>
               <WebsiteConfigEditor name={"Pricing Guestbook"} configKey="pricing.guestbook"/>
               <WebsiteConfigEditor name={"Pricing Album"} configKey="pricing.album"/>
               <WebsiteConfigEditor name={"Pricing Boudoir"} configKey="pricing.boudoir"/>
               <WebsiteConfigStartupGuestbook name={"Startup Guestbook"} configKey="startup.guestbook"/>
            </Container>
        );
    }
}


export default WebsiteScreen;
