import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Input, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FoilConfig from "./FoilConfig";
import EmbossTemplateConfig from "./EmbossTemplateConfig";

class PricingConfigMaterial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'textil': this.props.materialPricing['textil'] || 0,
            'eco': this.props.materialPricing['eco'] || 0,
            'leather': this.props.materialPricing['leather'] || 0,
            'premiumLeather': this.props.materialPricing['premiumLeather'] || 0,
            editing: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            'textil': nextProps.materialPricing['textil'] || 0,
            'eco': nextProps.materialPricing['eco'] || 0,
            'leather': nextProps.materialPricing['leather'] || 0,
            'premiumLeather': nextProps.materialPricing['premiumLeather'] || 0,
        })
    }

    render() {
        const viewElement = this.getViewElement();
        const editElement = this.getEditElement();

        if (!this.state.editing) {
            return viewElement;
        }
        if (this.state.editing) {
            return editElement;
        }
    }

    getViewElement = () => {
        const size = this.props.size;

        return (
            <Table.Row>
                <Table.Cell>{size.cm}</Table.Cell>
                <Table.Cell>{this.state['textil']}</Table.Cell>
                <Table.Cell>{this.state['eco']}</Table.Cell>
                <Table.Cell>{this.state['leather']}</Table.Cell>
                <Table.Cell>{this.state['premiumLeather']}</Table.Cell>
                <Table.Cell><Button disabled={this.props.selectedCurrency !== 'USD'} basic onClick={() => this.setState({editing: true})}>edit</Button></Table.Cell>
            </Table.Row>
        )
    }

    getEditElement = () => {
        const size = this.props.size;

        return (
            <Table.Row >
                <Table.Cell>{size.cm}</Table.Cell>
                <Table.Cell><Input name='textil' value={this.state['textil']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='eco' value={this.state['eco']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='leather' value={this.state['leather']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='premiumLeather' value={this.state['premiumLeather']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Button basic onClick={this.onSaveClicked}>save</Button></Table.Cell>
            </Table.Row>
        )
    }

    onSaveClicked = () => {
        let payload = {
            values: {...this.state},
            path: `${this.props.type}.material.${this.props.size.cm}`,
            priceId: this.props.priceId,
        }

        delete payload.values.editing;

        this.setState({editing: false}, ()=>{
            this.props.onUpdateClientPrice(payload);
        })

    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});

}


export default PricingConfigMaterial;
