import React, {Component} from 'react';

import './GalleryItem.scss';
import * as Reorder from 'react-reorder';
import {reorder} from 'react-reorder';
import {config} from "../config/config";
import {Button, Dimmer, Image, Loader} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class ReorderMaterialsComponent extends Component {
    constructor(props) {
        super(props);

        let materials = [...this.props.materials];

        this.state = {
            materials: materials,
            currentSorting: materials.filter(m => m.category === 'eco').sort((a, b) => (a[`order_${this.props.type}`] > b[`order_${this.props.type}`]) ? 1 : -1),
            currentCategory: 'eco',
            isVisible: false,
            loading: false
        };
    }


    render() {
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <div className='toggle' style={{margin: '0 0 20px 0'}}>
                    <Button onClick={() => this.setState({isVisible: !this.state.isVisible})}> Reorder</Button>
                </div>

                {this.state.isVisible === true &&

                <div style={{marginBottom: '50px', border: '1px solid #dededf', padding: '20px', borderRadius: '5px'}}>
                    <div style={{margin: '0 0 20px 0'}}>
                        <Button.Group widths='4'>
                            <Button onClick={() => this.onCategoryChanged('eco')}
                                    active={this.state.currentCategory === 'eco'}>Leatherette</Button>
                            <Button onClick={() => this.onCategoryChanged('textil')}
                                    active={this.state.currentCategory === 'textil'}>Linen</Button>
                            <Button onClick={() => this.onCategoryChanged('leather')}
                                    active={this.state.currentCategory === 'leather'}>Leather</Button>
                            <Button onClick={() => this.onCategoryChanged('premium leather')}
                                    active={this.state.currentCategory === 'premium leather'}>Premium Leather</Button>
                        </Button.Group>
                    </div>

                    {this.state.currentSorting.length > 0 &&
                    <div>
                        <Reorder reorderId='id' onReorder={this.onReorder}
                                 style={{float: 'left', width: '100%', height: 'auto', margin: '0 0 40px 0'}}>
                            {this.state.currentSorting.map(item => {
                                let thumb = item.meta.imageThumb;
                                if (!thumb) {
                                    thumb = 'https://via.placeholder.com/150?text=Missing'
                                } else {
                                    thumb = `${config.ASSETS_PATH}/products/material/${item.meta.imageThumb}`
                                }
                                return (
                                    <div key={item.id} style={{float: 'left', width: '180px', margin: '10px'}}>
                                        <div>
                                            <Image src={thumb}
                                                   size='small'/>
                                            <div>{item.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Reorder>
                        <div>
                            <Button primary onClick={this.onSaveClicked}>save</Button>
                            <Button onClick={()=>this.setState({isVisible: false})}>cancel</Button>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        );
    }

    onCategoryChanged = (category) => {
        const filteredMaterials = [...this.state.materials].filter(m => m.category === category).sort((a, b) => (a[`order_${this.props.type}`] > b[`order_${this.props.type}`]) ? 1 : -1);
        this.setState({currentSorting: filteredMaterials, currentCategory: category})
    }

    onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        const newOrder = reorder(this.state.currentSorting, previousIndex, nextIndex);
        this.setState({currentSorting: newOrder});
    }

    onSaveClicked = () => {
        const items = [...this.state.currentSorting];
        let payload = [];
        items.forEach((item, index)=>{
            payload.push({id: item.id, index: index})
        })

        this.setState({loading: true}, ()=>{
            ApiService.updateMaterialsDisplayOrder(this.props.type, payload).then(data=>{
                this.setState({loading: false, isVisible: false})
            })
        })

    }


}


export default ReorderMaterialsComponent;
