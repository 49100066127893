import React, {Component} from 'react';
import './AddEtsyStatement.scss';
import {Button, Input} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class AddEtsyStatement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            jsonData: '',
            statementName: '',
            error: ''
        };
    }

    render() {
        const expandedElement = this.getExpandedElement();

        return (
            <div className='AddEtsyStatement'>
                <Button compact onClick={()=>this.setState({expanded: true})}>Import etsy statement</Button>
                {expandedElement}
            </div>
        );
    }

    getExpandedElement = () => {
        if(!this.state.expanded){
            return null;
        }

        const canSave = this.state.jsonData.length && this.state.statementName.length;
        const buttonLabel = this.state.importing ? 'Importing...' : 'Import';

        return (
            <div>
                <div>Paste JSON etsy statement</div>
                <textarea rows='50' cols='100' value={this.state.jsonData} onChange={(e)=>this.setState({jsonData: e.target.value})}></textarea>
                <br/>
                <br/>
                <Input value={this.state.statementName} onChange={(e, {value})=>this.setState({statementName: value})} label='statement name'/>
                <div style={{color:'red'}}>{this.state.error}</div>
                <Button disabled={!canSave} primary onClick={this.onImportStatementClicked}>{buttonLabel}</Button>
            </div>
        )
    }

    onImportStatementClicked = () => {
        let json = null;

        try{
            json = JSON.parse(this.state.jsonData);
        } catch(e){

        }

        if(!json){
            this.setState({error: "Unable to parse the pasted JSON"});
            return;
        }

        this.setState({importing: true, error: ''}, ()=>{
            ApiService.uploadEtsyStatement({data:json, name: this.state.statementName}).then(data=>{
                this.setState({
                    // jsonData: '',
                    importing: false,
                    // statementName: ''
                })
            })
        })


    }

}


export default AddEtsyStatement;
