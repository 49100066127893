import React, {Component} from 'react';
import {Button, Input, Modal, Table} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import {format, fromUnixTime} from "date-fns";
import './StockItemOperations.scss';
import FulfillerFilter from "../order/FulfillerFilter";
import TransferLayer from "./TransferLayer";

class StockItemOperations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: 'none',
            addValue: '',
            recountValue: '',
            saving: false,
            timeline: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.item?.id !== this.props.item?.id){
            this.setState({mode: 'none', addValue:'', recountValue:'', timeline:[]})
        }
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        const header = this.renderItemHeader();
        const body = this.renderItemBody();

        const renderItemHeader = () => {
            let item = {...this.props.item};
            if (item) {
                // this.props.item?.meta? = JSON.parse(this.props.item?.meta?);
            } else {
                return null;
            }


            switch (this.props.item?.type) {
                case 'bloc':
                    return (
                        <div className="itemDetails">
                            <div style={{margin: '0 10px'}}>Bloc</div>
                            <div style={{margin: '0 10px'}}>Quantity: {this.props.item?.quantity}</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                            <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                        </div>
                    )
                case 'bloc-r':
                    return (
                        <div className="itemDetails">
                            <div style={{margin: '0 10px'}}>Bloc spacer</div>
                            <div style={{margin: '0 10px'}}>Quantity: {this.props.item?.quantity}</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                            <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                        </div>
                    )
                case 'cover':
                    return (
                        <div className="itemDetails">
                            <div style={{margin: '0 10px'}}>Cover</div>
                            <div style={{margin: '0 10px'}}>Quantity: {this.props.item?.quantity}</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.materialName}</div>
                            <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                        </div>
                    )
                case 'cover-r':
                    return (
                        <div className="itemDetails">
                            <div style={{margin: '0 10px'}}>Cover spacer</div>
                            <div style={{margin: '0 10px'}}>Quantity: {this.props.item?.quantity}</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.materialName}</div>
                            <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                        </div>
                    )
                case 'corner':
                    return (
                        <div className="itemDetails">
                            <div style={{margin: '0 10px'}}>Corner</div>
                            <div style={{margin: '0 10px'}}>Quantity: {this.props.item?.quantity}</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.color}</div>
                            <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                        </div>
                    )
                case 'pen':
                    return (
                        <div className="itemDetails">
                            <div style={{margin: '0 10px'}}>Pen</div>
                            <div style={{margin: '0 10px'}}>Quantity: {this.props.item?.quantity}</div>
                            <div style={{margin: '0 10px'}}>{this.props.item?.meta?.color}</div>
                            <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                        </div>
                    )
            }
        }

        return (
            <Modal
                closeOnDimmerClick={true}
                closeOnEscape={true}
                onClose={this.props.onCloseClicked}
                open={this.props.item}>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    <div style={{marginBottom:'20px'}}>
                        <FulfillerFilter disableSelection={true} mode="stoc" selected={this.props.selectedStoc} onFulfilledFilterChange={()=>{}}/>
                            {renderItemHeader()}
                    </div>
                    <Modal.Description>
                        <Button size="small" disabled={this.state.saving} primary={this.state.mode === 'add'}
                                onClick={() => this.setState({mode: 'add'})}>Add more items in inventory</Button>
                        <Button size="small" disabled={this.state.saving} primary={this.state.mode === 'recount'}
                                onClick={() => this.setState({mode: 'recount'})}>Recount items in inventory</Button>
                        <Button size="small" disabled={this.state.saving} primary={this.state.mode === 'timeline'}
                                onClick={() => this.setState({mode: 'timeline'})}>Timeline</Button>
                        <Button size="small" disabled={this.state.saving} primary={this.state.mode === 'transfer'}
                                onClick={() => this.setState({mode: 'transfer'})}>Transfer</Button>
                    </Modal.Description>

                    <div style={{marginTop: '20px'}}>
                        {body}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' disabled={this.state.saving} onClick={this.props.onCloseClicked}>Close</Button>
                </Modal.Actions>
            </Modal>
        )
    }


    renderItemHeader = () => {
        const item = this.props.item;

        switch (this.props.item.type) {
            case 'bloc':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>Bloc</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                        <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                    </div>
                )
            case 'bloc-r':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>Bloc spacer</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                        <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                    </div>
                )
            case 'cover':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>Cover</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.materialName}</div>
                        <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                    </div>
                )
            case 'cover-r':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>Cover spacer</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.sizeName} cm</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.pages} pages</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.materialName}</div>
                        <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                    </div>
                )
            case 'corner':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>Corner</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.color}</div>
                        <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                    </div>
                )
            case 'pen':
                return (
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 10px'}}>Pen</div>
                        <div style={{margin: '0 10px'}}>{this.props.item?.meta?.color}</div>
                        <div style={{margin: '0 10px'}}>{item.pick_place}</div>
                    </div>
                )
        }
    }

    renderItemBody = () => {
        switch (this.state.mode) {
            case 'add':
                return this.getAddBodyElement();
            case 'recount':
                return this.getCountBodyElement();
            case 'timeline':
                return this.getTimelineBodyElement();
            case 'transfer':
                return this.getTransferBodyElement()
        }
    }

    getTransferBodyElement = () => {
        return  <TransferLayer quantity={this.props.item.quantity} from={this.props.selectedStoc} sku={this.props.item?.sku} onTransferComplete={this.onStocTransferComplete}/>
    }

    onStocTransferComplete = () => {
        window.location.reload()
    }

    getAddBodyElement = () => {
        return <div>
            <h4>In inventory: {this.props.item.quantity}</h4>
            <div className='label'>How many new items?</div>
            <Input style={{width: '200px', marginRight: '5px'}} value={this.state.addValue}
                   onChange={(e) => this.setState({addValue: e.target.value})}/>
            <Button primary disabled={this.state.saving} onClick={this.onAddSaveClicked}>Save</Button>
        </div>
    }

    getCountBodyElement = () => {
        return <div>
            <h4>In inventory: {this.props.item.quantity}</h4>
            <div className='label'>How many items in inventory?</div>
            <Input style={{width: '200', marginRight: '5px'}} value={this.state.recountValue}
                   onChange={(e) => this.setState({recountValue: e.target.value})}/>
            <Button disabled={this.state.saving} onClick={this.onRecountSaveClicked} primary>Save</Button>
        </div>
    }

    getTimelineBodyElement = () => {
        const getItems = () => {
            if(this.state.timeline.length !== 0){
                return this.state.timeline.map(item=>{
                    const date = format(fromUnixTime(parseInt(item.date)/1000), 'dd-MM-yyyy') ;
                    let meta = {};

                    if(item.meta?.length){
                       meta = JSON.parse(item.meta)
                    }

                    if(item.movement_type === 'transfer'){
                        meta.pieceSKU = `${meta.from} -> ${meta.to}`
                    }

                    return (
                        <Table.Row positive={item.movement_type==='add_more'} negative={item.movement_type === 'recount' && (item.quantity - item.prev_quantity < 0)}>
                            <Table.Cell>{date}</Table.Cell>
                            <Table.Cell>{item.movement_type}</Table.Cell>
                            <Table.Cell>{item.quantity - item.prev_quantity}</Table.Cell>
                            <Table.Cell>{item.prev_quantity}</Table.Cell>
                            <Table.Cell>{item.quantity}</Table.Cell>
                            <Table.Cell>{meta.pieceSKU}</Table.Cell>
                            <Table.Cell>{item.user}</Table.Cell>
                        </Table.Row>
                    )
                })
            }
        }
        return <div>
            <Button size="small" primary onClick={this.onLoadTimelineForItemClicked}>load data</Button>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Operation</Table.HeaderCell>
                        <Table.HeaderCell>Delta</Table.HeaderCell>
                        <Table.HeaderCell>Old quantity</Table.HeaderCell>
                        <Table.HeaderCell>New quantity</Table.HeaderCell>
                        <Table.HeaderCell>Used for</Table.HeaderCell>
                        <Table.HeaderCell>Operator</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {getItems()}
                </Table.Body>

            </Table>
        </div>
    }

    onLoadTimelineForItemClicked = async () => {
        const data = await ApiService.stockLoadTimelineForItem(this.props.item.id);
        this.setState({timeline: data.data.reverse()})
    }

    onAddSaveClicked = () => {
        this.setState({saving: true}, async()=>{
            const update = await ApiService.stockAddMore({itemId: this.props.item.id, added: parseInt(this.state.addValue)});

            this.setState({saving: false}, ()=>{
                this.props.closeAndRefresh();
            });
        })
    }

    onRecountSaveClicked = () => {
        this.setState({saving: true}, async()=>{
            const update = await ApiService.stockUpdateCount({itemId: this.props.item.id, newCount: parseInt(this.state.recountValue)});

            this.setState({saving: false}, ()=>{
                this.props.closeAndRefresh();
            });
        })
    }
}

export default StockItemOperations;
