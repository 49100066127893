import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Menu} from "semantic-ui-react";
import PouchesConfig from "../components/PouchesConfig";
import PensConfig from "../components/PensConfig";

class PensScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'empty'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='grey' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Pen configuration</Menu.Item>
                    <Menu.Item
                        name='colors'
                        active={activeItem === 'colors'}
                        onClick={() => this.setState({activeItem: 'colors'})}
                    />
                </Menu>

                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'colors':
                return (<PensConfig type='pen'/>);
            default :
                return null;
        }
    }

}


export default PensScreen;
