import React, {Component} from 'react';
import {Button, Checkbox, Container, Header, Icon, Image, Input, Item, Label, Modal} from "semantic-ui-react";
import { ChromePicker } from 'react-color'

class MaterialFoilMapping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };

        this.attributes = {
            width: 500,
            height: 100
        };

        this.canvas = null;

        this.ctx = null;

        this.backgroundCanvas = null;
        this.backgroundCTX = null;
        this.redrawBackground = true;

        this.embossCanvas = null;
        this.embossCTX = null;
        this.redrawEmboss = true;
    }

    componentWillReceiveProps(nextProps) {
        let color = '#aabbcc';

        if(this.props.material.meta && this.props.material.meta.foils && this.props.material.meta.foils[this.props.foil.id]){
            color = this.props.material.meta.foils[this.props.foil.id];
        }

        this.drawCanvas(color);
    }

    render() {
        const canvasElement = this.getCanvasElement();
        let checked = false;

        if(this.props.material.meta && this.props.material.meta.foils && this.props.material.meta.foils[this.props.foil.id]){
            checked = true;
        }

        return (
            <Container style={{paddingLeft: '2rem', marginTop: '1rem'}}>
                <div style={{display: 'flex', marginBottom: '1rem'}}>
                    <Checkbox label={this.props.foil.name} name='canEmboss' toggle checked={checked}
                              onChange={this.onCheckboxChanged}/>

                    <Button style={{marginLeft: '1rem', maxHeight: '26px'}} basic size='mini' icon='pencil alternate' compact  onClick={() => this.setState({expanded: !this.state.expanded})}/>

                    {canvasElement}

                </div>
            </Container>
        );
    }

    getCanvasElement = () => {
        if(!this.state.expanded){
            return null;
        }


        let color = '#aabbcc';
        if(this.props.material.meta && this.props.material.meta.foils && this.props.material.meta.foils[this.props.foil.id]){
            color = this.props.material.meta.foils[this.props.foil.id];
        }

        return (
            <div style={{display:'flex'}}>
                <canvas style={{zoom: 1, margin: '0 1rem', height:'100px'}} width='500' height='100' ref={this.setCanvas}/>
                <ChromePicker color={color} onChange={ this.onColorChanged } />
            </div>
        )
    };

    onCheckboxChanged = () => {
        this.props.onFoilChecked(this.props.foil);
    };

    onFoilColorSetClicked = (foil) => {

    };

    onColorChanged = (color) => {
        this.props.onColorChanged(this.props.foil.id, color.hex);
        // this.drawCanvas(color.hex)
    };


    setCanvas = async (canvas) => {
        if (!canvas) {
            return;
        }

        if (this.canvas) {
            return;
        }

        this.canvas = canvas;
        this.ctx = canvas.getContext("2d");

        let color = "#aabbcc";

        if(this.props.material.meta && this.props.material.meta.foils && this.props.material.meta.foils[this.props.foil.id]){
            color = this.props.material.meta.foils[this.props.foil.id];
        }

        this.drawCanvas(color);
    };

    drawCanvas = async (color) => {
        if(!this.ctx){
            return null;
        }

        await this.drawBackground();
        await this.drawEmbossCanvas(color);

        this.ctx.clearRect(0, 0, this.attributes.width, this.attributes.height);
        this.ctx.drawImage(this.backgroundCanvas, 0, 0, this.attributes.width, this.attributes.height);
        this.ctx.drawImage(this.embossCanvas, 0, 0, this.attributes.width, this.attributes.height);
    };

    drawBackground = () => {
        return new Promise(async resolve => {
            // if (!this.redrawBackground) {
            //     return resolve();
            // }

            if (!this.backgroundCanvas) {
                this.backgroundCanvas = document.createElement('canvas');
                this.backgroundCanvas.width = this.attributes.width;
                this.backgroundCanvas.height = this.attributes.height;
                this.backgroundCTX = this.backgroundCanvas.getContext('2d');
            } else {
                this.backgroundCTX.clearRect(0, 0, this.backgroundCanvas.width, this.backgroundCanvas.height);
            }

            await this.drawMaterial();

            this.redrawBackground = false;

            return resolve();
        })
    };

    drawMaterial = () => {
        return new Promise((resolve) => {
            const img1 = new window.Image();

            img1.onload = () => {
                this.backgroundCTX.drawImage(img1, 0, 0);
                return resolve();
            };

            const materialPath = this.props.material.imagePathUrl;

            img1.src = materialPath;
        })

    };

    drawEmbossCanvas = (color) => {
        return new Promise(async resolve => {
            // if (!this.redrawEmboss) {
            //     return resolve();
            // }

            this.embossCanvas = document.createElement('canvas');
            this.embossCanvas.width = this.attributes.width;
            this.embossCanvas.height = this.attributes.height;
            this.embossCTX = this.embossCanvas.getContext('2d');

            await this.drawEmbossedText(color);
            this.redrawEmboss = false;

            return resolve();
        })
    };

    drawEmbossedText = (color) => {
        return new Promise((resolve) => {
            let itemToDraw = {
                size: 36,
                font: 'Arial',
                y: 70,
                text: 'Phototailors 1234 & + '
            };

            this.drawEmbossedTextLines([itemToDraw], color);

            return resolve();
        })
    };

    drawEmbossedTextLines = (data, color) => {
        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            let font = `${item.size}pt ${item.font}`;
            this.embossCTX.font = font;

            let size = this.embossCTX.measureText(item.text);
            let px = (this.embossCanvas.width / 2) - (size.width / 2);
            let py = item.y;

            this.embossCTX.fillText(item.text, px, py);
        }


        this.embossCTX.globalCompositeOperation = "xor";
        this.embossCTX.fillRect(0, 0, this.embossCanvas.width, this.embossCanvas.height);

        this.embossCTX.shadowBlur = 2;
        this.embossCTX.shadowOffsetX = this.embossCTX.shadowOffsetY = 5;
        this.embossCTX.shadowColor = "rgba(0,0,0,0.2)";
        this.embossCTX.drawImage(this.embossCanvas, 0, 0);

        this.embossCTX.globalCompositeOperation = "destination-atop";
        this.embossCTX.shadowColor = "transparent";

        this.embossCTX.drawImage(this.backgroundCanvas, 0, 0);

        this.embossCTX.globalCompositeOperation = "normal";

        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            let font = `${item.size}pt ${item.font}`;
            this.embossCTX.font = font;

            let size = this.embossCTX.measureText(item.text);
            let px = (this.embossCanvas.width / 2) - (size.width / 2);
            let py = item.y;

            this.embossCTX.fillStyle = "rgba(255,255,255, 0.3)";
            this.embossCTX.fillText(item.text, px + 2, py + 2);
        }

        this.embossCTX.globalCompositeOperation = "normal";

        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            let font = `${item.size}pt ${item.font}`;
            this.embossCTX.font = font;

            let size = this.embossCTX.measureText(item.text);
            let px = (this.embossCanvas.width / 2) - (size.width / 2);
            let py = item.y;

            // this.embossCTX.fillStyle = "rgba(200,193,188, 0.6)";
            this.embossCTX.fillStyle = color;
            this.embossCTX.fillText(item.text, px + 1, py + 1);
        }
    };

}


export default MaterialFoilMapping;
