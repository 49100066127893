import ScreenController from "../controler/ScreenController";

class Store {

    constructor(state) {
        this.stateHandlers = [];
        this.actionHandlers = {};

        this.state = state;
        this.isDispatching = false;

        this.actionsQueue = [];
    }

    onStateChange = (handler) => {
        const handlerIndex = this.stateHandlers.indexOf(handler);

        if (handlerIndex === -1) {
            this.stateHandlers.push(handler);
        }
    };

    addActionHandler = (action, handler) => {
        if(this.actionHandlers[action]){
            throw new Error("This action is already being handled");
        }

        this.actionHandlers[action] = handler;
    };

    removeActionHandler = (action) => {
        this.actionHandlers[action] = null;
        delete this.actionHandlers[action];
    };

    dispatch = (actionName, payload) => {
        ScreenController.lock();

        if(this.isDispatching){
            this.actionsQueue.push({actionName, payload});
            console.log(`[Queued] ${actionName}`, payload);
            return;
        }

        console.log(`[Dispatch] ${actionName}`, payload);

        this.isDispatching = true;

        const actionHandler = this.actionHandlers[actionName];

        if(actionHandler){
            this.state = this.actionHandlers[actionName]({...this.state}, payload);

            this.stateHandlers.forEach(handler => {
                handler(this.state);
            });
        }


        this.isDispatching = false;

        ScreenController.unlock();
        ScreenController.execute();

        this.checkQueue();
    };

    checkQueue = () => {
        if(this.actionsQueue.length){
            let nextAction = this.actionsQueue.pop();
            this.dispatch(nextAction.actionName, nextAction.payload);
        } else {
            console.log(`[Queue Empty]`);
        }
    };

    getState = () => {
        return this.state;
    };
}

let store = new Store(null);

export let onStateChanged = store.onStateChange;
export let addActionHandler = store.addActionHandler;
export let dispatch = store.dispatch;
export let getState = store.getState;
export let initStore = state => store.state = state;
