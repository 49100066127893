import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FontConfig from "./FontConfig";
import ImagePassepartoutConfig from "./ImagePassepartoutConfig";

class ImagesConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, images: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getImages(this.props.type).then(data => {
                this.setState({loading: false, images: data.data.images});
            });
        });

    }

    render() {
        const passepartouts = this.getPassepartouts();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Header as='h4'>Passepartout</Header>
                <Item.Group divided relaxed>
                    {passepartouts}
                </Item.Group>
                <Button style={{marginTop: '3rem'}} content='add new passepartout' basic
                        onClick={this.onAddNewPassepartoutClicked}/>
            </Container>
        );
    }

    getPassepartouts = () => {
        if (this.state.images.length === 0) {
            return null;
        }

        let images = this.state.images.filter(i=>i.category==='passepartout');

        if(images.length === 0){
            return null;
        }

        return images.map(image => {
            return (
                <ImagePassepartoutConfig key={image.id} config={this.props.config} data={image}
                                onSavedClicked={this.onPassepartoutImageChanged}/>
            )
        })
    };

    onPassepartoutImageChanged = (image) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editImage(image).then(data => {
                this.setState({loading: false, images: data.data.images});
            });
        });
    };

    onAddNewPassepartoutClicked = () => {
        let images = [...this.state.images];
        images.push({
            editing: true,
            id: -1,
            name: '',
            category: 'passepartout',
            meta: {scale9:''},
            type: this.props.type
        });

        this.setState({
            images: images
        })
    };
}


export default ImagesConfig;
