import React, {Component} from 'react';
import './OrdersTabNavigator.scss';
import {Generate} from "./utils/checksum";
import OrderStatusToggler from "./order/OrderStatusToggler";
import {Button} from "semantic-ui-react";

class OrdersTabNavigator extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef()

    }

    render() {
        const items = this.getItems();
        if(this.myRef && this.myRef.current) {
            this.myRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }

        return (
            <div className='OrdersTabNavigator'>
                <div className="rightSide">
                    <div className="orderItems">
                        {items}
                    </div>
                </div>

                <div className="leftSide">
                    <div>
                        {this.props.tab &&
                        <OrderStatusToggler status={this.props.tab}
                                            enabled={true}
                                            onStatusChanged={(status) => this.props.onStatusChanged(status)}/>
                        }
                    </div>
                    <div className="refresh"><Button disabled={this.props.refreshing} onClick={this.props.onRefreshClicked}>Refresh</Button></div>
                    <div className="buttons">
                        <div className="button" onClick={this.props.onPrevOrderClicked}>{"<"}</div>
                        <div className="button" onClick={this.props.onNextOrderClicked}>{">"}</div>
                    </div>
                </div>

            </div>
        );
    }

    getItems = () => {
        let orders = [...this.props.orders];
        let items = [];
        for(let i=0; i<orders.length; i++){
            let order = orders[i];
            if(this.props.selectedId?.toString()===order.id.toString()){
                items.push(
                    <div key={order.id} ref={this.myRef} className="orderItem selected" onClick={()=>this.props.onOrderSelected(order.id)}>
                        <div className="orderId">{Generate(order.id)}</div>
                        <div className="client">{order.external_meta.user_name}</div>
                        <span className="arrow">&#8594;</span>
                    </div>
                );
            } else {
                items.push(
                    <div key={order.id} className="orderItem" onClick={()=>this.props.onOrderSelected(order.id)}>
                        <div className="orderId">{Generate(order.id)}</div>
                        <div className="client">{order.external_meta.user_name}</div>
                        <span className="arrow">&#8594;</span>
                    </div>
                );
            }


        }

        // if(items.length === 0 && !this.props.refreshing){
        //     return (
        //         <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>No order with this status</div>
        //     )
        // }

        return items;
    }

}


export default OrdersTabNavigator;
