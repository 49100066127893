import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Input, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FoilConfig from "./FoilConfig";
import EmbossTemplateConfig from "./EmbossTemplateConfig";

class AwbComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            awbConfig: {
                w: '',
                h: '',
                d: '',
                kg: '',
            }
        };
    }


    render() {
        // const existingAwbElement = this.getExistingAwbElement();
        const estimateAwbElement = this.getEstimateAwbElement();

        return (
            <Container>
                {estimateAwbElement}
            </Container>
        );
    }

    getEstimateAwbElement = () => {
        if(!this.state.expanded){
            return null;
        }

        const estimatedeAwbElement = this.getEstimatedAwbElement();

        return (
            <div>
                <p style={{whiteSpace: 'pre'}}>
                    {this.props.data.shiping.address}
                </p>
                <div>
                    <Input style={{padding: '1rem'}} size='mini' name='w' label='W' placeholder='10'
                           value={this.state.awbConfig.w}
                           onChange={(e, {name, value}) => this.handleAwbValueChange(name, value)}/>

                    <Input style={{padding: '1rem'}} size='mini' name='h' label='H' placeholder='11'
                           value={this.state.awbConfig.h}
                           onChange={(e, {name, value}) => this.handleAwbValueChange(name, value)}/>

                    <Input style={{padding: '1rem'}} size='mini' name='d' label='D' placeholder='12'
                           value={this.state.awbConfig.d}
                           onChange={(e, {name, value}) => this.handleAwbValueChange(name, value)}/>

                    <Input style={{padding: '1rem'}} size='mini' name='kg' label='KG' placeholder='1.75'
                           value={this.state.awbConfig.kg}
                           onChange={(e, {name, value}) => this.handleAwbValueChange(name, value)}/>

                </div>

                <Button primary onClick={this.onGenerateAwbClicked}>Get price</Button>

                {estimatedeAwbElement}
            </div>
        )
    };

    onGenerateAwbClicked = () => {
        let awbData = this.props.data.shiping;
        awbData.package = this.state.awbConfig;

        ApiService.getAwbPrices(awbData).then(data => {
            this.setState({estimateAwb: data.data});
        });
    };

    handleAwbValueChange = (key, value) => {
        let awbConfig = {...this.state.awbConfig};
        awbConfig[key] = value;

        this.setState({awbConfig: awbConfig})
    };

    getEstimatedAwbElement = () => {
        if (!this.state.estimateAwb) {
            return null;
        }

        return (
            <div>ES</div>
        )
    }

}


export default AwbComponent;
