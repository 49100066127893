import React, {Component} from 'react';
import './OrderTimeline.scss';
import {Dropdown, Icon} from "semantic-ui-react";

class OrderDeadline extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deadlines: [],
            selected: ''
        }
    }

    componentDidMount() {
        let deadlines = this.getDeadlines();
        if(this.props.deadline){
            if(!deadlines.find(i=>i.value === this.props.deadline)){
                deadlines.unshift({
                    key: this.props.deadline.split('-').reverse().join('-'),
                    text: this.props.deadline.split('-').reverse().join('-'),
                    value: this.props.deadline
                })
            }
        }
        this.setState({selected: this.props.deadline, deadlines: deadlines})
    }

    render() {
        return (
            <div className='OrderDeadline'>
                <div style={{marginBottom: '10px', paddingBottom: '4px', borderBottom: '2px solid #dededf', fontSize: '15px'}}>{this.props.label}</div>
                <Dropdown closeOnBlur
                          search selection name='orderDeadline' options={this.state.deadlines} placeholder={this.props.label} value={this.state.selected} onChange={this.onDeadlineSelected}/>
            </div>
        )
    }

    getDeadlines = () => {
        let deadLines = [];
        let date = new Date();

        for(let i=0; i<100; i++){
            let formatted = date.toISOString().split('T')[0].split('-');
            formatted = `${formatted[2]}-${formatted[1]}-${formatted[0]}`;

            deadLines.push(formatted);
            date.setDate(date.getDate()+1);
        }

        return deadLines.map(item=>{
            return {
                key: item,
                text: item,
                value: item.split('-').reverse().join('-')
            }
        })
    };

    onDeadlineSelected = (e, {value}) => {
        this.setState({selected: value}, ()=>{
            this.props.onDeadlineSelected(value);
        });
    }

}


export default OrderDeadline;
