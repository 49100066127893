import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import SizesConfig from "../components/SizesConfig";
import MaterialsConfig from "../components/MaterialsConfig";
import StylesConfig from "../components/StylesConfig";
import FontsConfig from "../components/FontsConfig";
import EmbossConfig from "../components/EmbossConfig";
import UVPrintConfig from "../components/UVPrintConfig";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import CornersConfig from "../components/CornersConfig";
import ClassicAlbumSizeConfig from "../components/ClassicAlbumSizeConfig";
import ClassicAlbumCoverSizeConfig from "../components/ClassicAlbumCoverSizeConfig";

class ClassicAlbumsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'bloc'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='green' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Classic albums configuration</Menu.Item>
                    <Menu.Item
                        name='Bloc'
                        active={activeItem === 'blocs'}
                        onClick={() => this.setState({activeItem: 'blocs'})}
                    />

                    <Menu.Item
                        name='Cover size'
                        active={activeItem === 'covers'}
                        onClick={() => this.setState({activeItem: 'covers'})}
                    />

                    <Menu.Item
                        name='Styles'
                        active={activeItem === 'styles'}
                        onClick={() => this.setState({activeItem: 'styles'})}
                    />

                    <Menu.Item
                        name='Cover Materials'
                        active={activeItem === 'covers-materials'}
                        onClick={() => this.setState({activeItem: 'covers-materials'})}
                    />
                </Menu>


                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'blocs':
                return (<ClassicAlbumSizeConfig type='classic-album-bloc'/>);
            case 'covers':
                return (<SizesConfig type='classic-album-cover'/>);
            case 'styles':
                return (<StylesConfig type='classic-album-cover' config={this.props.config}/>);
            case 'covers-materials':
                return (<div>
                    <a href='/materials'>Set it up via materals page</a>
                </div>);
            default :
                return null;
        }
    }

}


export default ClassicAlbumsScreen;
