import React, {Component} from 'react';
import {
    Button,
    Container, Input,
} from "semantic-ui-react";

import './NewOrderScreen.scss';
import ApiService from "../services/ApiService";
import AwbAddress from "../components/AwbAddress";
import UserSearchByName from "../components/UserSearchByName";
import {Generate} from "../components/utils/checksum";

class NewOrderScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            addressEditable: false,
            user_id: null,
            address: null,
            currency:'',
            amount:'',
            addresses: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.user_id !== this.state.user_id){
            this.loadUserAddreses(this.state.user_id)
        }
    }

    render() {
        let canOrder = true;

        if(this.state.currency.length !== 3){
            canOrder = false;
        }

        if(isNaN(parseFloat(this.state.amount)) === true){
            canOrder = false;
        }

        return (
            <Container style={{margin: '3rem 0'}} className='NewOrderScreen'>
                <div className='zone'>
                    <div className='title'>Client</div>
                    <UserSearchByName onUserSelected={this.onUserSelected}/>
                </div>

                <div className='zone'>
                    <div className='title'>Addresses</div>

                    {this.state.addresses.length > 0 &&
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {this.state.addresses.map(address=>{
                                return (
                                    <div style={{padding: '20px'}}>
                                        <div>{address.name}</div>
                                        <div>{address.email}</div>
                                        <div>{address.first_line} {address.second_line}</div>
                                        <div>{address.zip} {address.city} {address.state}</div>
                                        <div>{address.country}</div>
                                        <Button onClick={()=>this.onAddressSelected(address)} primary style={{marginTop:'15px'}}>Select</Button>
                                    </div>
                                )
                            })}
                        </div>
                    }

                </div>

                {this.state.address &&
                <div className='zone'>
                    <div className='title' style={{marginBottom:'10px'}}>Order total</div>
                    <Input value={this.state.currency} onChange={(e,data)=>this.setState({currency: data.value})} placeholder="USD/EUR"></Input>
                    <Input value={this.state.amount} onChange={(e,data)=>this.setState({amount: data.value})} placeholder="199.99"></Input>
                </div>
                }

                {this.state.address &&
                <div className='zone'>
                    <div className='title'>New order</div>
                    <Button disabled={!canOrder} style={{marginTop: '10px'}} primary onClick={this.onCreateOrderClicked}>Create</Button>
                </div>
                }
            </Container>
        );
    }

    onCreateOrderClicked = () => {
        this.setState({loading: true}, ()=>{
            ApiService.newOrder({
                users_id: this.state.user_id,
                address: this.state.address,
                currency: this.state.currency,
                amount: this.state.amount
            }).then(data=>{
                window.location = `/order/${Generate(data.data.id)}`;
            })
        })
    }

    onAddressSelected = (address) => {
        this.setState({addresses:[address], address: address})
    }

    onUserSelected = (user_id, address) => {
        if(address){
            this.setState({user_id: user_id, addresses: [address], address: address})
        } else {
            this.setState({user_id: user_id, addresses: [], address: null})
        }
    }

    onEditAddressClicked = () => {
        this.setState({addressEditable: true})
    }

    loadUserAddreses = () => {
        ApiService.loadUserAddresses(this.state.user_id).then(data=>{
            this.setState({addresses: [...this.state.addresses, ...data.data]})
        })
    }

}


export default NewOrderScreen;
