import React, {Component} from 'react';
import {Button, Checkbox, Container, Image, Input, Item, Label} from "semantic-ui-react";

class ShippingBoxConfig extends Component {
    constructor(props) {
        super(props);

            this.state = {
                editing: this.props.data.editing,

                code: this.props.data.code,
                width: this.props.data.width,
                height: this.props.data.height,
                depth:this.props.data.depth,
                supplier: this.props.data.supplier,
                supplier_code: this.props.data.supplier_code,

                available: this.props.data.available === 1,
                type: this.props.data.type
            }

    }

    render() {
        const editButton = this.getEditButton();
        const editingElement = this.getEditingElement();

        const availableStyle = this.props.data.available ? {color: 'green'} : {color: 'red'};

        return (
            <Item>
                <Item.Image size='tiny' src='https://via.placeholder.com/150'/>

                <Item.Content>
                    <Item.Header as='a'>
                        {this.props.data.code} {editButton}
                    </Item.Header>
                    <Item.Description>
                        <p>{`${this.props.data.width} x ${this.props.data.height} x ${this.props.data.depth}`} cm</p>
                    </Item.Description>

                    {editingElement}

                    {this.state.editing &&
                    <Button style={{marginTop: '15px'}} compact primary onClick={this.onSaveClicked}>Save</Button>
                    }
                </Item.Content>
            </Item>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return null;
        }

        return (
            <Button compact basic icon='pencil alternate' onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        if(!this.state.editing){
            return false;
        }

        return (
            <Container>
                <br/>
                <br/>
                <Input label='code' placeholder='B1' name='code' value={this.state.code} onChange={this.handleChange}/>
                <br/>
                <br/>
                <Input label='width cm' placeholder='10' name='width' value={this.state.width} onChange={this.handleChange}/>
                <br/>
                <br/>
                <Input label='height cm' placeholder='10' name='height' value={this.state.height} onChange={this.handleChange}/>
                <br/>
                <br/>
                <Input label='depth cm' placeholder='10' name='depth' value={this.state.depth} onChange={this.handleChange}/>
                <br/>
                <br/>
                <Input label='supplier' placeholder='Box SRL' name='supplier' value={this.state.supplier} onChange={this.handleChange}/>
                <br/>
                <br/>
                <Input label='supplier_code' placeholder='B-5cm-123' name='supplier_code' value={this.state.supplier_code} onChange={this.handleChange}/>
                <br/>
                <br/>
                <Checkbox label='available' name='available' toggle checked={this.state.available}  onChange={this.onCheckboxChanged}/>
            </Container>
        )
    };

    onSaveClicked =() => {
        this.setState({editing: false}, ()=>{
            this.props.onSavedClicked({
                id: this.props.data.id,
                code: this.state.code,
                width: this.state.width,
                height: this.state.height,
                depth: this.state.depth,
                supplier: this.state.supplier,
                supplier_code: this.state.supplier_code,

                available: this.state.available,
                type: this.state.type
            })
        });

    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });
    onCheckboxChanged = (e, { name, checked }) => this.setState({ [name]: checked });

}


export default ShippingBoxConfig;
