import React, {Component} from 'react';
import "./ShippmentItems.scss";
import {Button, Modal, Table} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import ItemWeights from "./ItemWeights";
import PopOverIcon from "./PopOverIcon";
import {config} from "../config/config";

class ShippmentItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            weights: [],
            packing:[],
            invoice: {},
            modalData: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.mainOrderId !== this.props.mainOrderId) {
            this.fetchData();
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const items = this.getItemsToRender();
        const invoice = this.getInvoiceToRender();
        const boxes = this.getBoxesElement();

        let anyStockErrors = false;
        this.state.items.forEach(item=>{
            if(item.stocAvailabilityError){
                anyStockErrors = true;
            }
        })

        return (
            <div className='ShippmentItems'>

                <div className="title">Packing boxes</div>
                <div className="items">
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Box name</Table.HeaderCell>
                                <Table.HeaderCell>Box type</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {boxes}
                        </Table.Body>
                    </Table>
                </div>

                <div className="title"> To Pack</div>
                <div className="items">
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>Amount</Table.HeaderCell>
                                <Table.HeaderCell>Item</Table.HeaderCell>
                                <Table.HeaderCell>Reference</Table.HeaderCell>
                                <Table.HeaderCell>Export code</Table.HeaderCell>
                                <Table.HeaderCell>Weight</Table.HeaderCell>
                                <Table.HeaderCell>Notes</Table.HeaderCell>
                                <Table.HeaderCell>In stoc</Table.HeaderCell>
                                <Table.HeaderCell>In Box</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {items}
                        </Table.Body>
                    </Table>
                </div>
                <div className="footer">
                    <div className="invoice">{invoice}</div>
                    {anyStockErrors &&
                    <div style={{fontSize: '16px', color:'red', marginRight:'10px', fontWeight:'bold'}}>Not enough in inventory</div>
                    }
                    {this.props.mainOrderStatus === 'packing'
                        ? <Button disabled>Packing in progress</Button>
                        : <Button disabled={anyStockErrors} primary onClick={this.onStartClicked}>START</Button>
                    }

                    {this.props.mainOrderStatus === 'packing'
                        ? <Button disabled={anyStockErrors} primary onClick={this.props.onCreateAWBClicked}>AWB</Button>
                        : <Button disabled>AWB</Button>
                    }

                    {this.props.mainOrderStatus === 'packing'
                        ? <Button disabled={anyStockErrors} primary onClick={this.onFinishedClicked}>Finished</Button>
                        : <Button disabled>Finished</Button>
                    }

                    <Button color="red"  onClick={this.onSkipClicked}>Skip</Button>

                </div>
                <ItemWeights items={this.state.items} weights={this.state.weights}/>
                {this.state.modalData &&
                <Modal
                    onClose={() => this.setState({modalData: null})}
                    open={this.state.modalData}>
                    <Modal.Header>{this.state.modalData.ref}</Modal.Header>
                    <Modal.Content image>
                        <Modal.Description>
                            <div style={{display: 'flex'}}>
                                <h5>Size:&nbsp;</h5>
                                <div>{this.state.modalData.meta.size.value}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <h5>Pages:&nbsp;</h5>
                                <div>{this.state.modalData.meta.nr_pages}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <h5>Material:&nbsp;</h5>
                                <div>{this.state.modalData.meta.material.value}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <h5>Emboss:&nbsp;</h5>
                                <div>{this.state.modalData.meta.emboss.label}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <h5>Emboss Text:&nbsp;</h5>
                                <div>
                                    <div>{this.state.modalData.meta.emboss_l1_text}</div>
                                    <div>{this.state.modalData.meta.emboss_l2_text}</div>
                                    <div>{this.state.modalData.meta.emboss_l3_text}</div>
                                </div>
                            </div>

                            {this.state.modalData.meta.cover_uv_image &&
                            <div style={{display: 'flex', flexDirection:'column'}}>
                                <h5>Mock:&nbsp;</h5>

                                <div>
                                    <img src={`${config.ASSETS_PATH}/products/uv-packing-check/${this.state.modalData.meta.cover_uv_image}`}/>
                                </div>
                            </div>
                            }


                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => this.setState({modalData: null})}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                }
            </div>
        );
    }

    getBoxesElement = () => {
        return this.state.packing.map(box=>{
            return (
                <Table.Row >
                    <Table.Cell>Box {box.id}</Table.Cell>
                    <Table.Cell>{box.type}</Table.Cell>
                </Table.Row>
            )
        })
    }

    onStartClicked = async () => {
        const data = await ApiService.mergeInvoicesForShipping(this.props.mainOrderId);
        window.location.reload();
    }

    onSkipClicked = async () => {
        await ApiService.updateOrderStatus({orderId: this.props.mainOrderId, status:'issue'});
        window.location.reload();
    }

    onFinishedClicked = async () => {
        let consumptionData = [];
        this.state.items.forEach(item=>{
            if(item.consumptionSKU){
                consumptionData.push({
                    sku: item.consumptionSKU,
                    amount: item.quantity,
                    item: item.ref,
                    itemId: item.id,
                })
            }
        })

        const data = await ApiService.finishOrderPacking(this.props.mainOrderId, consumptionData);
        window.location.reload();
    }

    getInvoiceToRender = () => {
        if (!this.state.invoice.id) {
            return (
                <div style={{fontWeight: 'bold', color: "red", fontSize: '20px'}}>
                    Unable to find a suitable invoice
                </div>
            );
        }

        return (
            <div style={{fontWeight: 'bold'}}>
                Will send invoice: {this.state.invoice.series}-{this.state.invoice.series_count}
            </div>
        )
    }

    getItemsToRender = () => {
        if (this.state.items.length === 0) {
            return null;
        }

        return this.state.items.map(item => {
            let goesInBox = null;

            this.state.packing.forEach(box=>{
                box.items.forEach(it=>{
                    console.log(it, item)
                    if(it.partId === item.id){
                        goesInBox = `Box ${box.id}`;
                    }
                })
            })

            return (
                <Table.Row style={{cursor: 'pointer'}} onClick={(e) => this.onItemClicked(item)} error={item.stocAvailabilityError}>
                    <Table.Cell>{item.quantity}</Table.Cell>
                    <Table.Cell>
                        <div><b>{item.name}</b></div>
                        <div style={{fontSize:'12px', marginTop:"10px"}}>{item?.meta?.emboss_l1_text}</div>
                        <div style={{fontSize:'12px'}}>{item?.meta?.emboss_l2_text}</div>
                        <div style={{fontSize:'12px'}}>{item?.meta?.emboss_l3_text}</div>
                    </Table.Cell>
                    <Table.Cell>{item.ref}</Table.Cell>
                    <Table.Cell>{item.exportCode}</Table.Cell>
                    <Table.Cell>{item.weight}</Table.Cell>
                    <Table.Cell>
                        {item.mentions &&
                        <PopOverIcon icon='warning sign' text={item.mentions}/>
                        }
                    </Table.Cell>
                    <Table.Cell >{item.stocAvailability}</Table.Cell>
                    <Table.Cell>{goesInBox}</Table.Cell>
                </Table.Row>
            )
        })
    }

    onItemClicked = (item, e) => {
        if (
            item.product_type === 'guestbook' ||
            item.product_type === 'album' ||
            item.product_type === 'classic-album' ||
            item.product_type === 'boudoir' ||
            item.product_type === 'boudoir-empty') {

            this.setState({modalData: item})
        }
    }

    fetchData = async () => {
        if (!this.props.mainOrderId) {
            return;
        }
        const data = await ApiService.fetchShippmentItemsForOrder(this.props.mainOrderId);
        const weights = await ApiService.getOrderPieceWeights();

        let items = data.data.items;

        for(let i=0; i<items.length; i++){
            let item = items[i];
            if(item.product_type === 'pen'){
                let penSKU = `PEN-${item.meta.color}`;
                item.consumptionSKU = penSKU;

                let amount = item.quantity;

                let inStoc = await ApiService.stockGetItemBySKU(penSKU);
                inStoc = inStoc.data[0]?.quantity ?? 0;

                item.stocAvailability = inStoc;
                if(amount > inStoc){
                    item.stocAvailabilityError = true;
                }
            }
            else if(item.product_type === 'corners'){

                let amount = item.quantity;

                let color = '';
                if(item.meta.corners_white){
                    color = "white"
                }
                if(item.meta.corners_black){
                    color = "black"
                }
                if(item.meta.corners_kraft){
                    color = "kraft"
                }

                let cornerSKU = `CORNER-${color}`;
                item.consumptionSKU = cornerSKU;

                let inStoc = await ApiService.stockGetItemBySKU(cornerSKU);
                inStoc = inStoc.data[0]?.quantity ?? 0;

                item.stocAvailability = inStoc;
                if(amount > inStoc){
                    item.stocAvailabilityError = true;
                }
            } else {
                item.stocAvailability = "X";
            }
        }

        const packing = await ApiService.getOrderPacking(this.props.mainOrderId)

        this.setState({
            items: data.data.items,
            weights: weights.data,
            invoice: data.data.invoice,
            packing: packing.data
        });
    }

}


export default ShippmentItems;
