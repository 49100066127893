import React from 'react';

import './OrderBadgeFilters.scss';

export class OrderBadgeFilters extends React.Component {
    render() {

        return (
            <div className='OrderBadgeFilters'>
                <div className={this.props.value === 'guestbook' ? 'item selected' : 'item'} onClick={()=>{
                    this.props.onFiltersChange('guestbook');
                }}>G</div>

                <div className={this.props.value === 'album' ? 'item selected' : 'item'} onClick={()=>{
                    this.props.onFiltersChange("album");
                }}>A</div>

                <div className={this.props.value === 'boudoir' ? 'item selected' : 'item'} onClick={()=>{
                    this.props.onFiltersChange("boudoir");
                }}>B</div>

                <div className={this.props.value === 'classicAlbum' ? 'item selected' : 'item'} onClick={()=>{
                    this.props.onFiltersChange("classicAlbum");
                }}>CA</div>

                <div className={this.props.value === 'guestbook-slots' ? 'item selected' : 'item'} onClick={()=>{
                    this.props.onFiltersChange("guestbook-slots");
                }}>GS</div>
            </div>
        )
    }


}

export default OrderBadgeFilters;
