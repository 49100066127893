import React, {Component} from 'react';
import './MaterialConfig2.scss';
import {config} from "../config/config";
import {Button, Checkbox, Image} from "semantic-ui-react";
import MaterialEditor from "./MaterialEditor";

class MaterialConfig2 extends Component {
    render() {
        let thumb = 'https://via.placeholder.com/400?text=no%20image';
        const material = this.props.data;
        if (material.meta && material.meta.imageThumb) {
            thumb = `${config.ASSETS_PATH}/products/material/${material.meta.imageThumb}`
        }

        let className = 'MaterialConfig2';
        if (this.props.editing) {
            className += ' editing';
        }

        const editingElement = this.getEditingElement();

        const checkmarkClassName = material.valid === 1 ? 'checkmark' : 'checkmark hide';

        return (
            <div className={className}>
                <div className='header'>
                    <div className='image'>
                        <div className={checkmarkClassName}><svg xmlns="http://www.w3.org/2000/svg" fill='#4caf50' width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg></div>
                        <Image src={thumb}
                               size='tiny'/>
                    </div>

                    <div className='name-col name'>
                        <h3 className='name'>{material.name}</h3>
                        <div>{material.internal_code}</div>
                    </div>

                    <div className='label-with-value category'>
                        <div className='label'>
                            category
                        </div>
                        <div className='value'>
                            {material.category}
                        </div>
                    </div>

                    <div style={{marginRight: '40px'}}></div>

                    <div className='label-with-value'>
                        <div className='label'>
                            available
                        </div>
                        <div className='value'>
                            <Checkbox checked={material.available}
                                      onChange={(event, {checked}) => this.onCheckboxChanged('available', checked)}/>
                        </div>
                    </div>

                    <div style={{marginRight: '40px'}}></div>

                    <div className='label-with-value'>
                        <div className='label'>
                            albums
                        </div>
                        <div className='value'>
                            <Checkbox checked={material.for_album} onChange={(event, {checked}) => this.onCheckboxChanged('for_album', checked)}/>
                        </div>
                    </div>

                    <div className='label-with-value'>
                        <div className='label'>
                            guestbooks
                        </div>
                        <div className='value'>
                            <Checkbox checked={material.for_guestbook}  onChange={(event, {checked}) => this.onCheckboxChanged('for_guestbook', checked)}/>
                        </div>
                    </div>

                    <div className='label-with-value'>
                        <div className='label'>
                            boudoirs
                        </div>
                        <div className='value'>
                            <Checkbox checked={material.for_boudoir}  onChange={(event, {checked}) => this.onCheckboxChanged('for_boudoir', checked)}/>
                        </div>
                    </div>

                    <div className='label-with-value'>
                        <div className='label'>
                            boudoir-empty
                        </div>
                        <div className='value'>
                            <Checkbox checked={material['for_boudoir-empty']}  onChange={(event, {checked}) => this.onCheckboxChanged('for_boudoir-empty', checked)}/>
                        </div>
                    </div>

                    <div className='label-with-value'>
                        <div className='label'>
                           classic album
                        </div>
                        <div className='value'>
                            <Checkbox checked={material['for_classic-album']}  onChange={(event, {checked}) => this.onCheckboxChanged('for_classic-album', checked)}/>
                        </div>
                    </div>

                    <div style={{marginRight: '40px'}}></div>

                    <div className='label-with-value'>
                        <div className='label'>
                            uv
                        </div>
                        <div className='value'>
                            <Checkbox checked={material.canUvPrint}  onChange={(event, {checked}) => this.onCheckboxChanged('canUvPrint', checked)}/>
                        </div>
                    </div>

                    <div className='label-with-value'>
                        <div className='label'>
                            emboss
                        </div>
                        <div className='value'>
                            <Checkbox checked={material.canEmboss}  onChange={(event, {checked}) => this.onCheckboxChanged('canEmboss', checked)}/>
                        </div>
                    </div>

                    <div style={{marginRight: '40px'}}></div>

                    {this.props.editing === false &&
                    <div className='label-with-value'>
                        <Button color='red' size='tiny'
                                onClick={() => this.props.onEditClicked(material.id)}>edit</Button>
                    </div>
                    }
                </div>

                {editingElement}
            </div>
        )
    }

    getEditingElement = () => {
        if (this.props.editing === false) {
            return null;
        }

        return (
            <MaterialEditor
                data={this.props.data}
                onDataChanged={this.props.onDataChanged}
                onMaterialDeleteClicked={this.props.onMaterialDeleteClicked}
                onVisualValidToggled={this.props.onVisualValidToggled}
            />
        )
    }

    onCheckboxChanged = (name, value) => {
        let material = {...this.props.data};
        material[name] = value === false ? 0 : 1;
        this.props.onDataChanged(material)
    }

}


export default MaterialConfig2;
