import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Divider,
    Grid,
    Header,
    Image,
    Input,
    Item,
    Label,
    Loader, Segment, Table
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PriceConfig from "./PriceConfig";
import TemplateConfig from "./TemplateConfig";
import {config} from "../config/config";

class EmbossTemplateConfig extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();

        this.state = {
            editing: this.props.data.editing,

            name: this.props.data.name,
            imagePath: this.props.data.imagePath,
            meta: this.props.data.meta,
            available: this.props.data.available === 1,
            type: this.props.data.type,
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            name: nextProps.data.name,
            imagePath: nextProps.data.imagePath,
            meta: nextProps.data.meta,
            available: nextProps.data.available === 1,
            type: nextProps.data.type,
        })
    }

    render() {
        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }

        const item = this.props.data;

        if (this.state.editing) {
            return this.getEditingElement();
        }

        let previewImage = null;
        if(this.state.meta && this.state.meta.image){
            previewImage = `${config.ASSETS_PATH}/products/text-templates/${this.state.meta.image}`;
        }

        let lines = null;
        if(this.state.meta && this.state.meta.lines){
            lines = this.state.meta.lines.length;
        }

        return (
            <Table.Row key={item.id}>
                <Table.Cell><Image src={previewImage}
                                   size='small'/></Table.Cell>
                <Table.Cell><h5>{item.name}</h5></Table.Cell>
                <Table.Cell>{lines}</Table.Cell>
                <Table.Cell>
                    <div style={availableStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return (
                <Button compact basic color='green' icon='check' onClick={this.onSaveClicked}/>
            )
        }

        return (
            <Button basic icon='pencil alternate' compact onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        const item = this.props.data;

        return (
            <div style={{padding: '30px'}}>
                <Loader active={this.state.loading}/>

                <h2>{item.name}</h2>

                <br/>
                <br/>


                <div style={{display: 'flex'}}>
                    <div style={{margin: '0 10px'}}>
                        <h5>Template name</h5>
                        <Input placeholder='name' name='name' value={this.state.name}
                               onChange={this.handleChange}/>
                    </div>
                    <div style={{margin: '0 10px'}}>
                        <h5 style={{width: '150px'}}>Template availability</h5>
                        <Checkbox label='available' name='available' toggle style={{marginTop: '15px'}}
                                  checked={this.state.available}
                                  onChange={this.onCheckboxChanged}/>
                    </div>

                </div>

                <br/>
                <br/>

                <div style={{display: 'flex', margin: '40px 0'}}>
                    <div style={{margin: '0 10px', minWidth:'200px'}}>
                        <h5>Template image  <br/>800x800px</h5>
                        <Image src={`${config.ASSETS_PATH}/products/text-templates/${this.state.meta.image}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.fileInputRef.current.click()}
                        />
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            hidden
                            onChange={this.onImageSelected}
                        />
                    </div>

                    <div style={{margin: '0 10px'}}>
                        <h5>Configuration</h5>
                        <TemplateConfig fonts={this.props.fonts} lines={this.state.meta.lines}
                                        onSaveClicked={this.onLinesSaveClicked}/>
                    </div>
                </div>

                <Button basic onClick={this.onSaveClicked}>Save</Button>

            </div>
        )
    };

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onTemplateSavedClicked({
                id: this.props.data.id,
                name: this.state.name,
                imagePath: this.state.imagePath,
                available: this.state.available,
                meta: this.state.meta,
                type: this.state.type
            })
        });
    };

    onLinesSaveClicked = (lines) => {
        let meta = {...this.state.meta};
        meta.lines = lines;

        this.setState({editing: false}, () => {
            this.props.onTemplateSavedClicked({
                id: this.props.data.id,
                name: this.state.name,
                imagePath: this.state.imagePath,
                available: this.state.available,
                meta: meta,
                type: this.state.type
            })
        });
    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

    onImageSelected = (e) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        ApiService.uploadImage('text-templates', data).then(r => {
            let meta = {...this.state.meta};
            meta.image = r.data.image;

            this.setState({
                loading: false,
                meta: meta
            })
        });
    }

}


export default EmbossTemplateConfig;
