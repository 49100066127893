import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import SizesConfig from "../components/SizesConfig";
import MaterialsConfig from "../components/MaterialsConfig";
import StylesConfig from "../components/StylesConfig";
import FontsConfig from "../components/FontsConfig";
import EmbossConfig from "../components/EmbossConfig";
import UVPrintConfig from "../components/UVPrintConfig";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import CornersConfig from "../components/CornersConfig";

class CornersScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'empty'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='grey' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Corners configuration</Menu.Item>
                    <Menu.Item
                        name='colors'
                        active={activeItem === 'colors'}
                        onClick={() => this.setState({activeItem: 'colors'})}
                    />
                </Menu>

                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'colors':
                return (<CornersConfig type='corners'/>);
            default :
                return null;
        }
    }

}


export default CornersScreen;
