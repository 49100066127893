import React, {Component} from 'react';
import './BlocStockNeeds.scss';
import {Button, Checkbox, Dropdown, Input, Table} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class BlocStockNeeds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditCoef: false,
            showMergeSpreads: false,
            showFilterBySize: false,
            showMergeSizes: false,
            sortProperty: '',
            sortType: 'string',
            sortDirection: 'none',
            data: {total: 0, items: [], filtered:[]}
        }
    }

    componentDidMount() {
        this.loadData();
    }

    onNewSpreadMerge = async (initial, item) => {
        let newMerge = `${initial}->${item}`;
        await ApiService.updateBlockStocNeedsSpreadsMerge({merge: newMerge, action: 'add'});
        this.loadData();
    }

    onNewSizeMerge = async (initial, item) => {
        let newMerge = `${initial}->${item}`;
        await ApiService.updateBlockStocNeedsSizesMerge({merge: newMerge, action: 'add'});
        this.loadData();
    }

    getDropdownForSameSize = (size) => {
        let data = [...this.state.data.pageMapArrayMerged] ?? [];
        let sizePart = size.split(':')[0];

        data = data.filter(i => i.indexOf('->') === -1 && i !== size);

        let options = data.map(i => {
            return {
                key: i,
                value: i,
                text: i,
                id: i
            }
        });

        return (
            <Dropdown
                options={options}
                selection compact
                value={null}
                search
                style={{minWidth: '100px'}}
                onChange={(e, item) => this.onNewSpreadMerge(size, item.value)}
            />
        )
    }

    getDropdownForSameSizeForSize = (size) => {
        let data = [...this.state.data.sizeMapArrayMerged] ?? [];

        data = data.filter(i => i.indexOf('->') === -1 && i !== size);

        let options = data.map(i => {
            return {
                key: i,
                value: i,
                text: i,
                id: i
            }
        });

        return (
            <Dropdown
                options={options}
                selection compact
                value={null}
                search
                style={{minWidth: '100px'}}
                onChange={(e, item) => this.onNewSizeMerge(size, item.value)}
            />
        )
    }

    onUnMergeSpreadsClicked = async (item) => {
        await ApiService.updateBlockStocNeedsSpreadsMerge({merge: item, action: 'remove'});
        this.loadData();
    }

    onUnMergeSizeClicked = async (item) => {
        await ApiService.updateBlockStocNeedsSizesMerge({merge: item, action: 'remove'});
        this.loadData();
    }

    onCoefChange = (value, key) =>{
        let newData = {...this.state.data};
        let newItems = [...newData.filtered];

        let itemToChange = newItems.find(i=>i.key === key);
        itemToChange.coef = parseInt(value);

        newData.filtered = newItems;
        this.setState({data:newData});
    }

    onSaveCoefClicked = async () =>{
        let dataToSave = this.state.data.filtered.map(i=>{
            return {
                key: i.key,
                coef: i.coef
            }
        });

        let payload = {};
        dataToSave.forEach(item=>{
            payload[item.key] = item.coef;
        })


        await ApiService.updateBlockStocNeedsCoef(payload);
        this.loadData();
    }

    onFilteredChanged = async (item, value) =>{
        await ApiService.updateBlockStocNeedsFilter({item, value});
        this.loadData();
    }

    render() {
        const dataset = this.sortTableData([...this.state.data.filtered]);

        return (
            <div className='BlocStockNeeds'>
                <Button onClick={() => this.setState({
                    showFilterBySize: !this.state.showFilterBySize,
                    showMergeSpreads: false,
                    showMergeSizes: false,
                    showEditCoef: false
                })}>Hide sizes</Button>
                <Button onClick={() => this.setState({
                    showMergeSpreads: !this.state.showMergeSpreads,
                    showMergeSizes: false,
                    showFilterBySize: false,
                    showEditCoef: false
                })}>Merge</Button>
                <Button onClick={() => this.setState({
                    showEditCoef: !this.state.showEditCoef,
                    showMergeSpreads: false,
                    showFilterBySize:false,
                    showMergeSizes: false
                })}>Change coefficient</Button>

                {this.state.showEditCoef &&
                <Button primary onClick={this.onSaveCoefClicked}>Save coefficients</Button>
                }

                {this.state.showFilterBySize &&
                    <div style={{marginTop: '20px'}}>
                        <h3>Hide sizes:</h3>
                        {Object.keys(this.state.data.sizeMapArrayFiltered)?.map(item => {
                            return (
                                <div style={{margin: '10px', display: 'flex', alignItems: 'center'}}>
                                    <div style={{marginRight: '5px'}}>{item}</div>

                                        <div><Checkbox onChange={(e, { name, checked })=>this.onFilteredChanged(item, checked)} checked={this.state.data.sizeMapArrayFiltered[item] === 1}/></div>
                                </div>
                            )
                        })}
                    </div>
                }

                {this.state.showMergeSpreads &&
                    <div style={{marginTop: '20px'}}>
                        <h3>Merge spreads into:</h3>
                        {this.state.data.pageMapArrayMerged?.map(item => {
                            return (
                                <div style={{margin: '10px', display: 'flex', alignItems: 'center'}}>
                                    <div style={{marginRight: '5px', opacity: (item.indexOf('->') > -1) ? 0.3 : 1}}>{item}</div>
                                    {(item.indexOf('->') > -1) &&
                                        <div><Button size={'tiny'}
                                                     onClick={() => this.onUnMergeSpreadsClicked(item)}>Unmerge</Button>
                                        </div>
                                    }

                                    {(item.indexOf('->') === -1) &&
                                        <div>{this.getDropdownForSameSize(item)}</div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                }

                <Table sortable celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={this.state.sortProperty === 'Size' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('Size', 'string')}
                            >Size:Spreads</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === 'daily sale' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('daily sale', 'number')}
                            >Daily sale</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === 'coef' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('coef', 'number')}
                            >Coefficient</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === '% stock' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('% stock', 'number')}
                            >% stock</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === '% sales' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('% sales', 'number')}
                            >% sales</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === 'needed' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('needed', 'number')}
                            >Needed</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === 'inStock' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('inStock', 'number')}
                            >Available</Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={this.state.sortProperty === 'status' ? this.state.sortDirection : null}
                                onClick={() => this.sortData('status', 'number')}
                            >Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {dataset.map(item => {
                            let percentSales = ((item.amt / this.state.data.total) * 100).toFixed(2);
                            let percentInStock = ((item.inStock / this.state.data.totalInStock) * 100).toFixed(2);
                            let needed = (item.coef * (item.amt / 500)).toFixed(3);

                            let statusStyle = {
                                width: '200px',
                                height: '14px',
                                backgroundColor: '#7eb0b4'
                            }

                            let stockStyle = {
                                width: '200px',
                                height: '14px',
                                backgroundColor: 'black'
                            }


                            let perc = (parseFloat(item.inStock) / parseFloat(needed)) * 200;
                            let mainPerc = perc;

                            if (perc > 200) {
                                perc = (parseFloat(needed) / parseFloat(item.inStock)) * 200;
                                stockStyle.width = `${perc}px`
                            } else {
                                statusStyle.width = `${perc}px`
                            }

                            let keyRender = item.key.split(':');
                            keyRender = `${keyRender[0]}:${keyRender[1] / 2}`

                            return (
                                <Table.Row key={item.key}>
                                    <Table.Cell>{keyRender}</Table.Cell>
                                    <Table.Cell>{(item.amt / 500).toFixed(3)}</Table.Cell>
                                    <Table.Cell>
                                        {this.state.showEditCoef
                                            ? <Input value={item.coef} onChange={(e)=>this.onCoefChange(e.target.value, item.key)}/>
                                            : <div>{item.coef}</div>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{percentInStock}</Table.Cell>
                                    <Table.Cell>{percentSales}</Table.Cell>
                                    <Table.Cell>{needed}</Table.Cell>
                                    <Table.Cell>{item.inStock}</Table.Cell>
                                    <Table.Cell>
                                        <div style={{display: 'absolute'}}>
                                            <div style={statusStyle}></div>
                                            <div style={stockStyle}></div>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}

                    </Table.Body>

                </Table>

            </div>
        )
    }

    sortTableData = (data) => {
        const getValueToSortBy = obj => {
            let key = this.state.sortProperty;
            switch (key) {
                case 'Size':
                    return obj.key;
                case 'daily sale':
                    return obj.amt / 500;
                case '% stock':
                    return ((obj.inStock / this.state.data.totalInStock) * 100);
                case '% sales':
                    return ((obj.amt / this.state.data.total) * 100);
                case 'needed':
                    return (obj.coef * (obj.amt / 500));
                case 'status':
                    const needed = (obj.coef * (obj.amt / 500));
                    return (parseFloat(obj.inStock) / parseFloat(needed)) * 200
            }
            return obj[key];
        }

        if (this.state.sortProperty.length) {
            if (this.state.sortDirection === 'ascending') {
                if (this.state.sortType === 'number') {
                    data = data.sort((a, b) => getValueToSortBy(a) - getValueToSortBy(b));
                }
                if (this.state.sortType === 'string') {
                    data = data.sort((a, b) => {
                        return getValueToSortBy(a).localeCompare(getValueToSortBy(b))
                    });
                }
            }
            if (this.state.sortDirection === 'descending') {
                if (this.state.sortType === 'number') {
                    data = data.sort((a, b) => getValueToSortBy(b) - getValueToSortBy(a));
                }
                if (this.state.sortType === 'string') {
                    data = data.sort((a, b) => {
                        return getValueToSortBy(b).localeCompare(getValueToSortBy(a))
                    });
                }
            }

            return data;
        } else {
            return data;
        }
    }

    loadData = async () => {
        const data = await ApiService.getBlockStocNeeds();
        this.setState({data: data.data, showEditCoef:false});
    }

    sortData = (property, type) => {
        let direction = this.state.sortDirection;

        switch (direction) {
            case 'none':
            case 'ascending':
                direction = 'descending';
                break;
            case 'descending':
                direction = 'ascending';
                break;
        }

        this.setState({sortProperty: property, sortDirection: direction, sortType: type})
    }


}


export default BlocStockNeeds;
