import React, {Component} from 'react';
import ApiService from "../../services/ApiService";
import {Button, Table} from "semantic-ui-react";
import StocStatisticsRequiredVsAvailable from "./statistics/StocStatisticsRequiredVsAvailable";

class StocksStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            pieces: [],
            materials: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div>
               <StocStatisticsRequiredVsAvailable materials={this.state.materials} items={this.state.pieces} stoc={this.props.items}/>
            </div>
        )
    }

    getData = async () => {
        const orders = await ApiService.getWorkOrdersUnfinished();
        const materials = await ApiService.getMaterials('guestbook');

        let ret = [];

        for(let i=0; i<orders.data.length; i++){
            let order = orders.data[i];
            let pieces = order.work_items;
            let orderDeadline = order.production_deadline;
            if(order.suborder_of){
                let newDeadline = await ApiService.getProductionDeadlineOfOrder(order.suborder_of);
                orderDeadline = newDeadline.data.deadline;
            }
            if(order.status === 'urgent'){
                orderDeadline = new Date().toISOString().split('T')[0];
            }

            pieces.forEach(item => {
                console.log(item)
                if (item.product_type === 'guestbook' || item.product_type === 'pen' || item.product_type === 'corners' || item.product_type === 'classic-album') {
                    if (order.suborder_of) {
                        item.mainOrderId = order.suborder_of;
                    } else {
                        item.mainOrderId = order.id;
                    }

                    item.deadline = orderDeadline;

                    let neededSKUS = this.determineItemStocSKU(item);
                    ret = [...ret, ...neededSKUS];
                }
            })
        }


        this.setState({ pieces: ret, materials: materials.data.materials});
    }

    determineItemStocSKU = item => {
        let neededSKUS = [];

        if (item.product_type === 'guestbook') {
            let material = item.item.material;
            let size = item.item.size;
            let pages = parseInt(item.item.nr_pages) / 2;
            let spacer = item.item.page_spacer === 'yes';
            let coverNeeded = item.progress.coperta !== 1;
            if(item.item.cover_style.text === 'Foto'){
                coverNeeded = false;
            }
            let blocNeeded = item.progress.bloc !== 1 && item.item.page_type.label === 'white';

            let coverSKU = `COVER-`;
            if (spacer) {
                coverSKU += 'R-'
            }
            coverSKU += `M${material.id}-`;
            coverSKU += `${size.value}-`;
            coverSKU += pages;
            let coverType = spacer ? 'cover-r' : 'cover'

            let blocSKU = `BLOC-`;
            if (spacer) {
                blocSKU += 'R-'
            }
            blocSKU += `${size.value}-`;
            blocSKU += pages;
            let blocType = spacer ? 'bloc-r' : 'bloc'

            if (coverNeeded) {
                neededSKUS.push({type: coverType, sku: coverSKU, orderId: item.mainOrderId, quantity: item.quantity, deadline: item.deadline})
            }
            if (blocNeeded) {
                neededSKUS.push({type: blocType, sku: blocSKU, orderId: item.mainOrderId, quantity: item.quantity, deadline: item.deadline})
            }
        }

        if (item.product_type === 'classic-album') {
            let material = item.item.material;
            let size = item.item.size;
            let pages = parseInt(item.item.nr_pages) / 2;
            let coverNeeded = item.progress.coperta !== 1;

            let blocNeeded = item.progress.bloc !== 1;

            let coverSKU = `COVER-CLA-`;
            coverSKU += `M${material.id}-`;
            coverSKU += `${size.text}-`;
            coverSKU += pages;
            let coverType = 'cover-c'

            let blocSKU = `BLOC-CLA-`;
            blocSKU += `${size.text}-`;
            blocSKU += pages;
            let blocType = 'bloc-c'

            if (coverNeeded) {
                neededSKUS.push({type: coverType, sku: coverSKU, orderId: item.mainOrderId, quantity: item.quantity, deadline: item.deadline})
            }
            if (blocNeeded) {
                neededSKUS.push({type: blocType, sku: blocSKU, orderId: item.mainOrderId, quantity: item.quantity, deadline: item.deadline})
            }
        }

        if (item.product_type === 'corners') {
            let color = '';
            if (item.item.corners_white) {
                color = 'white'
            }
            if (item.item.corners_black) {
                color = 'black'
            }
            if (item.item.corners_kraft) {
                color = 'kraft'
            }

            let cornersSKU = `CORNER-${color}`;
            neededSKUS.push({type:'corner', sku: cornersSKU, orderId: item.mainOrderId, quantity: item.quantity, deadline: item.deadline})
        }

        if (item.product_type === 'pen') {
            let color = item.item.color;

            let penSKU = `PEN-${color}`;
            neededSKUS.push({type: 'pen', sku: penSKU, orderId: item.mainOrderId, quantity: item.quantity, deadline: item.deadline})
        }
        return neededSKUS;
    }
}

export default StocksStatistics;
