import React, {Component} from 'react';
import './MaterialEditor.scss';
import {config} from "../config/config";
import {Button, Checkbox, Dropdown, Image, Input} from "semantic-ui-react";
import MaterialFoilMappings from "./MaterialFoilMappings";
import ApiService from "../services/ApiService";
import EmbossConfigurator from "./EmbossConfigurator";
import RadioGroup from "./radioGroup";

class MaterialEditor extends Component {
    constructor(props) {
        super(props);

        this.materialThumbRef = React.createRef();
        this.ratio1Ref = React.createRef();
        this.ratio2Ref = React.createRef();
        this.ratio3Ref = React.createRef();
        this.ratio4Ref = React.createRef();
        this.ratio5Ref = React.createRef();
        this.ratio6Ref = React.createRef();
        this.ratio7Ref = React.createRef();
        this.ratio8Ref = React.createRef();

        this.state = {
            id: this.props.data.id,
            name: this.props.data.name,
            manufacturerName: this.props.data.manufacturerName,
            manufacturerCode: this.props.data.manufacturerCode,

            blindTextureColor: this.props.data.blindTextureColor,
            blindSolidColor: this.props.data.blindSolidColor,
            blindWhiteColor: this.props.data.blindWhiteColor,

            highlightEmbossColor: this.props.data.highlightEmbossColor,
            lowlightEmbossColor: this.props.data.lowlightEmbossColor,

            imagePath: this.props.data.imagePath,
            thumbPath: this.props.data.thumbPath,
            internal_code: this.props.data.internal_code,
            canEmboss: this.props.data.canEmboss === 1,
            canUvPrint: this.props.data.canUvPrint === 1,
            available: this.props.data.available === 1,
            category: this.props.data.category,
            meta: this.props.data.meta,
            type: this.props.data.type,
            for_album: this.props.data.for_album,
            for_guestbook: this.props.data.for_guestbook,
            for_boudoir: this.props.data.for_boudoir,
            foils: [],
            preview: `${config.ASSETS_PATH}/products/material/${this.props.data.thumbPath}`,
            loadingImage: false
        }
    }

    componentDidMount() {
        ApiService.getAllFoils().then(data => {
            this.setState({foils: data.data.foils})
        })
    }

    render() {
        let collections = this.state.meta.collections || {};

        return (
            <div className="MaterialEditor">
                <div className='row'>
                    <div className='item'>
                        <h5>Name</h5>
                        <Input placeholder='name' name='name' value={this.state.name}
                               onChange={this.handleChange}/>
                    </div>

                    <div className='item'>
                        <h5>Internal code</h5>
                        <Input placeholder='internal_code' name='internal_code' value={this.state.internal_code}
                               onChange={this.handleChange}/>
                    </div>

                    <div className='item'>
                        <h5>Material category</h5>
                        <Dropdown name='category' selection options={[
                            {key: 1, text: 'eco', value: 'eco'},
                            {key: 2, text: 'leather', value: 'leather'},
                            {key: 3, text: 'textil', value: 'textil'},
                            {key: 4, text: 'premium leather', value: 'premium leather'},
                            {key: 5, text: 'velvet', value: 'velvet'},
                        ]} placeholder='Category' value={this.state.category} onChange={this.handleChange}/>
                    </div>

                    <div className='item'>
                        <h5>Collections</h5>
                        <label style={{marginRight: '5px'}}>
                            <input
                                style={{marginRight: '3px'}}
                                type="checkbox"
                                name={`${this.state.name}flap`}
                                value={`${this.state.name}flap`}
                                checked={collections.flap}
                                onChange={(e)=>this.setState({
                                    meta: {
                                        ...this.state.meta,
                                        collections: {
                                            ...collections,
                                            flap: collections.flap === true ? false : true
                                        }
                                    }
                                })}
                            />
                            flap
                        </label>

                        <label>
                            <input
                                style={{marginRight: '3px'}}
                                type="checkbox"
                                name={`${this.state.name}square`}
                                value={`${this.state.name}square`}
                                checked={collections.square}
                                onChange={(e)=>this.setState({
                                    meta: {
                                        ...this.state.meta,
                                        collections: {
                                            ...collections,
                                            square: collections.square === true ? false : true
                                        }
                                    }
                                })}
                            />
                            square
                        </label>
                    </div>


                </div>

                <div className='row'>
                    <div className='item'>
                        <h5>Supplier name</h5>
                        <Input placeholder='manufacturerName' name='manufacturerName'
                               value={this.state.manufacturerName}
                               onChange={this.handleChange}/>
                    </div>


                    <div className='item'>
                        <h5>Supplier code</h5>
                        <Input placeholder='manufacturerCode' name='manufacturerCode'
                               value={this.state.manufacturerCode}
                               onChange={this.handleChange}/>
                    </div>

                    <div style={{margin: '30px 0', border: '1px solid #ccc', padding:'10px', display: 'inline-block'}}>
                        <Checkbox label='valid' name='valid' toggle checked={this.props.data.valid === 1}  onChange={this.onVisualValidToggled}/>
                        <br/>
                        <br/>
                        <Button className='save-button' color='blue' onClick={this.onVisualCheckClicked}>visual check</Button>
                    </div>
                </div>

                <div className='images'>
                    <div className='image'>
                        <h5>Material image <br/>600x600px</h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageThumb}` || ''}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.materialThumbRef.current.click()}
                        />
                        <input
                            ref={this.materialThumbRef}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Thumb')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Wide image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio1}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio1Ref.current.click()}
                        />
                        <input
                            ref={this.ratio1Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio1')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Square image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio2}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio2Ref.current.click()}
                        />
                        <input
                            ref={this.ratio2Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio2')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Landscape image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio3}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio3Ref.current.click()}
                        />
                        <input
                            ref={this.ratio3Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio3')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Portrait image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio4}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio4Ref.current.click()}
                        />
                        <input
                            ref={this.ratio4Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio4')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Flap image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio5}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio5Ref.current.click()}
                        />
                        <input
                            ref={this.ratio5Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio5')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Flap open image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio6}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio6Ref.current.click()}
                        />
                        <input
                            ref={this.ratio6Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio6')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Flap mask image <br/> 1280x[...]px </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio7}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio7Ref.current.click()}
                        />
                        <input
                            ref={this.ratio7Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio7')}
                        />
                    </div>

                    <div className='image'>
                        <h5>Flap vellum image <br/>  </h5>
                        <div className='image-wrapper'>
                            <img className='image-element'
                                 src={`${config.ASSETS_PATH}/products/material/${this.state.meta.imageRatio8}`}
                                 size='small'/>
                        </div>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.ratio8Ref.current.click()}
                        />
                        <input
                            ref={this.ratio8Ref}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Ratio8')}
                        />
                    </div>
                </div>

                <div>
                    {this.state.canEmboss && (
                        <MaterialFoilMappings
                            foils={this.state.foils}
                            meta={this.state.meta}
                            onIncompatibeFoilChanged={this.onFoilChecked}

                        />
                    )}
                </div>

                <div style={{marginTop: '30px'}}>
                    {this.state.foils.length !== 0 &&
                    <EmbossConfigurator
                        material={this.state.meta.imageRatio5 ? this.state.meta.imageRatio5 : this.state.meta.imageRatio2}
                        foils={this.state.foils}

                        blindSolid={this.props.data.blindSolidColor}
                        blindTexture={this.props.data.blindTextureColor}
                        blindWhite={this.props.data.blindWhiteColor}

                        highlight={this.props.data.highlightEmbossColor}
                        lowlight={this.props.data.lowlightEmbossColor}
                        onSaveClicked={this.onEmbossColorsSavedClicked}
                    />
                    }
                </div>

                <div className='save'>
                    <Button className='save-button' color='red' onClick={this.onDeleteClicked}>delete material</Button>
                    <div style={{flex: 1}}></div>
                    <Button className='save-button' color='green' onClick={this.onSaveClicked}>save</Button>
                </div>
            </div>
        )
    }

    onVisualCheckClicked = () => {
        const url = `https://shop.phototailors.com/testallemboss/${this.state.id}/`;
        window.open(url, '_blank');
    }

    onVisualValidToggled = () => {
        this.props.onVisualValidToggled(this.state.id);
    }

    onImageSelected = (e, type) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        this.setState({loadingImage: true}, () => {
            ApiService.uploadImage('material', data).then(r => {
                let meta = {...this.state.meta};
                meta[`image${type}`] = r.data.image;

                this.setState({
                    loading: false,
                    meta: meta
                })
            });
        })
    };

    onFoilChecked = (id, checked) => {
        let meta = {...this.state.meta};
        if (!meta.incompatibleFoils) {
            meta.incompatibleFoils = {};
        }

        if (checked) {
            meta.incompatibleFoils[id] = true;
        } else {
            delete meta.incompatibleFoils[id];
        }

        this.setState({
            meta: meta
        })

    };

    onEmbossColorsSavedClicked = (data) => {
        this.setState({
            blindTextureColor: data.blindTexture,
            blindSolidColor: data.blindSolid,
            blindWhiteColor: data.blindWhite,
            highlightEmbossColor: data.highlight,
            lowlightEmbossColor: data.lowlight
        }, ()=>{
            this.onSaveClicked()
        })
    };

    onSaveClicked = () => {
        let material = {...this.state};
        delete material.foils;
        delete material.preview;
        delete material.loadingImage;
        delete material.loading;
        this.props.onDataChanged(material);
    }

    onDeleteClicked = () => {
        this.props.onMaterialDeleteClicked(this.state.id);
    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});

}


export default MaterialEditor;
