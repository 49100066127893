import {addActionHandler, dispatch, initStore} from "../services/Store";
import ApiService from "../services/ApiService";
import ScreenController from "./ScreenController";
import AuthService from "../services/AuthService";
import ActionsApp from "../actions/ActionsApp";
import {SCREEN_ACCOUNT_INFORMATION_MAIN, SCREEN_LOGIN} from "../screens";

class AppHandler {

    constructor() {

        initStore({
            loader: null,
            authentication: {}
        });

        addActionHandler(ActionsApp.LOGOUT, this.onLogout);
        addActionHandler(ActionsApp.HIDE_LOADER, this.onHideLoader);

        addActionHandler(ActionsApp.AUTHENTICATE_REQ, this.onAuthenticateRequest);
        addActionHandler(ActionsApp.AUTHENTICATE_RES, this.onAuthenticateResponse);

        addActionHandler(ActionsApp.LOAD_ACCOUNT_DATA_REQ, this.onLoadAccountDataRequest);
        addActionHandler(ActionsApp.LOAD_ACCOUNT_DATA_RES, this.onLoadAccountDataResponse);


    }

    init() {

    }

    onLogout = (state) => {
        AuthService.clearAuthToken();
        ScreenController.goToScreen(SCREEN_LOGIN);
        return {
            ...state,
            account: null,
            cart: null,
            registration: null,
            authentication: null,
            wizardUsb: null,
            wizardUsbOrderItem: null
        }
    };

    onHideLoader = (state) => {
        return {
            ...state,
            loader: null
        }
    };

    onAuthenticateRequest = (state, account) => {
        let redirect = account.redirect;

        if (account.redirect) {
            delete account.redirect;
        }

        ApiService.authenticateUser(account).then(({data}) => {
            data.redirect = redirect;
            dispatch(ActionsApp.AUTHENTICATE_RES, data);
        });
        return {...state, loader: "logging in"};
    };

    onAuthenticateResponse = (state, data) => {
        if (!data.error && data.token) {
            AuthService.setAuthToken(data.token);
            if (data.redirect) {
                ScreenController.goToScreen(data.redirect);
                delete data.redirect
            } else {
                ScreenController.goToScreen('/');
            }

        }
        return {...state, authentication: data, loader: null}
    };

    onLoadAccountDataRequest = (state, loaderMessage) => {
        if (!AuthService.isAuthenticated()) {
            return state;
        }

        ApiService.loadAccountData().then(({data}) => {
            dispatch(ActionsApp.LOAD_ACCOUNT_DATA_RES, data);
        }).catch(e => () => {
        });


        if (!loaderMessage) {
            loaderMessage = "loading your account data";
        }

        if (loaderMessage === 'silent') {
            loaderMessage = null;
        }

        return {...state, loader: loaderMessage};
    };

    onLoadAccountDataResponse = (state, data) => {
        return {
            ...state,
            account: data,
            loader: null,
            appConfig: {...state.appConfig, selectedCurrency: data.account.preferred_currency}
        };
    };


}

export default new AppHandler();
