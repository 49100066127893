import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dimmer, Dropdown,
    Grid,
    Header,
    Image,
    Input,
    Item,
    Label,
    Loader,
    Table
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import PriceConfig from "./PriceConfig";
import StylePassepartoutConfig from "./StylePassepartoutConfig";
import StyleEmbossConfig from "./StyleEmbossConfig";
import StyleUVPrintConfig from "./StyleUVPrintConfig";
import {config} from "../config/config";
import MaterialFoilMappings from "./MaterialFoilMappings";

class StyleConfig extends Component {
    constructor(props) {
        super(props);

        this.styleThumbRef = React.createRef();

        this.state = {
            editing: this.props.data.editing,

            name: this.props.data.name,
            thumbPath: this.props.data.thumbPath,
            type: this.props.data.type,
            meta: this.props.data.meta,
            available: this.props.data.available,


            loading: false
        }

    }

    render() {
        const item = this.props.data;

        if (this.state.editing) {
            return this.getEditingElement();
        }

        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }


        return (
            <Table.Row key={item.id}>
                <Table.Cell><Image src={`${config.ASSETS_PATH}/products/style/${this.state.meta.imageThumb}`}
                                   size='small'/></Table.Cell>
                <Table.Cell><h5>{item.name}</h5></Table.Cell>
                <Table.Cell>
                    <div style={availableStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return null;
        }

        return (
            <Button basic icon='pencil alternate' compact  onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        if (!this.state.editing) {
            return false;
        }

        const item = this.props.data;

        return (
            <div style={{padding: '30px'}}>
                <h2>{item.name}</h2>

                <br/>
                <br/>

                <div style={{display: 'flex'}}>
                    <div style={{margin: '0 10px'}}>
                        <h5>Style name</h5>
                        <Input placeholder='name' name='name' value={this.state.name}
                               onChange={this.handleChange}/>
                    </div>

                    <div style={{margin: '0 10px'}}>
                        <h5 style={{width: '150px'}}>Style availability</h5>
                        <Checkbox label='available' name='available' style={{marginTop: '15px'}} toggle
                                  checked={this.state.available}
                                  onChange={this.onCheckboxChanged}/>
                    </div>

                </div>

                <br/>
                <br/>

                {this.state.loading === false &&
                <div style={{display: 'flex', width: '900px'}}>

                    <div style={{margin: '0 10px'}}>
                        <h5>Style image <br/>600x600px</h5>
                        <Image src={`${config.ASSETS_PATH}/products/style/${this.state.meta.imageThumb}`}
                               size='small'/>
                        <Button
                            basic
                            content="select image"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.styleThumbRef.current.click()}
                        />
                        <input
                            ref={this.styleThumbRef}
                            type="file"
                            hidden
                            onChange={(e) => this.onImageSelected(e, 'Thumb')}
                        />
                    </div>

                </div>
                }

                {this.state.loading === true &&
                <h3>Uploading...</h3>
                }

                <Button basic onClick={this.onSaveClicked}>Save</Button>
            </div>
        )
    };

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onSavedClicked({
                id: this.props.data.id,

                name: this.state.name,
                thumbPath: this.state.thumbPath,
                pricesBase: this.state.pricesBase,
                pricesExtraSpread: this.state.pricesExtraSpread,
                available: this.state.available,
                meta: this.state.meta,

                defaultSpreads: this.state.defaultSpreads,
                minSpreads: this.state.minSpreads,
                maxSpreads: this.state.maxSpreads,

                hasPassepartout: this.state.hasPassepartout,
                hasOwnImages: this.state.hasOwnImages,
                hasOwnSpreads: this.state.hasOwnSpreads,
                hasWhitePage: this.state.hasWhitePage,
                hasWhitePageForPhoto: this.state.hasWhitePageForPhoto,
                hasEmboss: this.state.hasEmboss,
                hasUvPrint: this.state.hasUvPrint,


                type: this.state.type
            })
        });
    };

    onPassepartoutValueChanged = (sizeId, name, value) => {
        let meta = {...this.state.meta};
        let ppt = {...meta.passepartout};

        if(!ppt[sizeId]){
            ppt[sizeId] = {};
        }

        ppt[sizeId][name] = value;

        meta.passepartout = ppt;

        this.setState({meta: meta});
    };

    onEmbossValueChanged = (sizeId, name, value) => {
        let meta = {...this.state.meta};
        let ppt = {...meta.emboss};

        if(!ppt[sizeId]){
            ppt[sizeId] = {};
        }

        ppt[sizeId][name] = value;

        meta.emboss = ppt;

        this.setState({meta: meta});
    };

    onUVPrintValueChanged = (sizeId, name, value) => {
        let meta = {...this.state.meta};
        let ppt = {...meta.uvPrint};

        if(!ppt[sizeId]){
            ppt[sizeId] = {};
        }

        ppt[sizeId][name] = value;

        meta.uvPrint = ppt;

        this.setState({meta: meta});
    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

    onImageSelected = (e, type) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        this.setState({loading: true}, ()=>{
            ApiService.uploadImage('style', data).then(r => {
                let meta = {...this.state.meta};
                meta[`image${type}`] = r.data.image;

                this.setState({
                    loading: false,
                    meta: meta
                })
            });
        })
    };

}


export default StyleConfig;
