import React, {PureComponent, createRef} from 'react';
import {
    Header
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import moment from 'moment-timezone';
import './OrderItem.scss';
import AwbComponent from "./AwbComponent";
import OrderStatusToggler from "./order/OrderStatusToggler";
import getIcon from "./utils/iconHelper";
import {Generate} from "./utils/checksum";
import OrderBadgeDisplay from "./OrderBadgeDisplay";

class OrderItem extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            awbDetails: false
        };
    }

    render() {
        const order = this.props.data;
        const orderMeta = this.props.data.external_meta;

        let mainClassName = this.state.mouseOver ? "OrderItem over" : 'OrderItem';

        let headerClassName = this.props.data.num_pieces !== this.props.data.external_meta.items.length ? 'header red' : 'header';

        const created = moment(order.created).format('DD MMMM YYYY');

        const invoicesClassName = this.props.data.num_invoices ? 'clicker green' : 'clicker';
        const awbClassName = this.props.data.num_expeditions ? 'clicker green' : 'clicker';
        const itemsClassName = this.props.data.num_pieces ? 'clicker green' : 'clicker';

        const profitElement = this.getProfitElement();

        return (
            <div className={mainClassName} ref={this.ref}
                 onMouseEnter={() => this.setState({mouseOver: true})}
                 onMouseLeave={() => this.setState({mouseOver: false})}
            >
                <div className={headerClassName}
                     onClick={() => this.props.history.push(`/order/${Generate(this.props.data.id)}/?page=items`)}>
                    <div className='name'>
                        <Header as='h4' style={{marginBottom: 0, maxWidth: '130px'}}>{orderMeta.user_name}</Header>
                        {this.props.data.external_source === 'etsy' &&
                        <div><b>{Generate(order.id)}</b> &nbsp;&nbsp; {orderMeta.cost.currency} {orderMeta.cost.subtotal}</div>
                        }

                        {this.props.data.external_source !== 'etsy' &&
                        <div><b>{Generate(order.id)}</b> &nbsp;&nbsp; {orderMeta.cost.currency} {orderMeta.cost.total_price}</div>
                        }
                    </div>
                    <div className='created'>
                        <div>{created}</div>
                        <div style={{display: 'flex'}}>
                            {getIcon(this.props.data.external_source, 20)}
                            {this.props.data.remake_of &&
                            <div style={{
                                marginLeft: '10px',
                                background: 'purple',
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '0 5px'
                            }}>R</div>
                            }
                        </div>
                    </div>

                    <div className='status' style={{width: '120px', display: 'flex', justifyContent: 'center'}}>
                        <OrderStatusToggler displayOnly status={this.props.data.status}/>
                    </div>

                    <div>
                        <OrderBadgeDisplay
                            isAlbum={this.props.data.isAlbum}
                            isGuestbook={this.props.data.isGuestbook}
                            isBoudoir={this.props.data.isBoudoir}
                            isClassicAlbum={this.props.data.isClassicAlbum}
                            isGuestbookSlots={this.props.data.isGuestbookSlots}
                        />
                    </div>

                    <div className={itemsClassName}
                         onClick={this.onItemsClicked}>ITEMS {this.props.data.num_pieces}</div>
                    <div className={awbClassName}
                         onClick={() => this.props.history.push(`/order/${this.props.data.id}/?page=shipping`)}>AWB {this.props.data.num_expeditions}</div>


                    <div className={invoicesClassName}
                         onClick={() => this.props.history.push(`/order/${this.props.data.id}/?page=items`)}>INVOICE {this.props.data.num_invoices}</div>

                    {this.props.data.is_remote_delivery === 1 &&
                    <div className='clicker red'>REMOTE AREA</div>
                    }

                    {this.props.data.deleted === 1 &&
                    <div className="clicker" style={{background: 'red', color: "white"}}>DELETED</div>
                    }

                    {this.props.data.suborder_of !== null &&
                    <div className="clicker" style={{background: '#0095ff', color: "white"}}>suborder
                        of {Generate(this.props.data.suborder_of)}</div>
                    }

                    {this.props.data.address_invalid?.length > 0 &&
                    <div className="clicker" style={{background: 'red', color: "white"}}>ADDRESS ERROR</div>
                    }


                    {profitElement}
                </div>
                {this.props.data.status === 'shipped' &&
                <div className='awb' style={{marginLeft: '500px'}}>
                    {this.getAwbItems()}
                </div>
                }

            </div>
        );
    }

    getAwbElement = () => {
        if (this.props.expandedTab !== 'Awb') {
            return null;
        }
        return (
            <AwbComponent data={this.props.data}/>
        )
    };

    getAwbItems = () => {
        console.log(this.props.awbsForOrder.length)
        if (this.props.awbsForOrder.length === 0) {
            return null;
        }

        return this.props.awbsForOrder.map(awb => {
            const isLate = moment(awb.est).diff(moment()) < 0;
            const timeToDeliverDelta = moment(awb.est).fromNow();


            let itemClassName = isLate ? 'item late' : 'item';

            if(awb.alerted.success.sent){
                itemClassName = 'item sent';
            }

            const allEvents = awb.result.events.map(event=>{
                return (
                    <div className='lastEvent'><b>{event.Date}</b> {event.ServiceEvent.Description}, {event.ServiceArea.Description}</div>
                )
            })

            return (
                <div className={itemClassName}>
                    <div className="row">
                        <div className="nr"><b>{awb.nr}</b></div>
                        <div className="status">ETA: {timeToDeliverDelta}</div>
                        <div className="more" onClick={()=>this.setState({awbDetails: !this.state.awbDetails})}>more</div>
                    </div>
                    {this.state.awbDetails === false &&
                    <div
                        className='lastEvent'><b>{awb.result.lastEvent.Date}</b> {awb.result.lastEvent.ServiceEvent.Description}, {awb.result.lastEvent.ServiceArea.Description}</div>
                    }
                    {this.state.awbDetails === true &&
                    <div className="allEvents">
                        <div><b>Going to {awb.name}, in {awb.country}</b></div>
                        <div  className='goingTo'>Initial delivery estimate: {awb.est.split('T')[0]}</div>
                        {allEvents}
                    </div>
                    }
                </div>
            )
        })
        // return this.state.awbs.map(item=>{
        //     const deliveryTimeRaw = moment(item.created_at);
        //     let deliveryTime = deliveryTimeRaw.tz('Europe/Bucharest').format('DD MMMM YYYY');
        //
        //     return (
        //         <div>{deliveryTime} <span style={{fontWeight: 'bold'}}>{item.awbMeta.tracking}</span></div>
        //     )
        // })
    }

    onInvoiceDowloadClicked = () => {
        ApiService.downloadInvoice(this.props.orderId);
    };

    onItemsClicked = () => {
        this.props.history.push(`/order/${this.props.data.id}/?page=items`);
    };

    getProfitElement = () => {
        if (this.props.data.status !== 'complete' && this.props.data.status !== 'canceled') {
            return null;
        }

        let stats = this.props.data.stats;
        if (stats.err) {
            return (
                <div className='profit-stats'>
                    <div className="item red"> not enough data</div>
                </div>
            )
        }

        const profitClassName = stats.profit > 0 ? 'item green' : 'item red'
        const marginClassName = stats.margin > 0 ? 'item green' : 'item red'

        return (
            <div className='profit-stats'>
                <div className={profitClassName}>RON {stats.profit}</div>
                <div className={marginClassName}>{stats.margin}%</div>
            </div>
        )
    }

}


export default OrderItem;
