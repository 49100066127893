import React, {Component} from 'react';
import {Button, Dimmer, Input, Loader, Modal} from "semantic-ui-react";
import './InvoiceElement.scss';
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import ApiService from "../services/ApiService";
import moment from "moment-timezone";
import Utils from "../data/Utils";

class InvoiceElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data
        }
    }

    componentDidMount(){
        if(!this.props.data) {
            ApiService.getInvoiceById(this.props.id)
                .then(data => {
                    this.setState({
                        data: data.data
                    });
                })
        }
    }

    render() {
        const element = this.getElement();

        return (
            <div className='InvoiceElement'>
                {element}
            </div>
        );
    }

    getElement = () => {
        if (!this.state.data) {
            return (<div>Loading...</div>)
        }

        const date = Utils.stringToDateDisplay(this.state.data.date);

        return (
            <div className='body'>
                <div className='header'>
                    <div className='name' onClick={this.onTitleClicked}>{this.state.data.series}-{this.state.data.series_count}</div>
                    <Icon className='edit-icon' name='download' onClick={this.onInvoiceDownloadClicked}/>

                </div>
                <div className='name'>{this.state.data.data.clientName}</div>
                <div>{this.state.data.data.currency} {this.state.data.data.total}</div>
                <div className='date'>{date}</div>
            </div>
        )
    };

    onTitleClicked = () => {
        this.props.onInvoiceEditClicked(this.state.data);
    };

    onInvoiceDownloadClicked = () => {
        let id = this.props.id;
        if(!id){
            id = this.state.data.id;
        }

        ApiService.downloadInvoiceById(id);
    };

}


export default InvoiceElement;
