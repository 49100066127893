import {config} from '../config/config';
import ScreenController from "../controler/ScreenController";
import {dispatch} from "./Store";
import ActionsApp from "../actions/ActionsApp";
import Presence from "./Presence";

class AuthService {
    constructor() {
        this.serverTimeOffset = -1;

        // this.tick();
    }

    getAuthToken = () => {
        const token = localStorage.getItem(config.LS_USER_KEY);

        if (!token) {
            return "";
        }

        return token;
    };

    setAuthToken(token) {
        localStorage.setItem(config.LS_USER_KEY, token);
        const user = this.getUserData();
        if(user){
            Presence.updateUserId(user.id);
        }
    };

    clearAuthToken() {
        localStorage.removeItem(config.LS_USER_KEY);
    };

    getUserData() {
        const token = this.getAuthToken();
        let userData = null;

        if (token && token.length) {
            try {
                userData = JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
            }
        }

        return userData;
    };

    isAuthenticated(){
        const userData = this.getUserData();
        if (userData) {
            let now = new Date().getTime() + this.serverTimeOffset;
            
            if(now > userData.exp){
                return false;
            } else {
                return true;
            }
        }
    }

    tick() {
        const userData = this.getUserData();
        if (userData) {
            let now = new Date().getTime();
            if(now > userData.exp){
                // dispatch(ActionsApp.SHOW_MUST_LOGIN_OVERLAY, true);
            } else {
                // dispatch(ActionsApp.SHOW_MUST_LOGIN_OVERLAY, false);
            }
        } else {
            // dispatch(ActionsApp.SHOW_MUST_LOGIN_OVERLAY, true);
        }

        setTimeout(() => {
            this.tick();
        }, 1000);
    }
}

export default new AuthService();

