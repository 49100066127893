import React, {Component} from 'react';
import {Button} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import moment from "moment-timezone";
import './OrderItem_Awb.scss';
import AwbCreate from "./AwbCreate";
import ShippmentItems from "./ShippmentItems";
import printJS from "print-js";


class OrderItem_Awb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editingAwb: -1,
            awbs: [],
            orderValidation:[],
            createButtonVisible: true,
            shipping: null,
            errorMessage: null
        }
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.mainOrderId !== this.props.mainOrderId){
            this.updateData();
        }
    }

    render() {
        const awbElements = this.getAwbElements();
        const editAwb = this.getEditAwbElement();

        if(this.state.orderValidation.length){
            return (
                <div className='OrderItem_Awb'>
                    <ShippmentItems mainOrderId={this.props.mainOrderId} mainOrderStatus={this.props.data.status} onCreateAWBClicked={this.onCreateAwbClicked}/>
                    <h3 style={{color:"red"}}>Unable to create AWB for this order. There are some issues present:</h3>
                    {this.state.orderValidation.map(item=>{
                        return (
                            <div style={{color:"red"}}>{item}</div>
                        )
                    })}

                </div>
            )
        }

        return (
            <div className='OrderItem_Awb'>
                <ShippmentItems mainOrderId={this.props.mainOrderId} mainOrderStatus={this.props.data.status} onCreateAWBClicked={this.onCreateAwbClicked}/>
                {this.props.warning &&
                <div className='warning'>{this.props.warning}</div>
                }
                {awbElements}
                {this.state.errorMessage &&
                <h3 style={{color: 'red'}}>{this.state.errorMessage}</h3>
                }
                {editAwb}
            </div>
        );
    }

    onCreateAwbClicked = () => {
        this.setState({createButtonVisible: false}, () => {
            ApiService.createNewAwb(this.props.data.id).then(data => {
                ApiService.getShipingAddressForOrder(this.props.data.id).then(shipingData => {
                    this.setState({editingAwb: data.data, shipping: shipingData.data})
                })

            })
        })
    };

    getEditAwbElement = () => {
        if (this.state.editingAwb === -1) {
            return null;
        }

        return (
            <AwbCreate data={this.props.data} address={this.state.shipping} id={this.state.editingAwb}
                       mainOrderId={this.props.mainOrderId}
                       onCreateAwbClicked={this.onGenerateAwbClicked}
                       onCreatePacketaAwbClicked={this.onGeneratePacketaAwbClicked}
                       onCancelCreateAwb={this.onCancelCreateAwb}/>
        )
    };

    getAwbElements = () => {
        if (this.state.editingAwb !== -1) {
            return null;
        }

        if (this.state.awbs.length === 0) {
            return null;
        }

        return (

            this.state.awbs.map(item => {
                let packagesTotalWeight = 0;
                let packages = item.requestMeta?.shipment?.packages;
                if (!packages) {
                    packages = this.props.data.shipping_batch;
                    if(packages) {
                        packages.forEach(i => {
                            packagesTotalWeight += parseFloat(i.weight);
                        })
                    }
                } else {
                    packages.forEach(i => {
                        packagesTotalWeight += parseFloat(i.kg);
                    })
                }


                packagesTotalWeight = packagesTotalWeight.toFixed(3);


                const estimatedElement = this.getEstimatedAwbElement(item.requestMeta.estimate, packagesTotalWeight);
                const userElement = this.getUserElement(item.requestMeta.shipment);
                const downloadElement = this.getDownloadElement(item.awbMeta, item);

                const deliveryTimeRaw = moment(item.created_at);
                let deliveryTime = deliveryTimeRaw.tz('Europe/Bucharest').format('DD MMMM YYYY');

                return (
                    <div className='awb-info'>
                        <div className='created'>{deliveryTime}<br/>{item.provider}</div>
                        {userElement}
                        {estimatedElement}
                        {downloadElement}
                        <div className='delete' onClick={() => this.onDeleteClicked(item.id)}>delete</div>
                    </div>
                )
            })
        )
    };

    getUserElement = (data) => {
        if (!data) {
            return null;
        }

        let packages = null;

        if (data.packages) {
            packages = this.getPackagesElement(data.packages);
        }

        return (
            <div className='user'>
                <div className='name'>{data.name}</div>
                <div>{data.first_line}</div>
                <div>{data.second_line}</div>
                <div>{`${data.city}, ${data.state} ${data.zip}`}</div>
                <div>{data.country}</div>

                <div className='packages'>{packages}</div>
            </div>
        )
    };

    getDownloadElement = (data, item) => {
        if (!data) {
            return null;
        }

        let trackingNr = data.tracking;

        let internalAwb = null;

        if(this.props.data.shipper === 'Packeta'){
            trackingNr = `packeta-${trackingNr}`

            let events = {};

            try {
                events = JSON.parse(item.statusMeta);
            } catch(e){
                events = {};
            }

            if(events.events?.length){
                events = events.events;

                for(let i=0;i<events.length; i++){
                    let event = events[i];

                    if(event.packetaExternalTrackingCode?.length){
                        internalAwb = event.packetaExternalTrackingCode;
                        break;
                    }
                }
            }
        }

        return (
            <div className='download'>
                <div>Tracking: {internalAwb || data.tracking}</div>
                {data.tracking &&
                <div className='click'>
                    <div style={{margin:'10px 0'}} onClick={() => ApiService.downloadAwb(trackingNr)}>Download awb</div>
                    <div onClick={() => {
                        ApiService.downloadAwbForPrint(trackingNr).then(data=>{
                            const url = URL.createObjectURL(data.data);
                            printJS(url, 'pdf')
                        })

                    }
                    }>Print awb
                    </div>
                </div>
                }

                {!data.tracking &&
                <h3 style={{color: 'red'}}>Error</h3>
                }
            </div>
        )
    }

    getPackagesElement = (data) => {
        if (data.length === 0) {
            return 0;
        }

        return data.map(item => {
            return (
                <div className='estimate'>
                    {`Box ${item['@number']}: ${item.w}/${item.h}/${item.d} ${item.kg}kg`}
                </div>
            )
        })
    };

    getEstimatedAwbElement = (data, totalWeight) => {
        if (!data) {
            return null;
        }

        if (data.err) {
            return (
                <div className='error'>
                    Error: {data.err}
                </div>
            )
        }

        const dt = data;

        const deliveryTimeRaw = moment(dt.DeliveryTime);
        let deliveryTime = deliveryTimeRaw.tz('Europe/Bucharest').format('DD MMMM YYYY');

        const chargesElement = this.getChargesElement(dt.Charges);

        return (
            <div className='estimated'>
                <div>Delivery time {deliveryTime}</div>
                <div>
                    {chargesElement}
                </div>
                <h5>Total weight: {totalWeight} kg</h5>
                <div className='total'>Total: {dt.TotalNet.Currency} {dt.TotalNet.Amount}</div>
            </div>
        )
    };

    onDeleteClicked = (id) => {
        ApiService.deleteAwb(id).then(data => {
            this.updateData();
        })
    }

    getChargesElement = (data) => {
        const charges = data.Charge;
        const currency = data.Currency;

        if (!charges || charges.length === 0) {
            return null;
        }

        return charges.map(charge => {
            return (
                <div>{currency} {charge.ChargeAmount} {charge.ChargeType}</div>
            )
        })
    }

    updateData = async() => {
        const getAwbForOrder = await  ApiService.getAwbForOrder(this.props.data.id);
        const orderValidation = await ApiService.getOrderAssistant(this.props.data.id);

        this.setState({awbs: getAwbForOrder.data, orderValidation: orderValidation.data})
    };

    onCancelCreateAwb = () => {
        if (this.state.editingAwb !== -1) {
            ApiService.deleteAwb(this.state.editingAwb).then(data => {
                this.setState({editingAwb: -1, createButtonVisible: true}, () => {
                    this.updateData();
                })
            })
        }
    };

    onGenerateAwbClicked = (data) => {
        ApiService.createAwb(data).then(data => {
            this.setState({editingAwb: -1, createButtonVisible: true, errorMessage: data.data.errorMessage}, () => {
                if (!data.data.errorMessage) {
                    this.updateData();
                    if(this.props.onRefresh) {
                        this.props.onRefresh();
                    }
                }
            })
        });
    };

    onGeneratePacketaAwbClicked = (data) => {
        ApiService.createAwb(data).then(data => {
            this.setState({editingAwb: -1, createButtonVisible: true, errorMessage: data.data.errorMessage}, () => {
                if (!data.data.errorMessage) {
                    this.updateData();
                    if(this.props.onRefresh) {
                        this.props.onRefresh();
                    }
                }
            })
        });
    };
}


export default OrderItem_Awb;
