import React, {Component} from 'react';
import './Order2AwbScreen.scss';
import {Button, Checkbox, Container, Menu, Table,} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import {Generate} from "../components/utils/checksum";
import moment from "moment-timezone";

class AWBToClientScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            interactionDisabled: false
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const items = this.getItems();

        return (
            <Container style={{margin: '3rem 0'}} className="Order2AwbScreen">
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Order</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Ship By</Table.HeaderCell>
                            <Table.HeaderCell>Tracking</Table.HeaderCell>
                            <Table.HeaderCell>Etsy</Table.HeaderCell>
                            <Table.HeaderCell>Done</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6'>
                                <Button disabled={this.state.interactionDisabled} onClick={this.onMoveOrdersClicked} floated="left">
                                    Move selected items to
                                    Shipped</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Container>
        )
    }

    getItems = () => {
        if (this.state.orders.length === 0) {
            return null;
        }

        return this.state.orders.map(order => {
            const isRemake = order.remake_of !== null;

            let shipByDate = order.awb2client.expected_ship_date;
            let shipByDateString = '';

            let isLate = false;

            if (shipByDate) {
                const date = moment(shipByDate * 1000);
                shipByDateString = date.tz('Europe/Bucharest').format('DD.MM.YYYY');

                const now = new Date().getTime();
                if (now > shipByDate * 1000) {
                    isLate = true;
                }
            }

            const shipByClass = isLate ? 'isLate' : '';

            const compileTracking = (data) => {
                if (!data) {
                    return null;
                }

                return data.map(item => {
                    return (
                        <div className="trackingParent">
                            <div className="trackingWho">{item.provider}</div>
                            <div>{item.tracking}</div>
                        </div>
                    )
                })
            }

            const trackingElements = compileTracking(order.awb2client.expeditions);

            return (
                <Table.Row key={order.id}>
                    <Table.Cell>
                        {isRemake &&
                            <div className="isRemake">Remake</div>
                        }
                        {order.isSuborder &&
                            <div className="isSuborder">Suborder</div>
                        }
                        <div>
                            <a href={`/order/${Generate(order.id)}`} target="_blank">
                                {Generate(order.id)}
                            </a>
                        </div>
                    </Table.Cell>
                    <Table.Cell>{order.name}</Table.Cell>
                    <Table.Cell className={shipByClass}>{shipByDateString}</Table.Cell>
                    <Table.Cell>{trackingElements}</Table.Cell>
                    <Table.Cell>
                        <div>
                            <a href={`https://www.etsy.com/your/orders/sold?order_id=${order.awb2client.etsyId}`}
                               target="_blank">
                                {order.awb2client.etsyId}
                            </a>
                        </div>
                    </Table.Cell>
                    <Table.Cell> <Checkbox checked={order.etsy_awb}
                                           onChange={(e, data) => this.setChecked(order.id, data.checked)}/></Table.Cell>
                </Table.Row>
            )
        })
    }

    setChecked = async (orderId, checked) => {
        await ApiService.updateOrder({id: orderId, etsy_awb: checked ? 1 : 0});
        this.loadData();
    }

    loadData = async () => {
        const orders = await ApiService.getAwb2ClientData();
        this.setState({orders: orders.data.orders, interactionDisabled: false})
    }

    onMoveOrdersClicked = () => {
        this.setState({interactionDisabled: true}, async ()=>{
            await ApiService.moveShippedOrdersToShipped();
            this.loadData();
        })
    }

}


export default AWBToClientScreen;
