import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import BambooBoxConfig from "./BambooBoxConfig";
import PouchConfig from "./PouchConfig";
import PenConfig from "./PenConfig";

class PensConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, colors: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getProductPen().then(data => {
                this.setState({loading: false, colors: data.data});
            });
        });

    }

    render() {
        const colors = this.getColors();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Item.Group divided relaxed>
                    {colors}
                </Item.Group>

                <Button style={{marginTop: '3rem'}} content='add new color'  basic onClick={this.onAddNewColorClicked}/>
            </Container>
        );
    }

    getColors = () => {
        if (this.state.colors.length === 0) {
            return null;
        }

        return this.state.colors.map(item => {
            return (
                <PenConfig key={item.id} data={item} onSavedClicked={this.onColorChanged}/>
            )
        })
    };

    onColorChanged = (size) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editProductPen(size).then(data => {
                this.setState({loading: false, colors: data.data});
            });
        });
    };

    onAddNewColorClicked = () => {
        let colors = [...this.state.colors];
        colors.push({
            editing: true,
            id: -1,
            color: '',
            available: false,
            type: this.props.type
        });

        this.setState({
            colors: colors
        })
    };
}


export default PensConfig;
