import React, {Component} from 'react';
import './EtsyScreen.scss';
import {Button, Container, Input} from "semantic-ui-react";
import EtsyLedger from "../components/EtsyLedger";
import ApiService from "../services/ApiService";

class EtsyLedgerScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasNextPage: true,
            isNextPageLoading: false,

            isExportingFromSequence: false,
            exportFromSeq:'',
            exportToSeq:'',

            isFetchingReceiptId: false,
            fetchReceiptId:'',

            isParsingLedger: false,
            parseLedgerFromSeq:'',

            loadItemsNotParsed: false,

            items: []
        }
    }

    _loadNextPage = (...args) => {
        this.setState({isNextPageLoading: true}, async () => {
            const call = this.state.loadItemsNotParsed ? ApiService.etsyLedgerGetItemsNotParsed : ApiService.etsyLedgerGetItems
            let loadedData = await call(this.state.items[this.state.items.length - 1]?.id || 'NO');
            loadedData = loadedData.data;

            this.setState((state) => ({
                hasNextPage: state.items.length < loadedData.total,
                isNextPageLoading: false,
                items: [...state.items].concat(loadedData.items)
            }));

        });
    };

    render() {
        const {hasNextPage, isNextPageLoading, items} = this.state;
        const itemStyle = {display: 'inline-flex'}
        const colItemStyle = {margin: '0 10px', textAlign: 'left'};

        return (
            <Container style={{marginTop: '50px', width: '1800px'}}>

                <div style={{marginBottom:'20px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{padding: '15px', border:'1px solid #dadada', marginBottom:'20px', marginRight:'20px'}}>
                            <div style={{marginBottom:'10px'}}>Export ledger items</div>
                            <Input placeholder='from seq' value={this.state.exportFromSeq} onChange={(e, {value})=>this.onInputChange('exportFromSeq', value)}></Input>
                            <Input placeholder='to seq' value={this.state.exportToSeq} onChange={(e, {value})=>this.onInputChange('exportToSeq', value)}></Input>
                            <Button disabled={this.state.isExportingFromSequence} onClick={this.onExportLedgerClicked}>Export</Button>
                        </div>

                        <div style={{padding: '15px', border:'1px solid #dadada', marginBottom:'20px'}}>
                            <div style={{marginBottom:'10px'}}>Advanced</div>
                            <Input placeholder='fetch receipt by id' value={this.state.fetchReceiptId} onChange={(e, {value})=>this.onInputChange('fetchReceiptId', value)}></Input>
                            <Button disabled={this.state.isFetchingReceiptId} onClick={this.onFetchReceiptByIdClicked}>Run</Button>

                            <Input placeholder='parse ledger from seq' value={this.state.parseLedgerFromSeq} onChange={(e, {value})=>this.onInputChange('parseLedgerFromSeq', value)}></Input>
                            <Button disabled={this.state.isParsingLedger} onClick={this.onSequenceParseClicked}>Run</Button>

                            <Button disabled={this.state.loadItemsNotParsed} onClick={this.onLoadUnparsedClicked}>Get unparsed</Button>
                            <Button onClick={this.onForceSyncClicked}>Force Sync</Button>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                </div>

                <div style={itemStyle}>
                    <div style={{...colItemStyle, width: '140px'}}>date</div>
                    <div style={{...colItemStyle, width: '50px'}}>seq</div>
                    <div style={{...colItemStyle, width: '100px'}}>amount</div>
                    <div style={{...colItemStyle, width: '80px'}}>currency</div>
                    <div style={{...colItemStyle, width: '220px'}}>description</div>
                    <div style={{...colItemStyle, width: '90px'}}>balance</div>
                    <div style={{...colItemStyle, width: '210px'}}>ledger_type</div>
                    <div style={{...colItemStyle, width: '150px'}}>reference_type</div>
                    <div style={{...colItemStyle, width: '120px'}}>reference_id</div>
                    <div style={{...colItemStyle, width: '120px', overflow: 'hidden'}}>payment_adjustments</div>
                    <div style={{...colItemStyle, width: '120px'}}>order_id</div>
                </div>
                {this.state.items.length === 0 &&
                    <h2 style={{marginLeft:'10px', marginTop: '20px'}}>No items</h2>
                }
                <EtsyLedger
                    hasNextPage={hasNextPage}
                    isNextPageLoading={isNextPageLoading}
                    items={items}
                    loadNextPage={this._loadNextPage}
                />
            </Container>
        )
    }

    onForceSyncClicked = () =>{
        ApiService.etsyLedgerForceSync();
    }

    onLoadUnparsedClicked = () => {
        this.setState({loadItemsNotParsed: true, items:[]}, ()=>{
            this._loadNextPage();
        })
    }

    onExportLedgerClicked = () => {
        const fromSeq = parseInt(this.state.exportFromSeq);
        const toSeq = parseInt(this.state.exportToSeq);

        this.setState({isExportingFromSequence: true}, ()=>{
            ApiService.fetchLedgerToTSV(fromSeq, toSeq).then(data=>{
                this.setState({isExportingFromSequence: false})
            })
        })
    }

    onFetchReceiptByIdClicked = () => {
        const id = parseInt(this.state.fetchReceiptId);

        this.setState({isFetchingReceiptId: true}, ()=>{
            ApiService.etsyFetchReceiptById(id).then(data=>{
                this.setState({isFetchingReceiptId: false})
            })
        })
    }


    onSequenceParseClicked = () => {
        const seq = parseInt(this.state.parseLedgerFromSeq);

        this.setState({isParsingLedger: true}, ()=>{
            ApiService.etsyParseLedgerFromSequence(seq).then(data=>{
                this.setState({isParsingLedger: false})
            })
        })
    }

    onInputChange = (name, value)=>{
        this.setState({[name]:value});
    }


}


export default EtsyLedgerScreen
