import React, {Component} from 'react';
import {Button, Checkbox, Container, Image, Input, Item, Label} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class WebsiteConfigEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            text: '',
            expanded: false
        }
    }

    componentDidMount() {
        ApiService.getWebsiteConfig(this.props.configKey).then(data => {
            this.setState({data: data.data, text: JSON.stringify(data.data.data, null, 4)})
        })
    }

    render() {
        if (!this.state.data) {
            return null;
        }

        let json = null;

        try {
            json = JSON.parse(this.state.text);
        } catch (e) {
        }

        let valid = "VALID";
        if (!json) {
            valid = "INVALID"
        }

        return (
            <div style={{marginTop: '50px'}}>
                <h3>{this.props.name}</h3>

                {this.state.expanded &&
                <div>
                    <textarea onChange={this.onValueChanged} rows="10" cols="50" value={this.state.text}/>
                    <div>{valid}</div>
                    <Button primary onClick={this.onSaveClicked}>save</Button>
                </div>
                }

                {this.state.expanded === false &&
                <Button primary onClick={() => this.setState({expanded: true})}>edit</Button>
                }

            </div>
        );
    }

    onValueChanged = (e) => {
        let value = e.target.value;

        this.setState({text: value});
    }

    onSaveClicked = () => {
        let json = null;
        try {
            json = JSON.parse(this.state.text);
        } catch (e) {
        }

        if (json) {
            let payload = this.state.data;
            payload.data = json;

            ApiService.saveWebsiteConfig(payload).then(data => {
                this.setState({expanded: false})
            });
        }
    }

}


export default WebsiteConfigEditor;
