import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import OrderScreen from "./OrderScreen";
import OrdersTabNavigator from "../components/OrdersTabNavigator";
import * as queryString from "query-string";
import ApiService from "../services/ApiService";
import {Generate} from "../components/utils/checksum";

class OrdersTabScreen extends Component {
    constructor(props) {
        super(props);

        this.tabs = ['all', 'new', 'hold', 'mock-up', 'proofing', 'in production', 'urgent', 'issue', 'to pack', 'packing', 'ready to ship', 'shipped', 'complete', 'canceled']

        this.state = {
            refreshing: false,
            tab: 1,
            orderId: null,
            orders: [],
            filteredOrders: []
        }
    }

    componentDidMount() {
        let qs = queryString.parse(this.props.location.search);
        let tab = 1;
        let order = "";

        if (qs && qs.tab) {
            tab = parseInt(qs.tab);
        }

        if (qs && qs.order) {
            order = qs.order;
        }

        ApiService.getOrders().then(data => {
            this.setState({loading: false, orders: data.data.orders, tab: tab, orderId: order}, () => {
                if ('URLSearchParams' in window) {
                    let searchParams = new URLSearchParams(window.location.search)
                    searchParams.set("tab", tab);
                    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                    window.history.pushState(null, '', newRelativePathQuery);
                }
                this.computeFilteredItems();
            });
        });

    }

    render() {
        return (
            <div>
                <OrdersTabNavigator orders={this.state.filteredOrders}
                                    selectedId={this.state.orderId}
                                    tab={this.tabs[this.state.tab]}
                                    refreshing={this.state.refreshing}
                                    onOrderSelected={(id) => {
                                        this.setState({orderId: null}, () => {
                                            this.setState({orderId: id}, () => {

                                            });
                                        })
                                    }}
                                    onStatusChanged={(status) => {
                                        this.setState({
                                            tab: this.tabs.indexOf(status),
                                            orderId: null,
                                            filteredOrders: []
                                        }, () => {
                                            if ('URLSearchParams' in window) {
                                                let searchParams = new URLSearchParams(window.location.search)
                                                searchParams.set("tab", this.tabs.indexOf(status));
                                                let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                                                window.history.pushState(null, '', newRelativePathQuery);
                                            }

                                            this.loadData();
                                        })
                                    }}

                                    onNextOrderClicked={this.onNextOrderClicked}
                                    onPrevOrderClicked={this.onPrevOrderClicked}
                                    onRefreshClicked={this.onRefreshClicked}
                />
                {this.state.orderId &&
                <OrderScreen {...this.props} orderId={Generate(this.state.orderId)}/>
                }
            </div>
        )
    }

    onRefreshClicked = () => {
        this.setState({refreshing: true, filteredOrders: [], orders: []}, () => {
            this.loadData();
        })
    }

    onPrevOrderClicked = () => {
        let currentOrderIndex = this.state.filteredOrders.findIndex(i => i.id.toString() === this.state.orderId.toString());
        if (currentOrderIndex > 0) {
            this.setState({orderId: null}, () => {
                this.setState({orderId: this.state.filteredOrders[currentOrderIndex - 1].id}, () => {
                    if ('URLSearchParams' in window) {
                        let searchParams = new URLSearchParams(window.location.search)
                        searchParams.set("order", this.state.filteredOrders[currentOrderIndex - 1].id);
                        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                        window.history.pushState(null, '', newRelativePathQuery);
                    }
                });
            })
        }
    }

    onNextOrderClicked = () => {
        let currentOrderIndex = this.state.filteredOrders.findIndex(i => i.id === this.state.orderId);
        if ((currentOrderIndex + 1) < this.state.filteredOrders.length) {
            this.setState({orderId: null}, () => {
                this.setState({orderId: this.state.filteredOrders[currentOrderIndex + 1].id}, () => {
                    if ('URLSearchParams' in window) {
                        let searchParams = new URLSearchParams(window.location.search)
                        searchParams.set("order", this.state.filteredOrders[currentOrderIndex + 1].id);
                        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                        window.history.pushState(null, '', newRelativePathQuery);
                    }
                });
            })
        }
    }

    loadData = () => {
        ApiService.getOrders().then(data => {
            this.setState({
                loading: false,
                orders: data.data.orders,
                tab: this.state.tab,
                orderId: this.state.orderId
            }, () => {
                this.computeFilteredItems();
            });
        });
    }

    computeFilteredItems = () => {
        let filtered = [...this.state.orders];
        let status = this.tabs[this.state.tab];

        filtered = this.state.orders.filter(o => o.status === status);
        filtered = filtered.filter(o=>o.suborder_of === null);

        let orderId = this.state.orderId;

        if (!orderId && filtered.length > 0) {
            orderId = filtered[0].id;
        }

        let inArray = filtered.find(i => i.id.toString() === orderId.toString());

        if (!inArray && filtered.length > 0) {
            orderId = filtered[0].id;
        }

        if ('URLSearchParams' in window) {
            let searchParams = new URLSearchParams(window.location.search)
            searchParams.set("order", orderId);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (filtered.length === 0) {
            orderId = null
        }

        this.setState({
            orderId: null
        }, () => {
            this.setState({
                filteredOrders: filtered,
                refreshing: false,
                orderId: orderId
            }, () => console.log(this.state))
        })

    };


}


export default OrdersTabScreen;
