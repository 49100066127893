import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import SizesConfig from "../components/SizesConfig";
import MaterialsConfig from "../components/MaterialsConfig";
import StylesConfig from "../components/StylesConfig";
import FontsConfig from "../components/FontsConfig";
import EmbossConfig from "../components/EmbossConfig";
import UVPrintConfig from "../components/UVPrintConfig";
import BambooBoxesConfig from "../components/BambooBoxesConfig";
import CornersConfig from "../components/CornersConfig";
import ClassicAlbumSizeConfig from "../components/ClassicAlbumSizeConfig";
import ClassicAlbumCoverSizeConfig from "../components/ClassicAlbumCoverSizeConfig";

class RawItemsConfigScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 'bloc'
        }
    }

    render() {
        const activeItem = this.state.activeItem;

        const configElement = this.getConfigElement();

        return (
            <Container>
                <Menu pointing inverted color='purple' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Raw paper sizes configuration</Menu.Item>
                    <Menu.Item
                        name='Mucava'
                        active={activeItem === 'Mucava'}
                        onClick={() => this.setState({activeItem: 'Mucava'})}
                    />

                    <Menu.Item
                        name='Hartie alba'
                        active={activeItem === 'Hartie alba'}
                        onClick={() => this.setState({activeItem: 'Hartie alba'})}
                    />

                    <Menu.Item
                        name='DA140'
                        active={activeItem === 'DA140'}
                        onClick={() => this.setState({activeItem: 'DA140'})}
                    />

                    <Menu.Item
                        name='DA280'
                        active={activeItem === 'DA280'}
                        onClick={() => this.setState({activeItem: 'DA280'})}
                    />
                </Menu>


                {configElement}

            </Container>
        );
    }


    getConfigElement = () => {
        switch (this.state.activeItem) {
            case 'Mucava':
                return (<SizesConfig type='raw_mucava'/>);
            case 'Hartie alba':
                return (<SizesConfig type='raw_white-paper'/>);
            case 'DA140':
                return (<SizesConfig type='raw_DA140'/>);
            case 'DA280':
                return (<SizesConfig type='raw_DA280'/>);
            default :
                return null;
        }
    }

}


export default RawItemsConfigScreen;
