import React, {Component} from 'react';
import {Input, Table} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class SupplierPricesBookItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'priceColaj': this.props.price['priceColaj'] || 0,
            'priceCoverTextil': this.props.price['priceCoverTextil'] || 0,
            'priceCoverEco': this.props.price['priceCoverEco'] || 0,
            'priceCoverLeather': this.props.price['priceCoverLeather'] || 0,
            'priceCoverPremiumLeather': this.props.price['priceCoverPremiumLeather'] || 0,
            'priceCoverVelvet': this.props.price['priceCoverVelvet'] || 0,
            'priceColajRezerva': this.props.price['priceColajRezerva'] || 0,
            'priceColajNegru': this.props.price['priceColajNegru'] || 0,
            'priceColajText': this.props.price['priceColajText'] || 0,
            'priceCoverUV': this.props.price['priceCoverUV'] || 0,
            'priceCoverPPT': this.props.price['priceCoverPPT'] || 0,
            'priceCoverEmboss': this.props.price['priceCoverEmboss'] || 0,
            'pricePrintFlorin': this.props.price['pricePrintFlorin'] || 0,
            editing: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            'priceColaj': nextProps.price['priceColaj'] || 0,
            'priceCoverTextil': nextProps.price['priceCoverTextil'] || 0,
            'priceCoverEco': nextProps.price['priceCoverEco'] || 0,
            'priceCoverLeather': nextProps.price['priceCoverLeather'] || 0,
            'priceCoverPremiumLeather': nextProps.price['priceCoverPremiumLeather'] || 0,
            'priceCoverVelvet': nextProps.price['priceCoverVelvet'] || 0,
            'priceColajRezerva': nextProps.price['priceColajRezerva'] || 0,
            'priceColajNegru': nextProps.price['priceColajNegru'] || 0,
            'priceColajText': nextProps.price['priceColajText'] || 0,
            'priceCoverUV': nextProps.price['priceCoverUV'] || 0,
            'priceCoverPPT': nextProps.price['priceCoverPPT'] || 0,
            'priceCoverEmboss': nextProps.price['priceCoverEmboss'] || 0,
            'pricePrintFlorin': nextProps.price['pricePrintFlorin'] || 0,
        })
    }

    render() {
        const viewElement = this.getViewElement();
        const editElement = this.getEditElement();

        if (!this.state.editing) {
            return viewElement;
        }
        if (this.state.editing) {
            return editElement;
        }
    }

    getViewElement = () => {
        const size = this.props.size;

        return (
            <Table.Row key={size.id}>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>size</div>
                        <div> {size.cm}</div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColaj</div>
                        <div> {this.state['priceColaj']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverTextil</div>
                        <div> {this.state['priceCoverTextil']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverEco</div>
                        <div> {this.state['priceCoverEco']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverLeather</div>
                        <div> {this.state['priceCoverLeather']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverPremiumLeather</div>
                        <div> {this.state['priceCoverPremiumLeather']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverVelvet</div>
                        <div> {this.state['priceCoverVelvet']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColajRezerva</div>
                        <div> {this.state['priceColajRezerva']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColajNegru</div>
                        <div> {this.state['priceColajNegru']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColajText</div>
                        <div> {this.state['priceColajText']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverUV</div>
                        <div> {this.state['priceCoverUV']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverPPT</div>
                        <div> {this.state['priceCoverPPT']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverEmboss</div>
                        <div> {this.state['priceCoverEmboss']} </div>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>pricePrintFlorin</div>
                        <div> {this.state['pricePrintFlorin']} </div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                </Table.Cell>
            </Table.Row>
        )
    }

    getEditElement = () => {
        const size = this.props.size;
        return (
            <Table.Row key={size.id}>
                <Table.Cell>{size.cm}</Table.Cell>


                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColaj</div>
                        <div><Input name='priceColaj' value={this.state['priceColaj']}
                                    onChange={this.handleChange}/> </div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverTextil</div>
                        <div><Input name='priceCoverTextil' value={this.state['priceCoverTextil']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverEco</div>
                        <div><Input name='priceCoverEco' value={this.state['priceCoverEco']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverLeather</div>
                        <div><Input name='priceCoverLeather' value={this.state['priceCoverLeather']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverPremiumLeather</div>
                        <div><Input name='priceCoverPremiumLeather' value={this.state['priceCoverPremiumLeather']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverPremiumLeather</div>
                        <div><Input name='priceCoverVelvet' value={this.state['priceCoverVelvet']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColajRezerva</div>
                        <div><Input name='priceColajRezerva' value={this.state['priceColajRezerva']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColajNegru</div>
                        <div><Input name='priceColajNegru' value={this.state['priceColajNegru']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceColajText</div>
                        <div><Input name='priceColajText' value={this.state['priceColajText']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverUV</div>
                        <div><Input name='priceCoverUV' value={this.state['priceCoverUV']} onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverPPT</div>
                        <div><Input name='priceCoverPPT' value={this.state['priceCoverPPT']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>priceCoverEmboss</div>
                        <div><Input name='priceCoverEmboss' value={this.state['priceCoverEmboss']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>

                <Table.Cell>
                    <div style={{marginRight: '20px'}}>
                        <div style={{color: 'rgb(125, 125, 125', fontSize: '11px'}}>pricePrintFlorin</div>
                        <div><Input name='pricePrintFlorin' value={this.state['pricePrintFlorin']}
                                    onChange={this.handleChange}/></div>
                    </div>
                </Table.Cell>
                <Table.Cell><Button basic onClick={this.onSaveClicked}>save</Button></Table.Cell>
            </Table.Row>
        )
    }

    onSaveClicked = () => {
        let payload = {
            values: {...this.state},
            path: `${this.props.supplierId}.${this.props.type}.${this.props.size.cm}`,
            priceId: this.props.pricesId,
        }

        delete payload.values.editing;

        this.setState({editing: false}, () => {
            this.props.onUpdatePrice(payload);
        })

    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});

}


export default SupplierPricesBookItem;
