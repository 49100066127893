import React, {Component} from 'react';
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import moment from "moment-timezone";
import './AwbCreate.scss';
import AwbShipment from "./AwbShipment";

class AwbCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            awbConfig: {
                w: '',
                h: '',
                d: '',
                kg: '',
            },
            address: this.props.address,
            addressEditable: false,
            disableCreate: false,
            boxes: {}
        };
    }

    componentDidMount() {
        this.prefillBoxes();
    }

    prefillBoxes = async () =>{
        let packing = null;
        let boxes = [];

        try {
            packing = JSON.parse(this.props.data.packing);
        } catch(e){}

        if(packing){
            let boxConfig = await ApiService.getProductShippingBox();
            boxConfig = boxConfig.data;

            packing.forEach(b=>{
                let box = boxConfig.find(bc=> bc.id === b.sizeId);
                if(box) {
                    boxes.push({
                        w: box.width.toString(),
                        h: box.height.toString(),
                        d: box.depth.toString(),
                        kg:"0"
                    })
                } else {
                    boxes.push({
                        w: "0",
                        h: "0",
                        d: "0",
                        kg:"0"
                    })
                }
            })
        }

        if(boxes.length){
            let boxToState = {};

            boxes.forEach((box,index)=>{
                boxToState[(index+1).toString()] = {'@number': (index+1).toString(), ...box}
            })

            this.setState({boxes: boxToState})
        }
    }

    render() {
        const estimatedAwbElement = this.getEstimatedAwbElement(this.state.estimateAwb);

        return (
            <div className='AwbCreate'>
                <div className='head'>
                    <div className='title'>New {this.props.data.shipper} Awb</div>
                    <div className='cancel' onClick={this.props.onCancelCreateAwb}>cancel</div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '20px'}}>
                    <div>
                        {/*<AwbAddress data={this.state.address} label={'edit works for this awb only'}  editable={this.state.addressEditable} onSaveClicked={this.onNewAddressSaved} onEditClicked={()=>this.setState({addressEditable: true})}/>*/}

                        {this.props.data.shipper === 'DHL' &&
                        <div>
                            <Button primary compact style={{marginTop: '30px'}} onClick={this.onGenerateAwbClicked}>Get
                                AWB Price</Button>

                            {estimatedAwbElement}
                        </div>
                        }

                        {this.props.data.shipper === 'Packeta' &&
                        <div>
                            <Button primary compact style={{marginTop: '30px'}}
                                    onClick={this.onCreatePacketaAwbClicked}>Create Packeta AWB</Button>
                        </div>
                        }
                    </div>
                    <AwbShipment
                        boxes={this.state.boxes}
                        onlyOneBox={this.props.data.shipper === 'Packeta'}
                        onBoxSaved={this.onBoxSaved}
                    />
                </div>
            </div>
        );
    }

    onBoxSaved = (box) => {
        let boxes = {...this.state.boxes};
        boxes[box.package] = box;

        delete boxes[box.package].set;
        delete boxes[box.package].showSave;

        boxes[box.package]['@number'] = boxes[box.package].package;
        delete boxes[box.package].package;

        this.setState({boxes: boxes, estimateAwb: null})
    };

    onNewAddressSaved = (data) => {
        this.setState({addressEditable: false, address: data})
    }

    getExistingAwbElement = () => {
        if (!this.props.data.awb || !this.props.data.awb.tracking) {
            return null;
        }

        const estimateElement = this.getEstimatedAwbElement(this.props.data.awb_price.estimate, true);

        return (
            <div style={{display: 'flex'}}>
                <div style={{marginRight: '20px'}}>
                    <div style={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        marginBottom: '5px',
                        cursor: 'pointer'
                    }} onClick={() => {
                        ApiService.downloadAwb(this.props.data.awb.tracking)
                    }}>Download AWB
                    </div>
                    <div>{this.props.data.awb.tracking}</div>
                </div>
                <div>
                    {estimateElement}
                </div>
            </div>
        )
    };

    onGenerateAwbClicked = () => {
        let awbData = {...this.state.address};
        awbData.orderRef = this.props.data.id.toString().match(/.{1,3}/g).join('-');
        awbData.orderId = this.props.data.id;
        awbData.mainOrderId = this.props.mainOrderId;

        let boxes = [];

        for (let key in this.state.boxes) {
            boxes.push(this.state.boxes[key])
        }

        awbData.packages = boxes;
        awbData.awbId = this.props.id;

        ApiService.getAwbPrices(awbData).then(data => {
            this.setState({estimateAwb: data.data});
        });
    };

    onCreateAwbClicked = () => {
        this.setState({disableCreate: true}, ()=>{
            let awbData = {...this.state.address};
            awbData.orderRef = this.props.data.id.toString().match(/.{1,3}/g).join('-')
            awbData.orderId = this.props.data.id;
            awbData.mainOrderId = this.props.mainOrderId;
            awbData.price = this.props.data.external_meta.cost.subtotal;
            awbData.currency = this.props.data.external_meta.cost.currency;
            let boxes = [];

            for (let key in this.state.boxes) {
                boxes.push(this.state.boxes[key])
            }

            awbData.packages = boxes;
            awbData.awbId = this.props.id;

            this.props.onCreateAwbClicked(awbData);
        })

    };

    onCreatePacketaAwbClicked = () => {
        let awbData = {...this.state.address};
        awbData.orderRef = this.props.data.id.toString().match(/.{1,3}/g).join('-')
        awbData.orderId = this.props.data.id;
        awbData.mainOrderId = this.props.mainOrderId;
        awbData.price = this.props.data.external_meta.cost.subtotal;
        awbData.currency = this.props.data.external_meta.cost.currency;
        let boxes = [];

        for (let key in this.state.boxes) {
            boxes.push(this.state.boxes[key])
        }

        awbData.packages = boxes;
        awbData.awbId = this.props.id;

        this.props.onCreatePacketaAwbClicked(awbData);
    };

    handleAwbValueChange = (key, value) => {
        let awbConfig = {...this.state.awbConfig};
        awbConfig[key] = value;

        this.setState({awbConfig: awbConfig})
    };

    getEstimatedAwbElement = (data, withoutButton) => {
        if (!data) {
            return null;
        }

        if (data.err) {
            return (
                <div className='error'>
                    Error: {data.err}
                </div>
            )
        }

        const dt = data;

        const deliveryTimeRaw = moment(dt.DeliveryTime);
        let deliveryTime = deliveryTimeRaw.tz('Europe/Bucharest').format('DD MMMM YYYY');

        const chargesElement = this.getChargesElement(dt.Charges);

        return (
            <div className='estimated'>
                <div>Delivery time {deliveryTime}</div>
                <div>
                    {chargesElement}
                </div>

                {!withoutButton &&
                <Button disabled={this.state.disableCreate} primary onClick={this.onCreateAwbClicked}>{dt.TotalNet.Currency} {dt.TotalNet.Amount}</Button>
                }

                {withoutButton && <div>Total: {dt.TotalNet.Currency} {dt.TotalNet.Amount}</div>}

                {this.state.awbCreated && <div>Done</div>}

            </div>
        )
    }

    getChargesElement = (data) => {
        const charges = data.Charge;
        const currency = data.Currency;

        if (!charges || charges.length === 0) {
            return null;
        }

        return charges.map(charge => {
            return (
                <div>{currency} {charge.ChargeAmount} {charge.ChargeType}</div>
            )
        })
    }

}


export default AwbCreate;
