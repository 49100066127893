import React, {Component} from 'react';
import './OrderTimeline.scss';
import {Button, Dropdown, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";

class OrderShipper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            providers: [
                {key: 'DHL', text: 'DHL', value: 'DHL'},
                {key: 'Packeta', text: 'Packeta', value: 'Packeta'}
            ],
            tracking: '',
        }
    }

    render() {
        return (
            <div className='OrderDeadline'>
                <div style={{
                    marginBottom: '10px',
                    paddingBottom: '4px',
                    borderBottom: '2px solid #dededf',
                    fontSize: '15px'
                }}>Ship with
                </div>

                <div>
                    <Dropdown closeOnBlur
                              search selection name='orderDeadline' options={this.state.providers}
                              value={this.props.shipper} onChange={this.onShipperSelected}/>
                </div>

            </div>
        )
    }

    onShipperSelected = async (e, {value}) => {
        let address_invalid = this.props.order.address_invalid;

        if(value === "Packeta"){
            address_invalid = 'Order needs manual address validation';
        }

        await ApiService.updateOrder({id: this.props.orderId, shipper: value, address_invalid: address_invalid})
        this.props.reloadPage()
    }


}


export default OrderShipper;
