import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import SupplierPricesBookItem from "./SupplierPricesBookItem";
import SupplierPricesCornerItem from "./SupplierPricesCornerItem";
import SupplierPricesClassicAlbumItem from "./SupplierPricesClassicAlbumItem";

class SupplierPricesClassicAlbums extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        };
    }

    componentDidMount() {
        ApiService.getProductClassicAlbumBlocs().then(data => {
            this.setState({data: data.data})
        })
    }

    render() {
        const elements = this.getElements();

        return (
            <div>
                <h2 style={{marginTop: '100px'}}>classic albums</h2>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {elements}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    getElements = () => {
        if (this.state.data.length === 0) {
            return null;
        }


        let prices = this.props.prices.prices;

        let supplierPrices = prices[this.props.supplierId];
        if (!supplierPrices) {
            supplierPrices = {};
        }


        let productPrices = supplierPrices['classic-album'];
        if (!productPrices) {
            productPrices = {};
        }

        return this.state.data.map(item => {
            let price = {...productPrices[item.size]};
            if(price){
                price = price[item.pages]
            }
            if(!price){
                price = {};
            }


            return (
                <SupplierPricesClassicAlbumItem key={`classic-albums.${item.id}`} item={item} pricesId={this.props.prices.id}
                                        price={price} type='classic-album' supplierId={this.props.supplierId}
                                        onUpdatePrice={this.props.onUpdatePrice}/>
            )
        })
    }

}


export default SupplierPricesClassicAlbums;
