import React, {Component} from 'react';
import './ShippingScreen.scss';
import {Button, Container, Icon, Input, Tab, Table,} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import BlockStockTableItem from "../components/stocks/BlockStockTableItem";
import CoverStockTableItem from "../components/stocks/CoverStockTableItem";
import StockItemCreate from "../components/stocks/StockItemCreate";
import StockItemOperations from "../components/stocks/StockItemOperations";
import CoverStockCornerAndPenItem from "../components/stocks/CoverStockCornerAndPenItem";
import StocksStatistics from "../components/stocks/StocksStatistics";
import CoverStockSmallItem from "../components/stocks/CoverStockSmallItem";
import MaterialStockTableItem from "../components/stocks/MaterialStockTableItem";
import MaterialsAvailabilityStatistics from "../components/stocks/MaterialsAvailabilityStatistics";
import FulfillerFilter from "../components/order/FulfillerFilter";
import TransferLayer from "../components/stocks/TransferLayer";

class StocksScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTransfer: false,
            stocName: 'apertura',

            tabIndex: 8,
            subTabIndex: 0,
            modalVisible: false,
            showNewItem: false,
            items: [],
            search: '',
            operationItemId: null,
            operationItem: null,
            sortProperty: '',
            sortDirection: 'none',
            sortType: 'number',
            updatePickPlaceVisibility: false,
            newPickPlace: '',
            updatePickPlaceInProgress: false,
        }

        this.dataToRender = []
        this.itemType = ""

    }

    componentDidMount() {
        let searchParams = new URLSearchParams(window.location.search);
        let stocName = searchParams.get("stocName");
        let tabIndex = searchParams.get("tab");
        let subTabIndex = searchParams.get("subtab");
        let search = searchParams.get("search");
        let opi = searchParams.get("opi");

        if (!stocName) {
            stocName = "apertura";
            searchParams.set("stocName", stocName);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (!tabIndex) {
            tabIndex = 8;
            searchParams.set("tab", tabIndex);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (!subTabIndex) {
            subTabIndex = 0;
            searchParams.set("subtab", subTabIndex);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (!search) {
            search = '';
            searchParams.set("search", search);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        if (!opi) {
            opi = 0;
            searchParams.set("opi", opi);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({
            stocName: stocName,
            tabIndex: tabIndex,
            subTabIndex: subTabIndex,
            search: search,
            operationItemId: opi
        });


        this.getData(stocName);
    }

    render() {
        const blockRows = (
            <Table.Row>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'quantity' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('quantity', 'number')}
                >Quantity</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'pick_place' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('pick_place', 'string')}
                >Pick place</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.sizeName' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.sizeName', 'string')}
                >Size</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.pages' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.pages', 'number')}
                >Colaje</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.cpw' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.cpw', 'number')}
                >CPW</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.wis' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.wis', 'number')}
                >WIS</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.mpw' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.mpw', 'number')}
                >MPW</Table.HeaderCell>
            </Table.Row>
        )

        const coverRows = (
            <Table.Row>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'quantity' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('quantity', 'number')}
                >
                    Quantity
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'pick_place' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('pick_place', 'string')}
                >Pick place</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.materialName' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.materialName', 'string')}
                >Material</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.sizeName' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.sizeName', 'string')}
                >Size</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.pages' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.pages', 'number')}
                >Pages</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.cpw' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.cpw', 'number')}
                >CPW</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.wis' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.wis', 'number')}
                >WIS</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.mpw' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.mpw', 'number')}
                >MPW</Table.HeaderCell>
            </Table.Row>
        )

        const materialRows = (
            <Table.Row>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'quantity' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('quantity', 'number')}
                >
                    Quantity
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'pick_place' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('pick_place', 'string')}
                >Pick place</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.materialName' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.materialName', 'string')}
                >Material</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.sizeName' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.sizeName', 'string')}
                >Size</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.materialCategory' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.materialCategory', 'string')}
                >Category</Table.HeaderCell>
            </Table.Row>
        )

        const cornerRows = (
            <Table.Row>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'quantity' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('quantity', 'number')}
                >
                    Quantity
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'pick_place' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('pick_place', 'string')}
                >Pick place</Table.HeaderCell>
                <Table.HeaderCell>Color</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.cpw' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.cpw', 'number')}
                >CPW</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'statistics.wis' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('statistics.wis', 'number')}
                >WIS</Table.HeaderCell>
            </Table.Row>
        )

        const paperRows = (
            <Table.Row>
                <Table.HeaderCell>SKU</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'quantity' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('quantity', 'number')}
                >
                    Quantity
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'pick_place' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('pick_place', 'string')}
                >Pick place</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={this.state.sortProperty === 'meta.sizeName' ? this.state.sortDirection : null}
                    onClick={() => this.sortData('meta.sizeName', 'string')}
                >Size</Table.HeaderCell>

            </Table.Row>
        )

        const paneConfig = [
            {
                menuItem: 'Blocs',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Bloc Guestbook',
                        itemType: 'bloc-r',
                        rowRenderer: blockRows,
                        itemRenderer: BlockStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Bloc Album',
                        itemType: 'bloc',
                        rowRenderer: blockRows,
                        itemRenderer: BlockStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Bloc Classic Album',
                        itemType: 'bloc-c',
                        rowRenderer: blockRows,
                        itemRenderer: BlockStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Bloc Vow',
                        itemType: 'bloc-vow',
                        rowRenderer: blockRows,
                        itemRenderer: BlockStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Bloc Slots',
                        itemType: 'bloc-slots',
                        rowRenderer: blockRows,
                        itemRenderer: BlockStockTableItem,
                        render: () => null,
                    }
                ]
            },
            {
                menuItem: 'Covers',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Cover Guestbook',
                        itemType: 'cover-r',
                        rowRenderer: coverRows,
                        itemRenderer: CoverStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Cover Album',
                        itemType: 'cover',
                        rowRenderer: coverRows,
                        itemRenderer: CoverStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Cover Classic Album',
                        itemType: 'cover-c',
                        rowRenderer: coverRows,
                        itemRenderer: CoverStockTableItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Cover Vow Book',
                        itemType: 'cover-vow',
                        rowRenderer: coverRows,
                        itemRenderer: CoverStockTableItem,
                        render: () => null,
                    }
                ]
            },
            {
                menuItem: 'Corners',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Corners',
                        render: () => null,
                        rowRenderer: cornerRows,
                        itemRenderer: CoverStockCornerAndPenItem,
                        itemType: 'corner'
                    },
                ]
            },
            {
                menuItem: 'Pens',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Pens',
                        itemType: 'pen',
                        rowRenderer: cornerRows,
                        itemRenderer: CoverStockCornerAndPenItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Markers',
                        itemType: 'marker',
                        rowRenderer: cornerRows,
                        itemRenderer: CoverStockCornerAndPenItem,
                        render: () => null,
                    },
                ]
            },

            {
                menuItem: 'Paper',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Colaje',
                        itemType: 'colaj',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'Mucava',
                        itemType: 'mucava',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'White paper',
                        itemType: 'white-paper',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'DA140',
                        itemType: 'DA140',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'DA280',
                        itemType: 'DA280',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                ]
            },
            {
                menuItem: 'RAW',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Mucava Raw',
                        itemType: 'raw_mucava',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'White paper  Raw',
                        itemType: 'raw_white-paper',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'DA140 Raw',
                        itemType: 'raw_DA140',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                    {
                        menuItem: 'DA280  Raw',
                        itemType: 'raw_DA280',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                ]
            },
            {
                menuItem: 'Materials',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Materials',
                        itemType: 'material',
                        rowRenderer: materialRows,
                        itemRenderer: MaterialStockTableItem,
                        render: () => null,
                    },
                ]
            },
            {
                menuItem: 'Packing',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Packing Boxes',
                        itemType: 'shipping_box',
                        rowRenderer: paperRows,
                        itemRenderer: CoverStockSmallItem,
                        render: () => null,
                    },
                ]
            },
            {
                menuItem: 'Statistics',
                render: () => null,
                subPanes: [
                    {
                        menuItem: 'Statistics',
                        render: () => (<StocksStatistics items={this.state.items}/>),
                    },
                    {
                        menuItem: 'Materials',
                        render: () => (<MaterialsAvailabilityStatistics items={this.state.items}/>),
                    }
                ]
            }
        ]

        const subPanes = paneConfig[this.state.tabIndex]?.subPanes || [];

        const itemType = subPanes[this.state.subTabIndex]?.itemType || 'none';
        let dataToRender = [];
        dataToRender = this.state.items.filter(i => i.type === itemType);

        let items = [];

        let search = (arr, str) => {
            const regex = new RegExp(`\\b${str}\\b`, 'g');

            return arr.filter(x => Object.values(x.meta)
                .join(' ')
                .toLowerCase()
                .match(regex))
        }

        if (this.state.search?.length) {
            let terms = this.state.search.split(' ');
            terms.forEach(term => {
                if (term.length) {
                    dataToRender = search(dataToRender, term);
                }
            })

        }

        dataToRender = this.sortTableData(dataToRender);

        const rowToRender = subPanes[this.state.subTabIndex]?.rowRenderer || null;

        const IR = subPanes[this.state.subTabIndex]?.itemRenderer;

        if (IR) {
            items = (<IR items={dataToRender}
                         onItemClicked={(item) => this.onOperationItemSelected(item)}/>)
        }

        this.dataToRender = dataToRender;
        this.itemType = itemType;

        return (
            <Container style={{margin: '3rem 0'}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: "20px"}}>
                    <div style={{fontWeight: 'bold', fontSize: "16px"}}>Stoc:</div>
                    <FulfillerFilter mode="stoc" selected={this.state.stocName} onFulfilledFilterChange={(filter => {
                        this.setState({stocName: filter ? filter : this.state.stocName}, () => {
                            this.computeStockName();
                        })
                    })}/>

                    <div>
                        <Button
                            onClick={() => this.setState({showTransfer: !this.state.showTransfer})}>transfer</Button>
                    </div>
                </div>

                {this.state.showTransfer &&
                    <TransferLayer onTransferComplete={this.onStocTransferComplete}/>
                }


                <div style={{marginBottom: '5px'}}>
                    <Tab style={{flex: 1}} menu={{attached: false}} panes={paneConfig}
                         onTabChange={this.handleTabChange}
                         activeIndex={this.state.tabIndex}/>
                </div>
                <div style={{display: 'flex', marginBottom: '20px'}}>
                    <Tab
                        style={{flex: 1}} menu={{attached: false, color: "blue"}} panes={subPanes}
                        onTabChange={this.handleSubTabChange}
                        activeIndex={this.state.subTabIndex}/>

                    {this.state.tabIndex !== 8 &&
                        <>
                            <Button style={{marginLeft: '10px', height: '42px'}} color="green"
                                    onClick={this.newInventoryItemClicked}>+</Button>
                            <Button basic icon labelPosition='left' style={{marginLeft: '5px', height: '42px'}}
                                    onClick={this.toTSVClicked}>
                                <Icon name='download'/>
                                tsv</Button>
                        </>
                    }


                </div>
                {this.state.tabIndex < 8 &&
                    <Input style={{marginTop: '20px'}} placeholder="search: scarlet 20x25" value={this.state.search}
                           onChange={(e, {name, value}) => this.onSearchChanged(value)}/>
                }

                {(this.state.updatePickPlaceVisibility === false && this.state.tabIndex < 8) &&
                    <Button style={{marginLeft: '20px'}}

                            onClick={() => this.setState({updatePickPlaceVisibility: !this.state.updatePickPlaceVisibility})}>Update
                        pick place for selection</Button>
                }

                {/*<Button style={{marginLeft: '20px'}}*/}
                {/*        onClick={() => this.setState({updatePickPlaceVisibility: !this.state.updatePickPlaceVisibility})}>Transfer selection</Button>*/}

                {(this.state.updatePickPlaceVisibility && this.state.tabIndex < 8) &&
                    <div style={{marginTop: '20px'}}>
                        <Input value={this.state.newPickPlace}
                               onChange={(e, {name, value}) => this.setState({newPickPlace: value})}
                               placeholder="pick place"/>
                        <Button
                            disabled={this.state.updatePickPlaceInProgress}
                            primary
                            style={{marginLeft: '20px'}}
                            onClick={() => this.onBulkUpdatePickPlace(dataToRender)}
                        >{this.state.updatePickPlaceInProgress ? 'WAIT' : 'update'}</Button>
                    </div>

                }
                <Table sortable celled selectable>
                    <Table.Header>
                        {rowToRender}
                    </Table.Header>

                    <Table.Body>
                        {items}
                    </Table.Body>

                </Table>

                <StockItemOperations
                    selectedStoc={this.state.stocName}
                    item={this.state.operationItem}
                    closeAndRefresh={this.operationCloseAndRefresh}
                    onCloseClicked={this.onOperationItemClose}/>

                <StockItemCreate itemType={itemType} visible={this.state.showNewItem} hide={this.hideCreateLayer}/>

            </Container>
        );
    }

    onOperationItemClose = () => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("opi", 0);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({operationItem: null, operationItemId: 0});
    }

    onOperationItemSelected = (item) => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("opi", item.id);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({operationItem: item, operationItemId: item.id})
    }

    onSearchChanged = (value) => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("search", value);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({search: value});
    }

    onStocTransferComplete = () => {
        this.getData(this.state.stocName);
    }

    computeStockName = () => {
        let searchParams = new URLSearchParams(window.location.search);
        if (this.state.stocName.length < 2) {
            searchParams.delete("stocName");
        } else {
            searchParams.set("stocName", this.state.stocName);
        }
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.getData(this.state.stocName);
    }

    onBulkUpdatePickPlace = (filteredItems) => {
        let payload = {pick_place: this.state.newPickPlace};

        payload.items = filteredItems.map(i => i.id);

        this.setState({updatePickPlaceInProgress: true}, () => {
            ApiService.stocksUpdatePickPlaceBulk(payload).then(data => {
                this.setState({
                    updatePickPlaceInProgress: false,
                    newPickPlace: '',
                    updatePickPlaceVisibility: false
                }, () => {
                    this.getData(this.state.stocName)
                })
            })
        })
    }

    sortTableData = (data) => {
        const getValueToSortBy = obj => {
            let key = this.state.sortProperty;
            if (key.indexOf('.') > -1) {
                key = key.split('.');

                return obj[key[0]][key[1]];
            } else {
                return obj[key];
            }
        }

        if (this.state.sortProperty.length) {
            if (this.state.sortDirection === 'ascending') {
                if (this.state.sortType === 'number') {
                    data = data.sort((a, b) => getValueToSortBy(a) - getValueToSortBy(b));
                }
                if (this.state.sortType === 'string') {
                    data = data.sort((a, b) => {
                        return getValueToSortBy(a).localeCompare(getValueToSortBy(b))
                    });
                }
            }
            if (this.state.sortDirection === 'descending') {
                if (this.state.sortType === 'number') {
                    data = data.sort((a, b) => getValueToSortBy(b) - getValueToSortBy(a));
                }
                if (this.state.sortType === 'string') {
                    data = data.sort((a, b) => {
                        return getValueToSortBy(b).localeCompare(getValueToSortBy(a))
                    });
                }
            }

            return data;
        } else {
            return data;
        }
    }

    operationCloseAndRefresh = () => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("opi", 0);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({operationItem: null, operationItemId: 0}, () => {
            this.getData(this.state.stocName);
        })
    }

    handleTabChange = (e, data) => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("tab", data.activeIndex);
        searchParams.set("subtab", 0);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({tabIndex: data.activeIndex, subTabIndex: 0});
    };

    handleSubTabChange = (e, data) => {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("subtab", data.activeIndex);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({subTabIndex: data.activeIndex});
    };

    hideCreateLayer = () => {
        this.setState({showNewItem: false});
        this.getData(this.state.stocName);
    }

    newInventoryItemClicked = () => {
        this.setState({showNewItem: true});
    }

    toTSVClicked = () => {
        ApiService.stocksToTSV({data: this.dataToRender, filename: this.itemType})
    }

    sortData = (property, type) => {
        let direction = this.state.sortDirection;

        switch (direction) {
            case 'none':
            case 'ascending':
                direction = 'descending';
                break;
            case 'descending':
                direction = 'ascending';
                break;
        }

        this.setState({sortProperty: property, sortDirection: direction, sortType: type})
    }


    getData = async (stocName) => {
        const data = await ApiService.stocksGet(stocName);

        let opi = null;

        try {
            opi = data.data.find(i => i.id === parseInt(this.state.operationItemId))
        } catch (e) {
            opi = null;
        }

        this.setState({items: data.data, operationItem: opi})
    }

}


export default StocksScreen;
