import React, {Component} from 'react';
import {Icon} from "semantic-ui-react";
import './PopOverIcon.scss'

class PopOverIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        }
    }

    render() {
        let color = this.props.color;
        if (!color) {
            color = 'red'
        }

        return (
            <div className='PopOverIcon'>
                <Icon style={{color: color, marginLeft: '10px'}} name={this.props.icon}
                      onMouseEnter={() => this.setState({expanded: true})}
                      onMouseLeave={() => this.setState({expanded: false})}/>
                {this.state.expanded &&
                <div className='over'>
                    {this.props.text}
                </div>
                }

            </div>
        );
    }


}


export default PopOverIcon;
