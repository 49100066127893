import React, {Component} from 'react';
import {Button, Checkbox, Container, Grid, Image, Input, Item, Label} from "semantic-ui-react";

class StylePassepartoutConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const sizesElements = this.getSizesElements();

        return (
            <Container>
                <Grid container style={{margin: '1rem'}}>
                    {sizesElements}
                </Grid>
            </Container>
        );
    }

    getSizesElements = () => {
        if(!this.props.sizes.length){
            return null;
        }

        return this.props.sizes.map(size=>{
            return (
                <Item key={size.id} style={{margin: '10px'}}>
                    <Item.Content>
                        <Item.Header as='a'>
                            {size.cm}
                        </Item.Header>
                        <Item.Extra>
                            <Input label='Top (mm)' placeholder='1' name='top' value={this.getValue(size.id, 'top')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>

                            <Input label='Left (mm)' placeholder='1' name='left' value={this.getValue(size.id, 'left')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>

                            <Input label='W (mm)' placeholder='1' name='w' value={this.getValue(size.id, 'w')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>

                            <Input label='H (mm)' placeholder='1' name='h' value={this.getValue(size.id, 'h')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>

                            <Input label='Border (mm)' placeholder='1' name='border' value={this.getValue(size.id, 'border')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>

                            <Input label='Passepartout name' placeholder='Default' name='pptName' value={this.getValue(size.id, 'pptName')}
                                   onChange={(e, {name, value}) => this.handleChange(size.id, name, value)}/>
                            <br/>
                            <br/>

                        </Item.Extra>


                    </Item.Content>
                </Item>
            )
        })
    };

    handleChange = (sizeId, name, value)=>{
        this.props.onValueChanged(sizeId, name, value)
    };

    getValue = (sizeId, name) => {
        if(!this.props.data){
            return '';
        }
        if(!this.props.data[sizeId]){
            return '';
        }
        return this.props.data[sizeId][name] || '';
    }

}


export default StylePassepartoutConfig;
