import React, {Component} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Input,
    Loader,
    Table, TextArea
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import {config} from "../config/config";
import Utils from "../data/Utils";

class FontConfig extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();

        let meta = {};
        if(this.props.data.meta){
            try {
                meta = JSON.parse(this.props.data.meta);
            } catch(e){
                meta = {
                    renderedSizeReduction: 0,
                    kerning: {
                       base: 0,
                        ' ': 0,
                        '.': 0

                    }
                }
            }
        }

        let metaBoxValue = JSON.stringify(meta, null, 4);

        this.state = {
            editing: this.props.data.editing,

            name: this.props.data.name,

            sizes: this.props.data.sizes,
            meta: meta,
            metaBoxValue: metaBoxValue,
            available: this.props.data.available === 1
        }

    }

    render() {
        const editButton = this.getEditButton();
        const editingElement = this.getEditingElement();

        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }

        const item = this.props.data;

        if (this.state.editing) {
            return this.getEditingElement();
        }

        Utils.addFontToDom(item.name, `${config.ASSETS_PATH}/products/fonts/${item.filePath}`);

        return (
            <Table.Row key={item.id}>
                <Table.Cell><h5>{item.name}</h5></Table.Cell>
                <Table.Cell style={{fontFamily:item.name, fontSize:'30px'}}>Lorem ipsum 123 </Table.Cell>
                <Table.Cell><a href={`${config.ASSETS_PATH}/products/fonts/${item.filePath}`}>download</a></Table.Cell>
                <Table.Cell textAlign='center'>{this.state.sizes}</Table.Cell>

                <Table.Cell>
                    <div style={availableStyle}/>
                </Table.Cell>
                <Table.Cell>
                    <div style={{margin: '0 auto', width: 'fit-content'}}>
                        <Button basic onClick={() => this.setState({editing: true})}>edit</Button>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    getEditButton = () => {
        if (this.state.editing) {
            return (
                <Button compact basic color='green' icon='check' onClick={this.onSaveClicked}/>
            )
        }

        return (
            <Button basic icon='pencil alternate' compact onClick={() => this.setState({editing: true})}/>
        )
    };

    getEditingElement = () => {
        if (!this.state.editing) {
            return false;
        }

        return (
            <Container style={{padding: '30px'}}>
                <Loader active={this.state.loading}/>
                <h2>{this.state.name}</h2>
                <br/>
                <br/>
                <Button
                    basic
                    content="select font file"
                    labelPosition="left"
                    icon="image"
                    onClick={() => this.fileInputRef.current.click()}
                />
                <input
                    ref={this.fileInputRef}
                    type="file"
                    hidden
                    onChange={this.onImageSelected}
                />

                {this.state.fontUploaded && (<h5>Font uploaded</h5>)}

                <br/>
                <br/>
                <Input label='name' placeholder='name' name='name' value={this.state.name}
                       onChange={this.handleChange}/>
                <br/>
                <br/>

                <Input label='sizes' placeholder='28 72 100' name='sizes' value={this.state.sizes}
                       onChange={this.handleChange}/>
                <br/>
                <br/>

                <div>Meta</div>
                <TextArea value={this.state.metaBoxValue} onChange={e=>this.onMetaValueChanged(e.target.value)}/>

                <Checkbox label='available' name='available' toggle checked={this.state.available}
                          onChange={this.onCheckboxChanged}/>
                <br/>
                <br/>
                <Button basic onClick={this.onSaveClicked}>save</Button>
            </Container>
        )
    };

    onMetaValueChanged = (value) => {
        let newMeta;
        try{
            newMeta = JSON.parse(value);
        } catch(e){
            newMeta = {...this.state.meta};
        }

        this.setState({
            metaBoxValue: value,
            meta: newMeta
        })
    }

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onFontSaveClicked({
                id: this.props.data.id,
                name: this.state.name,
                sizes: this.state.sizes,
                meta: JSON.stringify(this.state.meta),
                filePath: this.state.filePath,
                available: this.state.available
            })
        });

    };

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

    onImageSelected = (e) => {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        ApiService.uploadImage('fonts', data).then(r => {
            this.setState({
                loading: false,
                filePath: r.data.image,
                fontUploaded: true
            })
        });
    }

}


export default FontConfig;
