import React, {Component} from 'react';
import './QuickFillScreen.scss';
import ApiService from "../services/ApiService";
import {Extract, Generate} from "../components/utils/checksum";
import OrderPiece from "../components/OrderPiece";
import FilledItemGuestbook from "../components/quickFill/FilledItemGuestbook";
import {Button, Checkbox, Input} from "semantic-ui-react";
import FilledItemCorners from "../components/quickFill/FilledItemCorners";
import FilledItemDiscount from "../components/quickFill/FilledItemDiscount";
import FilledItemPen from "../components/quickFill/FilledItemPen";
import FilledItemUnknown from "../components/quickFill/FilledItemUnknown";
import FilledItemRush from "../components/quickFill/FilledItemRush";
import FilledItemShipping from "../components/quickFill/FilledItemShipping";
import moment from "moment-timezone";
import OrderStatusToggler from "../components/order/OrderStatusToggler";
import FilledItemPenThree from "../components/quickFill/FilledItemPenThree";
import FilledItemPenTwo from "../components/quickFill/FilledItemPenTwo";
import FilledItemPenThreeSame from "../components/quickFill/FilledItemPenThreeSame";


class QuickFillScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderId: "",
            order: {},
            items: [],
            configs: {},
            filledItems: [],
            userOrders: [],
            loading: false,
            orderType: 'main',

            option_shipper: 'DHL',
            option_deadline: 1,
            option_box: "C1",
            option_status: "proofing",
            option_updateInvoice: true,

            option_packetaName: '',
            option_packetaSurname: '',
            option_packetaStreet: '',
            option_packetaHouseNr: '',
            option_phone: '',
            option_suborderId:0
        }

        this.euList =
            {
                'AT': 1,
                'BE': 1,
                'BG': 1,
                'HR': 1,
                'CY': 1,
                'CZ': 1,
                'DK': 1,
                'EE': 1,
                'FI': 1,
                'FR': 1,
                'DE': 1,
                'EL': 1,
                'HU': 1,
                'IE': 1,
                'IT': 1,
                'LV': 1,
                'LT': 1,
                'LU': 1,
                'MT': 1,
                'NL': 1,
                'PO': 1,
                'PT': 1,
                'RO': 1,
                'SK': 1,
                'SI': 1,
                'ES': 1,
                'SW': 1,
                // 'GB': 1,
                'SE': 1,
                'GR': 1,
            };

    }

    componentDidMount() {
        this.getSKUS();
        const searchParams = new URLSearchParams(window.location.search);
        let orderId = searchParams.get('id');
        if (orderId) {
            orderId = Extract(orderId);
            if (orderId) {
                this.setState({orderId}, () => {
                    this.getOrderById(orderId);
                })
            }
        }
    }

    render() {
        const externalItems = this.getExternalItems();

        const externalMetaItems = this.getExternalMetaItems();
        const itemsRenderer = this.getItemsRenderer();
        const filledItems = this.getFilledItems();
        const shippingAddress = this.getRequestedShipAddress();

        return (
            <div className="main">
                <div className="left">
                    <div className="order">{Generate(this.state.orderId)}</div>
                    <div className='left'>
                        <div className='infoPiece'>
                            <div className='leftTitle'>Address</div>
                            <div className='item'>{shippingAddress}</div>
                        </div>

                        <div className='infoPiece'>
                            <div className='leftTitle'>Ordered items</div>
                            <div className='item'>{externalItems}</div>
                        </div>

                        <div className='infoPiece'>
                            <div className='leftTitle'>Message from buyer</div>
                            <div className='message'> {this.state.order?.external_meta?.message_from_buyer}</div>
                        </div>

                    </div>
                </div>
                {this.state.loading === false &&
                    <div className="right">
                        <div className="row">
                            <div className="selectionItem">
                                <div className="title">Order type</div>
                                <div className="items">
                                    <div className={this.state.orderType === 'main' ? 'item selectedOrder' : 'item'}
                                         onClick={() => this.setState({orderType: "main"})}>Main order
                                    </div>
                                    <div className={this.state.orderType === 'suborder' ? 'item selectedOrder' : 'item'}
                                         onClick={() => this.setState({orderType: "suborder"})}>Suborder
                                    </div>
                                </div>
                            </div>

                            {this.state.orderType === 'suborder' &&
                                <div className="selectionItem">
                                    <div className="title">Items will be added in same box</div>
                                    <div className="items">
                                        <div className="item selectedOrder">Yes</div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'suborder' &&
                                <div className="selectionItem">
                                    <div className="title">Sync status with main order</div>
                                    <div className="items">
                                        <div className="item selectedOrder">Yes</div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'suborder' &&
                                <div className="selectionItem">
                                    <div className="title">Sync deadline with main order</div>
                                    <div className="items">
                                        <div className="item selectedOrder">Yes</div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'suborder' &&
                                <div className="selectionItem">
                                    <div className="title">Update invoice</div>
                                    <div className="items">
                                        <div className="item selectedOrder">Yes</div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'main' &&
                                <div className="selectionItem">
                                    <div className="title">Ship to</div>
                                    <div className="items">
                                        <div className="item selectedOrder">{this.state.option_country}</div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'main' &&
                                <div className="selectionItem">
                                    <div className="title">Ship with</div>
                                    <div className="items">
                                        <div
                                            className={this.state.option_shipper === 'DHL' ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_shipper: "DHL"})}>DHL
                                        </div>
                                        <div
                                            className={this.state.option_shipper === 'Packeta' ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_shipper: "Packeta"})}>PACKETA
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'main' &&
                                <div className="selectionItem">
                                    <div className="title">Production deadline</div>
                                    <div className="items">
                                        <div
                                            className={this.state.option_deadline === 1 ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_deadline: 1})}>1 day
                                        </div>
                                        <div
                                            className={this.state.option_deadline === 2 ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_deadline: 2})}>2 days
                                        </div>
                                        <div
                                            className={this.state.option_deadline === 3 ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_deadline: 3})}>3 days
                                        </div>
                                        <div
                                            className={this.state.option_deadline === 4 ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_deadline: 4})}>5 days
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'main' &&
                                <div className="selectionItem">
                                    <div className="title">Packing box</div>
                                    <div className="items">
                                        <div className={this.state.option_box === "C1" ? 'item selectedOrder' : 'item'}
                                             onClick={() => this.setState({option_box: "C1"})}>C1
                                        </div>
                                        <div className={this.state.option_box === "C2" ? 'item selectedOrder' : 'item'}
                                             onClick={() => this.setState({option_box: "C2"})}>C2
                                        </div>
                                        <div className={this.state.option_box === "C3" ? 'item selectedOrder' : 'item'}
                                             onClick={() => this.setState({option_box: "C3"})}>C3
                                        </div>
                                        <div className={this.state.option_box === "B2" ? 'item selectedOrder' : 'item'}
                                             onClick={() => this.setState({option_box: "B2"})}>B2
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'main' &&
                                <div className="selectionItem">
                                    <div className="title">Goes into</div>
                                    <div className="items">
                                        <div
                                            className={this.state.option_status === "proofing" ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_status: "proofing"})}>Proofing
                                        </div>
                                        <div
                                            className={this.state.option_status === "in production" ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_status: "in production"})}>Production
                                        </div>
                                        <div
                                            className={this.state.option_status === "new" ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_status: "new"})}>New
                                        </div>
                                        <div
                                            className={this.state.option_status === "urgent" ? 'item selectedOrder' : 'item'}
                                            onClick={() => this.setState({option_status: "urgent"})}>Urgent
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.orderType === 'main' &&
                                <div className="selectionItem">
                                    <div className="title">Update invoice</div>
                                    <div className="items">
                                        <div
                                            className={'item selectedOrder'}
                                            onClick={() => this.setState({option_updateInvoice: true})}>Yes
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {this.state.orderType === 'main' &&
                            <div className="row">
                                <div className="orderItems">
                                    <div className="selectionItem">
                                        <div
                                            className={this.state.option_phone.length ? "title" : 'title red'}>Shipping
                                        </div>
                                        <div className="items">
                                            <div>
                                                {this.state.option_shipper === 'Packeta' &&
                                                    <Input placeholder="Name" onChange={(e) => {
                                                        this.setState({option_packetaName: e.target.value})
                                                    }}/>
                                                }

                                                {this.state.option_shipper === 'Packeta' &&
                                                    <Input placeholder="Surname" onChange={(e) => {
                                                        this.setState({option_packetaSurname: e.target.value})
                                                    }}/>
                                                }

                                                {this.state.option_shipper === 'Packeta' &&
                                                    <Input placeholder="Street" onChange={(e) => {
                                                        this.setState({option_packetaStreet: e.target.value})
                                                    }}/>
                                                }

                                                {this.state.option_shipper === 'Packeta' &&
                                                    <Input placeholder="House Number" onChange={(e) => {
                                                        this.setState({option_packetaHouseNr: e.target.value})
                                                    }}/>
                                                }

                                                <Input value={this.state.option_phone} placeholder="Phone" onChange={(e) => {
                                                    this.setState({option_phone: e.target.value})
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.orderType === 'suborder' &&
                            <div className="row">
                                <div className="orderItems">
                                    <h2>Main order</h2>
                                    {this.state.userOrders.map(userOrder => {
                                        const createdTimeRaw = moment(userOrder.created);
                                        let createdTime = createdTimeRaw.tz('Europe/Bucharest').format('DD.MM.YYYY');

                                        if (userOrder.id === this.state.order.id) {
                                            return null;
                                        }
                                        return (
                                            <div className="mainOrderSelector">
                                                <Checkbox
                                                    className='checkbox-suborder'
                                                    name='checkboxRadioGroup'
                                                    value='this'
                                                    checked={this.state.option_suborderId === Generate(userOrder.id)}
                                                    onChange={(e, {checked}) => this.setState({option_suborderId:Generate(userOrder.id) })}
                                                />
                                                <div>{Generate(userOrder.id)}</div>
                                                <div className="mainOrderSelectorStatus"><OrderStatusToggler displayOnly status={userOrder.status}/></div>
                                                <div>{createdTime}</div>
                                            </div>
                                        )
                                    })}

                                    <div>or</div>
                                    <Input placeholder="manually add the order" onChange={(e) => {
                                        this.setState({option_suborderId: e.target.value})
                                    }}/>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="orderItems">
                                {/*<h2>EXTERNAL_META</h2>*/}
                                {/*<div>{externalMetaItems}</div>*/}

                                {/*<h2>ITEMS</h2>*/}
                                <div>{filledItems}</div>
                            </div>
                        </div>
                        <div>
                            <Button primary onClick={this.onFillOrderClicked}>Fill Order</Button>
                        </div>
                    </div>
                }

                {this.state.loading === true &&
                    <h2>Quick filling order...</h2>
                }
            </div>
        )
    }

    getRequestedShipAddress = () => {
        const address = this.state.order?.external_meta?.shiping;

        if (!address) {
            return null;
        }

        return (
            <div style={{opacity: 0.7}}>
                <div className='extra' style={{fontSize: '14px'}}>
                    <div><span style={{fontWeight: 'bold'}}>Name:</span> {address.name}</div>
                    <div><span style={{fontWeight: 'bold'}}>First line:</span> {address.first_line}
                    </div>
                    <div><span style={{fontWeight: 'bold'}}>Second line: </span>{address.second_line}</div>
                    <div><span style={{fontWeight: 'bold'}}>City:</span> {address.city}</div>
                    <div><span style={{fontWeight: 'bold'}}>State:</span> {address.state}</div>
                    <div><span style={{fontWeight: 'bold'}}>Zip:</span> {address.zip}</div>
                    <div><span style={{fontWeight: 'bold'}}>Country:</span> {address.country}</div>
                    <div><span style={{fontWeight: 'bold'}}>Email:</span> {address.email}</div>
                    <div><span style={{fontWeight: 'bold'}}>Phone:</span> {address.phone}</div>
                </div>
            </div>
        )
    }

    onFillOrderClicked = () => {
        let payload = {...this.state};
        delete payload.order;
        delete payload.items;
        delete payload.configs;

        payload.filledItems = {...payload.filledItems}

        this.setState({loading: true}, async () => {
            window.scrollTo(0, 0);
            await ApiService.quickFillOrder(payload);

            this.setState({loading: false}, () => {
                if(this.state.orderType === 'main') {
                    window.location = `/order/${Generate(payload.orderId)}`
                } else {
                    window.location = `/order/${this.state.option_suborderId}`
                }
            })
        })
    }

    getFilledItems = () => {
        const filledItems = Object.values(this.state.filledItems);
        if (!filledItems.length) {
            return null;
        }

        return filledItems.map(item => {
            if (item?.product_type === 'guestbook') {
                return <FilledItemGuestbook data={item} configs={this.state.configs}
                                            onPropertyChange={this.onItemPropertyChanged}/>
            } else if (item?.product_type === 'corners') {
                return <FilledItemCorners data={item}/>
            } else if (item?.product_type === 'shipping') {
                return <FilledItemShipping data={item}/>
            } else if (item?.product_type === 'discount') {
                return <FilledItemDiscount data={item}/>
            } else if (item?.product_type === 'pen') {
                return <FilledItemPen data={item} onPropertyChange={this.onItemPropertyChanged}/>
            } else if (item?.product_type === 'pen-three') {
                return <FilledItemPenThree data={item} onPropertyChange={this.onItemPropertyChanged}/>
            }else if (item?.product_type === 'pen-three-same') {
                return <FilledItemPenThreeSame data={item} onPropertyChange={this.onItemPropertyChanged}/>
            }
            else if (item?.product_type === 'pen-two') {
                return <FilledItemPenTwo data={item} onPropertyChange={this.onItemPropertyChanged}/>
            }
            else if (item?.product_type === 'rush') {
                return <FilledItemRush data={item} onPropertyChange={this.onItemPropertyChanged}/>
            } else {
                return <FilledItemUnknown/>
            }
        });
    };

    onItemPropertyChanged = (item, property, value) => {
        item = {...item}
        item.autofill[property] = value;

        if (property === 'option_notes') {
            item.details = value;
        }

        let items = {...this.state.filledItems};
        items[item.id] = item;
        this.setState({filledItems: items});
    }


    getExternalMetaItems = () => {
        if (!this.state.order?.external_meta?.items?.length) {
            return null;
        }

        return this.state.order.external_meta.items.map(item => {
            return (
                <div>{item.sku}</div>
            )
        })
    }

    getItemsRenderer = () => {
        if (!this.state.items?.length) {
            return null;
        }

        return this.state.items.map(item => {
            return (
                <div>{item.product_type}</div>
            )
        })
    }

    getExternalItems = () => {
        return this.getEtsyExternalItems();
    };

    getEtsyExternalItems = () => {
        if (!this.state.order?.external_meta) {
            return null;
        }

        return this.state.order.external_meta.items.map(order => {
            return (
                <OrderPiece
                    key={order.item_id}
                    data={order}
                />
            )
        });
    }

    getOrderById = async (orderId) => {
        const order = await ApiService.getOrderById(orderId);
        const items = await ApiService.loadOrderItems(orderId);
        const configs = await ApiService.loadProductConfig();
        const users = await ApiService.getUserById(order.data.users_id);

        this.setState({order: order.data, items: items.data, configs: configs.data}, () => {
            let filledItems = this.fillItems(order.data.external_meta.items, items.data);
            let filledItemsObject = {};

            filledItems.forEach(item => {
                if (item && item.id) {
                    filledItemsObject[item.id] = item;
                }
            })

            let shipper = 'DHL';
            let country = '';

            const country_code = order?.data?.external_meta?.shiping?.country_code;
            country = order?.data?.external_meta?.shiping?.country;

            if (country_code) {
                const inEU = this.euList[country_code];
                if (inEU) {
                    shipper = "Packeta"
                }
            }
            this.setState({
                filledItems: filledItemsObject,
                option_shipper: shipper,
                option_country: country,
                userOrders: users.data.orders
            })
        })
    }

    fillItems = (etsyItems, localItems) => {
        let newLocalItems = [];

        for (let i = 0; i < etsyItems.length; i++) {
            let etsyItem = etsyItems[i];
            let localItem = localItems[i];

            newLocalItems.push(this.fillItem(etsyItem, localItem));
        }

        return newLocalItems;
    }

    fillItem = (etsyData, localData) => {
        if(etsyData.sku.indexOf('GB-') > -1){
            return this.fillGuestbook(etsyData, localData)
        }
        switch (etsyData.sku) {
            case "CORNERS":
                return this.fillCorners(etsyData, localData)
            case "Discount":
                return this.fillDiscount(etsyData, localData)
            case "Shipping":
                return this.fillShipping(etsyData, localData)
            case "PEN":
                return this.fillPen(etsyData, localData)
            case "RUSH":
                return this.fillRush(etsyData, localData)
            default:
                localData.product_type = 'unknown';
                return localData
        }
    }

    fillGuestbook = (etsy, local) => {
        local.quantity = local.quantity;
        local.invoiceName = 'Photo album';
        local.product_type = 'guestbook';
        local.placeholder = 0;

        local.autofill = {
            option_size: "",
            option_sides: "",
            option_material: "",
            option_cover: "Classic",
            option_pages: "white",
            option_spacers: "no",
            option_notes: "",
            option_uv: "no",
            option_includedCorners:'white',
            option_embossTemplate: "no",
            option_embossFoil: "gold",
        };

        etsy.config.forEach(item => {
            if (item.name === 'Size and Sides') {
                let parts = item.value.split('/');

                parts.forEach(part => {
                    if (part.indexOf('cm') !== -1) {
                        let s = part.split('cm')[0];
                        s = s.split('x');
                        if (s.length === 2) {
                            s = s.map(ss => parseInt(ss));
                            local.autofill.option_size = `${s[0]}x${s[1] * 2}`
                        }
                    }

                    if (part.indexOf('sd') !== -1) {
                        let s = part.split('sd')[0];
                        local.autofill.option_sides = s;
                    }
                })
            }
            if (item.name === 'Template &amp; Foil Color') {
                let value = item.value.toLowerCase();
                if (value.indexOf('template #') > -1) {
                    let split = value.split('template #');
                    if (split.length > 1) {
                        let number = '';
                        let numberValue = split[1].split('')
                        for (let i = 0; i < numberValue.length; i++) {
                            let nr = numberValue[i];

                            if (!isNaN(parseInt(nr))) {
                                number += nr;
                            } else {
                                break;
                            }
                        }

                        const finalNumber = parseInt(number);
                        if (!isNaN(finalNumber)) {
                            if (finalNumber < 100 && finalNumber > 0) {
                                local.autofill.option_embossTemplate = `#${finalNumber}`
                            }
                        }
                    }
                }
            }
            if (item.name === 'Will you add photos?') {
                let value = item.value.toLowerCase();
                if(value === 'yes'){
                    local.autofill.option_spacers = 'yes'
                }
            }
            if(item.name === 'Personalization'){
                const parsedLines = this.fixPersonalisationRequest(this.parsePersonalisationRequest(item.value));

                parsedLines.forEach(item=>{
                    for(let key in item){
                        let value = item[key];

                        if(value){
                            switch (key){
                                case 'cover':
                                    local.autofill.option_material = value;
                                    break;
                                case 'template':
                                    local.autofill.option_embossTemplate = value;
                                    break;
                                case 'lettering color':
                                    local.autofill.option_embossFoil = value;
                                    break;
                                case 'text line 1':
                                    local.autofill.emboss_text_line_1 = value;
                                    break;
                                case 'text line 2':
                                    local.autofill.emboss_text_line_2 = value;
                                    break;
                                case 'text line 3':
                                    local.autofill.emboss_text_line_3 = value;
                                    break;
                                case 'text line 4':
                                    local.autofill.emboss_text_line_4 = value;
                                    break;
                                case 'text line 5':
                                    local.autofill.emboss_text_line_5 = value;
                                    break;
                                case 'phone number':
                                    this.setState({option_phone: value})
                                    break;
                            }
                        }


                    }
                })

            }
        })

        return local;
    }

    parsePersonalisationRequest = text =>{
        let parsedLines = [];

        const lines = text.split('\n');

        if(!lines.length){
            return parsedLines;
        }

        let availableLines = ['delivery deadline', 'cover', "template", "lettering color", "text line 1", "text line 2", "text line 3", "text line 4", "text line 5", "mock-up", "phone number"];
        availableLines.forEach(item=>{
            lines.forEach(line=>{
                if(line.toLowerCase().indexOf(item) > -1){
                    let parts = line.split(':');
                    if(parts.length === 2){
                        let value = parts[1].trim();
                        parsedLines.push({
                            [item]: value
                        })
                    }
                }
            })
        })

        return parsedLines;
    }

    fixPersonalisationRequest = data =>{
        data.forEach(item=>{
            for(const key in item){
                let value = item[key];

                if(key === 'template'){
                    if(value.indexOf('#') === -1){
                        if(!isNaN(value)){
                            item[key] = `#${value}`;
                        }
                    }
                }
                if(key === 'cover'){
                    const material = this.state.configs.materials.find(i=>i.name === value);
                    if(!material){
                        item[key] = null;
                    }
                }
                if(key === 'lettering color'){
                    const foil = this.state.configs.foils.find(i=>i.name === value.toLowerCase());
                    if(!foil){
                        item[key] = null;
                    } else {
                        item[key] = value.toLowerCase()
                    }
                }
                if(key === 'mock-up'){
                    if(value.toLowerCase() === 'yes'){
                        item[key] = 'yes'
                    }
                    else if(value.toLowerCase() === 'no'){
                        item[key] = 'no'
                    } else {
                        item[key] = null;
                    }
                }
            }
        })

        return data;
    }

    fillCorners = (etsy, local) => {
        local.quantity = local.quantity;
        local.invoiceName = 'Extra corners';
        local.product_type = 'corners';
        local.placeholder = 0;

        local.autofill = {
            option_color: "",
            option_sheets: "",
        };

        etsy.config.forEach(item => {
            if (item.name === 'Pieces') {
                let parts = item.value.split(' pieces');

                if (parts.length === 2) {
                    let amount = parseInt(parts[0]);

                    if (!isNaN(amount)) {
                        local.autofill.option_sheets = amount / 24;
                    }
                }
            }
            if (item.name === 'Color') {
                local.autofill.option_color = item.value;
            }
        })

        return local;
    }

    fillDiscount = (etsy, local) => {
        local.quantity = local.quantity;
        local.invoiceName = 'Discount';
        local.product_type = 'discount';
        local.placeholder = 0;

        local.autofill = {};

        return local;
    }

    fillShipping = (etsy, local) => {
        local.quantity = local.quantity;
        local.invoiceName = 'Shipping';
        local.product_type = 'shipping';
        local.placeholder = 0;

        local.autofill = {};

        return local;
    }

    fillRush = (etsy, local) => {
        local.quantity = local.quantity;
        local.invoiceName = 'Rush order';
        local.product_type = 'rush';
        local.placeholder = 0;

        local.autofill = {};

        return local;
    }

    fillPen = (etsy, local) => {
        local.quantity = local.quantity;
        local.invoiceName = 'Pen';
        local.product_type = 'pen';
        local.placeholder = 0;

        local.autofill = {
            option_color: '',
            option_set: '',
        };

        etsy.config.forEach(item => {
            if (item.name === 'Pen Color') {
                local.autofill.option_color = item.value.toLowerCase();
            }
            if (item.name === 'Number of Pens') {
                if (item.value === 'Set of 3 Multicolor') {
                    local.product_type = 'pen-three';
                    local.autofill.option_set = 'a Set of 3 Multicolor Pens';
                } else if (item.value === '1 Pen') {
                    local.autofill.option_set = 'One Pen'
                } else if (item.value === 'Set of 2 Pens') {
                    local.product_type = 'pen-two';
                    local.autofill.option_set = 'a Set of 2 Pens'
                } else if (item.value === 'Set of 3 Pens') {
                    local.autofill.option_set = 'a Set of 3 Pens'
                    local.product_type = 'pen-three-same';
                }

            }
        })

        return local;
    }

    getSKUS = async () => {
//         let newOrders = await ApiService.getOrders();
//         newOrders = newOrders.data.orders;
//         newOrders = newOrders.filter(o=>o.status === 'new');
//
//         newOrders = newOrders.map(o=>o.external_meta.items)
//
//         let skus = {};
//
//         newOrders.forEach(batch=>{
//             batch.forEach(item=>{
//                 skus[item.sku] = 1;
//             })
//         })
//
// console.log(newOrders)
//         console.log(Object.keys(skus))
    }

}


export default QuickFillScreen;
