import React, {Component} from 'react';
import './MaterialsScreen.scss';
import {Button, Container, Dimmer, Header, Loader, Menu} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import MaterialConfig2 from "../components/MaterialConfig2";

class MaterialsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            materials: [],
            sortProperty: 'name',
            sortDirection: 'up',
            editingId: -1
        }
    }

    componentDidMount() {
        this.loadMaterials();
    }

    render() {
        const materials = this.getMaterials();

        return (
            <Container>
                <Dimmer active={this.state.loading}>
                    <Loader/>
                </Dimmer>
                <Menu pointing inverted color='blue' stackable style={{marginTop: '2rem'}}>
                    <Menu.Item header>Materials configuration</Menu.Item>
                </Menu>

                <Button onClick={this.onNewMaterialClicked}>new material</Button>

                <div style={{
                    display: 'flex',
                    margin: '60px 0 30px 0',
                    paddingBottom: '10px',
                    borderBottom: '1px solid #eeeeee'
                }}>
                    <div style={{width: '122px'}}>Sort</div>
                    <div style={{
                        width: '170px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'name' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'name', sortDirection: 'up'})}>name
                    </div>
                    <div style={{
                        width: '114px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'category' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'category', sortDirection: 'up'})}>category
                    </div>
                    <div style={{
                        width: '115px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'available' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'available', sortDirection: 'down'})}>available
                    </div>
                    <div style={{
                        width: '70px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'for_album' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'for_album', sortDirection: 'down'})}>albums
                    </div>
                    <div style={{
                        width: '90px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'for_guestbook' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'for_guestbook', sortDirection: 'down'})}>guestbooks
                    </div>
                    <div style={{
                        width: '73px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'for_boudoir' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'for_boudoir', sortDirection: 'down'})}>boudoir
                    </div>
                    <div style={{
                        width: '145px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'for_boudoir-empty' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({
                        sortProperty: 'for_boudoir-empty',
                        sortDirection: 'down'
                    })}>boudoir-empty
                    </div>
                    <div style={{
                        width: '145px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'for_classic-album' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({
                        sortProperty: 'for_classic-album',
                        sortDirection: 'down'
                    })}>classic albums
                    </div>
                    <div style={{
                        width: '50px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'canUvPrint' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'canUvPrint', sortDirection: 'down'})}>uv
                    </div>
                    <div style={{
                        width: '90px',
                        cursor: 'pointer',
                        fontWeight: this.state.sortProperty === 'canEmboss' ? 'bold' : 'normal'
                    }} onClick={() => this.setState({sortProperty: 'canEmboss', sortDirection: 'down'})}>emboss
                    </div>
                </div>
                {materials}
            </Container>
        );
    }

    getMaterials = () => {
        if (this.state.materials.length === 0) {
            return null;
        }

        const prop = this.state.sortProperty;
        const direction = this.state.sortDirection;

        const sortFn = (a, b) => {
            if (direction === 'up') {
                if (a[prop] < b[prop]) {
                    return -1;
                }
                if (a[prop] > b[prop]) {
                    return 1;
                }
                return 0;
            } else {
                if (a[prop] > b[prop]) {
                    return -1;
                }
                if (a[prop] < b[prop]) {
                    return 1;
                }
                return 0;
            }
        }

        let data = [...this.state.materials].sort(sortFn);

        return data.map(material => {
            return (
                <MaterialConfig2
                    key={material.id}
                    editing={this.state.editingId === material.id}
                    config={this.props.config}
                    data={material}
                    onEditClicked={(id) => this.setState({editingId: id})}
                    onDataChanged={this.onDataChanged}
                    onMaterialDeleteClicked={this.onMaterialDeleteClicked}
                    onVisualValidToggled={this.onVisualValidToggled}
                />
            )
        })
    };

    onDataChanged = (material) => {
        this.setState({loading: false}, () => {
            ApiService.editMaterial(material).then(data => {
                this.setState({materials: data.data.materials, loading: false, editingId: -1})
            })
        })
    }

    onMaterialDeleteClicked = (id) => {
        ApiService.deleteMaterial(id).then(() => {
            this.loadMaterials();
        })
    }

    onVisualValidToggled = (id) => {
        ApiService.toggleMaterialValability(id).then(() => {
            this.loadMaterials();
        })
    }

    loadMaterials = () => {
        ApiService.getAllMaterials().then(data => {
            this.setState({materials: data.data, loading: false, editingId: -1})
        })
    }

    onNewMaterialClicked = () => {
        let materials = [...this.state.materials];

        let newMaterial = {
            available: 0,
            blindEmbossColor: "",
            canEmboss: 0,
            canUvPrint: 0,
            category: "",
            deleted: 0,
            for_album: 1,
            for_boudoir: 0,
            'for_boudoir-empty': null,
            'for_classic-album': null,
            for_guestbook: 0,
            highlightEmbossColor: "",
            id: -1,
            imagePath: null,
            internal_code: "",
            lowlightEmbossColor: "",
            manufacturerCode: "",
            manufacturerName: "",
            meta: {},
            name: "0 New Material",
            order_album: null,
            order_boudoir: null,
            'order_boudoir-empty': null,
            order_guestbook: 0,
            thumbPath: null,
            valid: 0,
        }

        materials.push(newMaterial);

        this.setState({materials:materials})
    }


}


export default MaterialsScreen;
