import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import BambooBoxConfig from "./BambooBoxConfig";
import CornerConfig from "./CornerConfig";

class CornersConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, corners: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getProductCorners().then(data => {
                this.setState({loading: false, corners: data.data});
            });
        });

    }

    render() {
        const corners = this.getCorners();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Item.Group divided relaxed>
                    {corners}
                </Item.Group>

                <Button style={{marginTop: '3rem'}} content='add new size'  basic onClick={this.onAddNewCornerClicked}/>
            </Container>
        );
    }

    getCorners = () => {
        if (this.state.corners.length === 0) {
            return null;
        }

        return this.state.corners.map(size => {
            return (
                <CornerConfig key={size.id} data={size} onSavedClicked={this.onSizeChanged}/>
            )
        })
    };

    onSizeChanged = (corner) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editProductCorners(corner).then(data => {
                this.setState({loading: false, corners: data.data});
            });
        });
    };

    onAddNewCornerClicked = () => {
        let corners = [...this.state.corners];
        corners.push({
            editing: true,
            id: -1,
            color: '',
            available: false,
            type: this.props.type
        });

        this.setState({
            corners: corners
        })
    };
}


export default CornersConfig;
