import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Input, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FoilConfig from "./FoilConfig";
import EmbossTemplateConfig from "./EmbossTemplateConfig";

class PricingConfigRush extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'price': this.props.bambooBoxPricing['price'] || 0,
            editing: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            'price': nextProps.bambooBoxPricing['price'] || 0,
        })
    }

    render() {
        const viewElement = this.getViewElement();
        const editElement = this.getEditElement();

        if (!this.state.editing) {
            return viewElement;
        }
        if (this.state.editing) {
            return editElement;
        }
    }

    getViewElement = () => {
        return (
            <Table.Row>
                <Table.Cell>{this.state['price']}</Table.Cell>
                <Table.Cell><Button disabled={this.props.selectedCurrency !== 'USD'} basic onClick={() => this.setState({editing: true})}>edit</Button></Table.Cell>
            </Table.Row>
        )
    }

    getEditElement = () => {
        return (
            <Table.Row >
                <Table.Cell><Input name='price' value={this.state['price']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Button  basic onClick={this.onSaveClicked}>save</Button></Table.Cell>
            </Table.Row>
        )
    }

    onSaveClicked = () => {
        let payload = {
            values: {...this.state},
            path: `${this.props.type}.rush`,
            priceId: this.props.priceId,
        }

        delete payload.values.editing;

        this.setState({editing: false}, ()=>{
            this.props.onUpdateClientPrice(payload);
        })

    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});

}


export default PricingConfigRush;
