import React, {Component} from 'react';
import AuthService from "../services/AuthService";

class HomeScreen extends Component {
    render() {
        const role = AuthService.getUserData().role;

        switch(role){
            case 'admin':
                window.location = '/orders';
                break;
            case 'manufacture':
                window.location = '/manufacture';
                break;
            case 'external':
                window.location = '/external';
                break;
        }
        return null;
    }
}


export default HomeScreen;
