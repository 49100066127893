import React, {Component} from 'react';
import {Table} from "semantic-ui-react";

class BlockStockTableItem extends Component {
    render() {
        const items = this.getItems();

        return items;
    }

    getItems = () => {
       return this.props.items.map(item=>{
           return (
               <Table.Row id={item.id} onClick={()=>this.props.onItemClicked(item)}>
                   <Table.Cell>{item.sku}</Table.Cell>
                   <Table.Cell>{item.quantity}</Table.Cell>
                   <Table.Cell>{item.pick_place}</Table.Cell>
                   <Table.Cell>{item.meta.sizeName}</Table.Cell>
                   <Table.Cell>{item.meta.pages}</Table.Cell>
                   <Table.Cell>{item.statistics.cpw}</Table.Cell>
                   <Table.Cell error={item.statistics.wis<1 && item.statistics.cpw > 0}>{item.statistics.wis}</Table.Cell>
                   <Table.Cell error={item.statistics.mpw > 10}>{item.statistics.mpw}</Table.Cell>
               </Table.Row>
           )
       })
    }
}

export default BlockStockTableItem;
