import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import SupplierPricesBookItem from "./SupplierPricesBookItem";
import SupplierPricesCornerItem from "./SupplierPricesCornerItem";

class SupplierPricesCorners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            corners: []
        };
    }

    componentDidMount() {
        ApiService.getProductCorners().then(data => {
            this.setState({corners: data.data})
        })
    }

    render() {
        const elements = this.getElements();

        return (
            <div>
                <h2 style={{marginTop: '100px'}}>corners</h2>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell width={12}/>

                            <Table.HeaderCell width={1}/>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {elements}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    getElements = () => {
        if (this.state.corners.length === 0) {
            return null;
        }


        let prices = this.props.prices.prices;


        let supplierPrices = prices[this.props.supplierId];
        if (!supplierPrices) {
            supplierPrices = {};
        }


        let productPrices = supplierPrices['corner'];
        if (!productPrices) {
            productPrices = {};
        }

        return this.state.corners.map(corner => {
            let price = {...productPrices[corner.color]};
            if(!price){
                price = {};
            }

            return (
                <SupplierPricesCornerItem key={`corners.${corner.id}`} corner={corner} pricesId={this.props.prices.id}
                                        price={price} type='corner' supplierId={this.props.supplierId}
                                        onUpdatePrice={this.props.onUpdatePrice}/>
            )
        })
    }

}


export default SupplierPricesCorners;
