import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import SupplierPricesBookItem from "./SupplierPricesBookItem";
import SupplierPricesCornerItem from "./SupplierPricesCornerItem";
import SupplierPricesBambooBoxItem from "./SupplierPricesBambooBoxItem";

class SupplierPricesBambooBoxes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            boxes: []
        };
    }

    componentDidMount() {
        ApiService.getProductBamboo().then(data => {
            this.setState({boxes: data.data})
        })
    }

    render() {
        const elements = this.getElements();

        return (
            <div>
                <h2 style={{marginTop: '100px'}}>bamboo boxes</h2>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell width={12}/>

                            <Table.HeaderCell width={1}/>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {elements}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    getElements = () => {
        if (this.state.boxes.length === 0) {
            return null;
        }


        let prices = this.props.prices.prices;


        let supplierPrices = prices[this.props.supplierId];
        if (!supplierPrices) {
            supplierPrices = {};
        }


        let productPrices = supplierPrices['bamboo-box'];
        if (!productPrices) {
            productPrices = {};
        }

        return this.state.boxes.map(box => {
            let price = {...productPrices[box.cm]};
            if(!price){
                price = {};
            }

            return (
                <SupplierPricesBambooBoxItem key={`bamboo-box.${box.id}`} box={box} pricesId={this.props.prices.id}
                                        price={price} type='bamboo-box' supplierId={this.props.supplierId}
                                        onUpdatePrice={this.props.onUpdatePrice}/>
            )
        })
    }

}


export default SupplierPricesBambooBoxes;
