import React, {Component} from 'react';
import './SupplierProductConfig.scss';
import {Button, Icon} from "semantic-ui-react";

class SupplierProductConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            abg: null,
            corners: null,
            bamboo: null,
            puouch: null
        };

        this.abgFileInputRef = React.createRef();
        this.cornersFileInputRef = React.createRef();
        this.bambooFileInputRef = React.createRef();
        this.pouchFileInputRef = React.createRef();

    }


    render() {
        return (
            <div className='SupplierProductConfig'>
                <div className='title'>Update price list</div>
                <div className='body'>
                    <div>
                        {!this.state.abg &&
                        <Icon name='warning sign' size='large' style={{color: 'red'}}/>
                        }
                        {this.state.abg &&
                        <Icon name='checkmark' size='large' style={{color: 'green'}}/>
                        }
                        <Button
                            basic
                            content="select album/guestbook/boudoir tsv"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.abgFileInputRef.current.click()}
                        />
                        <input
                            ref={this.abgFileInputRef}
                            type="file"
                            hidden
                            onChange={this.onAbgFileSelected}
                        />
                    </div>

                    <div>
                        {!this.state.corners &&
                        <Icon name='warning sign' size='large' style={{color: 'red'}}/>
                        }
                        {this.state.corners &&
                        <Icon name='checkmark' size='large' style={{color: 'green'}}/>
                        }
                        <Button
                            basic
                            content="select corners tsv"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.cornersFileInputRef.current.click()}
                        />
                        <input
                            ref={this.cornersFileInputRef}
                            type="file"
                            hidden
                            onChange={this.onCornersFileSelected}
                        />
                    </div>

                    <div>
                        {!this.state.bamboo &&
                        <Icon name='warning sign' size='large' style={{color: 'red'}}/>
                        }
                        {this.state.bamboo &&
                        <Icon name='checkmark' size='large' style={{color: 'green'}}/>
                        }
                        <Button
                            basic
                            content="select bamboo tsv"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.bambooFileInputRef.current.click()}
                        />
                        <input
                            ref={this.bambooFileInputRef}
                            type="file"
                            hidden
                            onChange={this.onBambooFileSelected}
                        />
                    </div>

                    <div>
                        {!this.state.pouch &&
                        <Icon name='warning sign' size='large' style={{color: 'red'}}/>
                        }
                        {this.state.pouch &&
                        <Icon name='checkmark' size='large' style={{color: 'green'}}/>
                        }
                        <Button
                            basic
                            content="select pouch tsv"
                            labelPosition="left"
                            icon="image"
                            onClick={() => this.pouchFileInputRef.current.click()}
                        />
                        <input
                            ref={this.pouchFileInputRef}
                            type="file"
                            hidden
                            onChange={this.onPouchFileSelected}
                        />
                    </div>

                    <Button className='save-button'
                            disabled={!this.state.abg || !this.state.corners || !this.state.bamboo || !this.state.pouch}
                            onClick={this.onSaveClicked}>Save</Button>
                </div>
            </div>
        )
    }

    onAbgFileSelected = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const contents = e.target.result;

            let prices = this.parseTSV(['cm', 'inch', 'ratio', 'aspect', 'type', 'priceColaj', 'priceCoverTextil', 'priceCoverEco', 'priceCoverLeather', 'priceCoverPremiumLeather','priceCoverVelvet', 'priceColajRezerva',
                'priceColajNegru', 'priceColajText', 'priceCoverUV', 'priceCoverPPT', 'priceCoverEmboss', 'pricePrintFlorin'], contents);

            prices = prices.map(price => {
                let ins = {
                    type: price.type,
                    _id: `${price.type}.${price.cm}`,
                    currency: 'RON',
                    suppliers_id: this.props.supplierId,
                    values: {
                        size: price.cm,
                        priceColaj: price.priceColaj,
                        priceCoverTextil: price.priceCoverTextil,
                        priceCoverEco: price.priceCoverEco,
                        priceCoverLeather: price.priceCoverLeather,
                        priceCoverPremiumLeather: price.priceCoverPremiumLeather,
                        priceCoverVelvet: price.priceCoverVelvet,
                        priceColajRezerva: price.priceColajRezerva,
                        priceColajNegru: price.priceColajNegru,
                        priceColajText: price.priceColajText,
                        priceCoverUV: price.priceCoverUV,
                        priceCoverPPT: price.priceCoverPPT,
                        priceCoverEmboss: price.priceCoverEmboss,
                        pricePrintFlorin: price.pricePrintFlorin,
                    }
                };

                ins.values = JSON.stringify(ins.values);

                return ins;
            });

            this.setState({
                abg: prices
            })
        };
        reader.readAsText(file);
    };

    onCornersFileSelected = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const contents = e.target.result;

            let prices = this.parseTSV(['type', 'color', 'price', 'currency'], contents);

            prices=prices.map(item=>{
                let ins = {
                    type: 'corners',
                    _id: `${item.type}.${item.color}`,
                    currency: 'RON',
                    suppliers_id: 1,
                    values: {
                        color: item.color,
                        price: item.price
                    }
                }

                ins.values = JSON.stringify(ins.values);

                return ins;
            });

            this.setState({
                corners: prices
            })
        };
        reader.readAsText(file);
    };


    onBambooFileSelected = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const contents = e.target.result;

            let prices = this.parseTSV(['type', 'cm', 'inch', 'price', 'currency'], contents);

            prices = prices.map(price => {
                let ins = {
                    type: 'bamboo-box',
                    _id: `${price.type}.${price.cm}`,
                    currency: 'RON',
                    suppliers_id: this.props.supplierId,
                    values: {
                        cm: price.cm,
                        inch: price.inch,
                        price: price.price,
                    }
                };

                ins.values = JSON.stringify(ins.values);

                return ins;
            });

            this.setState({
                bamboo: prices
            })
        };
        reader.readAsText(file);
    };

    onPouchFileSelected = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const contents = e.target.result;

            let prices = this.parseTSV(['type', 'cm', 'price', 'currency'], contents);

            prices = prices.map(price => {
                let ins = {
                    type: 'pouch',
                    _id: `${price.type}.${price.cm}`,
                    currency: 'RON',
                    suppliers_id: this.props.supplierId,
                    values: {
                        cm: price.cm,
                        price: price.price,
                    }
                };

                ins.values = JSON.stringify(ins.values);

                return ins;
            });

            this.setState({
                pouch: prices
            })
        };
        reader.readAsText(file);
    };

    parseTSV = (fields, data) => {
        data = data.split('\r\n');
        data.shift();
        data = data.map(m => m.split('\t'));

        return data.map(item => {
            let ob = {};

            for (let i = 0; i < fields.length; i++) {
                ob[fields[i]] = item[i].trim();
            }

            return ob;
        })
    }

    onSaveClicked = () => {
        let itemsToInsert = [...this.state.abg, ...this.state.corners, ...this.state.bamboo, ...this.state.pouch];

        let date = new Date();
        date = date.toISOString();
        date = date.substring(0, 10) + " " + date.substring(11, 19);

        itemsToInsert.forEach(item => {
            item.date = date;
        });

        this.props.onSaveProductsClicked(itemsToInsert);
    }
}


export default SupplierProductConfig;
