import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Input, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FoilConfig from "./FoilConfig";
import EmbossTemplateConfig from "./EmbossTemplateConfig";

class PricingConfigAlbumSize extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'base': this.props.price['base'] || 0,
            'extraSpread': this.props.price['extraSpread'] || 0,
            editing: false
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            'base': nextProps.price['base'] || 0,
            'extraSpread': nextProps.price['extraSpread'] || 0,
        })
    }

    render() {
        const viewElement = this.getViewElement();
        const editElement = this.getEditElement();

        if (!this.state.editing) {
            return viewElement;
        }
        if (this.state.editing) {
            return editElement;
        }
    }

    getViewElement = () => {
        const size = this.props.size;

        return (
            <Table.Row key={size.id}>
                <Table.Cell>{size.cm}</Table.Cell>
                <Table.Cell>{this.state['base']}</Table.Cell>
                <Table.Cell>{this.state['extraSpread']}</Table.Cell>
                <Table.Cell>
                    <Button disabled={this.props.selectedCurrency !== 'USD'}  basic onClick={() => this.setState({editing: true})}>edit</Button>
                </Table.Cell>
            </Table.Row>
        )
    }

    getEditElement = () => {
        const size = this.props.size;
        return (
            <Table.Row key={size.id}>
                <Table.Cell>{size.cm}</Table.Cell>
                <Table.Cell><Input name='base' value={this.state['base']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Input name='extraSpread' value={this.state['extraSpread']} onChange={this.handleChange}/></Table.Cell>
                <Table.Cell><Button basic onClick={this.onSaveClicked}>save</Button></Table.Cell>
            </Table.Row>
        )
    }

    onSaveClicked = () => {
        let payload = {
            values: {...this.state},
            path: `${this.props.type}.size.${this.props.size.cm}`,
            priceId: this.props.priceId,
        }

        delete payload.values.editing;

        this.setState({editing: false}, ()=>{
            this.props.onUpdateClientPrice(payload);
        })

    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});

}


export default PricingConfigAlbumSize;
