import React, {Component} from 'react';
import './TimelineScreen.scss';
import ApiService from "../services/ApiService";
import Utils from "../data/Utils";
import {Icon} from "semantic-ui-react";

class TimelineScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        ApiService.loadTimeline().then(data=>{
            this.setState({data: data.data});
        })
    }

    render() {
        const elements = this.getTimelineElements();
        // const productsElements = this.getProductsElements();

        return (
            <div className='TimelineScreen'>
                {elements}
            </div>
        )
    }

    getTimelineElements = () => {
        if (!this.state.data || this.state.data.length === 0) {
            return null;
        }

        let items = [];
        let lastDate = '';

        this.state.data.forEach(item => {
            let date = Utils.dateToStringDisplay(new Date(item.date), '.');
            if(date.split(' ')[0] !== lastDate){
                items.push((<div style={{borderBottom: '5px solid black', marginBottom: '20px'}}/>));
                lastDate = date.split(' ')[0]
            }
            items.push((
                <div className='Item'>
                    <div className='head'>
                        <a style={{marginRight: '5px'}} href={`/order/${item.orders_id}`} target='_blank'>Order {item.orders_id}</a>

                        <div className='date'>{date}</div>
                        <div className='who'>{item.who}</div>

                        {item.meta &&
                        <Icon className='meta' name='question circle' onClick={()=>this.setState({expandedMetaForId:item.id})}/>
                        }

                    </div>
                    <div className='what' dangerouslySetInnerHTML={{__html: item.what}}></div>

                    {(item.meta && this.state.expandedMetaForId===item.id) &&
                    <pre className='meta'>{JSON.stringify(JSON.parse(item.meta), null, 4)}</pre>
                    }
                </div>
            ))
        })
        return items;
    }


}


export default TimelineScreen;
