import React, {Component} from 'react';
import {Button, Radio, Table} from "semantic-ui-react";
import {Generate} from "../../../utils/checksum";

class StocVsMissingCorners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: "normal"
        }
    }

    render() {
        let renderData = [];

        this.props.items.forEach(item=>{
            let data = {};
            data.orderId = item.orderId;
            data.deadline = item.deadline;

            let skuParts = item.sku.split('-');
            data.color = skuParts[1];
            data.quantity = item.quantity;

            renderData.push(data);
        })

        let mergedData = [];
        let mergedKeys = {}
        this.props.items.forEach(item=>{
            let data = {};
            if(!mergedKeys[item.sku]){
                mergedKeys[item.sku] = 0;
            }
            mergedKeys[item.sku]+=item.quantity;
        })

        for(let sku in mergedKeys){
            let data = {};
            let quantity = mergedKeys[sku];

            let skuParts = sku.split('-');
            data.color = skuParts[1];
            data.quantity = quantity;

            mergedData.push(data);
        }

        return (
            <div>
                <Radio style={{marginBottom:'20px', marginRight:'20px'}}
                    onChange={() => this.setState({mode: this.state.mode === 'normal' ? "merge" : "normal"})}
                    checked={this.state.mode === 'merge'} toggle label={'merge items'}/>

                <Button onClick={()=>this.downloadClicked(renderData, mergedData)} primary size='small'>CSV</Button>

                <div>
                    {this.state.mode === 'normal' ? <div><h4>Total: {renderData?.length}</h4></div> : <div><h4>Total: {mergedData?.length} </h4></div>}
                    {this.state.mode === 'normal' ? this.getNormal(renderData) : this.getMerged(mergedData)}
                </div>

            </div>
        )
    }

    downloadClicked = (normalData, mergeData) =>{
        if(this.state.mode === 'normal') {
            let tsv = `Order Id\tDeadline\tColor\tQuantity\n`;
            normalData.forEach(item => {
                tsv += `${Generate(item.orderId)}\t${item.deadline}\t${item.color}\t${item.quantity}\n`
            })

            let now = new Date();
            this.download(tsv, `corners-all-${now.toString()}.tsv`, 'text/tab-separated-values')
        }
        if(this.state.mode === 'merge') {
            let tsv = `Color\tQuantity\n`;
            mergeData.forEach(item => {
                tsv += `${item.color}\t${item.quantity}\n`
            })

            let now = new Date();
            this.download(tsv, `corners-merge-${now.toString()}.tsv`, 'text/tab-separated-values')
        }
    }

    download = (data, filename, type) => {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    getNormal = (renderData) => {
        const getItems = () => {
            return renderData.map(item=>{
                return (
                    <Table.Row>
                        <Table.Cell><a href={`/order/${Generate(item.orderId)}`} target="_blank">{Generate(item.orderId)}</a></Table.Cell>
                        <Table.Cell>{item.deadline}</Table.Cell>
                        <Table.Cell>{item.color}</Table.Cell>
                        <Table.Cell>{item.quantity}</Table.Cell>
                    </Table.Row>
                )
            })
        }
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Order id</Table.HeaderCell>
                        <Table.HeaderCell>Deadline</Table.HeaderCell>
                        <Table.HeaderCell>Color</Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {getItems()}
                </Table.Body>
            </Table>
        )
    }

    getMerged = (renderData) => {
        const getItems = () => {
            return renderData.map(item=>{
                return (
                    <Table.Row>
                        <Table.Cell>{item.color}</Table.Cell>
                        <Table.Cell>{item.quantity}</Table.Cell>
                    </Table.Row>
                )
            })
        }
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Color</Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {getItems()}
                </Table.Body>
            </Table>
        )
    }
}

export default StocVsMissingCorners;
