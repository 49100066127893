import React, {Component} from 'react';
import {Button, Checkbox, Container, Icon, Image, Input, Item, Label, Modal, Table} from "semantic-ui-react";

class RawMaterialSizeConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: this.props.data.editing,
            mappingsFor: null,
            previewMappings: false,
            currentMappings: this.props.data.meta?.materialMappings || {},

            cm: this.props.data.cm,
            meta: this.props.data.meta || {},
            type: 'raw-material'
        }

    }

    render() {
        const item = this.props.data;

        let availableStyle = {
            width: '20px',
            height: '20px',
            margin: '0 auto'
        }

        if (this.props.data.available) {
            availableStyle.background = '#4caf50';
        } else {
            availableStyle.background = '#f44336';
        }

        const columns = this.getColumns(this.props.allSizes);


        return (
            <>
                <Table.Row key={item.id}>
                    <Table.Cell>
                        <h3>{item.cm}</h3>

                        {this.state.previewMappings &&
                            <div style={{display:'flex', marginTop:'20px'}}>{columns}</div>
                        }

                        {this.state.editing &&
                            <Input label='cm' placeholder='20x30' name='cm' value={this.state.color} onChange={this.handleChange}/>
                        }

                    </Table.Cell>

                    <Table.Cell verticalAlign={"top"}>
                        <div style={{margin: '0 auto', width: 'fit-content'}}>
                            {this.state.editing &&
                                <Button style={{marginTop: '15px'}} compact primary onClick={this.onSaveClicked}>Save</Button>
                            }

                            {this.props.data.id > -1 &&
                            <>
                                <Button basic onClick={() => this.setState({mappingsFor: item})}>edit mappings</Button>
                                <Button onMouseEnter={() => this.setState({previewMappings: true})} onMouseLeave={() => this.setState({previewMappings: false})}
                                        basic>view mappings</Button>
                            </>
                            }
                        </div>
                    </Table.Cell>
                </Table.Row>


                <Modal
                    open={this.state.mappingsFor !== null}>
                    <Modal.Header>Mappings for raw material size {item.cm} cm</Modal.Header>
                    <Modal.Content>
                        <div style={{display: 'flex'}}>
                            {columns}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={() => this.setState({mappingsFor: null})}>Cancel</Button>
                        <Button primary onClick={this.saveMappingsClicked}>Save</Button>
                    </Modal.Actions>
                </Modal>
            </>
        );
    }

    getColumns = (data) => {
        let ret = [];

        for (let name in data) {
            let rows = this.getRows(data[name]);

            ret.push(
                <div key={name} style={{marginLeft: '25px'}}>
                    <h3>{name}</h3>
                    <div>{rows}</div>
                </div>
            )
        }

        return ret;
    }

    getRows = (data) => {
        return data.map(size => {
            let isChecked = this.state.currentMappings[size.id] === true;

            return (
                <div key={size.id}>
                    <Checkbox label={size.cm} checked={isChecked} onChange={(a, b) => this.onMappingChanged(size.id, b.checked)}/>
                </div>
            )
        })
    }

    onMappingChanged = (productSizeId, checked) => {
        let mappings = {...this.state.currentMappings};

        if (!checked) {
            delete mappings[productSizeId];
        } else {
            mappings[productSizeId] = true;
        }

        this.setState({currentMappings: mappings})
    }

    onSaveClicked = () => {
        this.setState({editing: false}, () => {
            this.props.onSavedClicked({
                id: this.props.data.id,
                cm: this.state.cm,
                meta: this.state.meta,
                type: this.state.type
            })
        });

    };

    saveMappingsClicked = () => {
        let meta = this.state.meta;
        meta.materialMappings = this.state.currentMappings;

        this.props.onSavedClicked({
            id: this.props.data.id,
            meta: this.state.meta,
            type: this.state.type
        })

        this.setState({mappingsFor: null})
    }


    handleChange = (e, {name, value}) => {
        switch (name) {
            case 'embossMarginTop':
            case 'embossMarginRight':
            case 'embossMarginBottom':
            case 'embossMarginLeft':
                let meta = {...this.state.meta};
                meta[name] = value;
                this.setState({meta: meta});
                break;
            default:
                this.setState({[name]: value})
                break;
        }

    };
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});

}


export default RawMaterialSizeConfig;
