import React, {Component} from 'react';
import './ShippingScreenBatchAwb.scss';
import {
    Container, Menu, Table,
} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ApiService from "../../services/ApiService";

class ShippingScreenBatchAwb extends Component {
    constructor(props) {
        super(props);

        let shouldEdit = false;
        props.awb.boxes.forEach(box => {
            if (!box.width || !box.height || !box.depth || !box.weight) {
                shouldEdit = true;
            }
        })

        this.state = {
            edit: shouldEdit,
            saving: false,
            boxes: props.awb?.boxes || []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let shouldEdit = false;
        nextProps.awb.boxes.forEach(box => {
            if (!box.width || !box.height || !box.depth || !box.weight) {
                shouldEdit = true;
            }
        })

        this.setState({boxes: nextProps.awb.boxes, edit: shouldEdit})
    }

    render() {
        const order = this.props.order;
        const awb = this.props.awb;

        const awbElement = this.getAwbElement(order, awb);

        return awbElement;
    }

    getAwbElement = (order, awb) => {
        const is_remote_label = awb.is_remote === 2 ? 'no' : 'yes';
        const hasErrors = awb.err_estimate === 1 || awb.err_generate === 1;
        const boxesElements = this.getBoxesElements(order, awb, hasErrors);

        let style = {}
        if (hasErrors) {
            style = {border: '3px solid red'};
        }

        let totalWeight = 0;
        awb.boxes.forEach(box => {
            let bw = box.weight || 0;
            bw = parseFloat(bw);
            totalWeight += bw;
        })

        totalWeight = totalWeight.toFixed(3);

        return (
            <Table compact key={awb.id} style={style}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>AWB</Table.HeaderCell>
                        <Table.HeaderCell>Colete</Table.HeaderCell>
                        <Table.HeaderCell>Dimensiuni</Table.HeaderCell>
                        <Table.HeaderCell>Greutate</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {boxesElements}
                </Table.Body>

                <Table.Footer>

                    <Table.Row>
                        <Table.HeaderCell colSpan='5'>
                            <Table.Row style={{color: 'gray'}}>
                                <Table.Cell>{awb.country}</Table.Cell>
                                <Table.Cell>Remote: {is_remote_label}</Table.Cell>
                                <Table.Cell>{`${awb.cost} RON` || '--- RON'}</Table.Cell>
                                <Table.Cell>{totalWeight} kg</Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                            {!hasErrors &&
                            <div style={{display: 'flex', marginTop: '10px'}}>
                                <div style={{flex: '1'}}>
                                    {(awb.generated === 1 && awb.err_generate !== 1) && !this.props.complete &&
                                    <h3 style={{background: '#4caf50', color: 'white', padding: '5px'}}>OK</h3>
                                    }

                                    {awb.generated === 0 &&
                                    <Button basic size='small' disabled={this.state.saving}
                                            onClick={this.onCreateNewBoxClicked}>Adauga colet</Button>
                                    }
                                </div>
                                {(this.state.edit === false && awb.generated === 0) &&
                                <Button basic color='blue' disabled={this.state.saving} size='small'
                                        onClick={() => this.setState({edit: true})}>Modifica</Button>
                                }

                                {this.state.edit === false && !this.props.complete &&
                                <Button basic color='red' disabled={this.state.saving} size='small'
                                        onClick={() => this.onDeleteAwbClicked(awb.id)}>Sterge</Button>
                                }
                                {this.state.edit !== false &&
                                <Button color='green' size='small' disabled={this.state.saving}
                                        onClick={this.onSaveAwbClicked}>Salveaza</Button>
                                }
                            </div>
                            }
                            {hasErrors &&
                            <div style={{display: 'flex'}}>
                                <div style={{flex: '1'}}>
                                    Din pacate acest AWB nu poate fi creat.
                                </div>
                                <Button color='red' disabled={this.state.saving} size='small'
                                        onClick={() => this.onDeleteAwbClicked(awb.id)}>Sterge</Button>
                            </div>
                            }

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        )

    }

    getBoxesElements = (order, awb, hasErrors) => {
        return awb.boxes.map((box, index) => {
            if (this.state.edit) {
                return (
                    <Table.Row key={box.id}>
                        <Table.Cell>{awb.awb_nr || '---'}</Table.Cell>
                        <Table.Cell>Colet {index + 1}</Table.Cell>
                        <Table.Cell>
                            <input style={{width: '60px'}} value={box.width}
                                   onChange={(e) => this.onBoxParamChanged(box.id, e.target.value, 'width')}/>
                            <input style={{width: '60px'}} value={box.height}
                                   onChange={(e) => this.onBoxParamChanged(box.id, e.target.value, 'height')}/>
                            <input style={{width: '60px'}} value={box.depth}
                                   onChange={(e) => this.onBoxParamChanged(box.id, e.target.value, 'depth')}/>
                        </Table.Cell>
                        <Table.Cell><input style={{width: '60px'}} value={box.weight}
                                           onChange={(e) => this.onBoxParamChanged(box.id, e.target.value, 'weight')}/></Table.Cell>
                        <Table.Cell>
                            {!hasErrors &&
                            <Button color="red" size="mini" disabled={this.state.saving}
                                    onClick={() => this.onDeleteBoxClicked(box)}>X</Button>
                            }
                        </Table.Cell>

                    </Table.Row>
                )
            }
            return (
                <Table.Row key={box.id}>
                    <Table.Cell>{awb.awb_nr || '---'}</Table.Cell>
                    <Table.Cell>Colet {index + 1}</Table.Cell>
                    <Table.Cell>{box.width}/{box.height}/{box.depth}</Table.Cell>
                    <Table.Cell>{box.weight} kg</Table.Cell>
                    <Table.Cell></Table.Cell>
                </Table.Row>
            )
        })
    }

    onDeleteBoxClicked = (box) => {
        this.setState({saving: true}, () => {
            ApiService.shippingBatchDeleteBox(box).then(data => {
                this.setState({saving: false}, () => {
                    this.props.refreshData();
                })
            })
        })

    }

    onBoxParamChanged = (boxId, value, param) => {
        let boxes = [...this.state.boxes];
        let box = boxes.find(i => i.id === boxId);
        box[param] = value;
        box.dirty = true;

        this.setState({boxes: boxes});
    }

    onCreateNewBoxClicked = () => {
        let payload = {
            shipping_batches_awb_id: this.props.awb.id,
            shipping_batches_id: this.props.awb.shipping_batches_id
        }

        ApiService.shippingBatchEditBoxDetails(this.state.boxes).then(data => {
            ApiService.shippingBatchCreateBox(payload).then(data => {
                this.setState({edit: true}, () => {
                    this.props.refreshData();
                })
            });
        });
    }

    onSaveAwbClicked = () => {
        this.setState({saving: true}, () => {
            ApiService.shippingBatchEditBoxDetails(this.state.boxes).then(data => {
                this.setState({edit: false, saving: false}, () => {
                    this.props.refreshData();
                })
            });
        })
    }

    onDeleteAwbClicked = (awbId) => {
        this.setState({saving: true}, () => {
            ApiService.shippingBatchDeleteAwb(awbId).then(data => {
                this.setState({edit: false, saving: false}, () => {
                    this.props.refreshData();
                })
            });
        })
    }

}


export default ShippingScreenBatchAwb;
