import React, {Component} from 'react';
import './OrderPieceElement.scss';
import {Dropdown, Image, Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Generate} from "../utils/checksum";
import {config} from "../../config/config";
import ApiService from "../../services/ApiService";
import UvPackingPrintCheck from "../UvPackingPrintCheck";
import ConsumptionPanel from "../ConsumptionPanel";
import ConsumptionAvailabilityPanel from "../ConsumptionAvailabilityPanel";

class OrderPieceVow extends Component {
    constructor(props) {
        super(props);

        let hasProgress = false;
        if (this.props.data.progress && Object.keys(this.props.data.progress).length) {
            hasProgress = true;
        }


        this.state = {
            productType: this.props.data.product_type || 'vow-book',
            invoiceName: this.props.data.invoiceName || 'Vow Book',
            orderId: this.props.data.orderId || 0,
            item: {
                size: this.props.data.item.size || {id: 0, label: ''},
                nr_pages: this.props.data.item.nr_pages || '',
                material: this.props.data.item.material || {id: 0, label: ''},
                cover_style: this.props.data.item.cover_style || {id: 0, label: ''},
                page_type: this.props.data.item.page_type || {id: 0, label: ''},
                cover_uv: this.props.data.item.cover_uv || '',
                cover_uv_image: this.props.data.item.cover_uv_image || '',
                emboss: this.props.data.item.emboss || {id: 0, label: ''},
                emboss_l1_text: this.props.data.item.emboss_l1_text || '',
                emboss_l1_size: this.getFontSizeHack(this.props.data.item.emboss_l1_size),
                emboss_l1_font: this.getFontByNameHack(this.props.data.item.emboss_l1_font),

                emboss_l2_text: this.props.data.item.emboss_l2_text || '',
                emboss_l2_size: this.getFontSizeHack(this.props.data.item.emboss_l2_size),
                emboss_l2_font: this.getFontByNameHack(this.props.data.item.emboss_l2_font),

                emboss_l3_text: this.props.data.item.emboss_l3_text || '',
                emboss_l3_size: this.getFontSizeHack(this.props.data.item.emboss_l3_size),
                emboss_l3_font: this.getFontByNameHack(this.props.data.item.emboss_l3_font),

                emboss_l4_text: this.props.data.item.emboss_l4_text || '',
                emboss_l4_size: this.getFontSizeHack(this.props.data.item.emboss_l4_size),
                emboss_l4_font: this.getFontByNameHack(this.props.data.item.emboss_l4_font),

                emboss_l5_text: this.props.data.item.emboss_l5_text || '',
                emboss_l5_size: this.getFontSizeHack(this.props.data.item.emboss_l5_size),
                emboss_l5_font: this.getFontByNameHack(this.props.data.item.emboss_l5_font),

                embossTemplate: this.props.data.item.embossTemplate || {id: 0, name: ''},

                thumbPath: this.props.data.item.thumbPath,
                textPosition: this.props.data.item.textPosition || 1,
                textAlignment: this.props.data.item.textAlignment || 1
            },
            progress: hasProgress ? this.props.data.progress : {
                bloc: 0,
                coperta: 0,
                stantat: 0,
                uv: 0,
                impachetat: 0,
            },
            details: this.props.data.details || "",
            price: this.props.data.price || '',
            currency: this.props.data.currency || "USD",
            quantity: this.props.data.quantity || 0,
            id: this.props.data.id || -1
        }
    }

    render() {
        let formInvalid = false;

        if (!this.state.invoiceName || !this.state.invoiceName.length) {
            console.log("FAILED invoiceName")
            formInvalid = true;
        }

        if (!this.state.quantity) {
            console.log("FAILED quantity")
            formInvalid = true;
        }

        if (!this.state.price || !this.state.price.length) {
            console.log("FAILED price")
            formInvalid = true;
        }

        if (!this.state.currency || !this.state.currency.length) {
            console.log("FAILED currency")
            formInvalid = true;
        }

        if (!this.state.item.size || !this.state.item.size.id) {
            console.log("FAILED size")
            formInvalid = true;
        }

        if (!this.state.item.cover_style || !this.state.item.cover_style.id) {
            console.log("FAILED cover_style")
            formInvalid = true;
        }

        if (!this.state.item.nr_pages) {
            console.log(this.state.item.nr_pages, this.state.item)
            console.log("FAILED nr_pages")
            formInvalid = true;
        }

        if (!this.state.item.material || !this.state.item.material.id) {
            console.log("FAILED material")
            formInvalid = true;
        }


        if (!this.state.item.page_type.label || !this.state.item.page_type.label.length) {
            console.log("FAILED page_type")
            formInvalid = true;
        }

        if (!this.state.item.cover_uv || !this.state.item.cover_uv.length) {
            console.log("FAILED cover_uv")
            formInvalid = true;
        }


        if (!this.state.item.emboss.label || !this.state.item.emboss.label.length) {
            console.log("FAILED emboss")
            formInvalid = true;
        }

        const fontSizesL1 = this.getFontSizes(this.state.item.emboss_l1_font.label);
        const fontSizesL2 = this.getFontSizes(this.state.item.emboss_l2_font.label);
        const fontSizesL3 = this.getFontSizes(this.state.item.emboss_l3_font.label);
        const fontSizesL4 = this.getFontSizes(this.state.item.emboss_l4_font.label);
        const fontSizesL5 = this.getFontSizes(this.state.item.emboss_l5_font.label);

        const mainOrderId = this.props.mainOrderId ? this.props.mainOrderId : this.props.data.orders_id;
        const codeSuffix = this.props.data.product_type.substring(0, 3).toUpperCase() + "-" + this.props.data.id;
        const photosName = `${Generate(mainOrderId)}-${codeSuffix}`

        const disableEdit = (this.props.orderStatus === 'in production' || this.props.orderStatus === 'urgent');
        const mainClassName = disableEdit ? 'OrderPieceElement disableAll' : 'OrderPieceElement';

        return (
            <div className={mainClassName}>
                <div className='header'>
                    <h3 className='name'>Vow Book {photosName}</h3>
                    {this.state.id === -1 &&
                        <div className='ct' onClick={this.props.onChangeTypeClicked}>change type</div>
                    }
                    {this.state.id !== -1 &&
                        <div className='delete' onClick={() => this.props.onDeleteClicked(this.state.id)}>delete</div>
                    }
                </div>

                {(disableEdit === true) &&
                <div style={{color:'orange', marginTop:'0'}}>
                    Editing disabled while the order is in production.
                </div>
                }

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Invoice name*</div>
                        <Input style={{width: '200px'}} value={this.state.invoiceName}
                               onChange={(e) => this.onMainFieldChanged('invoiceName', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Price*</div>
                        <Input style={{width: '80px'}} value={this.state.price}
                               onChange={(e) => this.onMainFieldChanged('price', e.target.value)}/>
                        <Input style={{width: '65px'}} value={this.state.currency}
                               onChange={(e) => this.onMainFieldChanged('currency', e.target.value)}/>

                    </div>

                    <div className='item'>
                        <div className='label'>Quantity*</div>
                        <Input style={{width: '60px'}} value={this.state.quantity}
                               onChange={(e) => this.onMainFieldChanged('quantity', e.target.value)}/>
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Size*</div>

                        <Dropdown
                            options={this.props.configs.sizes.filter(i => i.type === 'vow-book').map(i => {
                                return {
                                    key: i.id,
                                    value: i.cm,
                                    text: i.cm,
                                    id: i.id
                                }
                            })}
                            selection compact
                            value={this.state.item.size.value}
                            search
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onItemFieldChanged('size', item.options.find(o => o.value === item.value))}
                        />

                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Nr.Pages*</div>
                        <Input style={{width: '150px'}} value={this.state.item.nr_pages}
                               onChange={(e) => this.onItemFieldChanged('nr_pages', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Material*</div>
                        <Dropdown
                            options={this.props.configs.materials.filter(i => i.for_guestbook === 1).map(i => {
                                return {
                                    key: i.id,
                                    value: i.name,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            search
                            value={this.state.item.material.value}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onItemFieldChanged('material', item.options.find(o => o.value === item.value))}
                        />
                    </div>

                    <div className='item'>
                        <div className='label'>Cover style*</div>

                        <Dropdown
                            options={this.props.configs.styles.filter(i => i.type === 'vow-book').map(i => {
                                return {
                                    key: i.id,
                                    value: i.name,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            search
                            value={this.state.item.cover_style.value}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onItemFieldChanged('cover_style', item.options.find(o => o.value === item.value))}
                        />
                    </div>

                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Pages*</div>

                        <Dropdown
                            options={[
                                {
                                    id: 1,
                                    text: 'white',
                                    value: 'white',
                                },
                                {
                                    id: 4,
                                    text: 'printed',
                                    value: 'printed',
                                },

                            ]}
                            selection compact
                            value={this.state.item.page_type.label}
                            style={{minWidth: '100px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('page_type.label', value)}
                        />
                    </div>


                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>UV Print*</div>

                        <Dropdown
                            options={[
                                {
                                    id: 1,
                                    text: 'yes',
                                    value: 'yes',
                                },
                                {
                                    id: 2,
                                    text: 'no',
                                    value: 'no',
                                }

                            ]}
                            selection compact
                            value={this.state.item.cover_uv}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('cover_uv', value)}
                        />
                    </div>

                    {this.state.item.cover_uv === 'yes' &&
                        <UvPackingPrintCheck image={this.state.item.cover_uv_image}
                                             onImageUploaded={(image) => this.onItemFieldChanged('cover_uv_image', image)}/>
                    }
                    <div className='item'>
                        <div className='label'>Emboss*</div>

                        <Dropdown
                            options={[{name: 'no', id: 'no'}, ...this.props.configs.foils].map(i => {
                                return {
                                    key: i.id,
                                    value: i.name,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            value={this.state.item.emboss.label}
                            style={{minWidth: '200px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('emboss.label', value)}
                        />
                    </div>

                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div className='item'>
                        <div className='label'>Text position*</div>

                        <Dropdown
                            options={this.props.configs.embossTextPositions.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            value={this.state.item.textPosition}
                            style={{minWidth: '200px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('textPosition', value)}
                        />
                    </div>

                    <div className='item' style={{marginLeft: '20px'}}>
                        <div className='label'>Text alignment*</div>

                        <Dropdown
                            options={this.props.configs.embossTextAlignments.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection compact
                            value={this.state.item.textAlignment}
                            style={{minWidth: '200px'}}
                            onChange={(e, {name, value}) => this.onItemFieldChanged('textAlignment', value)}
                        />
                    </div>

                    <div className='item' style={{marginLeft: '20px'}}>
                        <div className='label'>Emboss template*</div>

                        <Dropdown
                            options={this.props.configs.embossTemplatesConfig.map(i => {
                                return {
                                    key: i.name,
                                    value: i.name,
                                    text: i.name,
                                    id: i.name
                                }
                            })}
                            selection compact search
                            value={this.state.item.embossTemplate.id.toString().indexOf('#') > -1 ? this.state.item.embossTemplate.id : `#${this.state.item.embossTemplate.id}`}
                            style={{minWidth: '200px'}}
                            onChange={(e, {name, value}) => this.onEmbossTemplateChanged(value)}
                        />
                    </div>

                </div>

                {(this.state.item.emboss.label !== 'no' && this.state.item.emboss.label) &&

                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div className='item' style={{marginRight: '20px'}}>
                            <div className='label'>Emboss L1</div>

                            <Input style={{width: '350px'}} value={this.state.item.emboss_l1_text}
                                   onChange={(e) => this.onItemFieldChanged('emboss_l1_text', e.target.value)}/>
                        </div>

                        <div className='item'>
                            <div className='label'>Font</div>

                            <Dropdown
                                options={this.props.configs.fonts.map(i => {
                                    return {
                                        key: i.id,
                                        value: i.name,
                                        text: i.name,
                                        id: i.id
                                    }
                                })}
                                selection
                                value={this.state.item.emboss_l1_font.label}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l1_font.label', value)}
                            />
                        </div>
                        <div className='item' style={{marginLeft: '20px'}}>
                            <div className='label'>Font size</div>

                            <Dropdown
                                options={fontSizesL1}
                                selection compact
                                value={this.state.item.emboss_l1_size.label}
                                style={{width: '80px'}}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l1_size.label', value)}
                            />
                        </div>
                    </div>

                }

                {(this.state.item.emboss.label !== 'no' && this.state.item.emboss.label) &&

                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div className='item' style={{marginRight: '20px'}}>
                            <div className='label'>Emboss L2</div>

                            <Input style={{width: '350px'}} value={this.state.item.emboss_l2_text}
                                   onChange={(e) => this.onItemFieldChanged('emboss_l2_text', e.target.value)}/>
                        </div>

                        <div className='item'>
                            <div className='label'>Font</div>

                            <Dropdown
                                options={this.props.configs.fonts.map(i => {
                                    return {
                                        key: i.id,
                                        value: i.name,
                                        text: i.name,
                                        id: i.id
                                    }
                                })}
                                selection
                                value={this.state.item.emboss_l2_font.label}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l2_font.label', value)}
                            />
                        </div>
                        <div className='item' style={{marginLeft: '20px'}}>
                            <div className='label'>Font size</div>

                            <Dropdown
                                options={fontSizesL2}
                                selection compact
                                value={this.state.item.emboss_l2_size.label}
                                style={{width: '80px'}}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l2_size.label', value)}
                            />
                        </div>
                    </div>
                }

                {(this.state.item.emboss.label !== 'no' && this.state.item.emboss.label) &&
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div className='item' style={{marginRight: '20px'}}>
                            <div className='label'>Emboss L3</div>

                            <Input style={{width: '350px'}} value={this.state.item.emboss_l3_text}
                                   onChange={(e) => this.onItemFieldChanged('emboss_l3_text', e.target.value)}/>
                        </div>

                        <div className='item'>
                            <div className='label'>Font</div>

                            <Dropdown
                                options={this.props.configs.fonts.map(i => {
                                    return {
                                        key: i.id,
                                        value: i.name,
                                        text: i.name,
                                        id: i.id
                                    }
                                })}
                                selection
                                value={this.state.item.emboss_l3_font.label}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l3_font.label', value)}
                            />
                        </div>
                        <div className='item' style={{marginLeft: '20px'}}>
                            <div className='label'>Font size</div>

                            <Dropdown
                                options={fontSizesL3}
                                selection compact
                                value={this.state.item.emboss_l3_size.label}
                                style={{width: '80px'}}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l3_size.label', value)}
                            />
                        </div>
                    </div>
                }

                {(this.state.item.emboss.label !== 'no' && this.state.item.emboss.label) &&
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div className='item' style={{marginRight: '20px'}}>
                            <div className='label'>Emboss L4</div>

                            <Input style={{width: '350px'}} value={this.state.item.emboss_l4_text}
                                   onChange={(e) => this.onItemFieldChanged('emboss_l4_text', e.target.value)}/>
                        </div>

                        <div className='item'>
                            <div className='label'>Font</div>

                            <Dropdown
                                options={this.props.configs.fonts.map(i => {
                                    return {
                                        key: i.id,
                                        value: i.name,
                                        text: i.name,
                                        id: i.id
                                    }
                                })}
                                selection
                                value={this.state.item.emboss_l4_font.label}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l4_font.label', value)}
                            />
                        </div>
                        <div className='item' style={{marginLeft: '20px'}}>
                            <div className='label'>Font size</div>

                            <Dropdown
                                options={fontSizesL4}
                                selection compact
                                value={this.state.item.emboss_l4_size.label}
                                style={{width: '80px'}}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l4_size.label', value)}
                            />
                        </div>
                    </div>
                }

                {(this.state.item.emboss.label !== 'no' && this.state.item.emboss.label) &&
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <div className='item' style={{marginRight: '20px'}}>
                            <div className='label'>Emboss L5</div>

                            <Input style={{width: '350px'}} value={this.state.item.emboss_l5_text}
                                   onChange={(e) => this.onItemFieldChanged('emboss_l5_text', e.target.value)}/>
                        </div>

                        <div className='item'>
                            <div className='label'>Font</div>

                            <Dropdown
                                options={this.props.configs.fonts.map(i => {
                                    return {
                                        key: i.id,
                                        value: i.name,
                                        text: i.name,
                                        id: i.id
                                    }
                                })}
                                selection
                                value={this.state.item.emboss_l5_font.label}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l5_font.label', value)}
                            />
                        </div>
                        <div className='item' style={{marginLeft: '20px'}}>
                            <div className='label'>Font size</div>

                            <Dropdown
                                options={fontSizesL5}
                                selection compact
                                value={this.state.item.emboss_l5_size.label}
                                style={{width: '80px'}}
                                onChange={(e, {name, value}) => this.onItemFieldChanged('emboss_l5_size.label', value)}
                            />
                        </div>
                    </div>
                }

                {this.props.data.item.clientRequest &&

                    <div className='item'>
                        <div className='label'>Client notes</div>
                        <div className='client-request'>{this.props.data.item.clientRequest}</div>
                    </div>

                }

                <div className='item'>
                    <div className='label'>Production notes</div>
                    <textarea id="story"
                              name="story"
                              rows="3" cols="33"
                              value={this.state.details}
                              onChange={(e) => this.onMainFieldChanged('details', e.target.value)}
                              style={{width: '100%'}}>

                        </textarea>
                </div>

                {!this.props.data.consumption_meta &&
                    <div className='item'>
                        <ConsumptionAvailabilityPanel data={this.props.data.needed_skus_meta} type={this.props.data.product_type}/>
                    </div>
                }

                <div className='item'>
                    <ConsumptionPanel data={this.props.data.consumption_meta} type={this.props.data.product_type}/>
                </div>

                {this.state.dirty &&
                    <div>
                        <Button color='red' disabled={formInvalid} compact
                                onClick={this.onOrderPieceSaveClicked}>Save</Button>
                        <div>Warning! This action will remove the item from production. You will have to manually add it
                            back to production.
                        </div>
                    </div>
                }

                {this.state.item.thumbPath &&
                    <img src={this.state.item.thumbPath} width='400px' height='auto'/>
                }

            </div>
        );
    }

    getFontSizes = (fontName) => {
        if (!fontName) {
            return [];
        }

        let font = this.props.configs.fonts.find(f => f.name === fontName);
        if (!font) {
            return [];
        }

        if (!font.sizes) {
            return []
        }

        return font.sizes.split(' ').map(size => {
            return {
                key: size, text: `${size}pt`, value: size
            }
        })
    }

    getFontByNameHack = (font) => {
        if (!font) {
            return {id: 0, label: ''}
        }

        let found = null;

        switch (font.label) {
            case 'goudy.ttf':
                found = this.props.configs.fonts.find(f => f.name === 'Goudy Old Style');
                break;
            case 'Goudy':
                found = this.props.configs.fonts.find(f => f.name === 'Goudy Old Style');
                break;
            case 'goudycoursive.otf':
                found = this.props.configs.fonts.find(f => f.name === 'Goudy Cursive');
                break;
            case 'traftonscript.ttf':
                found = this.props.configs.fonts.find(f => f.name === 'Trafton Script');
                break;
            default:
                found = this.props.configs.fonts.find(f => f.name === font.label);
        }

        if (!found) {
            return {id: 0, label: ''};
        }

        return {id: found.id, label: found.name}

    }

    getFontSizeHack = (font) => {
        if (!font) {
            return {id: 0, label: ''}
        }

        if (font.label) {
            if (font.label.toString() === '32') {
                font.label = 36;
            }
            font.label = font.label.toString();
        }

        return font;
    }

    onMainFieldChanged = (field, value) => {
        this.setState({[field]: value, dirty: true})
    };

    onItemFieldChanged = (field, value) => {
        let item = {...this.state.item};
        if (field.indexOf('.') > 0) {
            let parts = field.split('.');
            item[parts[0]][parts[1]] = value;
        } else {
            item[field] = value;
        }

        this.setState({item: item, dirty: true});
    };

    onOrderPieceSaveClicked = () => {
        this.setState({
            dirty: false
        }, () => {
            this.props.onOrderItemSaveClicked({...this.state});
        })
    };

    onEmbossTemplateChanged = (embossTemplate) => {
        let item = {...this.state.item};
        item.embossTemplate = {id: embossTemplate, name: `${embossTemplate}`};

        const chosenTemplate = this.props.configs.embossTemplatesConfig.find(i=>i.name === embossTemplate);

        for(let i=1; i<=5; i++){
            item[`emboss_l${i}_font`] = {id:0, label: ""}
            item[`emboss_l${i}_size`] = {id: 0, label: ""}
        }

        for(const lineNr in chosenTemplate.lines){
            const lineConfig = chosenTemplate.lines[lineNr];
            const font = this.props.configs.fonts.find(f=>f.name === lineConfig.font);

            item[`emboss_l${lineNr}_font`] = {id: font.id, label: lineConfig.font}
            item[`emboss_l${lineNr}_size`] = {id: 0, label: lineConfig.size}
        }

        console.log(item)

        this.setState({item: item, dirty: true});
    }


}


export default OrderPieceVow;
