import React, {Component} from 'react';
import ApiService from "../../services/ApiService";
import './OrderLink.scss';
import moment from "moment-timezone";
import OrderStatusToggler from "./OrderStatusToggler";
import {Button, Checkbox, Container, Dimmer, Loader, Modal, Select} from "semantic-ui-react";
import {toast} from "react-toastify";
import {Generate} from "../utils/checksum";

class OrderRemake extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reason: '',
            remakePieces: [],
            dataLoading: false
        };

        this.options = [
            {
                key: 1,
                value: 'Production error',
                text: 'Production error'
            }, {
                key: 2,
                value: 'Sales error',
                text: 'Sales error'
            }]
    }


    render() {

        return (
            <div className='OrderLink'>
                <Dimmer active={this.state.dataLoading} page>
                    <Loader/>
                </Dimmer>

                {this.state.remakePieces.length > 0 &&
                <Modal size={'large'} open={true} onClose={this.close}>
                    <Modal.Header>Remake order - {this.state.reason}</Modal.Header>
                    <Modal.Content>
                        <p>Select items to remake</p>

                        {this.state.remakePieces.map(data => {

                            return this.formatWorkItem(data)

                            return (
                                <div>
                                    <div className='title'>[{data.id}] {data.quantity} x {data.product_type} |
                                        size: {data.item.size.value} | pages: {data.item.nr_pages} |
                                        material: {data.item.material.value} | style: {data.item.cover_style.value} |
                                        uv: {data.item.cover_uv} |
                                        emboss: {data.item.emboss.label}</div>
                                </div>
                            )
                        })}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={this.onRemakeOrderClicked}>Remake order</Button>
                    </Modal.Actions>
                </Modal>
                }

                <div className='head'>
                    <div className='text'>Remake</div>
                    <div className='fill'></div>
                </div>

                <div className='extra'>
                    <Select placeholder='Reason' label='Reason' options={this.options}
                            value={this.state.reason}
                            onChange={(e, {name, value}) => this.setState({reason: value})}/>
                </div>

                <Button disabled={this.state.reason === ''} primary style={{marginLeft: '5px'}} size={'mini'}
                        onClick={this.onRemakeClicked}>Remake</Button>
            </div>
        );
    }

    onRemakeClicked = () => {
        this.setState({
            dataLoading: true
        }, () => {
            ApiService.orderRemake({
                orders_id: this.props.id,
                reason: this.state.reason
            }).then(data => {
                data.data.forEach(item => item.selected = true);
                this.setState({remakePieces: data.data, dataLoading: false})
            });
        })
    };

    onRemakeOrderClicked = () => {
        let itemsToClone = this.state.remakePieces.filter(i=>i.selected === true).map(i=>i.id);
        let remakeData = {
            orders_id: this.props.id,
            pieces: itemsToClone,
            reason: this.state.reason.toLowerCase().replace(/\s/g, "")
        };

        ApiService.orderRemakeCreate(remakeData).then(data=>{
            window.location.href = `/order/${Generate(data.data.orderId)}`;
        });
    }

    formatWorkItem = (data) => {
        data.item = JSON.parse(data.item_meta);

        switch (data.product_type) {
            case 'album':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b> |
                        size: {data.item.size.value} | pages: {data.item.nr_pages} |
                        material: {data.item.material.value} | style: {data.item.cover_style.value} |
                        uv: {data.item.cover_uv} |
                        emboss: {data.item.emboss.label}</div>
                );
            case 'classic-album':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b> |
                        size: {data.item.size.value} | pages: {data.item.nr_pages} |
                        material: {data.item.material.value} | style: {data.item.cover_style.value} |
                        uv: {data.item.cover_uv} |
                        emboss: {data.item.emboss.label}</div>
                );
            case 'guestbook':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b> |
                        size: {data.item.size.value} | pages: {data.item.nr_pages} | spacers: {data.item.page_spacer} |
                        material: {data.item.material.value} | style: {data.item.cover_style.value} |
                        uv: {data.item.cover_uv} |
                        emboss: {data.item.emboss.label}</div>
                );
            case 'boudoir':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b> |
                        size: {data.item.size.value} | pages: {data.item.nr_pages} |
                        material: {data.item.material.value} | style: {data.item.cover_style.value} |
                        uv: {data.item.cover_uv} |
                        emboss: {data.item.emboss.label}</div>
                );
            case 'boudoir-empty':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b> |
                        size: {data.item.size.value} | pages: {data.item.nr_pages} |
                        material: {data.item.material.value} | style: {data.item.cover_style.value} |
                        uv: {data.item.cover_uv} |
                        emboss: {data.item.emboss.label}</div>
                );
            case 'rush':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] <b>{data.product_type}</b></div>
                );
            case 'corners':
                return (
                    <div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                     onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b></div>
                );
            case 'bamboo-box':
                return (<div className='title'><Checkbox style={{marginRight: '5px'}} checked={data.selected}
                                                         onChange={() => this.toggleCheckbox(data.id)}/>[{data.id}] {data.quantity} x <b>{data.product_type}</b> |
                    size: {data.item.size.value} </div>);
        }
    };

    toggleCheckbox = (itemId) => {
        let items = [...this.state.remakePieces];
        for (let i = 0; i < items.length; i++) {
           if(items[i].id === itemId){
               items[i].selected = !items[i].selected;
           }
        }

        this.setState({remakePieces: items})
    };

}


export default OrderRemake;
