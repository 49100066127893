import React, {Component} from 'react';
import './EmbossWorkItem.scss';
import {Generate} from "./utils/checksum";
import workItemSKU from "./utils/workItemSKU";

class EmbossWorkItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            progress: this.props.data.progress
        }
    }
    render() {
        let font1 = this.props.data.item.emboss_l1_font.label;
        if (font1) {
            font1 = font1.replace('.', '');
        }

        let font2 = this.props.data.item.emboss_l2_font.label;
        if (font2) {
            font2 = font2.replace('.', '');
        }

        let font3 = this.props.data.item.emboss_l3_font.label;
        if (font3) {
            font3 = font3.replace('.', '');
        }

        let font4 = this.props.data.item.emboss_l4_font?.label;
        if (font4) {
            font4 = font4.replace('.', '');
        }

        let font5 = this.props.data.item.emboss_l5_font?.label;
        if (font5) {
            font5 = font5.replace('.', '');
        }

        let statusClassName = this.state.progress.stantat === 0 ? 'status' : 'status done';

        if(this.state.progress.stantat === undefined){
            statusClassName = 'status';
        }

        let fonts = '';
        if(this.props.data.item.emboss_l1_font && this.props.data.item.emboss_l1_font.label){
            fonts+=this.props.data.item.emboss_l1_font.label.split('.')[0];
            fonts+=" " + this.props.data.item.emboss_l1_size.label;
        }
        if(this.props.data.item.emboss_l2_font && this.props.data.item.emboss_l2_font.label){
            fonts+=" / " + this.props.data.item.emboss_l2_font.label.split('.')[0];
            fonts+=" " + this.props.data.item.emboss_l2_size.label;
        }
        if(this.props.data.item.emboss_l3_font && this.props.data.item.emboss_l3_font.label){
            fonts+=" / " + this.props.data.item.emboss_l3_font.label.split('.')[0];
            fonts+=" " + this.props.data.item.emboss_l3_size.label;
        }
        if(this.props.data.item.emboss_l4_font && this.props.data.item.emboss_l4_font.label){
            fonts+=" / " + this.props.data.item.emboss_l4_font.label.split('.')[0];
            fonts+=" " + this.props.data.item.emboss_l4_size.label;
        }
        if(this.props.data.item.emboss_l5_font && this.props.data.item.emboss_l5_font.label){
            fonts+=" / " + this.props.data.item.emboss_l5_font.label.split('.')[0];
            fonts+=" " + this.props.data.item.emboss_l5_size.label;
        }

        let textAlignment = '';
        let textPosition = '';

        if(this.props.data.item.textAlignment){
            textAlignment = this.props.configs.embossTextAlignments.find(i=>i.id === this.props.data.item.textAlignment).name;
        }

        if(this.props.data.item.textPosition){
            textPosition = this.props.configs.embossTextPositions.find(i=>i.id === this.props.data.item.textPosition).name;
        }

        const codeSuffix = this.props.data.product_type.substring(0,3).toUpperCase();

        return (
            <div className='EmbossWorkItem'>
                <div className='header'>
                    <div className='text'>{workItemSKU(this.props.data)}</div>
                    <div className='text'>Folie: {this.props.data.item.emboss.label}</div>
                    <div className='text large'>Template: {this.props.data.item.embossTemplate?.name}</div>

                    <div className={statusClassName} onClick={this.toggleStatus}>stantat</div>
                </div>
                <div >
                    {this.props.data.details &&
                    <div style={{
                        marginBottom: '20px',
                        background: '#e21414',
                        color: 'white',
                        padding: '5px',
                        fontWeight: 'bold'
                    }}>{this.props.data.details}</div>
                    }
                </div>
                <div>Fonturi: {fonts}</div>
                {textPosition.length > 0 &&
                <div>Pozitie text: {textPosition}</div>
                }
                {textAlignment.length > 0 &&
                <div>Aliniere text: {textAlignment}</div>
                }
                <div className='lines'>
                    <div className='line' style={{
                        lineHeight: `${this.props.data.item.emboss_l1_size.label}px`,
                        fontSize: `${this.props.data.item.emboss_l1_size.label}px`,
                        fontFamily: font1
                    }}>{this.props.data.item.emboss_l1_text?.trim().replace(/\s+/g, '⎵')}</div>

                    {this.props.data.item.emboss_l2_size &&
                    <div className='line' style={{
                        lineHeight: `${this.props.data.item.emboss_l2_size.label}px`,
                        fontSize: `${this.props.data.item.emboss_l2_size.label}px`,
                        fontFamily: font2
                    }}>{this.props.data.item.emboss_l2_text?.trim().replace(/\s+/g, '⎵')}</div>
                    }

                    {this.props.data.item.emboss_l3_size &&
                    <div className='line' style={{
                        lineHeight: `${this.props.data.item.emboss_l3_size.label}px`,
                        fontSize: `${this.props.data.item.emboss_l3_size.label}px`,
                        fontFamily: font3
                    }}>{this.props.data.item.emboss_l3_text?.trim().replace(/\s+/g, '⎵')}</div>
                    }

                    {this.props.data.item.emboss_l4_size &&
                        <div className='line' style={{
                            lineHeight: `${this.props.data.item.emboss_l4_size.label}px`,
                            fontSize: `${this.props.data.item.emboss_l4_size.label}px`,
                            fontFamily: font4
                        }}>{this.props.data.item.emboss_l4_text?.trim().replace(/\s+/g, '⎵')}</div>
                    }

                    {this.props.data.item.emboss_l5_size &&
                        <div className='line' style={{
                            lineHeight: `${this.props.data.item.emboss_l5_size.label}px`,
                            fontSize: `${this.props.data.item.emboss_l5_size.label}px`,
                            fontFamily: font5
                        }}>{this.props.data.item.emboss_l5_text?.trim().replace(/\s+/g, '⎵')}</div>
                    }
                </div>



            </div>
        );
    }

    toggleStatus = () => {
        let newValue = parseInt(this.props.data.progress.stantat) === 0? 1:0;
        let steps = {...this.state.progress};
        steps['stantat'] = newValue;

        this.setState({
            progress: steps
        });

        let up = {
            id: this.props.data.id,
            step: 'stantat',
            orders_id: this.props.data.orders_id,
            value: newValue
        }

        this.props.onEmbossStatusToggled(up);
    }


}


export default EmbossWorkItem;
