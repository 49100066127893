import React, {Component} from 'react';
import {Button, Checkbox, Container, Dimmer, Grid, Header, Image, Input, Item, Label, Loader} from "semantic-ui-react";
import ApiService from "../services/ApiService";

class PriceConfig extends Component {
    render() {
        const currenciesElement = this.getCurrenciesElement();

        return (
                <Item  style={{margin: '10px'}}>
                    <Item.Content>
                        <Item.Header as='a'>
                           {this.props.label}
                        </Item.Header>
                        <Item.Extra>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                            {currenciesElement}
                            </div>
                        </Item.Extra>


                    </Item.Content>
                </Item>
        );
    }

    getCurrenciesElement = () =>{
        return this.props.currencies.map(currency=>{
            let value = 0;

            if(this.props.prices){
                value = this.props.prices[currency.id] || ''
            }


            return (
                <Input key={currency.id} label={currency.name} placeholder='0' value={value} onChange={(e, {value})=>this.handleChange(value, currency.id)}/>
            )
        })
    };

    handleChange = (value, currencyId)=>{
        this.props.onChanged(value, currencyId);
    }

}


export default PriceConfig;
