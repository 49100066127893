import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import SupplierPricesBambooBoxItem from "./SupplierPricesBambooBoxItem";
import SupplierPricesPenItem from "./SupplierPricesPenItem";

class SupplierPricesPen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            colors: []
        };
    }

    componentDidMount() {
        ApiService.getProductPen().then(data => {
            this.setState({colors: data.data})
        })
    }

    render() {
        const elements = this.getElements();

        return (
            <div>
                <h2 style={{marginTop: '100px'}}>pens</h2>
                <Table fixed selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell width={12}/>

                            <Table.HeaderCell width={1}/>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {elements}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    getElements = () => {
        if (this.state.colors.length === 0) {
            return null;
        }


        let prices = this.props.prices.prices;


        let supplierPrices = prices[this.props.supplierId];
        if (!supplierPrices) {
            supplierPrices = {};
        }


        let productPrices = supplierPrices['pen'];
        if (!productPrices) {
            productPrices = {};
        }

        return this.state.colors.map(color => {
            let price = {...productPrices[color.color]};
            if (!price) {
                price = {};
            }

            return (
                <SupplierPricesPenItem key={`pouch.${color.id}`} color={color} pricesId={this.props.prices.id}
                                             price={price} type='pen' supplierId={this.props.supplierId}
                                             onUpdatePrice={this.props.onUpdatePrice}/>
            )
        })
    }

}


export default SupplierPricesPen;
