import React from 'react';
import ReactDOM from 'react-dom';
import {createBrowserHistory} from 'history'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {withRouter} from 'react-router-dom';


const HApp = withRouter(({history}) => (
    <App history={history}/>
));

ReactDOM.render(
    <BrowserRouter>
            <HApp/>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();



