import React, {Component} from 'react';
import './ShipmentRefreshAndStatus.scss';
import ApiService from "../services/ApiService";
import {Button} from "semantic-ui-react";

class ShipmentRefreshAndStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            syncRunning: true,
            syncManual: false
        };

        this.timer = null;
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className='ShipmentRefreshAndStatus'>
                <Button onClick={this.doRefresh}
                        disabled={this.state.syncRunning || this.state.syncManual}>{this.state.syncRunning ? 'Refresh in progress...' : 'Refresh'}</Button>
            </div>
        );
    }

    doRefresh =  () => {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.setState({
            syncRunning: true,
            syncManual: true
        }, async ()=>{
            await ApiService.forceShipingSync();
            this.getData();
        })
    }

    getData = async () => {
        const data = await ApiService.getShipingSyncStatus();

        if(this.state.syncManual){
            if(data.data === false && this.state.syncRunning === true){
                window.location.reload();
            }
        }

        this.setState({syncRunning: data.data}, ()=>{
           this.timer = setTimeout(()=>{
               this.getData();
           }, 2000)
        })
    }


}


export default ShipmentRefreshAndStatus;
