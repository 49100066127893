import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {Container, Header, Menu} from "semantic-ui-react";
import ExternalConfigurator from "../components/ExternalConfigurator";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import './ExternalScreen.scss';
import ApiService from "../services/ApiService";
import ExternalOrder from "../components/ExternalOrder";
import AuthService from "../services/AuthService";

class ExternalScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editingConfiguration: null,
            orders: []
        }
    }

    componentDidMount() {
        this.updateDataTimeout();
    }

    render() {
        const orders = this.getOrdersElement();
        const role = AuthService.getUserData().role;

        return (
            <Container className='ExternalScreen'>

                {role !== 'external' &&
                <div className='buttons'>
                    <Button.Group>
                        <Button disabled color={'green'} compact>+</Button>
                        <Button primary compact onClick={() => this.onCreateOrderClicked('Print colaje')}>Print colaje</Button>
                        <Button primary compact onClick={() => this.onCreateOrderClicked('Print UV Coperta')}>Print UV Coperta</Button>
                        <Button primary compact onClick={() => this.onCreateOrderClicked('Print passepartout')}>Print passepartout</Button>
                        <Button primary compact onClick={() => this.onCreateOrderClicked('Taiere MDF')}>Taiere MDF</Button>
                    </Button.Group>
                </div>
                }
                <ExternalConfigurator type={this.state.editingConfiguration} onSaveClicked={this.onSaveClicked} onCancelClicked={()=>this.setState({editingConfiguration: null})}/>

                {orders}
            </Container>
        );
    }

    getOrdersElement = () => {
        if(this.state.orders.length === 0){
            return <h4>Nimic de afisat</h4>;
        }

        let finishedItems = [];
        let progressItems = [];

        this.state.orders.forEach(item=>{
            if(item.status_dispatched === 1 && item.status_executed === 1 && item.status_received === 1)  {
                finishedItems.push(item);
            } else {
                progressItems.push(item)
            }
        })


        let allItems = [...progressItems, ...finishedItems];

        return allItems.map(order=>{
            return (
                <ExternalOrder key={order.id} data={order} onDeleteClicked={this.onDeleteClicked}/>
            )
        })
    };

    onDeleteClicked = (id) => {
        ApiService.deleteExternalOrder(id).then(data=>{
            this.updateDataTimeout();
        })
    }

    onSaveClicked = (data)=>{
        data.orderPlacedOn = new Date();
        data.status = {
            executat: 0,
            trimis: 0,
            primit: 0
        };

        ApiService.placeExternalOrder(data).then(data=>{
            this.setState({editingConfiguration: null}, ()=>{
                this.updateData();
            })

        });
    };

    onCreateOrderClicked = (type) => {
        this.setState({
            editingConfiguration: type
        })
    };

    updateData = ()=>{
        ApiService.getExternalWork().then(data=>{
            let orders = data.data;

            orders.sort((a,b)=>{
                return new Date(a.deadline)-new Date(b.deadline);
            })

            this.setState({orders: orders})
        })
    }

    updateDataTimeout = ()=>{
        ApiService.getExternalWork().then(data=>{
            let orders = data.data;

            orders.sort((a,b)=>{
                return new Date(a.deadline)-new Date(b.deadline);
            });

            this.setState({orders: orders}, ()=>{
                setTimeout(() => {
                    this.updateDataTimeout();
                }, 1000 * 60 * 1)
            })
        })
    }

}


export default ExternalScreen;
