import React, {Component} from 'react';
import {Modal} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import './OrderItemStatusModal.scss';

class OrderItemStatusModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            triggerRebut: false,
            rebutConsumed: false
        }
    }
    render() {
        const modalParams = this.props.modalParams;

        const header = this.getItemHeader(modalParams);
        const newItemElements = this.getNewItemElements(modalParams);
        const fromStocElements = this.getFromStocElements(modalParams);

        return (
            <Modal
                size="fullscreen"
                open={this.props.isVisible === true}>
                <Modal.Content image>
                    <Modal.Description>
                        {/*<h4>Disponibil inventar: {available}</h4>*/}
                        {header}
                        {newItemElements}
                        {fromStocElements}

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <div className='modalActions'>
                        <Button color='black' onClick={this.props.onCloseClicked}>Ok</Button>
                    </div>
                </Modal.Actions>
            </Modal>
        );
    }

    getItemHeader = (modalParams) => {
        return (
            <div className="action">
                {modalParams.action}
            </div>
        )
    }

    getFromStocElements = (data) => {
        if (!data.fromStocParams) {
            return null;
        }

        const fromStoc = data.fromStocParams;

        return (
            <div className="OrderItemStatusModalSection">
                <div className="title">Din stoc:</div>

                <div className="list">
                    <div className="listItem">
                        <div className="value">{fromStoc.required} x</div>
                    </div>

                    <div className="listItem">
                        <div className="value">{fromStoc.type}</div>
                    </div>

                    <div className="listItem pickPlace">
                        <div className="label">Pozitie</div>
                        <div className="value">{fromStoc.pickPlace || '???'}</div>
                    </div>

                    <div className="listItem">
                        <div className="label">Marime</div>
                        <div className="value">{fromStoc.size}</div>
                    </div>

                    {fromStoc.pages &&
                        <div className="listItem">
                            <div className="label">Colaje</div>
                            <div className="value">{fromStoc.pages}</div>
                        </div>
                    }

                    {fromStoc.materialName &&
                        <div className="listItem">
                            <div className="label">Material</div>
                            <div className="value">{fromStoc.materialName}</div>
                        </div>
                    }

                    <div className="listItem light">
                        <div className="label">Disponibil in stoc</div>
                        <div className="value">{fromStoc.inInventory}</div>
                    </div>

                    <div className="listItem light">
                        <div className="label">SKU</div>
                        <div className="value">{fromStoc.sku}</div>
                    </div>

                    <div className="listItem">
                        <div className="label">Rebut</div>
                        <div className="value">
                            {(this.state.triggerRebut === true) &&
                                <Button size='small' color='blue' onClick={this.onRebutClicked}>sigur?</Button>
                            }
                            {(this.state.triggerRebut !== true && this.state.rebutConsumed === false) &&
                            <Button size='small' color='red' onClick={()=>this.setState({triggerRebut: true})}>rebut</Button>
                            }

                            {(this.state.rebutConsumed === true) &&
                                <Button size='small' color='green'>consumat din nou</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getNewItemElements = (data) => {
        if (!data.newItem) {
            return null;
        }

        const newItem = data.newItem;

        return (
            <div className="OrderItemStatusModalSection">
                <div className="title">Se face:</div>

                <div className="list">
                    <div className="listItem">
                        <div className="value">{newItem.required} x</div>
                    </div>

                    <div className="listItem">
                        <div className="value">{newItem.type}</div>
                    </div>

                    <div className="listItem">
                        <div className="label">Marime</div>
                        <div className="value">{newItem.size}</div>
                    </div>

                    {newItem.pages &&
                        <div className="listItem">
                            <div className="label">Colaje</div>
                            <div className="value">{newItem.pages}</div>
                        </div>
                    }

                    {newItem.materialName &&
                        <div className="listItem">
                            <div className="label">Stil coperta</div>
                            <div className="value">{newItem.coverType}</div>
                        </div>
                    }

                    {newItem.materialName &&
                        <div className="listItem">
                            <div className="label">Material</div>
                            <div className="value">{newItem.materialName}</div>
                        </div>
                    }

                    {newItem.pageType &&
                        <div className="listItem">
                            <div className="label">Pagini</div>
                            <div className="value">{newItem.pageType}</div>
                        </div>
                    }

                </div>
            </div>
        )
    }

    onRebutClicked = () => {
        this.setState({triggerRebut: false, rebutConsumed: true})
        this.props.onRebutClicked(this.props.modalParams.fromStocParams);
    }
}

export default OrderItemStatusModal;
