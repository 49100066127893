export default {
    LOGOUT: 'LOGOUT',

    AUTHENTICATE_REQ: 'AUTHENTICATE_REQ',
    AUTHENTICATE_RES: 'AUTHENTICATE_RES',

    HIDE_LOADER: 'HIDE_LOADER',

    LOAD_ACCOUNT_DATA_REQ: 'LOAD_ACCOUNT_DATA_REQ',
    LOAD_ACCOUNT_DATA_RES: 'LOAD_ACCOUNT_DATA_RES'
}