import React, {Component} from 'react';
import '../../screens/QuickFillScreen.scss';
import {Dropdown, Input} from "semantic-ui-react";


class FilledItemUnknown extends Component {
    render() {
        return (
            <div className="FilledItem">
                <h2 className="FilledItemTitle">Unknown item</h2>
                <div className="FilledItemBody">
                    <div className="row">
                        <div style={{color:'red'}}>
                            <h2>Item could not be quick filled. Please fill it from the main order screen!</h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default FilledItemUnknown;
