import React, {Component} from 'react';
import {Container, Dimmer, Header, Image, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import FoilConfig from "./FoilConfig";
import EmbossTemplateConfig from "./EmbossTemplateConfig";

class UVPrintConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, templates: [], fonts: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getUVTemplates(this.props.type).then(templatesData => {
                ApiService.getFonts(this.props.type).then(fontsData => {
                    this.setState({
                        loading: false,
                        templates: templatesData.data.templates,
                        fonts: fontsData.data.fonts
                    });
                });
            });
        });

    }

    render() {
        const templates = this.getTemplates();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Header as='h3' style={{marginTop: '5rem'}}>Templates</Header>
                <Item.Group divided relaxed>
                    {templates}
                </Item.Group>
                <Button style={{marginTop: '3rem'}} content='add new template' basic
                        onClick={this.onAddNewTemplateClicked}/>

                <div style={{marginBottom: '5rem'}}/>

            </Container>
        );
    }

    getTemplates = () => {
        if (this.state.templates.length === 0) {
            return null;
        }

        return this.state.templates.map(template => {
            return (
                <EmbossTemplateConfig key={template.id} config={this.props.config} data={template}
                                      sizes={this.state.sizes}
                                      fonts={this.state.fonts}
                                      onTemplateSavedClicked={this.onTemplateChanged}
                />
            )
        })
    };

    onTemplateChanged = (template) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editUVPrintTemplate(template).then(data => {
                this.setState({loading: false, templates: data.data.templates});
            });
        });
    };

    onAddNewTemplateClicked = () => {
        let templates = [...this.state.templates];
        templates.push({
            editing: true,
            id: -1,

            name: '',
            imagePath: '',
            available: false,
            meta: {lines: []},
            type: this.props.type
        });

        this.setState({
            templates: templates
        })
    };
}


export default UVPrintConfig;
