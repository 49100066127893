import React from 'react';
import './FulfillerFilter.scss';
import Utils from "../../data/Utils";

export default function FulfillerFilter({mode, disableSelection, selected, onFulfilledFilterChange}) {
    const config = mode === 'stoc' ? Utils.getFulfillerStockConfig() : Utils.getFulfillerConfig();

    const onFilterClicked = (filter) => {
        if(selected === filter){
            onFulfilledFilterChange('')
        } else {
            onFulfilledFilterChange(filter)
        }
    }

    const getItemsToRender = () => {
        let render = [];

        for (let key in config) {
            let fulfillerConfig = config[key];

            let className = 'FulfillerFilterItem'

            if (key === selected) {
                className = 'FulfillerFilterItem selected'
            } else {
                if(disableSelection){
                    className = 'FulfillerFilterItem hidden'
                }
            }



            render.push(
                <div className={className} style={{background: fulfillerConfig.bg}}
                     onClick={() => onFilterClicked(key)}>{fulfillerConfig.label}</div>
            )

        }

        return render;
    }

    return (
        <div className="FulfillerFilter">
            {getItemsToRender()}
        </div>
    )
}
