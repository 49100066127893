class Utils {
    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    stringToDate = (string) => {
        let parts = string.split(' ');
        let date = parts[0].split('-');
        let hour = parts[1].split(':');

        return new Date(date[0], parseInt(date[1]) - 1, date[2], hour[0], hour[1]);
    };

    stringToDateDisplay = (string, separator = '-') => {
        let parts = string.split(' ');
        let date = parts[0].split('-');
        let hour = parts[1].split(':');

        return `${date[2]}${separator}${date[1]}${separator}${date[0]}`
    };

    dateToString = (d) => {
        return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    };

    dateToStringDisplay = (d, separator = '-') => {
        return ("0" + d.getDate()).slice(-2) + separator +
            ("0" + (d.getMonth() + 1)).slice(-2) + separator +
            d.getFullYear() + " " +
            ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    };


    dateToStringZeroTime = (d) => {
        return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            ("0" + d.getDate()).slice(-2) + " " + "00:00:00";
    }

    addFontToDom = (familyName, path) => {
        let markup = `@font-face {font-family: '*FONT_FAMILY*';font-style: normal;font-weight: 400;src: local('*FONT_FAMILY*'),url('*FONT_PATH*') format('truetype')}`;
        markup = markup.replace('*FONT_FAMILY*', familyName);
        markup = markup.replace('*FONT_FAMILY*', familyName);
        markup = markup.replace('*FONT_PATH*', path);


        const newStyle = document.createElement('style');
        newStyle.appendChild(document.createTextNode(markup));

        document.head.appendChild(newStyle);
    }

    getCurrentStocName = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let stocName = searchParams.get("stocName");
        return stocName || 'apertura';
    }

    getFulfillerConfig = () =>{
        return {
            "apertura": {bg: '#e0e0e0', label:'AP'},
            "florin": {bg:'#00BCD4', label: 'FL'},
            "baia mare":{bg:'#CDDC39', label:'BM'}
        };
    }

    getFulfillerStockConfig = () =>{
        return {
            "apertura": {bg: '#e0e0e0', label:'AP'},
            "apertura storage": {bg: '#e0e0e0', label:'AP-S'},
            "florin": {bg:'#00BCD4', label: 'FL'},
            "baia mare":{bg:'#CDDC39', label:'BM'}
        };
    }
}

export default new Utils();
