import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import BambooBoxConfig from "./BambooBoxConfig";

class AlbumSleevesConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, sizes: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getProductAlbumSleeve().then(data => {
                this.setState({loading: false, sizes: data.data});
            });
        });

    }

    render() {
        const sizes = this.getSizes();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <Item.Group divided relaxed>
                    {sizes}
                </Item.Group>

                <Button style={{marginTop: '3rem'}} content='add new size'  basic onClick={this.onAddNewSizeClicked}/>
            </Container>
        );
    }

    getSizes = () => {
        if (this.state.sizes.length === 0) {
            return null;
        }

        return this.state.sizes.map(size => {
            return (
                <BambooBoxConfig key={size.id} data={size} onSavedClicked={this.onSizeChanged}/>
            )
        })
    };

    onSizeChanged = (size) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editProductAlbumSleeve(size).then(data => {
                this.setState({loading: false, sizes: data.data});
            });
        });
    };

    onAddNewSizeClicked = () => {
        let sizes = [...this.state.sizes];
        sizes.push({
            editing: true,
            id: -1,
            cm: '',
            inch: '',
            available: false,
            type: this.props.type
        });

        this.setState({
            sizes: sizes
        })
    };
}


export default AlbumSleevesConfig;
