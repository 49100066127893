import React, {useEffect, useState} from 'react';

import './TransferLayer.scss';
import FulfillerFilter from "../order/FulfillerFilter";
import {Button, Input, Select} from "semantic-ui-react";
import ApiService from "../../services/ApiService";

export default function TransferLayer({quantity, sku, from, onTransferComplete}) {
    const [to, setTo] = useState('');

    const [selectedItemFrom, setSelectedItemFrom] = useState();

    const [transferAmount, setTransferAmount] = useState('');
    const [transferInProgress, setTransferInProgress] = useState(false);

    const onTransferClicked = async () => {
        setTransferInProgress(true);

        const transferPayload = {
            from: from,
            to: to,
            itemSKU: sku,
            transferAmount: parseInt(transferAmount)
        }

        onTransferComplete();

        await ApiService.transferStock(transferPayload);


        setTimeout(() => {
            setTo('');
            setSelectedItemFrom(null);
            setTransferAmount('');
            setTransferInProgress(false);
        }, 2000)
    }

    if (transferInProgress) {
        return (
            <div className="TrasferLayer">
                <h2>Transferring...</h2>
            </div>
        )
    }

    return (
        <div className="TrasferLayer">
            <h2>Stoc transfer</h2>

            <div className="panes">
                <div className="pane full">
                    <div className="rowItem">
                        <div className="title">From</div>
                        <FulfillerFilter disableSelection={true} mode="stoc" selected={from} onFulfilledFilterChange={item => {
                        }}/>
                    </div>
                    <div className="content">
                        <div className="rowItem">
                            <div className="title">Item Sku</div>
                            <div>{sku}</div>
                        </div>

                        <div className="rowItem">
                            <div className="title">Available</div>
                            <div>{quantity}</div>
                        </div>

                        <div className="rowItem">
                            <div className="title">Transfer to</div>
                            <FulfillerFilter  mode="stoc" selected={to} onFulfilledFilterChange={item => setTo(item)}/>
                        </div>


                        {to &&
                            <div className="rowItem">
                                <div className="title">Transfer amount</div>
                                <Input value={transferAmount} onChange={e => setTransferAmount(e.target.value)}/>
                            </div>
                        }

                        {to &&
                            <Button primary onClick={onTransferClicked} disabled={!transferAmount || transferAmount > quantity}>Transfer</Button>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
