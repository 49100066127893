import React, {Component} from 'react';
import {Button, Container, Dimmer, Dropdown, Image, Input, Item, Loader, Table} from "semantic-ui-react";

import './GalleryItem.scss';
import ImageUpload from "./ImageUpload";
import {config} from "../config/config";
import ApiService from "../services/ApiService";

class GalleryItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: this.props.data.editing,

            id: this.props.data.id,
            name: this.props.data.meta.name,
            size: this.props.data.meta.size,
            style: this.props.data.meta.style,
            material: this.props.data.meta.material,
            personalisation: this.props.data.meta.personalisation,
            uvTemplate: this.props.data.meta.uvTemplate,
            embossTemplate: this.props.data.meta.embossTemplate,
            embossFoil: this.props.data.meta.embossFoil,
            images: this.props.data.meta.images,

            type: this.props.type
        };
    }


    render() {
        const item = this.props.data.meta;

        if (this.state.editing) {
            return this.getEditingElement();
        }

        return (
            <Table.Row key={item.id}>
                <Table.Cell>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {this.getImagesElement()}
                    </div>
                </Table.Cell>
                <Table.Cell textAlign='center'>{item.name}</Table.Cell>
                <Table.Cell textAlign='center'>{this.configToName('sizes', 'cm_n', item.size)}</Table.Cell>
                <Table.Cell textAlign='center'>{this.configToName('styles', 'name', item.style)}</Table.Cell>
                <Table.Cell textAlign='center'>{this.configToName('materials', 'name', item.material)}</Table.Cell>
                {this.props.data.meta.personalisation === 1 &&
                <Table.Cell
                    textAlign='center'>-</Table.Cell>
                }
                <Table.Cell
                    textAlign='center'>{this.configToName('personalisations', 'name', item.personalisation)}</Table.Cell>
                {this.props.data.meta.personalisation === 2 &&
                <Table.Cell
                    textAlign='center'>{this.configToName('embossTemplates', 'name', item.template)}</Table.Cell>
                }
                {this.props.data.meta.personalisation === 3 &&
                <Table.Cell textAlign='center'>{this.configToName('uvTemplates', 'name', item.template)}</Table.Cell>
                }


                <Table.Cell textAlign='center'>{this.configToName('foils', 'name', item.embossFoil)}</Table.Cell>
                <Table.Cell textAlign='center'>
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 auto', width: 'fit-content'}}>
                            <Button basic compact onClick={() => this.setState({editing: true})}>edit</Button>
                        </div>
                        <div style={{margin: '0 auto', width: 'fit-content'}}>
                            <Button compact color='red' onClick={this.onDeleteClicked}>X</Button>
                        </div>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    getEditingElement = () => {
        const images = this.getImagesElement();

        return (
            <div className='GalleryItem'>
                <div className='editing'>
                    <div className='item'>
                        <div className='label'>Name</div>
                        <Input placeholder='name' name='name' value={this.state.name} onChange={this.handleChange}/>
                    </div>

                    <div className='item'>
                        <div className='label'>Size</div>

                        <Dropdown
                            options={this.props.configs.sizes.filter(i => i.type === this.props.type).map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.cm,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.size}
                            onChange={(e, {name, value}) => this.setState({size: value})}
                        />
                    </div>

                    <div className='item'>
                        <div className='label'>Style</div>

                        <Dropdown
                            options={this.props.configs.styles.filter(i => i.type === this.props.type).map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.style}
                            onChange={(e, {name, value}) => this.setState({style: value})}
                        />
                    </div>

                    <div className='item'>
                        <div className='label'>Material</div>

                        <Dropdown
                            options={this.props.configs.materials.filter(i => i[`for_${this.props.type}`] === 1).map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.material}
                            onChange={(e, {name, value}) => this.setState({material: value})}
                        />
                    </div>

                    <div className='item'>
                        <div className='label'>Personalisation</div>

                        <Dropdown
                            options={this.props.configs.personalisations.map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.personalisation}
                            onChange={(e, {name, value}) => this.setState({personalisation: value})}
                        />
                    </div>

                    {this.state.personalisation === 2 &&
                    <div className='item'>
                        <div className='label'>Emboss template</div>

                        <Dropdown
                            options={this.props.configs.embossTemplates.filter(i => i.type === this.props.type).map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.embossTemplate}
                            onChange={(e, {name, value}) => this.setState({embossTemplate: value})}
                        />
                    </div>
                    }

                    {this.state.personalisation === 2 &&
                    <div className='item'>
                        <div className='label'>Emboss foil</div>

                        <Dropdown
                            options={this.props.configs.foils.filter(i => i.type === this.props.type).map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.embossFoil}
                            onChange={(e, {name, value}) => this.setState({embossFoil: value})}
                        />
                    </div>
                    }

                    {this.state.personalisation === 3 &&
                    <div className='item'>
                        <div className='label'>UV template</div>

                        <Dropdown
                            options={this.props.configs.uvTemplates.filter(i => i.type === this.props.type).map(i => {
                                return {
                                    key: i.id,
                                    value: i.id,
                                    text: i.name,
                                    id: i.id
                                }
                            })}
                            selection
                            value={this.state.uvTemplate}
                            onChange={(e, {name, value}) => this.setState({uvTemplate: value})}
                        />
                    </div>
                    }
                </div>
                <div style={{border: '1px solid #ccc', background: 'white', padding: '5px'}}>
                    <h2>Images for item</h2>
                    <div style={{marginBottom: '20px', display: 'flex', flexWrap: 'wrap'}}>
                        {images}
                    </div>
                </div>
                <div style={{border: '1px solid #ccc', background: 'white', padding: '5px', marginTop: '30px'}}>
                    <h2>Add image</h2>
                    <ImageUpload label="" sizeLabel="800x800px" path="gallery"
                                 onImageUploaded={this.onImageUploaded}/>
                </div>

                <Button className='save' primary onClick={this.onSaveClicked}>Save</Button>
                <Button className='cancel'  onClick={this.onCancelClicked}>Cancel</Button>

            </div>
        );
    }

    getImagesElement = () => {

        if(this.state.images.length === 0){
            return (
                <div>No images for this item</div>
            )
        }

        let itemStyle = {};
        if(this.state.editing){
            itemStyle.marginRight = '20px'
            itemStyle.marginBottom = '20px'
            itemStyle.position = 'relative'
        }

        return this.state.images.map(image => {
            return (
                <div key={image} style={itemStyle}>
                    {this.state.editing &&
                    <div className='deleteButtonStyle' onClick={()=>this.onDeleteItemImageClicked(image)}>X</div>
                    }
                    <img style={{width: '100px', border: '1px solid #ccc'}}
                         src={`${config.ASSETS_PATH}/products/gallery/${image}`}/>

                </div>
            )
        })
    }

    configToName = (type, field, id) => {
        let item = this.props.configs[type].find(i => i.id === id);
        if (item) {
            return item[field];
        }

        return '';
    }

    onSaveClicked = () => {
        let item = {
            id: this.state.id,
            type: this.state.type
        }

        let meta = {...this.state};
        delete meta.id;
        delete meta.editing;
        delete meta.type;

        item.meta = JSON.stringify(meta);

        this.setState({editing: false}, () => {
            this.props.onItemSaved(item);
        })
    }

    onImageUploaded = (imagePath) => {
        let images = [...this.state.images];
        images.push(imagePath);

        this.setState({images: images});
    }

    onDeleteClicked = () => {
        this.props.onItemDeleted(this.props.data.id);
    }

    onDeleteItemImageClicked = (image) => {
        let images = [...this.state.images];
        images = images.filter(im=>im !== image);

        this.setState({images: images})
    }

    onCancelClicked = () => {
        this.setState({
            editing: false
        })
    }

    handleChange = (e, {name, value}) => this.setState({[name]: value});
    onCheckboxChanged = (e, {name, checked}) => this.setState({[name]: checked});


}


export default GalleryItem;
