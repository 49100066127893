import React, {Component} from 'react';
import './MonthlyProfitComponent.scss';
import ApiService from "../../services/ApiService";
import {Cell, Pie, PieChart, Tooltip} from "recharts";

class DividedRealProfitComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        ApiService.getReportsDividedReal(this.props.from, this.props.to)
            .then(data => {
                this.setState(data.data);
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.from === prevProps.from) {
            return;
        }
        ApiService.getReportsDividedReal(this.props.from, this.props.to)
            .then(data => {
                this.setState(data.data);
            })
    }

    render() {
        if(!this.state.guestbook){
            return null;
        }

        const data1 = [
            {name: 'guestbook', value: this.state.guestbook.profit},
            {name: 'boudoir', value: this.state.boudoir.profit},
            {name: 'boudoir-empty', value: this.state['boudoir-empty'].profit},
            {name: 'album', value: this.state.album.profit},

        ]
        const data2 = [
            {name: 'guestbook', value: this.state.guestbook.pieces},
            {name: 'boudoir', value: this.state.boudoir.pieces},
            {name: 'boudoir-empty', value: this.state['boudoir-empty'].pieces},
            {name: 'album', value: this.state.album.pieces},

        ]

        const data3 = [
            {name: 'guestbook', value: this.state.guestbook.profitPerItem},
            {name: 'boudoir', value: this.state.boudoir.profitPerItem},
            {name: 'boudoir-empty', value: this.state['boudoir-empty'].profitPerItem},
            {name: 'album', value: this.state.album.profitPerItem},
        ]

        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        const RADIAN = Math.PI / 180;

        return (
            <div className='MonthlyProfitComponent'>

                <h3 style={{marginBottom: '30px'}}>Profit by item type {this.props.from}</h3>
                <div className='content'>
                    <div>
                        <h3 style={{textAlign: 'center'}}>Profit</h3>
                        <PieChart width={300} height={300}>
                            <Pie isAnimationActive={false} data={data1} cx={150} cy={150} outerRadius={80}
                                 fill="#8884d8"
                                 label={entry => entry.name}>
                                {
                                    data1.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                    </div>

                    <div>
                        <h3 style={{textAlign: 'center'}}>Pieces</h3>
                        <PieChart width={300} height={300}>
                            <Pie isAnimationActive={false} data={data2} cx={150} cy={150} outerRadius={80}
                                 fill="#8884d8"
                                 label={entry => entry.name}>
                                {
                                    data2.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                    </div>

                    <div>
                        <h3 style={{textAlign: 'center'}}>Profit per item</h3>
                        <PieChart width={300} height={300}>
                            <Pie isAnimationActive={false} data={data3} cx={150} cy={150} outerRadius={80}
                                 fill="#8884d8"
                                 label={entry => entry.name}>
                                {
                                    data3.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                                }
                            </Pie>
                            <Tooltip/>
                        </PieChart>
                    </div>


                </div>
            </div>
        );
    }

}


export default DividedRealProfitComponent;
