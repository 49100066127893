const {Generate} = require("./checksum");

function workItemSKU(item){
    let basicSuffix = item.product_type.substring(0,3).toUpperCase();

    if(item.product_type==='guestbook-slots'){
        basicSuffix = 'GUE-S';
    }
    let suffix = basicSuffix + "-" + item.id;
    const sku = `${Generate(item.mainOrderId)}-${suffix}`;
    return sku;
}

export default workItemSKU;
