import React, {Component} from 'react';
import '../../screens/QuickFillScreen.scss';
import {Dropdown, Input} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import ConsumptionAvailabilityPanel from "../ConsumptionAvailabilityPanel";


class FilledItemGuestbook extends Component {
    constructor(props) {
        super(props);

        this.state = {
            neededSKUs: {}
        }
    }

    render() {
        const embossTemplatesElements = this.getEmbossTemplates();

        return (
            <div className="FilledItem">
                <h2 className="FilledItemTitle">Guestbook x {this.props.data.quantity}</h2>
                <div className="FilledItemBody">
                    <div className="row">
                        <div className="selectionItem">
                            <div className="title">Size</div>
                            <div className="items">
                                <div className="item selected">{this.props.data.autofill.option_size}</div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">Sides</div>
                            <div className="items">
                                <div className="item"
                                     onClick={() => this.props.onPropertyChange(this.props.data, 'option_sides', parseInt(this.props.data.autofill.option_sides) - 2)}>
                                    {parseInt(this.props.data.autofill.option_sides) - 2}
                                </div>
                                <div className="item selected">{this.props.data.autofill.option_sides}</div>
                                <div className="item"
                                     onClick={() => this.props.onPropertyChange(this.props.data, 'option_sides', parseInt(this.props.data.autofill.option_sides) + 2)}>
                                    {parseInt(this.props.data.autofill.option_sides) + 2}</div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">Cover</div>
                            <div className="items">
                                <div
                                    className={this.props.data.autofill.option_cover === 'Classic' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_cover', 'Classic')}
                                >Classic
                                </div>

                                <div
                                    className={this.props.data.autofill.option_cover === 'Boudoir Flap' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_cover', 'Boudoir Flap')}
                                >Boudoir Flap
                                </div>

                                <div
                                    className={this.props.data.autofill.option_cover === 'Lift Square' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_cover', 'Lift Square')}
                                >Lift Square
                                </div>

                                <div
                                    className={this.props.data.autofill.option_cover === 'Combo' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_cover', 'Combo')}
                                >Combo
                                </div>

                                <div
                                    className={this.props.data.autofill.option_cover === 'Hardcover' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_cover', 'Hardcover')}
                                >Hardcover
                                </div>

                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">Pages</div>
                            <div className="items">
                                <div
                                    className={this.props.data.autofill.option_pages === 'white' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_pages', 'white')}
                                >White
                                </div>

                                <div
                                    className={this.props.data.autofill.option_pages === 'black' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_pages', 'black')}
                                >Black
                                </div>

                                <div
                                    className={this.props.data.autofill.option_pages === 'printed text' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_pages', 'printed text')}
                                >Printed
                                </div>

                                <div
                                    className={this.props.data.autofill.option_pages === 'photo' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_pages', 'photo')}
                                >Photo
                                </div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">Spacers</div>
                            <div className="items">
                                <div
                                    className={this.props.data.autofill.option_spacers === 'yes' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_spacers', 'yes')}
                                >Yes
                                </div>
                                <div
                                    className={this.props.data.autofill.option_spacers === 'no' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_spacers', 'no')}
                                >No
                                </div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">UV Print</div>
                            <div className="items">
                                <div
                                    className={this.props.data.autofill.option_uv === 'yes' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_uv', 'yes')}
                                >Yes
                                </div>

                                <div
                                    className={this.props.data.autofill.option_uv === 'no' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_uv', 'no')}
                                >No
                                </div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div
                                className={this.props.data.autofill.option_material.length ? "title" : 'title red'}>Material
                            </div>
                            <div className="items">
                                <div>
                                    <Dropdown
                                        options={this.props.configs.materials.filter(i => i.for_guestbook === 1).map(i => {
                                            return {
                                                key: i.id,
                                                value: i.name,
                                                text: i.name,
                                                id: i.id
                                            }
                                        })}
                                        selection compact
                                        search
                                        value={this.props.data.autofill.option_material}
                                        style={{minWidth: '100px'}}
                                        onChange={(e, item) => this.props.onPropertyChange(this.props.data, 'option_material', item.options.find(o => o.value === item.value).value)}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '25px'}}>
                        <div className="selectionItem">
                            <div className="title">Emboss Template</div>
                            <div className="items">
                                <div
                                    className={this.props.data.autofill.option_embossTemplate === 'no' ? 'item selected' : 'item'}
                                    onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossTemplate', 'no')}>No
                                </div>
                                {embossTemplatesElements}
                            </div>
                        </div>
                        {this.props.data.autofill.option_embossTemplate !== 'No' &&
                            <div className="selectionItem">
                                <div className="title">Emboss Foil</div>
                                <div className="items">
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'blind' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'blind')}
                                    >blind
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'gold' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'gold')}
                                    >gold
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'silver' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'silver')}
                                    >silver
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'rose gold' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'rose gold')}
                                    >rose gold
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'black' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'black')}
                                    >black
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'white' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'white')}
                                    >white
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'cream' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'cream')}
                                    >cream
                                    </div>
                                    <div
                                        className={this.props.data.autofill.option_embossFoil === 'gray' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossFoil', 'gray')}
                                    >gray
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        {this.getEmbossInputs()}
                    </div>

                    <div className='row' style={{marginTop: '30px'}}>
                        {this.props.data.autofill.option_spacers === 'yes' &&
                            <div className="selectionItem">
                                <div className="title">Free corners</div>
                                <div className="items">
                                    <div
                                        className={'item'}
                                        onClick={() => {
                                        }}
                                    >I will add 5 sheets discounted 100%
                                    </div>

                                    <div
                                        className={this.props.data.autofill.option_includedCorners === 'white' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_includedCorners', 'white')}
                                    >white
                                    </div>

                                    <div
                                        className={this.props.data.autofill.option_includedCorners === 'black' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_includedCorners', 'black')}
                                    >black
                                    </div>

                                    <div
                                        className={this.props.data.autofill.option_includedCorners === 'kraft' ? 'item selected' : 'item'}
                                        onClick={() => this.props.onPropertyChange(this.props.data, 'option_includedCorners', 'kraft')}
                                    >kraft
                                    </div>


                                </div>
                            </div>
                        }
                    </div>

                    <div className='row' style={{marginTop: '30px'}}>
                        <div className="selectionItem">
                            <div className="title">Production notes</div>
                            <div className="items">
                                <textarea id="story"
                                          name="story"
                                          rows="3" cols="33"
                                          value={this.props.data.autofill.option_notes}
                                          onChange={(e) => this.props.onPropertyChange(this.props.data, 'option_notes', e.target.value)}
                                          style={{width: '100%'}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row' style={{marginTop: '30px'}}>
                        <div className="selectionItem">
                            <div className="title">Verifica stoc</div>
                            <div className="items">
                                <div
                                    className={'item selected'}
                                    onClick={this.getStockStuffForItem}>Verifica
                                </div>

                                <div>
                                    <ConsumptionAvailabilityPanel type="guestbook"
                                                                  data={JSON.stringify({neededSKUs: this.state.neededSKUs})}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    getStockStuffForItem = () => {
        const item = {...this.props.data};

        item.item_meta = {
            "size": {"id": 0, "value": ""},
            "nr_pages": "",
            "material": {"id": 0, "value": ""},
            "page_type": {"id": 0, "label": ""},
            "page_spacer": "",
            "cover_uv": "",
            "emboss": {"id": 0, "label": ""},
            "emboss_l1_text": "",
            "emboss_l1_size": {"id": 0, "label": 0},
            "emboss_l1_font": {"id": 0, "label": ""},
            "emboss_l2_text": "",
            "emboss_l2_size": {"id": 0, "label": 0},
            "emboss_l2_font": {"id": 0, "label": ""},
            "emboss_l3_text": "",
            "emboss_l3_size": {"id": 0, "label": ""},
            "emboss_l3_font": {"id": 0, "label": ""},

            "emboss_l4_text": "",
            "emboss_l4_size": {"id": 0, "label": ""},
            "emboss_l4_font": {"id": 0, "label": ""},

            "emboss_l5_text": "",
            "emboss_l5_size": {"id": 0, "label": ""},
            "emboss_l5_font": {"id": 0, "label": ""},

            "embossTemplate": {
                "id": 0,
                "name": ""
            },
            "textPosition": 1,
            "textAlignment": 1,

            "cover_style": {"id": 0, "value": ""}
        }

        const size = this.props.configs.sizes.find(s => s.type === 'guestbook' && s.cm === item.autofill.option_size)
        if (size) {
            item.item_meta.size = {
                id: size.id,
                value: size.cm
            }
        }

        item.item_meta.nr_pages = item.autofill.option_sides;

        const material = this.props.configs.materials.find(m => m.for_guestbook === 1 && m.name === item.autofill.option_material)
        if (material) {
            item.item_meta.material = {
                id: material.id,
                value: material.name
            }
        }

        item.item_meta.page_type = {
            id: 0,
            label: item.autofill.option_pages
        };

        item.item_meta.page_spacer = item.autofill.option_spacers;
        item.item_meta.cover_uv = item.autofill.option_uv;

        const style = this.props.configs.styles.find(s => s.type === 'guestbook' && s.name === item.autofill.option_cover)
        if (style) {
            item.item_meta.cover_style = {
                id: style.id,
                value: style.name
            }
        }

        if (item.autofill.option_embossTemplate === 'no') {
            item.item_meta.emboss = {
                id: 0,
                label: "no"
            }
        } else {
            item.item_meta.emboss = {
                id: 0,
                label: item.autofill.option_embossFoil
            }
            item.item_meta.embossTemplate.name = item.autofill.option_embossTemplate
            item.item_meta.embossTemplate.id = item.autofill.option_embossTemplate
        }


        item.item = item.item_meta;

        delete item.item_meta;
        delete item.autofill;
        delete item.progress;

        this.setState({neededSKUs: {}}, () => {
            ApiService.getStockNeedsForItem(item).then(data => {
                const neededSKUs = data.data.neededSKUs;
                this.setState({neededSKUs})
            })
        })
    }

    getEmbossTemplates = () => {
        if (!this.props.configs.embossTemplatesConfig) {
            return null;
        }

        return this.props.configs.embossTemplatesConfig.map(item => {
            return (
                <div key={item.name}
                     className={this.props.data.autofill.option_embossTemplate === item.name ? 'item selected' : 'item'}
                     onClick={() => this.props.onPropertyChange(this.props.data, 'option_embossTemplate', item.name)}
                >{item.name}
                </div>
            )
        })
    }


    getEmbossInputs = () => {
        const currentTemplate = this.props.configs.embossTemplatesConfig.find(t => t.name === this.props.data.autofill.option_embossTemplate);
        if (!currentTemplate) {
            return null;
        }

        let elements = [];

        for (let i = 1; i <= 5; i++) {
            let line = currentTemplate.lines[i];

            elements.push(
                <div key={i} className="row" style={{marginTop: '25px'}}>
                    <div className="selectionItem">
                        <div className="title">Emboss Line {i}</div>
                        <div className="items">
                            <div style={{marginRight: '15px'}}>
                                <Input style={{width: '350px'}}
                                       value={this.props.data.autofill[`emboss_text_line_${i}`]} onChange={(e) => {
                                    this.props.onPropertyChange(this.props.data, `emboss_text_line_${i}`, e.target.value)
                                }}/>
                            </div>

                            {(line !== null && line !== undefined) &&
                                <div className={'item'} onClick={() => {
                                }}>{line.font}
                                </div>
                            }

                            {(line === undefined || line === null) &&
                            <div className={''}>
                                <Dropdown
                                    options={this.props.configs.fonts.map(i => {
                                        return {
                                            key: i.id,
                                            value: i.name,
                                            text: i.name,
                                            id: i.id
                                        }
                                    })}
                                    selection
                                    value={this.props.data.autofill[`emboss_text_font_${i}`]}
                                    onChange={(e, {name, value}) => this.props.onPropertyChange(this.props.data, `emboss_text_font_${i}`, value)}
                                />
                            </div>
                            }

                            {(line !== null && line !== undefined) &&
                                <div className={'item'} onClick={() => {
                                }}>{line.size} pt
                                </div>
                            }

                            {(line === undefined || line === null) &&
                                <div className={''} >
                                    <Dropdown
                                        options={[14, 24,36,60, 72].map(i => {
                                            return {
                                                key: i,
                                                value: i,
                                                text: `${i}pt`,
                                                id: i
                                            }
                                        })}
                                        selection
                                        value={this.props.data.autofill[`emboss_text_size_${i}`]}
                                        onChange={(e, {name, value}) => this.props.onPropertyChange(this.props.data, `emboss_text_size_${i}`, value)}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            )
        }

        return elements;
    }

}


export default FilledItemGuestbook;
