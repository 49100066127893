import React, {Component} from 'react';
import './ExternalOrder.scss';
import moment from "moment-timezone";
import ApiService from "../services/ApiService";
import AuthService from "../services/AuthService";
import {Button} from "semantic-ui-react";
import {Generate} from "./utils/checksum";

class ExternalOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data
        }
    }


    render() {
        let className = 'ExternalOrder';

        let now = new Date().getTime();
        let deadline = new Date(this.state.data.deadline).getTime();
        let timespan = now - deadline;
        const role = AuthService.getUserData().role;

        if (timespan > 0) {
            if (this.state.data.status_dispatched === 0) {
                className += ' late'
            }
        } else {
            if (timespan > -86400000) {
                if (this.state.data.status_executed === 0) {
                    className += ' closing'
                }
            }
        }

        const fields = this.getFieldsElement(role);
        const statusCN = this.state.data.status_executed === 1 ? 'item green' : 'item';
        const sentCN = this.state.data.status_dispatched === 1 ? 'item green' : 'item';
        const reveivedCN = this.state.data.status_received === 1 ? 'item green' : 'item';

        return (
            <div className={className}>
                <div className='left'>
                    <div className='title'> {this.state.data.label}</div>
                    <div className='deadline'> {moment(this.state.data.deadline).fromNow()}</div>
                    {role !== 'external' &&
                    <div>
                        <div className='source'>{this.state.data.source} </div>
                        <Button style={{marginTop: '5px'}} size='tiny' onClick={this.onDeleteClicked}>delete</Button>
                    </div>
                    }
                </div>
                <div className='right'>
                    {fields}
                </div>

                <div className='status'>
                    <div className={statusCN} onClick={() => this.onStatusToggled('status_executed')}>executat</div>
                    <div className={sentCN} onClick={() => this.onStatusToggled('status_dispatched')}>trimis</div>
                    {role !== 'external' &&
                    <div className={reveivedCN} onClick={() => this.onStatusToggled('status_received')}>primit</div>
                    }
                </div>
            </div>
        );
    }

    onDeleteClicked = () => {
        if(this.props.onDeleteClicked) {
            this.props.onDeleteClicked(this.state.data.id);
        }
    }

    getFieldsElement = (role) => {
        let fields = this.state.data.orderMeta;
        let elements = [];


        elements.push(
            <div className='field'>
                <div className='label'>Id</div>
                <div className='value'>{this.state.data.id}</div>
            </div>
        );

        if (role !== 'external') {
            elements.push(
                <div className='field'>
                    <div className='label'>Client</div>
                    <div className='value'>{Generate(this.state.data.orders_id)}{this.state.data.clientName}</div>
                </div>
            );
        }


        for (let key in fields) {
            let field = fields[key];

            if (field.value) {

                if (field.type === 'text') {
                    elements.push(
                        <div className='field'>
                            <div className='label'>{field.label}</div>
                            <div className='value'>{field.value}</div>
                        </div>
                    )
                }
                if (field.type === 'textbox') {
                    elements.push(
                        <div className='field'>
                            <div className='label'>{field.label}</div>
                            <div className='value' style={{maxWidth: '230px'}}>{field.value}</div>
                        </div>
                    )
                }
                if (field.type === 'dropdown') {
                    if (field.label !== 'Sursa') {
                        elements.push(
                            <div className='field'>
                                <div className='label'>{field.label}</div>
                                <div className='value'>{field.value}</div>
                            </div>
                        )
                    }
                }
                if (field.type === 'url') {
                    elements.push(
                        <div className='field'>
                            <div className='label'>{field.label}</div>
                            <div className='value'><a href={`${field.value}`} target='_blank'>Descarca</a></div>
                        </div>
                    )
                }
            }
        }

        return elements;
    };

    onStatusToggled = (field) => {
        let data = {...this.state.data};
        data[field] = this.state.data[field] === 0 ? 1 : 0;

        this.setState({data: data}, () => {
            ApiService.toggleExternalWorkStatus({
                id: this.state.data.id,
                field: field,
                value: this.state.data[field]
            });
        })
    };


}


export default ExternalOrder;
