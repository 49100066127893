import React, {Component} from 'react';
import {Container, Dimmer, Image, Item, Loader, Table} from "semantic-ui-react";
import SizeConfig from "./SizeConfig";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import MaterialConfig from "./MaterialConfig";
import ReorderMaterialsComponent from "./ReorderMaterialsComponent";
import MigrateMaterialsToLive from "./MigrateMaterialsToLive";

class MaterialsConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {loadingData: false, materials: [], foils: []};
    }

    componentDidMount() {
        this.setState({
            loading: true
        }, () => {
            ApiService.getMaterials(this.props.type).then(materials => {
                ApiService.getSizes(this.props.type).then(sizes => {
                    ApiService.getFoils(this.props.type).then(foils => {
                        this.setState({
                            loading: false,
                            materials: materials.data.materials,
                            foils: foils.data.foils,
                            sizes: sizes.data.sizes
                        });
                    });
                });
            });
        });

    }

    render() {
        const materials = this.getMaterials();

        return (
            <Container style={{marginTop: '5rem', marginBottom: '5rem'}}>
                <Loader active={this.state.loading}/>

                <div style={{display:'flex'}}>
                    {this.state.materials.length &&
                    <ReorderMaterialsComponent materials={this.state.materials} type={this.props.type}/>
                    }
                    <MigrateMaterialsToLive type={this.props.type}/>
                </div>
            </Container>
        );
    }

    getMaterials = () => {
        if (this.state.materials.length === 0) {
            return null;
        }

        return this.state.materials.map(material => {
            return (
                <MaterialConfig key={material.id} config={this.props.config} foils={this.state.foils} data={material}
                                sizes={this.state.sizes}
                                onSavedClicked={this.onMaterialChanged}/>
            )
        })
    };

    onMaterialChanged = (material) => {
        this.setState({
            loading: true
        }, () => {
            ApiService.editMaterial(material).then(data => {
                this.setState({loading: false, materials: data.data.materials});
            });
        });
    };

    onAddNewMaterialClicked = () => {
        let materials = [...this.state.materials];
        materials.push({
            editing: true,
            id: -1,
            name: '',
            imagePath: '',
            thumbPath: '',
            canEmboss: false,
            canUvPrint: false,
            available: false,
            category: '',
            prices: {},
            meta: {},
            type: this.props.type
        });

        this.setState({
            materials: materials
        })
    };
}


export default MaterialsConfig;
