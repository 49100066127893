import React, {Component} from 'react';
import {Button, Dimmer, Dropdown, Input, Loader, Modal} from "semantic-ui-react";
import './InvoiceEditor.scss';
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import ApiService from "../services/ApiService";
import moment from "moment-timezone";
import Utils from "../data/Utils";
import "react-datepicker/dist/react-datepicker.css";
import {parse} from "query-string";
import DatePicker from "react-datepicker/es";

const ibans = [
  {
    currency: "RON",
    iban: "IBAN(RON) RO97BTRL00501202H41975XX",
  },
  {
    currency: "USD",
    iban: "IBAN(USD) RO17BTRLUSDCRT00H4197502",
  },
  {
    currency: "GBP",
    iban: "IBAN(GBP) RO42BTRLGBPCRT00H4197502",
  },
  {
    currency: "EUR",
    iban: `IBAN(EUR) RO72BTRL00504202H41975XX`,
  },
];

class InvoiceEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: null,
            dataLoading: false,
        }
    }

    componentDidMount() {
        const defaultIban = `IBAN(RON) RO97BTRL00501202H41975XX`

        if (!this.props.invoiceId && !this.props.financeId) {
            ApiService.createNewEmptyInvoice().then(data => {
                const ronIban = ibans.find(iban => iban.currency === 'RON');
                this.setState({
                    invoice: data.data,
                    selectedIban: ronIban
                })
            })
            return;
        }

        if (this.props.invoiceId) {
            ApiService.getInvoiceById(this.props.invoiceId).then(data => {
                const invoiceIban = data.data.data.companyLine7;
                this.setState({
                    invoice: data.data,
                    selectedIban: invoiceIban
                })
            })
            ApiService.loadHistoryForInvoice(this.props.invoiceId).then(data => {
                this.setState({displayHistory: data.data})
            })
        } else {
            if (this.props.financeId) {
                ApiService.getNewInvoiceDataForFianceId(this.props.financeId).then(data => {
                    this.setState({
                      invoice: data.data,
                      selectedIban: defaultIban,
                    });
                })
            }
        }
    }

    render() {
        if (!this.state.invoice) {
            return null;
        }

        const items = this.getItemsElements();

        const historyInput = this.getHistoryInputElement();
        const historyElement = this.getHistoryElement();
        const statusElement = this.getStatusElement();

        let canSave = false;
        if (this.state.invoice.id === -1) {
            canSave = true;
        } else {
            if (this.state.modifiedReason && this.state.modifiedReason.length > 3 && this.state.modifiedWhat && this.state.modifiedWhat.length > 3) {
                canSave = true;
            }
        }

        return (
          <Modal open={true} onClose={this.props.onClose} size="fullscreen">
            <Modal.Content>
              <Dimmer active={this.state.dataLoading}>
                <Loader />
              </Dimmer>
              <div className="InvoiceEditor">
                <div className="head">
                  <div className="left">
                    <img
                      className="logo"
                      src="https://www2.phototailors.com/wp-content/uploads/2019/02/pt_black.svg"
                    />
                  </div>
                  <div className="right">
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine1}
                    />
                    <br />
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine2}
                    />
                    <br />
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine3}
                    />
                    <br />
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine4}
                    />
                    <br />
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine5}
                    />
                    <br />
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine6}
                    />
                    <br />

                    {/* <input className='input-inv' defaultValue={this.state.invoice.data.companyLine7}/><br/> */}
                    <select
                      value={this.state.selectedIban}
                      onChange={(event) => {
                        this.setState({ selectedIban: event.target.value });
                      }}
                      className='input-inv'
                    >
                      {ibans.map(
                        ({ iban }) => {
                          return <option value={iban}>{iban}</option>;
                        }
                      )}
                    </select>
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine8}
                    />
                    <br />
                    <input
                      className="input-inv"
                      defaultValue={this.state.invoice.data.companyLine9}
                    />
                    <br />
                  </div>
                </div>

                <div className="invoice-head">
                  <div className="title">Invoice {statusElement}</div>
                  <div className="error">{this.state.errorMessage}</div>
                  <hr />
                  <div className="info">
                    <div className="left">
                      <div className="">
                        Invoice Number: {this.state.invoice.series_count}
                      </div>
                      <div className="">
                        Invoice Date: &nbsp;
                        {this.state.invoice.id === -1 && (
                          <DatePicker
                            style={{ marginLeft: "5px" }}
                            dateFormat="dd-MM-yyy"
                            selected={new Date(this.state.invoice.date)}
                            onChange={(date) => this.onDateChanged(date)}
                          />
                        )}
                        {this.state.invoice.id !== -1 && (
                          <span>
                            &nbsp;{" "}
                            {Utils.stringToDateDisplay(this.state.invoice.date)}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="right">
                      <input
                        className="input-inv normal"
                        style={{ fontWeight: "bold" }}
                        placeholder="Client name"
                        onChange={(e) =>
                          this.updateFieldInData("clientName", e.target.value)
                        }
                        value={this.state.invoice.data.clientName || ""}
                        style={{ width: "500px" }}
                      />
                      <br />
                      <input
                        className="input-inv normal"
                        value={this.state.invoice.data.address1 || ""}
                        onChange={(e) =>
                          this.updateFieldInData("address1", e.target.value)
                        }
                        placeholder="Address Line 1"
                        style={{ width: "500px" }}
                      />
                      <br />
                      <input
                        className="input-inv normal"
                        value={this.state.invoice.data.address2 || ""}
                        onChange={(e) =>
                          this.updateFieldInData("address2", e.target.value)
                        }
                        placeholder="Address Line 2"
                        style={{ width: "500px" }}
                      />
                      <br />
                    </div>
                  </div>
                  <hr />
                </div>

                <div>
                  {(this.props.financeId || this.state.invoice.finances_id) && (
                    <Button
                      size="mini"
                      onClick={this.onGetItemsForInvoiceClicked}
                    >
                      Get Items
                    </Button>
                  )}
                  <Button
                    size="mini"
                    color={"green"}
                    onClick={this.onRecalculateClicked}
                  >
                    Recalculate
                  </Button>

                  <br />
                  <br />

                  <span style={{ marginRight: "10px" }}>VAT </span>
                  <Button.Group size="mini">
                    <Button
                      primary={this.state.invoice.data.vatType === "no"}
                      onClick={() => this.onChangeVatType("no")}
                    >
                      NO
                    </Button>
                    <Button
                      primary={this.state.invoice.data.vatType === "before"}
                      onClick={() => this.onChangeVatType("before")}
                    >
                      BEFORE
                    </Button>
                    <Button
                      primary={this.state.invoice.data.vatType === "after"}
                      onClick={() => this.onChangeVatType("after")}
                    >
                      AFTER
                    </Button>
                  </Button.Group>

                  <br />
                  <br />

                  <span style={{ marginRight: "10px" }}>Currency</span>
                  <Button.Group size="mini">
                    <Button
                      primary={this.state.invoice.data.currency === "RON"}
                      onClick={() => this.onChangeCurrency("RON")}
                    >
                      RON
                    </Button>
                    <Button
                      primary={this.state.invoice.data.currency === "USD"}
                      onClick={() => this.onChangeCurrency("USD")}
                    >
                      USD
                    </Button>
                    <Button
                      primary={this.state.invoice.data.currency === "EUR"}
                      onClick={() => this.onChangeCurrency("EUR")}
                    >
                      EUR
                    </Button>
                    <Button
                      primary={this.state.invoice.data.currency === "GBP"}
                      onClick={() => this.onChangeCurrency("GBP")}
                    >
                      GBP
                    </Button>
                    <Button
                      primary={this.state.invoice.data.currency === "AUD"}
                      onClick={() => this.onChangeCurrency("AUD")}
                    >
                      AUD
                    </Button>
                  </Button.Group>
                  <input
                    className="input-inv normal"
                    style={{ fontWeight: "bold" }}
                    placeholder="RON"
                    onChange={(e) =>
                      this.updateFieldInData("currency", e.target.value)
                    }
                    value={this.state.invoice.data.currency || ""}
                    style={{ width: "500px", marginLeft: "30px" }}
                  />
                  <br />

                  <br />

                  <span style={{ marginRight: "10px" }}>Series</span>
                  <Button.Group size="mini">
                    <Button
                      primary={this.state.invoice.series === "EXT"}
                      onClick={() => this.onChangeSeriesType("EXT")}
                    >
                      EXT
                    </Button>
                    <Button
                      primary={this.state.invoice.series === "INT"}
                      onClick={() => this.onChangeSeriesType("INT")}
                    >
                      INT
                    </Button>
                  </Button.Group>

                  <br />
                  <br />

                  <span style={{ marginRight: "10px" }}>Platform</span>
                  <Button.Group size="mini">
                    <Button
                      primary={this.state.invoice.data.platform !== "loribooks"}
                      onClick={() =>
                        this.onChangeInvoicePlatform("phototailors")
                      }
                    >
                      Phototailors
                    </Button>
                    <Button
                      primary={this.state.invoice.data.platform === "loribooks"}
                      onClick={() => this.onChangeInvoicePlatform("loribooks")}
                    >
                      Loribooks
                    </Button>
                  </Button.Group>

                  <br />
                  <br />

                  <span style={{ marginRight: "10px" }}>Payment Provider</span>
                  <Button.Group size="mini">
                    <Button
                      primary={this.state.invoice.payment_provider === "etsy"}
                      onClick={() =>
                        this.onChangeInvoicePaymentProvider("etsy")
                      }
                    >
                      Etsy
                    </Button>
                    <Button
                      primary={this.state.invoice.payment_provider === "stripe"}
                      onClick={() =>
                        this.onChangeInvoicePaymentProvider("stripe")
                      }
                    >
                      Stripe
                    </Button>
                    <Button
                      primary={this.state.invoice.payment_provider === "other"}
                      onClick={() =>
                        this.onChangeInvoicePaymentProvider("other")
                      }
                    >
                      Other
                    </Button>
                  </Button.Group>

                  <br />
                  <br />
                </div>

                <div className="invoice-items">
                  <div className="row head">
                    <div style={{ width: "260px", marginLeft: "20px" }}>
                      Item
                    </div>
                    <div style={{ width: "350px" }}>Description</div>
                    <div style={{ width: "130px" }}>Unit Cost</div>
                    <div style={{ width: "130px" }}>Invoice price</div>
                    <div style={{ width: "130px" }}>Unit VAT</div>
                    <div style={{ width: "160px" }}>Quantity</div>
                    <div style={{ width: "80px" }}>Line total</div>
                  </div>

                  {items}

                  <Icon
                    style={{ cursor: "pointer" }}
                    name="plus circle"
                    onClick={this.onLineAdded}
                  />

                  <div
                    className="row item noborder"
                    style={{ marginTop: "20px" }}
                  >
                    <div style={{ flex: 3 }}></div>
                    <div style={{ flex: 3 }}></div>
                    <div style={{ flex: 2 }}>Subtotal</div>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 2 }}>
                      <div
                        className="input-inv normal right"
                        style={{ display: "flex" }}
                      >
                        <div
                          style={{
                            marginRight: "4px",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.invoice.data.currency}
                        </div>
                        {this.state.invoice.data.subtotal}
                      </div>
                    </div>
                  </div>
                  <div className="row item noborder">
                    <div style={{ flex: 3 }}></div>
                    <div style={{ flex: 3 }}></div>
                    <div style={{ flex: 2 }}>TVA</div>
                    <div style={{ flex: 1 }}>
                      {this.state.invoice.data.vatRate * 100}%
                    </div>
                    <div style={{ flex: 2 }}>
                      <div
                        className="input-inv normal right"
                        style={{ display: "flex" }}
                      >
                        <div
                          style={{
                            marginRight: "4px",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.invoice.data.currency}
                        </div>
                        {this.state.invoice.data.vat}
                      </div>
                    </div>
                  </div>
                  <div
                    className="row item noborder"
                    style={{ fontWeight: "bold" }}
                  >
                    <div style={{ flex: 3 }}></div>
                    <div style={{ flex: 3 }}></div>
                    <div style={{ flex: 2 }}>Total</div>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 2 }}>
                      <div
                        className="input-inv normal right"
                        style={{ display: "flex" }}
                      >
                        <div
                          style={{
                            marginRight: "4px",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.invoice.data.currency}
                        </div>
                        {this.state.invoice.data.total}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {historyInput}

              <Button
                disabled={!canSave}
                primary
                onClick={this.onInvoiceSaveClicked}
              >
                Recalculate & Save
              </Button>

              {historyElement}
            </Modal.Content>
          </Modal>
        );
    }

    getHistoryInputElement = () => {
        if (!this.state.invoice) {
            return null;
        }

        if (this.state.invoice.id === -1) {
            return null;
        }

        return (
            <div>
                <br/>
                <br/>
                <hr/>
                <br/>
                <br/>

                <Input size='mini' fluid label='What was changed' value={this.state.modifiedWhat}
                       onChange={(e, {value}) => this.setState({modifiedWhat: value})}/>
                <br/>
                <Input size='mini' fluid label='Reason for the change' value={this.state.modifiedReason}
                       onChange={(e, {value}) => this.setState({modifiedReason: value})}/>
                <br/>
            </div>
        )
    };

    getHistoryElement = () => {
        if (!this.state.displayHistory || this.state.displayHistory.length === 0) {
            return null;
        }

        return this.state.displayHistory.map(item => {
            return (
                <div key={item.id} className='invoice-history'>
                    <div style={{display: 'flex'}}>
                        <div style={{fontWeight: 'bold'}}>{item.username}</div>
                        <div style={{marginLeft: '5px'}}>{item.date}</div>
                    </div>
                    <div style={{marginLeft: '10px'}}>
                        <div>Changes: {item.changes}</div>
                        <div>Reason: {item.reason}</div>
                    </div>
                </div>
            )
        })
    };

    getStatusElement = () => {
        if (!this.state.invoice) {
            return null;
        }

        if (this.state.invoice.id === -1) {
            return (<span style={{color: 'red'}}>(not saved)</span>)
        }
        if (this.state.invoice.autogenerated === 1) {
            return (<span style={{color: 'red'}}>(autogenerated)</span>)
        }
    };

    getItemsElements = () => {
        if (!this.state.invoice) {
            return null;
        }

        if (this.state.invoice.data.items.length === 0) {
            return null;
        }

        return this.state.invoice.data.items.map((item, index) => {
            return (
                <div key={index} className='row item'>
                    <Icon name='trash alternate outline' style={{cursor: 'pointer'}}
                          onClick={() => this.onLineDeleted(index)}/>
                    <div style={{width: '260px'}}><input value={item.item}
                                                         placeholder='Item'
                                                         onChange={(e) => this.onLineFieldChanged('item', e.target.value, index)}
                                                         className='input-inv normal'/></div>
                    <div style={{width: '350px'}}><textarea style={{width: '90%'}} value={item.description}
                                                            placeholder='Description'
                                                            onChange={(e) => this.onLineFieldChanged('description', e.target.value, index)}
                                                            className='input-inv normal'/></div>


                    <div style={{display: 'flex', width: '130px'}}>
                        <div style={{marginRight: '4px', fontWeight: 'bold'}}>{this.state.currency}</div>
                        <input value={item.unitCost}
                               onChange={(e) => this.onLineFieldChanged('unitCost', e.target.value, index)}
                               className='input-inv normal'
                               style={{width: '80px'}}
                        />
                    </div>
                    <div style={{display: 'flex', width: '130px'}}>
                        <div style={{marginRight: '4px', fontWeight: 'bold'}}>{this.state.currency}</div>
                        <input value={item.unitPrice}
                               disabled
                               onChange={(e) => this.onLineFieldChanged('unitPrice', e.target.value, index)}
                               className='input-inv normal'
                               style={{width: '80px'}}
                        />
                    </div>
                    <div style={{display: 'flex', width: '130px'}}>
                        <input value={item.unitVAT}
                               disabled
                               onChange={(e) => this.onLineFieldChanged('unitVAT', e.target.value, index)}
                               className='input-inv normal'
                               style={{width: '80px'}}
                        />
                    </div>
                    <div style={{width: '160px', display: 'flex'}}><input value={item.quantity}
                                                                          onChange={(e) => this.onLineFieldChanged('quantity', e.target.value, index)}
                                                                          className='input-inv normal'
                                                                          style={{width: '50px'}}/></div>
                    <div style={{width: '80px', display: 'flex'}}>
                        <input value={item.lineTotal}
                               disabled

                               className='input-inv normal' style={{width: '150px'}}/>
                    </div>
                </div>

            )
        })
    };

    onLineDeleted = (index) => {
        let lines = [...this.state.invoice.data.items];
        lines.splice(index, 1);
        this.updateFieldInData('items', lines);
    };

    onLineAdded = () => {
        let lines = [...this.state.invoice.data.items];
        lines.push({
            item: '',
            description: '',
            unitCost: 0,
            quantity: 0,
            lineTotal: 0
        });
        this.updateFieldInData('items', lines);
    };

    onLineFieldChanged = (property, value, index) => {
        let lines = [...this.state.invoice.data.items];
        let line = {...lines[index]};

        if (property === 'unitCost') {
            let floatedValue = parseFloat(value);
        }

        line[property] = value;

        lines[index] = line;

        this.updateFieldInData('items', lines);
    };

    onDateChanged = (date) => {
        let userTimezoneOffset = date.getTimezoneOffset() * 60000;
        let selectedDate = new Date(date.getTime() - userTimezoneOffset);
        selectedDate = selectedDate.toISOString().replace('T', ' ').split(' ')[0] + ' 00:00:00';

        this.updateFieldInInvoice('date', selectedDate);
    };

    updateFieldInInvoice = (field, value) => {
        this.setState({
            invoice: {...this.state.invoice, [field]: value}
        })
    };

    onGetItemsForInvoiceClicked = () => {
        let finances_id = this.props.financeId;
        if (!finances_id) {
            finances_id = this.state.invoice.finances_id;
        }

        ApiService.getItemsForInvoice({
            invoices_id: this.state.invoice.id,
            finances_id: finances_id,
            vatType: this.state.invoice.data.vatType,
            currency: this.state.invoice.data.currency,
            series: this.state.invoice.series,
            refund: this.state.invoice.data.refund
        }).then(data => {
            this.setState({invoice: data.data})
        })
    }

    onRecalculateClicked = () => {
        ApiService.recalculateInvoiceTotals({...this.state.invoice}).then(data => {
            this.setState({invoice: data.data});
        })
    }

    updateFieldInData = (field, value) => {
        this.setState({
            invoice: {...this.state.invoice, data: {...this.state.invoice.data, [field]: value}}
        })
    };

    onInvoiceSaveClicked = () => {
        ApiService.recalculateInvoiceTotals({...this.state.invoice}).then(data => {
            this.setState({invoice: data.data}, () => {
                this.doSaveInvoice();
            });
        })
    }

    doSaveInvoice = () => {
        let invoice = {...this.state.invoice};
        invoice.data.companyLine7 = this.state.selectedIban

        invoice.history = {
            modifiedWhat: this.state.modifiedWhat,
            modifiedReason: this.state.modifiedReason
        };

        this.setState({
            dataLoading: true
        }, () => {
            ApiService.pushInvoice(invoice)
                .then(data => {
                    if (data.data.err) {
                        this.setState({dataLoading: false, errorMessage: data.data.reason})
                    } else {
                        this.setState({dataLoading: false, errorMessage: null}, () => {
                            this.props.onSaved(data.data);
                        })
                    }
                })
        });
    };

    onChangeVatType = (type) => {
        let invoice = {...this.state.invoice}
        invoice.data.vatType = type;

        this.setState({invoice: invoice})
    }

    onChangeCurrency = (currency) => {
        let invoice = {...this.state.invoice}
        invoice.data.currency = currency;

        this.setState({invoice: invoice})
    }

    onChangeSeriesType = (series) => {
        let invoice = {...this.state.invoice}
        invoice.series = series;

        this.setState({invoice: invoice})
    }

    onChangeInvoicePlatform = (platform) => {
        let invoice = {...this.state.invoice}
        invoice.data = {...invoice.data, platform: platform}

        this.setState({invoice: invoice})
    }

    onChangeInvoicePaymentProvider = payment_provider => {
        let invoice = {...this.state.invoice}
        invoice.payment_provider = payment_provider;

        this.setState({invoice: invoice})
    }
}


export default InvoiceEditor;
