import React, {Component} from 'react';
import {Button, Dropdown, Input, Modal} from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import StockItemCreateBlock from "./StockItemCreateBlock";
import StockItemCreateCover from "./StockItemCreateCover";
import StockItemCreateBlockSpacer from "./StockItemCreateBlockSpacer";
import StockItemCreateCoverSpacer from "./StockItemCreateCoverSpacer";
import StockItemCreateCorners from "./StockItemCreateCorners";
import StockItemCreatePens from "./StockItemCreatePens";
import StockItemCreateBlockClassic from "./StockItemCreateBlockClassic";
import StockItemCreateClassicCover from "./StockItemCreateClassicCover";
import StockItemCreateWithSize from "./StockItemCreateWithSize";
import StockItemCreateMaterial from "./StockItemCreateMaterial";
import StockItemCreateShippingBox from "./StockItemCreateShippingBox";
import StockItemCreateBlockVow from "./StockItemCreateBlockVow";
import StockItemCreateCoverVow from "./StockItemCreateCoverVow";
import StockItemCreateBlockSlots from "./StockItemCreateBlockSlots";
import StockItemCreateMarkers from "./StockItemCreateMarkers";

class StockItemCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            materials: [],
            sizes: [],
            boudoirSizes: [],
            allSizes: [],
            classicAlbums: [],
            colajSizes: [],
            whitePaperSizes: [],
            DA140Sizes: [],
            DA280Sizes: [],
            mucavaSizes: [],
            rawMaterialSizes: [],
            shippingBoxSizes: [],

            materialName: null,
            sizeName: null
        }
    }

    componentDidMount() {
        this.loadData();
    }



    render() {
        return (
            <Modal
                onClose={this.props.hide}
                open={this.props.visible}>
                <Modal.Header>New {this.props.itemType} Item</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.props.itemType === 'bloc-r' &&
                            <StockItemCreateBlockSpacer materials={this.state.materials} sizes={this.state.sizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'cover-r' &&
                        <StockItemCreateCoverSpacer materials={this.state.materials} sizes={this.state.sizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'bloc' &&
                        <StockItemCreateBlock materials={this.state.materials} sizes={this.state.sizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'bloc-vow' &&
                            <StockItemCreateBlockVow materials={this.state.materials} sizes={this.state.allSizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'bloc-c' &&
                            <StockItemCreateBlockClassic sizes={this.state.classicAlbums} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'bloc-slots' &&
                            <StockItemCreateBlockSlots sizes={this.state.allSizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'cover-c' &&
                            <StockItemCreateClassicCover materials={this.state.materials} sizes={this.state.classicAlbumSizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'cover' &&
                        <StockItemCreateCover materials={this.state.materials} sizes={[...this.state.sizes, ...this.state.boudoirSizes]} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'cover-vow' &&
                        <StockItemCreateCoverVow materials={this.state.materials} sizes={this.state.allSizes} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'corner' &&
                        <StockItemCreateCorners corners={this.state.corners} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'pen' &&
                        <StockItemCreatePens pens={this.state.pens} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'marker' &&
                            <StockItemCreateMarkers markers={this.state.markers} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'colaj' &&
                            <StockItemCreateWithSize sizes={this.state.colajSizes} itemType='colaj' getItemSKU={state=>`COLAJ-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'white-paper' &&
                            <StockItemCreateWithSize sizes={this.state.whitePaperSizes} itemType='white-paper' getItemSKU={state=>`WHITE_PAPER-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'DA140' &&
                            <StockItemCreateWithSize sizes={this.state.DA140Sizes} itemType='DA140' getItemSKU={state=>`DA140-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'DA280' &&
                            <StockItemCreateWithSize sizes={this.state.DA280Sizes} itemType='DA280' getItemSKU={state=>`DA280-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'mucava' &&
                            <StockItemCreateWithSize sizes={this.state.mucavaSizes} itemType='mucava' getItemSKU={state=>`MUCAVA-${state.sizeName}`} hide={this.props.hide}/>
                        }

                        {this.props.itemType === 'raw_white-paper' &&
                            <StockItemCreateWithSize sizes={this.state.raw_whitePaperSizes} itemType='raw_white-paper' getItemSKU={state=>`RAW_WHITE_PAPER-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'raw_DA140' &&
                            <StockItemCreateWithSize sizes={this.state.raw_DA140Sizes} itemType='raw_DA140' getItemSKU={state=>`RAW_DA140-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'raw_DA280' &&
                            <StockItemCreateWithSize sizes={this.state.raw_DA280Sizes} itemType='raw_DA280' getItemSKU={state=>`RAW_DA280-${state.sizeName}`} hide={this.props.hide}/>
                        }
                        {this.props.itemType === 'raw_mucava' &&
                            <StockItemCreateWithSize sizes={this.state.raw_mucavaSizes} itemType='raw_mucava' getItemSKU={state=>`RAW_MUCAVA-${state.sizeName}`} hide={this.props.hide}/>
                        }

                        {this.props.itemType === 'material' &&
                            <StockItemCreateMaterial sizes={this.state.rawMaterialSizes} materials={this.state.materials} itemType='material' getItemSKU={state=>`MATERIAL-${state.sizeName}`} hide={this.props.hide}/>
                        }

                        {this.props.itemType === 'shipping_box' &&
                            <StockItemCreateShippingBox sizes={this.state.shippingBoxSizes} itemType='shipping_box' getItemSKU={state=>`SHIPPINGBOX-${state.code}`} hide={this.props.hide}/>
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.props.hide}>Close</Button>
                </Modal.Actions>
            </Modal>
        )
    }

    loadData = async () => {
        const materials = await ApiService.getAllMaterials();
        const sizes = await ApiService.getSizes('guestbook');
        const allSizes = await ApiService.getAllSizes();
        const rawMaterialsSizes = await ApiService.getSizes('raw-material');
        const pens = await ApiService.getProductPen();
        const markers = await ApiService.getProductMarker();
        const corners = await ApiService.getProductCorners();
        const classicAlbums = await ApiService.getProductClassicAlbumBlocs();
        const classicAlbumSizes =await ApiService.getSizes('classic-album-cover');
        const colajSizes =await ApiService.getSizes('colaj');
        const whitePaperSizes =await ApiService.getSizes('white-paper');
        const DA140Sizes =await ApiService.getSizes('DA140');
        const DA280Sizes =await ApiService.getSizes('DA280');
        const mucavaSizes =await ApiService.getSizes('mucava');
        const boudoirSizes = await ApiService.getSizes('boudoir');

        const raw_whitePaperSizes =await ApiService.getSizes('raw_white-paper');
        const raw_DA140Sizes =await ApiService.getSizes('raw_DA140');
        const raw_DA280Sizes =await ApiService.getSizes('raw_DA280');
        const raw_mucavaSizes =await ApiService.getSizes('raw_mucava');
        const shippingBoxSizes =await ApiService.getProductShippingBox();
        this.setState({
            materials: materials.data,
            sizes: sizes.data.sizes,
            allSizes: allSizes.data,
            pens: pens.data,
            corners: corners.data,
            markers: markers.data,
            classicAlbums: classicAlbums.data,
            classicAlbumSizes: classicAlbumSizes.data.sizes,
            colajSizes: colajSizes.data.sizes,
            whitePaperSizes: whitePaperSizes.data.sizes,
            DA140Sizes: DA140Sizes.data.sizes,
            DA280Sizes: DA280Sizes.data.sizes,
            mucavaSizes: mucavaSizes.data.sizes,
            boudoirSizes: boudoirSizes.data.sizes,
            rawMaterialSizes: rawMaterialsSizes.data.sizes,

            raw_whitePaperSizes: raw_whitePaperSizes.data.sizes,
            raw_DA140Sizes: raw_DA140Sizes.data.sizes,
            raw_DA280Sizes: raw_DA280Sizes.data.sizes,
            raw_mucavaSizes: raw_mucavaSizes.data.sizes,
            shippingBoxSizes: shippingBoxSizes.data,
        })
    }
}

export default StockItemCreate;
