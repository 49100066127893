import React from 'react';
import './ProductPersonalisationEmboss.scss'
import workItemSKU from "../../utils/workItemSKU";

export default function ProductPersonalisationLaser({data, configs, onEmbossStatusToggled}) {
    const item = data.item;

    let statusClassName = data.progress.laser_engraving === 0 ? 'status' : 'status done';

    if(data.progress.laser_engraving === undefined){
        statusClassName = 'status';
    }


    const toggleStatus = () => {
        let newValue = parseInt(data.progress.laser_engraving) === 0? 1:0;

        let up = {
            id: data.id,
            step: 'laser_engraving',
            orders_id: data.orders_id,
            value: newValue
        }

        onEmbossStatusToggled(up);
    }

    return (
        <div className="ProductPersonalisationEmboss">
            <div className="header">
                <div className="hlistItem">{workItemSKU(data)}</div>
                <div className="fill"/>
                <div className={statusClassName} onClick={toggleStatus}>laser engraving</div>
            </div>

            {data.details &&
            <div className="productionNotes">⚠ {data.details}</div>
            }

            {data.item.cover_uv_image &&
            <div className="preview">
                <img width="100%" height="auto" src={`https://d1496qv8pnac5u.cloudfront.net/products/uv-packing-check/${data.item.cover_uv_image}`}/>
            </div>
            }
        </div>
    )
}
