import React, {Component} from 'react';
import './OrderTimeline.scss';
import {Dropdown, Icon} from "semantic-ui-react";
import Utils from "../../data/Utils";

class OrderFulfiller extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const deadlines = Object.keys(Utils.getFulfillerConfig()).map(i=>{
            return {
                key: i,
                text: i,
                value: i
            }
        })

        return (
            <div className='OrderDeadline'>
                <div style={{marginBottom: '10px', paddingBottom: '4px', borderBottom: '2px solid #dededf', fontSize: '15px'}}>{this.props.label}</div>
                <Dropdown closeOnBlur
                          search selection name='orderDeadline' options={deadlines} placeholder={this.props.label} value={this.props.fulfiller} onChange={this.onDeadlineSelected}/>
            </div>
        )
    }


    onDeadlineSelected = (e, {value}) => {
            this.props.onFulfillerSelected(value);
    }

}


export default OrderFulfiller;
