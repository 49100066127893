import React, {Component} from 'react';
import AuthService from "./services/AuthService";
import ScreenController from "./controler/ScreenController";
import {dispatch, getState} from "./services/Store";
import ActionsApp from "./actions/ActionsApp";


export default function userStatusHandler(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            ScreenController.setHistory(props.history);
        }

        render() {
            // let goToScreen = '';
            //
            // if(!AuthService.isAuthenticated()){
            //     goToScreen = '/login'
            //     ScreenController.goToScreen(goToScreen);
            //
            //     if(goToScreen.length && this.props.history.location.pathname !== goToScreen){
            //         ScreenController.goToScreen(goToScreen);
            //     }
            // }

            return <WrappedComponent {...this.props} />;
        }
    }
}