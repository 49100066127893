import React, {Component} from 'react';
import './AuthenticateScreen.scss';
import {
    Container, Dropdown, Icon,
} from "semantic-ui-react";
import ApiService from "../services/ApiService";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Utils from "../data/Utils";
import VerificationComponent from "../components/reports/VerificationComponent";
import moment from "moment";
import PiecesSoldComponent from "../components/reports/PiecesSoldComponent";
import MonthlyProfitComponent from "../components/reports/MonthlyProfitComponent";
import MonthlyEstimatedProfitComponent from "../components/reports/MonthlyEstimatedProfitComponent";
import MonthlyRealProfitComponent from "../components/reports/MonthlyRealProfitComponent";
import DividedRealProfitComponent from "../components/reports/DividedRealProfitComponent";
import AllTimeDividedRealProfitComponent from "../components/reports/AllTimeDividedRealProfitComponent";

class ReportsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVerificationVisible: false,
            isMonthlyProfitVisible: false,
            isDividedProfitVisible: false,
            isPiecesVisible: false,
            piecesGraphVisible: false,
            fromDate:'',
            toDate:'',
            dates: []
        }
    }

    componentDidMount() {
        const dates = this.getMonths();
        this.setState({dates: dates, fromDate: dates[0].key, toDate: moment(dates[0].key).add(1, 'month').format("YYYY-MM")});
    }

    render() {
        return (
            <Container style={{margin: '3rem 0'}}>
                <Button icon='left chevron' onClick={this.onDateDecrementClick}/>
                <Dropdown closeOnBlur
                          selection name='orderDeadline'
                          options={this.state.dates} placeholder='Month' value={this.state.fromDate}
                          onChange={(e, {value})=>this.setState({fromDate: value, toDate: moment(value).add(1, 'month').format("YYYY-MM")})}/>
                <Button icon='right chevron' onClick={this.onDateIncrementClick}/>
                <Button toggle active={this.state.piecesGraphVisible} onClick={()=>this.setState({piecesGraphVisible:!this.state.piecesGraphVisible})}>
                    <Button.Content visible>
                        <Icon name='line graph' />
                    </Button.Content>
                </Button>
                <Button toggle active={this.state.isVerificationVisible} onClick={()=>this.setState({isVerificationVisible: !this.state.isVerificationVisible})}>
                    Verification
                </Button>
                <Button toggle active={this.state.isMonthlyProfitVisible}
                        onClick={() => this.setState({isMonthlyProfitVisible: !this.state.isMonthlyProfitVisible})}>
                    Profit
                </Button>
                <Button
                        onClick={this.getProductionTSV}>
                    Production TSV
                </Button>

                {/*<Button*/}
                {/*    onClick={this.getProductionTSVRepairs}>*/}
                {/*    Extra TSV*/}
                {/*</Button>*/}

                <Button
                    onClick={this.getMaterialUsage}>
                    Material Usage TSV
                </Button>

                <Button
                    onClick={this.getBonuriConsum}>
                    Bonuri consum TSV
                </Button>


                {/*<Button*/}
                {/*    onClick={this.getProductionTSVRepairs2}>*/}
                {/*    Extra aggregated TSV*/}
                {/*</Button>*/}

                {this.state.isVerificationVisible &&
                    <VerificationComponent from={this.state.fromDate} to={this.state.toDate}/>
                }

                <PiecesSoldComponent piecesGraphVisible={this.state.piecesGraphVisible} from={this.state.fromDate} to={this.state.toDate}/>

                {/*{this.state.isMonthlyProfitVisible &&*/}
                {/*    <MonthlyProfitComponent from={this.state.fromDate} to={this.state.toDate}/>*/}
                {/*}*/}
                {this.state.isMonthlyProfitVisible &&
                <AllTimeDividedRealProfitComponent from={this.state.fromDate} to={this.state.toDate}/>
                }
                {this.state.isMonthlyProfitVisible &&
                <DividedRealProfitComponent from={this.state.fromDate} to={this.state.toDate}/>
                }
                {this.state.isMonthlyProfitVisible &&
                <MonthlyRealProfitComponent from={this.state.fromDate} to={this.state.toDate}/>
                }




                {/*{this.state.isMonthlyProfitVisible &&*/}
                {/*<MonthlyEstimatedProfitComponent from={this.state.fromDate} to={this.state.toDate}/>*/}
                {/*}*/}
            </Container>
        );
    };

    onDateDecrementClick = ()=>{
        let dates = [...this.state.dates].reverse();
        let index = dates.indexOf(dates.find(d=>d.key === this.state.fromDate));

        let newIndex = index - 1;
        if(newIndex >=0){
            let newDate = dates[newIndex].key;

            this.setState({fromDate:newDate, toDate:moment(newDate).add(1, 'month').format("YYYY-MM")})
        }
    }

    onDateIncrementClick = ()=>{
        let dates = [...this.state.dates].reverse();
        let index = dates.indexOf(dates.find(d=>d.key === this.state.fromDate));

        let newIndex = index + 1;
        if(newIndex < dates.length){
            let newDate = dates[newIndex].key;
            this.setState({fromDate:newDate, toDate:moment(newDate).add(1, 'month').format("YYYY-MM")})
        }
    }

    getMonths = () => {
        let userStartDate = moment([2017, 0, 1, 0, 0, 0]);
        let userEndDate = moment()

        let deadLines = [];

        while(userStartDate < userEndDate){
            deadLines.push({formatted: userStartDate.format('MMMM YYYY'), raw: userStartDate.format('YYYY-MM')});
            userStartDate.add(1, 'month');
        }

        return deadLines.map(item=>{
            return {
                key: item.raw,
                text: item.formatted,
                value: item.raw
            }
        }).reverse()
    };

    getProductionTSV = () => {
        ApiService.getReportsProductionTSV(this.state.fromDate, this.state.toDate).then(data=>{
            this.download(data.data, `productie-${this.state.fromDate}.tsv`, 'text/tab-separated-values');
        })
    }

    getProductionTSVRepairs = () => {
        ApiService.getReportsProductionTSVRepairs(this.state.fromDate, this.state.toDate).then(data=>{
            this.download(data.data, `productie-diff-${this.state.fromDate}.tsv`, 'text/tab-separated-values');
        })
    }

    getProductionTSVRepairs2 = () => {
        ApiService.getReportsProductionTSVRepairs2(this.state.fromDate, this.state.toDate).then(data=>{
            this.download(data.data, `productie-diff-agg.tsv`, 'text/tab-separated-values');
        })
    }

    getMaterialUsage = () => {
        ApiService.getReportMaterialUsage().then(data=>{
            this.download(data.data, `material-usage.tsv`, 'text/tab-separated-values');
        })
    }

    getBonuriConsum = () => {
        ApiService.getReportBonuriConsum().then(data=>{
            this.download(data.data, `bonuri-consum.tsv`, 'text/tab-separated-values');
        })
    }

    download = (data, filename, type)=> {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

}


export default ReportsScreen;
