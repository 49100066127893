import React, {Component} from 'react';
import '../../screens/QuickFillScreen.scss';
import {Dropdown, Input} from "semantic-ui-react";


class FilledItemPenThree extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="FilledItem">
                <h2 className="FilledItemTitle">Set of 3 Multicolor Pen x {this.props.data.quantity}</h2>
                <div className="FilledItemBody">
                    <div className="row">

                        <div className="selectionItem">
                            <div className="title">Pens</div>
                            <div className="items">
                                <div
                                    className='item '
                                >I will add a set of multicolor pens
                                </div>
                            </div>
                        </div>

                        <div className="selectionItem">
                            <div className="title">Color</div>
                            <div className="items">
                                <div
                                    className='item '
                                >One of each
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default FilledItemPenThree;
