import React, {Component} from 'react';
import {formatDistanceToNow} from "date-fns";

import './EtsySyncStats.scss';
import ApiService from "../services/ApiService";

export class EtsySyncStats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lastForceSync: 0
        }

        this.timer = null;

    }

    componentDidMount() {
        this.poll();
    }

    componentWillUnmount() {
        if(this.timer) {
            clearTimeout(this.timer)
        }
    }


    render() {
        if (!this.props.data) {
            return null;
        }

        const lastEtsyReceiptSync = formatDistanceToNow(this.props.data.last, {addSuffix: true});
        const lastEtsyLedgerSync = formatDistanceToNow(this.props.data.ledger, {addSuffix: true});

        let autoClassName = this.props.data.enabled ? 'value green button' : 'value red button';

        let now = new Date().getTime();
        let syncDiff = (now - this.props.data.last) / 1000;
        let ledgerDiff = (now - this.props.data.ledger) / 1000;

        let lastClassNameReceipts = syncDiff > (60 * 7) ? 'value red' : 'value green';
        let lastClassNameLedger = ledgerDiff > (60 * 60 * 2) ? 'value red' : 'value green';

        let canForceSync = Math.round((now - this.state.lastForceSync) / 1000) > 600;
        let forceSync = 600 - Math.round((now - this.state.lastForceSync) / 1000);

        return (
            <div className='EtsySyncStats'>
                <div className='title'>Etsy sync</div>
                <div className='content'>
                    <div className='item'>
                        <div className='name'>receipts</div>
                        <div className={lastClassNameReceipts}>{lastEtsyReceiptSync}</div>
                    </div>

                    <div className='item'>
                        <div className='name'>ledger</div>
                        <div className='value green'>{lastEtsyLedgerSync}</div>
                    </div>

                    <div className='item'>
                        <div className='name'>force sync</div>
                        {canForceSync &&
                            <div className='value blue button' onClick={this.forceSync}>start {canForceSync}</div>
                        }
                        {!canForceSync &&
                            <div className='value red button'>cooldown {forceSync} seconds</div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    forceSync = () => {
        let now = new Date().getTime();

        this.setState({lastForceSync: now}, () => {
            ApiService.etsyPerformForcedSync();
        })
    }

    poll = async () => {
        const data = await ApiService.getEtsyLastForceSync();
        this.setState({lastForceSync: data.data.lastForceSync});

        if(this.timer){
            clearTimeout(this.timer)
            this.timer = null;
        }

        this.timer = setTimeout(() => {
            this.poll();
        }, 1000 * 30)
    }
}

export default EtsySyncStats;
