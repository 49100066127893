import React, {Component} from 'react';
import './PiecesSoldComponent.scss';
import ApiService from "../../services/ApiService";
import {Button, Container, Icon, Statistic} from "semantic-ui-react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";

class PiecesSoldComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            piecesGraphVisible: false
        }
    }

    componentDidMount() {
        // ApiService.getReportsPieces()
        //     .then(data => {
        //         this.setState({data: data.data});
        //     })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.from === prevProps.from) {
            return;
        }
        ApiService.getReportsPieces()
            .then(data => {
                this.setState({data: data.data});
            })
    }

    render() {
        if (this.state.data.length === 0) {
            return (<div>Generating reports. This can take minutes..</div>)
        }

        let totalAS = 0;
        let totalAF = 0;

        let totalGS = 0;
        let totalGF = 0;

        let totalBS = 0;
        let totalBF = 0;

        let totalBES = 0;
        let totalBEF = 0;

        this.state.data.forEach(item => {
            totalAS += item['albums sold']
            totalAF += item['albums fulfilled']

            totalGS += item['guestbooks sold']
            totalGF += item['guestbooks fulfilled']

            totalBS += item['boudoir sold']
            totalBF += item['boudoir fulfilled']

            totalBES += item['boudoir-empty sold']
            totalBEF += item['boudoir-empty fulfilled']
        })

        let lm = moment(this.props.from).subtract(1, 'month').format("YYYY-MM");
        let tm = this.props.from

        let lastMonthOrders = this.state.data.find(item=>item.date === lm);
        let thisMonthOrders = this.state.data.find(item=>item.date === tm);

        const getDaysInMonth = (date) => {
            let year = parseInt(date.split('-')[0])
            let month = parseInt(date.split('-')[1])
            return new Date(year, month, 0).getDate()
        }

        const getDaysThisMonth = () => {
            let date = new Date();
            let realMonth = date.getMonth() + 1;
            let setMonth = parseInt(this.props.from.split('-')[1]);

            if(realMonth === setMonth){
                return new Date().getDate()
            } else {
                return getDaysInMonth(thisMonthOrders.date)
            }
        }

        const getMonthlyIncrease = (type) => {
            if(!thisMonthOrders){
                return "•"
            }
            if (thisMonthOrders[type] === 0) {
                return "•"
            }
            let lastMonthOrdersPerDay = lastMonthOrders[type] / getDaysInMonth(lastMonthOrders.date);
            let thisMonthOrdersPerDay = thisMonthOrders[type] / getDaysThisMonth();

            return (((thisMonthOrdersPerDay / lastMonthOrdersPerDay) * 100 - 100).toFixed(0) + "%")
        }


        const albumsIncrease = getMonthlyIncrease('albums sold');
        const guestboksIncrease = getMonthlyIncrease('guestbooks sold');
        const boudoirIncrease = getMonthlyIncrease('boudoir sold');
        const boudoirEmptyIncrease = getMonthlyIncrease('boudoir-empty sold');

        const albumsFulfilled = thisMonthOrders['albums r-fulfilled'];
        const guestboksFulfilled = thisMonthOrders['guestbooks r-fulfilled'];
        const boudoirFulfilled = thisMonthOrders['boudoir r-fulfilled'];
        const boudoirEmptyFulfilled = thisMonthOrders['boudoir-empty r-fulfilled'];

        const date = moment(this.props.from).format("MMMM YYYY");

        return (
            <div className='PiecesSoldComponent'>
                    <i style={{marginLeft:'15px'}}>Updated every 24 hours</i>
                <div className='stats'>
                    <div className='stat'>
                        <h3 style={{marginBottom: '0px'}}>Total</h3>
                        <div style={{color: 'gray', fontSize: '12px'}}>ordered</div>
                        <Statistic size='mini'>
                            <Statistic.Label>Album</Statistic.Label>
                            <Statistic.Value>{totalAS}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Guestbook</Statistic.Label>
                            <Statistic.Value>{totalGS}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir</Statistic.Label>
                            <Statistic.Value>{totalBS}</Statistic.Value>
                        </Statistic>

                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir E</Statistic.Label>
                            <Statistic.Value>{totalBES}</Statistic.Value>
                        </Statistic>
                    </div>

                    <div className='stat'>
                        <h3 style={{color: '#03A9F4', marginBottom: '0px'}}>Orders</h3>
                        <div style={{color: 'gray', fontSize: '12px'}}>{date}</div>
                        <Statistic size='mini'>
                            <Statistic.Label>Album</Statistic.Label>
                            <Statistic.Value>{thisMonthOrders['albums sold']}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Guestbook</Statistic.Label>
                            <Statistic.Value>{thisMonthOrders['guestbooks sold']}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir</Statistic.Label>
                            <Statistic.Value>{thisMonthOrders['boudoir sold']}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir E</Statistic.Label>
                            <Statistic.Value>{thisMonthOrders['boudoir-empty sold']}</Statistic.Value>
                        </Statistic>
                    </div>

                    <div className='stat'>
                        <h3 style={{color: '#4CAF50', marginBottom: '0px'}}>Fulfilled</h3>
                        <div style={{color: 'gray', fontSize: '12px'}}>{date}</div>
                        <Statistic size='mini'>
                            <Statistic.Label>Album</Statistic.Label>
                            <Statistic.Value>{albumsFulfilled}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Guestbook</Statistic.Label>
                            <Statistic.Value>{guestboksFulfilled}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir</Statistic.Label>
                            <Statistic.Value>{boudoirFulfilled}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir E</Statistic.Label>
                            <Statistic.Value>{boudoirEmptyFulfilled}</Statistic.Value>
                        </Statistic>
                    </div>

                    <div className='stat'>
                        <h3 style={{color: '#FF5722', marginBottom: '0px'}}>Backlog</h3>
                        <div style={{color: 'gray', fontSize: '12px'}}>now</div>
                        <Statistic size='mini'>
                            <Statistic.Label>Album</Statistic.Label>
                            <Statistic.Value>{totalAS - totalAF}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Guestbook</Statistic.Label>
                            <Statistic.Value>{totalGS - totalGF}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir</Statistic.Label>
                            <Statistic.Value>{totalBS - totalBF}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir E</Statistic.Label>
                            <Statistic.Value>{totalBES - totalBEF}</Statistic.Value>
                        </Statistic>
                    </div>

                    <div className='stat'>
                        <h3 style={{marginBottom: '0px'}}>Delta</h3>
                        <div style={{color: 'gray', fontSize: '12px'}}>{moment(this.props.from).subtract(1, 'month').format("MMMM YYYY")} - {moment(this.props.to).subtract(1, 'month').format("MMMM YYYY")}</div>
                        <Statistic size='mini'>
                            <Statistic.Label>Album</Statistic.Label>
                            <Statistic.Value>{albumsIncrease}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Guestbook</Statistic.Label>
                            <Statistic.Value>{guestboksIncrease}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir</Statistic.Label>
                            <Statistic.Value>{boudoirIncrease}</Statistic.Value>
                        </Statistic>
                        <Statistic size='mini'>
                            <Statistic.Label>Boudoir E</Statistic.Label>
                            <Statistic.Value>{boudoirEmptyIncrease}</Statistic.Value>
                        </Statistic>
                    </div>

                </div>

                {this.props.piecesGraphVisible &&

                <div className='main'>
                    <h2>Pieces sold/fulfilled</h2>
                    {/*<ResponsiveContainer>*/}
                    <LineChart width={1100} height={500} data={this.state.data}
                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <XAxis dataKey="date"/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="orders" stroke="black" strokeWidth={1} dot={{r: 0}}/>

                        <Line type="monotone" dataKey="albums sold" stroke="#8884d8" strokeWidth={2} dot={{r: 0}}/>
                        <Line type="monotone" legendType='none' dataKey="albums fulfilled" stroke="#8884d8"
                              strokeDasharray="5 5" dot={{r: 0}}/>

                        <Line type="monotone" dataKey="boudoir sold" stroke="#ffc658" strokeWidth={2} dot={{r: 0}}/>
                        <Line type="monotone" legendType='none' dataKey="boudoir fulfilled" stroke="#ffc658"
                              strokeDasharray="5 5" dot={{r: 0}}/>

                        <Line type="monotone" dataKey="boudoir-empty sold" stroke="#e91e63" strokeWidth={2} dot={{r: 0}}/>
                        <Line type="monotone" legendType='none' dataKey="boudoir-empty fulfilled" stroke="#e91e63"
                              strokeDasharray="5 5" dot={{r: 0}}/>

                        <Line type="monotone" dataKey="guestbooks sold" stroke="#82ca9d" strokeWidth={2}
                              dot={{r: 0}}/>
                        <Line type="monotone" legendType='none' dataKey="guestbooks fulfilled" stroke="#82ca9d"
                              strokeDasharray="5 5" dot={{r: 0}}/>
                    </LineChart>
                    {/*</ResponsiveContainer>*/}
                </div>

                }
            </div>
        );
    }

}


export default PiecesSoldComponent;
