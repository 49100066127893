import React, {Component} from 'react';
import './OrderPieceElement.scss';
import {Dropdown, Input} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ConsumptionPanel from "../ConsumptionPanel";
import ConsumptionAvailabilityPanel from "../ConsumptionAvailabilityPanel";

class OrderPiecePenThreeSame extends Component {
    constructor(props) {
        super(props);

        let hasProgress = false;
        if (this.props.data.progress && Object.keys(this.props.data.progress).length) {
            hasProgress = true;
        }

        this.state = {
            productType: this.props.data.product_type || 'pen',
            invoiceName: this.props.data.invoiceName || 'Pack of 3 Pens',
            orderId: this.props.data.orderId || 0,
            item: {
                color: this.props.data.item.color || null,
            },
            progress: hasProgress ? this.props.data.progress : {
                impachetat: 0,
            },
            details: this.props.data.details || "",
            price: this.props.data.price || '',
            currency: this.props.data.currency || "USD",
            quantity: this.props.data.quantity || 0,
            id: this.props.data.id || -1
        }
    }

    render() {
        let formInvalid = false;

        if (!this.state.invoiceName || !this.state.invoiceName.length) {
            console.log("FAILED invoiceName")
            formInvalid = true;
        }

        if (!this.state.quantity) {
            console.log("FAILED quantity")
            formInvalid = true;
        }

        if (!this.state.price || !this.state.price.length) {
            console.log("FAILED price")
            formInvalid = true;
        }

        if (!this.state.currency || !this.state.currency.length) {
            console.log("FAILED currency")
            formInvalid = true;
        }

        if (this.state.item.color === null ) {
            console.log("FAILED")
            formInvalid = true;
        }

        const disableEdit = (this.props.orderStatus === 'in production' || this.props.orderStatus === 'urgent');
        const mainClassName = disableEdit ? 'OrderPieceElement disableAll' : 'OrderPieceElement';

        return (
            <div className={mainClassName}>
                <div className='header'>
                    <h3 className='name'>Pack of 3 Pens</h3>
                    {this.state.id === -1 &&
                    <div className='ct' onClick={this.props.onChangeTypeClicked}>change type</div>
                    }
                    {this.state.id !== -1 &&
                    <div className='discount' onClick={() => this.props.onDiscountClicked(this.state)}>apply 100% discount</div>
                    }
                    {this.state.id !== -1 &&
                    <div className='delete' onClick={() => this.props.onDeleteClicked(this.state.id)}>delete</div>
                    }
                </div>

                {(disableEdit === true) &&
                    <div style={{color:'orange', marginTop:'0'}}>
                        Editing disabled while the order is in production.
                    </div>
                }

                <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Invoice name*</div>
                        <Input style={{width: '200px'}} value={this.state.invoiceName}
                               onChange={(e) => this.onMainFieldChanged('invoiceName', e.target.value)}/>
                    </div>

                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Price*</div>
                        <Input style={{width: '80px'}} value={this.state.price}
                               onChange={(e) => this.onMainFieldChanged('price', e.target.value)}/>
                        <Input style={{width: '65px'}} value={this.state.currency}
                               onChange={(e) => this.onMainFieldChanged('currency', e.target.value)}/>

                    </div>

                    <div className='item'>
                        <div className='label'>Quantity*</div>
                        <Input style={{width: '60px'}} value={this.state.quantity}
                               onChange={(e) => this.onMainFieldChanged('quantity', e.target.value)}/>
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div className='item' style={{marginRight: '20px'}}>
                        <div className='label'>Color*</div>
                        <Dropdown
                            options={['gold', 'silver', 'black'].map(i => {
                                return {
                                    key: i,
                                    value: i,
                                    text: i,
                                    id: i
                                }
                            })}
                            selection compact
                            search
                            value={this.state.item.color}
                            style={{minWidth: '100px'}}
                            onChange={(e, item) => this.onColorChanged(item)}
                        />
                    </div>
                </div>

                <div className='item'>
                    <div className='label'>Production notes</div>
                    <textarea id="story"
                              name="story"
                              rows="3" cols="33"
                              value={this.state.details}
                              onChange={(e) => this.onMainFieldChanged('details', e.target.value)}
                              style={{width: '100%'}}
                    >

                        </textarea>
                </div>

                {!this.props.data.consumption_meta &&
                <div className='item'>
                    <ConsumptionAvailabilityPanel data={this.props.data.needed_skus_meta} type={this.props.data.product_type}/>
                </div>
                }

                <div className='item'>
                    <ConsumptionPanel data={this.props.data.consumption_meta} type={this.props.data.product_type}/>
                </div>


                {this.state.dirty &&
                <Button color='red' disabled={formInvalid} compact onClick={this.onOrderPieceSaveClicked}>Save</Button>
                }

            </div>
        );
    }

    onColorChanged = (color) => {
        this.setState({
            item: {color: color.value}, dirty: true
        })
    }


    onMainFieldChanged = (field, value) => {
        this.setState({[field]: value, dirty: true})
    };

    onItemFieldChanged = (field, value) => {
        let item = {...this.state.item};
        if (field.indexOf('.') > 0) {
            let parts = field.split('.');
            item[parts[0]][parts[1]] = value;
        } else {
            item[field] = value;
        }

        this.setState({item: item, dirty: true});
    };

    onOrderPieceSaveClicked = () => {
        this.setState({
            dirty: false
        }, () => {
            this.props.onOrderItemSaveClicked({...this.state});
        })
    };
}


export default OrderPiecePenThreeSame;
